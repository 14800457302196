import React from "react"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

const ZoomContent = ({ children }) => {
  return (
    <TransformWrapper
      defaultScale={1}
      defaultPositionX={0}
      defaultPositionY={0}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <div className="content__steering">
            Scroll
            <br /> zoom
            <span></span>
            <button
              className="content__steering-button content__steering-button--plus"
              onClick={zoomIn}
            >
              +
            </button>
            <button
              className="content__steering-button content__steering-button--minus"
              onClick={zoomOut}
            >
              -
            </button>
          </div>
          <button
            className="content__refresh"
            onClick={resetTransform}
            id="resetZoom"
          >
            Refresh <br />
            View
          </button>
          <TransformComponent>{children}</TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  )
}

export default ZoomContent
