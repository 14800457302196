import "./frontCasterLink.scss"

import React from "react"

const FrontCasterLink = ({ explode }) => {
  return explode ? (
    <svg
      className="frontCasterLink"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1545 1080"
    >
      <g id="Nr">
        <g
          id="CT012064"
          className="cursor--pointer"
          onClick={() =>
            window.open(
              "https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-steering-link-set",
              "_blank"
            )
          }
        >
          <g>
            <g>
              <path
                class="st0"
                d="M859,629h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C871,623.6,865.6,629,859,629z"
              />
            </g>
            <polyline class="st1" points="859.8,620.3 862.8,617 859.5,613.7 			" />
          </g>
          <g>
            <path
              class="st2"
              d="M776.4,612.7c-0.8,0-1.4,0.3-1.9,0.9s-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3c0.7,0,1.6-0.2,2.5-0.5v1.8
				c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7s0.9-1.4,1.6-1.8
				s1.5-0.6,2.5-0.6s1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.8-0.3-1.1-0.5C777.1,612.7,776.7,612.7,776.4,612.7z"
            />
            <path
              class="st2"
              d="M784.9,621h-2.1v-8.2h-2.7V611h7.5v1.8h-2.7V621z"
            />
            <path
              class="st2"
              d="M795.4,616c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S795.4,614.4,795.4,616z M790.5,616c0,1.2,0.1,2.1,0.3,2.6
				s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8c0.2-0.5,0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C790.6,613.9,790.5,614.8,790.5,616z"
            />
            <path
              class="st2"
              d="M801.7,621h-2.1v-5.8v-1v-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7L801.7,621L801.7,621z"
            />
            <path
              class="st2"
              d="M811.4,621h-7v-1.5l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3s-0.8,0.1-1.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
				s0.7-0.3,1.1-0.4s0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.4s-0.1,0.9-0.2,1.3
				s-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4v1.8L811.4,621L811.4,621z"
            />
            <path
              class="st2"
              d="M819.4,616c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S819.4,614.4,819.4,616z M814.5,616c0,1.2,0.1,2.1,0.3,2.6
				s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8c0.2-0.5,0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C814.6,613.9,814.5,614.8,814.5,616z"
            />
            <path
              class="st2"
              d="M820.3,616.8c0-2,0.4-3.4,1.3-4.4s2.1-1.4,3.8-1.4c0.6,0,1,0,1.3,0.1v1.7c-0.4-0.1-0.8-0.1-1.2-0.1
				c-0.7,0-1.3,0.1-1.8,0.3s-0.8,0.5-1,1c-0.2,0.4-0.4,1-0.4,1.8h0.1c0.5-0.8,1.2-1.2,2.2-1.2c0.9,0,1.6,0.3,2.1,0.8
				s0.8,1.3,0.8,2.3c0,1.1-0.3,1.9-0.9,2.5c-0.6,0.6-1.4,0.9-2.5,0.9c-0.7,0-1.4-0.2-1.9-0.5s-1-0.8-1.3-1.5
				S820.3,617.7,820.3,616.8z M824,619.5c0.5,0,0.8-0.2,1-0.5s0.4-0.7,0.4-1.3c0-0.5-0.1-0.9-0.3-1.2s-0.6-0.4-1-0.4
				s-0.8,0.1-1.1,0.4s-0.5,0.6-0.5,1c0,0.5,0.1,1,0.4,1.4C823.2,619.3,823.5,619.5,824,619.5z"
            />
            <path
              class="st2"
              d="M835.6,619h-1.2v2.1h-2.1V619H828v-1.5l4.4-6.5h1.9v6.3h1.2L835.6,619L835.6,619z M832.3,617.3v-1.7
				c0-0.3,0-0.7,0-1.2s0-0.9,0.1-0.9h-0.1c-0.2,0.4-0.4,0.7-0.6,1.1l-1.8,2.8L832.3,617.3L832.3,617.3z"
            />
          </g>
        </g>
      </g>
      <g id="_x32_">
        <path
          class="st3"
          d="M618.1,580.2l-1,0.5l-0.9,0.7 M614.6,548.1l0.3,0.3l1.4,1.1l1.3,0.6l0.4,0.1 M691,572.8h0.2l1.1-0.3l1.1-0.6
		l1-0.8l1-1.1l0.9-1.3l0.8-1.5l0.7-1.7l0.6-1.9l0.4-2l0.3-2.1l0.2-2.3l0.1-2.3l-0.1-2.4L699,550l-0.3-2.4l-0.4-2.4l-0.6-2.4
		l-0.7-2.3l-0.8-2.2l-0.9-2.1l-1-1.9l-1-1.8l-1.1-1.6l-1.1-1.4l-1.2-1.1l-1.2-0.9l-1.2-0.7l-1.2-0.4l-1.2-0.2l-1,0.1 M611.3,544.5
		l1.1,0.7l1.8,1.4l1.8,1.6l1.7,1.7l0.3,0.4 M618.1,580.2l-0.4,0.3l-1.2,0.9l-1.5,1 M583.6,531.1L583.6,531.1l-2.5-0.3l-2.5-0.1
		l-2.5,0.2l-2.5,0.4l-2.5,0.7l-2.4,0.9l-2.4,1.2l-2.3,1.4l-2.2,1.6l-2.1,1.8l-2,2l-1.8,2.2l-1.7,2.3l-1.5,2.4l-1.3,2.6l-1.1,2.7
		l-0.9,2.7l-0.7,2.8l-0.4,2.8l-0.2,2.8v2.8l0.2,2.7l0.4,2.7l0.7,2.6l0.9,2.5l1.1,2.3l1.3,2.2l1.5,2l1.7,1.8l1.8,1.6l0.1,0.1
		 M614,583.8L614,583.8L614,583.8l-0.1-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.2 M613.9,547.4L613.9,547.4l0.1,0.3v0.2l-0.1,0.2l-0.1,0.2
		l-0.2,0.3l-0.2,0.2 M574.1,594.5l-1.8-1.6l-1.7-1.8l-1.5-2l-1.3-2.2l-1.1-2.3l-0.9-2.5l-0.7-2.6l-0.4-2.7l-0.2-2.7v-2.8l0.2-2.8
		l0.4-2.8l0.7-2.8l0.9-2.7l1.1-2.7l1.3-2.6l1.5-2.4l1.7-2.3l1.8-2.2l2-2l2.1-1.8l2.2-1.6l2.3-1.4l2.4-1.2l2.4-0.9l2.5-0.7l2.5-0.4
		l2.5-0.2l2.5,0.1l2.4,0.3 M989.7,527.5l-0.2,0.9l-0.4,0.5l-0.7,0.2l-1.1-0.2l-0.8-0.3 M1002.9,468.3l-0.7-0.1l-2.4-0.2h-2.5
		l-2.5,0.3l-2.5,0.6l-2.5,0.8l-2.4,1l-2.3,1.3l-2.3,1.5l-2.2,1.7l-2,1.9l-1.9,2.1l-1.7,2.2l-1.2,1.7 M994.1,532l-1-0.9l-1.7-1.7
		l-1.6-1.9 M989.7,491l1.6-2.4l1.7-2.2l1.9-2.1l2-1.9l2.2-1.7l2.3-1.5l2.3-1.3l2.4-1l2.5-0.8l2.5-0.6l2.5-0.3h2.5l2.4,0.2l0.8,0.1
		 M975.3,482.9l0.8-0.4l2.7-0.9l2.6-0.4h2.4l2.1,0.6l1.6,1.1l0.9,1.3l0.6,1.6l0.5,2.4l0.2,2.8 M986.7,495.2v-0.5l0.1-1.3l0.1-1.7
		l0.1-1.6v-1.5v-0.8l-0.1-1.3l-0.3-1.2l-0.3-0.6l-0.6-0.9L985,483l-0.4-0.3l-1.4-0.5l-1.7-0.2h-0.9l-1,0.1 M986.6,528.6L986.6,528.6
		l0.3-0.2l0.1-0.2v-0.4l-0.1-0.6l-0.1-0.8l-0.2-1v-0.2 M896.2,494.7l1-0.1l1.2,0.2l1.2,0.4l1.2,0.7l1.2,0.9l1.2,1.1l1.1,1.4l1.1,1.6
		l1,1.8l1,1.9l0.9,2.1l0.8,2.2l0.7,2.3l0.6,2.4l0.4,2.4l0.3,2.4l0.2,2.4l0.1,2.4l-0.1,2.3l-0.2,2.3l-0.3,2.1l-0.4,2l-0.6,1.9
		l-0.7,1.7l-0.8,1.5l-0.9,1.3l-1,1.1l-1,0.8l-1.1,0.6l-1.1,0.3H903 M848.6,517.9L848.6,517.9l0.8,0.9l0.7,1.1l0.6,1.2l0.5,1.4
		l0.4,1.5l0.3,1.5l0.1,1.5v1.5l-0.1,1.4l-0.3,1.3l-0.4,1.1l-0.2,0.5 M845.4,539.7l0.5-0.1l0.8-0.4l0.8-0.7l0.7-0.9l0.6-1.1l0.5-1.3
		l0.4-1.5l0.3-1.6l0.1-1.7v-1.8l-0.1-1.9l-0.3-1.9l-0.4-1.8l-0.5-1.8l-0.6-1.7l-0.7-1.5l-0.8-1.4l-0.8-1.2l-0.9-1l-0.9-0.7l-0.9-0.5
		l-0.9-0.2h-0.8 M742.6,544.1l-0.1-1.5l-0.2-1.5l-0.3-1.5l-0.4-1.4l-0.5-1.3l-0.6-1.2l-0.7-1l-0.7-0.8l-0.8-0.5l-0.8-0.3h-0.7
		l-0.7,0.2l-0.7,0.5l-0.6,0.7l-0.5,0.9l-0.4,1.1l-0.3,1.3l-0.1,1.4v1.5l0.1,1.5l0.3,1.5l0.4,1.5l0.5,1.4l0.6,1.2l0.7,1.1l0.7,0.9
		l0.7,0.6l0.8,0.4l0.8,0.2l0.7-0.1l0.7-0.4l0.6-0.6l0.5-0.8l0.4-1l0.3-1.2l0.2-1.3L742.6,544.1 M736.7,530.8l0.4-0.6l0.7-0.9
		l0.8-0.7l0.8-0.4l0.9-0.2l0.9,0.1l0.9,0.4l0.9,0.6l0.9,0.9l0.9,1.1l0.8,1.3l0.7,1.5l0.7,1.6l0.6,1.7l0.4,1.8l0.3,1.9l0.2,1.9
		l0.1,1.8 M748.7,544.5l-0.1,1.8l-0.2,1.7l-0.3,1.6l-0.4,1.4l-0.6,1.2l-0.7,1l-0.7,0.8l-0.8,0.5l-0.9,0.3h-0.9l-0.9-0.2l-0.9-0.5
		l-0.9-0.7l-0.3-0.3 M640.1,576.9L640.1,576.9H638l-3-0.2 M618.1,580.2L618.1,580.2l0.1-0.2v-0.1v-0.1v-0.1v-0.1v-0.2v-0.2
		 M635.6,550L635.6,550l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l0.1-0.3 M635.6,575.7L635.6,575.7l-0.2,0.2l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M635.6,550l-2.7,2.2l-1.5,1.8l-1.3,2.1l-0.9,2.2l-0.6,2.3l-0.3,2.4v2.4l0.4,2.3
		l0.8,2.2l1.1,1.9l1.4,1.7l1.7,1.3l1.9,0.9 M635.6,575.7l2.3,0.7l2.1,0.5h0.1 M640.1,547L640.1,547l-2.2,1.5l-2.3,1.6 M592.6,559.2
		l-0.1,1.4l-0.3,2.3l-0.6,2.2l-0.8,2.2l-1,2.1l-1.2,2l-1.3,1.9l-1.5,1.7l-0.6,0.6 M635,549l3-1.2l2-0.8h0.1 M618.1,550.2l0.1,0.1
		v0.2v0.2v0.2v0.2v0.2v0.2v0.2 M690.9,572.8L690.9,572.8 M618.1,550.2l22-3.3 M640.1,547l0.8,2.3l0.7,2.4l0.6,2.5l0.4,2.6l0.3,2.6
		l0.1,2.6v2.5l-0.1,2.4l-0.3,2.3l-0.4,2.2l-0.6,2l-0.7,1.8l-0.8,1.6 M640.1,576.9l-22,3.3 M590,551l0.8,1.4l0.8,1.8l0.5,1.9l0.3,2
		l0.1,2l-0.1,2.1l-0.3,2.1l-0.5,2l-0.8,2l-1,1.9l-1.1,1.8l-1.3,1.6l-1.4,1.5l-1.6,1.3l-1.7,1.1l-1.8,0.9l-1.8,0.6l-1.8,0.4l0,0
		 M582.2,554.6l0.8-0.6l1.7-1.1l1.8-0.9l1.8-0.6l1.8-0.4l1.8-0.2l1.8,0.1l1.8,0.3l1.7,0.6l1.6,0.8l1.4,1l1.3,1.3l1.1,1.4l1,1.6
		l0.8,1.8l0.5,1.9l0.3,2l0.1,2l-0.1,2.1l-0.3,2.1l-0.5,2l-0.8,2l-1,1.9l-1.1,1.8l-1.3,1.6l-1.4,1.5l-1.6,1.3l-1.7,1.1l-1.8,0.9
		l-1.8,0.6l-1.8,0.4l-1.8,0.1l-1.8-0.1l-1.8-0.3L583,584l-1.6-0.8l-1.4-1l-1.3-1.3l-1.1-1.4l-1-1.6l-0.8-1.8l-0.5-1.9l-0.3-2
		l-0.1-1.3 M557.9,587.3l1.9,1.3l2.1,1.2l0.8,0.4 M588.5,532.7l-0.4-0.2l-2.3-0.8l-2.3-0.6 M618.3,579.1l16.7-2.5 M635,576.6l-2-0.9
		l-1.8-1.3l-1.5-1.7l-1.2-2l-0.9-2.2l-0.5-2.4l-0.1-2.6l0.2-2.6l0.6-2.6l1-2.5l1.3-2.3l1.6-2.1l1.5-1.4l1.6-1.2 M635,549l-16.7,2.5
		 M618.3,551.5l-1.5-0.1l-1-0.4l-1-0.6l-1.5-1.5 M613.4,583.2l1-1.3l1.2-1.2l1.6-1.1l1.3-0.4 M574.8,571.8l0.1-2.3l0.3-2.3l0.6-2.2
		l0.8-2.2l1-2.1l1.2-2l1.3-1.9l1.5-1.7l1.6-1.5l1.8-1.3l1.9-1.1l1.9-0.9l2-0.7l2-0.4l2-0.2l2,0.1l1.9,0.3l1.9,0.6l1.8,0.8l1.6,1.1
		l1.5,1.3l1.3,1.5l1.2,1.7l1,1.8l0.8,2l0.6,2.1l0.3,2.2l0.1,2.2l-0.1,2.3l-0.3,2.3l-0.6,2.2l-0.8,2.2l-1,2.1l-1.2,2l-1.3,1.9
		l-1.5,1.7l-1.6,1.5l-1.8,1.3l-1.9,1.1l-1.9,0.9l-2,0.7l-2,0.4l-2,0.2l-2-0.1l-1.9-0.3l-1.9-0.6l-1.8-0.8l-1.6-1.1l-1.5-1.3
		l-1.3-1.5l-1.2-1.7l-1-1.8l-0.8-2l-0.6-2.1l-0.3-2.2L574.8,571.8 M613.4,548.9l-1.5-1.8l-1.7-1.6l-1.8-1.4l-2-1.2l-2.1-1l-2.2-0.8
		l-2.3-0.5l-2.3-0.3h-2.4l-2.4,0.2l-2.4,0.5l-2.4,0.7l-2.3,0.9l-2.3,1.2l-2.2,1.4l-2.1,1.6l-2,1.8l-1.8,2l-1.7,2.1L572,555l-1.4,2.4
		l-1.2,2.5l-1,2.6l-0.8,2.6l-0.5,2.7l-0.3,2.7l0.2,2.5l0.1,2.6l0.3,2.6l0.5,2.5l0.8,2.4l1,2.3l1.2,2.1l1.4,2l1.5,1.8l1.7,1.6
		l1.8,1.4l2,1.2l2.1,1l2.2,0.7l2.3,0.5l2.3,0.3h2.4l2.4-0.2l2.4-0.5l2.4-0.7l2.3-1l2.3-1.2l2.2-1.4l2.1-1.6l2-1.8l1.8-2l1.7-2.1
		l1.5-2.3 M599.7,538.4L599.7,538.4l2.4,0.6l2.3,0.8l2.2,1.1l2,1.3l0.9,0.6 M584.2,599.1l-1.6-0.3l-2.3-0.7l-2.2-1L576,596l-2-1.4
		l0,0 M1005,512.9l0.6-0.6l1.5-1.7l1.3-1.9l1.2-2l1-2.1l0.8-2.2l0.6-2.2l0.3-2.3l0.1-1.4 M997.2,516.5L997.2,516.5l1.9-0.4l1.8-0.6
		l1.8-0.9l1.7-1.1l1.6-1.3l1.4-1.5l1.3-1.6l1.1-1.8l1-1.9l0.8-2l0.5-2l0.3-2.1l0.1-2.1 M1012.4,497.3l-0.1-2l-0.3-2l-0.5-1.9
		l-0.8-1.8l-0.8-1.4 M989.7,527.5l-0.8-1l-0.9-0.9l-1-0.5h-0.3 M994.6,508.2l0.1,1.3l0.3,2l0.5,1.9l0.8,1.8l1,1.6l1.1,1.4l1.3,1.3
		l1.4,1l1.6,0.8l1.7,0.6l1.8,0.3l1.8,0.1l1.8-0.1l1.8-0.4l1.8-0.6l1.8-0.9l1.7-1.1l1.6-1.3l1.4-1.5l1.3-1.6l1.1-1.8l1-1.9l0.8-2
		l0.5-2l0.3-2.1l0.1-2.1 M1025,502.9l-0.1-2l-0.3-2l-0.5-1.9l-0.8-1.8l-1-1.6l-1.1-1.4l-1.3-1.3l-1.4-1l-1.6-0.8l-1.7-0.6l-1.8-0.3
		l-1.8-0.1l-1.8,0.1l-1.8,0.4l-1.8,0.6l-1.8,0.9l-1.7,1.1l-0.8,0.6 M1008.3,469.9l-1.4-0.6l-2.3-0.7l-1.7-0.4 M991.9,526.7l-0.3,0.2
		l-0.3,0.2l-0.3,0.2l-0.3,0.1l-0.3,0.1h-0.3h-0.2l-0.2-0.1 M1003.7,536.4L1003.7,536.4l-2.4-0.6L999,535l-2.2-1.1l-2-1.3l-0.9-0.6
		 M1019.2,475.6l1.6,0.3l2.3,0.7l2.2,1l2.1,1.2l2,1.4l0,0 M991.9,492.3l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.1
		l-0.2-0.1l-0.2-0.1 M986.7,495.2l0.8-0.6l0.9-1.1l0.8-1.3l0.6-1.2 M986.9,524.1v0.2v0.1v0.1v0.1v0.1v0.1v0.1l-0.1,0.1 M970.2,526.6
		l-3.1,1.1l-2.3,0.7h-0.1 M970,525.7L970,525.7v0.2v0.1v0.1v0.1v0.1v0.1v0.1 M986.9,496.4v-0.2V496v-0.2v-0.2v-0.2v-0.2V495
		l-0.1-0.1 M1030.4,503.7l-0.1-2.2l-0.3-2.2l-0.6-2.1l-0.8-2l-1-1.8l-1.2-1.7l-1.3-1.5l-1.5-1.3l-1.6-1.1l-1.8-0.8l-1.9-0.6
		l-1.9-0.3l-2-0.1l-2,0.2l-2,0.4l-2,0.7l-1.9,0.9l-1.9,1.1l-1.8,1.3l-1.6,1.5l-1.5,1.7l-1.3,1.9l-1.2,2l-1,2.1l-0.8,2.2l-0.6,2.2
		l-0.3,2.3l-0.1,2.3l0.1,2.2l0.3,2.2l0.6,2.1l0.8,2l1,1.8l1.2,1.7l1.3,1.5l1.5,1.3l1.6,1.1l1.8,0.8l1.9,0.6l1.9,0.3l2,0.1l2-0.2
		l2-0.4l2-0.7l1.9-0.9l1.9-1.1l1.8-1.3l1.6-1.5l1.5-1.7l1.3-1.9l1.2-2l1-2.1l0.8-2.2l0.6-2.2l0.3-2.3L1030.4,503.7 M986.9,496.4
		l-16.7,2.5 M970.2,498.9l2,0.9l1.8,1.3l1.5,1.7l1.2,2l0.9,2.2l0.5,2.4l0.1,2.6l-0.2,2.6l-0.6,2.6l-1,2.5l-1.3,2.3l-1.6,2.1
		l-1.5,1.4l-1.6,1.2 M970.2,526.6l16.7-2.5 M986.9,524.1l1.5,0.1l1,0.4l1,0.6l1.5,1.5 M991.9,526.7l1.5,1.8l1.7,1.6l1.8,1.4l2,1.2
		l2.1,1l2.2,0.8l2.3,0.5l2.3,0.3h2.4l2.4-0.2l2.4-0.5l2.4-0.7l2.3-0.9l2.3-1.2l2.2-1.4l2.1-1.6l2-1.8l1.8-2l1.7-2.1l1.5-2.3l1.4-2.4
		l1.2-2.5l1-2.6l0.8-2.6l0.5-2.7l0.3-2.7l0.1-2.7l-0.1-2.6l-0.3-2.6l-0.5-2.5l-0.8-2.4l-1-2.3l-1.2-2.1l-1.4-2l-1.5-1.8l-1.7-1.6
		l-1.8-1.4l-2-1.2l-2.1-1l-2.2-0.7l-2.3-0.5l-2.3-0.3h-2.4l-2.4,0.2l-2.4,0.5l-2.4,0.7l-2.3,1l-2.3,1.2l-2.2,1.4l-2.1,1.6l-2,1.8
		l-1.8,2l-1.7,2.1l-1.5,2.3 M991.9,492.3l-1,1.3l-1.2,1.2l-1.6,1.1l-1.3,0.4 M970,500v-0.1v-0.1v-0.1v-0.1v-0.1v-0.1v-0.1v-0.1
		 M964.7,498.5L964.7,498.5l2.4,0.2l3.1,0.3 M970,525.7l2.7-2.2l1.5-1.8l1.3-2.1l0.9-2.2l0.6-2.3l0.3-2.4v-2.4l-0.4-2.3l-0.8-2.2
		l-1.1-1.9l-1.4-1.7L972,501l-2-1 M970,500l-2.5-0.8l-2.8-0.8h-0.1 M964.7,528.4L964.7,528.4l2.8-1.2l2.6-1.5 M900.7,507.2l-0.6-0.4
		l-0.9-0.4l-0.9-0.1l-0.8,0.1l-0.8,0.4l-0.7,0.6l-0.6,0.9l-0.5,1.1l-0.4,1.3l-0.3,1.4l-0.2,1.6l-0.1,1.7l0.1,1.7l0.2,1.7l0.3,1.7
		l0.4,1.7l0.5,1.6l0.6,1.4l0.7,1.3l0.8,1.1l0.8,0.9l0.9,0.6l0.9,0.4l0.9,0.1l0.8-0.1l0.8-0.4l0.7-0.6l0.4-0.5 M898.1,531.2l-0.9-0.5
		l-0.9-0.8l-0.9-1l-0.8-1.2l-0.8-1.4l-0.7-1.6l-0.6-1.7l-0.5-1.8l-0.4-1.8l-0.2-1.9l-0.1-1.9v-1.8l0.2-1.7l0.3-1.6l0.4-1.5l0.5-1.3
		l0.6-1.1l0.7-0.8l0.8-0.6l0.9-0.4l0.9-0.1l0.9,0.2l0.9,0.4l0.9,0.7l0.9,0.9l0.9,1.1l0.8,1.3l0.7,1.5l0.6,1.6l0.5,1.7l0.4,1.8
		l0.3,1.9l0.2,1.9v1.8l-0.1,1.8l-0.2,1.7l-0.4,1.5l-0.5,1.4l-0.6,1.2l-0.7,1l-0.8,0.7l-0.8,0.5l-0.9,0.2h-0.9L898.1,531.2
		 M986.7,525.1l-22,3.3 M964.7,528.4l0.8-1.6l0.7-1.8l0.6-2l0.4-2.2l0.3-2.3l0.1-2.4v-2.5l-0.1-2.6l-0.3-2.6l-0.4-2.6l-0.6-2.5
		l-0.7-2.4l-0.8-2.3 M964.7,498.5l22-3.3 M903.1,541.1L903.1,541.1 M898.1,539l1.2,0.4l1.2,0.2l1.1-0.1l1.1-0.3l1-0.6l1-0.8l0.9-1.1
		l0.8-1.3l0.7-1.5l0.6-1.7l0.5-1.8l0.4-2l0.3-2.1l0.1-2.2v-2.3l-0.1-2.3l-0.3-2.3l-0.4-2.3l-0.5-2.3l-0.6-2.2l-0.7-2.1l-0.8-2
		l-0.9-1.9l-1-1.7l-1-1.5l-1.1-1.3l-1.1-1.1l-1.2-0.9l-1.2-0.6l-1.2-0.4l-1.2-0.2l-1.1,0.1l-1.1,0.3l-1,0.6l-1,0.8l-0.9,1.1
		l-0.8,1.3l-0.7,1.5l-0.6,1.7l-0.5,1.8l-0.4,2l-0.3,2.1l-0.1,2.2v2.3l0.1,2.3l0.3,2.3l0.4,2.3l0.5,2.3l0.6,2.2l0.7,2.1l0.8,2
		l0.9,1.9l1,1.7l1,1.5l1.1,1.3l1.1,1.1l1.2,0.9L898.1,539 M848.6,517.9l-3.3-3.3 M848.7,536.9l2.2-4.1 M841.5,512.9L740,528.1
		 M744,554.8l101.5-15.2 M736.8,549.8l3.3,3.3 M736.7,530.8l-2.2,4.1 M690.9,572.7l0.5-0.1l1.2-0.4l1.1-0.6l1.1-0.8l1-1l0.9-1.2
		l0.8-1.4l0.7-1.6l0.6-1.7l0.5-1.9l0.4-2l0.3-2.1l0.1-2.2v-2.2l-0.1-2.3l-0.2-2.3l-0.3-2.3l-0.4-2.3l-0.5-2.2l-0.7-2.1l-0.7-2
		l-0.8-1.9l-0.9-1.8l-1-1.7l-1.1-1.5l-1.2-1.3l-1.2-1.2l-1.3-1l-1.3-0.7l-1.3-0.5l-1.3-0.2h-1.3l-0.3,0.1 M614,583.8l0.7-1l0.9-1
		l0.6-0.5 M614.6,548.1l-0.7-0.7 M583.4,531.1L583.4,531.1l-2.3-0.3l-2.3-0.1l-2.3,0.1l-2.3,0.4l-2.3,0.6l-2.3,0.8l-2.3,1l-2.2,1.2
		l-2.1,1.4l-2,1.6l-1.9,1.8l-1.8,1.9l-1.7,2.1l-1.5,2.2l-1.4,2.3l-1.2,2.4l-1,2.5l-0.8,2.5l-0.7,2.6l-0.5,2.6l-0.3,2.6l-0.1,2.6
		l0.1,2.6l0.3,2.5l0.5,2.5l0.7,2.4l0.9,2.3l1.1,2.2l1.3,2l1.4,1.9l1.6,1.7l1.7,1.5 M684.1,526.4l-82.2,12.3 M613.7,584.3l77.3-11.6
		 M604.7,540l-16.2-7.3 M562.7,590.3l16.2,7.3 M613.9,547.4l-0.8-1l-1.6-1.7l-1.7-1.5l-0.4-0.3 M584.3,599.2L584.3,599.2l2.3,0.3
		l2.3,0.1l2.3-0.2l2.3-0.4l2.3-0.6l2.3-0.8l2.3-1l2.2-1.2l2.1-1.4l2-1.6l1.9-1.8l1.8-1.9l1.7-2.1l1.4-2.1l0.4-0.6 M1002.8,468.3
		l-2.2-0.3l-2.3-0.1l-2.3,0.2l-2.3,0.4l-2.3,0.6l-2.3,0.8l-2.3,1l-2.2,1.2l-2.1,1.4l-2,1.6l-1.9,1.8l-1.8,1.9L975,481l-1.4,2.1l0,0
		 M986.6,528.6h0.2h0.1 M985.3,528.8l13.4,6 M1024.5,477.2l-16.2-7.3 M1003.9,536.4l2.2,0.3l2.3,0.1l2.3-0.1l2.3-0.4l2.3-0.6
		l2.3-0.8l2.3-1l2.2-1.2l2.1-1.4l2-1.6l1.9-1.8l1.8-1.9l1.7-2.1l1.5-2.2l1.4-2.3l1.2-2.4l1-2.5l0.8-2.6l0.7-2.6l0.5-2.6l0.3-2.6
		l0.1-2.6l-0.1-2.6l-0.3-2.5l-0.5-2.5l-0.7-2.4l-0.9-2.3l-1.1-2.2l-1.3-2l-1.4-1.9l-1.6-1.7l-1.6-1.4 M903.1,541.1l83.5-12.5
		 M979.7,482.2l-83.5,12.5 M898.9,540.4l0.7,0.3l1.3,0.3l1.3,0.1l0.7-0.1 M896.2,494.7l-1,0.2l-1.2,0.5l-1.1,0.7l-1,0.9l-1,1.1
		l-0.9,1.3l-0.8,1.5l-0.7,1.7l-0.6,1.8l-0.4,1.9l-0.3,2l-0.2,2.1l-0.1,2.2v2.2l0.2,2.3l0.3,2.3l0.4,2.3l0.5,2.2l0.6,2.2l0.7,2.1
		l0.8,2l0.9,1.9l1,1.8l1,1.6l1.1,1.4l1.2,1.3l1.2,1.1l1.3,0.8l0.7,0.4"
        />
        <line class="st4" x1="904.6" y1="517.4" x2="851.7" y2="525.3" />
        <line class="st4" x1="738.1" y1="542.5" x2="699.6" y2="548.3" />
      </g>
    </svg>
  ) : (
    <svg
      className="frontCasterLink"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1545 1080"
    >
      <path
        class="st3"
        d="M719.2,563.2l-1,0.5l-0.9,0.7 M715.7,531.2l0.3,0.3l1.4,1.1l1.3,0.6l0.4,0.1 M792.1,555.8l0.2,0l1.1-0.3
	l1.1-0.6l1-0.8l1-1.1l0.9-1.3l0.8-1.5l0.7-1.7l0.6-1.9l0.4-2l0.3-2.1l0.2-2.3l0.1-2.3l-0.1-2.4l-0.2-2.4l-0.3-2.4l-0.4-2.4l-0.6-2.4
	l-0.7-2.3l-0.8-2.2l-0.9-2.1l-1-1.9l-1-1.8l-1.1-1.6l-1.1-1.4l-1.2-1.1l-1.2-0.9l-1.2-0.7l-1.2-0.4l-1.2-0.2l-1,0.1 M712.6,527.7
	l2.7,2l3.6,3.3l0.3,0.4 M719.2,563.2l-3,2.1 M684.6,514.2L684.6,514.2l-2.5-0.3l-2.5-0.1l-2.5,0.2l-2.5,0.4l-2.5,0.7l-2.4,0.9
	l-2.4,1.2l-2.3,1.4l-2.2,1.6l-2.1,1.8l-2,2l-1.8,2.2l-1.7,2.3l-1.5,2.4l-1.3,2.6l-1.1,2.7l-0.9,2.7l-0.7,2.8l-0.4,2.8l-0.2,2.8v2.8
	l0.2,2.7l0.4,2.7l0.7,2.6l0.9,2.5l1.1,2.3l1.3,2.2l1.5,2l1.7,1.8l1.8,1.6l0.1,0.1 M715.1,566.8L715.1,566.8L715.1,566.8l-0.1-0.1
	l-0.1-0.1l-0.2-0.1l-0.2-0.2 M715,530.4L715,530.4l0.1,0.3l0,0.2l-0.1,0.2l-0.1,0.2l-0.2,0.3l-0.2,0.2 M675.1,577.6l-1.8-1.6
	l-1.7-1.8l-1.5-2l-1.3-2.2l-1.1-2.3l-0.9-2.5l-0.7-2.6l-0.4-2.7l-0.2-2.7v-2.8l0.2-2.8l0.4-2.8l0.7-2.8l0.9-2.7l1.1-2.7l1.3-2.6
	l1.5-2.4l1.7-2.3l1.8-2.2l2-2l2.1-1.8l2.2-1.6l2.3-1.4l2.4-1.2l2.4-0.9l2.5-0.7l2.5-0.4l2.5-0.2l2.5,0.1l2.4,0.3 M881.8,541.8
	l-0.2,0.9l-0.4,0.5l-0.7,0.2l-1.1-0.2l-0.8-0.3 M894.9,482.6l-0.7-0.1l-2.4-0.2l-2.5,0l-2.5,0.3l-2.5,0.6l-2.5,0.8l-2.4,1l-2.3,1.3
	l-2.3,1.5l-2.2,1.7l-2,1.9l-1.9,2.1l-1.7,2.2l-1.2,1.7 M886.1,546.3l-1-0.9l-1.7-1.7l-1.6-1.9 M881.8,505.3l1.6-2.4l1.7-2.2l1.9-2.1
	l2-1.9l2.2-1.7l2.3-1.5l2.3-1.3l2.4-1l2.5-0.8l2.5-0.6l2.5-0.3l2.5,0l2.4,0.2l0.8,0.1 M867.4,497.2l0.8-0.4l2.7-0.9l2.6-0.4l2.4,0
	l2.1,0.6l1.6,1.1l0.9,1.3l0.6,1.6l0.5,2.4l0.2,2.8 M878.7,509.5l0.3-3.5l0.1-3.2l-0.2-2.1l-0.5-1.8l-0.7-1.1l-1.1-0.8l-1.3-0.5
	l-1.7-0.2l-1.9,0.2 M878.7,542.8l0.1,0l0.3-0.3l0-1.1l-0.3-1.8l0-0.2 M788.3,509l1-0.1l1.2,0.2l1.2,0.4l1.2,0.7l1.2,0.9l1.2,1.1
	l1.1,1.4l1.1,1.6l1,1.7l1,1.9l0.9,2.1l0.8,2.2l0.7,2.3l0.6,2.4l0.4,2.4l0.3,2.4l0.2,2.4l0.1,2.4l-0.1,2.3l-0.2,2.3l-0.3,2.1l-0.4,2
	l-0.6,1.9l-0.7,1.7l-0.8,1.5l-0.9,1.3l-1,1.1l-1,0.8l-1.1,0.6l-1.1,0.3l-0.2,0 M741.2,559.9L741.2,559.9l-2.1,0l-3-0.2 M719.2,563.2
	l0.1-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.2l0-0.2 M736.7,533.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1 M736.7,558.7L736.7,558.7l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M736.7,533.1l-2.7,2.2
	l-1.5,1.8l-1.3,2.1l-0.9,2.2l-0.6,2.3l-0.3,2.4l0,2.4l0.4,2.3l0.8,2.2l1.1,1.9l1.4,1.7l1.7,1.3l1.9,0.9 M736.7,558.7l2.3,0.7
	l2.2,0.5l0.1,0 M741.2,530L741.2,530l-2.2,1.5l-2.3,1.6 M693.7,542.3l-0.1,1.4l-0.3,2.3l-0.6,2.2l-0.8,2.2l-1,2.1l-1.2,2l-1.3,1.9
	l-1.5,1.7l-0.6,0.6 M736.1,532l3-1.2l2-0.8l0.1,0 M719.2,533.3l0.1,0.1l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M792,555.8
	L792,555.8 M719.2,533.3l22-3.3 M741.2,530l0.8,2.3l0.7,2.4l0.6,2.5l0.4,2.6l0.3,2.6l0.1,2.6v2.5L744,550l-0.3,2.3l-0.4,2.2l-0.6,2
	l-0.7,1.8l-0.8,1.6 M741.2,559.9l-22,3.3 M691.1,534l0.8,1.4l0.8,1.8l0.5,1.9l0.3,2l0.1,2l-0.1,2.1l-0.3,2.1l-0.5,2l-0.8,2l-1,1.9
	l-1.1,1.8l-1.3,1.6l-1.4,1.5l-1.6,1.3l-1.7,1.1l-1.8,0.9l-1.8,0.6l-1.8,0.4l0,0 M683.3,537.6l0.8-0.6l1.7-1.1l1.8-0.9l1.8-0.6
	l1.8-0.4l1.8-0.1l1.8,0.1l1.8,0.3l1.7,0.6l1.6,0.8l1.4,1l1.3,1.3l1.1,1.4l1,1.6l0.8,1.8l0.5,1.9l0.3,2l0.1,2l-0.1,2.1l-0.3,2.1
	l-0.5,2l-0.8,2l-1,1.9l-1.1,1.8l-1.3,1.6l-1.4,1.5l-1.6,1.3l-1.7,1.1l-1.8,0.9l-1.8,0.6l-1.8,0.4l-1.8,0.2l-1.8-0.1l-1.8-0.3
	l-1.7-0.6l-1.6-0.8l-1.4-1l-1.3-1.3l-1.1-1.4l-1-1.6l-0.8-1.8l-0.5-1.9l-0.3-2l-0.1-1.3 M659,570.4l1.9,1.3l2.1,1.2l0.8,0.4
	 M689.6,515.7l-0.4-0.2l-2.3-0.8l-2.3-0.6 M719.4,562.2l16.7-2.5 M736.1,559.7l-2-0.9l-1.8-1.3l-1.5-1.7l-1.2-2l-0.9-2.2l-0.5-2.4
	l-0.1-2.6l0.2-2.6l0.6-2.6l1-2.5l1.3-2.3l1.6-2.1l1.5-1.4l1.6-1.2 M736.1,532l-16.7,2.5 M719.4,534.5l-1.5-0.1l-1-0.4l-1-0.6
	l-1.5-1.5 M714.4,566.3l1-1.3l1.2-1.2l1.6-1.1l1.3-0.4 M675.9,554.9l0.1-2.3l0.3-2.3l0.6-2.2l0.8-2.2l1-2.1l1.2-2l1.3-1.9l1.5-1.7
	l1.6-1.5l1.8-1.3l1.9-1.1l1.9-0.9l2-0.7l2-0.4l2-0.2l2,0.1l1.9,0.3l1.9,0.6l1.8,0.8l1.6,1.1l1.5,1.3l1.3,1.5l1.2,1.7l1,1.8l0.8,2
	l0.6,2.1l0.3,2.2l0.1,2.2l-0.1,2.3l-0.3,2.3l-0.6,2.2l-0.8,2.2l-1,2.1l-1.2,2l-1.3,1.9l-1.5,1.7l-1.6,1.5l-1.8,1.3l-1.9,1.1
	l-1.9,0.9l-2,0.7l-2,0.4l-2,0.2l-2-0.1l-1.9-0.3l-1.9-0.6l-1.8-0.8l-1.6-1.1l-1.5-1.3l-1.3-1.5l-1.2-1.7l-1-1.8l-0.8-2l-0.6-2.1
	l-0.3-2.2L675.9,554.9 M714.4,531.9l-1.5-1.8l-1.7-1.6l-1.8-1.4l-2-1.2l-2.1-1l-2.2-0.8l-2.3-0.5l-2.3-0.3l-2.4,0l-2.4,0.2l-2.4,0.5
	l-2.4,0.7l-2.3,1l-2.3,1.2l-2.2,1.4l-2.1,1.6l-2,1.8l-1.8,2l-1.7,2.1l-1.5,2.3l-1.4,2.4l-1.2,2.5l-1,2.6L669,548l-0.5,2.7l-0.3,2.7
	L668,556l0.1,2.6l0.3,2.6l0.5,2.5l0.8,2.4l1,2.3l1.2,2.1l1.4,2l1.5,1.8l1.7,1.6l1.8,1.4l2,1.2l2.1,1l2.2,0.7l2.3,0.5l2.3,0.3l2.4,0
	l2.4-0.2l2.4-0.5l2.4-0.7l2.3-1l2.3-1.2l2.2-1.4l2.1-1.6l2-1.8l1.8-2l1.7-2.1l1.5-2.3 M700.8,521.4L700.8,521.4l2.4,0.6l2.3,0.8
	l2.2,1.1l2,1.3l0.9,0.6 M685.3,582.2l-1.6-0.3l-2.3-0.7l-2.2-1l-2.1-1.2l-2-1.4l0,0 M897.1,527.2l0.6-0.6l1.5-1.7l1.3-1.9l1.2-2
	l1-2.1l0.8-2.2l0.6-2.2l0.3-2.3l0.1-1.4 M889.2,530.8L889.2,530.8l1.9-0.4l1.8-0.6l1.8-0.9l1.7-1.1l1.6-1.3l1.4-1.5l1.3-1.6l1.1-1.8
	l1-1.9l0.8-2l0.5-2l0.3-2.1l0.1-2.1 M904.4,511.6l-0.1-2l-0.3-2l-0.5-1.9l-0.8-1.8l-0.8-1.4 M881.8,541.8l-0.8-1l-0.9-0.9l-1-0.5
	l-0.3,0 M886.6,522.5l0.1,1.3l0.3,2l0.5,1.9l0.8,1.8l1,1.6l1.1,1.4l1.3,1.3l1.4,1l1.6,0.8l1.7,0.6l1.8,0.3l1.8,0.1l1.8-0.1l1.8-0.4
	l1.8-0.6l1.8-0.9l1.7-1.1l1.6-1.3l1.4-1.5l1.3-1.6l1.1-1.8l1-1.9l0.8-2l0.5-2l0.3-2.1l0.1-2.1 M917.1,517.2l-0.1-2l-0.3-2l-0.5-1.9
	l-0.8-1.8l-1-1.6l-1.1-1.4l-1.3-1.3l-1.4-1l-1.6-0.8l-1.7-0.6l-1.8-0.3l-1.8-0.1l-1.8,0.1l-1.8,0.4l-1.8,0.6l-1.8,0.9l-1.7,1.1
	l-0.8,0.6 M900.3,484.2l-1.4-0.6l-2.3-0.7l-1.7-0.3 M883.9,541l-0.3,0.2l-0.3,0.2l-0.3,0.2l-0.3,0.1l-0.3,0.1l-0.3,0l-0.2,0
	l-0.2-0.1 M895.8,550.6L895.8,550.6l-2.4-0.6l-2.3-0.8l-2.2-1.1l-2-1.3l-0.9-0.6 M911.3,489.9l1.6,0.3l2.3,0.7l2.2,1l2.1,1.2l2,1.4
	l0,0 M883.9,506.6l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.1l-0.2-0.1l-0.2-0.1 M878.7,509.5l0.8-0.6l0.9-1.1l0.8-1.3
	l0.6-1.2 M878.9,538.3l0,0.2l0,0.2l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l-0.1,0.1 M862.2,540.8l-3.1,1.1l-2.3,0.7l-0.1,0 M862,540L862,540
	l0,0.2l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1 M878.9,510.7l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l-0.1-0.1 M922.5,518l-0.1-2.2
	l-0.3-2.2l-0.6-2.1l-0.8-2l-1-1.8l-1.2-1.7l-1.3-1.5l-1.5-1.3l-1.6-1.1l-1.8-0.8l-1.9-0.6l-1.9-0.3l-2-0.1l-2,0.2l-2,0.4l-2,0.7
	l-1.9,0.9l-1.9,1.1l-1.8,1.3l-1.6,1.5l-1.5,1.7l-1.3,1.9l-1.2,2l-1,2.1l-0.8,2.2l-0.6,2.2l-0.3,2.3l-0.1,2.3l0.1,2.2l0.3,2.2
	l0.6,2.1l0.8,2l1,1.8l1.2,1.7l1.3,1.5l1.5,1.3l1.6,1.1l1.8,0.8l1.9,0.6l1.9,0.3l2,0.1l2-0.2l2-0.4l2-0.7l1.9-0.9l1.9-1.1l1.8-1.3
	l1.6-1.5l1.5-1.7l1.3-1.9l1.2-2l1-2.1l0.8-2.2l0.6-2.2l0.3-2.3L922.5,518 M878.9,510.7l-16.7,2.5 M862.2,513.2l2,0.9l1.8,1.3
	l1.5,1.7l1.2,2l0.9,2.2l0.5,2.4l0.1,2.6l-0.2,2.6l-0.6,2.6l-1,2.5l-1.3,2.3l-1.6,2.1l-1.5,1.4l-1.6,1.2 M862.2,540.8l16.7-2.5
	 M878.9,538.3l1.5,0.1l1,0.4l1,0.6l1.5,1.5 M883.9,541l1.5,1.8l1.7,1.6l1.8,1.4l2,1.2l2.1,1l2.2,0.8l2.3,0.5l2.3,0.3l2.4,0l2.4-0.2
	l2.4-0.5l2.4-0.7l2.3-0.9l2.3-1.2l2.2-1.4l2.1-1.6l2-1.8l1.8-2l1.7-2.1l1.5-2.3l1.4-2.4l1.2-2.5l1-2.6l0.8-2.6l0.5-2.7l0.3-2.7
	l0.1-2.7l-0.1-2.6l-0.3-2.6l-0.5-2.5l-0.8-2.4l-1-2.3l-1.2-2.1l-1.4-2l-1.5-1.8l-1.7-1.6l-1.8-1.4l-2-1.2l-2.1-1l-2.2-0.7l-2.3-0.5
	l-2.3-0.3l-2.4,0l-2.4,0.2l-2.4,0.5l-2.4,0.7l-2.3,1l-2.3,1.2l-2.2,1.4l-2.1,1.6l-2,1.8l-1.8,2l-1.7,2.1l-1.5,2.3 M883.9,506.6
	l-1,1.3l-1.2,1.2l-1.6,1.1l-1.3,0.4 M862,514.3l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1 M856.7,512.7L856.7,512.7l2.4,0.2
	l3.1,0.3 M862,540l2.7-2.2l1.5-1.8l1.3-2.1l0.9-2.2l0.6-2.3l0.3-2.4l0-2.4l-0.4-2.3l-0.8-2.2l-1.1-1.9l-1.4-1.7l-1.7-1.3l-1.9-0.9
	 M862,514.3l-2.5-0.8l-2.8-0.8l-0.1,0 M856.7,542.7L856.7,542.7l2.8-1.2l2.6-1.5 M878.7,539.4l-22,3.3 M856.7,542.7l0.8-1.6l0.7-1.8
	l0.6-2l0.4-2.2l0.3-2.3l0.1-2.4v-2.5l-0.1-2.6l-0.3-2.6l-0.4-2.6l-0.6-2.5l-0.7-2.4l-0.8-2.3 M856.7,512.7l22-3.3 M795.2,555.3
	L795.2,555.3 M792,555.8l0.5-0.1l1.2-0.4l1.1-0.6l1.1-0.8l1-1l0.9-1.2l0.8-1.4l0.7-1.6l0.6-1.7l0.5-1.9l0.4-2l0.3-2.1l0.1-2.2l0-2.2
	l-0.1-2.3l-0.2-2.3l-0.3-2.3l-0.4-2.3l-0.6-2.2l-0.6-2.1l-0.8-2.1l-0.8-1.9l-0.9-1.8l-1-1.7l-1.1-1.5l-1.2-1.3l-1.2-1.2l-1.3-1
	l-1.3-0.7l-1.3-0.5l-1.3-0.2l-1.3,0l-0.3,0.1 M715.1,566.8l0.7-1l0.9-1l0.6-0.5 M715.6,531.2l-0.7-0.7 M684.5,514.1L684.5,514.1
	l-2.3-0.3l-2.3-0.1l-2.3,0.1l-2.3,0.4l-2.3,0.6l-2.3,0.8l-2.3,1l-2.2,1.2l-2.1,1.4l-2,1.6l-1.9,1.8l-1.8,1.9l-1.7,2.1l-1.5,2.2
	l-1.4,2.3l-1.2,2.4l-1,2.5l-0.8,2.5l-0.7,2.6l-0.5,2.6l-0.3,2.6l-0.1,2.6l0.1,2.6l0.3,2.5l0.5,2.5l0.7,2.4l0.9,2.3l1.1,2.2l1.3,2
	l1.4,1.9l1.6,1.7l1.7,1.5 M785.1,509.5L703,521.7 M714.7,567.4l77.3-11.6 M705.8,523l-16.2-7.3 M663.8,573.3l16.2,7.3 M715,530.4
	l-0.8-1l-1.6-1.7l-1.7-1.5l-0.4-0.3 M685.4,582.2L685.4,582.2l2.3,0.3l2.3,0.1l2.3-0.2l2.3-0.4l2.3-0.6l2.3-0.8l2.3-1l2.2-1.2
	l2.1-1.4l2-1.6l1.9-1.8l1.8-1.9l1.7-2.1l1.4-2.1l0.4-0.6 M894.8,482.6l-2.2-0.3l-2.3-0.1l-2.3,0.2l-2.3,0.4l-2.3,0.6l-2.3,0.8
	l-2.3,1l-2.2,1.2l-2.1,1.4l-2,1.6l-1.9,1.8l-1.8,1.9l-1.7,2.1l-1.4,2.1l0,0 M878.7,542.9l0.2,0l0.1,0 M877.3,543.1l13.4,6
	 M916.5,491.5l-16.2-7.3 M895.9,550.7l2.2,0.3l2.3,0.1l2.3-0.1l2.3-0.4l2.3-0.6l2.3-0.8l2.3-1l2.2-1.2l2.1-1.4l2-1.6l1.9-1.8
	l1.8-1.9l1.7-2.1l1.5-2.2l1.4-2.3l1.2-2.4l1-2.5l0.8-2.6l0.7-2.6l0.5-2.6l0.3-2.6l0.1-2.6l-0.1-2.6l-0.3-2.5l-0.5-2.5l-0.7-2.4
	l-0.9-2.3l-1.1-2.2l-1.3-2l-1.4-1.9l-1.6-1.7l-1.6-1.4 M795.2,555.3l83.5-12.5 M871.7,496.5L788.3,509 M793.7,555.4l0.7,0l0.7-0.1
	 M788.3,509l-1,0.2l-0.5,0.2"
      />
    </svg>
  )
}

export default FrontCasterLink
