import "./content.scss"

import React, { useState, useEffect } from "react"
import ZoomContent from "../../components/ZoomContent/ZoomContent"

import {
  Main,
  SteeringMount,
  Steering,
  SteeringLink,
  FrontCasterLink,
  Chassis,
  MotorMount,
  SteeringModule,
  ReceiverModule,
  FrontAntiRollBar,
  TopDeck,
  FrontBumperGuard,
  MountingFrontEndMount,
  FrontBumper,
  FrontEndAssembly,
  FrontShock,
  FrontUpperLink,
  FrontSuspension,
  FrontSteeringHub,
  FrontTower,
  FrontBulkhead,
  FrontDifferential,
  BevelGear,
  RearAntiRollBar,
  MountingRearEnd,
  RearBumper,
  RearEndAssembly,
  RearShock,
  RearUpperLink,
  RearSuspension,
  RearHub,
  RearTower,
  RearBulkhead,
  RearDifferential,
  MiddleDifferential,
  BodyMount,
  WheelsMount,
  Wheel,
} from "../Svg"

const Content = ({ subMenu, reset }) => {
  const [explode, setExplode] = useState(true)

  const contenData = {
    explode,
  }

  useEffect(() => {
    // document.getElementById("resetZoom").click()
  }, [reset])

  return (
    <div className="content">
      {subMenu && (
        <div className="content__view">
          <button
            className={explode ? "current" : ""}
            onClick={() => setExplode(!explode)}
          >
            <img
              src={require("../../assets/images/ico-view-1.svg").default}
              alt=""
            />
          </button>
          <button
            className={!explode ? "current" : ""}
            onClick={() => setExplode(!explode)}
          >
            <img
              src={require("../../assets/images/ico-view-2.svg").default}
              alt=""
            />
          </button>
        </div>
      )}
      <div className="content__info">
        <p>
          Use your Mouse Wheel, or Pinch Gesture to alter the level of Zoom and
          Drag the diagram around. Simply click the part numbers to see more
          information on each. You can also use the button below to instantly
          switch between exploded or assembled views.
        </p>
      </div>
      <ZoomContent>
        {subMenu === "steering-mount" ? (
          <SteeringMount {...contenData} />
        ) : subMenu === "steering" ? (
          <Steering {...contenData} />
        ) : subMenu === "steering-link" ? (
          <SteeringLink {...contenData} />
        ) : subMenu === "front-caster-link" ? (
          <FrontCasterLink {...contenData} />
        ) : subMenu === "chassis" ? (
          <Chassis {...contenData} />
        ) : subMenu === "motor-mount" ? (
          <MotorMount {...contenData} />
        ) : subMenu === "steering-module" ? (
          <SteeringModule {...contenData} />
        ) : subMenu === "receiver-module" ? (
          <ReceiverModule {...contenData} />
        ) : subMenu === "front-anti-roll-bar" ? (
          <FrontAntiRollBar {...contenData} />
        ) : subMenu === "top-deck" ? (
          <TopDeck {...contenData} />
        ) : subMenu === "front-bumper-guard" ? (
          <FrontBumperGuard {...contenData} />
        ) : subMenu === "mounting-front-end" ? (
          <MountingFrontEndMount {...contenData} />
        ) : subMenu === "front-bumper" ? (
          <FrontBumper {...contenData} />
        ) : subMenu === "front-end-assembly" ? (
          <FrontEndAssembly {...contenData} />
        ) : subMenu === "front-shock" ? (
          <FrontShock {...contenData} />
        ) : subMenu === "front-upper-link" ? (
          <FrontUpperLink {...contenData} />
        ) : subMenu === "front-suspension" ? (
          <FrontSuspension {...contenData} />
        ) : subMenu === "front-steering-hub" ? (
          <FrontSteeringHub {...contenData} />
        ) : subMenu === "front-tower" ? (
          <FrontTower {...contenData} />
        ) : subMenu === "front-bulkhead" ? (
          <FrontBulkhead {...contenData} />
        ) : subMenu === "front-differential" ? (
          <FrontDifferential {...contenData} />
        ) : subMenu === "bevel-gear" ? (
          <BevelGear {...contenData} />
        ) : subMenu === "rear-anti-roll-bar" ? (
          <RearAntiRollBar {...contenData} />
        ) : subMenu === "mounting-rear-end" ? (
          <MountingRearEnd {...contenData} />
        ) : subMenu === "rear-bumper" ? (
          <RearBumper {...contenData} />
        ) : subMenu === "rear-end-assembly" ? (
          <RearEndAssembly {...contenData} />
        ) : subMenu === "rear-shock" ? (
          <RearShock {...contenData} />
        ) : subMenu === "rear-upper-link" ? (
          <RearUpperLink {...contenData} />
        ) : subMenu === "rear-suspension" ? (
          <RearSuspension {...contenData} />
        ) : subMenu === "rear-hub" ? (
          <RearHub {...contenData} />
        ) : subMenu === "rear-tower" ? (
          <RearTower {...contenData} />
        ) : subMenu === "rear-bulkhead" ? (
          <RearBulkhead {...contenData} />
        ) : subMenu === "rear-differential" ? (
          <RearDifferential {...contenData} />
        ) : subMenu === "middle-differential" ? (
          <MiddleDifferential {...contenData} />
        ) : subMenu === "body-mount" ? (
          <BodyMount {...contenData} />
        ) : subMenu === "wheels-mount" ? (
          <WheelsMount {...contenData} />
        ) : subMenu === "wheel" ? (
          <Wheel {...contenData} />
        ) : (
          <Main />
        )}
      </ZoomContent>
    </div>
  )
}

export default Content
