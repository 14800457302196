import "./steering.scss"

import React from "react"

const Steering = ({ explode }) => {
  return explode ? (
    <svg
      className="steering"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1545 1080"
    >
      <g id="_x31_">
        <path
          class="st0"
          d="M957.4,569.3L957.4,569.3l0.3-0.3l0.2-0.4l0.1-0.5l0-0.5 M946.7,575.5L946.7,575.5l0.3-0.3l0.2-0.4l0.1-0.5
		l0.1-0.5 M915.5,551.7v8.5 M920.1,557.5v-3.2 M940,578.1l0.1-0.1l0.2-0.2l0.1-0.3l0.1-0.4l0-0.5 M916.8,591.3l0.1-0.2l0.2-0.5
		l0.1-0.6 M958.1,567.7l4.3-3.5l1.9-4.2l0.1-1.1 M910.2,563.2v-11.7 M851.9,585.2v2.9 M841.8,593.9v8.8 M846.9,599.8v-2.9
		 M836.8,605.6v-11.7 M793.8,618.7v2.9 M783.7,627.5v8.8 M788.7,633.3v-2.9 M778.6,639.2v-11.7 M725.5,661.1v8.8 M730.6,666.9V664
		 M911.8,595.6L911.8,595.6l0.3-0.3l0.2-0.4l0.1-0.5l0-0.5 M699.6,718.1L699.6,718.1l0.3-0.3l0.2-0.4l0.1-0.5l0-0.5 M720.5,672.7
		v-11.7 M662.2,694.7v2.9 M692.8,720.7l0.1-0.1l0.2-0.2l0.1-0.3l0.1-0.4l0-0.5 M670,732.6l-0.1,0.6l-0.2,0.5l-0.1,0.2 M665.4,736.6
		l-0.1,0.5l-0.1,0.5l-0.2,0.4l-0.3,0.3 M654.7,742.8l0,0.5l-0.1,0.5l-0.2,0.4l-0.3,0.3v0 M618.1,734l0.7,3.2l3.1,3.9l5.3,3l6.8,1.8
		l7.4,0.4l7.2-1.1l6.1-2.5 M927,415.4l-0.1,0.4l-0.2,0.4l-0.3,0.4l-0.4,0.3 M925.9,416.9v-21.3 M914.1,389.8v31 M915.8,419.8v-29.2
		 M920.9,393.1v26.7 M909.8,420.5v-32.8 M922,420.4l-0.1,0.1l-0.9,1.4l-1.1,0.9 M1002.1,373.8L1002.1,373.8l-0.3-0.1l-0.3,0
		l-0.3,0.1l-0.3,0.1 M1000.9,373.8l-72.7-49.6 M658.1,324.7l-1,3.5l-3.3,3.4l-5.3,2.5l-6.5,1.3l-6.8-0.2l-6.3-1.6l-4.8-2.8l-2.8-3.6
		l-0.5-2.4 M961.6,149.6l-1,3.5l-3.3,3.4L952,159l-6.5,1.3l-6.8-0.2l-6.3-1.6l-4.8-2.8l-2.8-3.6l-0.5-2.4 M561.2,588.8l0.1,0.1
		l0.1,0.3l0.1,0.3l0,0.3 M566.1,592.5l0-0.3l0-0.3l0-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.1l-0.1,0 M620.1,576.3l-0.2,0.6
		l-0.3,0.6l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.1,0l-0.2-0.1 M619,577.9l2.6-7.7 M614.1,568.1l-5.2,15.6 M607.3,568.1v4.7 M607.3,572.8
		l1.6-4.7 M543.6,576.4l23.6,11.6 M567.3,588v-19.9 M622.5,568.8l0-0.5l0-0.2 M555.5,568.1l0,0.2l0,0.5 M501.2,534L501.2,534
		l0.3-0.1l0.3,0l0.3,0.1l0.3,0.1 M503.7,576.6l0,0.3l0.1,0.3l0.1,0.2l0.2,0.2 M540,577.5l0,0.3l-0.1,0.3l-0.1,0.2l-0.2,0.1l0,0
		 M540,577.5l19.2-9.4 M556.8,568.1l-1.4,0.7 M625.1,568.1l0.6,0l1-0.1l0.8-0.2l0.6-0.3l0.4-0.4l0.1-0.5 M552.8,568.1l-0.6,0l-1-0.1
		l-0.8-0.2l-0.6-0.3l-0.4-0.4l-0.1-0.5 M603.3,572.4v-4.3 M571.5,568.1v19.6 M561.5,589.8l-1.1-1l-0.5-0.7 M620.9,574l-0.2,1.2
		l-0.6,2.1l-0.4,0.8l0,0.1l-0.4,0.5l-0.4,0.3l-0.4,0.1 M618.7,568.1l45.1,22 M622.5,568.8l-1.4-0.7 M698.7,548.9L698.7,548.9
		l-0.3-0.1l-0.3,0l-0.3,0.1l-0.3,0.1 M553.2,499.3L502.4,534 M697.5,548.9l-72.7-49.6 M526.5,430.8L526.5,430.8l-2.7,1l-3.1,0.4
		l-3.1-0.4l-2.7-1l0,0 M531.3,423.6l0,1.1l-1.2,2.1l-2.3,1.8l-3.1,1.2l-3.5,0.6l-3.6-0.1l-3.2-0.8l-2.5-1.3l-1.5-1.7l-0.3-1.5
		 M531.4,420.7l0.1,0.2l-0.7,2.2l-1.9,1.9l-2.8,1.5l-3.4,0.9l-3.6,0.2l-3.4-0.5l-2.9-1.1l-2-1.6l-0.8-1.9l0.1-0.4 M531.3,417.8
		l-0.2,1.6l-1.4,2.1l-2.4,1.7l-3.2,1.1l-3.6,0.5l-3.5-0.2l-3.1-0.9l-2.4-1.4l-1.3-1.8l-0.1-1.2 M531.3,414.9l0.1,0.7l-1,2.2
		l-2.1,1.9l-2.9,1.4l-3.5,0.7l-3.6,0.1l-3.3-0.6l-2.7-1.2l-1.7-1.7l-0.6-1.9 M531.4,412l-0.4,2l-1.6,2l-2.6,1.6l-3.3,1l-3.6,0.3
		l-3.5-0.4l-3-1l-2.2-1.5l-1.1-1.8l0.1-0.8 M531.3,409v1.1l-1.2,2.1l-2.3,1.8l-3.1,1.3l-3.5,0.6l-3.6-0.1l-3.2-0.8l-2.5-1.3
		l-1.5-1.7l-0.3-1.5 M531.4,406.1l0.1,0.2l-0.7,2.2l-1.9,1.9l-2.8,1.5l-3.4,0.9l-3.6,0.2l-3.4-0.5l-2.9-1.1l-2-1.6L510,408l0.1-0.4
		 M525.8,409.1l-1.7,0.6l-3.6,0.5l-3.5-0.2l-3.1-0.9l-2.4-1.4l-0.2-0.3 M529.5,422.3l-0.1,1.3l-1.2,1.8l-0.6,0.4 M529.5,419.4
		l0.1,0.5l-0.8,1.8l-1.4,1.3 M529.6,416.4l-0.3,1.6l-1.3,1.7l-0.2,0.1 M529.5,413.5l0,0.8l-0.9,1.8l-1,0.9 M529.6,410.6L529.6,410.6
		l-0.5,1.9l-1.4,1.6 M512.3,428.6l0.7,1l2.1,1.3l2.7,0.8l3.1,0.2l3.2-0.4l2.9-1l2.3-1.5l1.4-1.8l0.2-1.5 M512.7,407.8l1.9,1l3.3,0.7
		l3.6,0l3.5-0.6l1.5-0.7 M510.1,407.1l0,0.9l1.2,1.8l2.2,1.5l3,1l3.5,0.3l3.6-0.4l3.3-1l2.6-1.6l1.6-2l0.3-1.9 M510.6,408.1
		l-0.1,0.1l-0.5,1.9l0.7,1.9l1.8,1.6l2.7,1.2l3.4,0.6l3.6-0.1l3.4-0.8l2.9-1.4l2-1.9l0.9-2.2l-0.1-0.5 M510.8,410.9l-0.7,1.3
		l0.2,1.9l1.4,1.8l2.4,1.4l3.2,0.9l3.6,0.2l3.6-0.5l3.1-1.2l2.4-1.7l1.4-2.1l0.1-1.4 M510.6,414l-0.3,0.4l-0.3,1.9l0.9,1.9l2,1.6
		l2.9,1.1l3.4,0.5l3.6-0.2l3.4-0.9l2.7-1.5l1.8-2l0.7-2.2l-0.3-1.3 M510.4,417.6l-0.4,0.9l0.4,1.9l1.6,1.7l2.6,1.3l3.3,0.7l3.6,0
		l3.5-0.6l3-1.3l2.2-1.8l1.1-2.1l-0.1-2.2l-0.4-0.7 M511,419.4l-0.8,1.2l-0.1,1.9l1.1,1.8l2.2,1.5l3,1l3.5,0.3l3.6-0.4l3.3-1
		l2.6-1.6l1.6-2l0.4-2.2l-0.8-2.2l-0.1-0.1 M510.6,422.7l-0.1,0.1l-0.5,1.9l0.7,1.9l1.8,1.6l2.7,1.2l3.4,0.6l3.6-0.1l3.4-0.8
		l2.9-1.4l2-1.9l0.9-2.2l-0.3-2.2l-0.3-0.3 M498.9,394l0.7,1.9l0.7,1.8l0.7,1.7l0.7,1.6l0.7,1.5l0.7,1.4l0.7,1.3l0.7,1.2
		 M498.9,388.6v5.4 M526.5,410l2-0.8l2-0.9l2-1l2-1.1l2-1.2l2-1.3l2-1.4l2-1.5 M542.5,395.4v5.4 M511.8,391.2L511.8,391.2l0.7,2
		l1.9,1.7 M514.4,394.8l2.9,1.1l3.4,0.4l3.4-0.4l2.9-1.1l1.9-1.7l0.7-2 M526.5,404.6v5.4 M504.8,401.2v5.4 M504.8,406.6l2.7,0.7
		l2.7,0.7l2.7,0.6l2.7,0.5l2.7,0.4l2.7,0.3l2.7,0.2l2.7,0.1 M532.8,382.5l1.5,0.8l4,3.4l1.6,4l-1,4.1l-3.5,3.6l-5.5,2.6l-6.7,1.3
		l-7.1-0.2l-6.5-1.7l-5-2.9l-2.9-3.7l-0.3-4.1l2.3-3.9l4.5-3.1l0.2-0.1 M504.8,401.2l-0.7-1.9l-0.7-1.8l-0.7-1.7l-0.7-1.6l-0.7-1.5
		l-0.7-1.4l-0.7-1.3l-0.7-1.2 M526.5,404.6l-2.7-0.7l-2.7-0.7l-2.7-0.6l-2.7-0.5l-2.7-0.4l-2.7-0.3l-2.7-0.2l-2.7-0.1 M542.5,395.4
		l-2,0.8l-2,0.9l-2,1l-2,1.1l-2,1.2l-2,1.3l-2,1.4l-2,1.5 M536.6,382.8l0.7,1.2l0.7,1.3l0.7,1.4l0.7,1.5l0.7,1.6l0.7,1.7l0.7,1.8
		l0.7,1.9 M533.4,381.9l0.6,0.1l2.7,0.7 M498.9,388.6l2-1.5l2-1.4l2-1.3l2-1.2l1.6-0.9 M516.4,360.4l2,0.8l2.3,0.3l2.3-0.3l2-0.8
		l1.3-1.1l0.5-1.3l-0.5-1.4l-1.3-1.1l-2-0.8l-2.3-0.3l-2.3,0.3l-2,0.8l-1.3,1.1l-0.5,1.4l0.5,1.3L516.4,360.4 M688.2,861.6l-1.9,1.6
		l-2.9,1.1l-3.4,0.4l-3.4-0.4l-2.9-1.1 M673.7,863.2l-1.9-1.7l-0.3-0.5 M658.2,846.7l0.7,1.9l0.7,1.8l0.7,1.7l0.7,1.6l0.7,1.5
		l0.7,1.4l0.7,1.3l0.7,1.2 M658.2,846.7v-5.4 M685.8,862.6l2-0.8l2-0.9l2-1l2-1.1l2-1.2l2-1.3l2-1.4l2-1.5 M701.8,853.4V848
		 M664.1,859.2v-5.4 M664.1,859.2l2.7,0.7l2.7,0.7l2.7,0.6l2.7,0.5l2.7,0.4l2.7,0.3l2.7,0.2l2.7,0.1 M685.8,862.6v-5.4 M695.9,835.5
		l0.7,1.2l0.7,1.3l0.7,1.4l0.7,1.5l0.7,1.6l0.7,1.7l0.7,1.8l0.7,1.9 M690.6,834.1l2.6,0.6l2.7,0.8 M658.2,841.3l2-1.5l2-1.4l2-1.3
		l2-1.2l2-1.1l1.2-0.6 M664.1,853.8l-0.7-1.9l-0.7-1.8l-0.7-1.7l-0.7-1.6l-0.7-1.5l-0.7-1.4l-0.7-1.3l-0.7-1.2 M685.8,857.2
		l-2.7-0.8l-2.7-0.7l-2.7-0.6l-2.7-0.5l-2.7-0.4l-2.7-0.3l-2.7-0.2l-2.7-0.1 M701.8,848l-2,0.8l-2,0.9l-2,1l-2,1.1l-2,1.2l-2,1.3
		l-2,1.4l-2,1.5 M669.2,834.6l-1.6,0.7l-4.5,3.2l-2.3,3.9l0.3,4.1l2.9,3.7l5,2.9l6.5,1.7l7.1,0.2l6.7-1.3l5.5-2.6l3.5-3.6l1-4.1
		l-1.6-4l-4-3.4 M693.6,836l-2.9-1.4 M688.9,843.8l-0.7,2l-1.9,1.7l-2.9,1.1L680,849l-3.4-0.4l-2.9-1.1 M673.7,847.5l-1.9-1.7
		l-0.6-1.4 M690.7,840.1l-0.4,2l-1.7,2l-2.6,1.6l-3.3,1l-3.6,0.3l-1.4-0.1 M690.6,837.2l0,1.1l-1.2,2.1l-2.3,1.8l-3.1,1.2l-3.5,0.6
		l-3.6-0.1l-3.2-0.8l-2.5-1.3l-1.5-1.7l-0.3-1.5 M690.7,834.3l0.1,0.2l-0.7,2.2l-1.9,1.9l-2.8,1.5L682,841l-3.6,0.2l-3.4-0.5
		l-2.8-1.1l-1.9-1.6l-0.8-1.9l0.1-0.4 M690.6,831.3l-0.2,1.6L689,835l-2.4,1.7l-3.2,1.1l-3.6,0.5l-3.5-0.2l-3.1-0.9l-2.3-1.4
		l-1.3-1.8l-0.1-1.1 M690.6,828.4l0.1,0.7l-1,2.2l-2.1,1.9l-2.9,1.4l-3.5,0.7l-3.6,0l-3.3-0.6l-2.7-1.2l-1.7-1.7l-0.6-1.9
		 M690.7,825.5l-0.4,2l-1.6,2l-2.6,1.6l-3.3,1l-3.6,0.3l-3.5-0.4l-3-1l-2.2-1.5l-1.1-1.8l0.1-0.8 M690.6,822.6v1.1l-1.2,2.1
		l-2.3,1.8l-3.1,1.3l-3.5,0.6l-3.6-0.1l-3.2-0.8l-2.5-1.3l-1.5-1.7l-0.3-1.5 M690.7,819.7l0.1,0.2l-0.7,2.2l-1.9,1.9l-2.8,1.5
		l-3.4,0.9l-3.6,0.2l-3.4-0.5l-2.9-1.1l-2-1.6l-0.8-1.9l0.1-0.4 M690.7,816.1l-0.2,2.2l-1.4,2.1l-2.4,1.7l-3.2,1.1l-3.6,0.5
		l-3.5-0.2l-3.1-0.9l-2.4-1.4l-1.3-1.8l-0.1-1.2 M669.5,842.3l0.4,0.8l0.6,0.8l0.8,0.7l1,0.7l1.1,0.6l1.3,0.5l1.4,0.4l1.5,0.3
		 M687.4,812.2l2.1,1.7l1.2,2.2 M682.9,820.1l-2.3,0.4l-3,0l-2.8-0.6l-2.4-1.1l-1.5-1.5l-0.6-1.7l0.5-1.7l1.5-1.6l0.7-0.4
		 M682.9,820.1l1.5-0.4l1.4-0.6l1.2-0.7l0.9-0.8l0.6-0.9l0.3-1l0-1l-0.3-1 M690.7,816.1l0,0.9l-0.2,0.9l-0.4,0.8l-0.6,0.8l-0.8,0.8
		l-1,0.7l-1.1,0.6l-1.2,0.5 M685.8,811.3l-2.7-1l-3.1-0.4l-3.1,0.4l-2.7,1l-1.8,1.5l-0.6,1.8l0.6,1.8l1.8,1.5l2.7,1l3.1,0.4l3.1-0.4
		l2.7-1l1.8-1.5l0.6-1.8l-0.6-1.8L685.8,811.3 M669.8,840.3l-0.3,0.6l0,1.4 M669.3,817.7l0.4,1.6l1.6,1.7l2.6,1.3l3.3,0.7l3.6,0.1
		l3.5-0.6l1.2-0.4 M670.3,818.4l-0.8,1.2l-0.1,1.9l1.1,1.8l2.2,1.5l3,1l3.5,0.3l3.6-0.4l3.3-1l2.6-1.6l1.6-2l0.4-2.2l-0.8-2.2
		l-0.2-0.2 M670.2,821.3l-0.4,0.4l-0.5,1.9l0.7,1.9l1.8,1.6l2.7,1.2l3.4,0.6l3.6-0.1l3.4-0.8l2.9-1.4l2-1.9l0.9-2.2l-0.1-0.5
		 M670.1,824.4l-0.7,1.3l0.2,1.9l1.4,1.8l2.4,1.4l3.2,0.9l3.6,0.2l3.6-0.5l3.2-1.2l2.4-1.7l1.4-2.1l0.1-1.5 M670.1,827.3l-0.5,0.6
		l-0.3,1.9l0.9,1.9l2,1.6l2.9,1.1l3.4,0.5l3.6-0.2l3.4-0.9l2.7-1.5l1.8-2l0.7-2.2l-0.3-1.3 M669.7,831.1l-0.4,0.9l0.4,1.9l1.6,1.7
		l2.6,1.3l3.3,0.7l3.6,0l3.5-0.6l3-1.3l2.2-1.8l1.1-2.1l-0.1-2.2l-0.6-1 M670.3,833l-0.8,1.2l-0.1,1.9l1.1,1.8l2.2,1.5l3,1l3.5,0.3
		l3.6-0.4l3.3-1l2.6-1.6l1.6-2l0.4-1.9 M669.9,836.2l-0.1,0.1l-0.5,1.9l0.7,1.9l1.8,1.6l2.7,1.2l3.4,0.6l3.6-0.1l3.4-0.8l2.9-1.4
		l2-1.9l0.9-2.2l-0.3-2.2l-0.3-0.4 M669.5,842.3l1.4,1.8l2.4,1.4l3.2,0.9l3.6,0.2l3.6-0.5l3.2-1.2l2.4-1.7l1.4-2.1l0.1-1.5
		 M685.5,822l2.4-1.9l1.4-2.3l0.1-2.3l-0.8-1.7 M688.8,835.8l0.1,0.5l-0.8,1.8l-1.2,1.1 M688.9,832.9l-0.3,1.6l-1.3,1.7 M688.8,830
		l0,0.8l-0.9,1.8l-1.1,0.9 M688.9,827L688.9,827l-0.5,1.9l-1.3,1.4 M688.8,824.1l-0.1,1.2l-1.1,1.8l-1,0.7 M688.8,821.2l0.1,0.4
		l-0.7,1.8l-1.2,1.2 M688.8,815.4l0,0.7l-0.9,1.8l-1.8,1.5l-2.5,1.1l-2.9,0.6l-3,0l-2.8-0.5l-2.2-1l-1.4-1.4l-0.1-0.5 M991.4,686.8
		l-0.6,0.6l-2.5,1.4l-3.2,0.7l-3.5,0l-3.2-0.8 M978.3,688.7l-2.4-1.4l-0.8-1 M961,673.9l1.2,1.8l1.2,1.7l1.2,1.6l1.2,1.5l1.2,1.4
		l1.2,1.3l1.2,1.2l1.2,1.1 M961,673.9v-5.4 M993,686.7l1.6-1l1.6-1.1l1.6-1.2l1.6-1.3l1.6-1.4l1.6-1.5l1.6-1.6l1.6-1.7 M1005.9,676
		v-5.4 M970.6,685.6v-5.4 M970.6,685.6l2.8,0.5l2.8,0.4l2.8,0.3l2.8,0.2l2.8,0.1l2.8,0l2.8-0.1l2.8-0.2 M993,686.7v-5.4
		 M996.3,658.8l1.2,1.1l1.2,1.2l1.2,1.3l1.2,1.4l1.2,1.5l1.2,1.6l1.2,1.7l1.2,1.8 M994.3,658.5l2,0.3 M961,668.5l1.6-1.7l1.6-1.6
		l1.6-1.5l1.6-1.4l1.6-1.3l1.6-1.2l1.1-0.8 M970.6,680.2l-1.2-1.8l-1.2-1.7l-1.2-1.6l-1.2-1.5l-1.2-1.4l-1.2-1.3l-1.2-1.2l-1.2-1.1
		 M993,681.3l-2.8-0.5l-2.8-0.4l-2.8-0.3l-2.8-0.2l-2.8-0.1l-2.8,0l-2.8,0.1l-2.8,0.2 M1005.9,670.6l-1.6,1l-1.6,1.1l-1.6,1.2
		l-1.6,1.3l-1.6,1.4l-1.6,1.5l-1.6,1.6l-1.6,1.7 M973.2,659.3l-4.6,2.3l-3.5,3.6l-1,4.1l1.6,4l4,3.4l5.8,2.3l6.9,1l7-0.5l6.2-2
		l4.6-3.1l2.3-3.9l-0.3-4.1l-2.8-3.8l-5-2.9 M992.4,668.7l-0.2,1.1l-1.4,1.8l-2.5,1.4l-3.2,0.7l-3.5,0l-3.2-0.8 M978.3,673l-2.4-1.4
		l-1.3-1.9l-0.1-0.4 M994,665.1l0.1,0.8l-1.1,2.2l-2.1,1.8l-3,1.3l-3.5,0.7L983,672 M994.1,662.1l-0.6,2.2l-1.7,2l-2.7,1.5l-3.3,1
		l-3.6,0.3l-3.5-0.4l-2.9-1l-2.1-1.5l-1-1.8l0.1-0.6 M994,659.2l-0.1,1.3l-1.3,2.1l-2.3,1.7l-3.1,1.2l-3.5,0.6l-3.6-0.1l-3.2-0.8
		l-2.5-1.4l-1.4-1.7l-0.2-1.4 M994.1,656.3l0.1,0.4l-0.8,2.2l-1.9,1.9l-2.8,1.4l-3.4,0.8l-3.6,0.1l-3.4-0.5l-2.8-1.2l-1.9-1.6
		l-0.8-1.9l0.1-0.2 M994,653.4l-0.3,1.7l-1.5,2.1l-2.5,1.6l-3.2,1.1l-3.6,0.4l-3.5-0.3l-3.1-0.9l-2.3-1.5l-1.2-1.8l0-1 M994,650.5
		l0.1,0.8l-1,2.1l-2.1,1.8l-3,1.3l-3.5,0.7l-3.6,0l-3.3-0.7l-2.6-1.3l-1.7-1.7l-0.5-1.8 M994.1,647.5l-0.6,2.1l-1.7,2l-2.7,1.5
		l-3.3,1l-3.6,0.3l-3.5-0.4l-2.9-1l-2.1-1.5l-1-1.8l0.1-0.6 M994,644.6l-0.1,1.3l-1.3,2.1l-2.3,1.7l-3.1,1.2l-3.5,0.6l-3.6-0.1
		l-3.2-0.8l-2.5-1.4l-1.5-1.7l-0.2-1.4 M994.1,641.7l0.1,0.4l-0.8,2.2l-1.9,1.9l-2.8,1.4l-3.4,0.8l-3.6,0.1l-3.4-0.5l-2.8-1.2
		l-1.9-1.6l-0.8-1.9l0.1-0.2 M973.5,668.2l0.7,0.8l0.9,0.7l1,0.6l1.2,0.5l1.3,0.4l1.4,0.3l1.5,0.2l1.5,0.1 M990.8,637.2l1.1,0.6
		l1.8,2.1 M987.8,644.6l-2.1,0.6l-3,0.3l-3-0.3l-2.7-0.8l-2-1.3l-1.1-1.6l-0.1-1.8l1-1.7l1.7-1.2 M987.8,644.6l1.4-0.6l1.2-0.7
		l0.9-0.8l0.6-0.9l0.3-1l0-1l-0.3-1l-0.6-0.9 M993.7,639.9l0.3,0.9l0.1,0.9l-0.1,0.9l-0.3,0.9l-0.5,0.8l-0.7,0.8l-0.9,0.7l-1.1,0.6
		 M988.1,635.6l-2.9-0.7l-3.2,0l-3,0.7l-2.3,1.3l-1.3,1.7l-0.1,1.9l1.2,1.7l2.2,1.3l2.9,0.7l3.2,0l3-0.7l2.3-1.3l1.3-1.7l0.1-1.9
		l-1.2-1.7L988.1,635.6 M973.3,665.3l-0.2,1.6l0.5,1.4 M973.8,640.2l-0.9,1.2l-0.1,1.9l1.1,1.8l2.1,1.5l3,1l3.5,0.4l3.6-0.3l3.3-1
		l1.1-0.5 M973.1,644.3l-0.4,1.2l0.6,1.9l1.7,1.7l2.7,1.2l3.3,0.7l3.6,0l3.5-0.7l2.9-1.4l2.1-1.9l1-2.2l-0.1-0.7 M973.6,646.3
		l-0.8,1.4l0.1,1.9l1.3,1.8l2.3,1.4l3.1,0.9l3.5,0.2l3.6-0.5l3.2-1.1l2.5-1.7l1.4-2.1l0.2-1.6 M973.6,649.3l-0.5,0.5l-0.4,1.9
		l0.8,1.9l1.9,1.6l2.8,1.1l3.4,0.5l3.6-0.2l3.4-0.9l2.8-1.5l1.9-1.9l0.8-2.2l-0.1-0.3 M973.6,652l-0.9,1.9l0.3,1.9l1.5,1.7l2.5,1.3
		l3.2,0.8l3.6,0.1l3.5-0.6l3.1-1.2l2.3-1.8l1.2-2.1l0-2.2l-0.6-1.1 M973.5,655.3l-0.6,0.8l-0.1,1.9l1.1,1.8l2.1,1.5l3,1l3.5,0.4
		l3.6-0.3l3.3-1l2.6-1.6l1.7-2l0.5-2.2l-0.7-2.2 M973.1,658.9l-0.4,1.2l0.6,1.9l1.7,1.7l2.7,1.2l3.3,0.7l3.6,0l3.5-0.7l2.9-1.4
		l2.1-1.9l1-2.2l-0.2-2.2l-0.3-0.5 M972.8,663.1l0.1,1.1l1.3,1.8l2.3,1.4l3.1,0.9l3.5,0.2l3.6-0.5l3.2-1.1l2.5-1.7l1.4-2.1l0.3-2.2
		l-0.7-1.7 M973.5,668.2l1.9,1.6l2.8,1.1l3.4,0.5l3.6-0.2l3.4-0.9l2.8-1.5l1.9-1.9l0.8-2.2l-0.1-0.3 M990.4,646.2l1.9-2.1l0.8-2.4
		l-0.5-2.3l-1.2-1.6 M992.3,660.8l-0.2,1.4l-1.2,1.7l-0.8,0.6 M992.2,657.8l0.1,0.6l-0.8,1.8l-1.1,1 M975.8,658.8l-0.4-0.3l-0.9-1.5
		l0-0.6 M992.3,654.9l-0.4,1.7l-1.3,1.6 M992.2,652l0,1l-1,1.8l-1,0.8 M992.3,649.1l0,0.2l-0.6,1.9l-1.3,1.3 M992.2,646.2l-0.1,1.3
		l-1.2,1.8l-0.9,0.7 M992.3,640.3l-0.3,1.6l-1.3,1.7l-2.1,1.4l-2.7,0.9l-3,0.3l-2.9-0.3l-2.5-0.8l-1.8-1.2l-0.7-1.1 M947.5,573.8
		l10.7-6.2 M940.5,576.5l1-0.2l0.9-0.3l0.9-0.3l0.9-0.3l0.9-0.4l0.8-0.4l0.8-0.4l0.8-0.4 M947.5,553.4v20.4 M940,578.1L940,578.1
		 M917.2,590l1.8-2.2l2.2-2.1l2.5-2l2.8-1.8l3.1-1.6l3.4-1.5l3.6-1.3l3.8-1 M940.5,576.5v-20.4 M958.1,567.7v-20.4 M960.5,503.9
		l1.9,1.9l1.9,4.2l-0.6,4.3l-3.1,3.9l-5.3,3l-6.8,1.8l-7.4,0.4l-7.2-1.1l-6.1-2.5l-4.3-3.5l-1.9-4.2l0.6-4.3l3.1-3.9l0.1,0
		 M912.5,594l0.7-0.4l0.7-0.5l0.7-0.5l0.6-0.5l0.6-0.5l0.5-0.5l0.5-0.5l0.4-0.6 M917.2,569.5V590 M936.4,490.3l3,1.2l3.6,0.4
		l3.6-0.4l3-1.2l2-1.7l0.7-2.1l-0.7-2.1l-2-1.7l-3-1.2l-3.6-0.4l-3.6,0.4l-3,1.2l-2,1.7l-0.7,2.1l0.7,2.1L936.4,490.3 M955.4,479.3
		l3.8,3.3l1.3,3.9l-1.3,3.9l-3.8,3.3l-5.7,2.2l-6.7,0.8l-6.7-0.8l-5.7-2.2l-3.8-3.3l-1.3-3.9l1.3-3.9l3.8-3.3l5.7-2.2l6.7-0.8
		l6.7,0.8L955.4,479.3 M925.4,511l1.3,3.9l3.8,3.3 M930.6,518.2l5.7,2.2l6.7,0.8l6.7-0.8l5.7-2.2l3.8-3.3l1.3-3.9 M700.3,716.5
		L912.5,594 M912.5,594v-20.4 M693.3,719.2l1-0.2l0.9-0.3l0.9-0.3l0.9-0.3l0.9-0.4l0.8-0.4l0.8-0.4l0.8-0.4 M700.3,696v20.4
		 M692.8,720.7L692.8,720.7 M670,732.6l1.8-2.2l2.2-2.1l2.5-2l2.8-1.8l3.1-1.6l3.4-1.5l3.6-1.3l3.8-1 M693.3,719.2v-20.4
		 M665.4,736.6l0.7-0.4l0.7-0.5l0.7-0.5l0.6-0.5l0.6-0.5l0.5-0.5l0.5-0.5l0.4-0.6 M670,712.2v20.4 M654.7,742.8l10.7-6.2
		 M665.4,736.6v-20.4 M733.1,653.8l45.5-26.3 M778.6,627.5l0.5-0.3l0.6-0.2l0.7-0.1l0.7,0l0.7,0l0.7,0.1l0.6,0.2l0.5,0.3
		 M783.7,627.5l5.1,2.9 M788.7,630.4l0.4,0.3l0.3,0.4l0.2,0.4l0.1,0.4l-0.1,0.4l-0.2,0.4l-0.3,0.4l-0.4,0.3 M788.7,633.3l-45.5,26.3
		 M743.2,659.6l-10.1-5.8 M662.2,694.7l58.3-33.6 M720.5,661.1l0.5-0.3l0.6-0.2l0.7-0.1l0.7,0l0.7,0l0.7,0.1l0.6,0.2l0.5,0.3
		 M725.5,661.1l5.1,2.9 M730.6,664l0.4,0.3l0.3,0.4l0.2,0.4l0.1,0.4l-0.1,0.4l-0.2,0.4l-0.3,0.4l-0.4,0.3 M730.6,666.9l-58.3,33.6
		 M672.3,700.5l-0.6,0.3l-0.7,0.2l-0.7,0.1l-0.7,0l-0.7-0.1l-0.7-0.2l-0.6-0.2l-0.5-0.3 M667,700.4l-0.5-0.4l-0.5-0.4l-0.6-0.3
		l-0.6-0.3l-0.6-0.3l-0.6-0.3L663,698l-0.6-0.3 M662.4,697.7l-0.5-0.3l-0.4-0.4l-0.3-0.4l-0.1-0.4l0-0.4l0.2-0.4l0.3-0.4l0.5-0.3
		 M862,591l58.3-33.6 M862,591l-0.5,0.3l-0.6,0.2l-0.7,0.1l-0.7,0l-0.7,0l-0.7-0.1l-0.6-0.2L857,591 M851.9,588.1l5.1,2.9
		 M851.9,588.1l-0.4-0.3l-0.3-0.4L851,587l-0.1-0.4l0.1-0.4l0.2-0.4l0.3-0.4l0.4-0.3 M910.2,551.5l-58.3,33.6 M910.2,551.5l0.6-0.3
		l0.7-0.2l0.7-0.1l0.7,0l0.7,0.1l0.7,0.1l0.6,0.2l0.5,0.3 M920.1,554.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3
		L916,552l-0.5-0.4 M920.1,554.3l0.5,0.3l0.4,0.4l0.3,0.4l0.1,0.4l0,0.4l-0.2,0.4l-0.3,0.4l-0.5,0.3 M841.8,593.9l5.1,2.9
		 M846.9,596.9l0.4,0.3l0.3,0.4l0.2,0.4l0.1,0.4l-0.1,0.4l-0.2,0.4l-0.3,0.4l-0.4,0.3 M846.9,599.8l-43,24.8 M803.9,624.6l-0.5,0.3
		l-0.6,0.2l-0.7,0.1l-0.7,0l-0.7,0L800,625l-0.6-0.2l-0.5-0.3 M798.8,624.6l-5.1-2.9 M793.8,621.7l-0.4-0.3L793,621l-0.2-0.4
		l-0.1-0.4l0.1-0.4l0.2-0.4l0.3-0.4l0.4-0.3 M793.8,618.7l43-24.8 M836.8,593.9l0.5-0.3l0.6-0.2l0.7-0.1l0.7,0l0.7,0l0.7,0.1
		l0.6,0.2l0.5,0.3 M917.5,537.6l-1.4,2.1l-1.8,2l-2.2,1.8l-2.6,1.7l-2.9,1.5l-3.2,1.3l-3.4,1l-3.6,0.8 M896.5,549.8l-1.2,0.2
		l-1.2,0.3l-1.2,0.3l-1.1,0.4l-1.1,0.4l-1,0.4l-1,0.5l-0.9,0.5 M887.7,552.8L664.4,681.7 M664.4,681.7l-0.9,0.5l-0.8,0.6l-0.8,0.6
		l-0.7,0.6l-0.4,0.4 M654.7,722.4l10.7-6.2 M665.4,716.2l0.7-0.4l0.7-0.5l0.7-0.5l0.6-0.5l0.6-0.5l0.5-0.5l0.5-0.5l0.4-0.6
		 M670,712.2l1.8-2.2l2.2-2.1l2.5-2l2.8-1.8l3.1-1.6l3.4-1.5l3.6-1.3l3.8-1 M693.3,698.7l1-0.2l0.9-0.3l0.9-0.3l0.9-0.3l0.9-0.4
		l0.8-0.4l0.8-0.4l0.8-0.4 M700.3,696l212.2-122.5 M912.5,573.6l0.7-0.4l0.7-0.5l0.7-0.5l0.6-0.5l0.6-0.5l0.5-0.5l0.5-0.5l0.4-0.6
		 M940.5,556.1l-3.8,1l-3.6,1.3l-3.4,1.5l-3.1,1.6l-2.8,1.8l-2.5,2l-2.2,2.1l-1.8,2.2 M940.5,556.1l1-0.2l0.9-0.3l0.9-0.3l0.9-0.3
		l0.9-0.4l0.8-0.4l0.8-0.4l0.8-0.4 M947.5,553.4l10.7-6.2 M921.5,538.5l0.7,3.2l3.1,3.9l5.3,3l6.8,1.8l7.4,0.4l7.2-1.1l6.1-2.5
		 M921.5,533.4l-0.4,0.3l-0.8,0.6l-0.7,0.6l-0.6,0.6l-0.6,0.7l-0.5,0.7l-0.4,0.7 M654.7,722.4v20.4 M654.7,722.4l4.3-3.5l1.9-4.2
		l0.1-1.1 M657.1,679l1.9,1.9l1.9,4.2l-0.6,4.3l-3.1,3.9l-5.3,3l-6.8,1.8l-7.4,0.4l-7.2-1.1l-6.1-2.5l-4.3-3.5l-1.9-4.2l0.7-4.3
		l3.1-3.9l0.1,0 M633,665.4l-2-1.7l-0.7-2.1l0.7-2.1l2-1.7l3-1.2l3.6-0.4l3.6,0.4l3,1.2l2,1.7l0.7,2.1l-0.7,2.1l-2,1.7l-3,1.2
		l-3.6,0.4l-3.6-0.4L633,665.4 M651.9,654.5l3.8,3.3l1.3,3.9l-1.3,3.9l-3.8,3.3l-5.7,2.2l-6.7,0.8l-6.7-0.8l-5.7-2.2l-3.8-3.3
		l-1.3-3.9l1.3-3.9l3.8-3.3l5.7-2.2l6.7-0.8l6.7,0.8L651.9,654.5 M622,686.2l1.3,3.9l3.8,3.3 M627.1,693.3l5.7,2.2l6.7,0.8l6.7-0.8
		l5.7-2.2l3.8-3.3l1.3-3.9 M919.9,422.9l0-0.3l0.1-0.3l0.1-0.3l0.1-0.2l0.1-0.2l0.2-0.2l0.2-0.2l0.2-0.1 M915.8,424l1.8-1l1.7-1
		l1.6-0.9 M920.8,421.1l4.4-2.6 M915.3,425.5l0-0.3l0-0.2l0-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.1l0.1-0.1 M925.2,418.5l0.2-0.1
		l0.1-0.1l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0-0.3l0-0.3 M925.2,418.5l-5.1,2.9 M915.8,424l-0.6,0.3l-0.2,0l-0.5,0l-0.1-0.1l-0.4-0.4
		l-0.2-1l0.3-1.7l0.1-0.3 M920.1,421.4l-4.4,2.6 M920.1,421.4l0.2-0.1l0.1-0.1l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0-0.3l0-0.3
		 M920.1,421.4l-0.5,0.2l-0.6,0.1l-0.6,0l-0.6-0.2l-0.6-0.3l-0.5-0.4l-0.5-0.5l-0.4-0.6 M920.9,419.8l5.1-2.9 M914.1,420.8l1.7-1
		 M909.8,420.5l0.5-0.1l0.6-0.1l0.6,0l0.6,0l0.6,0.1l0.5,0.1l0.5,0.2l0.4,0.2 M920.9,419.8l-0.5,0.3l-0.6,0.2l-0.7,0.1l-0.7,0
		l-0.7,0l-0.7-0.1l-0.6-0.2l-0.5-0.3 M967.7,416L967.7,416l-0.2-0.2l-0.1-0.2l-0.1-0.3l0-0.3 M876.7,253.6l6.3-2.5l7.4-1.2l7.7,0.4
		l7,1.9l5.4,3.1l3.3,4l0.7,4.4l-2,4.3l-4.4,3.6l-6.3,2.5l-7.4,1.2l-7.7-0.4l-7-1.9l-5.4-3.1l-3.3-4l-0.7-4.4l2-4.3L876.7,253.6
		 M919.9,422.9l-1.4,0.8l-1.5,0.9l-1.6,0.9 M915.3,425.5l-1.4,0.5l-1.3-0.1l-1.1-0.6l-1.1-1.5l-0.6-2.8l0-0.5 M875.1,370.7l-2.5-1.3
		l-3.2-2.2l-0.1-0.1 M928.1,443.7l-0.2,2.1l-2.3,5.5l-4.8,4.9l-6.9,4l-8.5,2.8l-9.5,1.3l-9.7-0.2l-9.3-1.7l-8.1-3.1l-6.4-4.2
		l-4.2-5.1l-1.7-5.5l0-0.7 M917.7,277.2l-7.8,3.4l-9.1,2.1l-9.7,0.6l-9.6-1l-8.8-2.4l-7.3-3.7l-5.3-4.7l-2.9-5.4l-0.3-5.6l2.3-5.5
		l4.8-4.9l6.9-4l8.5-2.8l9.5-1.3l9.7,0.2l9.3,1.7l8.1,3.1l6.4,4.2l4.2,5.1l1.7,5.5l-1,5.6l-3.6,5.2L917.7,277.2 M869.3,347.3v19.9
		 M869.3,367.2l98,47.8 M869.3,347.3L869.3,347.3l3.3,0.6l3.5,0.5l1.9,0.3l2,0.4 M967.3,395.1l-98-47.8 M967.3,395.1V415 M967.3,415
		l7.1,2.4l8.1,1l8.2-0.6l7.4-2.1l5.8-3.4l3.6-4.3l1-4.1 M1002.5,374.8l0-0.3l-0.1-0.3l-0.2-0.2l-0.2-0.1 M967.3,395.1l0-0.4l0.1-0.4
		l0.1-0.4l0.2-0.4l0.2-0.4l0.2-0.3l0.3-0.3l0.3-0.2 M1002.5,374.8l4.2,4.1l1.7,4.7l-1,4.7l-3.6,4.3l-5.8,3.4l-7.4,2.1l-8.2,0.6
		l-8.1-1l-7.1-2.4 M955.6,366.3l-5.8,2.2l-6.8,0.8l-6.8-0.8l-5.8-2.2l-3.9-3.3l-1.4-4l1.4-3.9l3.9-3.4l5.8-2.2l6.8-0.8l6.8,0.8
		l5.8,2.2l3.9,3.4l1.4,3.9l-1.4,4L955.6,366.3 M990,386.1l-3,1.2l-3.6,0.4l-3.6-0.4l-3-1.2l-2-1.7l-0.7-2.1l0.7-2.1l2-1.7l3-1.2
		l3.6-0.4l3.6,0.4l3,1.2l2,1.7l0.7,2.1l-0.7,2.1L990,386.1 M880,349.2l9.9,1.2L900,350l9.5-2l8.1-3.5l6.1-4.7l3.6-5.5l0.8-4.4
		 M880,349.2l88.6,43.3 M968.6,392.4l7.4,2.4l8.4,0.7l8.2-1.1l7-2.8l4.8-4l1.9-4.8l-1.2-4.8l-4.2-4.2 M645.3,392.6l-2.5,0.9
		l-3.3,0.4l-3.3-0.4l-2.5-0.9 M645.6,313.7v3.5 M633.5,313.7v3.5 M639.5,317.2v3.5 M628.8,387.3l0.8,2.4l2.3,2 M631.9,391.6l3.5,1.3
		l4.1,0.5l4.1-0.5l3.5-1.3l2.3-2l0.8-2.4 M642.6,318.9v3.5 M636.5,318.9v3.5 M630.4,315.4l3-1.8 M633.5,313.7l6.1,3.5 M639.5,317.2
		l6.1-3.5 M645.6,313.7l3,1.8 M648.6,315.4l-6.1,3.5 M642.6,318.9l6.1,3.5 M648.6,322.4l-3,1.8 M645.6,324.2l-6.1-3.5 M639.5,320.7
		l-6.1,3.5 M633.5,324.2l-3-1.8 M630.4,322.4l6.1-3.5 M636.5,318.9l-6.1-3.5 M647.6,314.3l-3.7-1.4l-4.4-0.5l-4.4,0.5l-3.7,1.4
		l-2.5,2.1l-0.9,2.5l0.9,2.5l2.5,2.1l3.7,1.4l4.4,0.5l4.4-0.5l3.7-1.4l2.5-2.1l0.9-2.5l-0.9-2.5L647.6,314.3 M614.5,330.6l1,4.1
		l3.6,4.3l5.8,3.4l7.4,2.1l8.2,0.6l8.1-1l7.1-2.4l5.3-3.6l3-4.4l0.5-3 M658.1,327.7l-0.2,1.5l-2.2,3.7l-4.4,3l-6,1.9l-6.8,0.5
		l-6.7-0.9l-5.6-2.2 M626.4,335.3l-3.9-3.3l-1.6-3.8l0-0.5 M624.3,316.2l-3.9,2.1l-4.2,4.1l-1.7,4.7l1,4.7l3.6,4.3l5.8,3.4l7.4,2.1
		l8.2,0.6l8.1-1l7.1-2.4l5.3-3.6l3-4.4l0.3-4.8l-2.4-4.6l-4.8-3.9 M657.2,317.5l-2.8-1.4 M948.8,217.5l-2.5,0.9l-3.3,0.4l-3.3-0.4
		l-2.5-0.9 M949,138.6v3.5 M936.9,138.6v3.5 M943,142.1v3.5 M932.2,212.1l0.8,2.4l2.3,2 M935.4,216.5l3.5,1.3l4.1,0.5l4.1-0.5
		l3.5-1.3l2.3-2l0.8-2.4 M946,143.8v3.5 M939.9,143.8v3.5 M933.9,140.3l3-1.8 M936.9,138.6l6.1,3.5 M943,142.1l6.1-3.5 M949,138.6
		l3,1.8 M952.1,140.3l-6.1,3.5 M946,143.8l6.1,3.5 M952.1,147.3l-3,1.8 M949,149.1l-6.1-3.5 M943,145.6l-6.1,3.5 M936.9,149.1
		l-3-1.8 M933.9,147.3l6.1-3.5 M939.9,143.8l-6.1-3.5 M951.1,139.1l-3.7-1.4l-4.4-0.5l-4.4,0.5l-3.7,1.4l-2.5,2.1l-0.9,2.5l0.9,2.5
		l2.5,2.1l3.7,1.4l4.4,0.5l4.4-0.5l3.7-1.4l2.5-2.1l0.9-2.5l-0.9-2.5L951.1,139.1 M917.9,155.5l1,4.1l3.6,4.3l5.8,3.4l7.4,2.1
		l8.2,0.6l8.1-1l7.1-2.4l5.3-3.6l3-4.4l0.5-3 M961.6,152.6l-0.2,1.5l-2.2,3.7l-4.4,3l-6,1.9l-6.8,0.5l-6.7-0.9l-5.6-2.2
		 M929.8,160.2l-3.9-3.3l-1.6-3.8l0-0.5 M927.7,141.1l-3.9,2.1l-4.2,4.1l-1.7,4.7l1,4.7l3.6,4.3l5.8,3.4l7.4,2.1l8.2,0.6l8.1-1
		l7.1-2.4l5.3-3.6l3-4.4l0.3-4.8l-2.4-4.6l-4.8-3.9 M960.7,142.4l-2.8-1.4 M560.5,588l1.6,0.9l1.7,1l1.8,1 M618.4,579l0.1-0.1
		l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M609.2,584.3L609.2,584.3l-0.1-0.2l-0.1-0.1L609,584l0-0.1l0-0.1l0-0.1
		l0-0.1 M571.5,587.7l-0.5-0.1l-0.6-0.1l-0.6,0l-0.6,0l-0.6,0.1l-0.5,0.1l-0.5,0.2l-0.4,0.2 M608.9,583.7l3.8-3.2l3.4-2l3-0.7
		 M603.3,572.4l0.5-0.1l0.5-0.1l0.5,0l0.5,0l0.5,0.1l0.5,0.1l0.5,0.2l0.4,0.2 M608.9,583.7l-0.4,0.4l-0.2,0.1l-0.2,0.1l-0.2,0
		l-0.2-0.1l-0.4-0.4l-0.4-1.1l-0.2-4.1l0.3-3.5l0.3-2.4 M567.3,588l0.4,1.7l-0.2,1l-0.5,0.5l-0.4,0.1l-0.3,0l-0.6-0.2l-0.2-0.1
		 M586.7,536l0.5-0.2l0.6-0.2l0.6-0.1l0.6,0l0.6,0l0.6,0.1l0.6,0.2l0.5,0.2 M591.3,534.8l-0.5-0.2l-0.6-0.2l-0.6-0.1l-0.6,0l-0.6,0
		l-0.6,0.1l-0.6,0.2l-0.5,0.2 M502.3,575.7l0,0.3l0.1,0.3l0.1,0.2l0.2,0.2 M631,567.1l-0.2-0.1l-0.3-0.1l-0.3-0.1l-0.3-0.1l-0.3-0.1
		l-0.3,0l-0.3,0l-0.3,0 M549.2,566.6l-0.3,0l-0.3,0l-0.3,0l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.2,0.1 M589,540.5l0.4-0.4
		l0.4-0.5l0.4-0.5l0.3-0.6l0.3-0.6l0.2-0.7l0.1-0.6l0-0.6 M589,540.5l-0.5-0.4l-0.4-0.5l-0.4-0.5l-0.3-0.6l-0.3-0.6l-0.2-0.7
		l-0.1-0.6l0-0.6 M622.5,551.3l0,0.6l-0.1,0.6l-0.2,0.7l-0.3,0.6l-0.3,0.6l-0.4,0.5l-0.4,0.5l-0.4,0.4 M631,561.2l-0.2-0.1l-0.3-0.1
		l-0.3-0.1l-0.3-0.1l-0.3-0.1l-0.3,0l-0.3,0l-0.3,0 M557.7,555.8l-0.4-0.4l-0.4-0.5l-0.4-0.5l-0.4-0.6l-0.3-0.6l-0.2-0.6l-0.1-0.6
		l0-0.6 M549.2,560.7l-0.3,0l-0.3,0l-0.3,0l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.2,0.1 M494.4,564.8l0,0.6l0.7,2.9l1.6,2.7
		l2.4,2.5l3.2,2.2 M502.3,555.9v19.9 M502.3,575.7l1.5,0.9 M500.7,534.9l0-0.3l0.1-0.3l0.2-0.2l0.2-0.1 M502.3,555.9l-3.2-2.2
		l-2.4-2.5l-1.6-2.7l-0.7-2.8l0.3-2.9l1.2-2.8l2-2.6l2.8-2.4 M502.3,555.9l0-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.3-0.3l0.3-0.3
		l0.3-0.2 M503.7,556.7l-1.5-0.9 M538.6,578.3l-3.8,1.5l-4.2,1.1l-4.5,0.6l-4.6,0.1l-4.5-0.4l-4.3-0.8l-4-1.3l-3.5-1.7 M504.2,577.6
		l0.2,0.1l0.3,0l0.3-0.1l0.3-0.1 M539.6,578.4l-0.2,0.1h-0.2l-0.3-0.1l-0.3-0.2 M555.4,568.8l-1.5,0.6l-1.4,0.4l-1.3,0.2l-1.2,0
		l-1-0.2l-0.8-0.4l-0.6-0.6l-0.4-0.8l-0.1-1 M546.9,567.1v-5.8 M546.9,561.2l0.2-1.2l0.5-1.3l0.8-1.4l1-1.4l1.3-1.4l1.5-1.3l1.6-1.1
		l1.7-0.9 M555.4,551.3l31.3-15.3 M586.7,536v-1.2 M586.7,534.8l0-0.4l-0.1-0.4l-0.1-0.4l-0.2-0.4L586,533l-0.2-0.3l-0.3-0.3
		l-0.3-0.2 M586.7,534.8L540,557.6 M503.7,576.6v-19.9 M503.7,576.6l3.8,1.8l4.3,1.4l4.7,0.9l4.9,0.4l5-0.1l4.8-0.7l4.5-1.2l4.1-1.6
		 M540,557.6v19.9 M589,540.5l-31.3,15.3 M620.2,555.8L589,540.5 M540,557.6l0-0.4l-0.1-0.4l-0.1-0.4l-0.2-0.4l-0.2-0.4l-0.2-0.3
		l-0.3-0.3l-0.3-0.2 M503.7,556.7l0-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.3-0.3l0.3-0.3l0.3-0.2 M540,557.6l-4.1,1.6l-4.5,1.2
		l-4.8,0.7l-5,0.1l-4.9-0.4l-4.7-0.9l-4.3-1.4l-3.8-1.8 M663.8,590.1l0,0.3l0.1,0.3l0.1,0.2l0.2,0.1l0,0 M573.3,428.7l6.3-2.6
		l7.4-1.2l7.7,0.4l7,1.9l5.4,3.1l3.2,4l0.7,4.4l-2,4.3l-4.4,3.6l-6.3,2.5l-7.4,1.2l-7.7-0.4l-7-1.9l-5.4-3.1l-3.3-4l-0.7-4.4l2-4.3
		L573.3,428.7 M618.4,579l-2.7,0.5l-3.1,1.8l-3.4,3 M609.2,584.3l-0.7,0.6l-0.1,0.1l-0.7,0.3l-0.7,0l-0.8-0.4l-1-1.2l-1.5-4.5
		l-0.5-6.7 M571.5,587.7l-0.4,2.8l-1,1.6l-1.2,0.8l-0.2,0.1l-1.4,0.1l-1.3-0.5 M566.1,592.5l-1.6-0.9l-1.5-0.9l-1.4-0.8
		 M624.7,618.8l-0.2,2.1l-2.3,5.5l-4.8,4.9l-6.9,4l-8.5,2.8l-9.5,1.3l-9.7-0.2l-9.3-1.7l-8.1-3.1l-6.4-4.2l-4.2-5.1l-1.7-5.5l0-0.7
		 M614.2,452.4l-7.8,3.4l-9.1,2.1l-9.7,0.6l-9.6-1l-8.8-2.4l-7.3-3.7l-5.3-4.7l-2.9-5.4l-0.3-5.6l2.3-5.5l4.8-4.9l6.9-4l8.5-2.8
		l9.5-1.3l9.7,0.2l9.3,1.7l8.1,3.1l6.4,4.2l4.2,5.1l1.7,5.5l-1,5.6l-3.6,5.2L614.2,452.4 M549.2,560.7v5.8 M549.2,566.6h79.5
		 M628.7,566.6v-5.8 M591.3,534.8v1.2 M591.3,536l31.3,15.3 M622.5,551.3l1.5,0.8l1.4,1l1.3,1.1l1.2,1.2l1,1.2l0.8,1.2l0.6,1.2
		l0.4,1.2l0.1,1.1 M631,561.2v5.8 M631,567.1l-0.2,1.1l-0.5,0.9l-0.8,0.6l-1.1,0.4l-1.3,0.1l-1.5-0.2l-1.6-0.4l-1.6-0.7
		 M591.3,534.8l0-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.2-0.3l0.3-0.3l0.3-0.2 M663.8,570.2l-72.6-35.4 M628.7,560.7l-0.2-0.6
		l-0.5-0.7l-0.8-0.7l-1-0.7l-1.3-0.7l-1.5-0.6l-1.6-0.6l-1.7-0.5 M557.7,555.8h62.5 M549.2,560.7l0.2-0.6l0.5-0.7l0.8-0.7l1.1-0.7
		l1.3-0.7l1.5-0.6l1.6-0.6l1.7-0.5 M628.7,560.7h-79.5 M663.8,570.2v19.9 M663.8,590.1l7.1,2.4l8.1,1l8.2-0.6l7.4-2.1l5.8-3.4
		l3.6-4.3l1-4.1 M699.1,549.9l0-0.3l-0.1-0.3l-0.2-0.2l-0.2-0.1 M663.8,570.2l0-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.2-0.3
		l0.3-0.3l0.3-0.2 M699.1,549.9l4.2,4.1l1.7,4.7l-1,4.7l-3.6,4.3l-5.8,3.4l-7.4,2.1l-8.2,0.6l-8.1-1l-7.1-2.4 M553.2,504.9l0.7,4
		l3.2,5.3l5.6,4.6l7.6,3.6l9,2.3l9.7,0.8l9.7-0.8l9-2.3l7.6-3.6l5.6-4.6l3.2-5.3l0.7-4 M502.4,534l-2.6,2.2l-1.9,2.4l-1.1,2.6
		l-0.2,2.6l0.6,2.6l1.4,2.5l2.2,2.3l2.9,2 M503.8,553.2l1.5,0.9 M505.3,554.1l3.5,1.7l4,1.3l4.3,0.8l4.5,0.4l4.6-0.1l4.5-0.6
		l4.2-1.1l3.8-1.5 M538.6,554.9l46.6-22.8 M585.3,532.1l0.8-0.3l0.9-0.2l1-0.2l1-0.1l1,0.1l1,0.2l0.9,0.2l0.8,0.3 M592.7,532.1
		l72.6,35.4 M665.2,567.5l7.4,2.4l8.4,0.7l8.2-1.1l7-2.8l4.8-4l1.9-4.8l-1.2-4.8l-4.2-4.2 M527.3,548.1l2-1.7l0.7-2.1l-0.7-2.1
		l-2-1.7l-3-1.2l-3.6-0.4l-3.6,0.4l-3,1.2l-2,1.7l-0.7,2.1l0.7,2.1l2,1.7l3,1.2l3.6,0.4l3.6-0.4L527.3,548.1 M652.2,541.4l-5.8,2.2
		l-6.8,0.8l-6.8-0.8l-5.8-2.2l-3.9-3.3l-1.4-4l1.4-3.9l3.9-3.4l5.8-2.2l6.8-0.8l6.8,0.8l5.8,2.2l3.9,3.4l1.4,3.9l-1.4,4L652.2,541.4
		 M686.6,561.2l-3,1.2l-3.6,0.4l-3.6-0.4l-3-1.2l-2-1.7l-0.7-2l0.7-2.1l2-1.7l3-1.2l3.6-0.4l3.6,0.4l3,1.2l2,1.7l0.7,2.1l-0.7,2.1
		L686.6,561.2 M526.5,430.8l1.2-0.7 M514.1,430.3l0.7,0.4 M531.4,423.6v-0.5 M531.4,420.7v-0.5 M531.4,417.8v-0.5 M531.4,414.9v-0.5
		 M531.4,412v-0.5 M531.4,409v-0.5 M510,410v0.5 M510,412.9v0.5 M510,415.8v0.5 M510,418.7v0.5 M510,421.7v0.5 M510,424.6v0.5
		 M529.6,391.2v-6.1 M511.8,385.1v6.1 M537.6,370.7l-1.3-6.5l-3.7-5.5l-5.5-3.7l-6.5-1.3l-6.5,1.3l-5.5,3.7l-3.7,5.5l-1.3,6.5
		l1.3,6.5l3.7,5.5l5.5,3.7l6.5,1.3l6.5-1.3l5.5-3.7l3.7-5.5L537.6,370.7 M667.3,860.1l-2.9,4.7l-1.3,6.5 M663.1,871.3l1.3,6.5
		l3.7,5.5l5.5,3.7l6.5,1.3l6.5-1.3l5.5-3.7l3.7-5.5l1.3-6.5l-1.3-6.5l-3.4-5.2 M688.9,815.4v-0.1 M669.3,817.7v0.5 M669.3,820.6v0.5
		 M669.3,823.5v0.5 M669.3,826.4v0.5 M669.3,829.3v0.5 M669.3,832.3v0.5 M669.3,835.2v0.5 M669.3,838.1v0.5 M690.7,840.1v-0.5
		 M690.7,837.2v-0.5 M690.7,834.3v-0.5 M690.7,831.3v-0.5 M690.7,828.4v-0.5 M690.7,825.5V825 M690.7,822.6V822 M690.7,819.7v-0.5
		 M674.2,811.3L673,812 M687.4,812.2l-1.6-0.9 M970.4,685.4l-2.6,4.5l-1.2,6.5l1.4,6.4l3.7,5.4l5.5,3.6l6.5,1.2l6.4-1.4l5.4-3.7
		l3.6-5.5l1.2-6.5l-1.4-6.4l-3-4.7 M992.4,640.3v-0.2 M972.7,642.6v0.5 M972.7,645.5v0.5 M972.7,648.5v0.5 M972.7,651.4v0.5
		 M972.7,654.3v0.5 M972.7,657.2v0.5 M972.7,660.1v0.5 M972.7,663.1v0.5 M994.1,665.1v-0.5 M994.1,662.1v-0.5 M994.1,659.2v-0.5
		 M994.1,656.3v-0.5 M994.1,653.4v-0.5 M994.1,650.5v-0.5 M994.1,647.5V647 M994.1,644.6v-0.5 M994.1,641.7v-0.1 M977.6,636.2
		l-1.2,0.7 M990.8,637.1l-1.6-0.9 M957.4,569.3l2.6-1.8l3.2-3.8l1.2-4.3l-0.1-0.4 M946.7,575.5l10.7-6.1 M940,578.1l3.3-1l3.5-1.6
		 M916.8,591.3l2-2.4l5.4-4.4l7.2-3.7l2.3-0.7 M933.7,580.1l6.2-2 M911.8,595.6l2.6-1.8l2.4-2.6 M921.5,511v27.4 M964.4,558.9V511
		 M925.4,486.5V511 M960.5,511v-24.5 M699.6,718.1l212.2-122.5 M692.8,720.7l3.3-1l3.5-1.6 M669.6,733.9l2-2.4l5.4-4.4l7.2-3.7
		l2.3-0.7 M686.5,722.7l6.2-2 M664.6,738.3l2.6-1.8l2.4-2.6 M654,744.4l10.7-6.2 M618.2,734l0.8,4.2l2.8,3.8l4.5,3.1l6,2.1l6.8,0.8
		l6.9-0.6l6.3-2l1.7-0.9 M618.1,686.2V734 M661,713.6v-27.5 M622,661.6v24.5 M657.1,686.2v-24.5 M925.2,418.5l0.4-0.3l0.3-0.3
		l0.3-0.3l0.3-0.4l0.2-0.4l0.2-0.4l0.1-0.5l0-0.5 M927,415.4v-19.4 M1002.1,373.8l-74-50.5 M875,370.7l92.7,45.2 M856.6,262.6v181.1
		 M928.1,443.7v-47 M928.1,329.8v-67.2 M1008.4,403.9v-19.9 M645.3,392.6l1.5-0.8 M632.2,391.8l1.5,0.8 M650.3,387.3v-43.6
		 M628.8,343.7v43.6 M620.9,324.8v2.9 M658.1,327.7v-2.9 M651.2,313.9l-3.2-1.5l-6.1-1.3l-6.5,0.2l-6.2,1.8l-4.6,3.1l-2.8,4l-1,4.4
		l0,0.2 M657.9,324.7l0.1-1.7l-1.6-4.2l-2.9-3.3l-2.3-1.6 M664.6,330.6v-2.9 M614.5,327.7v2.9 M948.8,217.5l1.5-0.8 M935.7,216.7
		l1.5,0.8 M953.7,212.1v-43.6 M932.2,168.5v43.6 M924.4,149.7v2.9 M961.6,152.6v-2.9 M954.6,138.8l-3.2-1.5l-6.1-1.3l-6.5,0.2
		l-6.2,1.8l-4.6,3.1l-2.8,4l-1,4.4l0,0.2 M961.3,149.5l0.1-1.7l-1.6-4.2l-2.9-3.3l-2.3-1.6 M968,155.5v-2.9 M917.9,152.6v2.9
		 M619.7,577.3L619.7,577.3L619.7,577.3l0.2-0.2l0.2-0.1 M620.1,576.3l2-5.9 M539.8,578.3l25.9,12.7 M553.2,498.4l-52,35.5
		 M502.7,576.7l1.5,0.9 M539.6,578.4l21.2-10.3 M698.7,548.9l-74-50.5 M494.4,544.9v19.9 M617.2,568.1l47.1,23 M552.8,568.1h72.3
		 M553.2,437.8v67.2 M553.2,584.9v33.9 M624.7,618.8v-47 M624.7,504.9v-67.2 M705,579.1v-19.9"
        />
        <polyline
          class="st1"
          points="673.4,553.7 676.4,552.5 680,552.1 683.6,552.5 686.6,553.7 688.6,555.4 689.3,557.5 688.6,559.5
		686.6,561.3 683.6,562.4 680,562.8 676.4,562.4 673.4,561.3 671.4,559.5 670.7,557.5 671.4,555.4 673.4,553.7 	"
        />
        <polyline
          class="st1"
          points="688.9,843.8 688.3,845.8 686.3,847.5 683.4,848.6 680,849 676.6,848.6 673.7,847.5 671.7,845.8
		671.1,844.4 	"
        />
        <polyline
          class="st1"
          points="991.4,686.8 989.7,688.1 986.8,689.2 983.4,689.6 980,689.2 977.1,688.1 975.2,686.5 975.1,686.3
		"
        />
        <polyline
          class="st1"
          points="976.8,378.5 979.9,377.4 983.4,377 987,377.4 990,378.5 992,380.3 992.7,382.3 992,384.4 990,386.1
		987,387.3 983.4,387.7 979.9,387.3 976.8,386.1 974.8,384.4 974.1,382.3 974.8,380.3 976.8,378.5 	"
        />
        <polyline class="st1" points="955.4,518.2 959.1,514.9 960.5,511 	" />
        <polyline
          class="st1"
          points="925.4,511 926.8,514.9 930.6,518.2 936.3,520.4 943,521.1 949.7,520.4 955.4,518.2 	"
        />
        <polyline class="st1" points="651.9,693.3 655.7,690 657.1,686.1 	" />
        <polyline
          class="st1"
          points="622,686.1 623.3,690 627.1,693.3 632.8,695.5 639.5,696.3 646.2,695.5 651.9,693.3 	"
        />
        <line class="st2" x1="680" y1="814.6" x2="680" y2="593.5" />
        <line class="st1" x1="680" y1="562.8" x2="680" y2="557.5" />
        <line class="st1" x1="983.4" y1="382.3" x2="983.4" y2="387.7" />
        <line class="st2" x1="983.4" y1="418.4" x2="983.4" y2="639.5" />
        <line class="st1" x1="943" y1="491.9" x2="943" y2="403.9" />
        <line class="st3" x1="943" y1="369.3" x2="943" y2="218.8" />
        <line class="st3" x1="639.5" y1="393.9" x2="639.5" y2="544.4" />
        <line class="st1" x1="639.5" y1="579" x2="639.5" y2="667" />
        <line class="st4" x1="520.7" y1="432.1" x2="520.7" y2="549.7" />
      </g>
      <g id="Nr">
        <path class="st5" d="M929,539" />
        <g
          id="CT012038"
          className="cursor--pointer"
          onClick={() =>
            window.open(
              "https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-steering-module-set",
              "_blank"
            )
          }
        >
          <g>
            <g>
              <path
                class="st6"
                d="M878,750h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C890,744.6,884.6,750,878,750z"
              />
            </g>
            <polyline class="st7" points="878.8,741.3 881.8,738 878.5,734.7 			" />
          </g>
          <g>
            <path
              class="st8"
              d="M795.4,733.7c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3c0.7,0,1.6-0.2,2.5-0.5
				v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7c0.4-0.8,0.9-1.4,1.6-1.8
				c0.7-0.4,1.5-0.6,2.5-0.6c1,0,1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.8-0.3-1.1-0.5C796.1,733.7,795.7,733.7,795.4,733.7z"
            />
            <path
              class="st8"
              d="M803.9,742h-2.1v-8.2h-2.7V732h7.5v1.8h-2.7V742z"
            />
            <path
              class="st8"
              d="M814.4,737c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S814.4,735.4,814.4,737z M809.5,737c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8c0.5,0,0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8
				c-0.5,0-0.9,0.3-1.1,0.8C809.6,734.9,809.5,735.8,809.5,737z"
            />
            <path
              class="st8"
              d="M820.7,742h-2.1v-5.8l0-1l0-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7V742z"
            />
            <path
              class="st8"
              d="M830.4,742h-7v-1.5l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
				c0.3-0.2,0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.4
				c0,0.5-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4V742z"
            />
            <path
              class="st8"
              d="M838.4,737c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S838.4,735.4,838.4,737z M833.5,737c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8c0.5,0,0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8
				c-0.5,0-0.9,0.3-1.1,0.8C833.6,734.9,833.5,735.8,833.5,737z"
            />
            <path
              class="st8"
              d="M846,734.3c0,0.6-0.2,1.2-0.6,1.6c-0.4,0.4-0.9,0.7-1.6,0.9v0c0.8,0.1,1.4,0.3,1.8,0.7
				c0.4,0.4,0.6,0.9,0.6,1.6c0,1-0.3,1.7-1,2.2c-0.7,0.5-1.7,0.8-3,0.8c-1.1,0-2-0.2-2.9-0.5v-1.8c0.4,0.2,0.8,0.4,1.3,0.5
				s0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.5-0.4c0.3-0.2,0.5-0.6,0.5-1.1c0-0.5-0.2-0.8-0.6-1s-1-0.3-1.8-0.3h-0.8v-1.6h0.8
				c0.8,0,1.3-0.1,1.7-0.3c0.4-0.2,0.5-0.5,0.5-1c0-0.8-0.5-1.1-1.4-1.1c-0.3,0-0.7,0.1-1,0.2s-0.7,0.3-1.1,0.6l-1-1.5
				c0.9-0.7,2-1,3.3-1c1,0,1.9,0.2,2.5,0.6S846,733.5,846,734.3z"
            />
            <path
              class="st8"
              d="M850.8,731.9c1,0,1.7,0.2,2.3,0.7c0.6,0.4,0.9,1,0.9,1.8c0,0.5-0.1,1-0.4,1.4c-0.3,0.4-0.7,0.8-1.4,1.1
				c0.7,0.4,1.3,0.8,1.6,1.3c0.3,0.4,0.5,0.9,0.5,1.4c0,0.8-0.3,1.5-1,2c-0.6,0.5-1.5,0.7-2.5,0.7c-1.1,0-2-0.2-2.6-0.7
				c-0.6-0.5-0.9-1.1-0.9-2c0-0.6,0.2-1.1,0.5-1.5s0.8-0.8,1.5-1.2c-0.6-0.4-1-0.7-1.2-1.2c-0.2-0.4-0.4-0.9-0.4-1.3
				c0-0.7,0.3-1.3,0.9-1.7C849.1,732.1,849.9,731.9,850.8,731.9z M849.3,739.4c0,0.4,0.1,0.7,0.4,0.9s0.6,0.3,1.1,0.3
				c0.5,0,0.9-0.1,1.2-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9c-0.3-0.3-0.7-0.5-1.3-0.9
				C849.8,738.1,849.3,738.7,849.3,739.4z M850.8,733.5c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
				c0.2,0.2,0.5,0.4,1,0.7c0.4-0.2,0.8-0.4,0.9-0.6s0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.7C851.5,733.6,851.2,733.5,850.8,733.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      className="steering"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1545 1080"
    >
      <path
        class="st0"
        d="M957.4,569.3L957.4,569.3l0.3-0.3l0.2-0.4l0.1-0.5l0.1-0.5 M946.7,575.4L946.7,575.4l0.3-0.3l0.2-0.4l0.1-0.5
l0.1-0.5 M915.5,551.7v8.5 M920.1,557.5v-3.2 M940,578.1L940,578.1l0.3-0.3l0.1-0.3l0.1-0.4v-0.5 M916.8,591.2l0.1-0.2l0.2-0.5
l0.1-0.6 M958.1,567.7l4.3-3.5l1.9-4.2l0.1-1.1 M910.2,563.2v-11.7 M851.9,585.2v2.9 M841.8,593.9v8.8 M846.9,599.8v-2.9
 M836.8,605.6v-11.7 M793.8,618.7v2.9 M783.7,627.5v8.8 M788.7,633.3v-2.9 M778.6,639.2v-11.7 M725.5,661v8.8 M730.6,666.9V664
 M911.8,595.6L911.8,595.6l0.3-0.3l0.2-0.4l0.1-0.5v-0.5 M720.5,672.7V661 M701.8,715.6L912.6,594 M654.7,742.8v0.5l-0.1,0.5
l-0.2,0.4l-0.3,0.3 M654.7,742.8l8.4-4.9 M665.4,728.5v-3.3 M618.1,734l0.7,3.2l3.2,3.9l5.3,3l6.8,1.8l7.4,0.4l7.2-1.1l6.1-2.5
 M914.1,518.5V542 M915.8,540.2v-20.9 M920.9,521.8v12.1 M909.8,545v-28.7 M1002.1,502.4L1002.1,502.4l-0.3-0.1h-0.3l-0.3,0.1
l-0.3,0.1 M950.6,468.1l-22.4-15.3 M1000.9,502.5l-34-23.2 M658.1,655.7l-1,3.5l-3.3,3.4l-5.3,2.5l-6.5,1.3l-6.8-0.2l-6.3-1.6
l-4.8-2.8l-2.8-3.6l-0.5-2.4 M961.6,480.6l-1,3.5l-3.3,3.4L952,490l-6.5,1.3l-6.8-0.2l-6.3-1.6l-4.8-2.8l-2.8-3.6l-0.5-2.4
 M561.2,717.5L561.2,717.5l0.2,0.4l0.1,0.3v0.3 M566.1,721.1v-0.3v-0.3v-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.1h-0.1 M608.9,712.4
l3.8-3.2l3.4-2l2.1-0.6 M614.1,696.8l-5.2,15.6 M607.3,696.8v4.7 M607.3,701.5l1.6-4.7 M543.6,705.1l23.6,11.6 M567.3,716.7v-19.9
 M622.5,697.4v-0.5v-0.1 M555.5,696.8v0.2v0.5 M503.7,705.2v0.3l0.1,0.3l0.1,0.2l0.2,0.2 M540,706.1v0.3l-0.1,0.3l-0.1,0.2l-0.2,0.1
l0,0 M540,706.1l19.2-9.4 M556.8,696.8l-1.4,0.7 M625.1,696.8h0.6l1-0.1l0.8-0.2l0.6-0.3l0.4-0.4l0.1-0.5 M552.8,696.8h-0.6l-1-0.1
l-0.8-0.2l-0.6-0.3l-0.4-0.4l-0.1-0.5 M603.3,701v-4.3 M571.5,696.8v19.6 M561.5,718.5l-1.1-1l-0.5-0.7 M618.7,696.8l45.1,22
 M622.5,697.4l-1.4-0.7 M698.7,677.6L698.7,677.6l-0.3-0.1h-0.3l-0.3,0.1l-0.3,0.1 M553.2,627.9l-16.8,11.5 M498.9,665.7l-1.1,1.5
l-1.1,2.6l-0.2,2.6l0.6,2.6l1.4,2.5l2.2,2.3l2.9,2 M647.1,643.2l-22.4-15.3 M697.5,677.6l-34.1-23.2 M498.9,668.8l0.7,1.9l0.7,1.8
l0.7,1.7l0.7,1.6l0.7,1.5l0.7,1.4l0.7,1.3l0.7,1.2 M498.9,663.4v5.4 M526.5,684.7l2-0.8l2-0.9l2-1l2-1.1l2-1.2l2-1.3l2-1.4l2-1.5
 M542.5,670.1v5.4 M511.8,666L511.8,666l0.7,2l1.9,1.7 M514.4,669.6l2.9,1.1l3.4,0.4l3.4-0.4l2.9-1.1l1.9-1.7l0.7-2 M526.5,679.3
v5.4 M504.8,676v5.4 M504.8,681.4l2.7,0.7l2.7,0.7l2.7,0.6l2.7,0.5l2.7,0.4l2.7,0.3l2.7,0.2l2.7,0.1 M532.8,657.3l1.5,0.8l4,3.4
l1.6,4l-1,4.1l-3.5,3.6l-5.5,2.6l-6.7,1.3l-7.1-0.2l-6.5-1.7l-5-2.9l-2.9-3.7l-0.3-4.1l2.3-3.9l4.5-3.1l0.2-0.1 M504.8,676l-0.7-1.9
l-0.7-1.8l-0.7-1.7L502,669l-0.7-1.5l-0.7-1.4l-0.7-1.3l-0.7-1.2 M526.5,679.3l-2.7-0.7l-2.7-0.7l-2.7-0.6l-2.7-0.5l-2.7-0.4
l-2.7-0.3l-2.7-0.2l-2.7-0.1 M542.5,670.1l-2,0.8l-2,0.9l-2,1l-2,1.1l-2,1.2l-2,1.3l-2,1.4l-2,1.5 M536.6,657.6l0.7,1.2l0.7,1.3
l0.7,1.4l0.7,1.5l0.7,1.6l0.7,1.7l0.7,1.8l0.7,1.9 M533.4,656.7l0.6,0.1l2.7,0.7 M498.9,663.4l2-1.5l2-1.4l2-1.3l2-1.2l1.6-0.9
 M516.4,635.2l2,0.8l2.3,0.3l2.3-0.3l2-0.8l1.3-1.1l0.5-1.4l-0.5-1.3l-1.3-1.1l-2-0.8l-2.3-0.3l-2.3,0.3l-2,0.8l-1.3,1.1l-0.5,1.4
l0.5,1.3L516.4,635.2 M688.2,727.2l-1.9,1.6l-2.9,1.1l-3.4,0.4l-3.4-0.4l-2.9-1.1 M673.7,728.9l-1.9-1.7l-0.3-0.5 M660.5,717.9
l0.7,1.5l0.7,1.5l0.7,1.4l0.7,1.3l0.7,1.2 M685.8,728.2l2-0.8l2-0.9l2-1l2-1.1l2-1.2l2-1.3l2-1.4l2-1.5 M701.8,719.1v-4.2
 M664.1,724.9v-5.2 M664.1,724.9l2.7,0.7l2.7,0.7l2.7,0.6l2.7,0.5l2.7,0.4l2.7,0.3l2.7,0.2l2.7,0.1 M685.8,728.2v-5.4 M690.7,685.3
l0.1,0.2l-0.7,2.2l-1.9,1.9l-2.8,1.5L682,692l-3.6,0.2l-3.4-0.5l-2.9-1.1l-2-1.6l-0.8-1.9l0.1-0.4 M690.7,681.7l-0.2,2.2l-1.4,2.1
l-2.4,1.7l-3.2,1.1l-3.6,0.5l-3.5-0.2l-3.1-0.9l-2.4-1.4l-1.3-1.8l-0.1-1.1 M687.4,677.9l2.1,1.6l1.2,2.2 M682.9,685.7l-2.3,0.4h-3
l-2.8-0.6l-2.4-1.1l-1.5-1.5l-0.6-1.7l0.5-1.7l1.5-1.6l0.7-0.4 M682.9,685.7l1.5-0.4l1.4-0.6l1.2-0.7l0.9-0.8l0.6-0.9l0.3-1v-1
l-0.3-1 M690.7,681.7v0.9l-0.2,0.9l-0.4,0.8l-0.6,0.8l-0.8,0.8l-1,0.7l-1.1,0.6l-1.2,0.5 M685.8,676.9l-2.7-1l-3.1-0.4l-3.1,0.4
l-2.7,1l-1.8,1.5l-0.6,1.8l0.6,1.8l1.8,1.5l2.7,1l3.1,0.4l3.1-0.4l2.7-1l1.8-1.5l0.6-1.8l-0.6-1.8L685.8,676.9 M669.7,682l-0.4,1
l0.4,1.9l1.6,1.7l2.6,1.3l3.3,0.7l3.6,0.1l3.5-0.6l1.2-0.4 M670.3,684l-0.8,1.2l-0.1,1.9l1.1,1.8l2.2,1.5l3,1l3.5,0.3l3.6-0.4l3.3-1
l2.6-1.6l1.6-2l0.4-2.2l-0.8-2.2l0,0 M685.5,687.6l2.4-1.9l1.4-2.3l0.1-2.3l-0.7-1.7 M688.8,681v0.7l-0.9,1.8l-1.8,1.5l-2.5,1.1
l-2.9,0.6h-3l-2.7-0.4l-2.2-1l-1.4-1.4l-0.1-0.5 M991.4,552.5l-0.6,0.6l-2.5,1.4l-3.2,0.7h-3.5l-3.2-0.8 M978.3,554.4l-2.4-1.4
l-0.8-1 M962.8,542.2l0.5,0.7l1.2,1.6l1.2,1.5l1.2,1.4l1.2,1.3l1.2,1.2l1.2,1.1 M993,552.3l1.6-1l1.6-1.1l1.6-1.2l1.6-1.3l1.6-1.4
l1.6-1.5l1.6-1.6l1.6-1.7 M1005.9,541.6V539 M970.6,551.2v-5.4 M970.6,551.2l2.8,0.5l2.8,0.4l2.8,0.3l2.8,0.2l2.8,0.1h2.8l2.8-0.1
l2.8-0.2 M993,552.3v-5.4 M970.6,545.8l-0.7-1.1 M994,510.2l-0.1,1.3l-1.3,2.1l-2.3,1.7l-3.1,1.2l-3.5,0.6l-3.6-0.1l-3.2-0.8
l-2.5-1.4l-1.5-1.7l-0.2-1.4 M994.1,507.3l0.1,0.4l-0.8,2.2l-1.9,1.9l-2.8,1.4l-3.4,0.8l-3.6,0.1l-3.4-0.5l-2.8-1.2l-1.9-1.6
l-0.8-1.9l0.1-0.2 M990.8,502.8l1.1,0.6l1.8,2.1 M987.8,510.2l-2.1,0.6l-3,0.3l-3-0.3L977,510l-2-1.3l-1.1-1.6l-0.1-1.8l1-1.7
l1.7-1.2 M987.8,510.2l1.4-0.6l1.2-0.7l0.9-0.8l0.6-0.9l0.3-1v-1l-0.3-1l-0.6-0.9 M993.7,505.5l0.3,0.9l0.1,0.9l-0.1,0.9l-0.3,0.9
l-0.5,0.8l-0.7,0.8l-0.9,0.7l-1.1,0.6 M988.1,501.3l-2.9-0.7H982l-3,0.7l-2.3,1.3l-1.3,1.7v1.9l1.2,1.7l2.2,1.3l2.9,0.7h3.2l3-0.7
l2.3-1.3l1.3-1.7l0.1-1.9l-1.2-1.7L988.1,501.3 M973.8,505.9l-0.9,1.2l-0.1,1.9l1.1,1.8l2.1,1.5l3,1l3.5,0.4l3.6-0.3l3.3-1l1.1-0.5
 M973.1,510l-0.4,1.2l0.6,1.9l1.7,1.7l2.7,1.2l3.3,0.7h3.6l3.5-0.7l2.9-1.4l2.1-1.9l1-2.2l-0.1-0.7 M990.4,511.9l1.9-2.1l0.8-2.4
l-0.5-2.3l-1.2-1.6 M992.3,506l-0.3,1.6l-1.3,1.7l-2.1,1.4l-2.7,0.9l-3,0.3l-2.9-0.3l-2.5-0.8l-1.8-1.2l-0.7-1.1 M947.5,573.8
l10.7-6.2 M940.5,576.5l1-0.2l0.9-0.3l0.9-0.3l0.9-0.3l0.9-0.3l0.8-0.4l0.8-0.4l0.8-0.4 M947.5,553.4v20.4 M940,578.1L940,578.1
 M917.2,589.9l1.8-2.2l2.2-2.1l2.5-2l2.8-1.8l3.1-1.6l3.4-1.4l3.6-1.3l3.8-1 M940.5,576.5v-20.4 M958.1,567.7v-20.4 M912.5,594
l0.7-0.4l0.7-0.5l0.7-0.5l0.6-0.5l0.6-0.5l0.5-0.5l0.5-0.5l0.4-0.6 M917.2,569.5v20.4 M912.5,594v-20.4 M733.1,653.7l45.5-26.3
 M778.6,627.5l0.5-0.3l0.6-0.2l0.7-0.1h0.7h0.7l0.7,0.1l0.6,0.2l0.5,0.3 M783.7,627.5l5.1,2.9 M788.7,630.4l0.4,0.3l0.3,0.4l0.2,0.4
l0.1,0.4l-0.1,0.4l-0.2,0.4l-0.3,0.4l-0.4,0.3 M788.7,633.3l-45.5,26.3 M743.2,659.6l-10.1-5.8 M695.5,675.4l24.9-14.4 M720.5,661
l0.5-0.3l0.6-0.2l0.7-0.1h0.7h0.7l0.7,0.1l0.6,0.2l0.5,0.3 M725.5,661l5.1,2.9 M730.6,664l0.4,0.3l0.3,0.4l0.2,0.4l0.1,0.4l-0.1,0.4
l-0.2,0.4l-0.3,0.4l-0.4,0.3 M730.6,666.9l-27.3,15.7 M862,591l58.3-33.6 M862,591l-0.5,0.3l-0.6,0.2l-0.7,0.1h-0.7h-0.7l-0.7-0.1
l-0.6-0.2L857,591 M851.9,588.1l5.1,2.9 M851.9,588.1l-0.4-0.3l-0.3-0.4L851,587l-0.1-0.4l0.1-0.4l0.2-0.4l0.3-0.4l0.4-0.3
 M910.2,551.5l-58.3,33.6 M910.2,551.5l0.6-0.3l0.7-0.2l0.7-0.1h0.7l0.7,0.1l0.7,0.2l0.6,0.2l0.5,0.3 M920.1,554.3l-0.6-0.3
l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.5-0.4l-0.5-0.4 M920.1,554.3l0.5,0.3l0.4,0.4l0.3,0.4l0.1,0.4v0.4l-0.2,0.4
l-0.3,0.4l-0.5,0.3 M841.8,593.9l5.1,2.9 M846.9,596.8l0.4,0.3l0.3,0.4l0.2,0.4l0.1,0.4l-0.1,0.4l-0.2,0.4l-0.3,0.4l-0.4,0.3
 M846.9,599.8l-43,24.8 M803.9,624.6l-0.5,0.3l-0.6,0.2l-0.7,0.1h-0.7h-0.7l-0.7-0.1l-0.6-0.2l-0.5-0.3 M798.8,624.6l-5.1-2.9
 M793.8,621.6l-0.4-0.3l-0.3-0.4l-0.2-0.4l-0.1-0.4l0.1-0.4l0.2-0.4l0.3-0.4l0.4-0.3 M793.8,618.7l43-24.8 M836.8,593.9l0.5-0.3
l0.6-0.2l0.7-0.1h0.7h0.7l0.7,0.1l0.6,0.2l0.5,0.3 M917.5,537.6l-1.4,2.1l-1.8,2l-2.2,1.8l-2.6,1.7l-2.9,1.5l-3.2,1.3l-3.4,1
l-3.6,0.8 M896.5,549.8l-1.2,0.2l-1.2,0.3l-1.2,0.3l-1.1,0.4l-1.1,0.4l-1,0.4l-1,0.5l-0.9,0.5 M887.7,552.8L686.3,669.1
 M654.7,722.3l6.2-3.6 M705,693.3l207.5-119.8 M912.5,573.5l0.7-0.4l0.7-0.5l0.7-0.5l0.6-0.5l0.6-0.5l0.5-0.5l0.5-0.5l0.4-0.6
 M940.5,556.1l-3.8,1l-3.7,1.2l-3.4,1.5l-3.1,1.6l-2.8,1.8l-2.5,2l-2.2,2.1l-1.8,2.2 M940.5,556.1l1-0.2l0.9-0.3l0.9-0.3l0.9-0.3
l0.9-0.3l0.8-0.4l0.8-0.4l0.8-0.4 M947.5,553.4l10.7-6.2 M921.5,538.5l0.7,3.2l3.2,3.9l5.3,3l6.8,1.8l7.4,0.4l7.2-1.1l6.1-2.5
 M921.5,533.4l-0.4,0.3l-0.8,0.6l-0.7,0.6l-0.6,0.7l-0.6,0.7l-0.5,0.7l-0.4,0.7 M654.7,722.3v20.4 M654.7,722.3l4.3-3.5l0.9-1.2
 M967.7,544.6L967.7,544.6l-0.2-0.2l-0.1-0.2l-0.1-0.3v-0.3 M876.7,382.3l6.3-2.6l7.4-1.1l7.7,0.4l7,1.9l5.4,3.1l3.3,4l0.7,4.4
l-2,4.3l-4.4,3.6l-6.3,2.5l-7.4,1.2l-7.7-0.4l-7-1.9l-5.4-3.1l-3.3-4l-0.7-4.4l2-4.3L876.7,382.3 M875.1,499.4l-2.5-1.3l-3.2-2.2
l-0.1-0.1 M917.7,405.9l-7.8,3.4l-9.1,2.1l-9.7,0.6l-9.6-1l-8.8-2.4l-7.3-3.7l-5.3-4.7l-2.9-5.4l-0.3-5.6l2.3-5.5l4.8-4.9l6.9-4
l8.5-2.8l9.5-1.3l9.7,0.2l9.3,1.7l8.1,3.1l6.4,4.2l4.2,5.1l1.7,5.5l-1,5.6l-3.6,5.2L917.7,405.9 M869.3,476v19.9 M869.3,495.8
l98,47.8 M869.3,476L869.3,476l3.3,0.6l3.5,0.5l1.9,0.3l2,0.4 M967.3,523.8l-98-47.8 M967.3,523.8v19.9 M967.3,543.6l7.1,2.4l8.1,1
l8.2-0.6l7.4-2.1l5.8-3.4l3.6-4.3l1-4.1 M1002.5,503.4v-0.3l-0.1-0.3l-0.2-0.2l-0.2-0.1 M967.3,523.8v-0.4l0.1-0.4l0.1-0.4l0.2-0.4
l0.2-0.4l0.2-0.3l0.3-0.3l0.3-0.2 M1002.5,503.4l4.2,4.1l1.7,4.7l-1,4.7l-3.6,4.3l-5.8,3.4l-7.4,2.1l-8.2,0.6l-8.1-1l-7.1-2.4
 M880,477.8l9.9,1.2l10.1-0.4l9.5-2.1l8.1-3.5l6.1-4.7l3.6-5.5l0.8-4.4 M880,477.8l88.6,43.3 M968.6,521.1l7.4,2.4l8.4,0.7l8.2-1.1
l7-2.8l4.8-4l1.9-4.8l-1.2-4.8l-4.2-4.2 M633.5,644.7v3.5 M639.5,648.2v3.5 M645.6,644.7v3.5 M642.6,649.9v3.5 M636.5,649.9v3.5
 M630.4,646.4l3-1.8 M633.5,644.7l6.1,3.5 M639.5,648.2l6.1-3.5 M645.6,644.7l3,1.8 M648.6,646.4l-6.1,3.5 M642.6,649.9l6.1,3.5
 M648.6,653.4l-3,1.8 M645.6,655.2l-6.1-3.5 M639.5,651.7l-6.1,3.5 M633.5,655.2l-3-1.8 M630.4,653.4l6.1-3.5 M636.5,649.9l-6.1-3.5
 M647.6,645.2l-3.7-1.4l-4.4-0.5l-4.4,0.5l-3.7,1.4l-2.5,2.1l-0.9,2.5l0.9,2.5l2.5,2.1l3.7,1.4l4.4,0.5l4.4-0.5l3.7-1.4l2.5-2.1
l0.9-2.5l-0.9-2.5L647.6,645.2 M658.1,658.7l-0.2,1.5l-2.2,3.7l-4.4,3l-6,1.9l-6.8,0.5l-6.7-0.9l-5.6-2.2 M626.4,666.3l-3.9-3.3
l-1.6-3.8v-0.5 M624.3,647.2l-3.9,2.1l-4.2,4.1l-1.7,4.7l1,4.7l3.6,4.3l5.8,3.4l7.4,2.1l8.2,0.6l8.1-1l7.1-2.4l5.3-3.6l3-4.4
l0.3-4.8l-2.3-4.7l-4.8-3.9 M657.2,648.5l-2.8-1.4 M614.5,661.6l1,4.1l3.6,4.3l5.8,3.4l7.4,2.1l8.2,0.6l8.1-1l7.1-2.4l5.3-3.6l3-4.4
l0.5-3 M949,469.5v3.5 M936.9,469.5v3.5 M943,473.1v3.5 M946,474.8v3.5 M939.9,474.8v3.5 M933.9,471.3l3-1.8 M936.9,469.5l6.1,3.5
 M943,473.1l6.1-3.5 M949,469.5l3,1.8 M952.1,471.3l-6.1,3.5 M946,474.8l6.1,3.5 M952.1,478.3l-3,1.8 M949,480.1l-6.1-3.5
 M943,476.6l-6.1,3.5 M936.9,480.1l-3-1.8 M933.9,478.3l6.1-3.5 M939.9,474.8l-6.1-3.5 M951.1,470.1l-3.7-1.4l-4.4-0.5l-4.4,0.5
l-3.7,1.4l-2.5,2.1l-0.9,2.5l0.9,2.5l2.5,2.1l3.7,1.4l4.4,0.5l4.4-0.5l3.7-1.4l2.5-2.1l0.9-2.5l-0.9-2.5L951.1,470.1 M961.6,483.6
l-0.2,1.5l-2.2,3.7l-4.4,3l-6,1.9l-6.8,0.5l-6.7-0.9l-5.6-2.2 M929.8,491.2l-3.9-3.3l-1.6-3.8v-0.5 M927.7,472.1l-3.9,2.1l-4.2,4.1
l-1.7,4.7l1,4.7l3.6,4.3l5.8,3.4l7.4,2.1l8.2,0.6l8.1-1l7.1-2.4l5.3-3.6l3-4.4l0.3-4.8l-2.4-4.6l-4.8-3.9 M960.7,473.3l-2.8-1.4
 M917.9,486.5l1,4.1l3.6,4.3l5.8,3.4l7.4,2.1l8.2,0.6l8.1-1l7.1-2.4l5.3-3.6l3-4.4l0.5-3 M560.5,716.7l1.6,0.9l1.7,1l1.8,1
 M609.2,713L609.2,713l-0.1-0.2l-0.1-0.1l-0.1-0.1v-0.1v-0.1v-0.1v-0.1 M571.5,716.4l-0.5-0.1l-0.6-0.1h-0.6h-0.6l-0.6,0.1l-0.5,0.1
l-0.5,0.2l-0.4,0.2 M603.3,701l0.5-0.1l0.5-0.1h0.5h0.5l0.5,0.1l0.5,0.1l0.5,0.2l0.4,0.2 M608.9,712.4l-0.4,0.4l-0.2,0.1l-0.2,0.1
h-0.2l-0.2-0.1l-0.4-0.4l-0.4-1.1l-0.2-4.1l0.3-3.5l0.3-2.4 M567.3,716.7l0.4,1.7l-0.2,1l-0.5,0.5l-0.4,0.1h-0.3l-0.6-0.2l-0.2-0.1
 M586.7,664.7l0.5-0.2l0.6-0.2l0.6-0.1h0.6h0.6l0.6,0.1l0.6,0.2l0.5,0.2 M591.3,663.5l-0.5-0.2l-0.6-0.2l-0.6-0.1H589h-0.6l-0.6,0.1
l-0.6,0.2l-0.5,0.2 M502.3,704.4v0.3l0.1,0.3l0.1,0.2l0.2,0.2 M631,695.7l-0.2-0.1l-0.3-0.1l-0.3-0.1l-0.3-0.1l-0.3-0.1h-0.3H629
h-0.3 M549.2,695.2h-0.3h-0.3h-0.3l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.2,0.1 M589,669.2l0.4-0.4l0.4-0.5l0.4-0.5l0.3-0.6
l0.3-0.6l0.2-0.7l0.1-0.6v-0.6 M589,669.2l-0.5-0.4l-0.4-0.5l-0.4-0.5l-0.3-0.6l-0.3-0.6l-0.2-0.7l-0.1-0.6v-0.6 M622.5,679.9v0.6
l-0.1,0.6l-0.2,0.7l-0.3,0.6l-0.3,0.6l-0.4,0.5l-0.4,0.5l-0.4,0.4 M631,689.9l-0.2-0.1l-0.3-0.1l-0.3-0.1l-0.3-0.1l-0.3-0.1h-0.3
H629h-0.3 M557.7,684.4l-0.4-0.4l-0.4-0.5l-0.4-0.5l-0.4-0.6l-0.3-0.6l-0.2-0.7l-0.1-0.6v-0.6 M549.2,689.4h-0.3h-0.3h-0.3l-0.3,0.1
l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.2,0.1 M494.4,693.4v0.6l0.7,2.8l1.6,2.7l2.4,2.5l3.2,2.2 M502.3,684.5v19.9 M502.3,704.4l1.5,0.9
 M502.3,684.5l-3.2-2.2l-2.4-2.5l-1.6-2.7l-0.7-2.8l0.3-2.9l1.2-2.8l2-2.6l1-1 M502.3,684.5v-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4
l0.3-0.3l0.3-0.3l0.3-0.2 M503.7,685.4l-1.5-0.9 M538.6,706.9l-3.8,1.5l-4.2,1.1l-4.5,0.6l-4.6,0.1l-4.5-0.4l-4.3-0.8l-4-1.3
l-3.5-1.7 M504.2,706.2l0.2,0.1h0.3l0.3-0.1l0.3-0.1 M539.6,707.1l-0.2,0.1h-0.2l-0.3-0.1l-0.3-0.1 M555.4,697.4l-1.5,0.6l-1.4,0.4
l-1.3,0.2H550l-1-0.2l-0.8-0.4l-0.6-0.6l-0.4-0.8l-0.1-1 M546.9,695.7v-5.8 M546.9,689.9l0.2-1.2l0.5-1.3l0.8-1.4l1-1.4l1.3-1.4
l1.5-1.3l1.6-1.1l1.7-0.9 M555.4,679.9l31.3-15.3 M586.7,664.7v-1.2 M586.7,663.5v-0.4l-0.1-0.4l-0.1-0.4l-0.2-0.4l-0.2-0.4
l-0.2-0.3l-0.3-0.3l-0.3-0.2 M586.7,663.5L540,686.3 M503.7,705.2v-19.9 M503.7,705.2l3.8,1.8l4.4,1.4l4.7,0.9l4.9,0.4l5-0.1
l4.8-0.7l4.5-1.2l4.1-1.6 M540,686.3v19.9 M589,669.2l-31.3,15.3 M620.2,684.4L589,669.2 M540,686.3v-0.4l-0.1-0.4l-0.1-0.4
l-0.2-0.4l-0.2-0.4l-0.2-0.3l-0.3-0.3l-0.3-0.2 M503.7,685.4V685l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.3-0.3l0.3-0.3l0.3-0.2
 M540,686.3l-4.1,1.6l-4.5,1.2l-4.8,0.7l-5,0.1l-4.9-0.4l-4.7-0.9l-4.4-1.4l-3.8-1.8 M663.8,718.8v0.3l0.1,0.3l0.1,0.2l0.2,0.2l0,0
 M573.3,557.4l6.3-2.5l7.4-1.2l7.7,0.4l7,1.9l5.4,3.1l3.2,4l0.7,4.4l-2,4.3l-4.4,3.6l-6.3,2.5l-7.4,1.2l-7.7-0.4l-7-1.9l-5.4-3.1
l-3.3-4l-0.7-4.4l2-4.3L573.3,557.4 M618.4,707.7l-2.7,0.5l-3.1,1.8l-3.4,3 M609.2,713l-0.7,0.6l-0.1,0.1l-0.7,0.3H607l-0.8-0.4
l-1-1.2l-1.5-4.5l-0.5-6.7 M571.5,716.4l-0.4,2.8l-1,1.6l-1.2,0.8l-0.2,0.1l-1.4,0.1l-1.3-0.5 M566.1,721.1l-1.6-0.9l-1.5-0.9
l-1.4-0.8 M624.7,747.5l-0.2,2.1l-2.3,5.5l-4.8,4.9l-6.9,4l-8.5,2.8l-9.5,1.3l-9.7-0.2l-9.3-1.7l-8.1-3.1l-6.4-4.2l-4.2-5.1
l-1.7-5.5v-0.7 M614.2,581l-7.8,3.4l-9.1,2.1l-9.7,0.6l-9.6-1l-8.8-2.4l-7.3-3.7l-5.3-4.7l-2.9-5.4l-0.3-5.6l2.3-5.5l4.8-4.9l6.9-4
l8.5-2.8l9.5-1.3l9.7,0.2l9.3,1.7l8.1,3.1l6.4,4.2l4.2,5.1l1.7,5.5l-1,5.6l-3.6,5.2L614.2,581 M549.2,689.4v5.8 M549.2,695.2h79.5
 M628.7,695.2v-5.8 M591.3,663.5v1.2 M591.3,664.7l31.3,15.3 M622.5,679.9l1.5,0.8l1.4,1l1.3,1.1l1.2,1.2l1,1.2l0.8,1.2l0.6,1.2
l0.4,1.2l0.1,1.1 M631,689.9v5.8 M631,695.7l-0.2,1.1l-0.5,0.9l-0.8,0.6l-1.1,0.4l-1.3,0.1l-1.5-0.2l-1.6-0.4l-1.7-0.7 M591.3,663.5
v-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.2-0.3l0.3-0.3l0.3-0.2 M663.8,698.9l-72.6-35.4 M628.7,689.4l-0.2-0.6l-0.5-0.7l-0.8-0.7
l-1-0.7l-1.3-0.7l-1.5-0.6l-1.6-0.6l-1.7-0.5 M557.7,684.4h62.5 M549.2,689.4l0.2-0.6l0.5-0.7l0.8-0.7l1.1-0.7l1.3-0.7l1.5-0.6
l1.6-0.6l1.7-0.5 M628.7,689.4h-79.5 M663.8,698.9v19.9 M663.8,718.8l7.1,2.4l8.1,1l8.2-0.6l7.4-2.1l5.8-3.4l3.6-4.3l1-4.1
 M699.1,678.5v-0.3l-0.1-0.3l-0.2-0.2l-0.2-0.1 M663.8,698.9v-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.2-0.3l0.3-0.3l0.3-0.2
 M699.1,678.5l4.2,4.1l1.7,4.7l-1,4.7l-3.6,4.3l-5.8,3.4l-7.4,2.1l-8.2,0.5l-8.1-1l-7.1-2.4 M553.2,633.6l0.7,4l3.2,5.3l5.6,4.6
l7.6,3.6l9,2.3l9.7,0.8l9.7-0.8l9-2.3l7.6-3.6l5.6-4.6l3.2-5.3l0.7-4 M503.8,681.9l1.5,0.9 M505.3,682.8l3.5,1.7l4,1.3l4.3,0.8
l4.5,0.4l4.6-0.1l4.5-0.6l4.2-1.1l3.8-1.5 M538.6,683.6l46.6-22.8 M585.3,660.8l0.8-0.3l0.9-0.2l1-0.2l1-0.1l1,0.1l1,0.2l0.9,0.2
l0.8,0.3 M592.7,660.8l72.6,35.4 M665.2,696.2l7.4,2.4l8.4,0.7l8.2-1.1l7-2.8l4.8-4l1.9-4.8l-1.2-4.8l-4.2-4.2 M529.6,666v-6.1
 M511.8,659.9v6.1 M537.6,645.5l-1.3-6.5l-3.7-5.5l-5.5-3.7l-6.5-1.3l-6.5,1.3l-5.5,3.7l-3.7,5.5l-1.3,6.5l1.3,6.5l3.7,5.5l5.5,3.7
l6.5,1.3l6.5-1.3l5.5-3.7l3.7-5.5L537.6,645.5 M667.3,725.8l-2.9,4.7l-1.3,6.5 M663.1,736.9l1.3,6.5l3.7,5.5l5.5,3.7l6.5,1.3
l6.5-1.3l5.5-3.7l3.7-5.5l1.3-6.5l-1.3-6.5l-3.4-5.2 M688.9,681L688.9,681 M669.3,683.3v0.5 M690.7,685.3v-0.5 M674.2,676.9
l-1.2,0.7 M687.4,677.8l-1.6-0.9 M970.4,551.1l-2.6,4.5l-1.2,6.5l1.4,6.4l3.7,5.4l5.5,3.6l6.5,1.2l6.4-1.4l5.4-3.7l3.6-5.5l1.2-6.5
l-1.4-6.4l-3-4.7 M992.4,506v-0.2 M972.7,508.3v0.5 M994.1,510.2v-0.5 M994.1,507.3v-0.1 M977.6,501.8l-1.2,0.7 M990.8,502.7
l-1.6-0.9 M957.4,569.3l2.6-1.8l3.2-3.8l1.2-4.3l-0.1-0.4 M946.7,575.4l10.7-6.2 M939.9,578.1l3.3-1l3.5-1.6 M916.8,591.2l2-2.4
l5.4-4.4l7.2-3.7l2.3-0.7 M933.7,580.1l6.2-2 M911.8,595.6l2.6-1.8l2.4-2.6 M921.5,522.1v16.4 M964.4,558.9v-14.5 M701.8,716.8
l210-121.2 M654,744.4l9.3-5.4 M618.2,734l0.8,4.2l2.8,3.8l4.5,3.1l6,2.1l6.8,0.8l6.9-0.6l6.3-2l1.7-0.9 M618.1,697.2V734
 M953.4,469.2L928.1,452 M1002.1,502.4l-36.3-24.7 M875,499.4l92.7,45.2 M856.6,391.3v179.5 M928.1,458.5v-67.2 M1008.4,532.6v-19.9
 M620.9,655.8v2.9 M658.1,658.7v-2.9 M651.2,644.9l-3.2-1.5l-6.1-1.3l-6.5,0.2l-6.2,1.8l-4.6,3.1l-2.8,4l-1,4.4v0.2 M657.9,655.7
l0.1-1.7l-1.6-4.2l-2.9-3.3l-2.3-1.6 M664.6,661.6v-2.9 M614.5,658.7v2.9 M924.4,480.6v2.9 M961.6,483.6v-2.9 M954.6,469.8l-3.2-1.5
l-6.1-1.3l-6.5,0.2l-6.2,1.8l-4.6,3.1l-2.8,4l-1,4.4v0.2 M961.3,480.5l0.1-1.7l-1.6-4.2l-2.9-3.3l-2.3-1.6 M968,486.5v-2.9
 M917.9,483.6v2.9 M539.8,707l25.9,12.7 M553.2,627.1l-17.1,11.6 M502.7,705.4l1.5,0.9 M539.6,707.1l21.2-10.3 M650,644.3
l-25.3-17.2 M698.7,677.6l-36.3-24.7 M494.4,673.6v19.9 M617.2,696.8l47.1,23 M552.8,696.8h72.3 M553.2,566.4v67.2 M553.2,713.6
v33.9 M624.7,747.5V744 M624.7,633.6v-67.2 M705,707.7v-19.9"
      />
    </svg>
  )
}

export default Steering
