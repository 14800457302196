import "./bevelGear.scss"

import React from "react"

const BevelGear = ({ explode }) => {
  return explode ? <svg
  className="bevelGear"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<g id="_x31_">
	<path class="st0" d="M1055.7,656.1L1055.7,656.1l0.1,0.2l0,0.3 M1056.7,654.2l-0.1,0l-0.1,0h0 M1062.2,631.6L1062.2,631.6l0.1,0
		l0.2,0 M1062.9,629.2l-0.2,0.4l-0.2,0.1l0,0 M1042.8,679.9l0.2,0h0.1 M1046.3,676.7L1046.3,676.7l0-0.4l-0.1-0.2l0,0 M1045.4,677.7
		L1045.4,677.7l0,0.3l-0.2,0.6l-0.1,0.1 M1043.4,679.8l0.1,0l0.1,0l0,0 M1063,603.8l-0.1-0.1l-0.1-0.1l-0.2,0 M1063.8,608.4
		L1063.8,608.4l0.2-0.1l0.2-0.4l0-0.1 M1063.4,604.1L1063.4,604.1l-0.1-0.1l-0.1-0.1l-0.1,0 M1064,605.7l0,0.2l-0.2,0.7l-0.1,0.2
		l0,0 M1056.4,654.2L1056.4,654.2l0.2-0.2l0.2-0.6l0-0.3 M1062.1,632.5l0.1-0.1l0.1-0.5l0-0.2l0,0 M1046.2,676.2L1046.2,676.2
		l0.2-0.1l0.2-0.2 M1029.9,694.7l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0 M1033.5,693.1l0.3-0.3l0.2-0.7l0-0.3l0,0 M1064,608.7
		l-0.1-0.2l-0.1-0.1l0,0 M1055.3,624.5l0.2,0l1.5-0.3l2.1,0.5l0,0 M1032.9,692.7L1032.9,692.7l-0.1,0.4l-0.4,0.8l-0.4,0.5
		 M1030.5,694.6l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0h0.1l0.1,0 M1036,680.3l6.8-0.4 M1059.1,586.7L1059.1,586.7l-0.1-0.1l-0.1,0h-0.1
		l-0.1,0l-0.1,0l-0.1,0.1 M1059.5,587.4l0.1,0.1l0.2,0.8l-0.1,0.9l-0.1,0.3l0,0 M1033.9,691.8L1033.9,691.8l0.1,0.1l0.1,0.1
		 M1042.8,679.9l-3.5-2.4l-0.9-1.1l-0.7-1.5l-0.1-0.8l-0.2-0.2l-0.1,0 M1058.9,586.5L1058.9,586.5l-0.2-0.1l-0.1-0.1l-0.1,0l-0.2,0
		l-0.2,0l-0.2,0 M1060.4,590.5l-0.2,0.2l-0.1,0l0,0 M1054.4,607.7l0.2,0l0.4-0.4l1.6-1.5l2.4-1.3l2.4-0.7l1.2-0.1 M1062.6,603.6
		l-5.1-4.1 M1021.4,698.4L1021.4,698.4l0.1,0.2l0,0.5 M1029.9,694.7l-2-5.5l-0.4-1.9l-0.2-2.2l0.2-1.3l0-0.5l-0.1-0.3l-0.2,0
		 M1022.8,696.1l7.1-1.4 M1052.1,580.6L1052.1,580.6l-0.2,0.6l-0.2,0.2l0,0 M1051.2,578.5L1051.2,578.5L1051.2,578.5 M1049.5,596.8
		L1049.5,596.8l0.3-0.1l0-0.1l0.3-0.6l0.6-1.5l0.9-1.4l1-1.3l3.4-3.7l1.9-1.7 M1057.9,586.4l-5.9-5.7 M1008.9,696.9l0.1,0.1l0.1,0
		l0.1,0l0.1,0 M1011.1,694.7L1011.1,694.7l0,0.3l-0.2,0.7l-0.1,0.2 M1039.8,579.2L1039.8,579.2l-0.2-0.1l-0.1,0l-0.1,0l-0.1,0
		 M1040.4,580.5l0.1,0.4l-0.2,0.8l-0.1,0.3l0,0 M1008.7,696.9L1008.7,696.9l0.2,0.1l0.1,0h0 M1017.3,699.8l-0.2-7.7l0.1-2.4l0.3-2.5
		l0.5-1.5l0.2-0.8l0-0.6l-0.2-0.3l0,0l-0.5-0.1 M1039.5,578.9L1039.5,578.9L1039.5,578.9l-0.1,0l-0.1,0l-0.1,0 M1041,593.4l0.6-0.1
		l0.1,0l0.2-0.3l0.3-0.8l0.2-1.2l1-3l1.9-4l2.2-3.8l1.1-1.8 M1002,683.3l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0l0.1,0
		 M1004.6,681.5L1004.6,681.5l-0.1,0.4l-0.4,0.8l-0.6,0.6l-0.3,0.1 M1001.8,683.3l0,0.1l0,0.1l0,0.1l0,0.1l0,0l0,0h0 M1007.1,694.4
		l1.6-8.6l0.6-2.4l0.7-2.4l0.8-1.5l0.4-0.9l0.2-0.7l0-0.6l0-0.1l-0.4-0.7l-0.1,0l-0.3-0.1l-0.2,0 M1026.5,589.4L1026.5,589.4
		L1026.5,589.4L1026.5,589.4l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M1030.9,598.5l0.4-0.1l0.1,0l0.7-0.7l0.1-0.1l0.2-0.4
		l0.2-1l0-1.3l0.4-3l1.2-4.4l1.5-4.5l0.8-2.2 M1002.3,663.6l-1.3-0.1l-0.9-0.3 M1001.1,679.4l3.1-8l1-2.1l1-1.9l1-1.2l0.5-0.8
		l0.4-0.8l0.1-0.7l0-0.1l-0.1-1.1l0-0.1l-0.2-0.3l-0.6-0.3l-0.2,0 M1020.2,610.8L1020.2,610.8l0.6,0.2l0.6-0.1l0.5-0.4l0.1-0.1
		l0.6-1.1l0-0.1l0.1-0.5l0-1.1l-0.2-1.2l-0.3-2.5l0.3-4l0.6-4.4l0.4-2.3 M1014.3,608.1l3.5,0.5 M454,508.5l8.4-7.3 M521.7,384.7
		v-9.9 M532.6,383.2V376 M515.4,410.3l3.7,0.8l5.3,0.3l5.7-0.6l5.4-1.5l3.1-1.3 M537,409.4l-1.1,0.7l-2.5,1.1l-3,0.8l-3.3,0.4
		l-3.4,0l-3.3-0.4l-3-0.7l-2.6-1.1l-1.5-0.9 M537.3,409.1l-2.4,1.2l-4.6,1.3l-5.2,0.4l-5.2-0.4l-4.5-1.3 M525.5,388l-4.8,0l-5.1-0.9
		l-4.3-1.6l-3.2-2.4l-1.6-2.9l0.4-3l0.3-0.5 M507.2,376.7L507.2,376.7 M540.8,379.8l-0.2,1.7l-1.8,2.5l-3.3,2l-4.5,1.4l-5.5,0.6
		 M528.6,385.4l-10.9-1.2 M517.7,384.3l-3.4-5.3 M514.3,379l7.5-4.1 M521.7,374.8l10.9,1.2 M532.6,376l3.4,5.3 M536.1,381.3
		l-7.5,4.1 M544.4,400.3l0,0.8l-0.2,0.8l-0.3,0.8l-0.4,0.8l-0.4,0.6l-0.8,1l-1.5,1.3l-2.3,1.5 M544.4,394.4L544.4,394.4l-0.4,2.3
		l-1.3,2.1l-1.2,1.2l-1.7,1.3l-4.2,2.1l-1.3,0.4l-1.2,0.3l-1,0.3l-4.9,0.8l-1.2,0.1l-1.1,0.1l-1.1,0l-1,0l-3.3-0.2l-2.5-0.3
		l-1.3-0.3l-1-0.2l-0.7-0.2l-1.2-0.4l-1.1-0.4l-1.1-0.5l-1.1-0.6l-0.5-0.3l-0.5-0.4l-0.5-0.4l-0.9-0.9l-0.8-1.3l-0.3-0.8l-0.1-0.7
		l0-0.3 M544.5,388.6L544.5,388.6l0,0.7l-0.1,0.5l-0.2,0.8l-0.4,0.8l-0.5,0.8l-0.7,0.9l-0.6,0.7l-0.9,0.8l-2.4,1.6l-2.2,1l-1.7,0.6
		l-0.4,0.1l-0.3,0.1l-0.3,0.1l-1.4,0.4l-1.4,0.3l-1.5,0.3l-1.1,0.2l-1.6,0.2l-2,0.1l-4.8-0.1l-1.5-0.2l-1.5-0.2l-1.4-0.3l-0.9-0.2
		l-1.3-0.4l-1.5-0.6l-1-0.4l-1-0.5l-1-0.6l-1-0.8l-1.1-1.1l-0.6-1.1l-0.3-1l0.1-0.7 M544.4,382.7l0,0.2l-0.2,1.3l-0.4,1.2l-1.5,2.2
		l-1.4,1.3l-1.9,1.3l-4.1,1.9l-1.7,0.5l-1.9,0.5l-1.5,0.3l-1.4,0.2l-1.1,0.1l-1.7,0.1l-2.4,0.1l-2.7-0.1l-2.9-0.3l-1.7-0.3l-1.3-0.3
		l-2.5-0.8l-2.1-1l-1.3-0.8l-1.1-0.9l-1.1-1.2l-0.6-1.1l-0.1-1.4 M507.2,376.6l0.8-0.8l0.9-0.8l1.1-0.8l1.2-0.7l1.3-0.6l1.5-0.6
		l1.6-0.5l1.6-0.4 M524.5,410.7l1.9,0l1.9-0.1l1.9-0.2l1.8-0.3l1.8-0.4l1.7-0.5l1.6-0.6l1.5-0.6 M543.7,397.1l0.7,1.8l0,0.8
		l-0.1,0.7l-0.1,0.6l-0.3,0.8l-0.4,0.8l-0.5,0.8l-0.7,0.9l-0.7,0.7l-0.9,0.8l-2.3,1.5l-2.1,1l-1.2,0.5l-1,0.3l-0.7,0.2l-1.4,0.4
		l-1.4,0.3l-1.5,0.3l-1.8,0.2l-2.9,0.2 M543.8,391.2l0.4,1l0.2,1.3l0,0.4l0,0.3l0,0l0,0l0,0.1l-0.1,0.5l-0.1,0.5l-0.2,0.6l-0.3,0.7
		l-0.7,1.2l-1.1,1.2l-2.9,2.2l-1.2,0.6l-1.3,0.6l-1.3,0.5l-1.1,0.4l-1.4,0.4l-1.7,0.4l-4.6,0.6l-4.8,0.1l-2.3-0.2l-2.2-0.3l-1.2-0.2
		l-1.3-0.3l-1.5-0.5l-2.3-0.9l-0.6-0.3l-0.2-0.1l-0.1-0.1l-2.2-1.6l-0.7-0.8l-0.5-0.8l-0.3-0.6l-0.2-1.1l0.1-1l0.1-0.5l0.2-0.5
		l0.3-0.5l0.2-0.3 M543.9,385.3l0.5,1.4l0.1,1l0,0.9l-0.3,1.3l-1.4,2.4l-2.5,2.3l-1.1,0.7l-1.2,0.6l-1.2,0.6l-1.7,0.7l-2.4,0.7
		l-1.1,0.3l-1.1,0.2l-1.1,0.2l-1.1,0.2l-0.4,0l-2.5,0.2l-1.8,0.1l-2.2,0l-2.6-0.2l-2.4-0.4l-1.9-0.4l-1.2-0.4l-1.2-0.4l-1.1-0.5
		l-0.9-0.5l-1.3-0.8l-1.4-1.1l-1.1-1.4l-0.6-2.3l0.1-0.6l0.1-0.6l0.3-0.7l0.4-0.5 M523.6,370.8l0.3,0l4.8,0.4l2.3,0.4l2.2,0.6
		l1.2,0.4l1.3,0.5l1.5,0.6l2.3,1.2l0.6,0.4l0.3,0.2l0.2,0.1l1.3,1.1l0.6,0.7l0.6,0.8l0.6,1l0.4,1.1l0.2,1.4l-0.1,1.2l-0.1,0.6
		l-0.2,0.6l-0.3,0.6l-0.3,0.6L543,386l-0.8,1l-0.7,0.6l-0.8,0.7l-1.1,0.8l-1.4,0.8l-3.6,1.5l-1.4,0.4l-1.5,0.4l-1.6,0.3l-3.8,0.5
		l-2.6,0.1l-3-0.1l-5.1-0.7l-1.8-0.5l-1.4-0.5l-2.8-1.4l-0.9-0.6l-0.8-0.7l-0.7-0.8l-0.6-0.8l-0.4-0.9l-0.2-0.8l0.2-1.9l0.4-1l0.7-1
		l0.1-0.1 M517.1,371.4l6.3-0.5l6.2,0.6l5.2,1.5l3.9,2.3l1.9,2.7l0.2,1.9 M538.4,408l-1.1,1.1 M538.5,402l-1.5,1.5l-4.3,2.1l-5,1.2
		l-5.5,0.3l-5.2-0.7l-4-1.5l-2.6-2 M538.3,396.4l-1,1.1l-4.2,2.2l-5,1.2l-5.5,0.4l-5.2-0.6l-4.1-1.4l-2.8-2l0-0.1 M538.4,390.4
		l-0.9,1l-4.1,2.2l-4.9,1.3l-5.5,0.4l-5.3-0.5l-4.2-1.3l-2.9-2l-0.1-0.3 M540.8,379.8l-0.6,2.7l-2.4,2.8l-4,2.3l-4.9,1.4l-5.4,0.5
		l-5.3-0.4l-4.3-1.3l-3-1.9l-0.7-1.3 M537.9,408.7l-0.6,0.5 M539.5,407.4L539.5,407.4l-1,0.8 M524.5,410.7l-6.3-0.6l-5.4-1.6
		l-3.9-2.4l-1.9-2.8l0.2-2.9 M1085,660.9l-0.1-0.3l-0.3-1.7l0.1-2.1l0.5-2.3l0.8-2.2l1-1.9l1.2-1.4 M1088.2,649l0.3-0.2 M1171,685.3
		l1.7,0.5 M1173.4,676.6l2.5,0.7 M1170.1,679.4l2.5,0.7 M1171,676.7l1.7,0.5 M1168.4,680.8l2.5,0.7 M1093,646.6L1093,646.6l-1.3,0.1
		l-1.4,0.8 M1090.3,647.5l-1.4,1.5l-1.2,2.1l-1,2.5l-0.7,2.6l-0.4,2.6v2.4l0.4,1.9l0.7,1.4l1,0.6 M1164.7,668.5l-0.4,0.3l-1.4,1.5
		l-1.2,2.1l-1,2.5l-0.7,2.6l-0.4,2.6v2.4l0.4,1.9l0.1,0.3 M1175.9,674.4l-3.3,2.8 M1175.9,677.3v-2.9 M1172.6,680.1l3.3-2.8
		 M1172.6,685.8v-5.6 M1171,687.2l1.7-1.5 M1171,681.6v5.6 M1167.7,684.4l3.3-2.8 M1167.7,681.5v2.9 M1171,678.7l-3.3,2.8 M1171,673
		v5.6 M1172.6,671.5l-1.7,1.5 M1172.6,677.2v-5.6 M1165.6,694.7l-0.6-0.2l-1.3-1.1l-1-1.8l-0.6-2.5l-0.2-3l0.2-3.3l0.6-3.5l1-3.5
		l1.3-3.4l1.6-3l1.7-2.6l1.8-1.9 M1170.1,664.7l1.8-1.2l1.7-0.5l1,0.1 M1171.8,665.2l1.8-1.2l1.7-0.5l1.6,0.3l1.3,1.1l1,1.8l0.6,2.5
		l0.2,3l-0.2,3.3l-0.6,3.5l-1,3.5l-1.3,3.4l-1.6,3l-1.7,2.6l-1.8,1.9l-1.8,1.2l-1.7,0.5l-1.6-0.3l-1.3-1.1l-1-1.8l-0.6-2.5l-0.2-3
		l0.2-3.3l0.6-3.5l1-3.5l1.3-3.4l1.6-3l1.7-2.6L1171.8,665.2 M1048.8,659.1l-0.6-0.1l-0.2-0.1 M1054.7,657.8l0.2-0.1l0.5-0.5
		l0.3-0.8l0-0.3v0 M1062.5,629.7L1062.5,629.7l0.1-0.3l0.2-0.9l-0.1-0.8l-0.4-0.7 M1059.1,624.8l0.3,0.2 M1060,590.7L1060,590.7
		l0.2-0.3l0.2-0.7l-0.1-0.6l0,0 M1042.8,679.9l0.5-0.1 M1043.4,679.8l0.8-0.4l0.6-0.6l0.5-0.9l0.1-0.3l0,0 M1063.7,606.9
		L1063.7,606.9l0.1-0.4l0-1l-0.3-0.9l-0.5-0.7 M1063,603.9l-0.4-0.3 M1019.7,700.4l0.7-0.3l0.5-0.6l0.4-0.8l0.1-0.3l0,0
		 M1020.6,698.4L1020.6,698.4l-0.1,0.4l-0.5,0.8l-0.7,0.6l-0.9,0.3 M1017.9,699.8l0.1,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1
		l0.1,0l0.1,0 M974.9,710.3l44.6-9.8 M1019.6,700.5l0.2,0 M1019.7,700.4l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0
		 M1018.3,700.6l-0.2,0 M1051.4,578.7L1051.4,578.7l-0.2-0.1 M1029.9,694.7l0.5-0.1 M1030.5,694.6l0.9-0.3l0.8-0.5l0.6-0.8l0.2-0.3
		l0,0 M1018.2,700.6l-33.4,7.3l-6.6,1.4l-4.9,1.1 M1017.3,699.8l0.1,0.2l0.1,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0
		 M1051.7,581.3L1051.7,581.3l0.1-0.4l0.1-0.9l-0.2-0.8l-0.5-0.7 M1050,578.1L1050,578.1l-0.1,0l-0.1,0.1l-0.1,0.1l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.1 M1050,578.1l0.6,0.7l0.3,0.9l0.1,1l0,0.3l0,0 M1059.6,589.6L1059.6,589.6l0-0.4l-0.2-1l-0.4-0.8
		l-0.6-0.7 M1058.4,586.8l-0.4-0.3 M1050,578.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.1,0.1l0.2,0.1 M1051.3,578.5
		l-0.1-0.1 M1051.2,578.4l-25-24.4l-4.9-4.8l-3.7-3.6 M1049.9,577.9l0.1,0.1 M1049.9,577.9l-0.1,0h-0.1l-0.1,0l-0.1,0l-0.2,0.1
		l-0.2,0.1l-0.2,0.1l-0.2,0.1 M1016,545l33.9,32.9 M1008.9,696.9l0.9-0.3l0.7-0.6l0.5-0.8l0.1-0.3l0,0 M1010.4,694L1010.4,694
		l-0.2,0.3l-0.7,0.7l-0.8,0.5l-0.9,0.3 M1007.7,694.5l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.1l0,0.1l0,0.1 M1017.3,699.8l0.6,0
		 M1017.9,699.8l1-0.2l0.8-0.4l0.7-0.6l0.2-0.2l0,0 M1050.9,581L1050.9,581l-0.1-0.3l-0.4-0.8l-0.6-0.7l-0.7-0.6 M1049.2,578.6
		l-0.5-0.3 M1040.2,582.1L1040.2,582.1l0.1-0.4l-0.1-1l-0.3-0.9l-0.6-0.7 M1037.8,579.8L1037.8,579.8l-0.2,0.2l-0.1,0.1l-0.1,0.1
		l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M1037.8,579.8l0.7,0.7l0.5,0.8l0.3,0.9l0,0.3v0 M1023.5,646.7l0.8-0.4l1.4-1.2l1.4-1.9
		l1.2-2.4l0.9-2.7l0.6-2.8l0.2-2.7 M1030.1,632.6l-0.2-2.3l-0.6-1.7l-0.4-0.6 M960.3,705.4l48.4-8.5 M1008.7,696.9l0.2,0
		 M1008.9,696.9l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1 M1007.8,695.8l-0.2,0 M962.3,707.8l0-2.7
		 M970.9,710l24.4-5.4l8.7-1.9l13.3-2.9 M1007.6,695.8l-36.2,6.1l-7.1,1.2L959,704 M1007.1,694.4l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2
		l0.1,0.2l0.1,0.1l0.1,0.1l0.1,0.1 M1048.7,578.3l-12.6-12.2l-22.4-21.7 M1002.5,547.1l0.3-0.7l2.4-4.4 M1037.7,579.7l0.2,0.1
		 M1037.8,579.8l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1 M1039.3,579.1l-0.1-0.1 M1039.2,578.9l-28.1-24.2
		l-5.5-4.8l-4.2-3.6 M1037.7,579.7l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.2,0.2l-0.2,0.2 M999.5,547.3
		l38.1,32.3 M1007.1,694.4l0.6,0 M1007.7,694.5l1,0l0.8-0.1l0.7-0.2l0.2-0.1l0,0 M1002,683.3l0.9-0.3l0.8-0.5l0.7-0.7l0.2-0.3l0,0
		 M1004.3,680.2L1004.3,680.2l-0.2,0.2l-0.7,0.4l-0.8,0.3l-1,0.1 M1001.7,679.6l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2
		 M1026.9,589.7L1026.9,589.7l-0.1-0.1h-0.1l-0.1,0l-0.1,0l-0.1,0.1l-0.1,0.1l-0.1,0.1 M1039.3,582.5L1039.3,582.5l-0.1-0.2
		l-0.5-0.5l-0.7-0.5l-0.9-0.4 M1037,581l-0.5-0.3 M1026.5,589.4l0.4,0.3 M1026.9,589.7l0.5,0.7l0.2,0.9l0,1l-0.1,0.3l0,0
		 M1027.5,592.7L1027.5,592.7l0-0.3l-0.3-0.9l-0.5-0.8l-0.7-0.7 M1024.6,591.8l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2
		l-0.1,0.2l-0.1,0.2l-0.1,0.2 M1024.6,591.8l0.8,0.5l0.6,0.6l0.4,0.7l0.1,0.2l0,0 M1000.3,662l0,0.2l0,0.2l0,0.2l0,0.1l0,0.1l0,0.1
		l0,0.1l0,0.1 M948.6,699.8l1.3-6l1.7-6.1l0.1-0.3 M956.9,703l26.4-4.5l9.4-1.6l14.4-2.5 M1000.3,662l1-0.1l0.8-0.3l0.7-0.4l0.2-0.2
		l0,0 M1002,683.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3 M1001.5,681.2l-0.2,0 M951,687
		l50.8-3.7 M1001.8,683.3l0.2,0 M1003,661.1L1003,661.1l-0.2,0.3l-0.6,0.8l-0.7,0.5l-0.9,0.3 M1000.6,663.1l-0.6,0 M1001.1,679.4
		l0.6,0.1 M1001.7,679.6l0.9,0.2l0.8,0.2l0.7,0.2l0.2,0l0,0 M1000.9,657.6l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2
		l-0.1,0.2l-0.1,0.2 M1003.1,659.3L1003.1,659.3l-0.2,0.1l-0.7,0.1l-0.8,0l-1-0.1 M1001.3,681.2l-37.8,2.4L956,684l-5.6,0.4
		 M1001.1,679.4l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M1014.8,608.5L1014.8,608.5l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.2 M1026.5,589.4L1026.5,589.4 M1036.5,580.7l-14.2-12l-25.2-21.4 M984.7,561.2l0.2-1.5l0.6-3.4l1.3-5.2
		l1.6-5.2 M1012.7,612l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M1015.9,611.4L1015.9,611.4l-0.1-0.2l-0.4-0.7l-0.6-0.6
		l-0.8-0.5 M1012.7,612l0.9,0.3l0.8,0.3l0.6,0.3l0.1,0.1l0,0 M1024.4,591.7l0.2,0.1 M1024.6,591.8l0.2-0.2l0.2-0.2l0.2-0.2l0.2-0.2
		l0.2-0.2l0.2-0.2l0.2-0.2l0.2-0.2 M1026.1,590l-0.2-0.1 M1026,589.8l-31.5-21.3l-6.2-4.2l-4.7-3.2 M1024.4,591.7l-0.1,0.2l-0.1,0.2
		l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M981.7,563.5l42.8,28.2 M1004.1,636.8l0,0.2v0.2v0.2l0,0.2v0.2l0,0.2v0.1
		l0,0.1 M1005.6,632.5l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M1007.2,635L1007.2,635l-0.2-0.1
		l-0.6-0.3l-0.7-0.3l-0.9-0.3 M1004.1,636.8l0.9,0.1l0.8,0l0.7-0.1l0.2,0l0,0 M1026.6,593.9L1026.6,593.9l-0.2,0l-0.7-0.1l-0.8-0.2
		l-0.9-0.2 M1024,593.3l-0.6-0.2 M1014.3,608.1l0.5,0.3 M1014.8,608.5l0.6,0.7l0.4,0.8l0.1,1l0,0.3l0,0 M1006.8,636.8L1006.8,636.8
		l-0.2,0.3l-0.7,0.6l-0.8,0.4l-0.9,0.2 M1004.1,638.3l-0.6,0 M1000.4,657.4l0.5,0.2 M1000.9,657.6l0.8,0.5l0.7,0.5l0.5,0.5l0.1,0.2
		l0,0 M1015.1,613.1L1015.1,613.1l-0.2,0.1l-0.7,0.3l-0.8,0.1l-1,0 M1012.3,613.6l-0.6-0.1 M1005.1,632.2l0.5,0.3 M1005.6,632.5
		l0.7,0.6l0.5,0.7l0.3,0.8l0.1,0.3l0,0 M1000.1,662l0,0.2l0,0.2l0,0.2v0.1v0.1l0,0.1l0,0.1l0,0 M1002.8,663.6l-0.5-0.1
		 M1000.1,663.1l-19.1-1.4l-33.9-2.5 M942.4,659.5l-0.5,0.1l-2.4-0.3 M940.8,678.8l2.3-5.6l2.6-5.3l1.6-2.6l1.2-1.9l0.5-1.1l0.3-1.2
		l0.1-1 M948.6,682.8l27.6-1.8l9.8-0.6l15.1-1 M1000.3,662l0-0.3l0-0.3l0-0.3l0-0.3l0-0.3l0-0.3l0-0.3l0-0.3 M1000.4,659.3l-0.2,0
		 M948.7,658.3l51.3,3.8 M1000.1,662l0.2,0 M1000.2,659.2l-38.1-3.3l-7.5-0.6l-5.6-0.5 M1000.4,657.4l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2
		l0,0.2l0,0.2l0,0.2 M1014.3,608.1L1014.3,608.1l-0.1,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2 M1012.5,611.9
		l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M1012.7,612l0.1-0.3l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3
		l0.2-0.3l0.2-0.3l0.2-0.3 M1013.9,609.3l-0.2-0.1 M1013.7,609.2L979,593l-6.8-3.2l-5.1-2.4 M965.5,590.7l46.9,21.2 M1012.5,611.9
		l0.2,0.1 M1003.9,636.8l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l0,0.2l0,0.2l0,0.1l0,0.1 M1005.1,632.2l0,0.1l0,0.2l-0.1,0.2l-0.1,0.2
		l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M1062.2,631.6l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2 M1062.5,629.7l-0.6-0.6
		l-1.6-1.1l-1.8-0.4l-1.5,0.3l-0.9-0.3l-0.6-1l-0.2-0.9l0-2.2l0.3-1.7l0.6-1.7l0.7-1.4l1.2-1.6l2.1-2.7l3.3-6.1 M1063.8,608.4l0-0.2
		l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2 M1063.7,606.9l-0.8,0.1l-2,0.6l-2.1,1.3l-1.6,1.5l-1.1,0.6l-0.9-0.2l-0.4-0.5l-0.5-1.6
		l-0.1-1.5l0.3-1.7l0.5-1.7l1-2.2l1.7-3.7l2.3-7.2 M1060,590.7l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
		l-0.1-0.1 M1059.6,589.6l-1.4,1.4l-2.9,3.5l-1.3,2.2l-1.4,2.2l-1,0.9l-0.9,0.2l-0.1,0l-0.9-0.7l-0.4-1l-0.1-1.5l0.2-1.6l0.6-2.5
		l1-4.1l0.8-7.1 M1051.7,581.3L1051.7,581.3l-0.2-0.1l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0 M1050.9,581l-0.8,1.3l-1.8,3.4
		l-1.6,3.7l-1,3l-0.9,1.9l-1,1.4l-0.6,0.5l-1.2,0.3l-0.7-0.4l-0.5-0.9l-0.2-1.3l0.1-2.3l0.2-3.8l-0.7-5.8 M1040.2,582.1l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M1039.3,582.5l-0.5,1.6l-1.2,4l-0.9,4l-0.4,3l-0.5,2.1l-0.8,1.9
		l-0.6,0.9l-1.2,1.2l-0.9,0.4l-0.7-0.2l-0.5-0.7l-0.4-1.7l-0.7-2.8l-2.2-3.5 M1027.5,592.7l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M1026.6,593.9l-0.2,1.6l-0.4,3.9l-0.1,3.7l0.3,2.5l-0.1,1.9l-0.5,2.1l-0.4,1.1l-1.1,1.9
		l-0.9,1l-0.9,0.5l-0.8-0.1l-0.9-0.8l-1.5-1.4l-3.3-0.6 M1015.9,611.4l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2
		l-0.1,0.2l-0.1,0.2 M1015.1,613.1l0.2,2.3l0.8,4.2l0.7,1.6l0.7,1.7l0.1,1.6l-0.2,1.8l-0.1,0.3l-0.8,2.3l-0.8,1.5l-0.9,1.1l-0.9,0.6
		l-1.2,0.2l-2,0.3l-3.8,2.5 M1007.2,635l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M1006.8,636.8
		l0.4,0.9l1.2,1.8l1.5,1.2l1.4,0.3l0.7,0.7l0.4,1.4l0,1l-0.3,2.3l-0.5,1.7l-0.7,1.6l-0.8,1.2l-1.3,1.2l-2.1,2l-3.6,5.1
		 M1003.1,659.3l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M1003,661.1l0.7,0.2l1.8,0.2l2-0.5l1.6-1l1-0.1l0.8,0.6l0.3,0.7
		l0.2,1.9l-0.1,1.7l-0.4,1.8l-0.6,1.6l-1.2,2l-1.9,3.3l-2.9,6.8 M1004.3,680.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2
		 M1004.6,681.5l0.8-0.5l2.1-1.5l2.1-2.1l1.6-2.1l1.1-1l1-0.3l0.5,0.2l0.7,1.2l0.2,1.3l-0.1,1.6l-0.3,1.7l-0.8,2.4l-1.4,4l-1.6,7.3
		 M1010.4,694L1010.4,694l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M1011.1,694.7l1.4-1.8l2.7-4.3l1.2-2.5l1.2-2.5
		l0.9-1.2l1-0.6l0.1,0l1,0.2l0.6,0.7l0.3,1.2l0,1.5l-0.4,2.5l-0.6,4l-0.1,6.6 M1020.6,698.4l0.1,0l0.1,0l0.1,0l0.1,0l0.1,0l0.1,0
		l0.1,0l0.1,0 M1021.4,698.4l0.7-1.5l1.5-3.8l1.3-4l0.7-3.1l0.7-2l0.9-1.7l0.6-0.7l1.2-0.8l0.8,0l0.6,0.6l0.4,1l0.2,2.1l0.3,3.4
		l1.5,4.7 M1032.9,692.7l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M1033.9,691.8l0.4-1.6l0.8-4.1l0.5-3.9
		l0.1-2.9l0.3-2.1l0.7-2.1l0.5-1l1.2-1.6l0.9-0.7l0.8-0.1l0.7,0.4l0.7,1.3l1.1,2.2l2.8,2.1 M1045.4,677.7l0.1-0.2l0.1-0.2l0.1-0.2
		l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2 M1046.2,676.2l0.1-1.5l0-3.6l-0.4-3.2l-0.6-2.1l-0.1-1.7l0.3-2l0.3-1.2l0.9-2.2l0.8-1.3
		l0.9-0.8l0.8-0.3l1.1,0.3l1.8,0.5l3.6-1 M1055.7,656.1l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2
		 M1056.4,654.2l-0.3-1.1l-0.9-2.6l-1.2-2l-1.1-1l-0.6-1.1l-0.2-1.7l0.1-1.1l0.5-2.4l0.6-1.7l0.8-1.4l0.9-0.9l1.3-0.7l2.1-1.2
		l3.8-3.9 M1043.3,630.9l-0.2,3.6l-0.6,3.8l-1,3.9l-1.3,3.8l-1.6,3.5l-1.8,3.1l-1.9,2.5l-2,1.9l-2,1.2l-1.9,0.4l-1.7-0.4l-1.4-1.1
		l-1.1-1.9l-0.8-2.5l-0.4-3v-3.5l0.4-3.7l0.8-3.9l1.1-3.8l1.4-3.6l1.7-3.3l1.9-2.8l2-2.2l2-1.6l1.9-0.8l1.8,0l1.6,0.8l1.3,1.5l1,2.2
		l0.6,2.8L1043.3,630.9 M1023.4,593.2l-15.9-10.5L979.3,564 M968.4,584.6L968.4,584.6l1.1-1.9l0.4-1.1l0.2-1.1l0.1-1.5l-0.3-2.7
		l-0.1-3l0.2-4.9l0.6-5.2 M959.6,581.4l2.7,0.4l1.2,0.6 M966.1,585.6l25.3,11.8l9,4.2l13.8,6.5 M1004.1,636.8l0.1-0.4l0.1-0.4
		l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4 M1004.8,633.9l-0.2-0.1 M1004.6,633.8l-37-9.8l-7.3-1.9l-5.5-1.5 M953.9,624.2
		l50,12.6 M1003.9,636.8l0.2,0 M938.1,687.7l-1.6-4.4l-1.4-5.5l-1-6l-0.6-6.5l-0.2-6.9 M933.4,658.5l0.2-7.2l0.6-7.5l1-7.7l1.4-7.8
		l1.7-7.9l2.1-7.9l2.4-7.8l2.7-7.6l3-7.3l3.3-7l3.5-6.7l3.7-6.2l3.8-5.7l4-5.1l4-4.5l4.1-3.9l3.6-2.8 M1000.4,657.4l4-6.1l1-1.4
		l1.2-1l1.1-0.6l0.6-0.6l0.5-0.7l0.3-0.7l0-0.1l0.2-1.3l0-0.1l-0.1-0.5l-0.4-0.7l-0.7-0.4l-1.3-0.3l-1.5-1.3l-1.3-2l-0.6-1.2
		 M1003.5,638.3l-18.6-4.7l-33-8.3 M940.1,648.5l3-4.2l3.1-3.5l1.7-1.5l1.3-1.1l0.6-0.8l0.5-1.1l0.3-1.1l0.3-2.2L951,632l-0.3-0.7
		l-0.6-0.5l-1.4-0.4l-1.4-0.6l-1.8-1.9l-1.6-2.5 M947.4,652.9l27.8,2.4l9.9,0.8l15.2,1.3 M1001.2,540.8l-2.3-0.5l-3.6-0.1l-3.8,0.7
		l-3.9,1.4l-4,2.1l-4.1,2.8l-4.1,3.5l-4.1,4.2l-4,4.8l-3.9,5.4l-3.8,5.9l-3.6,6.4l-3.4,6.8l-3.2,7.2l-3,7.4l-2.7,7.7l-2.4,7.8
		l-2,7.9l-1.7,7.9l-1.3,7.8l-1,7.7l-0.6,7.5l-0.2,7.2 M934.5,660.5l0.2,6.9l0.6,6.5l1,6l1.3,5.5l1.7,5l2,4.4l2.4,3.7l2.7,3l3,2.3
		l3.2,1.6l1.1,0.4 M973.2,710.5l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0 M973.2,710.5l-0.2,0l-0.3,0l-0.6-0.1l-1.2-0.3
		 M970.9,710l-7.4-1.9l-1.2-0.3 M952.6,704l1.1,0.8l1.1,0.7l1.2,0.6l1.2,0.5l1.2,0.4l1.3,0.3l1.3,0.2l1.3,0.1 M952.6,704l0.9,0.2
		l5.7,1.2 M959.2,705.4l0.8,0.1l0.2,0l0.1-0.1l0-0.1l0,0 M959,704l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2
		 M959,704l-0.1-0.1l-0.3-0.2l-0.5-0.3l-1.2-0.5 M956.9,703l-7.2-2.7l-1.1-0.4 M942.6,687l0.6,1.9l0.6,1.8l0.7,1.7l0.7,1.7l0.8,1.6
		l0.8,1.5l0.9,1.4l0.9,1.3 M942.6,687l1,0.1l6.1,0.5 M949.7,687.6l0.9-0.1l0.3-0.1l0.2-0.2l0-0.2l0-0.1 M950.4,684.4l0.1,0.3
		l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3 M950.4,684.4l-0.1-0.2l-0.2-0.3l-0.5-0.4l-1.1-0.7 M948.6,682.8
		l-6.8-3.4l-1.1-0.5 M939.5,659.2l0,2.6l0,2.6l0.1,2.5l0.1,2.5l0.2,2.4l0.2,2.4l0.3,2.3l0.3,2.2 M939.5,659.2l1,0l6.5-0.1
		 M947.1,659.2l1-0.2l0.3-0.2l0.3-0.3l0.1-0.2l0-0.1 M948.9,654.8l0,0.4l0,0.4l0,0.4l0,0.4l0,0.4l0,0.4l0,0.4l0,0.4 M948.9,654.8
		l0-0.3l-0.1-0.4l-0.4-0.5l-0.9-0.8 M947.4,652.9l-6.3-3.9l-1-0.6 M943.8,625.5l-0.6,2.9l-0.6,2.9l-0.5,2.9l-0.5,2.9l-0.4,2.9
		l-0.4,2.9l-0.3,2.8l-0.3,2.8 M943.8,625.5l1.1,0l7-0.2 M951.9,625.2l1.1-0.2l0.4-0.2l0.3-0.3l0.1-0.3l0-0.1 M954.8,620.5l-0.1,0.5
		l-0.1,0.5l-0.1,0.5l-0.1,0.5l-0.1,0.5l-0.1,0.5l-0.1,0.5l-0.1,0.5 M954.8,620.5l0-0.3l-0.1-0.4l-0.3-0.5l-0.8-0.8 M954.9,591.6
		l-1.1,2.7l-1.1,2.7l-1.1,2.8l-1,2.8l-1,2.8l-0.9,2.8l-0.9,2.9l-0.9,2.9 M963.3,591.6l1.2-0.2l0.4-0.2l0.4-0.3l0.2-0.2l0-0.1
		 M967.1,587.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4 M967.1,587.4l0.1-0.3l0-0.3l-0.2-0.5
		l-0.8-0.7 M966.1,585.6l-5.6-3.7l-0.9-0.6 M970.6,563.4l-1.4,2l-1.4,2.1l-1.4,2.2l-1.4,2.2l-1.4,2.3l-1.3,2.3l-1.3,2.4l-1.3,2.5
		 M970.6,563.4l1.2,0.1l7.5,0.5 M979.3,564l1.2,0l0.5-0.1l0.4-0.2l0.2-0.2l0,0 M983.5,561.2l-0.2,0.3l-0.2,0.3l-0.2,0.3l-0.2,0.3
		l-0.2,0.3l-0.2,0.3l-0.2,0.3l-0.2,0.3 M983.5,561.2l0.1-0.2l0-0.2l-0.2-0.4l-0.7-0.6 M982.7,559.8l-5.4-3.1l-0.9-0.5 M988.4,545.9
		l-1.5,1l-1.5,1.1l-1.5,1.2l-1.5,1.3l-1.5,1.3l-1.5,1.4l-1.5,1.5l-1.5,1.6 M988.4,545.9l1.2,0.2l7.5,1.2 M997.2,547.3l1.2,0.1l0.5,0
		l0.4,0l0.2-0.1l0,0 M1001.4,546.4l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1 M1001.4,546.4
		L1001.4,546.4l0.1-0.2l-0.2-0.2l-0.7-0.4 M1000.5,545.6l-5.4-2.3l-0.9-0.4 M1005.2,541.9l-1.3-0.2l-1.3-0.1l-1.3,0l-1.4,0.1
		l-1.4,0.2l-1.4,0.3l-1.4,0.4l-1.4,0.5 M1005.2,541.9l1.2,0.3l7.4,2.1 M1013.7,544.4l1.2,0.3l0.4,0.1l0.4,0.1l0.2,0.1l0,0
		 M1017.5,545.6l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1 M1017.5,545.6L1017.5,545.6
		 M1005.1,632.2l4.2-3.1l1-0.4l1.1,0l1,0l0.7-0.2l0.6-0.4l0.4-0.6l0-0.1l0.4-1.3l0-0.1l0-0.1l0-0.7l0-0.1l-0.3-0.8l-0.8-1.1
		l-0.5-0.9l-0.2-1l-0.7-4.6l-0.2-2.9 M1011.8,613.5l-17.4-7.9l-31-14.1 M954.9,591.6l1.1,0l7.3,0 M946.8,614l3.1-2.1l3-1.2l1.6-0.1
		l1.2,0l0.6-0.4l0.6-0.8l0.5-0.9l0.7-2.2l0.2-1.1l0-0.9l-0.3-1.1l-0.9-1.8l-0.8-1.9l-0.9-3.7l-0.6-4.1 M953.6,618.6l-5.9-4l-0.9-0.6
		 M953.6,618.6l27,7.2l9.6,2.6l14.8,3.9 M590.5,527.4l-0.3-0.9l-0.5-2.6l-0.1-3.1l0.3-3.4l0.6-3.6l1-3.6l1.3-3.4 M603.7,497.4
		l3.1-0.8 M592.8,506.8l10.9-9.5 M593,508.6l-0.2-1.7 M593,508.6l48.1,13.9 M593,508.6l-1.2,3.7l-0.9,3.8l-0.5,3.7l-0.1,3.5l0.3,3.1
		l0.7,2.6l1,2l1.3,1.3l1,0.4 M649,508.8l-42.2-12.2 M656.7,505.6l-0.3-0.1l-2,0l-2.1,0.8l-2.2,1.6 M650,507.9l-2.2,2.3l-2.1,2.9
		l-2,3.4l-1.8,3.9l-1.5,4.1l-1.2,4.3l-0.8,4.3l-0.4,4.1v3.9l0.4,3.4l0.8,2.9l1.2,2.3l1.5,1.5l1.4,0.7 M664.8,512.9l-0.6-1.9
		l-1.2-2.3l-1.5-1.6l-1.8-0.8l-2,0l-2.1,0.8l-2.2,1.6 M653.4,508.8l-2.2,2.3L649,514l-2,3.4l-1.8,3.9l-1.5,4.1l-1.2,4.3l-0.8,4.3
		l-0.4,4.1v3.9l0.4,3.4l0.8,2.9l1.2,2.3l1.5,1.6l1.8,0.8l2,0l2.1-0.8l2.2-1.6l0.7-0.6 M742,563.3l1.1,0.1l1.5-0.7l1.5-1.5l1.3-2.1
		l0.4-0.9 M742.1,560.8l1.3-1.7l1.2-2.3l0.8-2.5l0.4-2.4l0-2l-0.1-0.3 M744.4,565.2l1.5-1l1.8-2l1.5-2.7l1.2-3l0.7-3l0.1-2.7l-0.4-2
		l-0.1-0.2 M742.9,562.8l0.7-0.6l1.6-2.5l1.3-2.9l0.9-3.1l0.3-2.9l-0.1-1.8 M745,550.9l0,1.4l-0.5,2.4l-0.9,2.4l-1.1,1.9
		 M747.7,549.2l0.5,1.2l0.1,1.9l-0.3,2.3l-0.7,2.5l-1.1,2.3l-1.4,1.9l-1.5,1.3l-1.1,0.4 M747.7,558.2l0.4-1.4l0.3-1.4l0.2-1.4
		l0.1-1.3l-0.1-1.2l-0.2-1l-0.3-0.8l-0.4-0.6 M747.7,558.2l0.1-0.1l1.4-2.5l0.9-2.7l0.4-2.2l0-2 M750.6,548.5l0.3,0.8l0.2,0.9
		l0.2,1.1l0,1.2l0,1.3l-0.2,1.3l-0.2,1.4l-0.3,1.4 M746.6,548.7l0.6,0l0.5,0.5 M750.6,557.7l-1.1,2.5l-1.5,2.5l-1.7,1.7l-1.7,0.8
		l-1.4-0.3l-0.8-0.9 M751.4,540.1l0.1-2 M704.8,537.9l11.6-10 M747.4,566.1L746,567l-1.3,0.1l-1.1-0.6l-0.8-1.3l-0.5-1.9l-0.1-2.4
		l0.3-2.7l0.7-2.8l1-2.7l1.2-2.4l1.4-1.9l1.4-1.3l1.4-0.5l1.2,0.2l1,1l0.7,1.6l0.3,2.2l-0.1,2.6l-0.5,2.8l-0.8,2.8l-1.1,2.5
		l-1.3,2.1L747.4,566.1 M751.4,540.1l-8,6.9 M743.4,573.7l8-6.9 M751.4,566.8l1.6-3.2l1.3-3.5l1-3.7l0.6-3.6l0.2-3.4l-0.2-3.1
		l-0.6-2.6l-1-2l-1.3-1.2l-1.6-0.4 M743.4,573.7l-3.5,1.1 M743.4,546.9l-1.6,3.2l-1.3,3.5l-1,3.7l-0.6,3.6l-0.2,3.4l0.2,3.1l0.6,2.6
		l1,2l1.3,1.2l1.6,0.4 M739.9,548l3.5-1.1 M658.7,511.2l-1.5-0.1l-1.9,0.6l-1.9,1.3 M653.4,513l-1.9,2l-1.9,2.6l-1.7,3.1l-1.5,3.5
		l-1.2,3.7l-0.9,3.8l-0.5,3.7l-0.1,3.5l0.3,3.1l0.7,2.6l1,2l1.3,1.3l0.9,0.4 M704.8,564.6l35.1,10.1 M739.9,574.8l-1.5-1.1l-1.2-1.8
		l-0.8-2.5l-0.4-3V563l0.4-3.7l0.8-3.8l1.2-3.8l1.5-3.6 M739.9,548l-35.1-10.1 M716.4,527.9l-1.3-0.3l-1.4,0.2l-1.5,0.7l-1.5,1.1
		l-1.5,1.6l-1.5,1.9l-1.4,2.3l-1.3,2.6 M716.4,527.9l35.1,10.1 M751.5,538l1.5,1.1l1.2,1.8l0.2,0.4 M704.8,564.6l0.9,0.3
		 M441.9,486.7l-1.2,2.3l-2.3,4 M436.6,492.5l-0.9,0l-28.9,0.9 M405.5,494.5l1.2-1.1 M462.3,499.9l-25.6-7.4 M455.7,491.2
		L455.7,491.2l-2.5,3.3l-2.1,2.2 M540.4,515.5l0.8,1.2l1.4,1.1l1.7,0.4l1.8-0.4l1.9-1.2l2-1.9l1.9-2.5l1.8-3l1.5-3.4l1.3-3.7
		l0.9-3.8l0.6-3.7l0.2-3.5 M558.2,491.1l-0.2-3.2l-0.6-2.7l-0.9-2.1l-1.3-1.5l-1.5-0.7l-1.8,0l-1.7,0.7 M539.4,455l-0.3-1.2
		l-2.5-1.5l-1.9-0.7l-2.3-0.7l-2.6-0.7l-2.8-0.6l-5.6-0.8l-5,0.3l-2.1,0.7l-1.7,1.1 M512.6,450.6l-1.2,1.4l-0.6,1.7l0.4,3.8l2.2,3.1
		 M560.8,490.8l-0.2,3.8l-0.6,4l-1,4l-1.3,3.9l-1.6,3.7l-1.8,3.3l-2,2.8l-2.1,2.1l-2.1,1.4l-2,0.7l-1.8-0.1l-1.6-0.9l-1.3-1.6
		l-1-2.3l-0.6-2.9l-0.2-3.4l0.2-3.8l0.6-4l1-4l1.3-3.9l1.6-3.7l1.8-3.3l2-2.8l2.1-2.1l2.1-1.4l2-0.7l1.8,0.1l1.6,0.9l1.3,1.6l1,2.3
		l0.6,2.9L560.8,490.8 M510.8,446.8l4.6-2.3l6-0.6l6.6,0.7l3.2,0.7l3,0.8l2.7,0.8l2.2,0.9l2.7,1.9l0.2,2.4l-2.4,2.9l-4.6,3.1l-6,2.7
		l-6.6,1.4l-3.2,0l-3-0.5l-2.6-1.1l-2.2-1.6l-2.7-4.1l-0.2-4.5L510.8,446.8 M454,490.2l2.9,1.4l2.5,2.4l1.9,3l1.1,3.3l0.2,3.1
		l-0.7,2.6l-1.5,1.9l-2.3,1l-2.9,0l-1.2-0.3 M454,508.5l-48.5-14 M415.7,511.3l-2.3-1l-2.2-1.8l-1.9-2.5l-1.6-3.2l-1.2-3.8l-0.9-4.4
		 M405.5,476.2l0.9-5.9l1.2-6l1.6-6l1.9-5.9l2.2-5.7l2.5-5.4l2.7-5l2.9-4.5l3-4l3.1-3.3l3.1-2.7l3.1-2l3-1.2l2.9-0.5l2.7,0.3
		l0.2,0.1 M405.5,476.2l48.5,14 M572.9,480.3l-0.2-5.1l-0.6-4.6l-1-4.1l-1.4-3.5l-1.7-2.8l-2-2.1l-2.3-1.4l-2.6-0.6l-2.7,0.2l-2.9,1
		l-3,1.7l-3,2.4l-3,3.1l-2.9,3.8l-2.8,4.3l-2.7,4.8l-2.4,5.2l-2.2,5.5l-1.9,5.7l-1.5,5.9l-1.2,5.9l-0.8,5.8l-0.4,5.5v5.2l0.4,4.9
		l0.8,4.4l1.2,3.8l1.5,3.2l1.9,2.5l2.2,1.8l2.4,1l2.7,0.2l2.8-0.6l2.9-1.3l3-2.1l3-2.8l3-3.5l2.9-4.1l2.7-4.6l2.6-5l2.3-5.4l2-5.7
		l1.7-5.8l1.4-5.9l1-5.8l0.6-5.7L572.9,480.3 M545.6,542.7l-0.2,0.2l-3.1,1.8l-3,1l-2.8,0.3l-2.6-0.5l-2.4-1.2l-2.2-2l-1.8-2.7
		l-1.5-3.4l-1.2-4l-0.8-4.5l-0.4-5v-5.4l0.4-5.7l0.8-5.9l1.2-6l1.5-6l1.8-5.9l2.2-5.7l2.4-5.4l2.6-5.1l2.8-4.6l3-4.1l3.1-3.5
		l3.1-2.8l3.1-2.1l3-1.4l2.9-0.7l2.7,0.1l2.5,0.9l2.3,1.6l2,2.3l1.7,3l0.4,0.8 M899.5,584.1l1.9-1.3l1.9-0.6l1.7,0.2l1.5,0.9
		l1.2,1.6l0.9,2.3l0.5,2.8l0.1,3.3l-0.3,3.6l-0.7,3.8l-1,3.8l-1.3,3.6l-1.6,3.3l-1.8,2.9l-1.9,2.3l-2,1.7l-1.9,1l-1.8,0.2l-1.6-0.5
		l-1.3-1.3l-1-2l-0.7-2.6l-0.3-3.1l0.1-3.5l0.5-3.7l0.9-3.8l1.2-3.7l1.5-3.5l1.7-3.1l1.9-2.6L899.5,584.1 M899.5,580.7l2.1-1.5
		l2.1-0.8l1.9,0l1.7,0.8l1.4,1.5l1.1,2.2l0.8,2.8l0.4,3.3v3.7l-0.4,4l-0.8,4.1l-1.1,4.1l-1.4,4l-1.7,3.7l-1.9,3.3l-2.1,2.8l-2.1,2.2
		l-2.1,1.5l-2.1,0.8l-1.9,0l-1.7-0.8l-1.4-1.5l-1.1-2.2l-0.8-2.8l-0.4-3.3v-3.7l0.4-4l0.8-4.1l1.1-4.1l1.4-4l1.7-3.7l1.9-3.3
		l2.1-2.8L899.5,580.7 M891.4,622.5l-1.4-0.7l-1.4-1.5l-1.1-2.2l-0.8-2.8l-0.4-3.3v-3.7l0.4-4l0.8-4.1l1.1-4.1l1.4-4l1.7-3.7
		l1.9-3.3l2.1-2.8l2.1-2.2 M897.8,580.2l2.1-1.5l2.1-0.8l1.9,0l0.3,0.1 M911,557.9l0.6,0.3l2.2,1.5l1.9,2.3l1.6,2.9l1.2,3.6l0.9,4.1
		l0.5,4.6l0.1,5l-0.3,5.4l-0.7,5.6l-1.1,5.7l-1.4,5.7l-1.8,5.7l-2.1,5.5l-2.3,5.2l-2.6,4.8l-2.7,4.4l-2.9,3.8l-2.9,3.2l-3,2.6
		l-2.9,1.9l-2.9,1.1l-2.7,0.4l-1.5-0.1 M861,639.9l-2.3-1l-2.2-1.8l-1.9-2.5l-1.6-3.2l-1.2-3.8l-0.9-4.4l-0.5-4.9l-0.1-5.3l0.3-5.6
		l0.7-5.8l1.1-5.9l1.4-6l1.8-5.9l2.1-5.8l2.4-5.5l2.6-5.2l2.8-4.7l2.9-4.2l3-3.7l3.1-3 M874.4,551.8l3.1-2.3l3-1.6l2.9-0.9l2.8-0.1
		l1.5,0.3 M899.5,559l3.1-2.3l3-1.6l2.9-0.9l2.8-0.1l2.6,0.7l2.4,1.4l2.1,2.2l1.8,2.9l1.4,3.5l1.1,4.1l0.7,4.6l0.3,5.1l-0.1,5.4
		l-0.5,5.7l-0.9,5.9l-1.3,6l-1.6,6l-1.9,5.9l-2.2,5.6l-2.5,5.3l-2.7,5l-2.9,4.5l-3,3.9l-3.1,3.3l-3.1,2.7l-3.1,2l-3,1.2l-2.9,0.5
		l-2.7-0.3l-2.5-1.1l-2.2-1.8l-1.9-2.5l-1.6-3.2l-1.3-3.8l-0.9-4.4l-0.5-4.9l-0.1-5.3l0.3-5.6l0.7-5.8l1.1-5.9l1.4-6l1.8-5.9
		l2.1-5.8l2.4-5.5l2.6-5.2l2.8-4.7l2.9-4.2l3-3.7L899.5,559 M899.5,562.3l3-2.2l2.9-1.5l2.8-0.7l2.6,0l2.4,0.8l2.2,1.5l1.9,2.3
		l1.6,2.9l1.2,3.6l0.9,4.1l0.5,4.6l0.1,5l-0.3,5.4l-0.7,5.6l-1.1,5.7l-1.4,5.7l-1.8,5.7l-2.1,5.5l-2.3,5.2l-2.6,4.8l-2.7,4.4
		l-2.9,3.8l-2.9,3.2l-3,2.6l-2.9,1.9l-2.9,1.1l-2.7,0.4l-2.6-0.4l-2.3-1.2l-2.1-1.9l-1.8-2.6l-1.4-3.3l-1.1-3.9l-0.7-4.4l-0.3-4.9
		l0.1-5.2l0.5-5.5l0.9-5.7l1.2-5.7l1.6-5.7l1.9-5.6l2.2-5.3l2.4-5l2.6-4.6l2.8-4.1l2.9-3.5L899.5,562.3 M816.2,560l1.9-1.3l1.9-0.6
		l1.7,0.2l1.5,0.9l1.2,1.6l0.9,2.3l0.5,2.8l0.1,3.3l-0.3,3.6l-0.7,3.8l-1,3.8l-1.3,3.6l-1.6,3.3l-1.8,2.9l-1.9,2.3l-2,1.7l-1.9,1
		l-1.8,0.2l-1.6-0.5l-1.3-1.3l-1-2l-0.7-2.6l-0.3-3.1l0.1-3.5l0.5-3.7l0.9-3.8l1.2-3.7l1.5-3.5l1.7-3.1l1.9-2.6L816.2,560
		 M816.2,556.7l2.1-1.5l2.1-0.8l1.9,0l1.7,0.8l1.4,1.5l1.1,2.2l0.8,2.8l0.4,3.3v3.7l-0.4,4l-0.8,4.1l-1.1,4.1l-1.4,4l-1.7,3.7
		l-1.9,3.3l-2.1,2.8l-2.1,2.2l-2.1,1.5L812,599l-1.9,0l-1.7-0.8l-1.4-1.5l-1.1-2.2l-0.8-2.8l-0.4-3.3v-3.7l0.4-4l0.8-4.1l1.1-4.1
		l1.4-4l1.7-3.7l1.9-3.3l2.1-2.8L816.2,556.7 M808.1,598.5l-1.4-0.7l-1.4-1.5l-1.1-2.2l-0.8-2.8l-0.4-3.3v-3.7l0.4-4l0.8-4.1
		l1.1-4.1l1.4-4l1.7-3.7l1.9-3.3l2.1-2.8l2.1-2.2 M814.5,556.2l2.1-1.5l2.1-0.8l1.9,0l0.3,0.1 M827.6,533.9l0.6,0.3l2.2,1.5l1.9,2.3
		l1.6,2.9l1.2,3.6l0.9,4.1l0.5,4.6l0.1,5l-0.3,5.4l-0.7,5.6l-1.1,5.7l-1.4,5.7l-1.8,5.7l-2.1,5.5l-2.3,5.2l-2.5,4.8l-2.7,4.4
		l-2.9,3.8l-2.9,3.2l-3,2.6l-2.9,1.9l-2.9,1.1l-2.7,0.4l-1.5-0.1 M816.2,538.3l3-2.2l2.9-1.5l2.8-0.7l2.6,0l2.4,0.8l2.2,1.5l1.9,2.3
		l1.6,2.9l1.2,3.6l0.9,4.1l0.5,4.6l0.1,5l-0.3,5.4l-0.7,5.6l-1.1,5.7l-1.4,5.7l-1.8,5.7l-2.1,5.5l-2.3,5.2l-2.5,4.8l-2.7,4.4
		l-2.9,3.8l-2.9,3.2l-3,2.6l-2.9,1.9l-2.9,1.1l-2.7,0.4l-2.5-0.4l-2.3-1.2l-2.1-1.9l-1.8-2.6l-1.4-3.3l-1.1-3.9l-0.7-4.4l-0.3-4.9
		l0.1-5.2l0.5-5.5l0.9-5.7l1.2-5.7l1.6-5.7l1.9-5.6l2.2-5.3l2.4-5l2.6-4.6l2.8-4.1l2.9-3.5L816.2,538.3 M777.7,615.8l-2.3-1
		l-2.2-1.8l-1.9-2.5l-1.6-3.2l-1.3-3.8l-0.9-4.4l-0.5-4.9L767,589l0.3-5.6l0.7-5.8l1.1-5.9l1.4-6l1.8-5.9l2.1-5.8l2.4-5.5l2.6-5.2
		l2.8-4.7l2.9-4.2l3-3.7l3.1-3 M791.1,527.7l3.1-2.3l3-1.6l2.9-0.9l2.8-0.1l1.5,0.3 M816.2,534.9l3.1-2.3l3-1.6l2.9-0.9l2.8-0.1
		l2.6,0.7l2.4,1.4l2.1,2.2l1.8,2.9l1.4,3.5l1.1,4.1l0.7,4.6l0.3,5.1l-0.1,5.4l-0.5,5.7l-0.9,5.9l-1.2,6l-1.6,6l-1.9,5.9l-2.2,5.6
		l-2.5,5.3l-2.7,5l-2.9,4.5l-3,3.9l-3.1,3.3l-3.1,2.7l-3.1,2l-3,1.2l-2.9,0.5l-2.7-0.3l-2.5-1.1l-2.2-1.8l-1.9-2.5l-1.6-3.2
		l-1.3-3.8l-0.9-4.4l-0.5-4.9l-0.1-5.3l0.3-5.6l0.7-5.8l1.1-6l1.4-6l1.8-5.9l2.1-5.8l2.4-5.5l2.6-5.2l2.8-4.7l2.9-4.2l3-3.7
		L816.2,534.9 M537,409.4l1-0.7 M510,407l3.3,2.4 M544.5,400.3v-0.7 M544.5,394.4v-0.7 M544.5,388.6v-0.7 M544.5,382.7V382
		 M505.9,384.9v0.7 M505.9,390.8v0.7 M505.9,396.6v0.7 M1085,660.9l1,2.5 M1090.7,647.2l-2.2,1.6 M1087.7,665.1l72.9,21.1
		 M1165.9,667.6l-72.9-21 M1160,684.8l2.9,7 M1170.8,664.1l-6.1,4.4 M1176.3,663.6l-1.7-0.5 M1165.6,694.7l1.7,0.5 M1056.7,653.3
		l0-0.1 M1056.7,654.2l0.1-0.6l0-0.2 M1055,657.6l0.2-0.1l0.5-0.7l0-0.2 M1055.9,656.5l0.8-2.3 M1062.2,632.3l0.2-0.3l0.1-0.5
		 M1062.5,631.6l0.4-2.4 M1062.9,629.2l0.1-0.4l-0.2-0.9l-0.5-0.8l-0.1-0.1 M1036,680.3l7.1-0.4 M1043,679.9l0.4,0l0.3-0.1
		 M1046.3,676.7l0.1-0.1l0.2-0.7l0-0.1 M1045.2,678.7l1.2-1.9 M1043.6,679.8l0.5-0.2l0.7-0.6l0.3-0.4 M1064,608.7l0.2-0.4l0-0.5
		 M1063.4,604.2l-0.2-0.3l-0.2-0.1 M1063,603.8l-5.5-4.4 M1064.2,608l-0.2-2.5 M1064,605.7v-0.3l-0.3-0.9l-0.3-0.4 M1047,658.9
		l1.9,0.3 M1056.7,653.1l-0.4-1.8l-1.1-3l-0.9-1.6l-1.1-1.3l-0.5-0.4l-0.1,0 M1062.1,632.5l-1.9,2.3l-2,1.9l-1.6,1.2l-1.9,0.9
		 M1052.7,645.1L1052.7,645.1l-0.1-0.2l-0.1-0.7l0.1-0.7l0.2-1l0.4-1.4l0.4-1l0.4-0.7l0.4-0.5l0.2-0.2l0,0 M1048,659l0.8,0.1
		 M1046.6,675.9l0.1-2.5l-0.1-3.8l-0.3-2.3l-0.4-1.8l-0.3-1l-0.1-1.1l0.1-1.1l0.2-1l0.4-1.1l0.6-1.2l0.3-0.5l0.2-0.2l0,0
		 M1048.8,659.1l0.6,0.1l1.6-0.1l1.9-0.5l1.8-0.8 M1059.1,624.8l-3.2-1.7 M1033.5,693.1l0.4-0.5l0.2-0.6 M1030.6,695l0.2,0l0.2-0.1
		 M1022.6,696.5l8.1-1.5 M1032,694.4l1.6-1.4 M1062.3,627.2l-0.8-0.8l-1.8-1.3l-0.3-0.1 M1055.9,625.1L1055.9,625.1l0-0.1l0-0.4V624
		l0.1-1.2l0.2-1.1l0.4-1.1l0.5-1l0.6-0.9l0.9-1l1.1-1.4l1.7-2.7l1.9-3.5l0.6-1.4 M1031,694.8l0.6-0.2l0.4-0.3 M1060.4,590.5l0.1-0.2
		l0-0.8l-0.2-0.4 M1059.1,586.8l-0.1-0.2l-0.1-0.1 M1060.3,589.1l-0.8-1.7 M1059.5,587.4l-0.3-0.5l-0.4-0.4 M1034.1,692l0.5-2.1
		l0.9-4.2l0.4-3.3l0.1-2.6l0.1-1.7l0.3-1.4l0.4-1.1l0.5-0.9l0.5-0.7l0.3-0.3l0.1-0.1l0,0 M1058.9,586.5l-6.8-6.6 M1054.9,608.3
		L1054.9,608.3l-0.1-0.3l-0.1-0.6l0-0.8l0.1-0.9l0.3-1.1l0.5-1.3l0.9-1.7l1.4-3.2l1.1-3.2l1.2-4.2l0.1-0.6 M1019.4,700.5l0.4-0.1
		 M1019.8,700.4l0.5-0.1l0.8-0.6l0.4-0.6 M975,710.3l44.7-9.8 M1051.4,578.6l-0.2-0.2 M1052.1,580.5l0-0.4l-0.2-0.9l-0.4-0.6
		 M1021.4,699l0.9-1.9l1.6-3.8l1-3.1l0.8-3.3l0.5-1.7l0.5-1.2l0.4-0.7l0.3-0.4l0.2-0.2l0,0 M1049.5,596.9L1049.5,596.9l0-0.2l0-0.4
		l0-0.7l0.2-1.1l0.4-1.6l0.7-2.4l0.6-3.2l0.5-3.4l0.2-3.3 M1051.2,578.5l-8.7-8.5l-24.9-24.3 M1008.9,697l0.5-0.1 M1009.3,696.9
		l0.5-0.2l0.7-0.6l0.1-0.2 M1010.7,695.9l1-1.2l2.1-3l1.5-2.6l1.4-3l0.7-1.4l0.5-0.7l0.3-0.3l0.1-0.1l0,0 M1039.8,579.2l-0.4-0.4
		 M1040.5,580.5L1040.5,580.5l-0.4-1l-0.2-0.3 M1041.2,593.8L1041.2,593.8l0-0.1l-0.1-0.4l0-0.7l0.1-1.2l0.2-1.9l0-2.8l-0.2-2.8
		l-0.6-3.3 M960.2,705.5l48.7-8.5 M1039.5,578.9l-9.8-8.4l-28.4-24.4 M1002,683.8l0.7-0.1 M1002.5,683.7l0.6-0.2l0,0 M1003.1,683.4
		l0.9-0.4l2.3-1.6l2-1.8l1.8-2.2l0.5-0.7l0.4-0.4l0,0 M1031,598.8l0-0.1l-0.1-0.4l-0.2-1.1l-0.3-1.3l-0.8-2.2l-1.1-2l-1.6-2
		 M1027,589.8L1027,589.8 M950.1,687.6l51.9-3.8 M1002.3,663.6l1.9-0.1l1.7-0.5l1.3-0.7l0.5-0.3l0.1-0.1l0,0 M1026.5,589.4l-11-7.4
		l-32.6-22 M1020.6,611.1L1020.6,611.1L1020.6,611.1L1020.6,611.1L1020.6,611.1l-0.9-1.2l-1.6-1.2l-0.3-0.1 M942.4,659.6l31.4,2.1
		l29.1,2 M1017.8,608.6l-26.9-13l-27.4-13.3 M938.1,687.7l1.2,3 M981.1,546l-2.6,1.9 M1013.7,544.4l-12.5-3.6 M953.6,705.8l5,1.4
		 M590.5,527.4l0.8,2 M594.6,532.8l44.1,12.7 M643.4,551.9l3.3,1 M660.1,506.6l-3.3-1 M755.1,543.2l-0.8-2 M648,548.3l57.2,16.5
		 M716,527.7l-57.2-16.5 M561.1,452.9l-118.7-34.3 M415.7,511.3l118.7,34.3 M570.3,464.4l-1.2-3 M545.6,542.7l2.6-1.9 M905.9,578.5
		l-1.7-0.5 M891.4,622.5l1.7,0.5 M912.9,554.4l-25.1-7.2 M861,639.9l25.1,7.2 M822.6,554.5l-1.7-0.5 M808.1,598.5l1.7,0.5
		 M829.6,530.4l-25.1-7.2 M777.7,615.8l25.1,7.2"/>
	<polyline class="st1" points="517.9,411.5 515.2,410.5 513.3,409.4 	"/>
	<polyline class="st1" points="537,409.4 536.2,409.9 533.8,411 530.9,411.8 527.7,412.3 524.3,412.4 521,412.1 517.9,411.5 	"/>
	<polyline class="st2" points="405.5,476.2 406.4,470.3 407.7,464.3 409.3,458.3 411.2,452.4 413.4,446.8 415.9,441.4 418.6,436.4
		421.5,431.9 424.4,428 427.5,424.6 430.6,421.9 433.7,420 436.7,418.7 439.5,418.3 442.2,418.6 442.4,418.6 	"/>
	<polyline class="st1" points="1164.7,668.5 1164.3,668.9 1162.9,670.4 1161.7,672.4 1160.6,674.9 1159.9,677.5 1159.5,680.2
		1159.5,682.5 1159.9,684.5 1160,684.8 	"/>
	<line class="st1" x1="525.2" y1="461.7" x2="525.2" y2="412.4"/>
	<line class="st3" x1="1085.2" y1="654.4" x2="1025.2" y2="637.1"/>
	<line class="st1" x1="940.4" y1="612.6" x2="891.5" y2="598.5"/>
	<line class="st1" x1="854.4" y1="587.7" x2="808.2" y2="574.4"/>
	<line class="st1" x1="771" y1="563.7" x2="743" y2="555.6"/>
	<line class="st1" x1="591" y1="511.7" x2="541.4" y2="497.4"/>
</g>
<g id="Nr">
	<g id="CT012037" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-front-differential-set", "_blank")}>
		<g>
			<g>
				<path class="st4" d="M776,673h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C788,667.6,782.6,673,776,673z"/>
			</g>
			<polyline class="st5" points="776.8,664.3 779.8,661 776.5,657.7 			"/>
		</g>
		<g>
			<path class="st6" d="M693.4,656.7c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3c0.7,0,1.6-0.2,2.5-0.5
				v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7c0.4-0.8,0.9-1.4,1.6-1.8
				c0.7-0.4,1.5-0.6,2.5-0.6c1,0,1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.7-0.3-1.1-0.5S693.7,656.7,693.4,656.7z"/>
			<path class="st6" d="M701.9,665h-2.1v-8.2h-2.7V655h7.5v1.8h-2.7V665z"/>
			<path class="st6" d="M712.4,660c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S712.4,658.4,712.4,660z M707.5,660c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8c0.5,0,0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8
				c-0.5,0-0.9,0.3-1.1,0.8C707.6,657.9,707.5,658.8,707.5,660z"/>
			<path class="st6" d="M718.7,665h-2.1v-5.8l0-1l0-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7V665z"/>
			<path class="st6" d="M728.4,665h-7v-1.5l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8s0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3c-0.4,0-0.8,0.1-1.1,0.3s-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
				s0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.1,1s0.4,0.9,0.4,1.4c0,0.5-0.1,0.9-0.2,1.3
				c-0.2,0.4-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4V665z"/>
			<path class="st6" d="M736.4,660c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S736.4,658.4,736.4,660z M731.5,660c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8c0.5,0,0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8
				c-0.5,0-0.9,0.3-1.1,0.8C731.6,657.9,731.5,658.8,731.5,660z"/>
			<path class="st6" d="M744,657.3c0,0.6-0.2,1.2-0.6,1.6s-0.9,0.7-1.6,0.9v0c0.8,0.1,1.4,0.3,1.8,0.7c0.4,0.4,0.6,0.9,0.6,1.6
				c0,1-0.3,1.7-1,2.2c-0.7,0.5-1.7,0.8-3,0.8c-1.1,0-2-0.2-2.9-0.5v-1.8c0.4,0.2,0.8,0.4,1.3,0.5s0.9,0.2,1.4,0.2
				c0.7,0,1.2-0.1,1.5-0.4s0.5-0.6,0.5-1.1c0-0.5-0.2-0.8-0.6-1s-1-0.3-1.8-0.3h-0.8v-1.6h0.8c0.8,0,1.3-0.1,1.7-0.3
				c0.4-0.2,0.5-0.5,0.5-1c0-0.8-0.5-1.1-1.4-1.1c-0.3,0-0.7,0.1-1,0.2s-0.7,0.3-1.1,0.6l-1-1.5c0.9-0.7,2-1,3.3-1
				c1,0,1.9,0.2,2.5,0.6S744,656.5,744,657.3z"/>
			<path class="st6" d="M746.4,665l3.8-8.2h-4.9v-1.8h7.2v1.3l-3.8,8.7H746.4z"/>
		</g>
	</g>
</g>
</svg>

 : <svg
  className="bevelGear"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<path class="st0" d="M936.2,621.1L936.2,621.1l0.1,0.2v0.3 M937.1,619.3L937.1,619.3h-0.2l0,0 M942.7,596.6L942.7,596.6L942.7,596.6
	h0.3 M943.4,594.2l-0.2,0.4l-0.2,0.1l0,0 M923.3,644.9h0.2h0.1 M926.8,641.8L926.8,641.8v-0.4l-0.1-0.2l0,0 M925.8,642.7
	L925.8,642.7v0.3l-0.2,0.7l-0.1,0.1 M923.8,644.9L923.8,644.9h0.2l0,0 M943.5,568.8L943.5,568.8l-0.2-0.2h-0.2 M944.2,573.5
	L944.2,573.5l0.2-0.1l0.2-0.4v-0.1 M943.8,569.1L943.8,569.1L943.8,569.1l-0.2-0.2h-0.1 M944.5,570.7v0.2l-0.2,0.7l-0.1,0.2l0,0
	 M936.8,619.3L936.8,619.3l0.2-0.2l0.2-0.6l-0.1-0.3 M926.7,641.3L926.7,641.3l0.2-0.1l0.2-0.2 M910.4,659.7L910.4,659.7l0.2,0.2
	l0.1,0.1h0.1h0.1h0.1 M913.9,658.1l0.3-0.3l0.2-0.7v-0.3l0,0 M944.4,573.8l-0.1-0.2l-0.1-0.1l0,0 M935.8,589.6h0.2l1.5-0.3l2.1,0.5
	l0,0 M913.4,657.7L913.4,657.7l-0.1,0.4l-0.4,0.8l-0.4,0.5 M910.9,659.7L910.9,659.7l0.2,0.2l0.1,0.1h0.1h0.1h0.1 M916.5,645.4
	l6.8-0.4 M939.5,551.8L939.5,551.8l-0.1-0.1h-0.1h-0.1h-0.1H939l-0.1,0.1 M940,552.5L940,552.5l0.3,0.9l-0.1,0.9l-0.1,0.3l0,0
	 M914.3,656.9L914.3,656.9L914.3,656.9l0.2,0.2 M923.3,644.9l-3.5-2.4l-0.9-1.1l-0.8-1.5l-0.1-0.8l-0.2-0.2h-0.1 M939.3,551.6
	L939.3,551.6l-0.2-0.1l-0.1-0.1h-0.1h-0.2h-0.2h-0.2 M940.8,555.5l-0.2,0.2h-0.1l0,0 M934.9,572.8h0.2l0.4-0.4l1.6-1.5l2.5-1.3
	l2.4-0.7l1.2-0.1 M943,568.6l-5.1-4.1 M901.9,663.4L901.9,663.4l0.1,0.2v0.5 M910.4,659.7l-2-5.5l-0.4-1.9l-0.2-2.2l0.2-1.3v-0.5
	l-0.1-0.3h-0.2 M903.2,661.1l7.1-1.4 M932.6,545.6L932.6,545.6l-0.2,0.6l-0.2,0.2l0,0 M931.7,543.5L931.7,543.5L931.7,543.5
	 M930,561.8L930,561.8l0.3-0.1v-0.1l0.3-0.6l0.6-1.5l0.9-1.4l1-1.3l3.4-3.7l1.9-1.7 M938.4,551.5l-5.9-5.7 M889.3,661.9L889.3,661.9
	l0.2,0.1h0.1h0.1 M891.5,659.8L891.5,659.8v0.3l-0.2,0.7l-0.1,0.2 M920.3,544.3L920.3,544.3l-0.1-0.1h-0.1H920h-0.1 M920.9,545.5
	l0.1,0.4l-0.2,0.8l-0.1,0.3l0,0 M889.1,662L889.1,662l0.2,0.1h0.1l0,0 M897.7,664.9l-0.2-7.7l0.1-2.4l0.3-2.5l0.5-1.5l0.2-0.8v-0.6
	l-0.2-0.3l0,0l-0.5-0.1 M920,544L920,544L920,544L920,544h-0.2h-0.1 M921.5,558.5l0.6-0.1h0.1l0.2-0.3l0.3-0.8l0.2-1.2l1-3l1.9-4
	l2.2-3.8l1.1-1.8 M882.4,648.3L882.4,648.3l0.2,0.2l0.1,0.1l0.1,0.1h0.1h0.1h0.1 M885,646.6L885,646.6l-0.1,0.4l-0.4,0.8l-0.6,0.6
	l-0.3,0.1 M882.2,648.4L882.2,648.4v0.2v0.1v0.1l0,0l0,0l0,0 M887.5,659.5l1.6-8.6l0.6-2.4l0.7-2.4l0.8-1.5l0.4-0.9l0.2-0.7v-0.6
	v-0.1l-0.4-0.7h-0.1l-0.3-0.1h-0.2 M906.9,554.4L906.9,554.4L906.9,554.4L906.9,554.4L906.9,554.4l-0.2,0.2l-0.1,0.1l-0.1,0.1
	l-0.1,0.1 M911.4,563.6l0.4-0.1h0.1l0.7-0.7l0.1-0.1l0.2-0.4l0.2-1V560l0.4-3l1.2-4.4l1.5-4.5l0.8-2.2 M882.8,628.6l-1.3-0.1
	l-0.9-0.3 M881.6,644.5l3.1-8l1-2.1l1-1.9l1-1.2l0.5-0.8l0.4-0.8l0.1-0.7v-0.1l-0.1-1.1v-0.1l-0.2-0.3l-0.6-0.3h-0.2 M900.6,575.8
	L900.6,575.8l0.6,0.2l0.6-0.1l0.5-0.4l0.1-0.1l0.6-1.1v-0.1l0.1-0.5v-1.1l-0.2-1.2l-0.3-2.5l0.3-4l0.6-4.4l0.4-2.3 M894.7,573.2
	l3.5,0.5 M689.4,576l8.4-7.3 M757.1,528v-9.9 M768,526.5v-7.2 M763,528.7l-10-1.1 M753,527.6l-3.4-5.3 M749.6,522.3l7.5-4.1
	 M757.1,518.2l10.9,1.2 M768,519.3l3.4,5.3 M914.2,601.3l2.5,0.7 M910.9,604.1l2.5,0.7 M911.8,610l1.7,0.5 M911.8,601.4l1.7,0.5
	 M909.2,605.6l2.5,0.7 M906.4,619.4l-0.6-0.2l-1.3-1.1l-0.1-0.1 M913.1,588.1l1.4-0.3l1,0.1 M916.7,599.1l-3.3,2.8 M916.7,602v-2.9
	 M913.4,604.9l3.3-2.8 M913.4,610.5v-5.6 M911.8,612l1.7-1.5 M911.8,606.3v5.6 M908.5,609.1l3.3-2.8 M908.5,606.2v2.9 M911.8,603.4
	l-3.3,2.8 M911.8,597.8v5.6 M913.4,596.3l-1.7,1.5 M913.4,601.9v-5.6 M912.6,589.9l1.8-1.2l1.7-0.4l1.6,0.3l1.3,1.1l1,1.8l0.6,2.5
	l0.2,3l-0.2,3.3l-0.6,3.5l-1,3.5l-1.3,3.4l-1.6,3l-1.7,2.6l-1.8,1.9l-1.8,1.2L909,620l-1.6-0.3l-1.3-1.1l-1-1.8l-0.6-2.5l-0.2-3
	l0.2-3.3l0.6-3.5l1-3.5l1.3-3.4l1.6-3l1.7-2.6L912.6,589.9 M942.6,597.5L942.6,597.5l0.2-0.6v-0.2l0,0 M929.3,624.2l-0.6-0.1
	l-0.2-0.1 M935.2,622.8l0.2-0.1l0.5-0.5l0.3-0.8v-0.3l0,0 M943,594.8L943,594.8l0.1-0.3l0.2-0.9l-0.1-0.8l-0.4-0.7 M939.6,589.8
	l0.3,0.2 M940.5,555.7L940.5,555.7l0.2-0.3l0.2-0.7l-0.1-0.6l0,0 M923.3,644.9l0.5-0.1 M923.8,644.9l0.8-0.4l0.6-0.6l0.5-0.8
	l0.1-0.3l0,0 M944.1,571.9L944.1,571.9l0.1-0.4v-1l-0.3-0.9l-0.5-0.7 M943.5,568.9l-0.4-0.3 M900.2,665.5l0.7-0.3l0.5-0.6l0.4-0.8
	l0.1-0.3l0,0 M901,663.5L901,663.5l-0.1,0.4l-0.5,0.8l-0.7,0.6l-0.9,0.3 M898.3,664.9l0.1,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1
	l0.1,0.1h0.1h0.1 M855.4,675.4l44.7-9.8 M900,665.5h0.2 M900.2,665.5H900h-0.2h-0.2h-0.2h-0.2H899h-0.2h-0.2 M898.8,665.6h-0.2
	 M931.9,543.7L931.9,543.7l-0.2-0.1 M910.4,659.7l0.5-0.1 M910.9,659.7l0.9-0.3l0.8-0.5l0.6-0.8l0.2-0.3l0,0 M898.6,665.7l-33.4,7.3
	l-6.6,1.4l-5,1.1 M897.7,664.9l0.1,0.2l0.1,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1h0.1h0.1 M932.2,546.4L932.2,546.4l0.1-0.4l0.1-0.9
	l-0.2-0.8l-0.5-0.7 M930.5,543.1L930.5,543.1L930.5,543.1l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M930.5,543.1
	l0.6,0.7l0.3,0.9l0.1,1l-0.1,0.4l0,0 M940,554.7L940,554.7v-0.4l-0.2-1l-0.4-0.8l-0.6-0.7 M938.8,551.8l-0.4-0.3 M930.5,543.1
	l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.1,0.1 M931.7,543.6L931.7,543.6 M931.6,543.5l-25-24.4l-4.9-4.8
	l-3.7-3.6 M930.4,543L930.4,543 M930.4,543L930.4,543h-0.2h-0.1H930l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1 M896.4,510.1l33.9,32.9
	 M889.3,661.9l0.8-0.3l0.7-0.6l0.5-0.8l0.1-0.3l0,0 M890.9,659.1L890.9,659.1l-0.2,0.3l-0.7,0.7l-0.8,0.5l-0.9,0.3 M888.1,659.5v0.2
	v0.2v0.2v0.2v0.2v0.1v0.1v0.1 M897.7,664.9h0.6 M898.3,664.9l1-0.2l0.8-0.4l0.7-0.6l0.2-0.2l0,0 M931.4,546.1L931.4,546.1l-0.1-0.3
	L931,545l-0.6-0.7l-0.7-0.6 M929.7,543.7l-0.5-0.3 M920.7,547.1L920.7,547.1l0.1-0.4l-0.1-1l-0.3-0.9l-0.6-0.7 M918.3,544.9
	L918.3,544.9l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M918.3,544.9l0.7,0.7l0.5,0.8l0.3,0.9v0.3l0,0
	 M840.7,670.4l48.4-8.5 M889.1,662h0.2 M889.3,661.9L889.3,661.9l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	 M888.3,660.8h-0.2 M842.8,672.9v-2.7 M851.4,675.1l24.4-5.4l8.7-1.9l13.3-2.9 M888.1,660.9l-36.2,6.1l-7.1,1.2l-5.4,0.9
	 M887.5,659.5l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.1l0.1,0.1l0.1,0.1 M929.2,543.4l-12.6-12.2l-22.4-21.7 M883,512.1
	l0.3-0.7l2.4-4.4 M918.1,544.7l0.2,0.1 M918.3,544.9l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1 M919.8,544.1
	L919.8,544.1 M919.6,544l-28.1-24.2L886,515l-4.2-3.6 M918.1,544.7l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.2,0.1l-0.2,0.2l-0.2,0.2
	l-0.2,0.2 M880,512.4l38.1,32.3 M887.5,659.5h0.6 M888.1,659.5h1l0.8-0.1l0.7-0.2l0.2-0.1l0,0 M882.4,648.3l0.9-0.3l0.8-0.5l0.7-0.7
	l0.2-0.3l0,0 M884.7,645.2L884.7,645.2l-0.2,0.2l-0.7,0.4l-0.8,0.3l-1,0.1 M882.1,644.6v0.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2
	 M907.4,554.8L907.4,554.8L907.4,554.8h-0.2h-0.1H907l-0.1,0.1l-0.1,0.1l-0.1,0.1 M919.7,547.6L919.7,547.6l-0.1-0.2l-0.5-0.5
	l-0.7-0.5l-0.9-0.4 M917.5,546l-0.5-0.3 M906.9,554.5l0.4,0.3 M907.4,554.8l0.5,0.7l0.2,0.9v1l-0.1,0.3l0,0 M908,557.7L908,557.7
	v-0.3l-0.3-0.9l-0.5-0.8l-0.7-0.7 M905.1,556.9L905.1,556.9l-0.2,0.3l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2
	 M905.1,556.9l0.8,0.5l0.6,0.6l0.4,0.7l0.1,0.2l0,0 M880.7,627.1v0.2v0.2v0.2v0.1v0.1v0.1v0.1v0.1 M829.1,664.9l1.3-6l1.7-6.1
	l0.1-0.3 M837.3,668l26.4-4.5l9.4-1.6l14.4-2.5 M880.7,627.1l1-0.1l0.8-0.3l0.7-0.4l0.2-0.2l0,0 M882.4,648.3l-0.1-0.3l-0.1-0.3
	l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3 M882,646.2h-0.2 M831.4,652l50.8-3.7 M882.2,648.4h0.2 M883.5,626.1
	L883.5,626.1l-0.2,0.3l-0.6,0.8l-0.7,0.6l-0.9,0.3 M881.1,628.1h-0.6 M881.6,644.5l0.6,0.1 M882.1,644.6l0.9,0.2l0.8,0.2l0.7,0.1
	h0.2l0,0 M881.4,622.7l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M883.6,624.3L883.6,624.3l-0.2,0.1
	l-0.7,0.1h-0.8l-0.9-0.1 M881.8,646.2l-37.8,2.4l-7.5,0.5l-5.6,0.4 M881.6,644.5v0.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2 M895.2,573.5
	L895.2,573.5L895.2,573.5l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2 M906.9,554.5L906.9,554.5 M917,545.8l-14.2-12
	l-25.2-21.4 M865.1,526.2l0.2-1.5l0.6-3.4l1.3-5.2l1.6-5.2 M893.1,577v0.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2 M896.3,576.4L896.3,576.4
	l-0.1-0.2l-0.4-0.7l-0.6-0.6l-0.8-0.5 M893.1,577l0.9,0.3l0.7,0.3l0.6,0.3l0.2,0.1l0,0 M904.9,556.8l0.2,0.1 M905.1,556.9l0.2-0.2
	l0.2-0.2l0.2-0.2l0.2-0.2l0.2-0.2l0.2-0.2l0.2-0.2l0.2-0.2 M906.6,555l-0.2-0.1 M906.4,554.9l-31.5-21.3l-6.2-4.2l-4.7-3.2
	 M904.9,556.8l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.3,0l-0.1,0.2 M862.1,528.5l42.8,28.2 M884.5,601.9v0.2v0.2
	v0.2v0.2v0.2v0.2v0.1v0.1 M886,597.5L886,597.5l-0.2,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M887.7,600
	L887.7,600l-0.2-0.1l-0.6-0.3l-0.7-0.3l-0.9-0.3 M884.5,601.9l1,0.1h0.8l0.7-0.1h0.2l0,0 M907,558.9L907,558.9h-0.2l-0.7-0.1
	l-0.8-0.2l-0.9-0.2 M904.4,558.4l-0.6-0.2 M894.7,573.2l0.5,0.3 M895.2,573.5l0.6,0.7l0.4,0.8l0.1,1v0.3l0,0 M887.2,601.9
	L887.2,601.9l-0.2,0.3l-0.7,0.6l-0.8,0.4l-0.9,0.2 M884.5,603.3h-0.6 M880.9,622.5l0.5,0.2 M881.4,622.7l0.8,0.5l0.7,0.5l0.5,0.5
	l0.1,0.2l0,0 M895.5,578.1L895.5,578.1l-0.2,0.1l-0.7,0.3l-0.8,0.1h-1 M892.8,578.7l-0.6-0.1 M885.5,597.3l0.5,0.3 M886,597.5
	l0.7,0.6l0.5,0.7l0.3,0.8l0.1,0.3l0,0 M880.5,627.1v0.2v0.2v0.2v0.1v0.1v0.1v0.1l0,0 M883.3,628.7l-0.5-0.1 M880.5,628.2l-19.1-1.4
	l-33.9-2.5 M822.8,624.6l-0.5,0.1l-2.4-0.3 M821.2,643.9l2.3-5.6l2.6-5.3l1.6-2.6l1.2-1.9l0.5-1.1l0.3-1.2l0.1-1 M829.1,647.9
	l27.6-1.8l9.8-0.6l15.1-1 M880.7,627.1v-0.3v-0.3v-0.3v-0.3v-0.3v-0.3V625v-0.4 M880.9,624.3h-0.2 M829.2,623.3l51.3,3.8
	 M880.5,627.1h0.2 M880.7,624.3l-38.1-3.3l-7.5-0.6l-5.7-0.5 M880.9,622.5v0.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2 M894.7,573.2
	L894.7,573.2L894.7,573.2l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2 M892.9,577l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2
	l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M893.1,577l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3l0.2-0.3 M894.4,574.4
	l-0.2-0.1 M894.2,574.3l-34.7-16.2l-6.8-3.2l-5.1-2.4 M846,555.7l46.9,21.2 M892.9,577l0.2,0.1 M884.3,601.8l-0.1,0.2l-0.1,0.2
	l-0.1,0.2l-0.1,0.2v0.2v0.2v0.1v0.1 M885.5,597.3L885.5,597.3v0.3v0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M942.7,596.6
	v-0.2v-0.2V596v-0.2v-0.2v-0.2v-0.2V595 M943,594.8l-0.6-0.6l-1.6-1.1l-1.8-0.4l-1.5,0.3l-0.9-0.3l-0.6-1l-0.2-0.9v-2.2l0.3-1.7
	l0.6-1.7l0.7-1.4l1.2-1.6l2.1-2.7l3.3-6.1 M944.2,573.5v-0.2v-0.2v-0.2v-0.2v-0.2v-0.2v-0.2v-0.2 M944.1,571.9l-0.8,0.1l-2,0.6
	l-2.1,1.3l-1.6,1.5l-1.1,0.6l-0.9-0.2l-0.4-0.5l-0.5-1.6l-0.1-1.5l0.3-1.7l0.5-1.7l1-2.2l1.7-3.7l2.3-7.2 M940.5,555.7L940.5,555.7
	l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1 M940,554.7l-1.4,1.3l-2.9,3.5l-1.3,2.2l-1.4,2.2l-1,0.9l-0.9,0.2
	H931l-0.9-0.7l-0.4-1l-0.1-1.5l0.2-1.6l0.6-2.5l1-4.1l0.8-7.1 M932.2,546.4L932.2,546.4l-0.2-0.1h-0.1h-0.1h-0.1h-0.1h-0.1h-0.1
	 M931.4,546.1l-0.8,1.3l-1.8,3.4l-1.6,3.7l-1,3l-0.9,1.9l-1,1.4l-0.6,0.5l-1.2,0.3l-0.7-0.4l-0.5-0.9l-0.2-1.3l0.1-2.3l0.1-3.8
	l-0.7-5.8 M920.7,547.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M919.7,547.6l-0.5,1.6l-1.2,4
	l-0.9,4l-0.4,3l-0.5,2.1l-0.8,1.9l-0.6,0.9l-1.2,1.2l-0.9,0.4l-0.7-0.2l-0.5-0.7l-0.4-1.7l-0.7-2.8l-2.2-3.5 M908,557.7L908,557.7
	l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M907,558.9l-0.2,1.6l-0.4,3.9l-0.1,3.7l0.3,2.5l-0.1,1.9l-0.5,2.1
	l-0.4,1.1l-1.1,1.9l-0.9,1l-0.9,0.5l-0.8-0.1l-0.9-0.8l-1.5-1.4l-3.3-0.6 M896.3,576.4l-0.1,0.2l-0.1,0.2L896,577l-0.1,0.2l-0.1,0.2
	l-0.1,0.2l-0.1,0.2l-0.1,0.2 M895.5,578.1l0.2,2.3l0.8,4.1l0.7,1.6l0.7,1.7l0.1,1.6l-0.2,1.8l-0.1,0.3l-0.8,2.3l-0.8,1.5l-0.9,1.1
	l-0.9,0.6l-1.2,0.2l-2,0.3l-3.8,2.5 M887.7,600l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2
	 M887.2,601.9l0.4,0.9l1.2,1.8l1.5,1.2l1.4,0.3l0.7,0.7l0.4,1.4v1l-0.3,2.3l-0.5,1.7l-0.7,1.6l-0.8,1.2l-1.3,1.2l-2.1,2l-3.6,5.1
	 M883.6,624.3v0.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2 M883.5,626.1l0.7,0.2l1.8,0.2l2-0.5l1.6-0.9l1-0.1l0.8,0.6l0.3,0.7l0.2,1.9l-0.1,1.7
	l-0.4,1.8l-0.6,1.6l-1.2,2l-1.9,3.3l-2.9,6.8 M884.7,645.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2 M885,646.6l0.8-0.5l2.1-1.5l2.1-2.1
	l1.6-2.1l1.1-1l1-0.3l0.5,0.2l0.7,1.2l0.2,1.3l-0.1,1.6l-0.3,1.7l-0.8,2.4l-1.4,4l-1.6,7.3 M890.9,659.1L890.9,659.1l0.2,0.2
	l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M891.5,659.8l1.4-1.8l2.7-4.3l1.2-2.5l1.2-2.5l0.9-1.2l1-0.6h0.1l1,0.2l0.6,0.7
	l0.3,1.2v1.5l-0.4,2.4l-0.6,4l-0.1,6.6 M901,663.5L901,663.5h0.2h0.1h0.1h0.1h0.1h0.1h0.1 M901.9,663.4l0.7-1.5l1.5-3.8l1.3-4
	l0.7-3.1l0.7-2l1-1.7l0.6-0.7l1.2-0.8h0.8l0.6,0.6l0.4,1l0.2,2.1l0.3,3.4l1.5,4.7 M913.4,657.7L913.4,657.7l0.2-0.2l0.1-0.1l0.1-0.1
	l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M914.3,656.9l0.4-1.6l0.8-4.1l0.5-3.9l0.1-2.9l0.3-2.1l0.7-2.1l0.5-1l1.2-1.6l0.9-0.7l0.8-0.1
	l0.7,0.4l0.7,1.3l1.1,2.2l2.8,2.1 M925.8,642.7l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2 M926.7,641.3
	l0.1-1.5v-3.6l-0.4-3.2l-0.6-2.1l-0.1-1.7l0.3-2l0.3-1.2l0.9-2.2l0.8-1.3l0.9-0.8l0.8-0.3l1.1,0.3l1.8,0.5l3.6-1 M936.2,621.1
	l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2 M936.8,619.3l-0.3-1.1l-0.9-2.6l-1.2-2l-1.1-1l-0.6-1.1l-0.2-1.7
	l0.1-1.1l0.5-2.4l0.6-1.7l0.8-1.4l0.9-0.9l1.3-0.7l2.1-1.2l3.8-3.9 M923.7,595.9l-0.2,3.6l-0.6,3.8l-1,3.9l-1.3,3.8l-1.6,3.5
	l-1.8,3.1l-1.9,2.5l-2,1.9l-2,1.2l-1.9,0.4l-1.7-0.4l-1.4-1.1l-1.1-1.9l-0.8-2.5l-0.4-3v-3.5l0.4-3.7l0.8-3.9l1.1-3.8l1.4-3.6
	l1.7-3.3l1.9-2.8l2-2.2l2-1.6l1.9-0.8h1.8l1.6,0.8l1.3,1.5l1,2.2l0.6,2.8L923.7,595.9 M903.9,558.2L888,547.7L859.8,529
	 M848.9,549.7L848.9,549.7l1.1-1.9l0.4-1.1l0.2-1.1l0.1-1.5l-0.3-2.7l-0.1-3l0.2-4.9l0.6-5.2 M840.1,546.5l2.7,0.4l1.2,0.6
	 M846.6,550.7l25.3,11.8l9,4.2l13.8,6.5 M884.5,601.9l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4
	 M885.3,598.9l-0.2-0.1 M885.1,598.8l-37-9.8l-7.3-1.9l-5.5-1.5 M834.3,589.2l50,12.6 M884.3,601.8h0.2 M818.6,652.7l-1.6-4.4
	l-1.4-5.5l-1-6l-0.6-6.5l-0.2-6.9 M813.9,623.5l0.2-7.2l0.6-7.5l1-7.7l1.4-7.8l1.7-7.9l2.1-7.9l2.4-7.8l2.7-7.6l3-7.3l3.3-7l3.5-6.7
	l3.7-6.2l3.8-5.7l3.9-5.1l4-4.5l4.1-3.9l3.6-2.8 M880.9,622.5l4-6.1l1-1.4l1.2-1l1.1-0.6l0.6-0.6l0.5-0.7l0.3-0.7v-0.1l0.2-1.3v-0.1
	l-0.1-0.5l-0.4-0.7l-0.7-0.4l-1.3-0.3l-1.5-1.3l-1.3-2l-0.6-1.2 M883.9,603.3l-18.6-4.7l-33-8.3 M820.6,613.5l3-4.2l3.1-3.5l1.7-1.5
	l1.3-1l0.6-0.8l0.5-1.1l0.3-1.1l0.3-2.2l-0.1-0.9l-0.2-0.7l-0.6-0.5l-1.4-0.4l-1.4-0.6l-1.8-1.9l-1.6-2.5 M827.9,618l27.8,2.4
	l9.9,0.8l15.2,1.3 M881.7,505.8l-2.3-0.5l-3.6-0.1l-3.8,0.7l-3.9,1.4l-4,2.1l-4.1,2.8l-4.1,3.5l-4.1,4.2l-4,4.8l-3.9,5.4l-3.8,5.9
	l-3.6,6.4l-3.4,6.8l-3.2,7.2l-3,7.4l-2.7,7.7l-2.4,7.8l-2,7.9l-1.7,7.9l-1.3,7.8l-1,7.7l-0.6,7.5l-0.2,7.2 M814.9,625.5l0.2,6.9
	l0.6,6.5l1,6l1.3,5.5l1.7,5l2,4.4l2.4,3.7l2.7,3l3,2.3l3.2,1.6l1.1,0.4 M853.7,675.5h0.2h0.2h0.2h0.2h0.2h0.2h0.2h0.2 M853.7,675.5
	h-0.2h-0.3l-0.6-0.1l-1.2-0.3 M851.4,675.1l-7.4-1.9l-1.2-0.3 M833,669.1l1.1,0.8l1.1,0.7l1.2,0.6l1.2,0.5l1.2,0.4l1.3,0.3l1.3,0.2
	l1.3,0.1 M833,669.1l0.9,0.2l5.7,1.2 M839.7,670.5l0.8,0.1h0.2l0.1-0.1v-0.1l0,0 M839.4,669.1l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2
	l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2 M839.4,669.1L839.4,669.1l-0.4-0.3l-0.5-0.3l-1.2-0.5 M837.3,668l-7.2-2.7l-1.1-0.4 M823.1,652.1
	l0.6,1.9l0.6,1.8l0.7,1.7l0.7,1.7l0.8,1.6l0.8,1.5l0.9,1.4l0.9,1.3 M823.1,652.1l1,0.1l6.1,0.5 M830.1,652.6l0.9-0.1l0.3-0.1
	l0.2-0.2V652v-0.1 M830.9,649.4l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3 M830.9,649.4l-0.1-0.2l-0.2-0.3
	l-0.5-0.4l-1.1-0.7 M829.1,647.9l-6.8-3.4l-1.1-0.5 M820,624.3v2.6v2.6l0.1,2.5l0.1,2.5l0.2,2.4l0.2,2.4l0.3,2.3l0.3,2.2 M820,624.3
	h1l6.5-0.1 M827.5,624.2l1-0.2l0.3-0.2l0.3-0.3l0.1-0.2v-0.1 M829.4,619.9v0.4v0.4v0.4v0.4v0.4v0.4v0.4v0.4 M829.4,619.9v-0.3
	l-0.1-0.4l-0.4-0.5l-1-0.8 M827.9,618l-6.3-3.9l-1-0.6 M824.3,590.5l-0.6,2.9l-0.6,2.9l-0.5,2.9l-0.5,2.9l-0.4,2.9l-0.4,2.9
	l-0.3,2.8l-0.3,2.8 M824.3,590.5h1.1l7-0.2 M832.4,590.3l1.1-0.2l0.4-0.2l0.3-0.3l0.1-0.3v-0.1 M835.3,585.6l-0.1,0.5l-0.1,0.5
	l-0.1,0.5l-0.1,0.5l-0.1,0.5l-0.1,0.5l-0.1,0.5l-0.1,0.5 M835.3,585.6v-0.3l-0.1-0.4l-0.3-0.5l-0.9-0.8 M835.3,556.6l-1.1,2.7
	l-1.1,2.7l-1.1,2.8l-1,2.8l-1,2.8l-0.9,2.8l-0.9,2.9l-0.9,2.9 M843.8,556.6l1.2-0.2l0.4-0.2l0.4-0.3l0.2-0.2v-0.1 M847.5,552.5
	l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.4 M847.5,552.5l0.1-0.3v-0.3l-0.2-0.5l-0.8-0.7
	 M846.6,550.7L841,547l-0.9-0.6 M851.1,528.5l-1.4,2l-1.4,2.1l-1.4,2.2l-1.4,2.2l-1.4,2.3l-1.3,2.3l-1.3,2.4l-1.3,2.5 M851.1,528.5
	l1.2,0.1l7.5,0.5 M859.8,529.1h1.2l0.5-0.1l0.4-0.2l0.2-0.2l0,0 M864,526.3l-0.2,0.3l-0.2,0.3l-0.2,0.3l-0.2,0.3l-0.2,0.3l-0.2,0.3
	l-0.2,0.3l-0.2,0.3 M864,526.3l0.1-0.2v-0.2l-0.2-0.4l-0.7-0.6 M863.2,524.9l-5.4-3.1l-0.9-0.5 M868.9,510.9l-1.5,1l-1.5,1.1
	l-1.5,1.2l-1.5,1.3l-1.5,1.3l-1.5,1.4l-1.5,1.5l-1.5,1.6 M868.9,510.9l1.2,0.2l7.5,1.2 M877.6,512.4l1.2,0.1h0.5h0.4l0.2-0.1l0,0
	 M881.8,511.5l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1 M881.8,511.5l0.1-0.1v-0.1l-0.2-0.2
	l-0.7-0.4 M881,510.7l-5.4-2.3l-0.9-0.4 M885.7,507l-1.3-0.2l-1.3-0.1h-1.3l-1.4,0.1L879,507l-1.4,0.3l-1.4,0.4l-1.4,0.5 M885.7,507
	l1.2,0.3l7.4,2.1 M894.2,509.4l1.2,0.3l0.4,0.1l0.4,0.1l0.2,0.1l0,0 M898,510.7l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1
	l-0.2-0.1l-0.2-0.1l-0.2-0.1 M898,510.7L898,510.7 M885.5,597.3l4.2-3.1l1-0.4h1.1h1l0.7-0.2l0.6-0.4l0.4-0.6l0.1-0.1l0.4-1.3v-0.1
	V591v-0.7v-0.1l-0.3-0.8l-0.8-1.1l-0.5-0.9l-0.2-1l-0.7-4.6l-0.2-2.9 M892.2,578.6l-17.4-7.9l-31-14.1 M835.3,556.6h1.1h7.3
	 M827.3,579.1l3.1-2.1l3-1.2l1.6-0.1h1.2l0.6-0.4l0.6-0.8l0.5-0.9l0.7-2.2l0.2-1.1v-1l-0.3-1.1l-0.9-1.8l-0.8-1.9l-0.9-3.7l-0.6-4.1
	 M834.1,583.6l-5.9-4l-0.9-0.6 M834.1,583.6l27,7.2l9.6,2.6l14.8,3.9 M842.1,589.5l0.4,1.3l0.9,1.2l0.7,0.2 M677.3,554.2l-1.2,2.3
	l-2.3,4 M672,560h-0.9l-28.9,0.9 M640.9,562l1.2-1.1 M697.6,567.4L672,560 M691.1,558.7L691.1,558.7l-2.5,3.3l-2.1,2.2 M758.5,516.4
	l-1.7-0.2l-5,0.3l-2.1,0.7l-1.7,1.1 M774.8,522.5l-0.2-1.2l-0.7-0.5 M748,518.1l-1.2,1.4l-0.6,1.7l0.4,3.8l2.2,3.1 M746.2,514.3
	l4.6-2.3l6-0.6l6.6,0.7l3.2,0.7l3,0.8l2.7,0.8l2.2,0.9l2.7,1.9l0.2,2.4l-2.4,2.9l-4.6,3.1l-6,2.7l-6.6,1.4h-3.2l-3-0.5l-2.6-1.1
	l-2.2-1.6l-2.7-4.1l-0.2-4.5L746.2,514.3 M689.4,557.7l2.9,1.4l2.5,2.4l1.9,3l1.1,3.3l0.2,3.1l-0.7,2.6l-1.5,1.9l-2.3,1h-2.9
	l-1.2-0.3 M689.4,576l-48.5-14 M651.1,578.8l-2.3-1l-2.2-1.8l-1.9-2.5l-1.6-3.2l-1.2-3.8l-0.9-4.4 M640.9,543.7l0.9-5.9l1.2-6l1.6-6
	l1.9-5.9l2.2-5.7l2.5-5.4l2.7-5l2.9-4.5l3-4l3.1-3.3l3.1-2.7l3.1-2l3-1.2l2.9-0.5l2.7,0.3l0.2,0.1 M640.9,543.7l48.5,14
	 M797.6,523.7l-1.1-0.2l-2.7,0.2l-2.9,1l-3,1.7l-3,2.4l-3,3.1l-2.9,3.8l-2.8,4.3l-2.7,4.8l-2.4,5.2l-2.2,5.5l-1.9,5.7l-1.5,5.9
	l-1.2,5.9l-0.8,5.8l-0.4,5.5v5.2l0.4,4.8l0.8,4.4l1.2,3.8l1.5,3.2l1.9,2.5l2.2,1.8l1.5,0.7 M774.4,613.2l-2.5,0.2l-2.6-0.5l-2.4-1.2
	l-2.2-2l-1.8-2.7l-1.5-3.4l-1.2-4l-0.8-4.5l-0.4-5v-5.4l0.4-5.7l0.8-5.9l1.2-6l1.5-6l1.8-5.9l2.2-5.7l2.4-5.4l2.6-5.1l2.8-4.6l3-4.1
	l3.1-3.5l3.1-2.8l3.1-2.1l3-1.4l2.9-0.7l2.7,0.1l2.5,0.9l2.3,1.6l0.1,0.1 M803.9,582.3l0.3-2.1l0.8-4.1l1.1-4.1l1.4-4l1.5-3.3
	 M805.7,619h-0.4l-1.5-0.1 M828.4,533.7l0.6,0.3l1,0.6 M778.5,615.6l-2.3-1l-2.2-1.8l-1.9-2.5l-1.6-3.2l-1.3-3.8l-0.9-4.4l-0.5-4.9
	l-0.1-5.3l0.3-5.6l0.7-5.8l1.1-5.9l1.4-6l1.8-5.9l2.1-5.8l2.4-5.5l2.6-5.2l2.8-4.7l2.9-4.2l3-3.6l3.1-3 M791.9,527.5l3.1-2.3l3-1.6
	l2.9-0.9l2.8-0.1l1.5,0.3 M808.5,622.9l-2,0.3l-2.7-0.3l-2.5-1.1l-2.2-1.8l-1.9-2.5l-1.6-3.2l-1.3-3.8l-0.9-4.4l-0.5-4.9l-0.1-5.3
	l0.3-5.6l0.7-5.8l1.1-5.9l1.4-6l1.8-5.9l2.1-5.8l2.4-5.5l2.6-5.2l2.8-4.7l2.9-4.2l3-3.7l3.1-3 M816.9,534.7l3.1-2.3l3-1.6l2.9-0.9
	l2.8-0.1l2.6,0.7l2.4,1.4l0.8,0.8 M805.9,619.4l-1.5-0.3l-2.3-1.2L800,616l-1.8-2.6l-1.4-3.3l-1.1-3.9l-0.7-4.4l-0.3-4.9l0.1-5.2
	l0.5-5.5l0.9-5.7l1.2-5.7l1.6-5.7l1.9-5.6l2.2-5.4l2.4-5l2.6-4.6l2.8-4.1l2.9-3.5l3-2.9 M816.9,538.1l3-2.2l2.9-1.5l2.8-0.7h2.6
	l2.2,0.7 M812.7,625.5l-2.3-1l-2.2-1.8l-1.9-2.5l-1.6-3.2l-1.3-3.8l-0.9-4.4l-0.5-4.9l-0.1-5.3l0.3-5.6l0.7-5.8l1.1-6l1.4-6l1.8-5.9
	l2.1-5.8l2.4-5.5l2.6-5.2l2.8-4.7l2.9-4.2l3-3.7l3.1-3 M826.1,537.4l3.1-2.3l3-1.6l2.9-0.9l2.8-0.1l1.5,0.3 M917.2,588.4l-1.7-0.5
	 M906.4,619.4l1.7,0.5 M937.2,618.3L937.2,618.3 M937.1,619.3l0.1-0.6v-0.2 M935.5,622.6l0.2-0.1l0.5-0.7v-0.2 M936.3,621.6l0.8-2.3
	 M942.7,597.4l0.2-0.3l0.1-0.5 M943,596.6l0.4-2.4 M943.4,594.2l0.1-0.4l-0.2-0.9l-0.5-0.8l-0.1-0.1 M916.5,645.4l7.1-0.4
	 M923.4,645h0.4l0.3-0.1 M926.8,641.8L926.8,641.8l0.3-0.8v-0.1 M925.6,643.7l1.2-1.9 M924.1,644.8l0.5-0.2l0.7-0.6l0.3-0.4
	 M944.4,573.8l0.2-0.4v-0.5 M943.9,569.2l-0.2-0.3l-0.2-0.1 M943.5,568.8l-5.5-4.4 M944.6,573.1l-0.2-2.5 M944.5,570.7v-0.3
	l-0.3-0.9l-0.3-0.4 M927.4,623.9l1.9,0.3 M937.2,618.2l-0.4-1.8l-1.1-3l-0.9-1.6l-1.1-1.3l-0.5-0.4h-0.1 M942.6,597.5l-1.9,2.3
	l-2,1.9l-1.6,1.2l-1.9,0.9 M933.1,610.1L933.1,610.1l-0.1-0.3l-0.1-0.7l0.1-0.8l0.2-1l0.4-1.4l0.4-1l0.4-0.7l0.4-0.5l0.2-0.2l0,0
	 M928.4,624l0.8,0.1 M927,640.9l0.1-2.5l-0.1-3.8l-0.3-2.3l-0.4-1.8l-0.3-1l-0.1-1.1l0.1-1.1l0.2-1l0.4-1.1l0.6-1.2l0.3-0.5l0.2-0.2
	l0,0 M929.3,624.2l0.6,0.1l1.6-0.1l1.9-0.5l1.8-0.8 M939.6,589.8l-3.2-1.7 M913.9,658.1l0.4-0.5l0.2-0.6 M911.1,660h0.2l0.2-0.1
	 M903.1,661.5l8.1-1.5 M912.5,659.4l1.6-1.4 M942.7,592.3l-0.8-0.8l-1.8-1.3l-0.3-0.1 M936.3,590.2L936.3,590.2L936.3,590.2v-0.5
	v-0.6l0.1-1.2l0.2-1.1l0.4-1.1l0.5-1l0.6-0.9l0.9-1l1.1-1.4l1.7-2.7l1.9-3.5l0.6-1.4 M911.5,659.9l0.6-0.2l0.4-0.3 M940.8,555.5
	l0.1-0.2v-0.8l-0.2-0.4 M939.6,551.8l-0.1-0.2l-0.1-0.1 M940.8,554.1l-0.8-1.7 M939.9,552.5l-0.3-0.5l-0.4-0.4 M914.6,657.1l0.5-2.1
	l0.9-4.2l0.4-3.3l0.1-2.6l0.1-1.7l0.3-1.4l0.4-1.1l0.5-0.9l0.5-0.7l0.3-0.3l0.1-0.1l0,0 M939.3,551.6l-6.8-6.6 M935.3,573.3
	L935.3,573.3l-0.1-0.3l-0.1-0.6v-0.8l0.1-0.9l0.3-1.1l0.5-1.3l0.9-1.7l1.4-3.2l1.1-3.2l1.2-4.2l0.1-0.6 M899.9,665.5l0.4-0.1
	 M900.2,665.4l0.5-0.1l0.8-0.6l0.4-0.6 M855.4,675.4l44.7-9.8 M931.8,543.7l-0.2-0.2 M932.5,545.6v-0.4l-0.2-0.9l-0.4-0.6
	 M901.9,664.1l0.9-1.9l1.6-3.8l1-3.1l0.8-3.3l0.5-1.7l0.5-1.2l0.4-0.7l0.3-0.4l0.2-0.2l0,0 M930,561.9L930,561.9v-0.2v-0.4v-0.7
	l0.2-1.1l0.4-1.6l0.7-2.4l0.6-3.2l0.5-3.4l0.2-3.3 M931.7,543.5L923,535l-25-24.3 M889.4,662l0.5-0.1 M889.8,661.9l0.5-0.2l0.7-0.6
	l0.1-0.2 M891.1,661l1-1.2l2.1-3l1.5-2.6l1.4-3l0.7-1.4l0.5-0.7l0.3-0.3l0.1-0.1l0,0 M920.3,544.3l-0.4-0.4 M920.9,545.5
	L920.9,545.5l-0.4-1l-0.2-0.3 M921.6,558.8L921.6,558.8L921.6,558.8l-0.1-0.5v-0.7l0.1-1.2l0.2-1.9v-2.8l-0.2-2.8l-0.6-3.3
	 M840.7,670.6l48.7-8.5 M920,544l-9.8-8.4l-28.4-24.4 M882.5,648.8l0.7-0.1 M883,648.7l0.6-0.2l0,0 M883.6,648.5l0.9-0.4l2.3-1.6
	l2-1.8l1.8-2.2l0.5-0.7l0.4-0.4h0.1 M911.5,563.9L911.5,563.9l-0.1-0.5l-0.2-1.1l-0.3-1.3l-0.8-2.2l-1.1-2l-1.6-2 M907.5,554.9
	L907.5,554.9 M830.6,652.6l51.9-3.8 M882.8,628.7l1.9-0.1l1.7-0.5l1.3-0.7l0.5-0.3l0.1-0.1l0,0 M906.9,554.4l-11-7.4l-32.6-22
	 M901,576.1L901,576.1L901,576.1L901,576.1L901,576.1l-0.9-1.2l-1.6-1.2l-0.3-0.1 M822.8,624.6l31.4,2.1l29.1,2 M898.3,573.6
	l-26.9-13L844,547.3 M818.6,652.7l1.2,3 M861.6,511l-2.6,1.9 M894.2,509.4l-12.5-3.6 M834.1,670.8l5,1.5 M796.5,520.4l-118.7-34.3
	 M651.1,578.8l118.7,34.3 M830.3,530.2l-25.1-7.2 M778.5,615.6l25.1,7.2 M840.7,533.1l-1.2-0.3 M812.7,625.5l1.2,0.3"/>
</svg>



}

export default BevelGear
