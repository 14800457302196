import "../assets/styles/pages/interactive-support.scss"

import React, { useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Aside from "../components/Aside/Aside"
import Content from "../components/Content/Content"

const InteractiveSupport = () => {
  const [menu, setMenu] = useState("")
  const [subMenu, setSubMenu] = useState("")
  const [reset, setReset] = useState(false)

  const menuData = {
    menu,
    setMenu,
    subMenu,
    setSubMenu,
    reset,
    setReset,
  }

  return (
    <Layout>
      <Seo title="Interactive Support" />

      <main className="layout">
        <Aside {...menuData} />
        <Content {...menuData} />
      </main>
    </Layout>
  )
}

export default InteractiveSupport
