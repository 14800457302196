import "./aside.scss"

import React, { useState } from "react"
import { Link, withPrefix } from "gatsby"

const Aside = ({ menu, setMenu, subMenu, setSubMenu, reset, setReset }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <header className="aside-mobile">
        <div className="aside-mobile__logo">
          <Link to="/">
            <img
              src={
                require("../../assets/images/logo-hot-wheels-rc.svg").default
              }
              alt=""
            />
          </Link>
          <Link to="/">
            <img
              src={require("../../assets/images/logo-cybertruck.svg").default}
              alt=""
            />
          </Link>
          <button
            type="button"
            aria-label="Open Menu"
            className={`hamburger hamburger--squeeze ${
              isMenuOpen ? "is-active" : ""
            }`}
            onClick={e => setIsMenuOpen(!isMenuOpen)}
          >
            {Array(4)
              .fill(null)
              .map((item, index) => (
                <span key={index} />
              ))}
          </button>
        </div>
      </header>

      <aside className={`aside${isMenuOpen ? " is-open" : ""}`}>
        <div className="aside__logo">
          <Link to="/">
            <img
              src={
                require("../../assets/images/logo-hot-wheels-rc.svg").default
              }
              alt=""
            />
          </Link>
          <Link to="/">
            <img
              src={require("../../assets/images/logo-cybertruck.svg").default}
              alt=""
            />
          </Link>
        </div>
        <p className="aside__header">Support page</p>
        <nav className="aside__nav">
          <button
            className={`aside__nav-item${menu === "center" ? " current" : ""}`}
            onClick={() => setMenu("center")}
          >
            Center <span></span>
          </button>
          {menu === "center" && (
            <>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "steering-mount" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("steering-mount")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Steering Mount
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "steering" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("steering")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Steering
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "steering-link" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("steering-link")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Steering Link
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-caster-link" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-caster-link")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Caster Link
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "chassis" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("chassis")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Chassis
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "motor-mount" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("motor-mount")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Motor Mount
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "steering-module" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("steering-module")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Steering Module
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "receiver-module" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("receiver-module")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Receiver Module
              </button>
            </>
          )}
          <button
            className={`aside__nav-item${menu === "front" ? " current" : ""}`}
            onClick={() => setMenu("front")}
          >
            Front <span></span>
          </button>
          {menu === "front" && (
            <>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-anti-roll-bar" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-anti-roll-bar")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Anti Roll Bar
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "top-deck" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("top-deck")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Top Deck
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-bumper-guard" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-bumper-guard")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Bumper Guard
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "mounting-front-end" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("mounting-front-end")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Mounting Front End
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-bumper" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-bumper")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Bumper
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-end-assembly" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-end-assembly")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front End Assembly
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-shock" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-shock")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Shock
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-upper-link" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-upper-link")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Upper Link
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-suspension" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-suspension")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Suspension
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-steering-hub" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-steering-hub")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Steering Hub
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-tower" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-tower")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Tower
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-bulkhead" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-bulkhead")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Bulkhead
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "front-differential" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("front-differential")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Front Differential
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "bevel-gear" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("bevel-gear")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Bevel Gear
              </button>
            </>
          )}
          <button
            className={`aside__nav-item${menu === "rear" ? " current" : ""}`}
            onClick={() => setMenu("rear")}
          >
            Rear <span></span>
          </button>
          {menu === "rear" && (
            <>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-anti-roll-bar" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-anti-roll-bar")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Anti Roll Bar
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "mounting-rear-end" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("mounting-rear-end")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Mounting Rear End
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-bumper" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-bumper")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Bumper
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-end-assembly" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-end-assembly")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear End Assembly
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-shock" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-shock")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Shock
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-upper-link" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-upper-link")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Upper Link
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-suspension" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-suspension")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Suspension
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-hub" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-hub")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Hub
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-tower" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-tower")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Tower
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-bulkhead" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-bulkhead")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Bulkhead
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "rear-differential" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("rear-differential")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Rear Differential
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "middle-differential" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("middle-differential")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Middle Differential
              </button>
            </>
          )}
          <button
            className={`aside__nav-item${menu === "body" ? " current" : ""}`}
            onClick={() => setMenu("body")}
          >
            Body and wheels <span></span>
          </button>
          {menu === "body" && (
            <>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "body-mount" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("body-mount")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Body Mount
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "wheels-mount" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("wheels-mount")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Wheels Mount
              </button>
              <button
                className={`aside__nav-item-sub${
                  subMenu === "wheel" ? " current" : ""
                }`}
                onClick={() => {
                  setSubMenu("wheel")
                  setReset(!reset)
                  setIsMenuOpen(false)
                }}
              >
                Wheel
              </button>
            </>
          )}
        </nav>
        <div className="aside__action">
          <a
            href={withPrefix("CybertruckRC_UserGuide.pdf")}
            target="_blank"
            rel="noreferrer"
            className="aside__action-button"
          >
            User Guide PDF
          </a>
          <a
            href={withPrefix("CybertruckRC_BluePrints.pdf")}
            target="_blank"
            rel="noreferrer"
            className="aside__action-button"
          >
            Blueprints PDF
          </a>
        </div>
        <div className="aside__buy">
          <a
            href="https://carisma-shop.com/collections/cybertruck-spares"
            target="_blank"
            rel="noreferrer"
          >
            Buy parts
          </a>
        </div>
      </aside>
    </>
  )
}

export default Aside
