import "./frontBulkhead.scss"

import React from "react"

const FrontBulkhead = ({ explode }) => {
  return explode ? <svg
  className="frontBulkhead"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<g id="_x31_">
	<path class="st0" d="M682.2,878.1v0.4l1.1,2l2.5,1.7l3.6,1.1l4.2,0.4l4.2-0.3l3.7-1.1l2.6-1.7l1.2-2l0.1-0.6 M675.8,689.2v-1.4
		 M764.1,744.9l1.7,1.7l11,7.3 M661.8,768.6l-1.4-0.8l-1.1-1l-0.7-1.1l-0.2-0.6 M837.3,697.3l-9.6,9.3 M658.4,769.4l-3.8-6.4
		l-7.9-18.8l-4.8-19.1l-1.6-17.9 M815.7,742.9l3-8.1l1.3-12.2 M768.9,756.2l5.1,2.9l7.2,2.9l3.7,0.9 M821.8,749l-1.6-1.4l-1.5-1.4
		l-1.3-1.4l-0.8-1.2l-0.3-0.9l0.1-0.5 M823.7,794v8.9 M711.9,749.7l-4.2-6.8l-4.7-9l-3.7-9.4l-2.7-9.5l-1.7-9.4l-0.6-9.1
		 M661.8,768.6v-0.4l-0.1-0.4l-0.1-0.4l-0.2-0.3l-0.3-0.3l-0.2-0.2 M679.6,697.6l0.6,9.7l1.7,10l0.3,1.4 M721.2,798.2v-0.4l-0.1-0.4
		L721,797l-0.2-0.3l-0.3-0.3l-0.2-0.2 M738.6,796l-0.3,0.1l-0.5,0.5l-0.3,0.8l-0.1,0.8 M825.2,742.6l0.8-2.4l1.2-9.1 M821.8,749
		l2.2-3.5 M806.4,761.9l7.1-4.9l8.3-8 M666.8,714.4l-1.4-9.9l-0.6-10.7 M703.5,774.9l3.1-2.2 M704.4,771l-3.9,3.1L694,775l-6.8-2.1
		 M777.6,799.8l0.5-0.3l0.3-0.1 M719.7,795.9v-16.5 M812.8,768.1l3.2-2.9l3.6-4.6l2.8-5.3l1.8-5.6l0.6-5.4 M659.8,694.3l0.6,10.7
		l0.9,6.6 M646.2,703.8v-2.7 M839.2,733.5l-0.1,2.5 M772,786.2l3.7,6.8l2.7,4.2 M769.9,781.7l1,2.3 M770.9,784l-0.2-0.6l-0.2-0.7
		l-0.2-0.7l-0.1-0.6 M772,786.2l2,0.4l2.2-0.2l2.2-0.8l0,0 M761.1,793.5l-3.9,9.2l-7.4,9.3l-9.3,6 M756.6,824l1.7-0.9l4.1-2.6
		l3.7-3.1l3.4-3.7l3.1-4.2l2.7-4.6l2.3-5 M770,784l-0.8-2 M714.8,763l15.6-15 M740.3,218.2l-0.1,0.8l-1.3,1.9l-2.5,1.5l-3.5,0.9
		l-4,0.3l-3.9-0.4l-3.3-1.1 M721.6,222.1l-2.3-1.6l-0.9-2v-0.2 M767,145.4l-0.3,1.3l-1.6,1.9l-2.8,1.4l-3.7,0.8l-4,0.1 M754.5,150.9
		l-3.8-0.7l-3.1-1.3l-2-1.8l-0.6-1.7 M826.4,175.1l-0.1,0.6l-1.1,2l-2.5,1.6l-3.4,1l-3.9,0.3l-3.9-0.4 M811.5,180.2l-3.4-1.1
		l-2.4-1.6l-1.1-2v-0.3 M680.8,188.5l-0.5,1.7l-2,1.8l-3.1,1.3l-3.8,0.7l-4-0.1l-3.7-0.8 M663.8,193l-2.9-1.4l-1.6-1.9l-0.3-1.1
		 M704.6,148.4l-0.1,0.8l-1.3,1.9l-2.5,1.5l-3.5,0.9l-4,0.3l-3.9-0.4l-3.3-1.1 M686,152.2l-2.3-1.6l-0.9-2v-0.2 M827,396.3
		L827,396.3l-0.4-0.2l-0.3,0.1l-0.3,0.2 M659,408.6l0.5,0.4v0.1 M660.8,409.1l-0.3-0.2l-0.3-0.1l-0.3,0.1l-0.2,0.1 M825.9,396.4
		l3.1,2.3l0.9,1.4l0.3,1.5l-0.3,1.5l-0.3,0.4 M823,394.8l3,1.5 M771.8,324.9v-1.5 M798.7,327.9l0.3-0.6l0.3-0.4l0.2-0.2l0.2-0.1
		l0.3-0.1l0.3,0.2l0.3,0.3l0.4,1.3l0.3,2.3 M801.1,332.3l-0.4-2.8l-0.4-1.4l-0.2-0.4l-0.2-0.3l-0.1-0.1h-0.1l-0.1,0.1l-0.1,0.1
		l-0.1,0.2l-0.1,0.2l-0.2,0.5 M799.4,357.9l-0.8-2.8l-1.6-3l-2.5-2.6 M799.1,328.4l6.1,37.5 M659.6,409.7l-0.3-0.2l-0.3-0.1
		l-0.3,0.1l-0.2,0.1 M657.8,423.1l-2.1-1.3l-1.6-1.5l-1-1.6l-0.3-1.7 M805.9,367.1l-1-2l-0.3-0.8l-0.1-0.3 M724.7,341.1l-0.3-8.4
		 M697.5,335.6l0.7-2.5l1.8-1.4 M728.7,332.1l0.3,10.5 M659,409.4v-0.8 M667.1,410.6l2.4-0.3l2.5,0.1l2.4,0.4l2,0.8l0.3,0.3
		 M744.3,339l0.4-4.7 M717.7,337.2l-0.9,0.6l-0.7,0.6 M717.7,337.2l0.6,1.7 M700,331.6l0.1,0.9l-0.1,0.9l-0.3,0.9l-0.4,0.8l-0.6,0.7
		l-0.5,0.3 M714.5,337.9l0.2-0.3l0.5-0.9l0.7-0.9l0.8-0.8 M796,354.7l-1.2-1.2 M749.5,356.1l27.9-13.9 M674.2,420l-0.1,0.2
		 M676.6,419l-1.5,0.5l-0.3,0.2 M739.7,347.2l0.8,2.2 M677.3,408.9l-2-0.7l-2.9-0.6l-3.1-0.2l-3.1,0.3 M666.4,363.2l0.8-4.8l2.1-4.4
		l3.2-3.4l3.7-2l1.8-0.4h1.4l1.2,0.6l0.6,1.2l0.1,0.9 M680.8,360.3v29.4 M682.8,414.2L682.8,414.2L682.8,414.2l-0.2-0.2l-0.1-0.1
		 M738,446.1h0.4h0.3l0.3-0.1l0.3-0.2l0.3-0.3l0.1-0.2 M820.6,402.2L820.6,402.2l-3.7-9.7l-4.6-9.4l-5.4-8.9l-6-8.2l-6.5-7.4l-7-6.5
		l-7.2-5.4 M780,347l-26.2,13.1 M786.5,405.3l5.8-2.3l11.1-0.4l3.1,0.9 M675.6,417.9l0.5-1.6l1.7-2.5 M682.1,390.8l0.3,0.2l0.3,0.2
		l0.3,0.2l0.2,0.3l0.1,0.2 M789.2,411.4l0.5,0.1l0.7-0.1 M800.6,404.4l-6.9,0.2l-6.4,2.5 M789,410.8l1.7,0.5 M739.8,444.8
		L739.8,444.8v0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M682.8,415.4l0.2,0.6l0.9,2.2l2.8,4.2l0.5,0.4l0.5,0.2h0.6l1.2-0.2l2.7-0.3h3.3
		l1.6,0.2l1.5,0.3 M786.4,423.8l5.8-6.5l6.1-5.4l6.4-4.3l6.5-3.1l5-1.4 M781.5,433.8l8.1-11.3l9.3-9.2l9.9-6.5l9.9-3.5l9.4-0.2
		l6.1,2.3 M739.8,440.9l-0.8-0.9l-1.9-1.3l-2.5-0.9l-2.9-0.6l-3.1-0.2l-3.1,0.3 M725.8,382.3l0.3-2.4l0.8-2l0.9-1.4l1.2-1l1.4-0.6
		l1.5-0.2l1.6,0.3l1.7,0.8l1.9,1.5l1.6,2l1.3,2.9l0.6,3.1v3 M847.1,425.5l1.1-0.5l0.7-0.6l0.3-0.7 M849.2,423l-0.1-0.2l-0.1-0.1
		 M780.6,436.6l8-11.9l9.4-9.9l10.1-7.1l10.2-4l9.7-0.5l6.8,2.4 M710.8,338.7l-8.5-1.4h-8.2l-7.7,1.5l-6,2.4 M786.8,418.2l1.7-2.8
		l0.9-2.4l-0.1-1.6 M680.8,360.3l5.4,0.8 M724.4,382.2l0.3-2.5l0.9-2.2l1.3-1.6l1.5-1.2l1.7-0.7l1.9-0.2l2.1,0.4l2,1l2,1.7l1.6,2.3
		l1.1,2.9l0.5,3.4l-0.1,2.6 M697.1,345.6l-8.5-1.4h-8.2l-7.7,1.5l-6,2.4 M836.6,631.2L836.6,631.2 M858.1,587.8L858.1,587.8
		 M836.2,630.4L836.2,630.4 M857.1,588.1L857.1,588.1 M850,592.1L850,592.1L850,592.1l0.2-0.1l0,0 M835,622L835,622l0.1,0.3
		 M835.1,621.1l-0.1-0.2l0,0 M850,592.1l-0.3,0.1l0,0 M848.9,593L848.9,593h0.2 M837.4,610.4L837.4,610.4L837.4,610.4 M836.9,611.5
		L836.9,611.5l0.1,0.2 M841.8,601.2L841.8,601.2L841.8,601.2 M842.7,600.2h-0.2v0.1 M837.1,612.4L837.1,612.4 M858.2,587.8
		L858.2,587.8 M883,586l-7.1,0.7 M846.8,646.6l3.5,5.8 M850.1,592.1L850.1,592.1l0.2-0.1h0.1h0.1h0.1 M929.4,635l0.3-6 M758.4,499
		l-0.8-1.6l-1.6-1.6l-1.9-0.9h-1.9l-1,0.3 M753.7,501.6l-0.2-0.1h-0.2h-0.2h-0.1 M750,501.7l0.2-0.1h0.2h0.2h0.1 M793.9,580.8
		l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.1,0.1l0,0 M797.7,536.2L797.7,536.2L797.7,536.2l-0.2,0.1l-0.2,0.1l-0.2,0.1 M791.9,524.9
		L791.9,524.9L791.9,524.9l-0.2,0.1l-0.2,0.1l-0.2,0.1 M792,558.3l2,4 M768.6,512.4l2,4 M768.6,512.4L768.6,512.4l-0.2,0.2v0.3
		l0.3,0.8l0.3,0.4 M792.8,560.8l0.1,0.2l0.3,0.5l0.3,0.4l0.2,0.2l0.2,0.1h0.1h0.1 M770.7,516.4L770.7,516.4L770.7,516.4l-0.2-0.1
		h-0.2l-0.2,0.1 M791.9,558.2L791.9,558.2v0.2l-0.1,0.1l-0.1,0.1l-0.2,0.1 M674,594.3l2-3.1 M687.4,582v-0.9l0.4-1.2l0.8-0.7
		l1.1-0.1l1.3,0.6l0.4,0.3 M676.4,586.8l-0.3-0.9l-0.6-3.6v-0.7 M674.9,578.4l-4-2.9l-3.1-1.4 M665.8,573.8l3.2,1.4l4.3,3.2
		 M672.7,578.8l-4.3-3.3l-4.4-1.9l-4.2-0.3l-2.8,0.8 M664.5,573.5l3.1,0.3l4,1.6l3.9,2.9 M681.1,585.1l2.5,5.1l1.5,5.3l0.5,5.1
		l-0.6,4.6l-1.6,3.7l-0.8,1 M684.1,606.9l0.3-1.2l0.6-4.6l-0.5-5.2l-1.6-5.4l-2.5-5.1 M678.9,585.6l2.8,5.6l1.8,5.9l0.6,5.6L684,605
		 M677.5,615.6l0.8-0.5l2.8-2.8l1.8-4.2l0.6-5.1l-0.6-5.7l-1.8-5.9l-2.8-5.6 M682.5,543l1,1 M718.5,612.5l0.2,1.5 M683.4,544.7
		l-1.3,0.8 M683.6,541.7l0.3-0.7 M684.2,542.2l-0.5-0.5 M716.3,612.8l1.5-0.5 M721,614.1l-0.7-0.2 M720.4,613.9l-0.1-0.8
		 M682.1,545.5l0.2,0.2l0.2,0.1l0.2,0.1h0.2h0.2l0.1-0.1 M682.6,539.5l-0.6,1.8 M716.6,612L716.6,612l-0.2,0.2l-0.1,0.2l-0.1,0.2
		v0.2v0.3 M719.7,615.5l1.7,0.5 M681.1,538.7l0.5-0.2 M721.5,617.3l-0.4,0.3 M681.4,538.4L681.4,538.4l-0.2,0.1l-0.1,0.1l-0.1,0.2
		 M683.2,546.6l-0.2-0.2l-0.1-0.2l-0.1-0.2V546v-0.2v-0.1 M721.1,617.6h0.2h0.2l0.2-0.1l0,0 M716,611.2l0.1,0.2l0.1,0.2l0.1,0.2
		l0.1,0.1l0.2,0.1h0.1 M681.8,538.2v0.2v0.2v0.1l0.1,0.1 M721.9,617.4l-0.2-0.1l-0.1-0.1l-0.1-0.1V617 M683.5,539.8v0.2v0.2v0.1
		l0.1,0.1 M684.5,543.2l-0.2-0.2l-0.1-0.2l-0.1-0.2v-0.2v-0.2l0,0 M719.6,612.3l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.1l0.2,0.1h0.1
		 M721.6,615.1l-0.2-0.1l-0.1-0.1l-0.1-0.1v-0.1 M693.7,492.5l-1.1-1.5 M763.1,638.9l-0.3-1.4 M702.8,493.1l-1.7-2.8 M769.2,633.2
		l-0.9-2.6 M719.7,596.9l2.4-0.7l3.1-2.2l2.3-3.4l1.4-4.4l0.3-5.2l-0.8-5.6l-1.8-5.8L724,564l-3.4-5l-4-4.2l-4.3-3.1l-4.3-2
		l-4.2-0.6l-3.7,0.8l-2.8,1.9 M719.9,597.1l2.9-0.8l3.1-2.2l2.4-3.5l1.4-4.5l0.3-5.3l-0.8-5.8l-1.8-5.9l-2.7-5.7l-3.5-5.2l-4.1-4.3
		l-4.3-3.2l-4.4-2 M708.4,548.8l-4.2-0.6h-0.6 M738.6,556.2l0.2-0.1l0.2-0.1 M740.7,560.2l0.2-0.1l0.2-0.1 M738.5,555.1L738.5,555.1
		v0.3v0.3l0.1,0.3l0.1,0.3 M740.7,560.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.1l0,0 M738.1,554.9l-1.6,0.6 M740,562.3l1.4-0.9
		 M712.1,495.8l-0.2-0.4l-1.8-3.7 M774,625.7l-1.8-3.7 M739.1,555.6L739.1,555.6l-0.2,0.1l0,0l0,0 M741.4,560.1L741.4,560.1
		l-0.2,0.1l0,0l0,0 M777.5,616.7l-2.7-4.5 M721.4,500.5l-0.7-1.9l-1.3-3.5 M779.5,606.4l-3.9-5 M730.4,506.9l-0.8-3.4l-0.7-3.2
		 M780,595.2l-4.8-4.7l-0.5-0.5 M738.7,515.1l-0.3-4.9l-0.2-2.8 M779,583.2l-4.7-3.2l-2.3-1.6 M745.9,524.7L745.9,524.7l0.8-6.5
		l0.3-2.4 M694.4,553.1l1.5-2.6l3-2.8l3.7-1.5l4.2-0.1l4.6,1.3l4.6,2.6l4.4,3.8l4,4.8l3.3,5.6l2.6,6l1.5,6.1l0.5,5.9l-0.6,5.4
		l-1.6,4.5l-2.5,3.5l-3.4,2.2l-4,0.8l-3.4-0.3 M689.6,869.2l1.1-0.4l1.8-0.3l2,0.1l1.9,0.4l0.6,0.3l0.5,0.3 M688.5,868.5l0.3-0.4
		l0.3-0.3l0.9-0.4l1.6-0.4l1.9-0.2h0.3l1.8,0.2l1.8,0.6l1.1,0.7l0.3,0.3 M689.4,869.1l0.2-0.1l0.9-0.3l0.6-0.2l1.8-0.3h0.7l1.3,0.1
		l1.9,0.5l1.2,0.6 M688.3,869v-0.3l0.6-0.9l0,0l0.3-0.2l0.9-0.4l1.7-0.4l1.3-0.1h0.7l0,0h0.2h0.4l1.4,0.3l1.6,0.6l1.2,0.8l0.2,0.3
		 M694.7,865l1.3,0.3l0.6,0.2l1.4,0.7l0.3,0.3l0.5,0.6l0.3,1.2l-0.3,0.5 M684.3,878.9l0.3,0.2l1,0.6l1.1,0.5l1.3,0.4l1.4,0.3
		l1.4,0.3 M698.7,869l0.7-0.5l0.6-1l-0.1-1.1l-0.8-1l-1.4-0.8l-2-0.6 M690.5,865.6l-1.4,0.6l-0.8,0.8l-0.3,0.8l0.3,0.8l0.7,0.5
		 M694.7,865h-0.5h-0.5h-0.5l-0.5,0.1l-0.5,0.1l-0.5,0.1l-0.5,0.1l-0.4,0.2 M687.5,865.9l1.1-0.7l1.6-0.4l2-0.1l2,0.3l0.3,0.1
		 M695.7,863.8l-0.9-0.1h-1.9l-2,0.3l-1.8,0.7l-1.2,0.9l-0.3,0.3 M698.5,868.5l0.3,0.2l0.9,0.8l0.1,0.2 M688.6,866.7l0.8-0.4
		l1.5-0.4l1.6-0.2h1.8l1.8,0.3l1.5,0.5l1.2,0.7 M687.5,865.9L687.5,865.9l0.8-0.8l1.2-0.6l1.5-0.4l1.6-0.2l1.8,0.1l1.8,0.3l1.5,0.5
		l1.2,0.7l0.8,0.8l0.4,0.9 M680.8,872.4v-5.8 M682.5,877.1l-0.1-0.2l-1.3-3.2l-0.4-1.4 M684.3,878.7l-0.6-0.3l-1.1-1.4 M690.7,881.2
		l1.4,0.2l1.5,0.1l1.5-0.1l1.4-0.2l1.4-0.3l1.4-0.3l1.3-0.4l1.1-0.5 M700.4,865.6l0.2,1.4l-0.9,1.3l-1.8,1l-2.5,0.6l-2.7,0.1
		l-2.5-0.4l-2-0.8l-1.2-1.2l-0.2-1.4l0.8-1.3l1.8-1l2.5-0.6l2.7-0.1l2.5,0.4l2,0.9L700.4,865.6 M706.6,875.8V870 M701.9,879.7
		l-2.5,0.8h-2.3 M706.6,875.8l-4.2,3.6l-0.6,0.3 M697.2,880.5v-5.8 M684.3,878.7V873 M690.7,881.2l-3.3-0.8l-3.1-1.5 M697.2,880.5
		l-5.6,0.8l-0.8-0.1 M704.3,865l0.3,2l-1.2,2l-2.5,1.6l-3.5,1l-4,0.3l-3.9-0.4l-3.4-1.1l-2.4-1.6l-1-2l0.4-2l1.9-1.8l3-1.4l3.7-0.7
		h4l3.7,0.8l2.9,1.4L704.3,865 M697.2,874.7l-5.4-2l-5.6-0.1l-1.9,0.3 M706.6,870l-4,0.7l-4.1,2.7l-1.4,1.3 M703.2,863.5l0.4,0.3
		l1.4,2l1.4,3.5l0.3,0.8 M680.8,866.5l4-3.2l0.5-0.2 M684.3,873l-0.3-0.8l-1.4-3.5l-1.4-2l-0.4-0.3 M852.2,250.1l-1.3,0.6l-1.8,0.3
		h-2l-1.8-0.3l-1.2-0.6 M843.5,211.6v-1.9 M847.8,213.9v-2.8 M850.7,211.8V209 M850.4,213.9V212 M841.9,247.5l0.1,0.6l0.9,1.1
		l1.7,0.8l2.3,0.5h2.5l2.3-0.4l1.8-0.8l0.9-1.1l0.2-0.6 M846,215.2v-2.8 M850.4,212.1l-1.7,1.3 M847.7,213.9l-1.7-0.6 M843.1,220.2
		l1.6,0.8l2.3,0.5h2.5l2.3-0.4l1.7-0.9 M852.9,214.7l1.8-1.3 M848.6,213.3l4.2,1.4 M845.7,215.4l2.9-2.1 M843.1,214.5l2.6,0.9
		 M846,212.4l-2.9,2.1 M841.7,211l4.2,1.4 M843.5,209.7l-1.8,1.3 M847.8,211.1l-4.2-1.4 M850.7,209l-2.9,2.1 M853.3,209.9l-2.6-0.8
		 M850.4,212l2.9-2.1 M854.7,213.4l-4.2-1.4 M860.9,212.9v0.4l-1.1,2.1l-2.5,1.8l-3.6,1.3l-4.2,0.6l-4.3-0.2l-4-0.9l-3.1-1.5l-1.9-2
		l-0.5-1.7 M855.3,207l3.1,1.5l1.9,2l0.4,2.2l-1.1,2.1l-2.5,1.8l-3.6,1.3l-4.2,0.6l-4.3-0.2l-4-0.9l-3.1-1.5l-1.9-2l-0.4-2.2
		l1.1-2.1l2.5-1.8l3.6-1.3l4.2-0.6l4.3,0.2L855.3,207 M798.7,276.8l-0.5,0.3l-1.6,0.5l-1.9,0.2l-1.9-0.2l-1.6-0.5l-0.5-0.3
		 M791.1,238.3V236 M794.7,240v-2.2 M798.4,238.3V236 M796.9,241.1v-2.2 M792.5,241.1v-2.2 M788.4,274.2l0.5,1.2l1.4,1l2,0.7
		l2.4,0.3l2.4-0.3l2-0.7l1.4-1l0.5-1.2 M796.9,239.6l-1.5,0.8 M794.1,240.4l-1.5-0.8 M789.5,246.9l0.7,0.4l2,0.7l2.4,0.3l2.4-0.3
		l2-0.7l0.7-0.4 M798.4,241.9l2.2-1.1 M794.7,240l3.7,1.8 M791.1,241.9l3.7-1.8 M788.9,240.7l2.2,1.1 M792.5,238.9l-3.7,1.8
		 M788.9,237.1l3.7,1.8 M791.1,236l-2.2,1.1 M794.7,237.8l-3.7-1.8 M798.4,236l-3.7,1.8 M800.6,237.1l-2.2-1.1 M796.9,238.9l3.7-1.8
		 M800.6,240.7l-3.7-1.8 M807.3,239.7l-0.4,1.6l-1.9,2l-3.1,1.5l-4,0.9l-4.3,0.2l-4.2-0.6l-3.6-1.3l-2.5-1.8l-1.1-2.1l-0.1-0.5
		 M803.7,234.5l2.5,1.8l1.1,2.1l-0.3,2.2l-1.9,2l-3.1,1.5l-4,0.9l-4.3,0.2l-4.2-0.6l-3.6-1.3l-2.6-1.8l-1.1-2.1l0.4-2.2l1.9-2
		l3.1-1.5l4-0.9l4.3-0.2l4.2,0.6L803.7,234.5 M733.8,679.6l1.3,13.4l3.7,13.9 M752.2,678.3l-1.2-0.8l-0.4-1l0.4-1l1.2-0.8l1.8-0.6
		l2.1-0.2l2.1,0.2l1.8,0.6l1.2,0.8l0.4,1l-0.4,1l-1.2,0.8l-1.8,0.6l-2.1,0.2l-2.1-0.2L752.2,678.3 M748.7,680.1l-2.3-1.7l-0.8-2
		l0.8-2l2.3-1.7l3.4-1.1l4-0.4l4,0.4l3.4,1.1l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4L748.7,680.1 M738.9,682.1v24.8
		 M738.9,706.9l0.1,5.4l0.7,7.7l1.9,6.4 M692.2,702.9v-5.4 M827.1,798.8l-0.3,1.1l-0.7,1.1l-1.1,1l-1.4,0.8 M797.1,709.4l-3-2.1
		l-3.9-3.9l-3.3-4.8l-2.5-5.4l-1.6-5.5l-0.5-5.4 M794.3,717.1l-2.9-0.1l-4.2-1.4l-4.2-2.7l-3.9-3.9l-3.3-4.8l-2.5-5.4l-1.6-5.5
		l-0.5-5.4 M803.3,704l-0.2,2.5 M778,819.9v-2 M783.9,822.9l-6-3 M768.9,686.7l0.5,5.7l1.6,5.9l2.5,5.8l3.3,5.3l3.9,4.5l4.3,3.5
		l4.4,2.2l3.4,0.8 M776.9,753.9l11,3.7h10.4 M795.3,710.3l-0.5,5.1l-1.6,4.3l-2.5,3.2l-3.3,2l-3.9,0.6l-4.3-0.9l-4.4-2.2l-4.3-3.5
		l-3.9-4.5l-3.3-5.3l-2.5-5.8l-1.6-5.9l-0.5-5.7 M755.5,736.1v-45.7 M658.4,805l0.3,1.1l0.7,1.1l1.1,1l1.4,0.8 M811.6,708l1.8,0.6
		l2.1,0.2l2.1-0.2l1.8-0.6l1.2-0.9l0.4-1l-0.4-1l-1.2-0.9l-1.8-0.6l-2.1-0.2l-2.1,0.2l-1.8,0.6l-1.2,0.9l-0.4,1l0.4,1L811.6,708
		 M808.1,709.8l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7l3.4-1.1l4-0.4l4,0.4l3.4,1.1l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4
		L808.1,709.8 M798.3,711.9v45.8 M798.3,757.6v0.9v0.5 M815,743.8v-23.6 M656.3,761.3l0.3,1.4l0.8,1.3l1.3,1.2l1.7,1 M859.9,736.7
		l6.1-3.1 M666,721.4l1.8,0.6l2.1,0.2l2.1-0.2l1.8-0.6l1.2-0.9l0.4-1l-0.4-1l-1.2-0.9l-1.8-0.6l-2.1-0.2l-2.1,0.2l-1.8,0.6l-1.2,0.9
		l-0.4,1l0.4,1L666,721.4 M662.6,723.2l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7l3.4-1.1l4-0.4l4,0.4l3.4,1.1l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7
		l-3.4,1.1l-4,0.4l-4-0.4L662.6,723.2 M679.7,731.3l2.4,1.2 M687.1,724.6l3,1.5 M689.1,687.1l-1.4-1l-0.5-1.3l0.5-1.3l1.4-1l2.1-0.7
		l2.5-0.3l2.5,0.3l2.1,0.7l1.4,1l0.5,1.3l-0.5,1.3l-1.4,1l-2.1,0.7l-2.5,0.3l-2.5-0.3L689.1,687.1 M766.6,681.8l-0.8,2l-2.3,1.7
		l-3.4,1.1l-4,0.4l-4-0.4l-3.4-1.1 M748.7,685.5l-2.3-1.7l-0.8-2 M680.5,724.9l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4l-3.4-1.1
		 M662.6,728.6l-2.3-1.7l-0.8-2 M743.5,678.5l2.1-1 M743.5,678.5l-3.7-1.9 M739.7,676.6l-3.5-1.2l-4.1-0.6l-4.2,0.1l-3.9,0.8
		l-2.2,0.9 M719,678.1l3-1.4 M719,678.1l-2.3,0.8l-2.7,0.4l-2.8-0.1l-2.6-0.6l-1.4-0.6 M707.1,678.1l-4.5-1.7l-5.3-0.9l-5.6-0.1
		l-5.4,0.7l-4.8,1.4l-3.7,2.1l-2.3,2.5l-0.7,2.8l1,2.8 M675.8,687.8l0.2,0.3l0.1,0.3v0.3l-0.1,0.3l-0.2,0.3l-0.3,0.3l-0.3,0.3
		l-0.3,0.3 M674.6,690l0.2-0.1 M674.6,690l-1.2,0.4l-1.4,0.2h-1.4l-0.9-0.2 M668.5,690.1l1.3,0.3 M668.5,690.1l-4.1-0.6l-4.2,0.1
		l-3.9,0.8l-2.4,0.9 M653.9,691.3l-13.7,6.9 M640.2,698.2l-2.5,1.7l-1.2,2l0.3,2.1l1.6,2l1.7,1.1 M640.2,707.1l20.2,10.1
		 M659.4,720.5l-0.8,0.6l-1.3,1.2l-0.8,1.3l-0.3,1.4l0.3,1.4l0.8,1.3l1.3,1.2l1.7,1 M687.1,724.6l-0.4-1.6l-1-1.5l-1.5-1.4l-2-1.3
		l-2.1-0.9 M666.8,714.4l-20.7-10.3 M646.2,704.1l-0.8-0.6l-0.4-0.7l0.1-0.7l0.5-0.7l0.6-0.3 M692.2,697.4l-0.2-0.1 M694.2,696.5
		l-2,0.9 M727.9,679.6l1.2-0.4l1.4-0.2h1.4l1.3,0.3l0.8,0.3 M733.8,679.6l5.1,2.5 M755.5,690.5l-3.2-0.2l-3.1-0.5l-2.8-0.8l-2.5-1
		l-2-1.3l-1.5-1.4l-1-1.5l-0.4-1.6 M755.5,690.5l3,1.5 M758.5,691.9l10.4-5.2 M771.3,687.9l-2.4-1.2 M771.3,687.9l11-5.5
		 M782.2,682.4l-6-3 M773.3,679.4l0.6-0.2l0.7-0.1h0.7l0.7,0.2l0.3,0.2 M772,680.1l1.4-0.7 M772,680.1l-5.4-2.7 M849.6,688.2l-1.4-1
		l-0.5-1.3l0.5-1.3l1.4-1l2.1-0.7l2.5-0.3l2.5,0.3l2.1,0.7l1.4,1l0.5,1.3l-0.5,1.3l-1.4,1l-2.1,0.7l-2.5,0.3l-2.5-0.3L849.6,688.2
		 M846.8,689.6l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7l3.4-1.1l4-0.4l4,0.4l3.4,1.1l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4
		L846.8,689.6 M741.5,726.5l4.5,7.3l0.8,0.9 M727.9,679.6l0.3,5.7l0.7,5.9l1.1,6l1.5,6l2,6l2.4,6l2.7,5.9l3.1,5.6 M694.2,696.5
		l33.7-16.8 M843.7,691.3l0.8,2l2.3,1.7 M846.8,695l3.4,1.1l4,0.4l4-0.4l3.4-1.1l2.3-1.7l0.8-2 M866,726.4v7.3 M864.3,684.7l1.7,0.8
		l3,2l1.6,2.4l0.2,2.5l-1.3,2.4l-2.6,2.1l-3.8,1.6l-4.6,1l-5,0.3l-4.9-0.4l-4.4-1.2l-3.6-1.8l-2.4-2.2l-0.9-2.5l0.6-2.5l2-2.3
		l3.2-1.9l0.8-0.3 M867.5,740.1l2-1.7l1.3-2.4l0.1-0.9 M858.7,775.9l8.9-4.4 M867.5,771.5v-30.9 M661.8,768.6v40.5 M661.8,809
		l8.8,4.4 M670.5,813.5v7.3 M670.5,820.7l50.7,25.3 M777.4,826.1L737.6,846 M777.4,818.1v8 M783.9,814.9l-6.5,3.2 M783.9,822.9v-8
		 M823.7,802.9l-39.8,19.9 M679.6,697.6l4.1,0.6l1.2,0.1l4.2-0.3l3-0.7 M694.2,696.5l-2,6.4 M692.2,702.9l-0.1-0.7l-0.1-0.7v-0.7
		v-0.7v-0.7v-0.7v-0.7v-0.7 M721.2,798.2l-59.4-29.8 M737.5,798.2l3-1.4 M858.7,775.9l-0.3,0.8l-0.6,0.8l-0.9,0.7l-1.1,0.7l-1.3,0.5
		l-1.4,0.4l-1.5,0.3l-1.6,0.2 M858.7,754.8v21.1 M858.4,754.9l1.5-0.5l1.4-0.6l1.2-0.7l1-0.8l0.8-0.8l0.5-0.8l0.3-0.9l0.1-0.6
		 M737.5,846v-47.8 M721.2,846v-47.8 M721.2,846l1.8,0.7l2,0.5l2.1,0.3l2.3,0.1l2.3-0.1l2.1-0.3l2-0.5l1.8-0.7 M690.2,726.1l0.5,5.7
		l1.6,5.9l2.5,5.7l3.3,5.3l3.9,4.5l1.2,1.1 M679.7,731.3l10.4-5.2 M664.8,693.9l14.9,3.7 M737.5,798.2l-1.8,0.7l-2,0.5l-2.1,0.3
		l-2.3,0.1l-2.3-0.1l-2.1-0.3l-2-0.5l-1.8-0.7 M844.4,740.7l-1.2-0.8l-0.4-1l0.4-1l1.2-0.8l1.8-0.6l2.1-0.2l2.1,0.2l1.8,0.6l1.2,0.8
		l0.4,1l-0.4,1l-1.2,0.8l-1.8,0.6l-2.1,0.2l-2.1-0.2L844.4,740.7 M840.8,742.5l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7l3.4-1.1l4-0.4l4,0.4
		l3.4,1.1l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4L840.8,742.5 M682.1,732.4l0.5,5.4l1.6,5.5l2.6,5.4l3.3,4.8l3.9,3.9
		l4.2,2.7l3.6,1.3 M671.1,738l11-5.5 M659.8,694.3l1.2-0.4l1.4-0.2h1.4l0.9,0.2 M778.4,811.8l-2,1.6l-3.1,2.1l-3.1,1.7 M864.5,736
		l-3,1.4 M840,735.2l-15.2,7.6 M832.7,748.3l-2.6,1.6 M826,711.5l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4l-3.4-1.1 M808.1,715.3
		l-2.3-1.7l-0.8-2 M858.7,744.2l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4l-3.4-1.1 M840.8,747.9l-2.3-1.7l-0.8-2 M858.4,747.6
		l1.5-0.5l1.4-0.6l1.2-0.7l1-0.8l0.8-0.9l0.5-0.8l0.3-0.9v-0.9 M867.5,740.5l-2.3,1.1 M864.5,739l3,1.4 M864.5,736l3,1.4
		 M861.6,737.5l-8.9-4.4 M852.7,733l13.3-6.7 M866,726.4l-4.3,1.5l-5.1,0.8h-5.4l-5-0.9l-4.2-1.6l-3.1-2.2l-1.4-2.5l-0.1-1
		 M837.3,711.5l-9.6-4.8 M827.3,704.9l-0.3,0.2l-0.2,0.2l-0.1,0.3v0.3l0.1,0.3l0.2,0.3l0.3,0.3l0.3,0.2 M833.5,698.9l-0.5-0.1
		l-2.7-0.5l-2.9-0.3h-2.9l-2.9,0.3l-2.7,0.5l-2.5,0.8 M816.3,699.5l-2-1 M814.4,698.4l-11,5.5 M805.3,704.9l-2-0.9 M805.1,705.5
		l-9.8,4.8 M798.3,711.9l-3-1.5 M815,720.1l-3.2-0.2l-3.1-0.5l-2.8-0.8l-2.5-1l-2-1.3l-1.5-1.4l-1-1.5l-0.4-1.6 M820,722.7l-5.1-2.5
		 M820,722.7l0.8,0.6l0.4,0.7l-0.1,0.7l-0.1,0.2 M827.3,731.3l-3.1-0.9l-2.3-1.4l-1.2-1.6l-0.1-1.8l0.3-0.8 M827.3,731.3l12,2.3
		 M702.2,760.5l-1.1,2.3l-2.5,2.8l-3.3,1.4h-3.9l-4.2-1.4l-4.1-2.7l-3.9-3.9l-3.3-4.8l-2.5-5.4l-1.6-5.5l-0.5-5.4 M703.2,754
		l-0.3,3.1 M665.2,735l6,3 M665.2,733.5l1.4-0.7 M778.2,800.5l-0.6-0.7 M739.1,795.9L739.1,795.9 M700.5,776l2.4-0.8l0.7-0.3
		 M687.2,772.9l-8.1-5.9l-3.9-4.5l-3.5-5.3l-2.9-5.7l-2-5.9l-1.5-10.7 M665.2,735l-0.4-0.3l-0.2-0.3v-0.3l0.3-0.3l0.3-0.2
		 M660.3,729.7v36.4 M660.3,766.1l59.4,29.8 M706.9,773l-2.1,1.4 M660.3,729.7l6.2,3.1 M824.7,744.3l-0.4-0.3l-0.2-0.3v-0.3l0.3-0.3
		l0.3-0.2 M736.3,796.9l0.6-0.2l2-0.9 M719.7,795.9l2,0.9l2.4,0.6l2.5,0.4l2.6,0.2l2.6-0.2l2.6-0.4l0.4-0.1 M824.7,744.3l8,4
		 M830.1,750l-0.6,4.8l-1.7,4.9l-2.6,4.5l-3.3,3.7l-3.7,2.6l-3,1 M830.1,750l2.6,1.4 M725.2,750.9l-0.8-0.7l-0.4-1l0.4-1l1.2-0.8
		l1.8-0.6l2.1-0.2h0.4 M722.7,753.3l-0.8-0.3l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7l3.4-1.1l4-0.4h1.1 M815.4,773h1.8l3.7-1.1l3.7-2.5
		l3.3-3.7l2.6-4.5l1.7-4.9l0.6-4.8 M809,763.2l0.3,3.2 M832.7,751.3l3.2-1.4 M646.2,701.2l13.7-6.9 M702.2,767.1l0.1,0.8l0.5,1.4
		l1,1.4l1.4,1.3l1.9,1.1 M779,798.1l-0.5-0.1H778l-0.5,0.2l-0.4,0.3 M740.5,796.8v-0.4v-0.1 M740.5,818v-21.2 M772,786.2h-0.4
		l-0.5,0.1 M839.2,733.5l0.6,0.2l0.1,0.1l0.4,0.3l0.2,0.3v0.3l-0.3,0.3l-0.3,0.2 M770.9,784h-0.4l-0.5,0.1 M816.8,777l2.2-0.5
		l4.2-2.1l4.1-3.5l3.6-4.6l2.8-5.3l1.8-5.6l0.6-5.4 M836,750l8.8,4.4 M849.7,780.4l-44.5,22.3 M849.7,759.9v20.5 M808.2,780l1.2,2.3
		l2.9,3.3l2,1.3 M770.9,784l1,2.2 M740.5,818l0.8,5.1l1.7,3l2.2,1.4l2.7-0.1 M777.6,799.8l-0.6-1.3 M777,798.5l-0.7-1.4l-1.9-3.7
		l-3.4-7 M771,786.3l-0.9-2.2 M805.1,802.7l-0.3,0.9l-0.6,0.8l-0.8,0.7l-1.1,0.7l-1.3,0.5l-1.4,0.4l-1.5,0.3l-1.6,0.2 M805.1,781.5
		v21.2 M801.5,783.3l8.5-4.2 M810,779l1.9,4.5l2.9,3.3l3.7,2l4.3,0.5l4.7-1l4.8-2.4l4.7-3.7l4.3-4.8l3.7-5.7l2.9-6.2l1.9-6.4
		 M849.9,759.1l8.5-4.2 M858.4,747.6v7.3 M844.8,754.4l13.5-6.8 M844.8,754.4l-0.6,5.4l-1.8,5.6l-2.8,5.3l-3.6,4.6l-4.1,3.5
		l-4.2,2.1l-4.1,0.6l-3.6-1l-2.8-2.6l-1.8-3.8l-0.6-4.8 M772.5,780.5l-3.6,1.8 M778.4,783.4l-5.9-3 M772.5,773.2v7.3 M790.9,767.5
		l1.8,0.6l2.1,0.2l2.1-0.2l1.8-0.6l1.2-0.9l0.4-1l-0.4-1l-1.2-0.9l-1.8-0.6l-2.1-0.2l-2.1,0.2l-1.8,0.6l-1.2,0.9l-0.4,1l0.4,1
		L790.9,767.5 M787.3,769.2l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7l3.4-1.1l4-0.4l4,0.4l3.4,1.1l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.4,1.1
		l-4,0.4l-4-0.4L787.3,769.2 M784.4,810l3,1.4 M796.3,807.1l-8.9,4.4 M796.3,786v21.1 M787.3,780.6v30.9 M778.4,779.1v34.6
		 M781.4,815.2l-3-1.4 M867.5,737.5l-3,1.4 M784.4,810v-30.9 M781.4,815.2v-34.6 M798,806.9l-16.6,8.3 M851.4,780.1l-46.7,23.4
		 M867.5,772.1l-9.3,4.7 M867.5,771.5v0.7 M867.5,737.5v3 M789.5,779.5v7.3 M801.5,783.3l-0.9,0.8l-1.2,0.7l-1.4,0.6l-1.5,0.5
		l-1.6,0.4l-1.7,0.3l-1.8,0.2h-1.8 M801.5,776v7.3 M730.4,788.5l0.3,1.8l1.6,2.1 M765.9,794l-3-1.4 M765.9,750.3V794 M768.9,792.5
		l-3,1.4 M768.9,748.8v43.7 M759.1,794.4l3.8-2 M762.9,792.5v-43.7 M707.1,773.1v-7.3 M732.3,785.7l-25.2-12.6 M732.3,785.7v7.3
		 M732.5,793l-0.2-0.1 M732.3,749.3v29.1 M732.5,796l0.2-43.6 M735.3,797.4l-2.8-1.4 M735.3,753.7v43.7 M738.6,795.7l-3.3,1.7
		 M742.6,741.6l2.1,0.7l2.5,0.3l2.5-0.3l2.1-0.7l1.4-1l0.5-1.3l-0.5-1.3l-1.4-1l-2.1-0.7l-2.5-0.3l-2.5,0.3l-2.1,0.7l-1.4,1
		l-0.5,1.3l0.5,1.3L742.6,741.6 M739.7,743.1l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7l3.4-1.1l4-0.4l4,0.4l3.4,1.1l2.3,1.7l0.8,2l-0.8,2
		l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4L739.7,743.1 M719.5,756.4l-0.6-1.8 M805.3,771l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4l-3.4-1.1
		 M787.3,774.7L785,773l-0.8-2 M806.3,761.9l-2.5,1 M797.5,760.4l-2.4-1.2 M795.1,759.2l-0.6-0.2l-0.7-0.1h-0.7l-0.7,0.2l-0.3,0.2
		 M776.9,766.8l15.2-7.6 M776.9,766.8l-0.6,0.2l-0.7,0.1h-0.3 M775.3,767.1H775h-0.3h-0.3h-0.2h-0.2 M774.2,767.1l-1.4-0.1l-1.3-0.3
		l-0.5-0.3 M770.9,766.5l-2-1.1 M718.9,748.5l-2.4,1.2 M716.6,749.7l-2.4-1.2 M714.2,748.5l-11,5.5 M703.2,754l2,1 M705.2,755
		l-1.4,1.3l-1,1.4l-0.5,1.4v1.4l0.5,1.4l1,1.4l1.4,1.3l1.9,1.1 M707.1,765.8l25.2,12.7 M732.3,777.8l-0.4-0.4l-0.7-0.8l-0.5-0.9
		l-0.3-0.8l-0.1-0.8l0,0 M730.4,770.9l-15.6-7.8 M714.3,761.3l-0.3,0.2l-0.2,0.2l-0.1,0.2v0.2l0.1,0.2l0.1,0.3l0.2,0.2l0.3,0.2
		l0.3,0.2 M768.9,774.9l3.6-1.8 M772.5,773.2l8.9,4.4 M781.4,777.7l-3,1.4 M781.4,780.6l-3-1.4 M784.4,779.1l-3,1.4 M787.3,780.6
		l-3-1.4 M789.5,779.5l-2.3,1.1 M789.5,779.5h1.8l1.8-0.2l1.7-0.3l1.6-0.4l1.5-0.5l1.4-0.6l1.2-0.7l0.9-0.8 M815,769.2l-13.6,6.8
		 M815,769.2l-8.8-4.4 M806.3,764.8l2.6-1.6 M809,763.2l-2.6-1.4 M738.6,752.1v43.7 M759.1,794.4l-2.1,0.9l-2.4,0.7l-2.5,0.5
		l-2.7,0.3l-2.8,0.1l-2.7-0.2l-2.6-0.4l-2.5-0.6 M759.1,794.4v-43.7 M736.7,744.9l0.8,2l2.3,1.7 M739.7,748.5l3.4,1.1l4,0.4l4-0.4
		l3.4-1.1l2.3-1.7l0.8-2 M762.9,748.8l3,1.4 M768.9,748.8l-3,1.4 M763.7,746.3l5.1,2.5 M757.6,738.2l1.4,0.7l3.1,2.2l1.6,2.5
		l-0.1,2.7 M732.7,749.1l-2-2.5l-0.3-2.7l1.4-2.6l3-2.2l2-0.9 M732.3,749.3l0.3-0.2 M735.7,751l-3.3-1.7 M732.7,752.4l3-1.4
		 M732.7,752.4l2.6,1.3 M735.3,753.7l3.3-1.7 M759.1,750.7l-2.1,0.9l-2.4,0.7l-2.5,0.5l-2.7,0.3l-2.8,0.1l-2.7-0.2l-2.6-0.4
		l-2.5-0.6 M762.9,748.8l-3.8,2 M733.4,261.5l-0.5,0.3l-1.6,0.5l-1.9,0.2l-1.9-0.2l-1.6-0.5l-0.5-0.3 M725.8,213.8v1.8 M729.4,215.6
		v1.8 M732.9,213.8v1.8 M731.2,216.4v1.8 M727.6,216.4v1.8 M723.1,259l0.5,1.2l1.4,1l2,0.7l2.4,0.3l2.4-0.3l2-0.7l1.4-1l0.5-1.2
		 M734.7,218.2l-1.8,0.9 M732.9,219l-3.6-1.8 M729.4,217.3l-3.6,1.8 M725.8,219l-1.8-0.9 M724,218.2l3.6-1.8 M727.6,216.4l-3.6-1.8
		 M724,214.6l1.8-0.9 M725.8,213.8l3.6,1.8 M729.4,215.6l3.6-1.8 M732.9,213.8l1.8,0.9 M734.7,214.6l-3.6,1.8 M731.2,216.4l3.6,1.8
		 M718.4,223.7v0.3l0.9,2l2.3,1.6 M721.6,227.5l3.3,1.1l3.9,0.4l4-0.3l3.5-0.9l2.5-1.5l1.3-1.9l0.1-0.8 M723.2,219.6l2.9,0.9
		l3.4,0.3l3.4-0.3l2.9-0.9l2-1.4l0.7-1.7l-0.7-1.7l-2-1.4l-2.9-0.9l-3.4-0.3l-3.4,0.3l-2.9,0.9l-2,1.4l-0.7,1.7l0.7,1.7L723.2,219.6
		 M760.1,188.8l-1,0.5l-1.8,0.4l-2,0.1l-1.9-0.3l-1.4-0.7l0,0 M755,142.9v3.8 M755.7,140.4v4.8 M757.5,143.1v1.3 M750,142.5v1.3
		 M749.8,186.2l0.2,0.8l1.1,1.1l1.9,0.9l2.3,0.4l2.5-0.1l2.2-0.5l1.6-0.9l0.8-1.2l0.1-0.4 M756.9,145.3l-1.2-0.1 M755.7,145.2
		l-0.4,1.6 M754.7,144.2v2.5 M762.5,143.5l-0.3,1.3 M762.2,144.7l-5-0.3 M757.2,144.3l-0.7,2.5 M756.5,146.8l-2.5-0.2 M753.9,146.6
		l0.7-2.5 M754.7,144.2l-5-0.3 M749.7,143.8l0.3-1.3 M750,142.5l5,0.3 M755,142.9l0.7-2.5 M755.7,140.4l2.5,0.2 M758.3,140.6
		l-0.7,2.5 M757.5,143.1l5,0.3 M745.2,150.9l0.6,1.7l2,1.8l3.1,1.3l3.8,0.7 M754.5,156.3l4-0.1l3.7-0.8l2.8-1.4l1.6-1.9l0.3-1.3
		 M745.2,145.4v-0.2 M754.9,148l3.4-0.1l3.1-0.8l2.3-1.3l1.1-1.6l-0.2-1.7l-1.5-1.5l-2.5-1.2l-3.2-0.6l-3.4,0.1l-3.1,0.8l-2.3,1.3
		l-1.1,1.6l0.2,1.7l1.5,1.5l2.5,1.2L754.9,148 M819.6,218.4l-0.4,0.3l-1.6,0.6l-1.9,0.2l-2-0.2l-1.6-0.5l-0.5-0.3 M814.8,172.6v3.4
		 M816.7,170.2v4.1 M810.1,171.6v1.4 M817.2,173v1.4 M809.3,215.9L809.3,215.9l0.5,1.3l1.4,1l2,0.7l2.4,0.3l2.4-0.3l2-0.7l1.4-1
		l0.4-1.2 M813.9,173.7v2.7 M821.8,173.9l-0.9,1.2 M820.9,175.1l-4.7-0.9 M816.2,174.2l-1.9,2.4 M814.4,176.5L812,176 M812.1,176
		l1.9-2.4 M813.9,173.7l-4.7-0.9 M809.2,172.7l0.9-1.2 M810.1,171.6l4.7,0.9 M814.8,172.6l1.9-2.4 M816.7,170.2l2.4,0.4 M819,170.6
		l-1.9,2.4 M817.2,173l4.7,0.9 M804.6,180.6v0.4l1.1,2l2.4,1.6l3.4,1.1 M811.5,185.7l3.9,0.4l3.9-0.3l3.4-1l2.5-1.6l1.1-2l0.1-0.6
		 M812.2,177.4l3.4,0.3l3.4-0.3l2.8-0.9l1.9-1.4l0.6-1.7l-0.7-1.7l-2-1.4l-2.9-0.9l-3.4-0.3l-3.4,0.3l-2.8,0.9l-1.9,1.4l-0.6,1.7
		l0.7,1.7l2,1.4L812.2,177.4 M674,231.9l-1.3,0.6l-1.8,0.3h-2l-1.8-0.3l-1.2-0.6 M665.5,184.4v1.5 M669.6,185.8v2.2 M672.4,183.8
		v2.2 M671.7,186.5v1.5 M663.7,229.2l0.1,0.6l0.9,1.1l1.7,0.9l2.3,0.5h2.5l2.3-0.4l1.8-0.9l0.9-1.1l0.2-0.6 M668.2,186.8v2.2
		 M675.9,187.9l-1.4,1 M674.5,189l-4.2-1.4 M670.3,187.6l-2.8,2.1 M667.5,189.6l-2.1-0.7 M665.4,189l2.8-2.1 M668.2,186.8l-4.2-1.4
		 M664,185.5l1.4-1 M665.5,184.4l4.2,1.4 M669.6,185.8l2.8-2.1 M672.4,183.8l2.1,0.7 M674.5,184.4l-2.8,2.1 M671.7,186.5l4.2,1.4
		 M659,194l0.3,1.3l1.6,1.9l2.9,1.4 M663.8,198.5l3.7,0.8l4,0.1l3.8-0.7l3.1-1.3l2-1.8l0.5-1.7 M659,188.6v-0.2 M665,190.3l3.1,0.7
		h3.5l3.2-0.7l2.5-1.2l1.4-1.6v-1.7l-1.3-1.6l-2.4-1.2l-3.1-0.7h-3.5l-3.2,0.7l-2.5,1.2l-1.4,1.6v1.7l1.3,1.6L665,190.3
		 M697.8,199.1l-0.5,0.3l-1.6,0.5l-1.9,0.2l-1.9-0.2l-1.6-0.5l-0.5-0.3 M697.3,143.9v1.8 M690.2,143.9v1.8 M693.8,145.7v1.8
		 M692,146.6v1.8 M695.5,146.6v1.8 M687.4,196.4l0.5,1.2l1.4,1l2,0.7l2.4,0.3l2.4-0.3l2-0.7l1.4-1l0.5-1.2 M699,148.4l-1.8,0.8
		 M697.3,149.3l-3.6-1.8 M693.8,147.5l-3.6,1.8 M690.2,149.3l-1.8-0.8 M688.4,148.4l3.6-1.8 M692,146.6l-3.6-1.8 M688.4,144.8
		l1.8-0.8 M690.2,143.9l3.6,1.8 M693.8,145.7l3.6-1.8 M697.3,143.9l1.8,0.9 M699,144.8l-3.6,1.8 M695.5,146.6l3.6,1.8 M682.8,153.9
		v0.3l0.9,2l2.3,1.6 M686,157.8l3.3,1.1l3.9,0.4l4-0.3l3.5-0.9l2.5-1.5l1.3-1.9l0.1-0.8 M687.5,149.7l2.9,0.9l3.4,0.3l3.4-0.3
		l2.9-0.9l2-1.4l0.7-1.7l-0.7-1.7l-2-1.4l-2.9-0.9l-3.4-0.3l-3.4,0.3l-2.9,0.9l-1.9,1.5l-0.7,1.7l0.7,1.7L687.5,149.7 M661,408.9
		l-0.3-0.2l-0.3-0.1l-0.3,0.1l-0.2,0.1h-0.1l-0.3-0.2l-0.6-0.2 M662.5,406.4v0.3l-0.1,0.4l-0.2,0.4l-0.2,0.3l-0.3,0.3l-0.3,0.3
		l-0.3,0.3l-0.3,0.2 M665,407.7v0.3l-0.1,0.4l-0.2,0.3l-0.2,0.3l-0.3,0.3l-0.3,0.3l-0.3,0.3l-0.3,0.2 M801.8,328.5l-0.3-7.1
		 M801.2,330.6l0.4,3.6v0.4 M799.1,328.4L799.1,328.4L799.1,328.4l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1v-0.1 M794.3,326.7
		l1.1,1.8l1,0.9l0.6,0.2l0.3-0.1l0.3-0.2l0.3-0.3l0.3-0.3l0.5-0.9 M787.2,349.2v-0.5v-0.5l0.1-0.7l0.1-0.3V347v-0.1v-0.1l0,0l0,0
		l0,0l0,0l0,0 M787.3,346.7l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.2-0.3l0.2-0.3 M780.1,346.9l0.5-1l0.5-1.4l0.4-1.6
		l0.3-1.4 M777.5,342.1l0.2,1.4l0.6,1.4l0.8,1.3l0.9,0.8 M791.1,344l0.9,1.2l1,1.4l1.6,2.8 M787.2,349.2l0.6-0.3l0.5-0.4l0.6-0.6
		l0.6-0.7l0.5-0.7l0.5-0.9l0.3-0.8l0.3-0.9 M794.7,353.5l-0.3-0.3l-0.3-0.4l-0.2-0.5l-0.1-0.5v-0.6l0.1-0.6l0.2-0.6l0.3-0.6
		 M788.4,343.6l1.4,0.2l1.3,0.3 M792.8,336.4l-1.1-3.1l-0.3-0.3l-0.1-0.1h-0.1h-0.1h-0.1l0,0L791,333l-0.2,0.3l-0.2,3.6l0.3,7
		 M783.4,316.9l1.8,0.6l2.1,0.2l2.1-0.2l1.8-0.6l1.2-0.9l0.4-1l-0.4-1l-1.2-0.9 M791.2,313.1l-1.8-0.6l-2.1-0.2l-2.1,0.2l-1.8,0.6
		l-1.2,0.9l-0.4,1l0.4,1l1.2,0.9 M794.9,311.2l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.5,1.2l-4.1,0.4l-4.1-0.4l-3.5-1.2 M779.8,318.8
		l-2.3-1.7l-0.9-2l0.9-2l2.3-1.7l3.5-1.2l4.1-0.4l4.1,0.4l3.5,1.2 M781.8,341.4l-1.1-0.1l-1.3,0.1l-1.1,0.3l-0.9,0.3 M666.4,407.8
		v0.3v0.4l0.1,0.4l0.1,0.4l0.1,0.3l0.1,0.3l0.2,0.3l0.2,0.3 M781.8,341.4l3.6,1.1l2.9,1 M788.4,343.6l1.2-8.8l0.8-1.8l0.3-0.3
		l0.3-0.2l0.2-0.1h0.2l0.3,0.1 M781.3,328.2l0.5,13.3 M652.7,429.4l0.3,1.7l1,1.6l1.6,1.5l2.1,1.3 M799.1,328.4l-0.7,1l-0.8,0.6
		l-0.8,0.2l-0.8-0.1l-1.4-0.9l-1.5-1.9 M791.8,332.4l-1.5-3.8 M791.8,332.4l1,4 M794.5,349.4l-1.8-13 M804.4,363.8l0.2,0.4l0.1,0.5
		 M804.4,364l-0.1-0.2l0,0 M709.1,324.3l1.8,0.6l2.1,0.2l2.1-0.2l1.8-0.6l1.2-0.9l0.4-1l-0.4-1l-1.2-0.9 M716.9,320.5l-1.8-0.6
		l-2.1-0.2l-2.1,0.2l-1.8,0.6l-1.2,0.8l-0.4,1l0.4,1l1.2,0.9 M720.6,318.6l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.5,1.2l-4.1,0.4
		l-4.1-0.4l-3.5-1.2 M705.5,326.1l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7l3.5-1.2l4.1-0.4l4.1,0.4l3.5,1.2 M657.8,435.5l6.5,3.2
		 M657.8,423.1v12.4 M659,408.6l0.9-2l0.6-0.4l0.7-0.2l0.7,0.1l0.7,0.3 M662.5,406.4l2.5,1.3 M640.2,411.6l12.5,6.2 M664.2,438.8
		l0.6-6l1.7-5.2 M664.7,439.1l-0.3-0.3l-0.2-0.1 M665,407.7V363 M665,407.7l0.2,0.1l0.2,0.1h0.2h0.2h0.2h0.2h0.2h0.2 M675.9,416.2
		l-1.4,1l-2.1,0.7l-2.5,0.3l-2.5-0.3l-2.1-0.7 M674.5,412.6l1.4,1l0.3,0.5 M665.4,417.2l-1.4-1l-0.5-1.3l0.5-1.3l1.4-1l2.1-0.7
		l2.5-0.3l2.5,0.3l2.1,0.7 M663.6,410.2l0.8,0.3l0.9,0.2h0.9l0.9-0.1 M663.6,410.2l-2.5-1.3 M660.8,409.1l0.3-0.2 M659.6,409.7
		l1.2-0.6 M659.6,420.1l-3.1-2.3l-0.9-1.4l-0.3-1.5l0.3-1.5l0.9-1.4l3.1-2.3 M668.2,424.4l-0.1,0.3 M667.8,424.6l0.3-0.2
		 M668.2,424.4l-8.7-4.3 M659.6,420.1l-0.3,0.3l-0.3,0.3l-0.3,0.3l-0.3,0.4l-0.3,0.4l-0.2,0.4l-0.1,0.4v0.4 M666.5,427.5l-8.8-4.3
		 M716.7,335.2l1,2 M666.5,427.5l0.2-0.2l0.1-0.3l0.1-0.3l0.1-0.5l0.3-0.9l0.3-0.4l0.3-0.3 M666.5,427.5l0.3,0.3l0.2,0.3
		 M668.1,424.7l-0.1,0.3l0,0l0,0l0,0v0.1l-0.1,0.3 M667.8,424.6l0.3,0.2 M698.3,336.1l-0.2,0.2l-0.8,0.3l-0.9,0.1 M851.3,424.1h-0.3
		h-0.3l-0.3-0.1l-0.3-0.2l-0.3-0.2l-0.2-0.2l-0.2-0.3l-0.1-0.3 M848,427.6l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.1-0.3l-0.1-0.3
		l-0.1-0.3v-0.3 M847.1,425.5L846,426 M780,347l-1-0.9l-0.8-1.2l-0.6-1.4l-0.3-1.4 M848.5,422.3l2.7,1.8 M794.7,353.5l-1.5-0.9
		l-1.8-0.9l-2.2-1.2l-2.1-1.3 M787.2,349.2l-3.5-1.2l-3.5-1.1 M780.1,346.9L780.1,346.9 M817.8,383.5l-2.2-3.7l-4-5l-4.1-5.2
		l-1.7-2.6l-1.2-2.4l-0.3-0.9l-0.1-0.5l0.1-0.1l0.2,0.4l0.1,0.5 M777.4,342.2L777.4,342.2 M777.5,342.1l-0.7-13.5 M677.9,413.8
		l0.3,0.8l0.1,0.8v0.8l-0.2,0.8l-0.3,0.7l-0.3,0.6l-0.4,0.5l-0.5,0.3 M679.8,418.5l-3.2,0.5 M679.7,418.7l0.2-0.3 M682.8,414.8
		l-0.3,1.2l-0.6,1.1l-0.9,0.9l-1.1,0.5 M681.6,413.2l0.2,1.5l-0.3,1.6l-0.7,1.4l-1,0.9 M846.2,440.2l2-0.2l2,0.2 M666.4,407.8v-44.6
		 M666.4,363.2H666h-0.3l-0.3-0.1l-0.3-0.1 M681.1,350.8v1.1l-0.1,1.2l-0.2,1.4l-0.3,2.6l-0.1,2.6 M680.5,359.6l-0.1,0.2v0.2
		l0.1,0.2l0.2,0.2 M677.9,413.8l3.7-0.6 M705.5,331.7l-2.3-1.7l-0.8-2 M723.8,327.8l-0.8,2l-2.3,1.7l-3.5,1.2l-4.1,0.4l-4.1-0.4
		l-3.5-1.2 M793.1,327.2l1.2-0.4 M794.3,326.7l1.4-0.4l1.3-0.5l1.2-0.6l1-0.7l0.8-0.7l0.7-0.8l0.5-0.8l0.3-0.9 M798,319.6l3.6,1.8
		 M798,320.5l-0.1,0.8l-1,1.6l-2,1.4l-2.7,1l-3.2,0.5h-3.4l-3.2-0.5l-2.7-1 M779.8,324.2l-2-1.4l-1-1.6l-0.2-0.9 M776.6,315.6
		l-0.9,0.6l-0.9,0.7 M774.8,316.9l-0.9,0.6 M773.8,317.5l-2.9,1.4 M770.9,318.9l-0.2,0.6l-0.1,0.6v0.6l0.1,0.6l0.2,0.6l0.3,0.6
		l0.3,0.5l0.3,0.5 M771.8,323.4l0.3,0.6l-0.1,0.6l-0.4,0.6l-0.7,0.5 M770.8,325.6l-10.5,5.3 M760.4,330.9l-0.9,0.3l-1,0.2l-1.1,0.1
		l-1.1-0.1 M756.3,331.5l-25.5-3.4 M730.8,328l-1.1-0.3l-0.9-0.3l-0.7-0.5l-0.3-0.5 M702.3,322.6l-0.4,0.3l-2.6,2l-1.3,2.3l0.3,2.4
		l1.7,2.2 M727.8,326.4l-1.6-2.2l-2.5-1.6 M700,331.6l3.9,2.2l2.9,0.9l3.2,0.5l4.2,0.1l2.4-0.2 M716.7,335.2l1.1-0.2l1.1-0.2l1-0.3
		l1-0.3l0.9-0.3l0.9-0.3l0.8-0.4l0.8-0.4 M724.4,332.8l0.8-0.4l1.1-0.3l1.2-0.1l1.2,0.1 M728.7,332.1l16.1,2.1 M744.8,334.3l1.1,0.3
		l0.9,0.4l0.5,0.4l0.3,0.4l0.2,0.4l-0.1,0.4l-0.4,0.6l-0.8,0.5 M746.6,337.8l-13,6.5 M738.6,348l0.4-0.4l0.6-0.3 M739.7,347.2
		l37.2-18.6 M776.8,328.6l0.9-0.3l1.1-0.2h1.2l1.2,0.2 M781.3,328.2l1.1,0.2l1.1,0.2l1.1,0.1l1.1,0.1h1.1h1.1l1.1-0.1l1.1-0.1
		 M790.2,328.6l2.9-1.4 M682.2,435.2l6.7-3.3 M681.6,413.2l0.5-22.4 M682.6,413.9l-0.4-0.3l-0.6-0.3 M724.1,434.7l-1.2,0.4l-1.4,0.6
		l-1.4,0.7l-1.4,0.7 M849.1,427.8l-0.9-0.1H848 M848,427.6l-0.9-0.3l-0.6-0.4l-0.3-0.3l-0.1-0.3v-0.1 M846,426.1v-3 M724.4,435.5
		l-0.5,0.2l-0.6,0.3l-0.7,0.3l-0.8,0.3l-0.8,0.3l-0.8,0.3l-0.7,0.3l-0.6,0.3 M722.1,439.3l0.8-0.2l0.7-0.4l0.6-0.5l0.5-0.6
		 M841.7,439.4v-0.3l0.5-1.3l1.4-1l2.1-0.7l2.5-0.3l2.5,0.3l2.1,0.7l1.4,1l0.5,1.3v0.3 M718.2,435.3l0.2,0.9l0.3,0.9 M849.1,427.8
		l0.8-0.6l0.9-0.9l0.4-1l-0.1-1.1 M849.1,427.8h-2.4l-2.3,0.3l-2.2,0.4l-2,0.6l-1.7,0.8l-1.4,0.9l-1,1l-0.6,1.1 M851.3,424.1
		l17.5,8.8 M841.7,439.4l1.6,0.4l4.3,0.5l4.4-0.3l3.9-1.1l2.9-1.7l1.4-2.1l-0.2-2.2l-1.8-2l-3.1-1.6l-4.1-0.9l-4.5-0.1l-4.2,0.7
		l-3.5,1.4l-2.2,2l-0.6,2.2l1,2.2l0.8,0.8 M671.1,442.5l11-5.5 M683.6,429.6l-0.3,0.5l-0.9,3.2L682,437 M679.7,418.7l1.2-0.5l1-0.9
		l0.7-1.2l0.3-1.3 M698.7,422.9l-0.2,0.6l-0.2,0.6l-0.2,0.7l-0.2,0.7l-0.1,0.7l-0.1,0.6l-0.1,0.6l-0.1,0.5 M710,461.8l0.4,0.1
		l0.3,0.2 M699.6,423.1l-0.3,1.2l-0.3,1.3l-0.2,1.3l-0.1,1 M708.5,448.5L708.5,448.5h-0.2h-0.1h-0.1H708H708h-0.1h-0.1 M709.6,445.5
		L709.6,445.5L709.6,445.5l0.2,0.1h0.1h0.1h0.1h0.1h0.1 M698.8,427.9l1.2-0.8l1.3-1l1.1-1.2l0.7-1.1 M726.5,440.3l-0.2-0.3l-0.1-0.3
		l-0.1-0.3l-0.1-0.3l-0.1-0.4l-0.1-0.4v-0.4v-0.3 M720,439.8l-0.9-2 M719.1,437.8L719.1,437.8v-0.2v-0.1v-0.1v-0.1v-0.1v-0.1v-0.1
		 M718.7,437.1l-4.8,2.4 M701.9,431.6l-3.1-3.7 M698.8,427.9l-1.2-0.2 M708.5,444.4l-0.1,1.2 M738.7,447.8l0.3-0.2l0.3-0.3l0.3-0.3
		l0.3-0.3l0.2-0.3l0.2-0.3l0.1-0.4v-0.3 M741.5,465.2l1.2-0.6 M710.8,462.1l6.5,3.2 M708.5,448.5l1.7,6.9l0.6,6.6 M746.3,464.6
		l-0.3-0.1l-0.4-0.1l-0.5-0.1h-0.5H744l-0.5,0.1l-0.4,0.1l-0.3,0.2 M742.7,464.6v-12.4 M706.5,428.1l-0.3,0.4l-0.5,0.5l-0.6,0.5
		l-0.6,0.5l-0.7,0.5l-0.7,0.4l-0.7,0.4l-0.6,0.3 M714,439.5l-1,0.3l-1.2-0.2l-2.7-1.3l-3.2-2.5l-1.9-1.9l-1.9-2.2 M713.5,437.7v0.3
		l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3 M742.7,452.3l-1.2,0.6 M710.3,445.5l-0.3,0.3 M710.1,445.7l-0.3,0.3
		l-0.3,0.3l-0.4,0.4l-0.6,0.8l-0.1,0.3v0.3v0.3l0.1,0.3 M717.2,452.9l-8.8-4.3 M677.9,413.8l4.2-23 M742.7,452.3l0.8-0.3l0.9-0.1
		l0.9,0.1l0.9,0.3 M740.9,449.2l0.3,0.3l0.3,0.3l0.3,0.3l0.3,0.4l0.3,0.4l0.2,0.4l0.1,0.4v0.4 M703.2,458.6l6,3 M665.2,439.5l6,3
		 M671.1,442.5l0.5-4.8l1.6-3.9l2.5-2.8l3.3-1.4h3.9l4.2,1.4l4.2,2.7l3.9,3.9l3.3,4.8l2.5,5.4l1.6,5.5l0.5,5.4 M682.1,390.8
		l1.4-12.2 M701.4,423.3l-0.9-0.1h-0.9 M702.9,423.8l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.3-0.1
		 M742.7,446.5v0.3l-0.1,0.4l-0.2,0.3l-0.2,0.3l-0.3,0.3l-0.3,0.3l-0.3,0.3l-0.3,0.2 M746.3,452.3l-0.9-0.6l-0.9-0.4l-1.3-0.7
		l-0.9-0.8l-0.4-0.3l-0.5-0.3 M709.1,461.5l0.6,0.2l0.3,0.1 M708.5,444.4l-4.8-9l-6.1-7.7 M697.7,427.7l-2.9-1.4l-6.5-2.5l-4-2.4
		l-2.6-1.6l-2-1 M679.7,418.7l-6.1,1.5l-4.8,4.2 M668.8,424.5l-0.4,0.3l-0.3,0.4l-0.5,1.1l-0.3,1l-0.3,0.8 M667,428.2l-0.5,1.2
		l-0.4,1.2l-0.4,1.3l-0.3,1.4l-0.3,1.4l-0.2,1.4l-0.1,1.5v1.5 M664.7,439.1l0.3,0.3l0.2,0.1 M709.1,461.5l-1.5-10.7l-2-5.9l-2.9-5.7
		l-3.5-5.3l-3.9-4.5l-8.1-6l-8.1-2.1l-3.9,0.5l-3.5,1.7l-2.9,2.9l-2,3.8l-1.5,9.2 M710,461.8v-1.6l-0.1-1.6l-0.2-1.6l-0.3-1.6
		l-0.3-1.7l-0.4-1.7l-0.4-1.7l-0.5-1.7 M707.7,448.6l-0.2-0.9v-0.9l0.3-1.1l0.3-0.8l0.3-0.6 M703.7,401.6l-0.8,22.2 M702.9,423.8
		l3.6,4.2 M829.1,449.3l0.1,0.2l0.9,5.1 M832.7,455.9l-2.6-1.4 M827.5,450.2l2.1,1 M724.8,446.9l2.1,0.7l2.5,0.3l2.5-0.3l2.1-0.7
		l1.4-1l0.5-1.3l-0.5-1.3l-1.4-1 M734,442.3l-2.1-0.7l-2.5-0.3l-2.5,0.3l-2.1,0.7l-1.4,1l-0.5,1.3l0.5,1.3l1.4,1 M708.5,445.6h0.4
		h0.1l0,0l0,0l0,0h0.6 M710.3,445.5h-0.7 M719,449.8l-8.7-4.3 M740.9,449.2l-1.2,0.6 M741.3,449.1l-0.3,0.2 M741.3,449.1l-2.6-1.3
		 M738,446.1l-0.2,0.4l0.1,0.4l0.3,0.4l0.6,0.3 M726.5,440.3l2.4-0.3l2.5,0.1l2.4,0.4l2,0.8l1.4,1l0.9,1.2l0.3,1.3l-0.5,1.2
		 M722.1,439.3l0.7,0.6l1,0.4l1.3,0.2l1.3-0.1 M722.1,439.3l-1.2,0.2l-0.9,0.3 M708.5,445.6l11.6-5.8 M717.2,465.2l2.6,1.1l3,0.8
		l3.2,0.5l3.4,0.2l3.4-0.2l3.2-0.5l3-0.8l2.6-1.1 M741.5,465.2v-12.4 M717.2,452.9v12.4 M832.7,455.9l-0.6-4.2l-1.7-3.2l-0.1-0.1
		 M836,454.6l-3.2,1.4 M741.5,452.9v-0.4l-0.1-0.4l-0.2-0.4l-0.3-0.4l-0.3-0.4l-0.3-0.3l-0.3-0.3l-0.3-0.3 M739.7,449.8l-4.6,1.5
		l-2.8,0.4l-3,0.2l-3-0.2l-2.9-0.4l-4.6-1.5 M719,449.8l-0.3,0.3l-0.3,0.3l-0.3,0.3l-0.3,0.4l-0.3,0.4l-0.2,0.4l-0.1,0.4v0.4
		 M741.5,452.9l-2.6,1.1l-3,0.8l-3.2,0.5l-3.4,0.2l-3.4-0.2l-3.2-0.5l-3-0.8l-2.6-1.1 M724.4,435.5l0.3,2 M724.7,437.5L724.7,437.5
		h0.2h0.2h0.2h0.2h0.2h0.2h0.2 M790.3,411.4l0.3-0.2 M697.1,345.6l13.7-6.9 M724.1,434.7l0.3,0.9 M724.4,435.5v-53.3 M709.7,372.6
		L709.7,372.6L709.7,372.6L709.7,372.6L709.7,372.6l0.2,0.2 M710,372.8l1.3-0.3l1.6-0.1h1.2l0.8,0.1l0.8,0.2 M715.8,372.7l-0.9-0.3
		l-0.7-0.2l-0.9-0.2l-0.8-0.1h-1l-0.7,0.1l-0.7,0.2l-0.6,0.2 M709.6,372.5l0.2,0.2 M710.2,375.5l1.4-0.5l1.6-0.3l1.6-0.1l1.4,0.2
		 M721.2,434.5l-0.9,0.1l-0.8,0.2l-0.8,0.3l-0.6,0.3 M724.1,434.7l-0.3-0.1l-0.3-0.1l-0.3-0.1h-0.3h-0.4h-0.4h-0.4H721 M716.1,374.9
		l8,59.8 M836,454.6l-0.6-4.8l-1-2.6 M836,454.6l9.2,4.6 M789.3,419.5h-0.6l-0.7-0.3l-0.7-0.4l-0.5-0.5 M703.7,401.6l-0.3-0.2
		l-0.4-0.2l-0.4-0.1l-0.4-0.1l-0.4-0.1l-0.5-0.1h-0.5h-0.5 M706.8,374.5L706.8,374.5v-0.2v-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.1
		l0.1-0.1 M716.2,368.5l-0.7-0.3l-0.8-0.2l-0.9-0.1H713l-1.7,0.3L710,369 M694.3,361.4l-1.2-0.9l-0.4-1l0.4-1l1.2-0.9l1.8-0.6
		l2.1-0.2l2.1,0.2l1.8,0.6 M702,357.6l1.2,0.9l0.4,1l-0.4,1l-1.2,0.8l-1.8,0.6l-2.1,0.2l-2.1-0.2l-1.8-0.6 M690.6,363.3l-2.3-1.7
		l-0.8-2l0.8-2l2.3-1.7l3.5-1.2l4.1-0.4l4.1,0.4l3.5,1.2 M705.7,355.7l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.5,1.2l-4.1,0.4l-4.1-0.4
		l-3.5-1.2 M696.5,370.3l2.2,0.1l2.2-0.2l2.1-0.4l1.9-0.6l1.6-0.8l1.2-0.9l0.8-1l0.3-1.1 M687.5,365l0.2,0.9 M687.6,365.8l-3.7-1.9
		 M696.5,370.3l3.7,1.9 M690.6,378.7l0.3,0.1h0.3l0.5-0.2l0.8-0.7l1.5-2.1l2.4-5.6 M687.6,365.8l0.7,7.2l0.8,3.2l0.6,1.4l0.4,0.6
		l0.3,0.3l0.3,0.2 M713.5,437.7l4.8-2.4 M718.2,435.3l-8-59.8 M710.2,375.5l-0.2-2.7 M710,372.8l-0.8,1.1l-0.8,0.6l-0.8,0.2
		l-0.9-0.2 M790.7,411.2l1.4,1l0.2,1.8l-1,2.5l-2,3 M789.3,419.5l-2.9,4.3 M772,460.1l-0.3,0.9 M682.8,415v0.4v0.1 M698.7,422.9
		l0.9,0.3 M699.6,423.1l0.6-22.2 M700.1,400.9l1.3-16.4l1.6-6.7l1.5-3.1l0.5-0.6l0.5-0.4l0.4-0.2l0.3-0.1l0.3,0.1l0.3,0.2
		 M707.1,373.7l0.7,0.3h0.3l0.3-0.1l0.3-0.2l0.3-0.3l0.6-0.9 M709.6,372.5l0.3-3.3 M710,369.1l-0.9,0.6l-1,0.5l-1.1,0.5l-1.2,0.4
		l-1.3,0.3l-1.4,0.3l-1.4,0.3l-1.4,0.2 M700.1,372.1l-3,11.6l-2.5,7.9l-2.1,5.2l-1.4,2.6l-1.3,1.6l-1.2,0.7l-0.5,0.1l-0.5-0.2
		l-0.5-0.3l-0.5-0.6l-0.8-1.7l-0.8-2.6l-0.6-3.5l-0.6-6.5l-0.3-9.4l0.3-12.9 M683.9,364l0.3-0.8l0.4-0.7l0.6-0.7l0.7-0.7l0.9-0.6
		l0.8-0.4 M703.7,401.6l2.8,26.4 M706.5,428.1l2.3,4.4l0.6,1.1l1.3,2l1.5,1.9l0.7,0.4h0.3l0.3-0.1 M706.8,374.5l6.7,63.2
		 M706.8,374.5l-0.3-0.3l-0.3-0.1h-0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.2,0.2l-0.2,0.2l-0.3,0.5l-0.9,3.1l-0.7,6.7l0.1,16.7 M796.8,466.9
		l-2-0.2l-2,0.2 M780.6,436.6l-0.6-0.1l-0.7-0.2l-0.7-0.2l-0.5-0.3 M835.6,412.8v20.2 M827.2,432.1l-3.5-1.1l-4.7,0.2l-4.9,1.8
		l-4.9,3.2l-4.7,4.5l-4.1,5.5l-3.3,6.2 M832.4,434.7l3.2-1.6 M777.6,438.4l0.5,0.2l0.6,0.2l0.7,0.2l0.6,0.1 M781.5,433.8l-0.6-0.1
		l-0.7-0.2l-0.6-0.3l-0.5-0.2 M786.4,423.8h-0.6l-0.3-0.1l-0.3-0.2l-0.3-0.2l-0.3-0.3l-0.6-0.5 M816.2,403l1.3-0.3l2.7-0.3
		 M786.4,423.8l-4.9,10 M772,460.1l-0.4-0.3l-0.5-0.3 M779,442.1l-0.5-0.1l-0.5-0.2l-0.5-0.2l-0.4-0.2 M834.2,405.5l0.6,0.3
		 M781.5,433.8l-0.8,2.9 M725.8,437.5v-55.2 M780.5,460.7l1.7-5.9 M779,442.1l-3.4,9.4l-3.7,8.7 M772,460.1l1.2,0.5l1.3,0.4l1.4,0.3
		l1.4,0.3 M792.5,454.7v-20.2 M782.1,454.8l0.3-6.9l0.5-3.9 M782.1,454.8v5 M740.3,388.4v4.3l-0.4,7.2 M739.9,399.9l-0.1,0.2v0.2
		l0.1,0.2l0.2,0.2 M725.8,382.3h-0.3h-0.3l-0.3-0.1l-0.3-0.1 M801.2,466.1v-0.3l-0.5-1.3l-1.4-1 M799.3,463.5l-2.1-0.7l-2.5-0.3
		l-2.5,0.3l-2.1,0.7l-1.4,1l-0.5,1.3v0.3 M792.5,434.4l4.4-5.7l4.9-5.2l6-4.9l6.2-3.8l6.2-2.5l5.9-1l4.9,0.3l4.4,1.3 M835.6,412.8
		l0.1-1.5l0.1-0.8l0.2-0.8l0.2-0.5l0.3-0.5l0.7-0.3l1.4,0.3l2.5,2l3.4,4.9l1.6,6.5v0.8 M846,423l1.1,2.5 M849.3,423.7L849.3,423.7
		V423 M834.9,405.7l0.5,0.2 M780.6,436.6l-0.7,2.3 M779.9,438.9l-0.9,3.1 M779,442.1l1.1,0.5l1.2,0.6l1.2,0.6l0.4,0.2 M782.9,444
		l1.8-6.1l1.6-2.9l2.4-2.3l0.9-0.4l0.9-0.2l0.7,0.1l0.6,0.3l0.3,0.5l0.3,0.9v0.5 M797,452.4l-4.5,2.2 M792.5,454.7l-1.9,0.3
		l-1.8,0.4l-1.7,0.5l-1.5,0.6l-1.3,0.8l-1,0.9l-0.8,0.9l-0.5,0.9 M782.1,459.9l-1.7,0.9 M777.1,461.5l1.8-0.3l1.5-0.6 M777.1,461.5
		l-1.5,0.1l-1.5-0.1l-1.4-0.3l-1.2-0.4 M813.3,460.5l-16.3-8.2 M803.3,456.6l-3.7-1.3l-4.3-0.5l-4.4,0.3l-3.9,1.1l-2.9,1.7l-1.4,2.1
		l0.2,2.2l1.8,2l3.1,1.6l4.1,0.9l4.5,0.1l4.2-0.7l3.5-1.4l2.2-2l0.6-2.2l-1-2.2L803.3,456.6 M792.1,471.2l-20.5-10.3 M771.6,461
		l-0.3-0.2l-0.8-0.1 M710.8,338.7l0.9-0.3l1-0.1l2,0.1l4.8,0.9l6.3,2.2l5.8,2.7l6,3.7l1.6,1.4l1.3,1.8l0.1,0.8l-0.3,0.7l-0.6,0.4
		 M813.3,460.5l3.3-6.2l4.1-5.5l4.7-4.5l4.9-3.2l4.9-1.8l4.7-0.2l4.1,1.4l3.3,2.9 M792.1,471.2l21.2-10.6 M790.3,474.3v-0.4l0.1-0.4
		l0.2-0.4l0.3-0.4l0.3-0.4l0.3-0.3l0.3-0.3l0.3-0.3 M790.3,486.6l25.2-12.7 M845.2,459.2l-0.6-4.8l-1.8-3.8L840,448l-3.6-1l-4.1,0.6
		l-4.2,2.1l-4.1,3.5l-3.6,4.6l-2.8,5.3l-1.8,5.6l-0.6,5.4 M845.2,459.2l25.2-12.7 M870.5,434.2v12.4 M868.7,432.8l0.3-0.2l0.3-0.1
		l0.3,0.1l0.3,0.2l0.3,0.3l0.2,0.3l0.1,0.3v0.4 M847.4,443.4l21.2-10.6 M739.7,353.3l-13.7,6.9 M777.6,438.4l0.6-2.4 M778.2,436
		l0.8-3 M779.1,433.1l4.8-10.5 M783.8,422.6l3-4.4 M739.7,353.3l7.2,5.9l7,6.7l6.6,7.5l6.3,8.1l5.9,8.6l5.3,9.1l4.8,9.4l4.1,9.7
		 M740.2,400.6v44.6 M740.2,445.2l2.5,1.3 M742.7,446.5l0.8,0.5l0.8,0.8l0.6,0.8l0.5,0.9l0.9,2.8 M746.3,452.3v12.4 M746.3,464.6
		l43.9,22 M790.3,486.6v-12.4 M764.2,461.2l26.1,13 M708.9,364.2v0.7v0.3 M708.9,365.2l7.3,3.2 M716.2,368.5l-0.5,4.2 M715.8,372.7
		l0.3,2.2 M716.1,374.9l8.4,7.2 M741.4,388.2v1.2l-0.4,4l-0.5,3.8l-0.3,3.4 M740.2,400.6l8.7,14l7.1,15.3l5.2,15.9l3,15.4
		 M764.2,461.2l2.8-0.3l1.9-0.2l1.7-0.1 M770.5,460.7l0.5-1.2 M771,459.5l3.3-8.6l1.4-4.6l0.4-1.6l0.8-3.1 M777,441.6l0.6-3.1
		 M777.6,438.4l-3.7-11.6l-4.7-11.3l-5.6-11l-6.4-10.5l-7.1-9.9l-7.6-9l-8.1-8.1l-8.4-7.1 M726.1,360.1l-0.9,0.3l-1,0.1l-2-0.1
		l-4.8-0.9l-6.3-2.2l-5.8-2.7l-6-3.7l-1.6-1.4l-1.3-1.8l-0.1-0.8l0.3-0.7l0.6-0.4 M636.7,407.1l0.2,1.4l1.6,2l1.7,1.1 M665,363
		l-10.3,6.8l-8,11l-4.9,14.4l-1.6,16.5 M665,363l0.3-3.4l1.1-3.6l1.9-3.3l2.5-2.6l2.6-1.8l2.5-1.1l2-0.4l1.5,0.1l1,0.5l0.6,0.9
		l0.3,1.3v1.6l-0.2,1.9l-0.3,2.2l-0.2,2.4l-0.1,2.6 M836.6,631.4L836.6,631.4 M858.1,587.8L858.1,587.8 M836.2,630.4L836.2,630.4
		v-0.2v-0.1v-0.1V630v-0.1v-0.1l0.1-0.1 M856.7,588.5L856.7,588.5l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1h0.1 M835.1,622.5
		L835.1,622.5L835.1,622.5v-0.2v-0.1 M850.2,592L850.2,592 M835.1,621.1L835.1,621.1v-0.2v-0.1v-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.1
		 M848.7,593.6L848.7,593.6v-0.2v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M836.2,629.7L836.2,629.7L836.2,629.7 M836.3,629.8
		L836.3,629.8 M856.9,588.5h-0.2 M856.7,588.5h-0.2l0,0 M837,612.3L837,612.3l-0.1-0.2V612v-0.1v-0.1v-0.1v-0.1v-0.1 M842.7,600.2
		L842.7,600.2l0.1-0.2l0.1-0.1l0.1-0.1h0.1h0.1h0.1h0.1 M837.4,610.4L837.4,610.4v-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1
		l0.1-0.1 M841.7,602L841.7,602v-0.2v-0.1v-0.1v-0.1v-0.1l0.1-0.1l0.1-0.1 M835.3,620.4L835.3,620.4h0.2 M835.5,620.4L835.5,620.4
		 M837.1,612.3L837.1,612.3 M843.2,599.8h0.2 M848.8,593.6L848.8,593.6 M848.7,593.6L848.7,593.6L848.7,593.6 M837.7,609.9l0.2-0.1
		 M837.8,609.9h0.2 M841.8,602.1L841.8,602.1 M841.7,602l-0.1-0.2 M873.7,586.1l-15.5,1.7 M858.2,587.8L858.2,587.8 M858.1,587.8
		L858.1,587.8H858h-0.1h-0.1h-0.1h-0.1h-0.1h-0.1 M857.2,588L857.2,588 M836.6,631.5L836.6,631.5 M836.3,630.5L836.3,630.5
		 M836.2,630.4L836.2,630.4v0.2v0.1v0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M836.6,631.4L836.6,631.4 M836.6,631.4l6,10l1.2,2l0.8,1.4
		 M857.2,588l11.4-1.2l2.2-0.3l1.7-0.2 M856.9,588.5L856.9,588.5v-0.2v-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1h0.1 M836.3,630.5
		L836.3,630.5v-0.2v-0.1v-0.1v-0.1V630l0.1-0.1l0.1-0.1 M844.2,643.8l-7.9-13.3 M835.3,622.7L835.3,622.7L835.3,622.7l-0.1-0.2v-0.1
		v-0.1 M835.1,621.1v0.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2 M835.1,622.3L835.1,622.3 M835.1,622.4l5.6,7.7l1.1,1.5l0.8,1.1 M835.2,621.2
		L835.2,621.2 M850,592.1L850,592.1l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M849,592.9L849,592.9
		 M862.6,592l-12.7,0.1 M850.1,592.1L850.1,592.1 M835.2,621.2L835.2,621.2V621v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1
		 M842.7,631.2l-7.5-10 M849.1,592.9l9.3,0.1h1.8h1.4 M848.8,593.6L848.8,593.6v-0.2v-0.1v-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.1
		 M838.3,627l-1.4,2l-0.5,0.9 M836.5,629.8l3,4.9l5.2,8.8 M872.6,586.9l-8.2,0.9l-3,0.3l-4.5,0.5 M856.9,588.5l-1.4,3.4
		 M837.4,610.4L837.4,610.4 M837.4,610.4l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M837,611.7
		L837,611.7 M837,611.7l6.1,4.9l1.2,0.9l0.9,0.8 M837.1,612.4L837.1,612.4l-0.1-0.2v-0.1v-0.1V612v-0.1v-0.1v-0.1 M842.7,600.2
		l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M841.9,601.3L841.9,601.3 M842.7,600.2L842.7,600.2
		 M842.7,600.2L842.7,600.2l0.2-0.2l0.1-0.1l0.1-0.1h0.1h0.1h0.1h0.1 M852.8,602.9l-10.1-2.7 M837.4,610.4L837.4,610.4l0.1-0.2
		l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1 M845.7,616.8l-8.2-6.3 M842,601.3l7.4,2.2l1.4,0.4l1.1,0.3 M841.8,602.1L841.8,602.1
		v-0.2v-0.1v-0.1v-0.1v-0.1v-0.1l0.1-0.1 M840.7,615.7l-0.1,0.3v0.1l-0.1,0.3l-0.2,0.4l-0.3,0.3l-1,0.5l-2.9,2.3l-0.7,0.8
		 M837.1,612.4l1.5,1.4 M835.5,620.5l2.8,3.7l4.9,6.6 M845.6,619.1l-4.4-3.6l-1.6-1.3l-2.4-2 M845.5,600.2l-1.4-0.4h-0.7
		 M848.8,593.6l-0.3,3.6l0.3,1.9v0.5l-0.1,0.3l-0.2,0.3l-0.2,0.3l0,0l-0.2,0.2 M843.4,599.8l3.7,1l6.6,1.8 M861.6,593.6h-6.7h-2.4
		h-3.7 M841.8,602.1l0.8,2.9l0.9,1.3l0.2,0.3v0.3l-0.1,0.3l-0.1,0.3v0.1l-0.3,0.5v0.1l-0.1,0.2l-0.3,0.3l-0.3,0.2h-0.9l-3,0.8
		L838,610 M838,609.9l3.1,2.4l5.4,4.2 M852.1,605.1l-5.4-1.6l-2-0.6l-3-0.8 M882.9,585.7L882.9,585.7h0.2h0.1h0.1h0.1h0.1h0.1h0.1
		 M882.9,585.7L882.9,585.7L882.9,585.7L882.9,585.7l0.2,0.2 M883,586l1.7,1l0.3,0.2 M878.1,587.4l0.9-0.2l0.9-0.2l0.9-0.1l0.8-0.1
		h0.9h0.8l0.8,0.1l0.9,0.1 M878.1,587.4l-0.4-0.2l-3-1 M874.7,586.2l-0.5-0.2H874h-0.2h-0.1l0,0 M872.5,586.4l0.2-0.1l0.2-0.1
		l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1h0.2h0.2 M872.5,586.4L872.5,586.4L872.5,586.4l-0.1,0.2l0.2,0.3 M872.6,586.9l1.6,1.4l0.3,0.3
		 M867.2,592.7l0.9-0.7l0.9-0.6l0.9-0.6l0.9-0.5l0.9-0.5l0.9-0.4l0.9-0.4l0.9-0.4 M867.2,592.7l-0.4-0.1l-3-0.7 M863.7,591.9
		l-0.5-0.1H863l-0.2,0.1l-0.1,0.1l0,0 M861.5,593l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1 M861.5,593
		L861.5,593l-0.1,0.2v0.2l0.2,0.3 M861.6,593.6l1.7,1.7l0.3,0.3 M857,603.1l0.8-1l0.8-1l0.8-0.9l0.8-0.9l0.9-0.9l0.8-0.8l0.8-0.9
		l0.9-0.8 M857,603.1l-0.4-0.1l-2.8-0.4 M853.7,602.7h-0.4l-0.2,0.1l-0.2,0.1l-0.1,0.1l0,0 M851.9,604.3l0.1-0.2l0.1-0.2l0.1-0.2
		l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2 M851.9,604.3L851.9,604.3l-0.1,0.3l0.1,0.3l0.3,0.3 M852.1,605.1l1.8,2l0.3,0.3
		 M849.6,616.7l0.5-1.2l0.5-1.2l0.5-1.2l0.6-1.2l0.6-1.2l0.6-1.2l0.7-1.1l0.7-1.1 M849.6,616.7l-0.4-0.1l-2.6-0.3 M846.5,616.4h-0.4
		l-0.2,0.1l-0.2,0.1l-0.1,0.1l0,0 M845.2,618.3l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2 M845.2,618.3v0.2
		v0.2l0.1,0.3l0.3,0.4 M845.6,619.1l2,2l0.3,0.3 M846,631.2l0.1-1.2l0.2-1.2l0.2-1.2l0.3-1.2l0.3-1.2l0.3-1.2l0.3-1.2l0.3-1.2
		 M846,631.2l-0.3-0.1l-2.4-0.3 M843.3,630.9h-0.3l-0.1,0.1l-0.1,0.1v0.1l0,0 M842.6,632.7v-0.2v-0.2v-0.2V632v-0.2v-0.2v-0.2v-0.2
		 M842.6,632.7L842.6,632.7l0.1,0.3l0.2,0.3l0.3,0.3 M843.2,633.5l2.3,2l0.3,0.3 M847,644.1l-0.3-0.9l-0.3-1l-0.2-1l-0.2-1l-0.2-1
		l-0.1-1.1l-0.1-1.1v-1.1 M847,644.1l-0.3-0.1l-2.1-0.5 M844.6,643.5h-0.3h-0.1l-0.1,0.1v0.1l0,0 M844.6,644.9l-0.1-0.2l-0.1-0.2
		l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M844.6,644.9L844.6,644.9l0.2,0.2l0.2,0.2l0.4,0.3 M845.4,645.6l2.5,1.8
		l0.4,0.3 M852.4,653.2l-0.6-0.6l-0.6-0.6l-0.5-0.7l-0.5-0.7l-0.5-0.7l-0.4-0.8l-0.4-0.8l-0.4-0.8 M852.4,653.2l-0.3-0.1l-1.9-0.8
		 M850.2,652.3l-0.3-0.1h-0.1l0,0l0,0l0,0 M850.8,652.9L850.8,652.9l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
		l-0.1-0.1 M850.8,652.9L850.8,652.9l0.3,0.2l0.3,0.2l0.5,0.3 M851.8,653.4l2.8,1.4l0.4,0.3 M855.6,655.3L855,655 M888.1,588
		l0.8,0.3l0.2,0.1 M888.1,588l-0.4-0.3l-2.8-1.4 M884.8,586.5l-0.4-0.3l-0.2-0.1l-0.2-0.1H884l0,0 M879.3,651.9l-1.2,0.9l-5.4,3.1
		 M872.8,655.8l-5.3,1.7l-5,0.3l-4.5-1.2l-3.9-2.5l-3.1-3.8l-2.1-4.9l-1.1-5.9v-6.5l1.1-7l2.1-7.1l3.1-6.9l3.9-6.5l4.5-5.8l5-4.8
		l5.3-3.7l5.4-2.4l5.2-0.9l4.8,0.5l4.2,1.9l3.5,3.2l2.6,4.4l1.6,5.4l0.6,6.2v0.1 M900.5,612.5l0.1-2.1l-0.5-6l-1.5-5.3l-2.6-4.2
		l-3.4-3.1l-4.1-1.8l-4.7-0.4l-5,0.9l-5.2,2.3l-5.1,3.5l-4.8,4.7l-4.4,5.5l-3.7,6.2l-3,6.7l-2,6.8l-1,6.7v6.3l1,5.7l2,4.8l3,3.7
		l3.7,2.5l4.4,1.1l4.8-0.3l5.1-1.6 M873.7,655.1l5.2-2.9l2.8-2.1 M885.8,642.6l0.3-3.4l1.3-5.3l2.3-5.1l3.1-4.6l3.7-3.7l0.5-0.3
		 M945.4,672.9l-7.2-9.9l-0.3-0.3 M945.4,672.9l3.9-3.7l3.4-4.7l2.5-5.4l1.4-5.5l0.2-5.2l-1-4.4l-2.2-3.3l-3.1-1.9l-3.8-0.3
		 M945.4,672.9v0.9 M940.3,640.7l6.5-2.1 M946.8,637.7v0.9 M928.7,664.2l2.8,1.4 M938,662.7l-8.6-4.2 M938,662.7l2.5-2.7l2-3.3
		l1.4-3.7l0.5-3.6l-0.3-3.2l-1.1-2.6l-1.8-1.7l-0.9-0.4 M931.5,665.5l0.3,0.3l7.2,9.9 M928.7,664.6l0.4,0.3l2.4,0.6 M938.9,676.7
		v-0.9 M940.3,641.5l-3.9,3.7l-3.4,4.7l-2.5,5.4l-1.4,5.5l-0.2,5.2l1,4.4l2.2,3.3l3.1,1.9l3.8,0.3 M940.3,641.5v-0.9 M910.1,617.3
		l-1.1-0.4l-3.7-0.3l-4,1.1 M901.4,617.5l-4,2.5l-3.8,3.7l-3.2,4.6l-2.5,5.2l-1.5,5.4l-0.5,5.2l0.6,4.6l1.7,3.7l2.6,2.5l0.5,0.3
		 M940.3,640.6l-3.8,3.4l-3.3,4.3l-2.6,5l-1.8,5.3l-0.8,5.2l0.3,4.7l1.4,3.9l2.4,2.8l3.1,1.5l3.7,0.1 M940.3,640.6L923,632
		 M923.1,631.9l-0.9-0.7l-0.4-0.8l0.1-0.9l0.6-0.8l1-0.5l1.3-0.3l1.5,0.1l1.5,0.3l1.4,0.5l0.4,0.2 M929.5,629.1l17.3,8.6
		 M945.4,673.8l3.8-3.4l3.3-4.3l2.6-5l1.8-5.3l0.8-5.2l-0.3-4.7l-1.4-3.9l-2.4-2.8l-3.1-1.5l-3.7-0.1 M945.4,673.8l-12.4-6.2
		 M898,611.3l-2-0.7h-3.7l-4,1.4l-4,2.8l-3.7,3.9l-3.1,4.8l-2.3,5.3l-1.3,5.4l-0.2,5l0.9,4.4l2,3.4l2.5,2 M904.4,616.6l-0.6-0.1
		l-3.7,0.3l-3.9,1.7l-3.7,3l-3.4,4.1l-2.7,4.8l-1.8,5.1l-0.8,5l0.3,4.6l1.4,3.7l1.6,2 M908.4,616.7l-0.3-0.2l-3.2-1.4h-3.7l-4,1.4
		l-4,2.8l-3.7,3.9l-3.1,4.8l-2.3,5.3l-1.3,5.4l-0.2,5l0.9,4.4l2,3.4l2.8,2.1l1.4,0.5 M892.4,610.6l-0.8-0.2l-3.7,0.3l-3.9,1.7
		l-3.7,3l-3.4,4.1l-2.7,4.8l-1.8,5.1l-0.8,5l0.3,4.6l1.4,3.7l1.8,2.1 M896.3,610.6l-0.3-0.3l-3.1-1.4h-3.7l-4,1.4l-4,2.8l-3.7,3.9
		l-3.2,4.7L872,627l-1.3,5.4l-0.2,5l0.9,4.4l2,3.4l2.8,2.1l1.4,0.5 M885.9,605.2l-2-0.7h-3.7l-4,1.4l-4,2.8l-3.7,3.9l-3.1,4.8
		l-2.3,5.3l-1.3,5.4l-0.2,5l0.9,4.4l2,3.4l2.5,2 M750,494.3l2-0.5l2,0.3l2,1.1l1.7,2l1.1,2.2 M799.1,578.4l1.1,2.2l0.5,2.3l-0.3,2.4
		l-1.1,2l-1.5,1.1 M751.1,495.3l-0.1-0.2L751,495l-0.1-0.2l-0.2-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.2H750 M751.1,495.3l-8,4 M797.6,586.9
		l0.1,0.2l0.1,0.2l0.1,0.2v0.2v0.2v0.2v0.2l-0.1,0.2 M797.6,586.9l1.4-1.2l0.8-1.7l0.2-2l-0.5-2.2l-0.8-1.5 M789.6,590.9l8-4
		 M743.1,499.3l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.2-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.1H742 M789.6,590.9l0.1,0.2l0.1,0.2l0.1,0.2v0.2v0.2
		v0.2v0.2l-0.1,0.2 M742,499.4l0.2-0.1h0.2h0.1 M761.8,497.6l-0.2-0.1h-0.2h-0.2h-0.1 M789.2,591.3L789.2,591.3l-0.2,0.3l-0.2,0.1
		 M802,576.8l-0.1,0.2l-0.1,0.2l-0.1,0.1l-0.1,0.1 M791.7,583.7l0.2-0.1l0.1-0.1l0.1-0.2v-0.1 M802,576.8l1.5-1l0.9-1.3v-0.3
		 M797.5,536.3L797.5,536.3l0.2-0.1l0,0l0,0 M797.5,536.3l2,3.8l3.5,7.9l1.1,4.3l0.1,3.1l-0.5,1.8l-0.9,1.1l-0.6,0.4 M765.2,497.2
		l-0.2-0.1l-1.7-0.1l-1.7,0.6 M776.3,507.8l1.3-0.3l1.4,0.2l2,1l2.9,2.7l5.6,9.1l2.2,4.5 M776.3,507.8L776.3,507.8L776.3,507.8
		l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1v0.1v0.1 M802.2,558.8L802.2,558.8v-0.2v-0.1v-0.1v-0.1v-0.1v-0.1v-0.1 M794,562.2l8-4 M802,558.2
		l0.8-0.7l0.5-0.9l0.3-1.9l-0.3-3.5l-3.5-9.6l-2.6-5.3 M797.3,536.4l-5.8-11.3 M791.5,525l-1.6-3.1l-3-5.3l-3.9-5.4l-1.7-1.6l-2-1.2
		l-1.3-0.3l-1.2,0.2l-0.2,0.1 M776.7,508.4l-8,4 M768.6,512.4L768.6,512.4l-0.1-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
		l-0.1-0.1 M794,562.2L794,562.2l0.1,0.2v0.1v0.1v0.1v0.1v0.1v0.1 M770.7,516.8l0.1-0.2v-0.1v-0.1l0,0 M791.6,557.9h0.2l0.1,0.1
		l0.1,0.1l0,0 M770.7,516.8l3.7,4.3l3.5,4.7l3.1,4.9l2.9,5.2l2.5,5.4l2.1,5.5l1.7,5.6l1.4,5.5 M779,602.4l0.3-0.1l0.5-0.3l0.5-0.3
		l0.2-0.1 M780.5,601.7l8-4 M788.4,597.7l1.4-0.9l1.4-0.9l1.3-1l1.2-1.1l1.1-1.2l1.1-1.4l1-1.4l0.9-1.5 M797.8,588.4l-8,4
		 M789.8,592.4l-0.5,0.2H789l-0.2-0.2V592l0.2-0.4 M788.9,591.7l0.4-0.9l0.4-0.9l0.4-0.9l0.3-1l0.3-1l0.3-1l0.3-1l0.3-1.1
		 M791.7,583.7l0.3-0.8l0.4-0.8l0.6-0.7l0.6-0.5l0.3-0.2 M793.9,580.8l8-4 M802,576.8l0.3-2l0.3-2.1l0.2-2.2l0.1-2.2v-2.3l-0.1-2.3
		l-0.2-2.4l-0.3-2.4 M802.2,558.8l-8,4 M794.2,562.9l-0.3,0.1l-0.3-0.1l-0.5-0.3l-0.7-1l-0.9-2.8 M791.6,558.5l-1.4-5.7l-1.8-5.7
		l-2.2-5.6l-2.5-5.5l-2.9-5.4l-3.2-5.1l-3.6-4.8l-3.7-4.4 M770.2,516.5l-1.8-2.3l-0.3-0.8l-0.2-0.8l0.1-0.4l0.3-0.3l0.2-0.1
		 M768.3,511.9l8-4 M776.3,507.8l-1.8-1.6l-1.8-1.5l-1.8-1.4l-1.8-1.4l-1.9-1.3l-1.9-1.1l-1.9-1l-1.9-0.9 M761.8,497.6l-8,4
		 M753.7,501.6l-0.7,0.3l-0.8,0.1h-0.9l-0.8-0.1l-0.6-0.2 M750,501.7l-1-0.4l-1-0.3l-1-0.3l-1-0.3l-1-0.3l-1-0.3l-1-0.2l-1-0.2
		 M742,499.4l-0.5-0.2l-0.3-0.3l0.1-0.3l0.3-0.3l0.3-0.2 M742,498.3l8-4 M750,494.3l-1.8-0.1h-1.7l-1.7,0.1l-1.6,0.3l-1.6,0.3
		l-1.5,0.4l-1.5,0.6l-1.4,0.7 M821.7,541l1,0.1l3.9-0.4 M803.7,502.4l-0.8,1.3 M823.5,540.3l-1.1,0.3 M806.6,501.8l-0.5-0.9
		 M806.2,500.9l-1.9,0.9 M804.3,501.8l-0.4,0.3l-0.2,0.3 M803.7,502.4l-0.6,1.2 M822.8,540.5l0.5-0.2h0.3 M823.5,540.3l3,0.3
		 M826.6,540.7l0.6-0.1l0.3-0.1 M827.4,540.5l1.9-0.9 M829.2,539.6l-1.6-2.7 M833.8,536.1L832,537 M808.8,498.4l1.9-0.9
		 M810.7,497.4l4-0.5l4.3,1.1l4.4,2.6l4.2,4l3.6,5l2.8,5.7l1.7,5.9l0.5,5.7l-0.7,5.1l-1.9,4.1 M808.2,498.9l0.3-0.4l0.3-0.2
		 M832,537l-0.5,0.2h-0.3 M808.8,498.4l4-0.5l4.4,1.1l4.4,2.6l4.2,4l3.6,5l2.8,5.7l1.7,5.9l0.5,5.7l-0.7,5.1l-1.9,4.1 M806.4,501.9
		l0.4-0.3l0.2-0.3 M828.1,536.9h-0.6l-0.3,0.1 M807,501.4l1.4-2.5 M807,501.4l3.7-0.3l4,1.1l4,2.5l3.8,3.7l3.2,4.6l2.5,5.2l1.6,5.4
		l0.5,5.2l-0.6,4.6l-1.6,3.7 M831.1,537.2l-3-0.3 M831.1,537.2l1.8-4l0.7-5l-0.5-5.6l-1.7-5.9l-2.7-5.6l-3.6-4.9l-4.2-3.9l-4.4-2.6
		l-4.3-1.1l-3.9,0.4 M827.3,536.9l-13.6,6.8 M827.3,536.9l1.6-3.7l0.5-4.6l-0.5-5.1l-1.5-5.3l-2.5-5.1l-3.3-4.5l-3.7-3.6l-4-2.5
		l-3.9-1.1l-3.6,0.3 M792.8,508.7l13.6-6.8 M792.8,508.7l-1.1,0.5l-1.3,0.6l-1.3,0.6l-0.2,0.1 M809.2,547.3l13.6-6.8 M807.8,548.1
		l0.3-0.2l0.4-0.3 M809.2,547.3l-0.8,0.4 M808.5,547.7L808.5,547.7L808.5,547.7l0.2-0.1h0.1h0.1h0.1h0.1h0.1 M808.9,547.3l0.8-0.7
		l1-0.8l1.1-0.8l1.1-0.8l0.7-0.4 M692,578.4L692,578.4l-0.1-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
		 M691.5,577.8l-1-0.9l-1.2-0.7l-1.3-0.3l-1.3,0.1l-0.4,0.2 M694.8,572.8l-3.3,5 M692,578.4l3.3-5 M692.6,579.4l-0.3-0.6l-0.3-0.4
		 M666.5,594.9l9.3-4.6 M686.3,576l-0.3,0.2 M678.5,598.7l-6.5,3.2 M659.7,601.5l0.5-0.3l0.3-0.2 M655.8,602.7l1.6-1.2l9.1-6.6
		 M666.5,594.9l-1.4-3.6l-0.6-3.6l0.3-3.2l1-2.5l1.4-1.5 M656.9,603.6l2.8-2 M667.8,574.2l-1-0.3l-0.7-0.2 M663.7,573.3l0.9,0.2
		l1.2,0.3 M656.1,604.1l0.5-0.3l0.3-0.2 M655.8,602.7l-1.5,2.3 M655.8,602.7l-2.4-5.1l-1.4-5.3l-0.2-4.9l1-4.2l2-3.1l3-1.8l3.6-0.3
		l3.9,1.3l3.9,2.7 M654.2,605l1.9-0.9 M670.8,579.7l-4.3-3.3l-4.4-1.9l-4.2-0.3l-3.7,1.4l-2.8,2.8l-1.8,4.2l-0.6,5.1l0.6,5.7
		l1.8,5.9l2.8,5.6 M686,576.1l-10.7,5.4 M686,576.1l3.1-4.7 M675.5,578.3l-0.4,0.2h-0.2 M674.9,578.4l-1.5,0.2 M673.3,578.6
		l-0.4,0.1l-0.3,0.1 M672.7,578.8l-1.9,0.9 M670.8,579.7l-1.5,2.3 M675.3,581.5l-5,0.4l-0.9,0.1 M681.1,585.1l13.6-6.8 M706.5,598.7
		l1.4-0.9l2.3-2.9l1.4-4l0.3-4.8l-0.9-5.3l-1.9-5.4l-2.8-5l-3.5-4.3l-3.9-3.3l-4.1-2l-3.9-0.6l-3.5,0.8 M677.3,594.5L677.3,594.5
		l-1.3-0.4h-1.2l-0.8,0.3 M660.4,601.1l13.6-6.8 M689.1,571.5l-13.6,6.8 M689.1,571.5l1.1-0.3h1.3l1.4,0.3l1.3,0.7l0.7,0.7
		 M694.8,572.8L694.8,572.8l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M695.3,573.4l0.7,1.2l0.3,1.2L696,577
		l-0.6,0.9l-0.6,0.4 M680.5,585.4l0.4-0.1l0.3-0.1 M678.9,603.5l-1.4,0.3l-2.6-0.4l-2.7-1.4 M672.1,601.9l-9.1,6.6l-1.6,1.2
		 M683.5,608.4l0.7-1.5 M678.9,585.6l1.5-0.2 M684,604.9l-0.5,2.7l-1.1,3.1 M678.3,585.7l0.4-0.2h0.2 M676.4,586.7l1.9-0.9
		 M661.4,609.6l-1.5,2.3 M659.8,611.9l4.3,3.3l4.4,1.9l4.2,0.3l3.7-1.4l2.8-2.8l1.8-4.2l0.6-5.1l-0.6-5.7l-1.8-5.9l-2.8-5.6
		 M676.4,586.7l-1.5,2.3 M675,589l2.4,5.1l1.4,5.3l0.2,4.9l-1,4.2l-2,3.1l-3,1.8l-3.6,0.3l-3.9-1.3l-3.9-2.7 M682.5,543l0.2-0.1
		l0.2-0.2l0.2-0.2l0.2-0.2l0.2-0.2l0.1-0.2l0.1-0.2l0.1-0.2 M720.4,613.9h-0.2H720h-0.3h-0.3h-0.3L719,614l-0.2,0.1l-0.2,0.1
		 M682,541.3v0.2v0.2v0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.2l0.2,0.2 M680.4,543.9l2.1-1 M679.9,542.3l-0.1,0.2v0.2v0.3l0.1,0.3
		l0.1,0.3l0.1,0.3l0.2,0.2l0.2,0.2 M718.7,614l0.1,0.3l0.1,0.3l0.1,0.3l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.1l0.2,0.1 M718.7,614l-2,1.1
		 M716.5,615.2v0.3l0.1,0.3l0.1,0.3l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.1l0.2,0.1 M680.4,543.9l-0.2,0.1l-0.2,0.2l-0.2,0.2l-0.2,0.2
		l-0.2,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M680.4,543.9l1.7,1.6 M716.5,615.2l-0.2,0.1l-0.3,0.1l-0.3,0.1h-0.3h-0.3h-0.3h-0.2h-0.2
		 M716.3,612.8l0.3,2.3 M683.6,541.7l-0.2-0.2l-0.2-0.1l-0.2-0.1l-0.3-0.1h-0.3h-0.3h-0.2l-0.2,0.1 M720.4,613.9v0.3v0.3l-0.1,0.3
		l-0.1,0.3l-0.1,0.2l-0.1,0.2l-0.2,0.2l-0.2,0.1 M682.1,545.5l-0.2,0.1l-0.2,0.2l-0.2,0.2l-0.2,0.2l-0.2,0.2l-0.1,0.2l-0.1,0.2
		l-0.1,0.2 M682,541.3l-2.1,1 M716.3,612.8l-0.2,0.1l-0.2,0.1l-0.3,0.1h-0.3h-0.3h-0.3h-0.2h-0.2 M717.6,616.6l2-1.1 M679.9,542.3
		l-0.2,0.1h-0.2h-0.3H679l-0.3-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.2 M681.1,538.7l-1.3,3.5 M717.6,616.6l-0.2,0.1l-0.2,0.2l-0.1,0.2
		l-0.1,0.2l-0.1,0.3l-0.1,0.3v0.3v0.3 M717.6,616.6l3.6,1 M681.1,538.7l-0.2,0.1h-0.2h-0.3h-0.3l-0.3-0.1l-0.3-0.1l-0.2-0.1
		l-0.2-0.2 M721.1,617.6l-0.2,0.1l-0.2,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.3l-0.1,0.3v0.3v0.3 M684.1,542.3L684.1,542.3 M720.1,613
		L720.1,613 M719,597.2l0.9-0.1 M703.5,548.3l-3.2,0.9l-3.1,2.2l-0.7,0.8 M736.4,556.9l2.2-0.8 M740.7,560.2l-2,1.3 M738.6,556.2
		l2,4.1 M736.5,555.5l-0.2,0.1l-0.1,0.2l-0.1,0.2v0.2v0.3l0.1,0.3l0.1,0.3l0.1,0.3 M738.7,561.5l0.1,0.3l0.2,0.2l0.2,0.2l0.2,0.1
		l0.2,0.1h0.2h0.2l0.2-0.1 M736.4,556.9l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1 M738.7,561.5
		l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1 M736.4,556.9l2.3,4.5 M735.1,554.8l0.1,0.2l0.2,0.2
		l0.2,0.2l0.2,0.1l0.2,0.1h0.2h0.2l0.2-0.1 M738,554.7l-1.4,0.7 M740,562.3l-0.2,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2v0.3v0.3l0.1,0.3
		l0.1,0.3 M740,562.3l1.4-0.7 M741.4,561.5l-0.2,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2v0.3v0.3l0.1,0.3l0.1,0.3 M736.6,554l0.1,0.2
		l0.2,0.2l0.2,0.2l0.2,0.1l0.2,0.1h0.2h0.2l0.2-0.1 M768,567.5L768,567.5l-0.2-0.1 M751.8,533.6L751.8,533.6L751.8,533.6
		 M776.5,570.8l-4.3-1.7l-4.2-1.6 M751.8,533.7l0.5-1.2l2-4.9l0.8-1.8 M721.6,615.1l0.3,2.3 M721.9,617.4v0.4l-0.1,0.4l-0.1,0.3
		l-0.2,0.3l-0.3,0.2l-0.3,0.1h-0.3l-0.3-0.1 M720.4,619.1l-3.6-1 M717,618.1l-0.3-0.2l-0.3-0.3l-0.3-0.3l-0.3-0.3l-0.3-0.3l-0.3-0.4
		l-0.2-0.4l-0.1-0.4 M714.9,615.3l-0.3-2.3 M714.6,613v-0.4l0.1-0.4l0.1-0.3l0.2-0.3l0.3-0.2l0.3-0.1h0.3l0.3,0.1 M716,611.2l3.6,1
		 M719.6,612.3l0.3,0.2l0.3,0.3l0.3,0.3l0.3,0.3l0.3,0.4l0.3,0.4l0.2,0.4l0.1,0.4 M684.5,543.2l-1.3,3.5 M683.2,546.6L683,547
		l-0.2,0.3l-0.3,0.2l-0.3,0.1h-0.3l-0.3-0.1l-0.3-0.2l-0.3-0.3 M681,546.8l-1.7-1.6 M679.3,545.2l-0.3-0.3l-0.3-0.4l-0.3-0.4
		l-0.2-0.4l-0.1-0.4l-0.1-0.4v-0.4l0.1-0.3 M678.2,541.9l1.3-3.5 M679.5,538.4l0.2-0.3l0.2-0.3l0.3-0.2l0.3-0.1h0.3l0.3,0.1l0.3,0.2
		l0.3,0.3 M681.8,538.2l1.7,1.6 M683.5,539.8l0.3,0.3l0.3,0.4l0.3,0.4l0.2,0.4l0.1,0.4l0.1,0.4v0.4l-0.1,0.3 M739.1,555.6l2.3,4.5
		 M741.4,560.1l0.2,0.4l0.2,0.4l0.1,0.4v0.4l-0.1,0.5l-0.2,0.3l-0.2,0.3l-0.3,0.2 M741.1,563l-1.4,0.8 M739.7,563.8l-0.3,0.1h-0.3
		l-0.3-0.1l-0.3-0.2l-0.3-0.3l-0.3-0.3l-0.3-0.3l-0.3-0.4 M737.3,562.3l-2.3-4.5 M735,557.7l-0.2-0.4l-0.2-0.4l-0.1-0.4V556l0.1-0.4
		l0.2-0.3l0.2-0.3l0.3-0.2 M735.1,554.8l1.4-0.7 M736.6,554l0.3-0.1h0.3l0.3,0.1l0.3,0.2l0.3,0.3l0.3,0.3l0.3,0.3l0.3,0.4
		 M745.7,610.6l-2.4-1.3l-2.2-2.3l-1.6-2.9l-0.8-3.1l0.1-2.9l0.9-2.2l1.7-1.1l2.2,0.1l2.4,1.3l2.2,2.3l1.6,2.9l0.8,3.1l-0.1,2.9
		l-0.9,2.2l-1.7,1.1L745.7,610.6 M707.8,535.9l-2.4-1.3l-2.2-2.3l-1.6-2.9l-0.9-3.1l0.1-2.9l0.9-2.2l1.7-1.1l2.2,0.1l2.4,1.3
		l2.2,2.3l1.6,2.9l0.8,3.1l-0.1,2.9l-0.9,2.2L710,536L707.8,535.9 M751.6,645.2l0.4-0.3l0.7-0.3l0.4-0.3l0.5-0.3l0.8-0.4l1.4-0.7
		l6-3.1 M756,642.9l-2.2,1.2l-0.6,0.3 M761.8,639.9l0.3-0.2l0.3-0.2l0.2-0.1h0.1 M763.1,639L763.1,639l-0.2,0.1l-0.1,0.1l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M763.1,639L763.1,639L763.1,639L763.1,639L763.1,639 M763.1,638.9l-2.4,0.9l-1.7,0.8
		l-0.6,0.3L758,641h-0.1l0.1-0.1l0.6-0.6l0.6-0.5l0.4-0.3l0.4-0.3l0.8-0.5l1.3-0.8l5.9-3.5 M768,634.4l0.3-0.2l0.3-0.2l0.2-0.2
		l0.1-0.1 M769.2,633.3L769.2,633.3l-0.1,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M769.2,633.3L769.2,633.3
		l0.1-0.2h-0.1h-0.1 M769.2,633.2l-2.5,0.8l-1.7,0.7l-0.6,0.2l-0.4,0.1l-0.1-0.1v-0.2l0.5-0.8l0.5-0.7l0.3-0.3l0.4-0.4l0.7-0.5
		l1.3-0.8l5.7-3.7 M773.1,627.3l0.3-0.2l0.2-0.2l0.2-0.2v-0.1 M774.2,625.9L774.2,625.9l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.1 M774.2,625.9l0.1-0.2v-0.1l-0.1-0.1h-0.1 M774,625.7l-2.7,0.6l-1.8,0.6l-0.6,0.2h-0.5l-0.2-0.2v-0.3
		l0.3-0.9l0.3-0.9l0.3-0.4l0.3-0.4l0.7-0.6l1.2-0.8l5.4-4 M776.9,618.5l0.2-0.2l0.2-0.3l0.1-0.3v-0.1 M777.6,617L777.6,617v0.2v0.1
		v0.1v0.1v0.1v0.1v0.1 M777.6,617v-0.2l-0.1-0.1l-0.1-0.1h-0.1 M777.5,616.7l-2.9,0.4l-1.9,0.5l-0.7,0.2l-0.5-0.1l-0.3-0.2l-0.1-0.3
		l0.2-1.1l0.3-0.9l0.3-0.5l0.3-0.5l0.6-0.7l1.2-0.8l5.2-4.2 M779.2,608.5l0.2-0.3l0.2-0.3l0.1-0.3v-0.1 M779.8,606.8L779.8,606.8
		v0.2v0.1v0.1v0.1v0.1v0.1v0.1 M779.8,606.8v-0.2l-0.1-0.2l-0.1-0.1h-0.1 M779.5,606.4l-3.1,0.3l-2,0.5l-0.7,0.1l-0.6-0.1l-0.3-0.3
		l-0.2-0.3v-1.2l0.1-1l0.2-0.5l0.3-0.5l0.5-0.7l1.1-0.9l4.9-4.3 M780,597.5l0.2-0.3l0.1-0.3l0.1-0.3v-0.1 M780.4,595.6L780.4,595.6
		v0.2v0.1v0.1v0.1v0.1v0.1v0.1 M780.4,595.6v-0.3l-0.1-0.2l-0.2-0.1H780 M780,595.2l-3.2,0.2l-2,0.4l-0.7,0.1l-0.7-0.1l-0.3-0.3
		l-0.2-0.3l-0.2-1.3v-1.1l0.1-0.6l0.3-0.5l0.5-0.7l1.1-0.9l4.7-4.4 M779.3,585.6l0.2-0.3l0.1-0.3v-0.3v-0.1 M779.4,583.7v0.2v0.2
		v0.2v0.2v0.2v0.2v0.2v0.2 M779.4,583.7l-0.1-0.3l-0.1-0.2l-0.2-0.1H779 M779,583.2l-3.4,0.1l-2,0.4l-0.8,0.1l-0.7-0.2l-0.3-0.3
		l-0.3-0.3l-0.3-1.4l-0.2-1.1V580l0.2-0.5l0.4-0.8l1-0.9l4.5-4.5 M777.1,573.2l0.2-0.3l0.1-0.3v-0.3v-0.1 M777.1,571.3v0.2v0.2v0.2
		v0.2v0.2v0.2v0.2v0.2 M777.1,571.3L777,571l-0.2-0.2l-0.2-0.1h-0.1 M776.5,570.8l-3.6,0.1l-2.1,0.4l-0.8,0.1l-0.8-0.2l-0.4-0.3
		l-0.3-0.3l-0.5-1.4l-0.3-1.1v-0.6l0.1-0.5l0.4-0.8l0.9-0.9l4.2-4.5 M773.5,560.8l0.1-0.3l0.1-0.3v-0.3V560 M773.2,558.9l0.1,0.2
		l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2 M773.2,558.9l-0.1-0.3l-0.2-0.2l-0.2-0.1h-0.1 M772.6,558.4l-3.7,0.1
		l-2.2,0.4l-0.8,0.1l-0.8-0.2l-0.4-0.3l-0.3-0.3l-0.7-1.4l-0.4-1.1l-0.1-0.6l0.1-0.5l0.3-0.7l0.9-0.9l4-4.4 M768.5,548.5l0.1-0.3
		V548l-0.1-0.3v-0.1 M768,546.6l0.1,0.2l0.1,0.2l0.1,0.1l0.1,0.2l0.1,0.1l0.1,0.2l0.1,0.1l0.1,0.2 M768,546.6l-0.2-0.3l-0.2-0.2
		l-0.3-0.1h-0.1 M767.4,546.1l-3.9,0.1l-2.2,0.4l-0.9,0.1l-0.8-0.1l-0.5-0.3l-0.3-0.3l-0.8-1.3l-0.5-1l-0.2-0.5v-0.5l0.3-0.7
		l0.9-0.9l3.8-4.3 M762.3,536.8l0.1-0.3v-0.3l-0.1-0.3l-0.1-0.1 M761.7,535L761.7,535l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1
		l0.1,0.1l0.1,0.1 M761.7,535l-0.2-0.2l-0.3-0.2l-0.3-0.1h-0.1 M760.9,534.5l-4.1,0.2l-2.3,0.5l-0.8,0.1l-0.9-0.1l-0.5-0.3l-0.4-0.3
		l-0.8-1.2l-0.6-1l-0.2-0.5v-0.5l0.3-0.7l0.8-0.9l3.7-4.2 M755,525.8l0.1-0.3v-0.3L755,525L755,525 M754.3,524.1L754.3,524.1
		l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M754.3,524.1l-0.2-0.2l-0.3-0.2l-0.3-0.1h-0.2 M753.5,523.7l-4.2,0.3
		l-2.3,0.5l-0.8,0.2l-0.9-0.1l-0.6-0.2l-0.4-0.3l-1-1l-0.7-0.8l-0.2-0.4v-0.4l0.3-0.6l0.9-0.9l3.6-4 M746.9,515.9l0.1-0.2v-0.3
		l-0.1-0.3l-0.1-0.1 M746,514.4L746,514.4l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M746,514.4l-0.2-0.2l-0.3-0.1
		l-0.3-0.1h-0.2 M745.2,514.1l-4.2,0.5l-2.4,0.6l-0.8,0.2h-0.9l-0.6-0.2l-0.4-0.3l-1-0.9l-0.8-0.8l-0.3-0.4l-0.1-0.4l0.2-0.6
		l0.9-0.9l3.5-3.7 M738.1,507.4l0.1-0.2V507l-0.2-0.2l-0.1-0.1 M737.2,506.1L737.2,506.1l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1
		l0.1,0.1l0.1,0.1 M737.2,506.1l-0.3-0.2l-0.3-0.1h-0.3h-0.2 M736.3,505.8l-4.3,0.7l-2.4,0.7l-0.9,0.2l-0.9,0.1l-0.6-0.1l-0.5-0.2
		l-1.1-0.8l-0.8-0.6l-0.3-0.3l-0.1-0.3l0.2-0.5l0.8-0.8l3.4-3.5 M728.9,500.3l0.1-0.2V500l-0.2-0.2l-0.1-0.1 M727.9,499.3
		L727.9,499.3l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M727.9,499.3l-0.3-0.1l-0.3-0.1h-0.3H727 M727.1,499.1
		l-4.3,0.9l-2.4,0.8l-0.8,0.3l-1,0.1h-0.6l-0.5-0.1l-1.1-0.5l-0.8-0.4l-0.3-0.3l-0.1-0.3l0.2-0.4l0.8-0.7l3.4-3.2 M719.5,495.1
		l0.1-0.2v-0.2l-0.2-0.1h-0.1 M718.5,494.4L718.5,494.4l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1 M718.5,494.4
		l-0.3-0.1H718h-0.3h-0.2 M717.6,494.3l-4.3,1.1l-2.4,0.8l-0.9,0.3l-0.9,0.2h-0.6H708l-1.1-0.3l-0.8-0.3l-0.3-0.2l-0.1-0.3l0.2-0.3
		l0.8-0.7l3.4-2.9 M710.2,491.7L710.2,491.7l0.1-0.2l-0.2-0.1H710 M709.2,491.2L709.2,491.2h0.2h0.1h0.1h0.1h0.1h0.1h0.1
		 M709.2,491.2H709h-0.3l-0.3,0.1h-0.2 M708.3,491.3l-2.2,0.7l-2.1,0.7l-1.2,0.4l-1.2,0.4l-0.4,0.2l-0.4,0.2l-0.5,0.2l-0.5,0.1
		l-0.3,0.1l-0.2,0.1h-0.3h-0.3l-0.6-0.1l-0.5-0.1l-0.4-0.1l-0.3-0.1l-0.2-0.1l-0.1-0.1l-0.1-0.1v-0.1l0.1-0.2l0.2-0.2l0.3-0.3
		l0.4-0.3l2-1.3l1.5-1.3 M701.1,490.4L701.1,490.4l0.1-0.2h-0.1H701 M700.2,490.1L700.2,490.1h0.2h0.1h0.1h0.1h0.1h0.1h0.1
		 M700.2,490.1H700l-0.3,0.1l-0.3,0.1l-0.2,0.1 M699.4,490.3l-2.1,0.8l-2.1,0.9l-1.2,0.5l-1.2,0.5l-0.4,0.2l-0.4,0.2l-0.4,0.2
		l-0.5,0.2l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.3,0.1l-0.5,0.1l-0.5,0.1h-0.3h-0.3h-0.2H688l0,0V494l0.1-0.1l0.2-0.1l0.3-0.3l0.4-0.3
		l2-1.2l1.6-1.1 M692.6,491L692.6,491L692.6,491L692.6,491L692.6,491 M691.7,491L691.7,491h0.2h0.1h0.1h0.1h0.1h0.1h0.1 M691.7,491
		l-0.2,0.1l-0.3,0.1l-0.3,0.1l-0.2,0.1 M691,491.3l-4.2,1.9l-2.3,1.1l-0.8,0.4l-0.9,0.4l-0.6,0.3l-0.4,0.2l-0.9,0.3l-0.6,0.3
		l-0.2,0.1l0,0l0.3-0.2l0.9-0.4l3.7-1.9 M684.7,493.6L684.7,493.6L684.7,493.6L684.7,493.6L684.7,493.6 M655.1,542.4l0.5-9l1.5-8.7
		l2.5-7.8l3.5-6.8l4.4-5.7l5.2-4.5l6-3.2l6.5-1.9l7.1-0.5l7.5,0.9l7.7,2.2l7.8,3.6l7.8,4.8l7.6,6l7.3,7.1l6.9,8l6.3,8.8l5.6,9.4
		l4.8,9.9l4,10.2l3.1,10.4l2.1,10.3l1.1,10v9.6l-1,9l-2,8.2l-3.1,7.3l-4,6.3l-4.8,5.1l-5.6,3.9l-6.3,2.5l-6.8,1.2l-7.3-0.2l-7.6-1.5
		 M723.6,647.3l-7.7-2.9l-7.8-4.2l-6.1-4.2 M679.9,613.8l4.5,5.7l7.1,7.7l7.5,6.6l7.7,5.5l7.8,4.2l7.8,2.9 M669.2,596.7l2.7,5
		l0.1,0.2 M722.4,646.5l7.7,1.5l7.3,0.1l6.8-1.3l6.3-2.7l5.5-4l4.8-5.3l3.8-6.5l2.9-7.5l1.9-8.4l0.8-9.2l-0.3-9.8l-1.4-10.1
		l-2.4-10.4l-3.4-10.4l-4.3-10.2l-5.2-9.9l-6-9.3l-6.5-8.6l-7.1-7.7l-7.5-6.6l-7.7-5.5l-7.8-4.2l-7.8-2.9l-7.7-1.5l-7.3-0.1
		l-6.8,1.3l-6.3,2.7l-5.5,4l-4.8,5.3l-3.8,6.5l-2.9,7.5l-1.9,8.4l-0.8,9.2l0.3,9.8l1.4,10.1l2.4,10.4l0.6,2 M804,551l0.4-1.4l0.7-5
		l-0.4-5.7l-1.5-5.9l-2.5-5.8l-3.3-5.3l-4-4.5L789,514l-4.5-2l-0.3-0.1 M711.8,584.4l-0.3-2.1l-1.3-4.9l-2.2-4.8l-3-4.2l-2.7-2.7
		 M688,558.2l-2.3,1.8l-2,3.1 M702.3,600.8l1,0.2l3.8-0.1l1.8-0.6 M719.8,596.8l1.4-0.8l2.7-3l1.8-4.2l0.7-5l-0.4-5.7l-1.5-5.9
		l-2.5-5.8l-3.3-5.3l-4-4.5l-4.3-3.4l-4.5-2l-4.2-0.6l-3.8,0.9l-0.3,0.2 M701.7,601.1l1,0.2l3.8-0.1l3.3-1.4l2.5-2.7l1.7-3.8
		l0.7-4.7l-0.4-5.3l-1.4-5.5l-2.4-5.4l-3.1-4.9l-3.7-4.2l-4.1-3.1l-4.2-2l-4-0.6l-3.6,0.8l-3,2.1l-2,3.1 M700,602.1l3.2,0.8l4.1-0.1
		l3.6-1.6l2.7-3l1.8-4.2l0.7-5l-0.4-5.7l-1.5-5.9l-2.5-5.8l-3.3-5.3l-4-4.5l-4.3-3.4l-4.5-2l-4.3-0.6l-3.8,0.8l-3.1,2.3l-2.3,3.6
		l-0.7,1.9 M744.8,602.5l-1.8,0.9 M746.6,602.3l-1.8,0.8 M746.5,605.5l-0.8,0.4 M745.3,601.4l-0.9,0.4 M744.6,601.7l0.3,0.8
		 M744.8,602.5l1.8-0.2 M746.6,602.3l0.1,0.3 M747.4,602.3l-1.6,0.8 M742.5,595.8l1-0.3 M750.7,605.2l-0.3,1.4l-1,1.6l-0.6,0.4
		 M743.5,595.5l1.9,0.3l0.9,0.5 M747.1,601.5l-1.8,0.2 M747.6,602.9l-0.4-1.4 M745.8,603.1l1.8-0.2 M746.7,605.8l-0.9-2.6
		 M745.8,605.9l0.9-0.1 M744.8,603.2l0.8,2.6 M743.1,603.4l1.8-0.2 M742.6,602l0.4,1.4 M744.4,601.8l-1.8,0.2 M743.6,599.2l0.8,2.6
		 M744.4,599.1l-0.9,0.1 M745.3,601.7l-0.8-2.6 M742.9,595.8l1.9,0.3l2,1.3l1.7,2l1.2,2.6l0.5,2.5l-0.3,2.3l-1,1.6l-1.5,0.7
		l-1.9-0.3l-2-1.3l-1.7-2l-1.2-2.5l-0.5-2.5l0.3-2.3l1-1.6L742.9,595.8 M706.8,527.3l-1.8,0.8 M708.3,526.5l-1.8,0.9 M708,528.3
		l-0.8,0.4 M706,524.5l-0.8,0.4 M708.3,526.5L708.3,526.5 M708.3,526.5l-1.5,0.8 M706.8,527.3l0.7,1.3 M708.8,526.2l-1.5,0.8
		 M712.9,530.6L712.9,530.6l-0.6,2.1L711,534l0,0 M704.6,521.1l1.7-0.3l2,0.8l0.2,0.1 M705.2,524.9l1.3,2.5 M706,524.5l-0.8,0.4
		 M707.3,527l-1.3-2.5 M708.8,526.2l-1.5,0.8 M709.5,527.5l-0.7-1.3 M708,528.3l1.5-0.8 M709.2,530.8l-1.3-2.5 M708.5,531.2l0.8-0.4
		 M707.2,528.7l1.3,2.5 M705.6,529.5l1.5-0.8 M705,528.2l0.7,1.3 M706.5,527.4l-1.5,0.8 M703.4,529.9l-0.9-2.5l-0.2-2.5l0.6-2
		l1.3-1.3l1.7-0.3l2,0.8l1.9,1.6l1.5,2.3l0.9,2.5l0.2,2.5l-0.6,2l-1.3,1.3l-1.7,0.3l-2-0.8l-1.9-1.6L703.4,529.9 M682.2,878
		l-0.1,0.3l0.6,2.4l1.4,1.9l2.3,1.5l3.3,1.1l3.8,0.4l3.8-0.3l3.5-1.2l2.6-1.8l1.4-2.2l0.3-2 M683.6,878.5l0.6,0.4 M682.2,875.6v2.6
		 M685.8,862.8l-0.5,0.3 M703.3,863.6l-1.6-0.8 M852.2,250.1l1-0.7 M843.2,249.4l1,0.7 M854.5,247.5v-28.1 M841.9,219.4v28.1
		 M853.4,220.2l5.2-3.7 M837.9,216.6l5.2,3.7 M835.6,212.2v0.8 M860.9,212.9v-0.8 M798.7,276.8l1-0.7 M789.7,276.1l1,0.7 M801,274.2
		v-28.1 M788.4,246.2v28.1 M799.9,246.9l5.2-3.7 M784.5,243.3l5.2,3.7 M782.2,238.9v0.8 M807.3,239.7v-0.8 M674.8,684.8v5.2
		 M745.6,676.3v5.4 M766.6,681.8v-5.4 M827.1,798.8v-6.5 M658.4,764.9v40 M805,706.1v5.4 M826,711.5v-5.4 M837.3,695.1l-10.4,9.9
		 M636.6,702.6l-0.1,2l0.6,9.5l1.4,9.5l2,9.3l2.5,9l2.2,6.4l2.5,6.4l4.1,8.9l5.6,10.4l0.9,1.4 M821.1,725v-0.9 M656.3,724.9v36.4
		 M820.6,726.4l-1.1,8.8l-3.2,7.1 M659.4,719.4v5.4 M680.5,724.9v-5.4 M843.7,685.9v5.4 M864.7,691.3v-5.4 M816.2,742.2l-7.9,8.8
		l-9.6,7.7l-2,1.3 M871,735v-43.7 M837.3,691.3v29.1 M770.3,817l3.1-1.5l5.1-3.7 M865.3,749.3V742 M734.8,797.2L734.8,797.2
		l-2.3,0.4l-3.2,0.2 M729.4,797.8l-4-0.3l-0.5-0.1 M725,797.5l-0.9-0.2l-2.1-0.6l-1.7-0.7 M837.7,738.7v5.4 M858.7,744.2v-5.4
		 M756.7,824l13.7-6.9 M703.5,774.9l1.4-0.7 M669.8,754.7l3.4,5.8l1.5,2.1 M674.6,762.6L674.6,762.6l2.8,3.4l3,3l3.3,2.7l3.6,2.2
		l3.7,1.6l3.7,0.8l3.8,0.1l1.4-0.3 M664.7,734.3l0.1,3.2l0.7,4.8l0.8,3.6l1.2,3.7l2.2,5.1 M824.1,743.7l-0.3,3.4l-0.5,2.9l-0.8,2.9
		l-1.9,4.4l-2.7,4.6l-1.3,1.7l-2.2,2.5l-2.3,2 M718.8,749.2v5.4 M702.2,759.8v7.3 M840.5,735.2v-0.7 M804.2,803.9l3.4-0.9l5.9-2.6
		l5.7-3.5l2.1-1.6 M814.3,786.8l2.5,1.3 M748,827.1l4.8-1.4l3.7-1.9 M730.4,745.9l-16.3,15.6 M784.2,765.5v5.4 M805.3,771v-5.4
		 M730.4,784.7v3.8 M736.7,739.3v5.4 M757.7,744.9v-5.4 M764,746.4v-1.6 M730.4,744.9v29.1 M733.4,261.5l1-0.7 M724.4,260.9l1,0.7
		 M735.7,259v-30.9 M723.1,228.1V259 M718.4,218.2v5.4 M740.3,223.7v-5.4 M740.2,218.2l-0.3-2l-1.4-1.7l-2.3-1.4l-3.3-1l-3.7-0.3
		l-3.7,0.4l-3.3,1.1l-2.5,2l-1,2v1.1 M760.1,188.8l1-0.7 M751,188.1l1,0.7 M762.4,186.2v-30.9 M749.8,155.4v30.9 M745.2,145.4v5.4
		 M767,150.9v-5.4 M766.9,145.4v-1l-0.9-1.9l-1.9-1.6l-2.9-1.3l-3.7-0.6l-3.7,0.1l-3.5,0.9l-2.9,1.5l-1.6,2l-0.4,1.9 M819.6,218.4
		l1-0.7 M810.5,217.8l1,0.7 M821.8,215.9V185 M809.3,185v30.9 M804.6,175.2v5.4 M826.4,180.6v-5.4 M826.4,175.1l-0.4-2l-1.4-1.7
		l-2.4-1.4l-3.3-1l-3.7-0.3l-3.7,0.4l-3.2,1.2L806,172l-1.1,2v1.3 M674,231.9l1-0.7 M664.9,231.2l1,0.7 M676.2,229.2v-30.8
		 M663.7,198.5v30.9 M659,188.5v5.4 M680.8,194v-5.4 M680.8,188.5v-0.8l-0.9-2l-1.8-1.6l-2.8-1.3l-3.6-0.7h-3.7l-3.6,0.8l-3,1.5
		l-1.7,1.9l-0.6,2.1l0.1,0.3 M697.8,199.1l1-0.7 M688.7,198.3l1,0.7 M700,196.4v-38.1 M687.4,158.4v38.1 M682.8,148.4v5.4
		 M704.6,153.9v-5.4 M704.5,148.4l-0.3-2l-1.4-1.7l-2.3-1.4l-3.3-1l-3.7-0.3l-3.7,0.4l-3.3,1.1l-2.5,2l-1,2v1.1 M827,396.3l-4.4-2.2
		 M770.7,325.5v0.3 M832.7,404.5v-0.8 M832.7,403.7l0.1-0.3l-0.8-2.9l-1.8-2.2l-2.7-1.9l-0.4-0.2 M659.9,408.9L659.9,408.9
		L659.9,408.9l-0.3,0.2 M659.6,409l-1.2,0.6 M770.7,320.5v5.4 M802,337.5l-0.2-9 M807.2,369.2l-6-37 M776.6,314.9v5.4 M798,320.5
		v-5.5 M652.7,417.1v12.4 M658.4,409.6l-0.2,0.1l-2.2,1.4l-1.8,1.7l-1.2,2.1l-0.3,2.2 M804.3,363.3l-0.1-0.2l-0.1-0.2 M698,327.6
		l-0.5,8 M723.8,327.8v-5.4 M702.3,322.4v5.4 M667.5,427.1l0.3-0.9l0.3-0.7l0.3-0.4l0.2-0.1 M869.9,432.8L849,422.4 M675.6,417.8
		l-0.5,1.4l-0.3,0.4l0,0 M825.7,402.7l-0.2-0.7l-1.7-5.1l-2.2-5.6l-3-6.5 M804.1,363l-0.7-0.9l-4.8-5.1l-3.6-3.2 M674.7,419.6
		l-1.4,0.7 M680.4,359.9v31.9 M682.5,416.3l-0.2,0.2 M680.2,341.3l-13.7,6.9 M797.5,404.1l3.1,0.3 M682.8,415.4l-0.1,0.4l-0.3,0.5
		 M739.8,444.8v0.4l-0.1,0.3 M683.6,374.4l-7.9,43.4 M708.9,364.2v-4.7 M687.5,359.5v5.4 M820.2,402.5l-22.7,1.6 M739.8,400.2v44.6
		 M683.6,372l-0.9,43 M843.5,440.3l-16.3-8.2 M834.9,405.5l-1.1-0.7l-5.5-1.9l-5.6-0.6l-5.9,0.5l-1,0.3 M834.9,405.5l-0.7-0.3
		 M849.1,423l-0.6-1.9l-2.4-4.9l-3.1-4.4l-3.9-3.7l-3.7-2.3 M789.3,411.4l-0.3-0.8l-4.1-8.9 M784.9,401.7l-5.6-10.3l-2.5-4.1
		l-4.4-6.5l-4.8-6.4l-5.4-6.4l-6-6.1l-6.4-5.7l-6.7-5.2l-7.1-4.6l-7.3-3.8l-0.5-0.2 M724.7,341.1l-3.4-1.4l-7.6-2.1l-7.6-1.1
		l-7.6-0.1l-2.7,0.3 M728.2,342.5l-1.4-0.6 M695.8,336.9l-4.7,0.6l-7.1,2.1l-3.6,1.8 M666.7,348.2l-3.3,1.6l-6.5,4.7l-6,6l-4.5,6.4
		l-3.7,7.2l-2.9,8l-2,8.6l-1.1,9l-0.2,7.5 M836.6,631.4L836.6,631.4 M858.2,587.8L858.2,587.8 M858.1,587.8L858.1,587.8L858.1,587.8
		 M836.2,630.4l0.3,0.9 M858.1,587.8l-0.9,0.3 M836.2,629.7L836.2,629.7l-0.2,0.4l0.1,0.3 M857.1,588.1l-0.2,0.1l-0.3,0.3l-0.1,0.2
		 M835,622v0.3l0.1,0.3 M850.2,592h-0.2l-0.3,0.2l0,0 M850.3,592h-0.2 M835,620.9l-0.1,1.2 M835.3,620.4L835.3,620.4l-0.2,0.3v0.2
		 M849.8,592.2l-0.9,0.8 M848.9,593L848.9,593l-0.3,0.3v0.1 M846.3,646.3l3.7,6 M882.8,585.7l-7.4,0.7 M836.9,611.5l-0.1,0.2v0.3
		l0.1,0.3 M843.1,599.8h-0.2l-0.3,0.2l-0.2,0.3 M841.8,601.2l-0.1,0.2v0.3v0.1 M837.7,609.9l-0.2,0.1l-0.2,0.3l-0.1,0.2
		 M837.3,610.3l-0.4,1.3 M842.5,600.2l-0.8,1.1 M873.8,586.1l-15.5,1.7 M836.6,631.5l2,3.5l5.9,9.9 M835.2,622.7l2,2.6l5.6,7.7
		 M863.2,591.9l-12.8,0.1 M838.6,614l5.2,4.3l4.1,3.4 M857.1,602.9l-5-1.3l-6.6-1.6 M855.6,655.3l1.8,0.9 M890.8,589.3l-1.8-0.8
		 M900.5,610.5v-0.9 M879.3,651.9l0.7-0.5 M885.8,642.6v0.9 M897.7,619.7l-0.7,0.5 M891.3,654.8l42.2,21.1 M952.2,638.3l-42.2-21.1
		 M879.2,648.9l8.9,4.4 M906.9,615.7l-8.9-4.4 M867.1,642.8l8.9,4.4 M894.8,609.6l-8.9-4.4 M752.8,493.8l-2.4-0.2l-6.4,0.5l-5.9,1.9
		l-1.1,0.7 M788.5,597.5l1.6-1 M790.1,596.5l2.5-1.6l4.2-4.2l3.1-4.5 M742.5,499.3L742.5,499.3h-0.2l0,0 M789.1,591.4L789.1,591.4
		L789.1,591.4l0.1-0.2 M761.2,497.6l-8,4 M797.7,536.2l-5.8-11.3 M793.6,581.2l8-4 M753.2,501.6L753.2,501.6l-0.3,0.1l-0.4,0.1
		l-0.5,0.1h-0.6l-0.5-0.1l-0.3-0.1 M792.1,583.3L792.1,583.3l0.1-0.3l0.3-0.4l0.3-0.5l0.3-0.3l0.3-0.3l0.2-0.1 M804.3,574.2l0.5-2.6
		l0.5-7.6l-0.5-8l-0.8-4.7 M783.3,510.9l-3.6-3.7l-6.2-5.1l-6.5-4l-1.7-0.9 M770.8,516.4l-0.8-0.8l-0.9-1.3l-0.1-0.2 M792.8,560.8
		l-0.3-0.5l-0.4-1.4l-0.2-0.8 M737.3,496.4l-8.2,4.1 M777.2,603.3l1.8-0.9 M804.3,549.8l3.5-1.8 M789,510.5l-3.5,1.8 M666.1,573.8
		l-2.5-0.4 M666,574.1l-2.3-0.8h-4.1l-2,0.8 M656.9,574.3l-1.9,0.9 M687.7,561.2l-24.3,12.2 M682,611l24.5-12.2 M682.4,610.6
		l1.1-2.2 M676.8,615.7l1.4-0.6l2.7-2.5l1.9-3.4l1-4.2v-0.5 M675.6,616.5l1.9-0.9 M683,545.8L683,545.8 M716.5,612L716.5,612
		L716.5,612 M681.3,538.4L681.3,538.4L681.3,538.4l0.3,0.3l0.1,0.1 M684.1,542.3l-1.3,3.5 M721.5,617.1v0.3v0.2l0,0 M716.5,612
		l3.6,1 M681.8,538.7l1.7,1.6 M721.3,614.8l0.3,2.3 M683.5,540.3l0.3,0.3l0.3,0.4l0.2,0.4l0.1,0.4v0.3v0.1 M720.1,613l0.2,0.1
		l0.3,0.2l0.3,0.3l0.3,0.4l0.2,0.4l0.1,0.3 M763.3,638.9l-0.3-1.4 M693.8,492.5l-1.2-1.6 M769.3,633.2l-0.9-2.6v-0.1 M702.8,493.1
		l-1.7-2.9 M712.2,495.8l-2-4.2 M774.2,625.7l-1.1-2.4l-0.7-1.4 M719.8,597h1.1l1-0.4 M721.5,500.4l-2-5.5 M777.6,616.8l-1.3-2.1
		l-1.6-2.6 M779.7,606.5l-1.4-1.7l-2.7-3.5 M730.5,506.9l-1.5-6.8 M741.1,560.2l0.2,0.4l0.1,0.4v0.3v0.2l0,0 M738,554.7L738,554.7
		l0.3,0.1l0.3,0.3l0.3,0.3l0.2,0.3 M738.8,555.7l2.3,4.5 M780.3,595.3l-1.4-1.4l-4.1-4 M738.8,515.1l-0.6-8 M779.3,583.3l-1.4-0.9
		l-5.8-4 M745.9,524.7l0.1-0.9l1-8.2 M776.6,570.9l-1.3-0.5l-7.3-2.8 M751.8,533.6l0.7-1.6l2.5-6.3 M770.1,558.3l-3.7-0.1h-1.6h-0.3
		 M750.6,645.7l0.9-0.4 M684.4,493.8l-7.8,3.9 M701.1,635.5l0.8,0.6 M655.1,542.4v1 M697.3,551.7l-10.4,5.2 M709.5,602l10.4-5.2
		 M748.3,608.9l0.6-0.3 M742.5,595.8l-0.6,0.3 M710.4,534.2l0.6-0.3 M704.6,521.1l-0.6,0.3"/>
	<polyline class="st1" points="843.1,220.2 843.8,220.6 845.8,221.3 848.2,221.5 850.7,221.3 852.7,220.6 853.4,220.2 	"/>
	<polyline class="st1" points="789.5,246.9 790.3,247.4 792.4,248 794.7,248.3 797.1,248 799.2,247.4 799.9,246.9 	"/>
	<line class="st1" x1="693.8" y1="885.6" x2="693.8" y2="889.5"/>
	<line class="st2" x1="794.7" y1="768.2" x2="794.7" y2="484.4"/>
	<line class="st1" x1="794.7" y1="467" x2="794.7" y2="277.9"/>
	<line class="st1" x1="729.4" y1="262.6" x2="729.4" y2="447.9"/>
	<line class="st3" x1="729.4" y1="467.8" x2="729.4" y2="746.9"/>
	<line class="st4" x1="848.2" y1="741.5" x2="848.2" y2="649.4"/>
	<line class="st1" x1="848.2" y1="600.5" x2="848.2" y2="457.6"/>
	<line class="st1" x1="848.2" y1="440.3" x2="848.2" y2="251.1"/>
	<line class="st5" x1="815.6" y1="219.5" x2="815.6" y2="379.7"/>
	<line class="st6" x1="815.6" y1="474" x2="815.6" y2="708.8"/>
	<line class="st7" x1="670" y1="722.2" x2="670" y2="617.4"/>
	<line class="st1" x1="670" y1="603.4" x2="670" y2="596.4"/>
	<line class="st6" x1="670" y1="570" x2="670" y2="441.9"/>
	<line class="st1" x1="670" y1="418.1" x2="670" y2="232.9"/>
	<line class="st1" x1="756.1" y1="189.8" x2="756.1" y2="331.4"/>
	<line class="st1" x1="756.1" y1="469.5" x2="756.1" y2="495.2"/>
	<line class="st8" x1="756.1" y1="642.9" x2="756.1" y2="679.1"/>
	<line class="st8" x1="693.8" y1="870" x2="693.8" y2="832.3"/>
	<line class="st9" x1="693.8" y1="688.1" x2="693.8" y2="629.3"/>
	<line class="st9" x1="693.8" y1="492.4" x2="693.8" y2="435.9"/>
	<line class="st1" x1="693.8" y1="337.1" x2="693.8" y2="200"/>
</g>
<g id="Nr">
	<line class="st10" x1="839.4" y1="521.7" x2="947.8" y2="484.5"/>
	<g id="CT012037" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-front-differential-set", "_blank")}>
		<g>
			<g>
				<path class="st11" d="M993.3,484.9h-73.1c-5.6,0-10.2-4.6-10.2-10.2l0,0c0-5.6,4.6-10.2,10.2-10.2h73.1
					c5.6,0,10.2,4.6,10.2,10.2l0,0C1003.5,480.3,998.9,484.9,993.3,484.9z"/>
			</g>
			<polyline class="st12" points="994,477.5 996.5,474.7 993.7,471.9 			"/>
		</g>
		<g>
			<path class="st13" d="M923.1,471.1c-0.7,0-1.2,0.3-1.6,0.8c-0.4,0.5-0.6,1.2-0.6,2.1c0,1.9,0.7,2.8,2.1,2.8
				c0.6,0,1.4-0.2,2.1-0.4v1.5c-0.7,0.3-1.4,0.4-2.3,0.4c-1.3,0-2.2-0.3-2.8-1.1c-0.7-0.8-1-1.8-1-3.2c0-0.8,0.2-1.6,0.5-2.3
				c0.3-0.7,0.8-1.2,1.4-1.5s1.3-0.5,2.1-0.5c0.8,0,1.6,0.2,2.5,0.6l-0.6,1.4c-0.3-0.2-0.7-0.3-0.9-0.4
				C923.7,471.1,923.3,471.1,923.1,471.1z"/>
			<path class="st13" d="M930.3,478.1h-1.8v-7h-2.3v-1.5h6.4v1.5h-2.3V478.1z"/>
			<path class="st13" d="M939.2,473.9c0,1.4-0.3,2.5-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1
				c-0.5-0.8-0.8-1.8-0.8-3.2c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1
				C939,471.4,939.2,472.5,939.2,473.9z M935.1,473.9c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7s0.7-0.3,0.9-0.7
				c0.3-0.4,0.3-1.2,0.3-2.2s-0.1-1.8-0.3-2.2c-0.2-0.4-0.5-0.7-0.9-0.7s-0.8,0.3-0.9,0.7C935.2,472.1,935.1,472.9,935.1,473.9z"/>
			<path class="st13" d="M944.6,478.1h-1.8v-4.9v-0.9v-0.9c-0.3,0.3-0.5,0.5-0.6,0.6l-0.9,0.8l-0.8-1.1l2.7-2.2h1.4V478.1
				L944.6,478.1z"/>
			<path class="st13" d="M952.8,478.1h-6v-1.3l2.1-2.1c0.6-0.7,1-1.1,1.3-1.4c0.2-0.3,0.3-0.5,0.4-0.7c0.1-0.3,0.2-0.4,0.2-0.7
				c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.8-0.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.6,0.3-0.9,0.7l-0.9-1.2
				c0.4-0.3,0.8-0.6,1-0.8c0.3-0.2,0.6-0.3,0.9-0.3c0.3-0.1,0.7-0.1,1.1-0.1c0.5,0,1,0.1,1.4,0.3s0.7,0.4,0.9,0.9
				c0.3,0.3,0.3,0.8,0.3,1.2c0,0.4-0.1,0.8-0.2,1.1s-0.3,0.7-0.7,1c-0.3,0.3-0.8,0.9-1.5,1.5l-1.1,1v0.1h3.7v1.5H952.8z"/>
			<path class="st13" d="M959.6,473.9c0,1.4-0.3,2.5-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1
				c-0.5-0.8-0.8-1.8-0.8-3.2c0-1.5,0.3-2.6,0.8-3.3s1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1C959.4,471.4,959.6,472.5,959.6,473.9z
				 M955.5,473.9c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7s0.7-0.3,0.9-0.7c0.3-0.4,0.3-1.2,0.3-2.2s-0.1-1.8-0.3-2.2
				c-0.2-0.4-0.5-0.7-0.9-0.7s-0.8,0.3-0.9,0.7C955.6,472.1,955.5,472.9,955.5,473.9z"/>
			<path class="st13" d="M966.1,471.6c0,0.5-0.2,1-0.5,1.4c-0.3,0.3-0.8,0.6-1.4,0.8l0,0c0.7,0.1,1.2,0.3,1.5,0.6
				c0.3,0.3,0.5,0.8,0.5,1.4c0,0.9-0.3,1.4-0.9,1.9c-0.6,0.4-1.4,0.7-2.5,0.7c-0.9,0-1.7-0.2-2.5-0.4v-1.5c0.3,0.2,0.7,0.3,1.1,0.4
				c0.4,0.1,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.2-0.7-0.5-0.8s-0.8-0.3-1.5-0.3h-0.7v-1.4h0.7
				c0.7,0,1.1-0.1,1.4-0.3s0.4-0.5,0.4-0.9c0-0.7-0.4-0.9-1.2-0.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.6,0.3-0.9,0.5l-0.9-1.3
				c0.8-0.6,1.7-0.8,2.8-0.8c0.9,0,1.6,0.2,2.1,0.5C965.8,470.5,966.1,470.9,966.1,471.6z"/>
			<path class="st13" d="M968.1,478.1l3.2-7h-4.2v-1.5h6.1v1.1l-3.2,7.4H968.1z"/>
		</g>
	</g>
	<g id="front_differential">
		<path class="st13" d="M896.6,456.8h-1.8v-8.5h4.8v1.4h-3.1v2.2h2.9v1.4h-2.9L896.6,456.8L896.6,456.8z"/>
		<path class="st13" d="M903.2,453.6v3.2h-1.8v-8.5h2.5c1.2,0,2,0.2,2.5,0.6c0.6,0.4,0.8,1,0.8,2c0,0.5-0.2,0.9-0.4,1.4
			c-0.3,0.4-0.7,0.7-1.2,0.9c1.3,1.9,2.1,3.1,2.5,3.7h-2l-2-3.2L903.2,453.6L903.2,453.6z M903.2,452.1h0.6c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.2-0.7-0.4-0.8c-0.3-0.2-0.7-0.3-1.3-0.3h-0.6V452.1z"/>
		<path class="st13" d="M917,452.5c0,1.4-0.3,2.5-1,3.2s-1.7,1.1-3,1.1c-1.3,0-2.3-0.3-3-1.1s-1-1.9-1-3.2c0-1.4,0.3-2.5,1-3.2
			c0.7-0.8,1.7-1.1,3-1.1c1.3,0,2.3,0.3,3,1.1C916.6,450.1,917,451.2,917,452.5z M910.8,452.5c0,0.9,0.2,1.7,0.5,2.1
			c0.3,0.4,0.9,0.7,1.6,0.7c1.4,0,2.1-0.9,2.1-2.9s-0.7-2.9-2.1-2.9c-0.7,0-1.3,0.3-1.6,0.8C910.9,450.9,910.8,451.6,910.8,452.5z"
			/>
		<path class="st13" d="M926.2,456.8h-2.3l-3.7-6.5h-0.1c0.1,1.1,0.1,2,0.1,2.5v4h-1.6v-8.5h2.3l3.7,6.4l0,0
			c-0.1-1.1-0.1-1.9-0.1-2.4v-3.9h1.6L926.2,456.8L926.2,456.8z"/>
		<path class="st13" d="M931.7,456.8h-1.8v-7h-2.3v-1.4h6.4v1.5h-2.3V456.8z"/>
		<path class="st13" d="M945.4,452.5c0,1.4-0.4,2.5-1.2,3.2s-2,1.1-3.5,1.1h-2.4v-8.5h2.6c1.4,0,2.5,0.3,3.2,1.1
			C945,450.2,945.4,451.2,945.4,452.5z M943.6,452.5c0-1.8-0.8-2.7-2.4-2.7h-0.9v5.5h0.8C942.6,455.3,943.6,454.4,943.6,452.5z"/>
		<path class="st13" d="M947.1,456.8v-8.5h1.8v8.5H947.1z"/>
		<path class="st13" d="M952.8,456.8h-1.8v-8.5h4.8v1.4h-3.1v2.2h2.9v1.4h-2.9L952.8,456.8L952.8,456.8z"/>
		<path class="st13" d="M959.4,456.8h-1.8v-8.5h4.8v1.4h-3.1v2.2h2.9v1.4h-2.9L959.4,456.8L959.4,456.8z"/>
		<path class="st13" d="M969.1,456.8h-4.9v-8.5h4.9v1.4H966v1.9h2.9v1.4H966v2.2h3.1V456.8z"/>
		<path class="st13" d="M972.6,453.6v3.2h-1.8v-8.5h2.5c1.2,0,2,0.2,2.5,0.6c0.6,0.4,0.8,1,0.8,2c0,0.5-0.2,0.9-0.4,1.4
			c-0.3,0.4-0.7,0.7-1.2,0.9c1.3,1.9,2.1,3.1,2.5,3.7h-2l-2-3.2L972.6,453.6L972.6,453.6z M972.6,452.1h0.6c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.2-0.7-0.4-0.8c-0.3-0.2-0.7-0.3-1.3-0.3h-0.5L972.6,452.1L972.6,452.1z"/>
		<path class="st13" d="M983.6,456.8h-4.9v-8.5h4.9v1.4h-3.1v1.9h2.9v1.4h-2.9v2.2h3.1V456.8z"/>
		<path class="st13" d="M992.9,456.8h-2.3l-3.7-6.5h-0.1c0.1,1.1,0.1,2,0.1,2.5v4h-1.6v-8.5h2.3l3.7,6.4l0,0
			c-0.1-1.1-0.1-1.9-0.1-2.4v-3.9h1.6L992.9,456.8L992.9,456.8z"/>
		<path class="st13" d="M998.3,456.8h-1.8v-7h-2.3v-1.4h6.4v1.5h-2.3L998.3,456.8L998.3,456.8z"/>
		<path class="st13" d="M1001.9,456.8v-8.5h1.8v8.5H1001.9z"/>
		<path class="st13" d="M1011.1,456.8l-0.6-2h-3.1l-0.6,2h-2l3-8.5h2.2l3,8.5H1011.1z M1010,453.3c-0.6-1.9-0.9-2.9-0.9-3.1
			c-0.1-0.3-0.1-0.4-0.2-0.6c-0.2,0.5-0.5,1.7-1.1,3.7L1010,453.3L1010,453.3z"/>
		<path class="st13" d="M1014,456.8v-8.5h1.8v7h3.5v1.5H1014z"/>
	</g>
	<line class="st10" x1="935.2" y1="625.7" x2="1026.3" y2="591.9"/>
	<g id="CT012037_1_" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-front-differential-set", "_blank")}>
		<g>
			<g>
				<path class="st11" d="M1067.2,592h-73.1c-5.6,0-10.2-4.6-10.2-10.2l0,0c0-5.6,4.6-10.2,10.2-10.2h73.1c5.6,0,10.2,4.6,10.2,10.2
					l0,0C1077.4,587.4,1072.9,592,1067.2,592z"/>
			</g>
			<polyline class="st12" points="1067.9,584.6 1070.5,581.8 1067.7,579 			"/>
		</g>
		<g>
			<path class="st13" d="M997,578.2c-0.7,0-1.2,0.3-1.6,0.8c-0.3,0.5-0.6,1.2-0.6,2.1c0,1.9,0.7,2.8,2.1,2.8c0.6,0,1.4-0.2,2.1-0.4
				v1.5c-0.7,0.3-1.4,0.4-2.3,0.4c-1.3,0-2.2-0.3-2.8-1.1c-0.7-0.8-1-1.8-1-3.2c0-0.9,0.2-1.6,0.5-2.3c0.3-0.7,0.8-1.2,1.4-1.5
				c0.6-0.3,1.3-0.5,2.1-0.5c0.8,0,1.6,0.2,2.5,0.6l-0.6,1.4c-0.3-0.2-0.7-0.3-0.9-0.4C997.6,578.2,997.3,578.2,997,578.2z"/>
			<path class="st13" d="M1004.3,585.2h-1.8v-7h-2.3v-1.5h6.4v1.5h-2.3L1004.3,585.2L1004.3,585.2z"/>
			<path class="st13" d="M1013.2,581c0,1.4-0.3,2.6-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1
				c-0.5-0.8-0.8-1.8-0.8-3.2c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1s1.7,0.3,2.2,1.1C1012.9,578.5,1013.2,579.6,1013.2,581
				z M1009,581c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7s0.7-0.3,0.9-0.7c0.3-0.4,0.3-1.2,0.3-2.2s-0.1-1.8-0.3-2.2
				c-0.2-0.4-0.5-0.7-0.9-0.7s-0.8,0.3-0.9,0.7C1009.1,579.2,1009,580,1009,581z"/>
			<path class="st13" d="M1018.5,585.2h-1.8v-4.9v-0.8v-0.8c-0.3,0.3-0.5,0.5-0.6,0.6l-0.9,0.8l-0.8-1.1l2.7-2.2h1.4V585.2z"/>
			<path class="st13" d="M1026.8,585.2h-6V584l2.1-2.1c0.6-0.7,1-1.1,1.3-1.4c0.2-0.3,0.3-0.5,0.4-0.7c0.1-0.3,0.2-0.4,0.2-0.7
				c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.8-0.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.6,0.3-0.9,0.7l-0.9-1.2
				c0.4-0.3,0.8-0.6,1-0.8c0.3-0.2,0.6-0.3,0.9-0.3s0.7-0.1,1.1-0.1c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,0.9,0.8
				c0.3,0.3,0.3,0.8,0.3,1.2s-0.1,0.8-0.2,1.1c-0.2,0.3-0.3,0.7-0.7,1c-0.3,0.3-0.8,0.8-1.5,1.5l-1.1,1v0.1h3.7v1.5H1026.8z"/>
			<path class="st13" d="M1033.6,581c0,1.4-0.3,2.6-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1
				c-0.5-0.8-0.8-1.8-0.8-3.2c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1
				C1033.3,578.5,1033.6,579.6,1033.6,581z M1029.4,581c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7c0.4,0,0.7-0.3,0.9-0.7
				c0.3-0.4,0.3-1.2,0.3-2.2s-0.1-1.8-0.3-2.2s-0.5-0.7-0.9-0.7c-0.4,0-0.8,0.3-0.9,0.7C1029.5,579.2,1029.4,580,1029.4,581z"/>
			<path class="st13" d="M1040,578.7c0,0.5-0.2,1-0.5,1.4c-0.3,0.3-0.8,0.6-1.4,0.8l0,0c0.7,0.1,1.2,0.3,1.5,0.6
				c0.3,0.3,0.5,0.8,0.5,1.4c0,0.8-0.3,1.4-0.8,1.9c-0.6,0.4-1.4,0.7-2.6,0.7c-0.9,0-1.7-0.2-2.5-0.4v-1.5c0.3,0.2,0.7,0.3,1.1,0.4
				c0.4,0.1,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3c0.3-0.2,0.4-0.5,0.4-0.9s-0.2-0.7-0.5-0.8c-0.3-0.2-0.8-0.3-1.5-0.3h-0.7v-1.4h0.7
				c0.7,0,1.1-0.1,1.4-0.3c0.3-0.2,0.4-0.4,0.4-0.8c0-0.7-0.4-0.9-1.2-0.9c-0.3,0-0.6,0.1-0.8,0.2s-0.6,0.3-0.9,0.5l-0.9-1.3
				c0.8-0.6,1.7-0.9,2.8-0.9c0.9,0,1.6,0.2,2.1,0.5C1039.8,577.6,1040,578,1040,578.7z"/>
			<path class="st13" d="M1042.1,585.2l3.2-7h-4.2v-1.5h6.1v1.1l-3.2,7.4H1042.1z"/>
		</g>
	</g>
	<g id="bevel_gear">
		<path class="st13" d="M992.6,555.5h2.6c1.2,0,2,0.2,2.6,0.5c0.5,0.3,0.8,0.8,0.8,1.6c0,0.5-0.1,0.9-0.3,1.3
			c-0.3,0.3-0.6,0.5-0.9,0.6v0.1c0.5,0.1,0.9,0.3,1.2,0.7c0.3,0.3,0.3,0.8,0.3,1.3c0,0.8-0.3,1.4-0.8,1.8c-0.6,0.4-1.4,0.7-2.3,0.7
			h-3.1L992.6,555.5L992.6,555.5z M994.4,558.8h1c0.5,0,0.9-0.1,1-0.3c0.3-0.2,0.3-0.4,0.3-0.8s-0.1-0.6-0.3-0.7
			c-0.3-0.2-0.6-0.2-1.1-0.2h-0.9L994.4,558.8L994.4,558.8z M994.4,560.2v2.2h1.2c0.5,0,0.8-0.1,1.1-0.3c0.3-0.2,0.3-0.5,0.3-0.8
			c0-0.7-0.5-1-1.5-1L994.4,560.2L994.4,560.2z"/>
		<path class="st13" d="M1005.5,563.9h-4.9v-8.5h4.9v1.4h-3.1v1.9h2.9v1.4h-2.9v2.2h3.1V563.9z"/>
		<path class="st13" d="M1012.1,555.5h1.8l-2.9,8.5h-2l-2.9-8.5h1.8l1.6,5c0.1,0.3,0.2,0.7,0.3,1c0.1,0.4,0.2,0.7,0.2,0.9
			c0.1-0.3,0.2-1,0.4-1.9L1012.1,555.5z"/>
		<path class="st13" d="M1019.9,563.9h-4.9v-8.5h4.9v1.4h-3.1v1.9h2.9v1.4h-2.9v2.2h3.1V563.9z"/>
		<path class="st13" d="M1021.7,563.9v-8.5h1.8v7h3.5v1.5H1021.7z"/>
		<path class="st13" d="M1034.7,559.1h3.4v4.4c-0.5,0.2-1,0.3-1.5,0.3c-0.5,0.1-0.9,0.1-1.4,0.1c-1.3,0-2.3-0.3-3-1.1
			c-0.7-0.8-1-1.9-1-3.2s0.4-2.5,1.2-3.2s1.9-1.2,3.2-1.2c0.8,0,1.7,0.2,2.6,0.5l-0.6,1.4c-0.6-0.3-1.3-0.4-2-0.4
			c-0.8,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.7,1.2-0.7,2.1s0.2,1.6,0.6,2.1c0.3,0.5,0.9,0.8,1.6,0.8c0.3,0,0.8,0,1.1-0.1v-1.8h-1.6
			L1034.7,559.1L1034.7,559.1z"/>
		<path class="st13" d="M1045,563.9h-4.9v-8.5h4.9v1.4h-3.1v1.9h2.9v1.4h-2.9v2.2h3.1V563.9z"/>
		<path class="st13" d="M1051.9,563.9l-0.6-2h-3.1l-0.6,2h-2l3-8.5h2.2l3,8.5H1051.9z M1050.9,560.4c-0.6-1.9-0.8-2.9-0.9-3.1
			c-0.1-0.3-0.1-0.4-0.2-0.6c-0.2,0.5-0.5,1.7-1.1,3.7L1050.9,560.4L1050.9,560.4z"/>
		<path class="st13" d="M1056.8,560.7v3.2h-1.8v-8.5h2.5c1.2,0,2,0.2,2.6,0.6c0.6,0.4,0.8,1,0.8,2c0,0.5-0.2,0.9-0.4,1.4
			c-0.3,0.4-0.7,0.7-1.2,0.9c1.3,1.9,2.1,3.1,2.5,3.7h-2l-2-3.2L1056.8,560.7L1056.8,560.7z M1056.8,559.2h0.6c0.6,0,1-0.1,1.3-0.3
			c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.2-0.7-0.4-0.8c-0.3-0.2-0.7-0.3-1.3-0.3h-0.5L1056.8,559.2L1056.8,559.2z"/>
	</g>
	<polyline class="st10" points="713.7,876.9 799.8,892.1 765.6,836.1 	"/>
	<g>
		<g>
			<g>
				<g>
					<g>
						<path class="st13" d="M790.2,109.7c-0.3-0.1-0.9-0.2-1.4-0.3C790.1,109.6,790.8,109.7,790.2,109.7z"/>
						<path class="st13" d="M772.3,107.1c4.7,0.3,12.1,1.4,16.7,2.3c-2-0.3-5.9-0.9-9.3-1.3C776.1,107.6,773,107.4,772.3,107.1z"/>
						<path class="st13" d="M790.9,110.5c-1.9-0.4-3.8-0.8-5.7-1.1C788.6,109.8,787.9,110,790.9,110.5z"/>
						<polygon class="st13" points="789,109.7 789.2,109.7 792.3,110.3 						"/>
						<path class="st13" d="M811.6,301.1c0.6-0.1,1,0,0.8,0.1L811.6,301.1z"/>
						<path class="st13" d="M798,302.5c-1.2,0.1-2,0.3-2.8,0.3C796.1,302.7,796.9,302.6,798,302.5z"/>
						<path class="st13" d="M823.6,298.3c-0.8,0.3-1.5,0.4-2.3,0.7C822,298.7,822.9,298.4,823.6,298.3z"/>
						<path class="st13" d="M894.4,183.9c-0.4-2.2-1-4.2-1.4-5.9C894.4,182.2,894.3,182.7,894.4,183.9z"/>
						<path class="st13" d="M736.3,300.2l-0.6,0.1C735.1,300.2,734.7,300,736.3,300.2z"/>
						<path class="st13" d="M794.6,300.6c0.1,0.1-0.1,0.2-0.3,0.2c-0.3,0-0.5,0-0.7,0L794.6,300.6z"/>
						<path class="st13" d="M832.7,295.2C832.7,295.2,832.8,295.2,832.7,295.2l2-0.7C833.8,295,833.1,295.1,832.7,295.2z"/>
						<path class="st13" d="M717.8,297c-0.8-0.3-1.5-0.5-2.2-0.8C717.3,296.6,718.1,297,717.8,297z"/>
						<path class="st13" d="M795,302.9C794.9,302.9,795,302.9,795,302.9c0.1,0,0.3,0,0.3,0C795.2,302.9,795.1,302.9,795,302.9z"/>
						<path class="st13" d="M813.7,297.8c-1,0.2-2,0.3-3.1,0.5C812,298,813,297.8,813.7,297.8z"/>
						<path class="st13" d="M894.4,183.9c0.2,0.6,0.3,1.3,0.3,1.9C894.6,184.9,894.4,184.3,894.4,183.9z"/>
						<path class="st13" d="M805.6,112.6c1.9,0.5,2.2,0.7,2.5,0.8C807.2,113.2,806.3,112.9,805.6,112.6z"/>
						<path class="st13" d="M746.1,106.4l0.8-0.1C746.5,106.3,746.3,106.4,746.1,106.4z"/>
						<path class="st13" d="M677.3,128.8c-0.3,0.2-0.7,0.3-0.8,0.4C677,128.8,677.3,128.7,677.3,128.8z"/>
						<path class="st13" d="M636.2,178.8c1.3-4.4,3.7-8.6,5.7-12c-0.3,0.3-0.6,0.7-0.8,1.1c0.7-1.1,1.4-2.3,2.1-3.4l-0.3-0.1
							c1.8-2.8,4-5.9,6-8.3c1-1.3,2-2.4,2.9-3.2c0.4-0.4,0.8-0.9,1.2-1.2s0.7-0.6,0.9-0.8l-0.8,0.3c1.7-1.6,3.5-3.2,5.3-4.8
							c-1.1,0.8-2.1,1.7-3.1,2.6c2.8-2.9,6.7-6.1,10.1-8.6c3.4-2.5,6.4-4.3,7.1-4.8l-0.7,0.3c1-0.8,2-1.3,3.1-2l-1,0.3
							c5.2-3.1,8.6-5,12.2-7c3.6-1.9,7.4-3.7,13.8-6.1c3.4-1.4-2,0.7-2,0.6c1.8-0.9,6.4-2.5,7.3-2.7c-1.1,0-6.5,2.1-2.5,0.3
							c3-0.9-2.1,0.4,3.1-1.4l2.1-0.3c2.9-1,0.4-0.5,5-2c5.3-1.4-6.3,2.5,2.2-0.1c4.2-1.3,8.7-3.6,17.1-5.3c3.6-0.8,2.6-0.8,2-0.8
							c-0.6,0-0.9-0.1,4.2-1c-2.2,0.3-4.3,0.7-6.5,1c1.6-0.5,0.5-0.5,4.9-1.3c1.4-0.1,4.2-0.4,6.5-0.8c-4.8,0.7-2.7,0.2-0.6-0.3
							s4.4-1-0.1-0.5l3.3-0.5c-0.4,0.1-0.5,0-0.3-0.1c1.4-0.3,3-0.4,4.4-0.6c-2.8,0.2-9.3,1.3-6.3,0.4c2.1-0.3,4.2-0.7,6.5-1
							c-1.8,0,0.6-0.4,0.3-0.6c-6.5,0.6-12.7,1.6-19,2.8c0.8,0,3.5-0.5,2.5-0.2c-4.9,1.2-4.2,0.6-8.8,2l2.1-0.3c-0.4,0-1,0.2,0-0.1
							c1.6-0.5,2.4-0.5,2.4-0.4c-1.6,0.6-6.3,1.6-7.7,1.8c-6,1.6-1.1,0.8-2.4,1.2c-3.2,0.4-9.9,2.9-13.6,4c0.8-0.2,1.6-0.3,2.4-0.5
							c-4.3,1.8-8.4,3.1-11,4l1.1-0.2c-6.1,2.4-8.8,4.4-13.6,5.9l0.7-0.2c-2.4,1.2-6,3.3-8.3,4.3c0,0.1-0.3,0.4-1.3,1
							c-1.4,0.5-3.7,2.1-7,4.3c2.3-1.4,1.7-0.6,4.2-2c-1.3,0.9-2.1,1.5-2.9,2c-0.8,0.4-1.4,0.8-2,1.1c-1.1,0.6-2.2,1.1-4.2,2.6
							c-2.4,2,2.4-1.5,3.1-1.8c-3.3,2.2-6.4,4.8-9.1,7.4c-2.7,2.6-4.9,4.9-7,6.5l2.2-2.6c-1.2,1.1-2,1.9-2.5,2.5
							c-0.5,0.6-0.8,0.8-0.6,0.7c0.7-0.8,0.9-0.9,1.3-1.2c-3.1,3.6-5.5,6.4-7.9,9.4c-2.3,3-4.5,6-6.6,10.5c0.4-0.6,1.1-1.8,1.3-1.8
							c-1,2-1.6,2.9-2.4,4.2c-0.4,0.6-0.8,1.3-1.2,2.3c-0.3,0.5-0.4,1-0.7,1.7c-0.3,0.7-0.5,1.4-0.8,2.3l0.6-1.3
							c-0.3,1.2-0.8,2.1-1,2.8c-0.3,0.7-0.4,1-0.4,0.9c0.2,0.1-0.1,1-0.4,2.6c-0.3,1.4-0.9,3.5-1.3,5.6c-0.8,4.3-1.3,9.4-0.9,11.8
							c-0.2,0.9-0.3,2.1-0.3,3.7c0,0.8-0.1,1.5-0.1,2.4s0,1.6,0,2.5c0,1.7,0.2,3.3,0.3,4.8c0.1,1.4,0.3,2.6,0.4,3.4
							c0.9,4.3-0.1-3.9,0.5-2.3c0.2,1.7,0.3,3,0.4,4.5c0.1,0.8,0.2,1.5,0.3,2.5c0.2,0.9,0.3,2,0.6,3.2c-0.1,0-0.3-0.5-0.5-1.3
							c-0.3-0.8-0.5-1.7-0.7-2.4c-0.3-1.3-0.6-1.4,0.2,3.4l0.5,1.3c-0.3-0.9-0.6-2.3-0.8-3.7c-0.3-2.8,0.5,1.6,0.8,2.2
							c0.3,1.7,0.7,3.4,1.1,5.1l0.6,2.6l0.8,2.5c-0.1,0.4-0.5-0.9-1-2.3c-0.4-1.4-0.8-3.1-1-3.5c1,4,2.5,8.3,4.3,12.4
							c1.8,4.1,3.8,8,5.7,11.3l-0.3-0.7c0.6,1.1,1.1,2,1.5,2.6c0.4,0.7,0.8,1.3,1.1,1.7c0.7,0.9,1.1,1.4,1.5,1.8
							c0.4,0.4,0.8,0.9,1.3,1.5c0.5,0.7,1.2,1.7,2.3,3.2c1.4,1.5,1.2,1.2,1.2,1c0-0.2,0.2-0.1,2.2,2.2c1.2,1.3,0.3,0.6,0.2,0.4
							c1.3,1.4,2.1,2.2,2.6,2.7c0.6,0.5,0.9,0.8,1.2,1c0.6,0.5,1.1,0.9,3.6,3.4l-0.9-1.3c6,5.7,13,11.1,19.5,14.4
							c-0.3-0.4-4.2-2.6-5.5-3.7c4,2.8,9.2,5.5,14.5,7.8c5.3,2.3,10.6,4.1,14.9,5.6c-2.6-0.5,3.9,1.9-1.7,0.3
							c-1.3-0.9-4-1.4-6.9-2.6c2.2,1.3,7.2,2.9,11.8,4.1c4.7,1.2,8.9,2,9.6,2.6h1.4c1.4,0.3,4.8,1.3,2.4,0.9
							c3.3,0.6,6.5,1.1,9.9,1.5c-3.4-0.7-10.5-2.6-12.8-2.8c-2.7-0.9,4,0.7,1.7-0.3c3.2,0.9,4.2,1,5.3,1.1c1,0.1,2.1,0.2,5.5,0.7
							c-1.5,0,0.1,0.4,2.1,0.9c2,0.4,4.4,0.9,4.2,0.9c2.2,0.3,7.2,0.9,9.2,1.2c2,0.2,5.3,0.3,8.8,0.3c1.8,0,3.7,0,5.5,0
							c0.9,0,1.8,0,2.7,0c0.4,0,0.8,0,1.3,0c0.3,0,0.4,0,0.6,0c0.3,0,0.2,0,0.5,0c-0.2,0-0.3,0-0.3,0c-0.2,0-0.3,0-0.5,0
							c-0.3,0-0.7,0-1,0c-0.7,0-1.4-0.1-2-0.1c-1.2-0.1-2.1-0.3-2-0.3c1.5,0.1,3.2,0.1,4.5,0.1c0.3,0,0.7,0,0.9-0.1
							c0.2,0,0.2,0,0.3,0c0.2,0,0.3,0,0.3,0c0.4,0,0.8-0.1,0.9-0.2c-0.7-0.1-1.4-0.2-1.9-0.2c-0.6,0-1,0-1.5,0c-0.9,0-1.5,0-2,0
							c-1,0-1.4,0.2-1.8,0.3c-0.8,0.2-1.4,0.5-6.5,0.1c-2.5-0.5-5.2-0.7-10.4-1.3c2.4,0,1.4-0.2,2.3-0.3c3.1,0.7,7.2,0.9,11.6,1
							c4.3,0.1,8.9-0.2,13.1,0.3c1.7,0.2,2,0.3,2.7,0.5c0.8,0.2,2.1,0.3,6,0.3c-2.3,0.2-4.5,0.3-6.8,0.3c3.7-0.1,4.2-0.1,4.5,0
							c0.3,0.1,0.4,0.3,3.5,0.2l-2.5-0.2c3.8-0.2,5.2-0.2,7.3-0.1l-3.2-0.3c2-0.1,5.8-0.4,5.9-0.2c0.5-0.2,0.7-0.3,0.7-0.3
							c0.4,0,1.4,0,3.7-0.2c-1.4,0.1-2.4,0.2-2.5,0.1c9.1-0.7,2-0.6,7.7-1.1c-0.9,0,0.7-0.3,2.6-0.6c0,0.3,2.7,0.2,5.9-0.2
							s6.6-0.9,8.2-1l0,0c2.5-0.4,5-0.9,7.5-1.4c-1.5,0.3,0.1-0.3,2.2-0.8l-0.3,0.1c1.2-0.3,0.4-0.3,0.3-0.4
							c-0.2-0.1,0.3-0.3,3.8-1.2l3.7-0.9c-0.7,0.2-0.1-0.1,0.3-0.3c1.3-0.3,2.5-0.7,3.8-1c5.4-2.5,10.8-3.7,17-7.1
							c-0.9,0.5-2,1.1-2.9,1.4c-0.8,0.3-1.2,0.5-0.7,0.1c1.2-0.6,3.1-1.5,4.1-1.9l-0.3,0.1c4.1-2,8-4.2,11.8-6.6
							c-1,0.5-7.4,4.5-4.7,2.5c1.5-1.3,4.9-3.1,6.9-4.6c-0.2,0.3-3.7,3.1,0,0.5c0.4-0.3,3.7-3.1,2-2c0.9-0.7,2-1.4,3-2.3
							c1.1-0.8,2.1-1.8,3.1-2.7c2.1-1.9,4.1-3.7,5.5-5.3c0.8-1-0.6,0.5-1.1,0.9l-0.2,0.3c-0.9,1-1.1,1-1.5,1.3
							c-0.2,0.2-0.4,0.3-0.9,0.8c-0.4,0.4-1,1.1-2,2c-0.2,0,0.1-0.3,0.7-0.9c0.5-0.6,1.4-1.4,2.3-2.4c0.9-0.9,2-2,2.9-3.1
							s1.9-2.2,2.5-3.1c-0.8,1-1.7,1.9-2.5,2.9c-0.8,0.9-1.8,1.8-2.7,2.7c2-2.5,4.2-5.2,6.3-8.2c2-3,4.1-6.2,5.9-9.4
							c1.7-3.3,3.3-6.8,4.6-10.2c1.2-3.4,2.2-6.7,2.9-9.9c-0.6,3.7-1.7,6.6-1.2,6.4c0.6-1.9,0.9-3.3,1.3-4.4c0.3-1.1,0.4-2,0.7-2.8
							c0.2-0.8,0.4-1.7,0.6-2.8c0.1-0.6,0.2-1.2,0.3-2c0.2-0.8,0.3-1.6,0.4-2.6l-0.1,0.7c0.3-4.1,1.1-9.7,0.8-12.3
							c-0.2,0.9-0.2,2.3-0.2,3.6c-0.1,1.3-0.2,2.3-0.4,2.3c0.1-0.9,0.2-1.6,0.2-2.5l0.1-2.5c0.1-1.7,0.1-3.3,0.1-5
							c-0.2,3.1-0.3,9.9-0.8,13.8c-0.2,1.4,0,0,0.3-1.5c0.2-1.5,0.4-3.3,0.5-2.6c-0.1,1.2-0.2,2.2-0.3,3.1c-0.2,0.9-0.3,1.8-0.3,2.6
							c-0.2,1.5-0.5,2.8-0.8,3.9c-0.4,2.2-1.1,3.8-1.8,6c-1,2.6,0.6-4,0.8-5.7c1.5-5.7,2.6-14.4,1.9-23.5c-0.1-0.9-0.2-1.7-0.3-2.6
							l-0.4-2.5c0-1.1,0-1.9,0-2.5s0.1-1,0.1-1.6s0-1.3-0.1-2.3c-0.2-1-0.3-2.5-0.9-4.5c0.1,0.9,0.3,1.6,0.3,2.5
							c-0.4-1.8-0.8-2.9-0.9-3.7c0.7,3.5,1.1,7.3,1.3,10.5c-0.6-2.3-0.9-5.6-1.7-9.2c-0.3-1.8-0.8-3.7-1.2-5.4
							c-0.5-1.8-0.9-3.6-1.6-5.2c0,0.2,0.3,0.9,0.5,1.5c0.2,0.7,0.4,1.4,0.4,1.8c-1.7-4.8-3.7-9.7-6-14.1c-2.3-4.4-4.8-8.3-7.3-11.5
							c-1.1-1.3-0.2,0.1,0.9,1.6c1.1,1.5,2.2,3.2,1.6,2.7c-2.3-3.5-5.6-6.9-6.1-7.9c0.4,0.8-1.2-0.9-2.3-2.1
							c-1.2-1.1-1.7-1.8,0.8,1.3c-5.2-5.4-11.2-10.5-17.7-15c-6.5-4.5-13.4-8.4-20.2-11.6c0.6,0.4,0.6,0.5-0.1,0.3
							c2.3,1,5.2,2.5,7.7,3.8c2.5,1.4,4.7,2.8,5.9,3.7c-3-1.5-6.8-4.1-7.7-4.4c0.8,0.4,1.9,1,2.8,1.6c0.9,0.5,1.6,1,1.6,1.1
							c-4-1.9-6-3.2-8.8-4.6l1.5,0.6c-2.1-1.1-3.5-1.8-4.5-2.3c-0.9-0.5-1.6-0.9-2.2-1.2c-1.3-0.7-2.5-1.4-7.4-3.1
							c-3.6-0.9-0.1,0.3-0.1,0.6c-1.1-0.5-3.5-1.3-6-2c-2.5-0.8-4.8-1.5-6-2c2,0.6,3.9,1.2,6,1.8c-4.1-1.4-8-2.6-10.9-3.5
							c0.3,0.2,0.5,0.3,1.4,0.6c-3.3-1-7.6-2-9.3-2.6l-1,0.2c2,0.3,8,1.7,8.9,2.2c-0.7,0.2-6.6-1.5-9.3-2.1
							c5.9,1.4,8.7,2.4,11.4,3.2c2.6,0.9,5.1,1.7,9.9,3.1l-1.6-0.7c3.1,0.8,6,2,8.9,3.1c2.9,1.2,5.6,2.5,8.1,3.6
							c-1.4-0.5-1.8-0.5-1.2-0.2c5.6,2.3,11,5,16.4,8.2s10.6,6.9,15.9,11.4c0.9,1,2,2,2.8,3.1h0.4c2.5,2.3,2.6,2.6,2.6,2.8
							c-0.1,0.2-0.3,0,1.2,1.6c-1.1-1.2-0.8-1-1.1-1.3c2.5,3,3.3,3.7,3.7,4.2c0.5,0.4,0.8,0.5,2,2.3c-0.5-0.9-1.9-2.6-2.9-3.8
							c-1-1.2-1.8-2-0.9-1.5c2.5,3,5.6,7,7.8,10.5c1.1,1.7,2,3.4,2.6,4.7s0.9,2.2,0.9,2.6c1.7,3.1,3.3,6.6,4.6,10.4
							c1.3,3.7,2.3,7.6,3,11.4c0.8,3.8,1.1,7.6,1.3,11.2c0.1,1.8,0.2,3.6,0.1,5.3c0,1.7,0,3.3-0.1,4.8c0.1,1.7,0.2-1,0.2-0.4
							c-0.3,2.4-0.3,6.5-0.8,9.9c-0.1,0.9-0.2,1.7-0.3,2.5s-0.2,1.4-0.3,2c-0.2,1.1-0.3,1.9-0.4,1.9c-0.3,2.3-0.3,3.3-0.3,3.9
							c-0.4,1.5-0.9,3.1-1.4,4.8c-0.6,1.7-1.1,3.5-1.9,5.4c0.3-0.9,0.8-1.8,1-2.6l-0.1,0.3c-0.4,1.3-1,2.6-1.5,3.7v-0.2
							c-1.8,4.5-4.2,9.3-7,13.5c-2.8,4.3-6,8.2-9.2,11.2c3.6-3.6-0.5,1.3,1.2-0.4c-0.9,0.8-2,1.7-3.3,3c-0.7,0.6-1.4,1.2-2.1,1.9
							c-0.8,0.7-1.6,1.3-2.4,2c-3.4,2.7-7.3,5.6-11.1,7.9c0.1,0.1-0.4,0.5,0.5,0.1c-0.8,0.4-1.4,0.9-2.2,1.2
							c-0.8,0.4-1.5,0.8-2.3,1.2c0.3-0.1,0.4-0.1,0.7-0.2c-3.9,2.3-8.3,4.2-12.4,5.8c-4.1,1.5-7.9,2.8-10.7,3.8c3.6-1.1,3.6-1,4-0.9
							c-6.2,2.1-12.2,3.8-19.6,5.3c0.4-0.1,0.7-0.1,0.7,0c-2,0.5-4.3,0.8-6.4,1.1l-0.3,0.3c-3.9,0.6-8.8,1.4-13.5,1.7
							c-0.9,0.3-3.7,0.5-7,0.7c-3.2,0.2-7,0.3-9.7,0.3c-2,0,4.5,0.2-1.3,0.3c-0.4-0.3-2.3-0.3-4.1-0.3c-1.8,0-3.6-0.1-3.8-0.4
							l-2.6,0.1h-1.3H763h-0.3h-0.4H762h-0.2l-0.3-0.1l-0.5-0.2c-2.1,0.3-3.7,0.3-5.6,0.2c-1.9-0.2-4-0.4-7.2-0.4
							c0.3,0.1,1,0.2,0.7,0.2c-2-0.2-6.8-0.5-3.1-0.4c-1.7-0.1-5.4-0.4-8.1-0.7c-2.6-0.3-4.2-0.4-1.7,0.1c-0.3-0.3,4.3,0.4,6.4,0.7
							c-0.3,0.3-5.4-0.3-6.6-0.2c-0.2-0.1-0.8-0.2-1.3-0.3l-0.8,0.1c-6.3-1.1-12.8-2-17.2-3.4c-0.3,0-1.4-0.1-3.5-0.7v-0.3
							c-2.2-0.5-4.3-1.1-6.5-1.7l2.9,1.4c-7.2-1.8-12-4-17.6-6.3c-0.8,0-3.6-1.2-6.6-2.6c-3.1-1.4-6.5-3.3-8.9-4.4
							c1.4,0.9,2.7,1.6,4.1,2.4c-4.5-2.4-7-3.9-9.4-5.4c-2.4-1.5-4.8-3-8.3-6c3.2,2.4,4.8,3.5,7,4.8c1,0.7,2.2,1.4,3.7,2.2
							c1.5,0.8,3.5,1.9,6.1,3.1c-5.2-2.6-9.4-5.4-13.3-8.3c-3.9-3-7.5-6.1-11.3-9.8c0.4,0.6,2.2,2.5,0.3,0.9
							c-0.3-0.4-0.9-1.1-1.6-1.9c-0.7-0.8-1.4-1.6-2.1-2.4c-1.3-1.6-2.3-2.8-1.7-2.3c-2.1-2.5-2.7-2.8-3.9-4.4
							c-2-3.1-3.7-6.4-5.3-9.8l0.1,0.3c-2.3-4.1-4.1-8.3-5.4-12.6c-1.4-4.2-2.3-8.6-2.9-13c-1.3-8.8-1.4-17.6-0.3-26.9l-0.3,0.3
							c0.3-1.5,0.6-3.1,1-4.6c-0.9,3.6,0.1-1.6,0-2c1-3.9,2.3-7.7,4-11.4c0-0.2-0.2-0.1-0.5,0.3
							C637.1,176.5,636.6,177.3,636.2,178.8z M713.9,296.3c0.9,0.3,1.9,0.5,2.8,0.7c0.6,0.1,1.2,0.3,1.8,0.4l-1.8-0.4
							C715.3,296.7,714.2,296.6,713.9,296.3z M889.4,174.8c-0.2-0.7,0-0.3,0.3,0.5c0.3,0.8,0.7,1.8,0.8,2.2l-0.8-2.2l-0.9-2.2
							C888.5,172.6,889.2,174.2,889.4,174.8z M713.6,298.6l-1.6-0.2l-2-0.7C711.1,298,712.3,298.4,713.6,298.6z M674.6,283.6
							c1.2,0.8,2.4,1.4,3.6,2.1l-0.3-0.1c-1.2-0.7-2.4-1.4-3.6-2.1L674.6,283.6z M643.4,250.3c0.1,0.3,0.3,0.4,0.3,0.7
							C643.7,250.8,643.5,250.6,643.4,250.3c-0.7-1.4-1.3-2.3-1.8-3.2c-0.5-0.9-0.9-1.7-1.3-3C642,246.8,642.7,248.7,643.4,250.3z
							 M699.9,292.7c1.3,0.5,2,0.7,2.9,1c-0.7-0.3-3.2-1.4-1.3-0.9c1.3,0.6,5.8,2,5.8,2.2C703.4,293.8,700.5,293.4,699.9,292.7z
							 M796.4,300.8l-1,0.2c1.3-0.1,2.6-0.2,3.8-0.3C798.2,300.7,797.4,300.8,796.4,300.8z M813.9,298.2c2.1-0.1,7.4-1.4,11-2.4
							c0.8-0.5-2.9,0.3-7.1,1.3C812.8,298,816,297.9,813.9,298.2z M671.1,279.6l0.8,0.8l-0.9-0.5c1.1,0.9,2.3,1.5,3.4,2.3
							c-4.8-2.6-7.6-5.3-9.7-7c0.5,0.3,1.1,0.9,1.9,1.5s1.5,1.2,2.2,1.7C670.3,279.4,671.5,280.2,671.1,279.6z M849.6,286
							c0.9-0.4,1.9-0.9,2.9-1.4c1-0.5,2.1-1.1,3.1-1.5c1.9-1,3.2-1.8,3-1.4c0-0.3-1.2,0.3-2.5,1.1c-1.4,0.7-3,1.5-3.7,2
							C851.3,285.1,850.8,285.4,849.6,286z M849.7,286.8c-1.3,0.6-2.2,1-3.1,1.4c-0.8,0.3-1.4,0.6-2.1,0.8c-1.2,0.4-2.4,0.8-4.3,1.4
							c1.2-0.6,2.2-1,3.1-1.4c0.8-0.3,1.4-0.6,2-0.7c0.9-0.3,1.2-0.2,0.9,0c3.5-1.5,2.7-1.4,4.5-2.4
							C850.4,286.2,850.1,286.5,849.7,286.8z M844.1,288.2c-0.7,0.3-1.4,0.7-2,0.9c-1.2,0.6-1.9,1.1-2.6,1.4
							c-0.9,0.4-1.8,0.8-3.7,1.3c1.1-0.4,2.1-0.9,3.1-1.4c1-0.4,2.1-0.9,3.1-1.4C842.6,288.9,843.3,288.6,844.1,288.2z M879.8,260.5
							l-1.4,1.7C879.7,260.4,879.9,260.3,879.8,260.5c0.4-0.5,0.8-0.9,1.2-1.4C878.3,263.1,879.6,261,879.8,260.5z M853.8,283.3
							c-0.9,0.5-1.9,1-2.8,1.5c-0.9,0.5-1.9,1-2.9,1.4C848.5,286.4,852.1,284.4,853.8,283.3z M879.2,261.9c-0.4,0.6-1,1.3-1.6,2.1
							c-0.3,0.4-0.7,0.9-1,1.2c-0.3,0.4-0.7,0.8-1,1.2c-1.3,1.5-2.4,2.6-2,2.2c0.1-0.2,0.4-0.5,0.9-1.1c0.4-0.5,1.1-1.3,1.7-2
							c0.7-0.7,1.3-1.4,1.8-2.1C878.5,262.7,879,262.2,879.2,261.9z M858,135.9c1.1,0.8,2.2,1.6,3.3,2.4l-1.8-0.9
							C859,136.9,858.5,136.4,858,135.9z M889.3,171.6l-0.6-0.9l-1-2.1L889.3,171.6z M896.6,214.9c-0.1-0.3-0.2-0.1-0.3,0.3
							c-0.1,0.7-0.2,1.3-0.3,1.5c0.1-0.6,0.2-1.2,0.3-1.5c0.2-1.8,0.2-4.4,0.2-5.6c-0.2,0.7-0.3,3.4-0.3,4.5c-0.1-1,0-2.3,0.1-3.7
							c0.1-1.4,0.1-2.8,0.2-4c0.1-1.2,0-2.2-0.1-2.8c-0.1-0.5-0.2-0.6-0.4,0c0.1-1.7-0.2-7.6,0.2-5.5c0.1,1.2,0.3,2.6,0.3,4.1
							s0.2,3.1,0.2,4.8C896.6,210,896.6,213.2,896.6,214.9c0,0.2,0.1,0.6,0,1.4C896.6,216.1,896.6,215.6,896.6,214.9z M874.4,269.1
							c-1.2,1.2-1.7,1.7-2,1.9c-1.1,0.9-2,1.6-1.8,1.2c2.6-2.3,1.1-0.7,1.8-1.2C873,270.4,873.7,269.7,874.4,269.1z M866.6,276.2
							c0.8-0.5,1.4-1.1,2.2-1.7c0.7-0.6,1.4-1.1,2.1-1.8c-0.7,0.6-1.4,1.1-2.2,1.7c2.2-1.7,4.2-3.5,6.3-5.4
							C871.8,272.7,867.3,276,866.6,276.2z M865.2,277.6c-0.3,0.2-0.7,0.3-1,0.5c0.5-0.3,1-0.6,1.5-0.8L865.2,277.6l0.3-0.1
							c-2.5,1.9-5,3.6-7.7,5.2C860.3,281.1,862.7,279.4,865.2,277.6z M775.7,303.5c-1.7,0.4,0.3,0.6,3.1,0.5c2.7,0,6.1-0.2,7,0
							c0.2-0.2,1.1-0.4,0.9-0.5c-0.3-0.2-1.6-0.1-6.1,0.1c3.9-0.3,5.1-0.3,5.9-0.3c0.9,0,1.3,0,3.6-0.5c-3.7,0.4-8.2,0.4-8.9,0.4
							c1.4-0.1,2.9-0.2,4.4-0.3C783,302.6,778.8,303.6,775.7,303.5z M728.3,301.1c-1.9-0.3-3.8-0.6-5.7-1c1.8,0.3,3.6,0.7,5.4,1
							H728.3z M630.2,213c-0.1,0.2-0.4-1.5-0.6-3.6c-0.1-1-0.2-2.1-0.2-3.1c0-0.9,0.1-1.8,0.2-2.2C629.6,207.9,629.9,212,630.2,213z
							 M646.8,155.2l-2,2.6l-2,2.6c0.2-0.6,1-1.9,2-3.1c0.5-0.7,1.1-1.4,1.7-2c0.6-0.7,1.1-1.3,1.6-2
							C649,152.6,647.3,154.4,646.8,155.2z M679.6,128.7c0.6-0.3,1.2-0.6,1.9-0.9c-0.7,0.2-1.4,0.4-2.1,0.7L679.6,128.7z
							 M676.9,129.6c1-0.9-2.9,1.4-3.7,2C674.4,130.8,675.6,130.2,676.9,129.6z M711.9,114.1l-2.6,0.8l2.5-0.4L711.9,114.1z
							 M708,115.9c-0.7,0-0.6-0.5-6.5,1.8c-0.2,0.3,1.4-0.3-0.6,0.9c2-0.7,3.5-1.4,4.5-1.7c-1.4,0.4-2.9,1.1-3.3,1.1
							C704.3,117,706.6,116.3,708,115.9c0.3,0,0.6,0,1.4-0.3C709.3,115.6,708.8,115.7,708,115.9z M687,124.7
							c-0.8,0.4-1.4,0.8-1.6,0.9l-0.3,0.1C685.8,125.2,686.4,124.9,687,124.7c0.9-0.4,1.8-0.9,2.7-1.4c0.5-0.5-3.5,1.1-4.5,1.5
							c0.9-0.4,1.9-0.8,2.8-1.2c0.9-0.4,2-0.9,3.2-1.4c-0.3,0.1-0.1-0.1-1.6,0.6c1.1-0.5,2.3-1.1,3.4-1.6c4.1-1.4,0.3,0.3-3.2,2
							c-0.1,0.1-0.3,0.3-1,0.7C688.1,124.3,687.6,124.4,687,124.7z M715.2,114.4l-2,0.5c-1,0.3-0.4-0.1-0.8,0
							C714.8,114.2,717.8,113.6,715.2,114.4z M630.9,193.5c0.1-0.7-0.1-0.3-0.3,0.8c-0.2,1-0.4,2.5-0.4,4.1
							c-0.1-0.5-0.2-1.1-0.3-1.6c0.2,0,0.3-1.6,0.5-3.2c0.3-1.6,0.5-3.3,0.6-3.7c0.3-0.5,0.3-0.2,0.2,0.6
							C631.2,191.1,631,192.3,630.9,193.5z M708.6,296.6c0.5,0.3,2.6,1,4.8,1.5c2.2,0.5,4.6,1,5.4,1.3c-2-0.3-5.3-1-7.6-1.7
							C708.8,297.1,707.3,296.5,708.6,296.6z M721.8,299.5c2.1,0.4,2.7,0.4,2.5,0.7c1,0.3,2.7,0.4,3.7,0.7
							C723.8,300.3,724.2,300,721.8,299.5c-0.5-0.1-1.2-0.3-2-0.4C720.7,299.2,721.3,299.4,721.8,299.5z M649.9,261.5
							c-0.5-0.8-1-1.6-1.4-2.4c-0.9-1.1-1.8-2.4-2.6-3.6C647.1,257.5,648.5,259.5,649.9,261.5z M632.1,218.3
							c-0.2,0.4-0.8-4.6-0.8-5.7c-0.1,0.2-0.1,1.1,0,2.3c0.2,1.2,0.3,2.7,0.5,4.1c0,0.3,0.1,0.9,0.3,1.8c-0.1-0.5-0.2-1.1-0.3-1.8
							c-0.1-1,0.2-0.3,0.3-0.3c0.1,0.4,0.2,0.9,0.3,1.3c-0.2-1.2-0.3-2.5-0.4-3.7L632.1,218.3z M659.5,144.3c2.2-2,2.5-2.3,4.2-3.5
							c-0.3,0.3-0.8,0.8-1.6,1.4C661.2,143,660.3,143.7,659.5,144.3z M639,242.2c-2-4.6,0.6,2.4-0.9-0.6c0.3,0.9,0.8,1.6,1.1,2.4
							c-1-1.9-1.3-2.6-1.4-3.2c-0.2-0.6-0.2-1-0.8-2.5c0.3,0.6,0.5,1.3,0.8,1.9c0.4,0.7,0.2,0-0.2-1c-0.3-0.9-0.8-2.2-0.7-2.4
							C637.9,239,640,244,639,242.2z M663.2,141.4c1.9-1.6,2.8-2.2,3.5-2.6c0.7-0.4,1.2-0.6,2.5-1.4c-1.6,1.2-2.3,1.7-3,2.1
							C665.5,140,664.8,140.3,663.2,141.4z M632.3,211.3l0.1,0.3c-0.1-1.4-0.1-2.7-0.1-4l-0.1-0.3
							C632.3,208.6,632.2,209.9,632.3,211.3z"/>
						<path class="st13" d="M666.8,135.7c1-0.8,1.7-1.2,2.3-1.6C668.3,134.6,667.6,135.1,666.8,135.7z"/>
						<path class="st13" d="M733.9,303.1c0.1-0.1,0.6,0,1.2,0C735,303.2,734.6,303.2,733.9,303.1z"/>
						<path class="st13" d="M743.7,304.3c0.1,0,0.3,0,0.3,0s0.2,0,0.3,0.1L743.7,304.3z"/>
						<path class="st13" d="M808.6,113.1c-2-0.9,2,0.5,3.7,0.9C810.6,113.6,810,113.6,808.6,113.1z"/>
						<path class="st13" d="M821.3,117.6c-0.9-0.4-2.3-0.9-4-1.5C818.5,116.4,819.6,116.8,821.3,117.6z"/>
						<path class="st13" d="M810.9,114.2c2.5,0.7,4.8,1.3,6.5,1.9C815.7,115.5,814.4,115.2,810.9,114.2z"/>
						<path class="st13" d="M854.1,132.8c-0.8-0.7-2.1-1.4-3.2-2.1c-1.1-0.6-2-1.1-2-1.1c2,1,2.8,1.5,3.1,1.6
							c0.3,0.1-0.2-0.3-0.8-0.7c-1.4-0.9-3.8-2.4-4.2-2.6C851.8,130.6,851.6,130.9,854.1,132.8z"/>
						<path class="st13" d="M863.7,139.7c-0.7-0.6-1.4-1.1-2-1.4C862.4,138.7,863.1,139.1,863.7,139.7z"/>
						<path class="st13" d="M866.4,141.6c2.3,2,3,2.9,3.8,4c-1.4-1.3-2.6-2.2-3.5-3.2c-1-0.9-2-1.9-3.1-2.7
							C865.4,141,867,142.4,866.4,141.6z"/>
						<path class="st13" d="M896.6,227.1c0.2-0.7,0.4-2.4,0.5-2.5c-0.3,1.7-0.5,3.4-0.9,5.1C895.7,231.9,896.5,228.1,896.6,227.1z"
							/>
						<path class="st13" d="M890.7,245.3c-1.6,3.8-0.5,2-1.4,3.8l-0.3-0.4C889.6,247.6,890.2,246.5,890.7,245.3z"/>
						<path class="st13" d="M889.7,242.4l0.4-1.1c-0.2,0.4-0.3,0.8-0.5,1.3L889.7,242.4z"/>
						<path class="st13" d="M881.1,262.8c0.7-0.7,1.4-1.4,2-2C881,263.5,880.7,263.3,881.1,262.8z"/>
						<path class="st13" d="M870.6,274.5c0.7-0.5,1.3-1,1.9-1.5l-1.4,1.3l-1.4,1.2l-1.4,1.2l-1.5,1.2
							C867.9,276.8,869.2,275.7,870.6,274.5z"/>
						<path class="st13" d="M854.8,285.5l-0.5,0.4c-0.8,0.4-1.5,0.9-2.3,1.2c-0.8,0.4-1.5,0.8-2.4,1.2c0.8-0.4,1.8-0.9,2.6-1.4
							C853.1,286.5,854,286,854.8,285.5z"/>
						<path class="st13" d="M658.6,272.4c-0.2-0.4,1.3,0.9,3.1,2.2c0.8,0.8,1.9,1.4,2.7,2.1c0.8,0.7,1.7,1.3,2.2,1.5
							c-0.8-0.3-2.1-1.3-3.6-2.5C661.6,274.7,660,273.4,658.6,272.4z"/>
						<path class="st13" d="M661.1,140.2c-1,0.8-2,1.6-3.1,2.5c-1,0.8-2,1.7-3,2.6c2.2-2.1,2.5-2.4,2.9-2.6
							C658.3,142.3,658.7,142,661.1,140.2z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	<g id="CT012036" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-front-gearbox-set", "_blank")}>
		<g>
			<g>
				<path class="st11" d="M807.1,120.3H734c-5.6,0-10.2-4.6-10.2-10.2l0,0c0-5.6,4.6-10.2,10.2-10.2h73.1c5.6,0,10.2,4.6,10.2,10.2
					l0,0C817.3,115.7,812.8,120.3,807.1,120.3z"/>
			</g>
			<polyline class="st12" points="807.8,112.9 810.4,110.1 807.6,107.3 			"/>
		</g>
		<g>
			<path class="st13" d="M736.9,106.4c-0.7,0-1.2,0.3-1.6,0.8s-0.6,1.2-0.6,2.1c0,1.9,0.7,2.8,2.1,2.8c0.6,0,1.4-0.2,2.1-0.4v1.5
				c-0.7,0.3-1.4,0.4-2.3,0.4c-1.3,0-2.2-0.3-2.8-1.1c-0.6-0.8-1-1.8-1-3.2c0-0.9,0.2-1.6,0.5-2.3s0.8-1.2,1.4-1.5s1.3-0.5,2.1-0.5
				c0.9,0,1.6,0.2,2.5,0.6l-0.6,1.4c-0.3-0.2-0.6-0.3-0.9-0.4S737.2,106.4,736.9,106.4z"/>
			<path class="st13" d="M744.2,113.5h-1.8v-7h-2.3V105h6.4v1.5h-2.3V113.5z"/>
			<path class="st13" d="M753.1,109.2c0,1.4-0.3,2.6-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1
				c-0.5-0.8-0.8-1.8-0.8-3.2c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1
				C752.8,106.8,753.1,107.9,753.1,109.2z M748.9,109.2c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7c0.4,0,0.7-0.3,0.9-0.7
				c0.3-0.4,0.3-1.2,0.3-2.2s-0.1-1.8-0.3-2.2c-0.2-0.4-0.5-0.7-0.9-0.7c-0.4,0-0.8,0.3-0.9,0.7C749,107.4,748.9,108.2,748.9,109.2z
				"/>
			<path class="st13" d="M758.4,113.5h-1.8v-4.9v-0.8v-0.8c-0.3,0.3-0.5,0.5-0.6,0.6l-0.9,0.8l-0.9-1.1l2.7-2.2h1.4L758.4,113.5
				L758.4,113.5z"/>
			<path class="st13" d="M766.7,113.5h-6v-1.3l2.1-2.1c0.6-0.7,1-1.1,1.3-1.4c0.3-0.3,0.3-0.5,0.4-0.7c0.1-0.2,0.2-0.4,0.2-0.7
				c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.8-0.3s-0.7,0.1-0.9,0.3c-0.3,0.2-0.6,0.3-0.9,0.7l-0.9-1.2c0.4-0.3,0.8-0.6,1-0.8
				c0.3-0.2,0.6-0.3,0.9-0.3c0.3-0.1,0.7-0.1,1.1-0.1c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.4,0.9,0.8c0.2,0.4,0.3,0.8,0.3,1.2
				c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.3-0.3,0.7-0.7,1c-0.3,0.3-0.8,0.8-1.5,1.5l-1.1,1v0.1h3.7v1.5H766.7z"/>
			<path class="st13" d="M773.5,109.2c0,1.4-0.3,2.6-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1
				c-0.5-0.8-0.8-1.8-0.8-3.2c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1
				C773.2,106.8,773.5,107.9,773.5,109.2z M769.3,109.2c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7s0.7-0.3,0.9-0.7
				c0.3-0.4,0.3-1.2,0.3-2.2s-0.1-1.8-0.3-2.2c-0.2-0.4-0.5-0.7-0.9-0.7s-0.8,0.3-0.9,0.7C769.4,107.4,769.3,108.2,769.3,109.2z"/>
			<path class="st13" d="M779.9,106.9c0,0.5-0.2,1-0.5,1.4c-0.3,0.3-0.8,0.6-1.4,0.8l0,0c0.7,0.1,1.2,0.3,1.5,0.6
				c0.3,0.3,0.5,0.8,0.5,1.4c0,0.8-0.3,1.4-0.8,1.9c-0.6,0.4-1.4,0.7-2.5,0.7c-0.9,0-1.7-0.2-2.5-0.4v-1.5c0.3,0.2,0.7,0.3,1.1,0.4
				s0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3c0.3-0.3,0.4-0.5,0.4-0.9c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.8-0.3-1.5-0.3h-0.7v-1.4h0.7
				c0.7,0,1.1-0.1,1.4-0.3s0.4-0.5,0.4-0.8c0-0.7-0.4-0.9-1.2-0.9c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.6,0.3-0.9,0.5l-0.8-1.3
				c0.8-0.6,1.7-0.8,2.8-0.8c0.8,0,1.6,0.2,2.1,0.5C779.7,105.8,779.9,106.2,779.9,106.9z"/>
			<path class="st13" d="M781,109.9c0-1.7,0.3-2.9,1.1-3.7c0.8-0.9,1.8-1.2,3.2-1.2c0.5,0,0.9,0,1.1,0.1v1.4c-0.3-0.1-0.7-0.1-1-0.1
				c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.7,0.4-0.8,0.8c-0.2,0.4-0.3,0.9-0.3,1.5h0.1c0.4-0.7,1-1,1.9-1c0.8,0,1.4,0.3,1.8,0.7
				c0.4,0.4,0.7,1.1,0.7,2c0,0.9-0.3,1.6-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8c-0.6,0-1.2-0.2-1.6-0.4s-0.9-0.7-1.1-1.3
				C781.3,111.3,781,110.7,781,109.9z M784.1,112.2c0.4,0,0.7-0.2,0.9-0.4c0.2-0.3,0.3-0.6,0.3-1.1c0-0.4-0.1-0.8-0.3-1
				s-0.5-0.3-0.8-0.3s-0.7,0.1-0.9,0.3c-0.3,0.3-0.4,0.5-0.4,0.8c0,0.4,0.1,0.8,0.3,1.2C783.4,112,783.8,112.2,784.1,112.2z"/>
		</g>
	</g>
	<line class="st10" x1="864.5" y1="425.9" x2="902.8" y2="391"/>
	<g id="CT012036_1_" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-front-gearbox-set", "_blank")}>
		<g>
			<g>
				<path class="st11" d="M972.9,405h-73.1c-5.6,0-10.2-4.6-10.2-10.2l0,0c0-5.6,4.6-10.2,10.2-10.2h73.1c5.6,0,10.2,4.6,10.2,10.2
					l0,0C983.1,400.4,978.5,405,972.9,405z"/>
			</g>
			<polyline class="st12" points="973.6,397.6 976.1,394.8 973.3,392 			"/>
		</g>
		<g>
			<path class="st13" d="M902.7,391.2c-0.7,0-1.2,0.3-1.6,0.8s-0.6,1.2-0.6,2.1c0,1.9,0.7,2.8,2.1,2.8c0.6,0,1.4-0.2,2.1-0.4v1.5
				c-0.7,0.3-1.4,0.4-2.3,0.4c-1.3,0-2.2-0.3-2.8-1.1c-0.7-0.8-1-1.8-1-3.2c0-0.9,0.2-1.6,0.5-2.3c0.3-0.7,0.8-1.2,1.4-1.5
				s1.3-0.5,2.1-0.5c0.9,0,1.6,0.2,2.5,0.6l-0.6,1.4c-0.3-0.2-0.7-0.3-0.9-0.4S902.9,391.2,902.7,391.2z"/>
			<path class="st13" d="M909.9,398.2h-1.8v-7h-2.3v-1.5h6.4v1.5h-2.3V398.2z"/>
			<path class="st13" d="M918.8,394c0,1.4-0.3,2.5-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1c-0.5-0.8-0.8-1.8-0.8-3.2
				c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1C918.6,391.5,918.8,392.6,918.8,394z M914.7,394
				c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7c0.4,0,0.7-0.3,0.9-0.7c0.3-0.4,0.3-1.2,0.3-2.2c0-1-0.1-1.8-0.3-2.2
				c-0.2-0.4-0.5-0.7-0.9-0.7c-0.4,0-0.8,0.3-0.9,0.7C914.8,392.2,914.7,393,914.7,394z"/>
			<path class="st13" d="M924.2,398.2h-1.8v-4.9v-0.9v-0.9c-0.3,0.3-0.5,0.5-0.6,0.6l-0.9,0.8l-0.9-1.1l2.7-2.2h1.4L924.2,398.2
				L924.2,398.2z"/>
			<path class="st13" d="M932.4,398.2h-6V397l2.1-2.1c0.6-0.7,1-1.1,1.3-1.4c0.2-0.3,0.3-0.5,0.4-0.7c0.1-0.3,0.2-0.4,0.2-0.7
				c0-0.3-0.1-0.6-0.3-0.8s-0.4-0.3-0.8-0.3s-0.7,0.1-0.9,0.3c-0.3,0.2-0.6,0.3-0.9,0.7l-0.9-1.2c0.4-0.3,0.8-0.6,1-0.8
				c0.3-0.2,0.6-0.3,0.9-0.3c0.3-0.1,0.7-0.1,1.1-0.1c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,0.9,0.8c0.3,0.3,0.3,0.8,0.3,1.2
				c0,0.4-0.1,0.8-0.2,1.1s-0.3,0.7-0.7,1s-0.8,0.9-1.5,1.5l-1.1,1v0.1h3.7v1.5H932.4z"/>
			<path class="st13" d="M939.2,394c0,1.4-0.3,2.5-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1c-0.5-0.8-0.8-1.8-0.8-3.2
				c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1C939,391.5,939.2,392.6,939.2,394z M935.1,394
				c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7s0.7-0.3,0.9-0.7c0.3-0.4,0.3-1.2,0.3-2.2c0-1-0.1-1.8-0.3-2.2
				c-0.2-0.4-0.5-0.7-0.9-0.7s-0.8,0.3-0.9,0.7C935.2,392.2,935.1,393,935.1,394z"/>
			<path class="st13" d="M945.7,391.7c0,0.5-0.2,1-0.5,1.4c-0.3,0.3-0.8,0.6-1.4,0.8l0,0c0.7,0.1,1.2,0.3,1.5,0.6
				c0.3,0.3,0.5,0.8,0.5,1.4c0,0.9-0.3,1.4-0.8,1.9c-0.6,0.4-1.4,0.7-2.5,0.7c-0.9,0-1.7-0.2-2.5-0.4v-1.5c0.3,0.2,0.7,0.3,1.1,0.4
				c0.4,0.1,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.8-0.3-1.5-0.3h-0.7
				v-1.4h0.7c0.7,0,1.1-0.1,1.4-0.3s0.4-0.5,0.4-0.9c0-0.7-0.4-0.9-1.2-0.9c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.6,0.3-0.9,0.5
				l-0.8-1.3c0.8-0.6,1.7-0.9,2.8-0.9c0.8,0,1.6,0.2,2.1,0.5C945.4,390.6,945.7,391,945.7,391.7z"/>
			<path class="st13" d="M946.8,394.7c0-1.7,0.3-2.9,1.1-3.7c0.8-0.9,1.8-1.2,3.2-1.2c0.5,0,0.9,0,1.1,0.1v1.4
				c-0.3-0.1-0.7-0.1-1-0.1c-0.6,0-1.1,0.1-1.5,0.3s-0.7,0.4-0.8,0.9c-0.2,0.3-0.3,0.9-0.3,1.5h0.1c0.4-0.7,1-1,1.9-1
				c0.8,0,1.4,0.3,1.8,0.7c0.4,0.4,0.7,1.1,0.7,2c0,0.9-0.3,1.6-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8c-0.6,0-1.2-0.2-1.6-0.4
				c-0.4-0.3-0.9-0.7-1.1-1.3C947.1,396,946.8,395.4,946.8,394.7z M949.9,397c0.4,0,0.7-0.2,0.9-0.4c0.2-0.3,0.3-0.6,0.3-1.1
				c0-0.4-0.1-0.8-0.3-1c-0.2-0.3-0.5-0.3-0.8-0.3s-0.7,0.1-0.9,0.3c-0.3,0.3-0.4,0.5-0.4,0.9c0,0.4,0.1,0.9,0.3,1.2
				C949.2,396.8,949.5,397,949.9,397z"/>
		</g>
	</g>
	<g id="CT012036_2_" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-front-gearbox-set", "_blank")}>
		<g>
			<g>
				<path class="st11" d="M859.8,903.1h-73.1c-5.6,0-10.2-4.6-10.2-10.2l0,0c0-5.6,4.6-10.2,10.2-10.2h73.1
					c5.6,0,10.2,4.6,10.2,10.2l0,0C870,898.5,865.5,903.1,859.8,903.1z"/>
			</g>
			<polyline class="st12" points="860.5,895.7 863.1,892.9 860.3,890.1 			"/>
		</g>
		<g>
			<path class="st13" d="M789.6,889.3c-0.7,0-1.2,0.3-1.6,0.8c-0.3,0.5-0.6,1.2-0.6,2.1c0,1.9,0.7,2.8,2.1,2.8
				c0.6,0,1.4-0.2,2.1-0.4v1.5c-0.7,0.3-1.4,0.4-2.3,0.4c-1.3,0-2.2-0.3-2.8-1.1c-0.7-0.8-1-1.8-1-3.2c0-0.8,0.2-1.6,0.5-2.3
				c0.3-0.7,0.8-1.2,1.4-1.5c0.6-0.3,1.3-0.5,2.1-0.5s1.6,0.2,2.5,0.6l-0.6,1.4c-0.3-0.2-0.7-0.3-0.9-0.4
				C790.2,889.3,789.9,889.3,789.6,889.3z"/>
			<path class="st13" d="M796.9,896.3h-1.8v-7h-2.3v-1.5h6.4v1.5h-2.3V896.3z"/>
			<path class="st13" d="M805.8,892.1c0,1.4-0.3,2.5-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1
				c-0.5-0.8-0.8-1.8-0.8-3.2c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1
				C805.5,889.6,805.8,890.7,805.8,892.1z M801.6,892.1c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7c0.4,0,0.7-0.3,0.9-0.7
				s0.3-1.2,0.3-2.2c0-1-0.1-1.8-0.3-2.2c-0.2-0.4-0.5-0.7-0.9-0.7c-0.4,0-0.8,0.3-0.9,0.7C801.7,890.3,801.6,891.1,801.6,892.1z"/>
			<path class="st13" d="M811.1,896.3h-1.8v-4.9v-0.9v-0.8c-0.3,0.3-0.5,0.5-0.6,0.6l-0.9,0.8L807,890l2.7-2.2h1.4L811.1,896.3
				L811.1,896.3z"/>
			<path class="st13" d="M819.4,896.3h-6V895l2.1-2.1c0.6-0.7,1-1.1,1.3-1.4c0.2-0.3,0.3-0.5,0.4-0.7c0.1-0.3,0.2-0.4,0.2-0.7
				c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.8-0.3s-0.7,0.1-0.9,0.3c-0.3,0.2-0.6,0.3-0.9,0.7l-0.9-1.2c0.4-0.3,0.8-0.6,1-0.8
				c0.3-0.2,0.6-0.3,0.9-0.3c0.3-0.1,0.7-0.1,1.1-0.1c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,0.9,0.9c0.3,0.3,0.3,0.8,0.3,1.2
				s-0.1,0.8-0.2,1.1c-0.2,0.3-0.3,0.7-0.7,1c-0.3,0.3-0.8,0.9-1.5,1.5l-1.1,1v0.1h3.7v1.5H819.4z"/>
			<path class="st13" d="M826.2,892.1c0,1.4-0.3,2.5-0.8,3.3c-0.5,0.8-1.3,1.1-2.2,1.1c-1,0-1.7-0.3-2.2-1.1
				c-0.5-0.8-0.8-1.8-0.8-3.2c0-1.5,0.3-2.6,0.8-3.3c0.5-0.7,1.2-1.1,2.2-1.1c1,0,1.7,0.3,2.2,1.1
				C825.9,889.6,826.2,890.7,826.2,892.1z M822,892.1c0,1,0.1,1.8,0.3,2.2c0.2,0.4,0.5,0.7,0.9,0.7s0.7-0.3,0.9-0.7
				c0.3-0.4,0.3-1.2,0.3-2.2c0-1-0.1-1.8-0.3-2.2c-0.2-0.4-0.5-0.7-0.9-0.7s-0.8,0.3-0.9,0.7C822.1,890.3,822,891.1,822,892.1z"/>
			<path class="st13" d="M832.6,889.8c0,0.5-0.2,1-0.5,1.4c-0.3,0.3-0.8,0.6-1.4,0.8l0,0c0.7,0.1,1.2,0.3,1.5,0.6
				c0.3,0.3,0.5,0.8,0.5,1.4c0,0.8-0.3,1.4-0.8,1.9c-0.6,0.4-1.4,0.7-2.6,0.7c-0.9,0-1.7-0.2-2.5-0.4v-1.5c0.3,0.2,0.7,0.3,1.1,0.4
				c0.4,0.1,0.8,0.2,1.2,0.2c0.6,0,1-0.1,1.3-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.8-0.3-1.5-0.3h-0.7
				v-1.4h0.7c0.7,0,1.1-0.1,1.4-0.3c0.3-0.2,0.4-0.4,0.4-0.9c0-0.7-0.4-0.9-1.2-0.9c-0.3,0-0.6,0.1-0.9,0.2
				c-0.3,0.1-0.6,0.3-0.9,0.5l-0.9-1.3c0.8-0.6,1.7-0.8,2.8-0.8c0.9,0,1.6,0.2,2.1,0.5C832.4,888.7,832.6,889.1,832.6,889.8z"/>
			<path class="st13" d="M833.8,892.8c0-1.7,0.3-3,1.1-3.7s1.8-1.2,3.2-1.2c0.5,0,0.8,0,1.1,0.1v1.4c-0.3-0.1-0.7-0.1-1-0.1
				c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.7,0.4-0.8,0.8c-0.2,0.3-0.3,0.9-0.3,1.5h0.1c0.4-0.7,1-1,1.9-1c0.8,0,1.4,0.3,1.8,0.7
				c0.4,0.4,0.7,1.1,0.7,2c0,0.9-0.3,1.6-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8c-0.6,0-1.2-0.2-1.6-0.4c-0.4-0.3-0.9-0.7-1.1-1.3
				C834,894.1,833.8,893.5,833.8,892.8z M836.8,895c0.4,0,0.7-0.2,0.9-0.4c0.2-0.3,0.3-0.6,0.3-1.1c0-0.4-0.1-0.8-0.3-1
				c-0.2-0.3-0.5-0.3-0.9-0.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.3-0.4,0.5-0.4,0.8c0,0.4,0.1,0.9,0.3,1.2
				C836.1,894.9,836.5,895,836.8,895z"/>
		</g>
	</g>
</g>
</svg>


 : <svg
  className="frontBulkhead"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<path class="st0" d="M661.7,607.4l-1.4-0.8l-1.1-1l-0.7-1.1l-0.2-0.6 M837.3,536.3l-3.2,3.1 M649.1,588.8l-2.5-5.8l-4.8-19.1
	l-1.6-17.9 M658.4,608.3l-2.5-4.1 M661.7,613.5v34.3 M661.7,607.4v1.9 M823.6,632.9v8.9 M661.7,607.4V607l-0.1-0.4l-0.1-0.4
	l-0.2-0.3l-0.3-0.3l-0.2-0.2 M663.3,608.2l-1.5-0.8 M721.1,637.1l-43.5-21.8 M721.1,637.1v-0.4l-0.1-0.4l-0.1-0.4l-0.2-0.3l-0.3-0.3
	l-0.2-0.2 M738.6,634.9l-0.3,0.1l-0.5,0.5l-0.3,0.8l-0.1,0.9 M703.4,613.8l3.1-2.2 M704.3,609.9l-3.9,3.1l-6.5,0.9l-6.8-2.1
	 M674.4,600.4l-2.8-4.2l-0.3-0.5 M687.1,611.8l-2.8-2 M777.6,638.7l0.5-0.3l0.3-0.1 M660.3,601.1v3.8 M660.3,571.1v2.1 M719.7,634.7
	v-16.5 M771.9,625.1l3.7,6.8l2.7,4.2 M769.9,620.6l1,2.3 M770.9,622.9l-0.2-0.6l-0.2-0.7l-0.2-0.7l-0.1-0.6 M771.9,625.1l2,0.4
	l2.2-0.2l2.2-0.8l0,0 M761,632.3l-3.9,9.2l-7.4,9.3l-9.3,6 M756.6,662.9l1.7-0.9l4.1-2.6l3.7-3.1l3.4-3.7l3.1-4.2l2.7-4.6l2.3-5
	 M770,623l-0.8-2 M714.8,602l15.6-15 M734.1,578.3l-1.4,0.3l-4,0.3l-3.9-0.4l-3.3-1.1 M721.6,577.3l-2.3-1.6l-0.9-2v-0.2
	 M826.4,530.4l-0.1,0.6l-1.1,2l-0.7,0.6 M823.5,530.4l0.2-0.3l0.6-1.7l-0.7-1.7l-2-1.4l-0.3-0.1 M676.3,548.2l-1,0.3l-3.8,0.7
	l-4-0.1l-3.7-0.8 M663.8,548.2l-2.9-1.4l-1.6-1.9l-0.3-1.1 M677.9,540l-0.5-0.5l-2.4-1.2l-3.1-0.7h-3.4l-3.2,0.7l-2.5,1.2l-1.4,1.6
	v1.7l1.3,1.6l2.4,1.2 M664.9,545.6l3.1,0.7h3.4l3.2-0.7l2.2-1 M827,530.6L827,530.6l-0.4-0.2h-0.2 M825.9,530.7l3.1,2.3l0.9,1.4
	l0.3,1.5l-0.3,1.5l-0.3,0.4 M771.8,459.3v-1.5 M798.6,462.2l0.3-0.6l0.3-0.4l0.2-0.2l0.2-0.1l0.3-0.1l0.3,0.2l0.3,0.3l0.4,1.3
	l0.3,2.3 M801.1,466.6l-0.4-2.8l-0.4-1.4l-0.2-0.4l-0.2-0.3l-0.1-0.1h-0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.2,0.5
	 M799.4,492.2l-0.8-2.8l-1.6-3l-2.5-2.6 M799.1,462.7l6.1,37.5 M657.7,557.5l-2.1-1.3l-1.6-1.5l-1-1.6l-0.3-1.7 M805.8,501.4l-1-2
	l-0.3-0.8l-0.1-0.3 M724.6,475.5l-0.3-8.3 M697.5,469.9l0.7-2.5l1.8-1.4 M728.6,466.5l0.3,10.5 M658.9,543.7v-0.8 M658.9,542.9
	l0.9-2l0.6-0.4h0.1 M665,538v-40.6 M744.2,473.3l0.4-4.7 M717.6,471.6l-0.9,0.6l-0.7,0.6 M717.6,471.6l0.6,1.7 M700,466l0.1,0.9
	l-0.1,0.9l-0.3,0.8l-0.4,0.8l-0.6,0.7l-0.5,0.3 M714.4,472.2l0.2-0.3l0.5-0.8l0.7-0.9l0.8-0.8 M847.4,561.2l-0.1-0.2l-0.1-0.3
	l-0.1-0.3l-0.1-0.3V560 M795.9,489l-1.2-1.2 M749.4,490.5l27.9-13.9 M674.1,554.3l-0.1,0.2 M676.5,553.3l-1.5,0.5l-0.3,0.2
	 M739.6,481.5l0.8,2.2 M666.3,537.7v-40.2 M666.3,497.5l0.8-4.8l2.1-4.4l3.2-3.4l3.7-2l1.8-0.4h1.4l1.2,0.6l0.6,1.2l0.1,0.9
	 M680.8,494.6V524 M682.7,548.6L682.7,548.6L682.7,548.6l-0.2-0.2l-0.1-0.1 M850.2,561.3l0.7-0.8l0.4-1l-0.1-1.1 M820.5,536.6
	L820.5,536.6l-3.7-9.7l-4.6-9.3l-5.4-8.9l-6-8.2l-6.5-7.4l-7-6.5l-7.2-5.4 M779.9,481.3l-26.1,13.1 M786.4,539.6l5.8-2.3l11.1-0.4
	l3.1,0.8 M675.6,552.2l0.5-1.6l1.7-2.5 M682.1,525.1l0.3,0.2l0.3,0.2l0.3,0.2l0.2,0.3l0.1,0.2 M709.1,595.9l-1.5-10.7l-2-5.9
	l-2.9-5.7l-3.5-5.3l-3.9-4.5l-8.1-6l-8.1-2.1l-3.9,0.5l-3.5,1.7l-2.9,2.9l-2,3.8l-1.3,7.5 M717.1,587.2v10.2 M728.8,586.2l-2.5-0.1
	l-2.9-0.4l-4.6-1.5 M725.5,589.5l-2.7-0.4l-3-0.8l-2.6-1.1 M789.1,545.8l0.5,0.1l0.7-0.1 M724.4,567.7v-51.3 M716,509.2l7.9,58.6
	 M800.6,538.7l-6.9,0.2l-6.4,2.5 M789,545.1l1.7,0.5 M682.8,549.8l0.2,0.6l0.9,2.2l2.8,4.2l0.5,0.4l0.5,0.2h0.6l1.2-0.2l2.7-0.3h3.3
	l1.6,0.2l1.5,0.3 M786.4,558.1l5.8-6.5l6.1-5.4l6.4-4.3l6.5-3.1l5-1.4 M781.4,568.1l8.1-11.3l9.3-9.2l9.9-6.5l9.9-3.5l9.4-0.2
	l6.1,2.3 M725.7,567.3v-50.7 M725.7,516.6l0.3-2.4l0.8-2l0.9-1.4l1.2-1l1.4-0.6l1.5-0.2l1.6,0.3l1.7,0.8l1.9,1.5l1.6,2l1.3,2.8
	l0.6,3.1v3 M847.1,559.8l1.1-0.5l0.7-0.6l0.3-0.7 M849.2,557.3l-0.1-0.2l-0.1-0.1 M780.6,571l8-11.9l9.4-9.9l10.1-7.1l10.2-4
	l9.7-0.5l6.8,2.4 M710.8,473.1l-8.5-1.4h-8.2l-7.7,1.4l-5.9,2.4 M786.7,552.6l1.7-2.8l0.9-2.4l-0.1-1.6 M740.2,534.9v39.4
	 M768.8,597.9l21.4,10.7 M680.8,494.6l5.4,0.8 M724.4,516.5l0.3-2.5l0.9-2.2l1.3-1.6l1.5-1.2l1.7-0.7l1.9-0.2l2.1,0.4l2,1l2,1.7
	l1.6,2.3l1.1,2.9l0.5,3.4l-0.1,2.6 M740.2,534.9l8.7,14l7.1,15.3l4.8,14.7 M697.1,479.9l-8.5-1.4h-8.2l-7.7,1.5l-6,2.4 M861.7,601.2
	l0.3-6 M806.9,501.3l3.7-0.3l4,1.1l4,2.5l3.8,3.7l3.3,4.6l2.5,5.2l1.6,5.4l0.5,5.2l-0.4,4 M832.1,535.2l0.8-2l0.7-5l-0.5-5.6
	l-1.7-5.9l-2.7-5.6l-3.6-4.9l-4.2-3.9l-4.4-2.6l-4.3-1.1l-3.9,0.4 M829.1,532l0.3-3.3l-0.5-5.1l-1.5-5.3l-2.5-5.1l-3.2-4.5l-3.7-3.6
	l-4-2.5l-3.9-1.1l-3.1,0.2 M674,594.2l2-3.1 M687.4,581.9V581l0.4-1.2l0.8-0.7l1.1-0.1l1.3,0.6l0.4,0.3 M676.3,586.7l-0.3-0.9
	l-0.6-3.6v-0.7 M674.8,578.3l-4-2.9l-3.1-1.4 M665.7,573.8l3.2,1.4l4.3,3.2 M672.7,578.8l-4.3-3.3l-4.4-1.9l-4.2-0.3l-2.8,0.8
	 M664.4,573.4l3.1,0.3l4,1.6l3.9,2.9 M681,585.1l2.5,5.1l1.5,5.3l0.5,5.1l-0.6,4.6l-1.6,3.7l-0.8,1 M684.1,606.9l0.3-1.2l0.6-4.6
	l-0.5-5.2l-1.6-5.4l-2.5-5.1 M678.9,585.5l2.8,5.6l1.8,5.9l0.6,5.6l-0.1,2.4 M677.5,615.5l0.8-0.5l2.8-2.8l1.8-4.2l0.6-5.1l-0.6-5.7
	l-1.8-5.9l-2.8-5.6 M791.1,593.5v-2.2 M794.7,595.3V593 M798.3,593.5v-2.2 M796.9,596.4v-2.2 M792.4,596.4v-2.2 M796.9,594.8
	l-1.5,0.8 M794.1,595.6l-1.5-0.8 M798.3,597.1l2.2-1.1 M794.7,595.3l3.7,1.8 M791.1,597.1l3.7-1.8 M788.9,596l2.2,1.1 M792.4,594.2
	l-3.7,1.8 M788.9,592.4l3.7,1.8 M791.1,591.3l-2.2,1.1 M794.7,593l-3.7-1.8 M798.3,591.3l-3.7,1.8 M800.6,592.4l-2.2-1.1
	 M796.9,594.2l3.7-1.8 M800.6,596l-3.7-1.8 M807.3,594.9l-0.4,1.6l-1.9,2L802,600l-4,0.9l-4.3,0.2l-4.2-0.6l-3.6-1.3l-2.5-1.8
	l-1.1-2.1l-0.1-0.5 M803.6,589.7l2.5,1.8l1.1,2.1l-0.3,2.2l-1.9,2l-3.1,1.5l-4,0.9l-4.3,0.2l-4.2-0.6l-3.6-1.3l-2.5-1.8l-1.1-2.1
	l0.3-2.2l1.9-2l3.1-1.5l4-0.9l4.3-0.2l4.2,0.6L803.6,589.7 M843.5,566.8v-1.9 M847.8,569.2v-2.8 M850.7,567v-2.8 M850.4,569.2v-1.9
	 M846,570.4v-2.8 M850.4,567.3l-1.7,1.3 M847.7,569.2l-1.7-0.6 M852.9,570l1.8-1.3 M848.6,568.6l4.2,1.4 M845.7,570.7l2.9-2.1
	 M843.1,569.8l2.6,0.8 M846,567.6l-2.9,2.1 M841.7,566.2l4.2,1.4 M843.5,564.9l-1.8,1.3 M847.8,566.4l-4.2-1.4 M850.7,564.2
	l-2.9,2.1 M853.3,565.1l-2.6-0.8 M850.4,567.2l2.9-2.1 M854.7,568.7l-4.2-1.4 M837.9,571.8l-1.9-2l-0.5-1.7 M860.8,568.1v0.4
	l-1.1,2.1l-2.5,1.8l-3.6,1.3l-4.2,0.6l-4.3-0.2l-4-0.9l-0.2-0.1 M855.3,562.2l3.1,1.5l1.9,2l0.4,2.2l-1.1,2.1l-2.5,1.8l-3.6,1.3
	l-4.2,0.6l-4.3-0.2l-4-0.9l-3.1-1.5l-1.9-2l-0.4-2.2l1.1-2.1l2.5-1.8l3.6-1.3l4.2-0.6l4.3,0.2L855.3,562.2 M827,637.8l-0.2,1.1
	l-0.7,1.1l-1.1,1l-1.4,0.8 M777.9,658.8v-2 M783.9,661.7l-6-3 M658.4,643.8l0.3,1.1l0.7,1.1l1.1,1l1.4,0.8 M657.5,603.1l1,0.9l1.7,1
	 M636.6,541.5l0.2,1.4l1.6,2l1.7,1.1 M640.2,546l12.5,6.2 M849.6,527.1l-1.4-1l-0.5-1.3l0.5-1.3l1.4-1l2.1-0.7l2.5-0.3l2.5,0.3
	l2.1,0.7l1.4,1l0.5,1.3l-0.5,1.3l-1.4,1l-2.1,0.7l-2.5,0.3l-2.5-0.3L849.6,527.1 M846.7,528.4l-2.3-1.7l-0.8-2l0.8-2l2.3-1.7
	l3.4-1.1l4-0.4l4,0.4l3.4,1.1l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4L846.7,528.4 M843.6,530.2l0.8,2l2.3,1.7
	 M846.7,533.9l3.4,1.1l4,0.4l4-0.4l3.4-1.1l2.3-1.7l0.8-2 M864.3,523.5l1.7,0.8l3,2l1.6,2.4l0.2,2.5l-1.3,2.4l-2.6,2.1l-3.8,1.6
	l-4.6,1l-5,0.3l-4.9-0.4l-4.4-1.2l-3.6-1.8l-2.4-2.2l-0.9-2.5l0.6-2.5l2-2.3l3.2-1.9l0.8-0.3 M870.5,575.9l0.3-1l0.1-0.9 M867.5,596
	v-13.7 M661.7,647.9l8.8,4.4 M670.5,652.3v7.3 M670.5,659.6l50.7,25.3 M777.3,665l-39.8,19.9 M777.3,657v8 M783.9,653.7l-6.5,3.2
	 M783.9,661.7v-8 M823.6,641.8l-39.8,19.9 M737.5,637.1l3-1.4 M861.1,592.8l0.2-0.1l1.2-0.7l1-0.8l0.8-0.8l0.5-0.8l0.3-0.9l0.1-0.6
	 M737.5,684.9v-47.8 M721.1,684.9v-47.8 M721.1,684.9l1.8,0.7l2,0.5l2.2,0.3l2.3,0.1l2.3-0.1l2.2-0.3l2-0.5l1.8-0.7 M737.5,637.1
	l-1.8,0.7l-2,0.5l-2.2,0.3l-2.3,0.1l-2.3-0.1l-2.2-0.3l-2-0.5l-1.8-0.7 M778.3,650.6l-2,1.6l-3.1,2.1l-3.1,1.7 M833.5,537.7
	l-0.5-0.1l-0.3-0.1 M678,596.4l-1.4-2 M702.2,599.4l-1.1,2.3l-2.5,2.8l-3.3,1.4l-3.3,0.1 M703.2,592.9l-0.3,3.1 M778.2,639.4
	l-0.6-0.7 M739,634.7L739,634.7 M700.4,614.9l2.4-0.8l0.7-0.3 M679,614.4l40.6,20.3 M660.3,604.9l4.4,2.2 M706.9,611.8l-2.1,1.4
	 M736.3,635.7l0.6-0.2l2-0.8 M719.7,634.7l2,0.8l2.4,0.6l2.5,0.4l2.6,0.2l2.6-0.2l2.5-0.4l0.4-0.1 M702.2,606l0.1,0.8l0.5,1.4l1,1.4
	l1.4,1.3l1.9,1.1 M778.9,637l-0.5-0.1h-0.5l-0.5,0.2l-0.4,0.3 M740.5,635.6v-0.4v-0.1 M740.5,656.8v-21.2 M771.9,625.1h-0.4
	l-0.5,0.1 M770.9,622.9h-0.4L770,623 M834.9,626.6l-29.8,14.9 M808.2,618.8l1.2,2.3l2.9,3.3l2,1.3 M770.9,622.9l1,2.2 M740.5,656.8
	l0.8,5.1l1.7,3l2.2,1.4l2.7-0.1 M777.6,638.7l-0.6-1.3 M777,637.3l-0.7-1.4l-2-3.7l-3.4-7 M770.9,625.2L770,623 M805.1,641.5
	l-0.3,0.9l-0.6,0.8l-0.8,0.7l-1.1,0.7l-1.3,0.5l-1.4,0.4l-1.5,0.3l-1.6,0.2 M805.1,620.3v21.2 M801.5,622.2l8.5-4.2 M810,618
	l1.9,4.5l2.9,3.3l3.7,2l4.3,0.5l4.7-1l4.3-2 M858.3,586.9v4.5 M822.2,620.1l-2-0.8l-2.8-2.6l-1.8-3.8l-0.6-4.4 M772.4,619.3
	l-3.6,1.8 M778.3,622.3l-6-3 M772.4,612.1v7.3 M784.3,648.9l3,1.4 M796.2,645.9l-8.9,4.4 M796.2,624.9V646 M787.3,619.5v30.9
	 M778.3,618v34.6 M781.3,654.1l-3-1.4 M784.3,648.9V618 M781.3,654.1v-34.6 M798,645.7l-16.6,8.3 M835.5,627l-30.8,15.4
	 M789.5,620.6v5 M801.5,622.2l-0.9,0.8l-1.2,0.7l-1.4,0.6l-1.5,0.5l-1.6,0.4l-1.7,0.3l-1.8,0.2h-1.8 M801.5,615.3v6.8 M730.4,627.4
	l0.3,1.8l1.6,2.1 M768.8,631.4l-3,1.4 M768.8,587.7v43.7 M765.8,589.2v43.7 M765.8,632.8l-3-1.4 M759,633.3l3.8-2 M762.9,631.4
	v-43.7 M707,611.9v-7.3 M732.3,624.5L707,611.9 M732.3,624.5v7.3 M732.5,631.9l-0.2-0.1 M732.3,588.1v29.1 M738.6,634.6l-3.3,1.7
	 M735.3,592.6v43.7 M732.5,634.9l0.2-43.6 M735.3,636.2l-2.8-1.4 M742.5,580.6l2.1,0.7l2.5,0.3l2.5-0.3l2.1-0.7l1.4-1l0.5-1.3
	l-0.5-1.3l-1.4-1l-2.1-0.7l-2.5-0.3l-2.5,0.3l-2.1,0.7l-1.4,1l-0.5,1.3l0.5,1.3L742.5,580.6 M739.7,581.9l-2.3-1.7l-0.8-2l0.8-2
	l2.3-1.7l3.4-1.1l4-0.4l4,0.4l3.4,1.1l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.4,1.1l-4,0.4l-4-0.4L739.7,581.9 M703.2,592.9l2,1
	 M705.1,593.9l-1.4,1.3l-1,1.4l-0.5,1.4v1.4l0.5,1.4l1,1.4l1.4,1.3l1.9,1.1 M707,604.6l25.2,12.7 M732.3,616.8l-0.4-0.4l-0.7-0.8
	l-0.5-0.9l-0.3-0.8l-0.1-0.8l0,0 M730.4,609.7l-15.6-7.8 M714.3,600.3l-0.3,0.2l-0.2,0.2l-0.1,0.2v0.2l0.1,0.2l0.2,0.3l0.2,0.2
	l0.3,0.2l0.3,0.2 M768.8,613.9l3.6-1.8 M772.4,612.1l8.9,4.4 M781.3,616.5l-3,1.4 M781.3,619.5l-3-1.4 M784.3,618l-3,1.4
	 M787.3,619.5l-3-1.4 M738.6,590.9v43.7 M759,633.3l-2.1,0.9l-2.4,0.7l-2.5,0.5l-2.7,0.3l-2.8,0.1l-2.7-0.2l-2.6-0.4l-2.5-0.6
	 M759,633.3v-43.7 M736.7,583.7l0.8,2l2.3,1.7 M739.7,587.4l3.4,1.1l4,0.4l4-0.4l3.4-1.1l2.3-1.7l0.8-2 M762.9,587.7l3,1.4
	 M768.8,587.7l-3,1.4 M763.7,585.1l5.1,2.5 M757.4,577l1.6,0.7l3.1,2.2l1.6,2.5l-0.1,2.7 M732.7,587.9l-2-2.5l-0.3-2.7l1.4-2.6
	l3-2.2l2-0.9 M732.3,588.1l0.3-0.2 M735.7,589.8l-3.3-1.7 M732.7,591.3l3-1.4 M732.7,591.3l2.6,1.3 M735.3,592.6l3.3-1.7 M759,589.6
	l-2.1,0.9l-2.4,0.7l-2.5,0.5l-2.7,0.3l-2.8,0.1l-2.7-0.2l-2.6-0.4l-2.5-0.6 M762.9,587.7l-3.8,2 M725.8,569v1.8 M729.4,570.8v1.8
	 M732.9,569v1.8 M731.2,571.6v1.8 M727.6,571.6v1.8 M734.7,573.4l-1.8,0.8 M732.9,574.3l-3.6-1.8 M729.4,572.6l-3.6,1.8
	 M725.8,574.3l-1.8-0.9 M724,573.4l3.6-1.8 M727.6,571.6l-3.6-1.8 M724,569.8l1.8-0.9 M725.8,569l3.6,1.8 M729.4,570.8l3.6-1.8
	 M732.9,569l1.8,0.9 M734.7,569.8l-3.6,1.8 M731.2,571.6l3.6,1.8 M718.4,578.9v0.3l0.9,2l2.3,1.6 M721.6,582.8l3.3,1.1l3.9,0.4h1.5
	 M723.1,574.8l2.9,0.9l3.4,0.3l3.4-0.3l2.9-0.9l1.9-1.4l0.7-1.7l-0.7-1.7l-1.9-1.4l-2.9-0.9l-3.4-0.3l-3.4,0.3l-2.9,0.9l-1.9,1.4
	l-0.7,1.7l0.7,1.7L723.1,574.8 M826.1,537.1l0.3-0.7l0.1-0.5 M665.4,539.7v1.5 M669.6,541v2.2 M672.4,539v2.2 M671.7,541.8v1.5
	 M668.2,542.1v2.2 M675.8,543.2l-1.4,1 M674.5,544.3l-4.2-1.4 M670.2,542.8l-2.8,2.1 M667.4,544.9l-2.1-0.7 M665.4,544.2l2.8-2.1
	 M668.2,542.1l-4.2-1.4 M664,540.7l1.4-1 M665.4,539.7l4.2,1.4 M669.6,541l2.8-2.1 M672.4,539l2.1,0.7 M674.5,539.7l-2.8,2.1
	 M671.7,541.8l4.2,1.4 M659,549.2l0.3,1.3l1.6,1.9l2.9,1.4 M663.8,553.8l3.7,0.8l4,0.1l2.7-0.3 M659,543.9v-0.2 M801.8,462.9
	l-0.3-7.1 M801.1,465l0.4,3.6v0.4 M799.1,462.7L799.1,462.7L799.1,462.7l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1v-0.1
	 M794.3,461l1.1,1.8l1,0.9l0.6,0.2l0.3-0.1l0.3-0.2l0.3-0.3l0.3-0.3l0.5-0.9 M787.1,483.5V483v-0.5l0.1-0.7l0.1-0.3v-0.2v-0.1v-0.1
	l0,0l0,0l0,0l0,0l0,0 M787.3,481l0.1-0.4l0.1-0.4l0.1-0.4l0.1-0.4l0.2-0.4l0.2-0.4l0.2-0.3l0.2-0.3 M780,481.3l0.5-1l0.5-1.4
	l0.4-1.6l0.3-1.4 M777.5,476.4l0.2,1.4l0.6,1.4l0.8,1.3l0.9,0.8 M791,478.4l0.8,1.2l1,1.4l1.6,2.8 M787.1,483.5l0.6-0.3l0.5-0.4
	l0.6-0.6l0.6-0.7l0.5-0.7l0.5-0.9l0.3-0.8l0.3-0.9 M794.7,487.8l-0.3-0.3l-0.3-0.4l-0.2-0.5L794,486v-0.6l0.1-0.6l0.2-0.6l0.3-0.6
	 M788.3,477.9l1.4,0.2l1.3,0.3 M792.7,470.7l-1.1-3.1l-0.3-0.3l-0.1-0.1h-0.1h-0.1H791h-0.1l-0.1,0.1l-0.2,0.3l-0.2,3.6l0.3,7
	 M783.4,451.2l1.8,0.6l2.1,0.2l2.1-0.2l1.8-0.6l1.2-0.9l0.4-1l-0.4-1l-1.2-0.8 M791.2,447.4l-1.8-0.6l-2.1-0.2l-2,0.2l-1.8,0.6
	l-1.2,0.9l-0.4,1.1l0.4,1l1.2,0.9 M794.8,445.5l2.3,1.7l0.9,2l-0.9,2l-2.3,1.7l-3.5,1.2l-4.1,0.4l-4.1-0.4l-3.5-1.2 M779.7,453.1
	l-2.3-1.7l-0.9-2l0.9-2l2.3-1.7l3.5-1.2l4.1-0.4l4.1,0.4l3.5,1.2 M781.8,475.8l-1.1-0.1l-1.3,0.1l-1.1,0.3l-0.9,0.3 M781.8,475.8
	l3.6,1.1l2.9,1 M788.3,477.9l1.2-8.8l0.8-1.8l0.3-0.3l0.3-0.2l0.2-0.1h0.2l0.3,0.1 M781.3,462.5l0.5,13.3 M652.7,563.8l0.3,1.7
	l1,1.6l1.6,1.5l2.1,1.3 M799.1,462.7l-0.7,1l-0.8,0.6l-0.8,0.2l-0.8-0.1l-1.4-0.9l-1.5-1.9 M791.8,466.8l-1.5-3.8 M791.8,466.8l1,4
	 M794.5,483.7l-1.8-13 M804.4,498.2l0.2,0.4l0.1,0.5 M804.4,498.4l-0.1-0.2l0,0 M709.1,458.7l1.8,0.6l2.1,0.2l2.1-0.2l1.8-0.6
	l1.2-0.9l0.4-1l-0.4-1l-1.2-0.9 M716.9,454.8l-1.8-0.6L713,454l-2.1,0.2l-1.8,0.6l-1.2,0.9l-0.4,1l0.4,1l1.2,0.9 M705.4,460.5
	l-2.3-1.7l-0.9-2l0.9-2l2.3-1.7l3.5-1.2l4.1-0.4l4.1,0.4l3.5,1.2 M720.5,453l2.3,1.7l0.9,2l-0.9,2l-2.3,1.7l-3.5,1.2l-4.1,0.4
	l-4.1-0.4l-3.5-1.2 M657.7,569.8l6.2,3.1 M657.7,557.5v12.4 M664.2,573.1l0.6-6l1.7-5.2 M659.5,554.5l-3.1-2.3l-0.9-1.4l-0.3-1.5
	l0.3-1.5l0.9-1.4l3.1-2.3 M668.2,558.7l-0.1,0.3 M667.8,558.9l0.3-0.2 M668.2,558.7l-8.7-4.3 M659.5,554.5l-0.3,0.3l-0.3,0.3
	l-0.3,0.3l-0.3,0.4l-0.3,0.4l-0.2,0.4l-0.1,0.4v0.4 M666.5,561.9l-8.8-4.3 M716.6,469.5l1,2 M666.5,561.9l0.2-0.2l0.1-0.3l0.1-0.3
	l0.1-0.5l0.3-0.9l0.3-0.4l0.4-0.3 M666.5,561.9l0.3,0.3l0.2,0.3 M668.1,559l-0.1,0.3l0,0l0,0l0,0v0.1l-0.1,0.3 M667.8,558.9l0.3,0.2
	 M698.3,470.5l-0.2,0.2l-0.8,0.3l-0.9,0.1 M851.2,558.5h-0.3h-0.3l-0.3-0.1l-0.3-0.2l-0.3-0.2l-0.2-0.2l-0.2-0.3l-0.1-0.3
	 M847.1,559.8l-1.1,0.5 M779.9,481.3l-1-0.9l-0.8-1.2l-0.6-1.4l-0.3-1.4 M848.5,556.7l2.7,1.8 M794.7,487.8l-1.5-0.9l-1.8-0.9
	l-2.2-1.2l-2.1-1.3 M787.1,483.5l-3.5-1.2l-3.5-1.1 M780,481.3L780,481.3 M817.8,517.8l-2.2-3.7l-4-5l-4.1-5.2l-1.7-2.6l-1.2-2.4
	l-0.3-0.9l-0.1-0.5l0.1-0.1l0.2,0.4l0.1,0.5 M777.3,476.5L777.3,476.5 M777.5,476.4l-0.7-13.5 M677.9,548.1l0.3,0.8l0.1,0.8v0.8
	l-0.2,0.8l-0.3,0.7l-0.3,0.6l-0.4,0.5l-0.5,0.3 M679.8,552.8l-3.2,0.5 M679.6,553.1l0.2-0.3 M682.8,549.1l-0.3,1.2l-0.6,1.1
	l-0.9,0.8l-1.1,0.5 M681.6,547.5l0.2,1.5l-0.3,1.6l-0.7,1.4l-1,0.9 M666.3,497.5H666h-0.3l-0.3-0.1l-0.3-0.1 M681,485.2v1.1
	l-0.1,1.2l-0.2,1.4l-0.3,2.6l-0.1,2.6 M680.4,494l-0.1,0.2v0.2l0.1,0.2l0.2,0.2 M677.9,548.1l3.7-0.6 M705.4,466l-2.3-1.7l-0.9-2
	 M723.7,462.1l-0.8,2l-2.3,1.7l-3.5,1.2l-4.1,0.4l-4.1-0.4l-3.5-1.2 M793.1,461.5l1.2-0.4 M794.3,461l1.4-0.4l1.3-0.5l1.2-0.6l1-0.7
	l0.8-0.7l0.7-0.8l0.5-0.8l0.3-0.8 M798,454l3.6,1.8 M798,454.8l-0.1,0.9l-1,1.6l-2,1.4l-2.7,1l-3.2,0.5h-3.4l-3.2-0.5l-2.7-1
	 M779.7,458.6l-2-1.4l-1-1.6l-0.2-0.9 M776.5,449.9l-0.9,0.6l-0.9,0.7 M774.7,451.3l-0.9,0.6 M773.8,451.9l-2.9,1.4 M770.9,453.3
	l-0.2,0.6l-0.1,0.6v0.6l0.1,0.6l0.2,0.6l0.3,0.6l0.3,0.5l0.3,0.5 M771.8,457.7l0.3,0.6l-0.1,0.6l-0.4,0.6l-0.7,0.5 M770.8,460
	l-10.5,5.3 M760.3,465.3l-0.8,0.3l-1,0.2l-1.1,0.1l-1.1-0.1 M756.2,465.8l-25.5-3.4 M730.8,462.4l-1.1-0.3l-0.9-0.3l-0.7-0.5
	l-0.3-0.5 M702.3,456.9l-0.4,0.3l-2.6,2l-1.3,2.3l0.3,2.4l1.7,2.2 M727.8,460.8l-1.6-2.2l-2.5-1.6 M700,466l3.9,2.2l2.9,0.8l3.2,0.5
	l4.2,0.1l2.4-0.2 M716.6,469.5l1.1-0.2l1.1-0.2l1-0.3l1-0.3l0.9-0.3l0.9-0.3l0.9-0.4l0.8-0.4 M724.4,467.1l0.8-0.4l1-0.3l1.2-0.1
	l1.2,0.1 M728.6,466.5l16.1,2.1 M744.8,468.6l1.1,0.3l0.9,0.4l0.5,0.4l0.3,0.4l0.2,0.4l-0.1,0.4l-0.4,0.6l-0.8,0.5 M746.6,472.1
	l-13,6.5 M738.5,482.3l0.4-0.4l0.6-0.3 M739.6,481.5l37.2-18.6 M776.8,463l0.9-0.3l1.1-0.2h1.2l1.2,0.2 M781.3,462.5l1.1,0.2
	l1.1,0.2l1.1,0.1l1.1,0.1h1.1h1.1l1.1-0.1l1.1-0.1 M790.2,462.9l2.9-1.4 M681.6,547.5l0.5-22.4 M682.5,548.2l-0.4-0.4l-0.6-0.3
	 M846.4,561.3L846.4,561.3l-0.3-0.4l-0.1-0.3v-0.1 M846,560.4v-3 M718.1,569.7l0.2,0.9l0.3,0.8 M851.2,558.5l17.5,8.8 M841.7,573.7
	l1.6,0.4l4.3,0.5l4.4-0.3l3.9-1.1l2.1-1.2 M679.6,553.1l1.2-0.5l1-0.8l0.7-1.2l0.3-1.3 M698.6,557.2l-0.2,0.6l-0.2,0.6l-0.2,0.7
	l-0.2,0.7l-0.1,0.6l-0.1,0.6l-0.1,0.6l-0.1,0.5 M709.9,596.1l0.4,0.1l0.3,0.2 M699.5,557.5l-0.3,1.2l-0.3,1.3l-0.2,1.3l-0.1,1
	 M708.5,582.8L708.5,582.8h-0.2h-0.1h-0.1H708H708h-0.1h-0.1 M709.6,579.9L709.6,579.9L709.6,579.9l0.2,0.1h0.1h0.1h0.1h0.1h0.1
	 M698.8,562.3l1.2-0.8l1.3-1l1.1-1.2l0.7-1.1 M718.7,571.4l-4.8,2.4 M701.9,566l-3.1-3.7 M698.8,562.3l-1.1-0.2 M708.5,578.7
	l-0.1,1.2 M710.8,596.4l4.9,2.5 M708.5,582.8l1.7,6.9l0.6,6.6 M706.4,562.4l-0.3,0.4l-0.5,0.5l-0.6,0.5l-0.6,0.5l-0.7,0.5l-0.7,0.4
	l-0.7,0.4l-0.6,0.3 M713.9,573.8l-1,0.3l-1.2-0.2l-2.7-1.3l-3.2-2.6l-1.9-1.9l-1.9-2.2 M713.4,572.1v0.3l0.1,0.3l0.1,0.3l0.1,0.3
	l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3 M710.3,579.8l-0.3,0.3 M710,580l-0.3,0.3l-0.3,0.3l-0.4,0.4l-0.6,0.8l-0.1,0.3v0.3v0.3l0.1,0.3
	 M717.1,587.2l-8.8-4.3 M677.9,548.1l4.2-22.9 M703.2,592.9l6,3 M673,568.4l0.2-0.3l2.5-2.8l3.3-1.4h3.9l4.2,1.4l4.2,2.7l3.9,3.9
	l3.3,4.8l2.5,5.4l1.6,5.5l0.5,5.4 M682.1,525.1l1.4-12.2 M701.4,557.6l-0.9-0.2h-0.9 M702.9,558.2l-0.2-0.1l-0.2-0.1l-0.2-0.1
	l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.3-0.1 M709.1,595.9l0.6,0.2l0.3,0.1 M708.5,578.7l-4.8-9l-6.1-7.6 M697.6,562l-2.9-1.4
	l-6.5-2.5l-4-2.4l-2.5-1.6l-2-1 M679.6,553.1l-6.1,1.5l-4.8,4.2 M668.8,558.9l-0.4,0.3l-0.3,0.4l-0.5,1.1l-0.3,1l-0.3,0.8
	 M666.9,562.5l-0.5,1.2l-0.4,1.2l-0.4,1.3l-0.3,1.4l-0.3,1.4l-0.2,1.4l-0.1,1.5v0.8 M709.9,596.1v-1.6l-0.1-1.6l-0.2-1.6l-0.3-1.6
	l-0.3-1.7l-0.4-1.7l-0.4-1.7l-0.5-1.7 M707.7,582.9l-0.2-0.9v-0.8l0.3-1.2l0.3-0.8l0.3-0.6 M703.6,535.9l-0.8,22.2 M702.9,558.2
	l3.6,4.2 M708.4,580h0.4h0.1l0,0l0,0l0,0h0.6 M710.3,579.8h-0.7 M718.9,584.1l-8.7-4.3 M708.4,580l10-5 M718.9,584.1l-0.3,0.3
	l-0.3,0.3l-0.3,0.3l-0.3,0.4l-0.3,0.4l-0.2,0.4l-0.1,0.4v0.4 M790.3,545.8l0.3-0.2 M697.1,479.9l13.7-6.9 M709.7,506.9L709.7,506.9
	L709.7,506.9L709.7,506.9L709.7,506.9L709.7,506.9l0.2,0.2 M710,507.2l1.3-0.3l1.6-0.1h1.2l0.8,0.1l0.8,0.2 M715.7,507l-0.8-0.3
	l-0.7-0.2l-0.9-0.2l-0.8-0.1h-1l-0.7,0.1l-0.7,0.2l-0.6,0.2 M709.6,506.8l0.2,0.2 M710.2,509.9l1.4-0.5l1.6-0.3l1.6-0.1l1.4,0.2
	 M721.1,568.8l-0.9,0.1l-0.8,0.2l-0.8,0.3l-0.6,0.3 M789.2,553.8h-0.6l-0.7-0.3l-0.7-0.4l-0.5-0.5 M703.6,535.9l-0.3-0.2l-0.4-0.2
	l-0.4-0.1l-0.4-0.1l-0.4-0.1l-0.5-0.1h-0.5H700 M706.7,508.8L706.7,508.8v-0.2v-0.1v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1
	 M716.2,502.9l-0.7-0.3l-0.8-0.2l-0.9-0.1H713l-1.7,0.3l-1.4,0.7 M694.3,495.8l-1.2-0.9l-0.4-1l0.4-1l1.2-0.8l1.8-0.6l2.1-0.2
	l2.1,0.2l1.8,0.6 M702,491.9l1.2,0.8l0.4,1l-0.4,1l-1.2,0.9l-1.8,0.6l-2.1,0.2l-2.1-0.2l-1.8-0.6 M690.5,497.7l-2.3-1.7l-0.9-2
	l0.9-2l2.3-1.7l3.5-1.2l4.1-0.4l4.1,0.4l3.5,1.2 M705.7,490l2.3,1.7l0.8,2l-0.8,2l-2.3,1.7l-3.5,1.2l-4.1,0.4l-4.1-0.4l-3.5-1.2
	 M696.4,504.6l2.2,0.1l2.2-0.2l2.1-0.4l1.9-0.6l1.6-0.8l1.2-0.9l0.9-1l0.3-1.1 M687.5,499.3l0.2,0.9 M687.5,500.1l-3.7-1.9
	 M696.4,504.6l3.7,1.9 M690.5,513.1l0.3,0.1h0.3l0.5-0.2l0.9-0.7l1.5-2.1l2.4-5.6 M687.5,500.1l0.7,7.2l0.8,3.2l0.6,1.4l0.4,0.6
	l0.3,0.3l0.3,0.2 M713.4,572.1l4.8-2.4 M718.1,569.7l-8-59.8 M710.2,509.9l-0.2-2.7 M710,507.2l-0.8,1.1l-0.8,0.6l-0.8,0.2l-0.9-0.2
	 M790.7,545.6l1.4,1l0.2,1.8l-1,2.5l-2,3 M789.2,553.8l-2.9,4.3 M771.9,594.4l-0.3,0.9 M682.7,549.3v0.4v0.1 M698.6,557.2l0.9,0.3
	 M699.5,557.5l0.6-22.2 M700.1,535.2l1.3-16.4l1.6-6.7l1.5-3.1l0.5-0.6l0.5-0.4l0.4-0.2l0.3-0.1l0.3,0.1l0.3,0.2 M707,508l0.7,0.3
	h0.3l0.3-0.1l0.3-0.3l0.3-0.3l0.6-0.9 M709.6,506.8l0.3-3.3 M709.9,503.5l-0.9,0.6l-1,0.5l-1.1,0.5l-1.2,0.4l-1.3,0.3l-1.4,0.3
	l-1.4,0.3l-1.4,0.2 M700.1,506.4l-3,11.6l-2.5,7.9l-2.1,5.2l-1.4,2.5l-1.3,1.6l-1.2,0.7l-0.5,0.1l-0.5-0.2l-0.5-0.3l-0.5-0.6
	l-0.9-1.7l-0.8-2.6l-0.6-3.5l-0.6-6.5l-0.3-9.4l0.3-12.9 M683.9,498.4l0.3-0.8l0.4-0.7l0.6-0.7l0.7-0.7l0.9-0.6l0.8-0.4
	 M703.6,535.9l2.8,26.4 M706.4,562.4l2.3,4.4l0.6,1.1l1.3,2l1.5,1.9l0.7,0.4h0.3l0.3-0.1 M706.7,508.8l6.7,63.2 M706.7,508.8
	l-0.3-0.3l-0.3-0.1h-0.1H706l-0.1,0.1l-0.1,0.1l-0.2,0.2l-0.2,0.2l-0.3,0.5l-0.9,3.1l-0.7,6.7l0.1,16.7 M780.6,571l-0.6-0.1
	l-0.7-0.2l-0.7-0.2l-0.5-0.3 M835.5,547.1v20.3 M827.2,566.4l-3.5-1.1l-4.7,0.2l-4.9,1.8l-4.9,3.2l-4.7,4.5l-4.1,5.5l-3.3,6.2
	 M832.4,569l3.2-1.6 M777.6,572.7l0.5,0.2l0.6,0.2l0.7,0.2l0.6,0.1 M781.4,568.1l-0.6-0.1l-0.7-0.2l-0.6-0.3l-0.5-0.3 M786.4,558.1
	h-0.6l-0.3-0.1l-0.3-0.2l-0.3-0.2l-0.3-0.3l-0.6-0.5 M816.2,537.4l1.3-0.3l2.7-0.3 M786.4,558.1l-4.9,10 M771.9,594.4l-0.4-0.3
	l-0.5-0.3 M778.9,576.5l-0.5-0.1l-0.5-0.2l-0.5-0.2l-0.4-0.2 M834.1,539.8l0.6,0.3 M781.4,568.1l-0.9,2.9 M780.4,595l1.7-5.9
	 M778.9,576.5l-3.4,9.3l-3.7,8.7 M771.9,594.4l1.2,0.5l1.3,0.4l1.4,0.3l1.4,0.3 M792.5,587.9v-19.3 M782.1,589.1l0.3-6.9l0.5-3.9
	 M782.1,589.1v5 M740.3,522.8v4.3l-0.4,7.2 M739.9,534.3l-0.1,0.2v0.2l0.1,0.2l0.2,0.2 M725.7,516.6h-0.3H725l-0.3-0.1l-0.3-0.1
	 M792.5,568.7l4.4-5.7l4.9-5.2l6-4.9l6.2-3.8l6.2-2.5l5.9-1l4.9,0.3l4.4,1.3 M835.5,547.1v-0.7v-0.8l0.1-0.8l0.2-0.8l0.2-0.5
	l0.3-0.5l0.3-0.2l0.3-0.1l0.7,0.1l0.7,0.3l1.3,0.8l1.3,1.2l1.8,2.2l1.5,2.7l1.1,3.1l0.5,3.4v0.8 M846,557.3l1.1,2.5 M849.2,558
	L849.2,558v-0.8 M834.9,540.1l0.5,0.2 M780.6,571l-0.7,2.3 M779.9,573.3l-0.9,3.1 M778.9,576.5l1.1,0.5l1.2,0.6l1.2,0.6l0.4,0.2
	 M782.8,578.3l0.7-3.1l1.1-3.1l0.8-1.4l0.9-1.4l1.1-1.3l1.3-1.1l0.9-0.4l0.9-0.2l0.7,0.1l0.6,0.3l0.3,0.5l0.2,0.4l0.1,0.5v0.5
	 M796.9,586.8l-2.3,1.2 M782.1,594.2l-1.7,0.9 M777.1,595.9l1.8-0.3l1.5-0.6 M777.1,595.9l-1.5,0.1l-1.5-0.1l-1.4-0.3l-1.2-0.4
	 M800.9,588.7l-3.9-2 M813.3,594.9l-7.6-3.8 M784.8,598.8l3.1,1.5l4.1,0.9l4.5,0.1l4.2-0.7l3.5-1.4l0.6-0.3 M792,605.5l-20.5-10.3
	 M771.5,595.3l-0.3-0.2l-0.8-0.1 M710.8,473.1l0.9-0.3l1-0.1l2,0.1l4.8,0.9l6.3,2.2l5.8,2.7l6,3.7l1.6,1.4l1.3,1.8l0.1,0.8l-0.3,0.7
	l-0.6,0.4 M813.3,594.9l3.3-6.2l4.1-5.5l4.7-4.5l4.9-3.2l4.9-1.8l4.7-0.2l4.1,1.4l3.3,2.9 M792,605.5l21.2-10.6 M790.2,608.6v-0.4
	l0.1-0.4l0.2-0.4l0.3-0.4l0.3-0.4l0.3-0.3l0.3-0.3l0.3-0.3 M790.2,620.9l25.2-12.7 M841.1,582.9l-0.9-0.6l-3.6-1l-4.1,0.6l-4.2,2.1
	l-4.1,3.5l-3.6,4.6l-2.8,5.3L816,603l-0.6,5.4 M853.8,589.1l16.7-8.3 M870.5,568.5v12.4 M868.7,567.2l0.3-0.2l0.3-0.1l0.3,0.1
	l0.3,0.2l0.3,0.3l0.2,0.3l0.1,0.3v0.4 M847.4,577.8l21.2-10.6 M739.7,487.6l-13.7,6.9 M777.6,572.7l0.6-2.4 M778.2,570.4l0.8-3
	 M779,567.4l4.8-10.5 M783.8,557l3-4.4 M739.7,487.6l7.2,6l7,6.7l6.6,7.5l6.3,8.1l5.9,8.6l5.3,9.1l4.8,9.4l4.1,9.7 M768.8,610.3
	l21.4,10.7 M790.2,620.9v-12.4 M708.8,498.5v0.7v0.3 M708.8,499.6l7.3,3.2 M716.2,502.9l-0.5,4.2 M715.7,507l0.3,2.2 M716,509.2
	l8.4,7.2 M741.4,522.5v1.2l-0.4,4l-0.5,3.8l-0.3,3.4 M768.8,595.1h1.6 M770.4,595l0.5-1.2 M770.9,593.8l3.3-8.6l1.4-4.6l0.4-1.6
	l0.8-3.1 M777,575.9l0.6-3.1 M777.6,572.7l-3.7-11.6l-4.7-11.3l-5.6-11l-6.4-10.5l-7.1-9.9l-7.6-9l-8.1-8.1l-8.4-7.1 M726,494.4
	l-0.9,0.3l-1,0.1l-2-0.1l-4.8-0.9l-6.3-2.2l-5.8-2.7l-6-3.7l-1.6-1.4l-1.3-1.8l-0.1-0.8l0.3-0.7l0.6-0.4 M636.6,541.5l0.2,1.4l1.6,2
	l1.7,1.1 M665,497.3l-10.3,6.8l-8,11l-4.9,14.4l-1.6,16.5 M665,497.3l0.3-3.4l1.1-3.6l1.9-3.3l2.5-2.6l2.6-1.8l2.5-1.1l2-0.4
	l1.5,0.1l1,0.5l0.6,0.9l0.3,1.3v1.6l-0.2,1.9l-0.3,2.2l-0.2,2.4l-0.1,2.6 M818.2,608.8l0.3-3.4l1.3-5.3l2.3-5.1l3.1-4.6l3.7-3.7
	l0.5-0.3 M877.8,639l-7.2-9.9l-0.3-0.3 M877.8,639l3.9-3.7l3.4-4.7l2.5-5.4l1.4-5.5l0.2-5.2l-1-4.4l-2.2-3.3l-3.1-1.9l-3.8-0.3
	 M877.8,639v0.9 M872.6,606.9l6.5-2.1 M879.2,603.8v0.9 M861.1,630.3l2.8,1.4 M870.4,628.8l-8.6-4.2 M870.4,628.8l2.5-2.7l2-3.3
	l1.4-3.7l0.5-3.6l-0.3-3.2l-1.1-2.5l-1.8-1.7l-0.9-0.4 M863.8,631.7l0.3,0.3l7.2,9.9 M861.1,630.8l0.4,0.3l2.4,0.6 M871.2,642.9
	v-0.9 M872.6,607.7l-3.9,3.7l-3.4,4.7l-2.5,5.4l-1.4,5.5l-0.2,5.2l1,4.4l2.2,3.3l3.1,1.9l3.8,0.3 M872.6,607.7v-0.9 M842.4,583.4
	l-1.1-0.4l-3.7-0.3l-4,1.1 M833.8,583.7l-4,2.5l-3.8,3.7l-3.2,4.6l-2.5,5.2l-1.5,5.4l-0.5,5.2l0.6,4.6l1.7,3.7l2.6,2.5l0.5,0.3
	 M872.7,606.7l-3.8,3.4l-3.3,4.3l-2.6,5l-1.8,5.3l-0.8,5.2l0.3,4.7l1.4,3.9l2.4,2.8l3.1,1.5l3.7,0.1 M872.7,606.7l-17.3-8.7
	 M855.4,598.1l-0.8-0.7l-0.4-0.9l0.1-0.8l0.6-0.8l1-0.5l1.3-0.3l1.5,0.1l1.5,0.3l1.4,0.5l0.4,0.2 M862,595.2l17.3,8.6 M877.8,640
	l3.8-3.4l3.3-4.3l2.6-5l1.8-5.3l0.8-5.2l-0.3-4.7l-1.4-3.9l-2.4-2.8l-3.1-1.5l-3.7-0.1 M877.8,640l-12.4-6.2 M836.7,582.8l-0.6-0.1
	l-3.7,0.3l-3.9,1.7l-3.7,3l-3.4,4.1l-2.7,4.8l-1.8,5.1l-0.8,5l0.3,4.6l1.4,3.7l1.6,2 M815,608.6l0.1,1.1l0.9,4.4l2,3.4l2.8,2.1
	l1.4,0.5 M840.8,582.8l-0.3-0.2l-1.3-0.8 M833.8,536.1l-1.9,0.9 M808.8,498.3l1.9-0.9 M810.6,497.3l4-0.5l4.4,1.1l4.4,2.6l4.2,4
	l3.6,5l2.8,5.7l1.7,5.9l0.5,5.7l-0.7,5.1l-1.9,4.1 M808.2,498.9l0.3-0.4l0.3-0.2 M808.8,498.3l4-0.5l4.4,1.1l4.4,2.6l4.2,4l3.6,5
	l2.8,5.7l1.7,5.9l0.5,5.7l-0.7,5.1l-1.9,4.1 M806.8,501.4L806.8,501.4 M806.9,501.3l1.4-2.5 M692,578.4L692,578.4l-0.1-0.2l-0.1-0.1
	l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1 M691.5,577.7l-1-0.9l-1.2-0.7l-1.3-0.3l-1.3,0.1l-0.4,0.2 M694.7,572.7l-3.3,5
	 M692,578.4l3.3-5 M692.5,579.4l-0.3-0.6l-0.3-0.4 M666.4,594.9l9.3-4.6 M686.3,576l-0.3,0.2 M678.5,598.6l-6.5,3.2 M659.7,601.5
	l0.5-0.3l0.3-0.2 M655.8,602.7l1.6-1.2l9.1-6.6 M666.4,594.9l-1.4-3.6l-0.6-3.6l0.3-3.2l1-2.5l1.4-1.5 M656.9,603.5l2.8-2
	 M667.8,574.1l-1-0.3l-0.7-0.2 M663.7,573.2l0.9,0.2l1.2,0.3 M656.1,604l0.5-0.3l0.3-0.2 M655.8,602.7l-1.5,2.3 M655.8,602.7
	l-2.4-5.1l-1.4-5.3l-0.2-4.9l1-4.2l2-3.1l3-1.8l3.6-0.3l3.9,1.3l3.9,2.7 M654.2,604.9l1.9-0.9 M670.8,579.7l-4.3-3.3l-4.4-1.9
	l-4.2-0.3l-3.7,1.4l-2.8,2.8l-1.8,4.2l-0.6,5.1l0.6,5.7l1.8,5.9l2.8,5.6 M685.9,576l-10.7,5.4 M685.9,576l3.1-4.7 M675.4,578.2
	l-0.4,0.2h-0.2 M674.8,578.3l-1.5,0.2 M673.3,578.5l-0.4,0.1l-0.3,0.1 M672.7,578.8l-1.9,0.9 M670.8,579.7l-1.5,2.3 M675.2,581.4
	l-5,0.4l-0.9,0.1 M681,585.1l13.6-6.8 M677.3,594.5L677.3,594.5l-1.3-0.4h-1.2l-0.8,0.3 M660.4,601l13.6-6.8 M689,571.4l-13.6,6.8
	 M689,571.4l1.1-0.3h1.3l1.4,0.3l1.3,0.7l0.7,0.7 M694.7,572.7L694.7,572.7l0.1,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1
	l0.1,0.1 M695.2,573.4l0.7,1.2l0.3,1.2l-0.3,1.2l-0.6,0.9l-0.6,0.4 M680.4,585.3l0.4-0.1l0.3-0.1 M678.8,603.4l-1.4,0.3l-2.6-0.4
	l-2.7-1.4 M672,601.8l-9.1,6.6l-1.6,1.2 M683.5,608.4l0.7-1.5 M678.9,585.5l1.5-0.2 M683.9,604.9l-0.5,2.7l-1.1,3.1 M678.3,585.7
	l0.4-0.2h0.2 M676.4,586.6l1.9-0.9 M661.3,609.6l-1.5,2.3 M659.8,611.9l4.3,3.3l4.4,1.9l4.2,0.3l3.7-1.4l2.8-2.8l1.8-4.2l0.6-5.1
	l-0.6-5.7l-1.8-5.9l-2.8-5.6 M676.4,586.6l-1.5,2.3 M674.9,588.9l2.4,5.1l1.4,5.3l0.2,4.9l-1,4.2l-2,3.1l-3,1.8l-3.6,0.3l-3.9-1.3
	l-3.9-2.7 M740.4,524.5l-6.8-7.3l-7.5-6.6l-7.7-5.5l-7.8-4.2l-7.8-2.9l-7.7-1.5l-5.7-0.3 M804.7,598.8l0.3-0.3 M784.5,598.6l0.3,0.3
	 M782.1,594.2v0.8 M807.3,594.9v-0.8 M858.1,572.1l0.3-0.3 M835.5,567.5v0.8 M860.8,568.1v-0.7 M827,637.8v-6.5 M658.4,603.8v40
	 M837.3,534.1l-4.6,4.4 M636.5,541.5l-0.1,2l0.6,9.5l1.4,9.5l2,9.3l2.5,9l2.2,6.4l2.6,6.4l4.1,8.9l5.6,10.3l0.8,1.4 M656.3,569v5.4
	 M843.6,524.7v5.4 M864.6,530.2v-5.4 M870.9,573.8v-43.7 M837.3,530.2v11.1 M770.2,655.9l3.1-1.5l5.1-3.7 M865.3,588.1v-4.7
	 M735.4,636l-0.7,0.2l-2.2,0.3l-3.2,0.2 M729.4,636.7l-4-0.3l-0.5-0.2 M724.9,636.3l-0.9-0.2l-2.1-0.6l-1.7-0.7 M756.6,662.9
	l13.7-6.9 M703.4,613.8l1.4-0.7 M671,595.8l2.1,3.7l1,1.4 M683.4,610.2l0.3,0.3l3.6,2.2l3.7,1.6l3.7,0.8l3.8,0.1l1.4-0.3
	 M702.2,598.7v7.3 M804.2,642.8l3.4-0.9l5.9-2.6l5.7-3.5l2.1-1.6 M814.2,625.8l2.5,1.3 M748,666.1l4.8-1.4l3.7-1.9 M730.4,584.8
	l-16.3,15.6 M730.4,623.6v3.8 M736.7,578.3v5.4 M757.7,583.7v-5.4 M764,585.2v-1.6 M730.4,583.7v29.2 M718.4,573.5v5.4 M739.8,571.5
	L739.8,571.5l-1.4-1.8l-2.3-1.4l-3.3-1l-3.7-0.3l-3.7,0.4l-3.3,1.1l-2.6,2l-1,2v1.1 M826.4,535.8v-5.4 M826.4,530.3l-0.4-2l-1.4-1.7
	l-2.4-1.4l-1-0.3 M659,543.7v5.4 M677.9,539.2l-2.7-1.3l-3.6-0.7h-3.7l-3.6,0.8l-3,1.5l-1.7,1.9l-0.6,2.1l0.1,0.3 M827,530.6
	l-0.7-0.3 M770.6,459.9v0.3 M832.7,538.8V538 M832.6,538.1l0.1-0.3l-0.9-2.9l-1.8-2.2l-2.7-1.9l-0.4-0.2 M770.7,454.8v5.4
	 M802,471.9l-0.2-9 M807.1,503.5l-6-37 M776.5,449.3v5.4 M798,454.8v-5.4 M652.7,551.4v12.4 M658.4,543.9l-0.2,0.1l-2.2,1.4
	l-1.8,1.7l-1.2,2.1l-0.3,2.2 M804.3,497.7l-0.1-0.2l-0.1-0.2 M697.9,462l-0.5,8 M723.7,462.1v-5.4 M702.3,456.7v5.4 M667.4,561.4
	l0.3-0.9l0.3-0.7l0.3-0.4l0.2-0.1 M869.8,567.1L849,556.7 M675.6,552.2l-0.5,1.4l-0.3,0.4l0,0 M825.6,537l-0.2-0.7l-1.7-5.1
	l-2.2-5.6l-3-6.5 M804.1,497.3l-0.7-0.8l-4.8-5.1l-3.6-3.2 M674.7,553.9l-1.4,0.7 M680.4,494.2v31.9 M682.5,550.6l-0.2,0.2
	 M680.1,475.7l-13.7,6.9 M797.5,538.4l3.1,0.3 M682.8,549.8l-0.1,0.4l-0.3,0.5 M683.6,508.8l-7.9,43.4 M708.9,498.5v-4.7
	 M687.5,493.9v5.4 M820.2,536.8l-22.7,1.6 M739.8,534.5v40 M683.6,506.3l-0.9,43 M843.5,574.6l-16.3-8.2 M834.9,539.8l-1.1-0.7
	l-5.5-1.9l-5.6-0.6l-5.9,0.5l-1,0.3 M834.9,539.8l-0.7-0.3 M849.1,557.3l-0.6-1.9l-2.4-4.9l-3.1-4.4l-3.9-3.7l-3.7-2.3 M789.2,545.8
	l-0.3-0.8l-4.1-8.9 M784.8,536l-5.6-10.3l-2.5-4.1l-4.4-6.5l-4.8-6.4l-5.4-6.4l-6-6.1l-6.4-5.7l-6.7-5.2l-7.1-4.6l-7.3-3.8l-0.5-0.2
	 M724.6,475.5l-3.4-1.4l-7.6-2.1l-7.6-1.1l-7.6-0.1l-2.7,0.3 M728.1,476.9l-1.4-0.6 M695.8,471.2l-4.7,0.6l-7.1,2.1l-3.6,1.8
	 M666.7,482.5l-3.3,1.6l-6.5,4.7l-6,6l-4.5,6.4l-3.7,7.2l-2.9,8l-2,8.6l-1.1,9l-0.2,7.5 M818.2,608.8v0.8 M830.1,585.9l-0.7,0.5
	 M823.6,621l42.2,21.1 M884.6,604.5l-42.2-21.1 M666.1,573.7l-2.5-0.4 M665.9,574l-2.3-0.8h-4.1l-2,0.8 M656.9,574.2l-1.9,0.9
	 M682.5,563.7l-19.1,9.5 M681.9,611l19.3-9.6 M682.3,610.6l1.2-2.2 M676.8,615.7l1.4-0.6l2.7-2.5l1.9-3.4l1-4.2v-0.5 M675.6,616.4
	l1.9-0.9"/>
</svg>



}

export default FrontBulkhead
