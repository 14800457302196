import "./rearUpperLink.scss"

import React from "react"

const RearUpperLink = ({ explode }) => {
  return explode ? <svg
  className="rearUpperLink"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<g>
	<g id="CT012044" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-rear-camber-links-set", "_blank")}>
		<g>
			<g>
				<path class="st0" d="M838.1,603h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C850.1,597.6,844.7,603,838.1,603z"/>
			</g>
			<polyline class="st1" points="838.9,594.3 841.9,591 838.6,587.7 			"/>
		</g>
		<g>
			<path class="st2" d="M755.5,586.7c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3c0.7,0,1.6-0.2,2.5-0.5
				v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7c0.4-0.8,0.9-1.4,1.6-1.8
				c0.7-0.4,1.5-0.6,2.5-0.6s1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.7-0.3-1.1-0.5S755.8,586.7,755.5,586.7z"/>
			<path class="st2" d="M764,595h-2.1v-8.2h-2.7V585h7.5v1.8H764V595z"/>
			<path class="st2" d="M774.5,590c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S774.5,588.4,774.5,590z M769.6,590c0,1.2,0.1,2.1,0.3,2.6
				s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8c0.2-0.5,0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C769.7,587.9,769.6,588.8,769.6,590z"/>
			<path class="st2" d="M780.8,595h-2.1v-5.8v-1v-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7L780.8,595L780.8,595z"/>
			<path class="st2" d="M790.5,595h-7v-1.5l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3s-0.8,0.1-1.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
				s0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.4s-0.1,0.9-0.2,1.3
				c-0.2,0.4-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4v1.8H790.5z"/>
			<path class="st2" d="M798.5,590c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S798.5,588.4,798.5,590z M793.6,590c0,1.2,0.1,2.1,0.3,2.6
				s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C793.7,587.9,793.6,588.8,793.6,590z"/>
			<path class="st2" d="M806.7,593h-1.2v2.1h-2.1V593h-4.3v-1.5l4.4-6.5h1.9v6.3h1.2L806.7,593L806.7,593z M803.5,591.3v-1.7
				c0-0.3,0-0.7,0-1.2s0-0.9,0.1-0.9h-0.1c-0.2,0.4-0.4,0.7-0.6,1.1l-1.8,2.8L803.5,591.3L803.5,591.3z"/>
			<path class="st2" d="M814.7,593h-1.2v2.1h-2.1V593h-4.3v-1.5l4.4-6.5h1.9v6.3h1.2L814.7,593L814.7,593z M811.4,591.3v-1.7
				c0-0.3,0-0.7,0-1.2s0-0.9,0.1-0.9h-0.1c-0.2,0.4-0.4,0.7-0.6,1.1l-1.8,2.8L811.4,591.3L811.4,591.3z"/>
		</g>
	</g>
	<path class="st3" d="M1045.2,493.3l-0.2,1.1l-0.5,0.7l-0.9,0.2l-1.4-0.3l-0.9-0.4 M1060.6,418.3l-0.5-0.1l-3.3-0.2l-3.3,0.2
		l-3.3,0.6l-3.3,0.9l-3.2,1.3l-3.2,1.6l-3.1,1.9l-2.9,2.2l-2.8,2.5l-2.6,2.7l-2.3,3l-1.6,2.3 M1049.5,497.9l-2.2-2.1l-2.1-2.5
		 M1045.2,447.2l2.1-3.2l2.3-3l2.6-2.7l2.8-2.5l2.9-2.2l3.1-1.9l3.2-1.6l3.2-1.3l3.3-0.9l3.3-0.6l3.3-0.2l3.3,0.2l0.5,0.1
		 M1027,436.9l1-0.5l3.4-1.1l3.3-0.6l3,0.1l2.6,0.7l2,1.4l1.2,1.7l0.8,2l0.6,3.1l0.2,3.5 M1041.3,452.4l0.1-1.1l0.2-2.2l0.1-2.1
		l0.1-2v-1l-0.1-2l-0.2-1.2l-0.6-1.7l-0.4-0.7l-0.8-0.9l-1-0.7l-0.8-0.3l-1.9-0.4l-1.1-0.1l-2.2,0.2h-0.1 M1041.3,494.6l0.3-0.1
		l0.2-0.4v-0.3v-0.7l-0.1-0.9l-0.2-1.1l-0.1-0.7l-0.1-0.3 M927.2,451.8h0.9l1.6,0.2l1.7,0.6l1.7,0.9l1.6,1.3l1.6,1.6l1.5,1.9
		l1.5,2.2l1.4,2.5l1.2,2.7l1.1,2.9l1,3l0.8,3.2l0.6,3.2l0.5,3.3l0.3,3.3l0.1,3.2l-0.1,3.2l-0.3,3l-0.5,2.9l-0.6,2.7L944,502l-1,2.2
		l-1.1,1.9l-1.2,1.6l-1.4,1.3l-1.5,0.9l-1.5,0.5l-0.6,0.1 M525.9,567.3l-1.3,0.6l-1.2,0.8 M521.5,526.7l0.4,0.4l1.8,1.3l1.7,0.8
		l0.5,0.1 M618,557.9l0.5-0.1l1.5-0.5l1.5-0.9l1.4-1.3l1.2-1.6l1.1-1.9l1-2.2l0.8-2.4l0.6-2.7l0.5-2.9l0.3-3l0.1-3.2l-0.1-3.2
		l-0.3-3.3l-0.5-3.3l-0.6-3.3l-0.8-3.2l-1-3l-1.1-2.9l-1.2-2.7l-1.4-2.5l-1.5-2.2l-1.5-1.9l-1.6-1.6l-1.6-1.3l-1.7-0.9l-1.7-0.6
		l-1.6-0.2h-0.9 M517.3,522.1l0.2,0.1l2.3,1.7l2.3,1.9l2.3,2.1l1.1,1.1l0.4,0.5 M525.9,567.3l-0.9,0.8l-2,1.4l-0.9,0.5 M481,505
		L481,505l-3.3-0.3l-3.3,0.1l-3.3,0.4l-3.3,0.8l-3.3,1.1l-3.2,1.5l-3.1,1.8l-3,2.1l-2.8,2.4l-2.6,2.7l-2.4,2.9l-2.2,3.1l-1.9,3.3
		l-1.7,3.4l-1.4,3.6l-1.1,3.7l-0.8,3.7l-0.5,3.7l-0.2,3.7l0.2,3.7l0.5,3.6l0.8,3.5l1.1,3.3l1.4,3.2l1.7,2.9l1.9,2.7l2.2,2.5l1.9,1.8
		 M520.7,571.8L520.7,571.8L520.7,571.8l-0.1-0.2l-0.2-0.1l-0.2-0.2l-0.3-0.2 M520.6,525.8L520.6,525.8l0.1,0.3v0.3l-0.1,0.3
		l-0.2,0.3l-0.2,0.3l-0.3,0.3 M469.8,585.1l-1.9-1.8l-2.2-2.5l-1.9-2.7l-1.7-2.9l-1.4-3.2l-1.1-3.3l-0.8-3.5l-0.5-3.6l-0.2-3.6
		l0.2-3.7l0.5-3.7l0.8-3.7l1.1-3.7l1.4-3.6l1.7-3.4l1.9-3.3l2.2-3.1l2.4-2.9l2.6-2.7l2.8-2.4l3-2.1l3.1-1.8l3.2-1.5l3.3-1.1l3.3-0.8
		l3.3-0.4l3.3-0.1l3.2,0.3h0.1 M866.8,481.2l0.9,1.1l0.9,1.5l0.8,1.7l0.6,1.9l0.5,2l0.3,2.1l0.1,2.1l-0.1,2l-0.3,1.8L870,499l-0.4,1
		 M862.8,508.6l0.6-0.1l1.1-0.6l1.1-0.9l0.9-1.3l0.8-1.6l0.6-1.9l0.5-2.1l0.3-2.3l0.1-2.4l-0.1-2.5l-0.3-2.5l-0.5-2.5l-0.6-2.4
		l-0.8-2.3l-0.9-2.1l-1.1-1.9l-1.1-1.6l-1.2-1.3l-1.3-0.9l-1.3-0.6l-1.3-0.2h-0.6 M693.2,520.1l-0.1-2.1l-0.3-2.1l-0.5-2l-0.6-1.9
		l-0.8-1.7l-0.9-1.5l-1-1.2l-1-0.8l-1.1-0.5l-1-0.1l-1,0.3l-0.9,0.7l-0.8,1l-0.6,1.3l-0.5,1.6l-0.3,1.8l-0.1,2l0.1,2.1l0.3,2.1
		l0.5,2l0.6,1.9l0.8,1.7l0.9,1.5l1,1.2l1,0.8l1.1,0.5l1,0.1l1-0.3l0.9-0.7l0.8-1l0.6-1.3l0.5-1.6l0.3-1.8L693.2,520.1 M685.9,503.4
		l0.3-0.6l0.9-1.3l1.1-0.9l1.1-0.6l1.2-0.2l1.3,0.2l1.3,0.6l1.3,0.9l1.2,1.3l1.2,1.6l1.1,1.9l0.9,2.1l0.8,2.3l0.6,2.4l0.5,2.5
		l0.3,2.5l0.1,2.5 M701,520.6l-0.1,2.4l-0.3,2.3l-0.5,2.1l-0.6,1.9l-0.8,1.6l-0.9,1.3l-1.1,0.9l-1.2,0.6l-1.2,0.2l-1.3-0.2l-1.3-0.6
		l-1.3-0.9l-0.5-0.5 M1064.5,474.8l1.7-1.8l1.9-2.4l1.7-2.7l1.4-2.8l1.1-3l0.8-3l0.5-3.1l0.1-2 M1054.6,479.4L1054.6,479.4l2.6-0.6
		l2.5-0.9l2.4-1.3l2.3-1.6l2.1-1.9l1.9-2.1l1.7-2.4l1.4-2.5l1.1-2.7l0.8-2.8l0.5-2.8l0.2-2.8 M1073.9,455.1l-0.2-2.8l-0.5-2.7
		l-0.8-2.5l-1.1-2.3l-0.7-1.1 M1045.2,493.3l-1-1.3l-1.2-1.1l-1.3-0.6l-0.4-0.1 M1051.4,468.9l0.1,1.8l0.5,2.7l0.8,2.5l1.1,2.4
		l1.4,2.1l1.7,1.9l1.9,1.6l2.1,1.2l2.3,0.9l2.4,0.5l2.5,0.2l2.5-0.2l2.5-0.6l2.5-0.9l2.4-1.3l2.3-1.6l2.1-1.9l1.9-2.1l1.7-2.4
		l1.4-2.5l1.1-2.7l0.8-2.8l0.5-2.8l0.2-2.8 M1089.8,462.2l-0.2-2.8l-0.5-2.7l-0.8-2.5l-1.1-2.4l-1.4-2.1l-1.7-1.9l-1.9-1.6l-2.1-1.2
		l-2.3-0.9l-2.4-0.5l-2.5-0.2l-2.5,0.2l-2.5,0.6l-2.5,0.9l-2.4,1.3l-2.3,1.6l-0.3,0.2 M1068.7,420.5l-2.3-0.9l-3.1-0.9l-2.7-0.5
		 M1047.9,492.2l-0.3,0.3l-0.4,0.3l-0.4,0.2l-0.4,0.2l-0.4,0.1l-0.3,0.1h-0.3l-0.2-0.1 M1064,504.7l-3.1-0.6l-3.1-1l-2.9-1.3
		l-2.8-1.7l-2.6-2l-0.2-0.2 M1081.1,427.5l2.7,0.5l3.1,0.9l3,1.2l2.8,1.6l2.6,1.9l0.5,0.4 M1047.9,448.8l-0.3-0.2l-0.4-0.2l-0.4-0.2
		l-0.4-0.2l-0.4-0.2l-0.3-0.2l-0.3-0.2l-0.2-0.1 M1041.3,452.4l1-0.7l1.2-1.4l1-1.6l0.7-1.5 M1041.6,488.9v0.2v0.2v0.2v0.2v0.2v0.2
		l-0.1,0.1l-0.1,0.1 M1020.5,492.1l-4,1.4l-2.9,0.9h-0.1 M1020.3,491v0.1v0.1v0.1v0.1v0.1v0.1l0.1,0.2l0.1,0.2 M1041.6,454v-0.2
		v-0.2v-0.2v-0.2V453v-0.2l-0.1-0.2l-0.1-0.2 M1096.7,463.2l-0.2-3l-0.5-2.9l-0.8-2.8l-1.1-2.6l-1.4-2.4l-1.7-2.2l-1.9-1.9l-2.2-1.6
		l-2.3-1.2l-2.5-0.9l-2.6-0.5l-2.7-0.1l-2.7,0.2l-2.7,0.6l-2.7,1l-2.6,1.3l-2.5,1.6l-2.3,1.9l-2.2,2.2l-1.9,2.4l-1.7,2.7l-1.4,2.8
		l-1.1,3l-0.8,3l-0.5,3.1l-0.2,3.1l0.2,3l0.5,2.9l0.8,2.8l1.1,2.6l1.4,2.4l1.7,2.2l1.9,1.9l2.2,1.6l2.3,1.2l2.5,0.9l2.6,0.5l2.7,0.1
		l2.7-0.2l2.7-0.6l2.7-1l2.6-1.3l2.5-1.6l2.3-1.9l2.2-2.2l1.9-2.4l1.7-2.7l1.4-2.8l1.1-2.9l0.8-3l0.5-3.1L1096.7,463.2 M1041.6,454
		l-21.1,3.2 M1020.5,457.1l2.5,1.2l2.2,1.7l1.9,2.1l1.5,2.5l1.1,2.8l0.6,3l0.2,3.2l-0.3,3.3l-0.8,3.3l-1.3,3.2l-1.7,2.9l-2.1,2.6
		l-1.9,1.8l-2,1.5 M1020.5,492.1l21.1-3.2 M1041.6,488.9l1.9,0.1l1.3,0.5l1.2,0.8l1.9,1.9 M1047.9,492.2l2.1,2.5l2.3,2.2l2.5,1.9
		l2.7,1.6l2.9,1.2l3,0.9l3.1,0.5l3.2,0.2l3.2-0.2l3.3-0.6l3.2-0.9l3.2-1.3l3.1-1.6l3-1.9l2.8-2.2l2.6-2.5l2.4-2.8l2.2-3l2-3.2
		l1.7-3.3l1.4-3.5l1.1-3.6l0.8-3.6l0.5-3.7l0.2-3.7l-0.2-3.6l-0.5-3.5l-0.8-3.4l-1.1-3.2l-1.4-3.1l-1.7-2.8l-2-2.6l-2.2-2.3l-2.4-2
		l-2.6-1.7l-2.8-1.4l-3-1l-3.1-0.7l-3.2-0.3h-3.2l-3.3,0.4l-3.2,0.8l-3.2,1.1l-3.1,1.5l-3,1.8l-2.9,2.1l-2.7,2.4l-2.5,2.7l-2.3,2.9
		l-2.1,3.1 M1047.9,448.8l-1.2,1.7l-1.5,1.5l-2,1.4l-1.6,0.6 M1020.3,458.6v-0.2v-0.2V458v-0.2v-0.2v-0.2l0.1-0.2l0.1-0.2
		 M1013.5,456.5L1013.5,456.5l3,0.2l4,0.4 M1020.3,491l3.4-2.8l1.9-2.3l1.6-2.6l1.2-2.8l0.8-3l0.4-3l-0.1-3l-0.5-2.9l-1-2.8
		l-1.4-2.4l-1.8-2.1l-2.1-1.6l-2.5-1.2 M1020.3,458.6l-3.2-1l-3.5-0.9h-0.1 M1013.5,494.4L1013.5,494.4l3.5-1.5l3.2-1.9
		 M932.6,467.6l-0.4-0.3l-1.2-0.6l-1.2-0.3l-1.1,0.1l-1.1,0.5l-1,0.9l-0.9,1.2l-0.7,1.5l-0.6,1.8l-0.4,2l-0.2,2.2v2.3l0.2,2.4
		l0.4,2.4l0.6,2.3l0.7,2.2l0.9,2l1,1.7l1.1,1.5l1.1,1.2l1.2,0.8l1.2,0.4l1.2,0.1l1.1-0.3l1-0.7l0.9-1l0,0 M929.4,497.9l-1.3-0.8
		l-1.2-1.1l-1.2-1.4l-1.1-1.7l-1-2l-0.9-2.2l-0.7-2.4l-0.6-2.5l-0.4-2.5l-0.2-2.5v-2.5l0.2-2.4l0.4-2.2l0.6-2l0.7-1.7l0.9-1.4l1-1.1
		l1.1-0.7l1.2-0.4h1.2l1.3,0.4l1.3,0.8l1.2,1.1l1.2,1.4l1.1,1.7l1,2l0.9,2.2l0.7,2.4l0.6,2.5l0.4,2.5l0.2,2.5v2.5l-0.2,2.4l-0.4,2.2
		l-0.6,2l-0.7,1.7l-0.9,1.4l-1,1.1l-1.1,0.7l-1.2,0.4h-1.2L929.4,497.9 M1032.6,436h-0.2 M1041.3,490.2l-27.8,4.2 M1013.5,494.4
		l1.1-2.2l0.9-2.5l0.7-2.8l0.5-3.1l0.3-3.2l0.1-3.4l-0.1-3.5l-0.3-3.5l-0.5-3.5l-0.7-3.5l-0.9-3.4l-1.1-3.2 M1013.5,456.5l27.8-4.2
		 M927,451.8h0.2 M929.4,507.8l1.6,0.5l1.6,0.2l1.5-0.2l1.5-0.6l1.4-0.9l1.3-1.3l1.2-1.6l1-1.9l0.9-2.2l0.7-2.4l0.5-2.6l0.4-2.8
		l0.2-3v-3l-0.2-3.1l-0.4-3.2l-0.5-3.1l-0.7-3.1l-0.9-3l-1-2.8l-1.2-2.6l-1.3-2.4l-1.4-2.2l-1.5-1.9l-1.5-1.6l-1.6-1.2l-1.6-0.9
		l-1.6-0.5l-1.6-0.2l-1.5,0.2l-1.5,0.6l-1.4,0.9l-1.3,1.3l-1.2,1.6l-1,1.9l-0.9,2.2l-0.7,2.4l-0.5,2.6l-0.4,2.8l-0.2,3v3.1l0.2,3.1
		l0.4,3.2l0.5,3.1l0.7,3.1l0.9,3l1,2.8l1.2,2.6l1.3,2.4l1.4,2.2l1.5,1.9l1.5,1.6l1.6,1.2L929.4,507.8 M553.7,563.1L553.7,563.1H551
		l-3.8-0.3 M525.9,567.3L525.9,567.3l0.2-0.2l0.1-0.2v-0.2v-0.2v-0.2v-0.2v-0.2 M548,529.1l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2
		l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M548,561.6L548,561.6l-0.2,0.2l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2
		 M548,529.1l-3.4,2.8l-1.9,2.3l-1.6,2.6l-1.2,2.8l-0.8,3l-0.4,3l0.1,3.1l0.5,2.9l1,2.8l1.4,2.4l1.8,2.1l2.1,1.6l2.5,1.2 M548,561.6
		l2.9,0.9l2.7,0.6h0.1 M553.7,525.3L553.7,525.3l-2.7,1.9l-2.9,2 M493.6,540.8l-0.1,2l-0.5,3.1l-0.8,3l-1.1,3l-1.4,2.8l-1.7,2.7
		l-1.9,2.4l-1.7,1.8 M547.3,527.8l3.8-1.5l2.6-1h0.1 M525.9,529.4l0.1,0.2l0.1,0.2l0.1,0.2v0.2v0.2v0.2v0.2v0.2 M609.5,499.3h-0.2
		 M525.9,529.4l27.8-4.2 M553.7,525.3l1.1,3.2l0.9,3.4l0.7,3.5l0.5,3.5l0.3,3.5l0.1,3.5l-0.1,3.4l-0.3,3.2l-0.5,3.1l-0.7,2.8
		l-0.9,2.5l-1.1,2.2 M553.7,563.1l-27.8,4.2 M490.4,530.3l0.7,1.1l1.1,2.4l0.8,2.5l0.5,2.7l0.2,2.8l-0.2,2.8l-0.5,2.8l-0.8,2.8
		l-1.1,2.7l-1.4,2.5l-1.7,2.4l-1.9,2.1l-2.1,1.9l-2.3,1.6l-2.4,1.3l-2.5,0.9l-2.5,0.6l0,0 M480.5,534.9l0.3-0.2l2.3-1.6l2.4-1.3
		l2.5-0.9l2.5-0.6l2.5-0.2l2.5,0.2l2.4,0.5l2.3,0.9l2.1,1.2l1.9,1.6l1.7,1.9l1.4,2.1l1.1,2.4l0.8,2.5l0.5,2.7l0.2,2.8l-0.2,2.8
		l-0.5,2.8l-0.8,2.8l-1.1,2.7l-1.4,2.5l-1.7,2.4l-1.9,2.1l-2.1,1.9l-2.3,1.6l-2.4,1.3l-2.5,0.9l-2.5,0.6l-2.5,0.2l-2.5-0.2l-2.4-0.5
		l-2.3-0.9l-2.1-1.2l-1.9-1.6l-1.7-1.9l-1.4-2.1l-1.1-2.4l-0.8-2.5l-0.5-2.7l-0.1-1.8 M449.3,575.9l0.5,0.4l2.6,1.9l2.8,1.5l0.6,0.3
		 M488.5,507.2l-1.3-0.6l-3.1-1L481,505 M526.2,565.9l21.1-3.2 M547.3,562.8l-2.5-1.2l-2.2-1.7l-1.9-2.1l-1.5-2.5l-1.1-2.8l-0.6-3
		l-0.2-3.2l0.3-3.3l0.8-3.3l1.3-3.2l1.7-2.9l2.1-2.6l1.9-1.8l2-1.5 M547.3,527.8l-21.1,3.2 M526.2,531l-1.9-0.1l-1.3-0.5l-1.2-0.8
		l-1.9-1.9 M519.9,571.1l1.2-1.7l1.5-1.5l2-1.4l1.6-0.6 M471.1,556.7l0.2-3.1l0.5-3.1l0.8-3l1.1-3l1.4-2.8l1.7-2.7l1.9-2.4l2.2-2.2
		l2.3-1.9l2.5-1.6l2.6-1.3l2.7-0.9l2.7-0.6l2.7-0.2l2.7,0.1l2.6,0.5l2.5,0.9l2.3,1.2l2.2,1.6l1.9,1.9l1.7,2.2l1.4,2.4l1.1,2.6
		l0.8,2.8l0.5,2.9l0.2,3l-0.2,3.1l-0.5,3.1l-0.8,3l-1.1,3l-1.4,2.8l-1.7,2.7l-1.9,2.4l-2.2,2.2l-2.3,1.9l-2.5,1.6l-2.6,1.3l-2.7,1
		l-2.7,0.6l-2.7,0.2l-2.7-0.1l-2.6-0.5l-2.5-0.9l-2.3-1.2l-2.2-1.6l-1.9-1.9l-1.7-2.2l-1.4-2.4l-1.1-2.6l-0.8-2.8l-0.5-2.9
		L471.1,556.7 M519.9,527.7l-2.1-2.5l-2.3-2.2l-2.5-1.9l-2.7-1.6l-2.9-1.2l-3-0.9l-3.1-0.5l-3.2-0.2l-3.2,0.2l-3.3,0.6l-3.2,0.9
		l-3.2,1.3l-3.1,1.6l-3,1.9l-2.8,2.2l-2.6,2.5l-2.4,2.8l-2.2,3l-2,3.2l-1.7,3.3l-1.4,3.5l-1.1,3.6l-0.8,3.6l-0.5,3.7l-0.2,3.7
		l0.2,3.6l0.5,3.5l0.8,3.4l1.1,3.2l1.4,3.1l1.7,2.8l2,2.6l2.2,2.3l2.4,2l2.6,1.7l2.8,1.4l3,1l3.1,0.7l3.2,0.3h3.2l3.3-0.4l3.2-0.8
		l3.2-1.1l3.1-1.5l3-1.8l2.9-2.1l2.7-2.4l2.5-2.7l2.3-2.9l2.1-3.1 M501.5,514.2l3.1,0.6l3.1,1l2.9,1.3l2.8,1.7l2.6,2l0.2,0.2
		 M484.5,591.4l-2.7-0.5l-3.1-0.9l-3-1.2l-2.8-1.6l-2.6-1.9l-0.5-0.4 M866.8,481.2l-4.1-4.2 M866.9,505.2l2.7-5.2 M857.7,474.8
		l-167.8,25.1 M695,533.7l167.8-25.1 M686,527.4l4.1,4.2 M685.9,503.4l-2.7,5.2 M1060.4,418.2l-1.8-0.2h-3.1l-3.2,0.3l-3.2,0.6
		l-3.1,0.9l-3.1,1.2l-3,1.5l-2.9,1.8l-2.8,2.1l-2.6,2.3l-2.5,2.6l-2.3,2.8l-2,2.8l-0.1,0.2 M1041.3,494.7h0.2h0.1 M1039.6,494.9
		l16.9,7.6 M1089.2,429.7l-20.5-9.2 M1064.2,504.7l0.4,0.1l3.1,0.2l3.2-0.1l3.2-0.4l3.2-0.7l3.1-1l3.1-1.3l3-1.6l2.9-1.9l2.7-2.2
		l2.6-2.4l2.4-2.7l2.2-2.9l2-3l1.7-3.2l1.5-3.3l1.2-3.4l1-3.5l0.7-3.5l0.4-3.6l0.1-3.5l-0.2-3.5l-0.5-3.4l-0.8-3.3l-1.1-3.2l-1.3-3
		l-1.6-2.8l-1.8-2.6l-2.1-2.4l-2.3-2.2l-0.2-0.1 M935.7,510.4l105.6-15.8 M1032.6,436L927,451.8 M930.4,509.6l1,0.4l1.8,0.4l1.8,0.1
		l0.7-0.1 M927.2,451.8h-0.5l-1.6,0.5l-1.5,0.8l-1.4,1.1l-1.3,1.4l-1.2,1.7l-1.1,1.9l-0.9,2.2l-0.8,2.4l-0.6,2.6l-0.4,2.8l-0.3,2.9
		l-0.1,3l0.1,3.1l0.3,3.1l0.4,3.1l0.6,3.1l0.7,3l0.9,2.9l1,2.8l1.2,2.6l1.3,2.4l1.4,2.2l1.5,2l1.6,1.7l1.7,1.4l1.8,1.1l0.5,0.2
		 M617.9,557.9l1-0.2l1.6-0.6l1.5-0.9l1.4-1.2l1.3-1.5l1.2-1.8l1-2.1l0.9-2.3l0.7-2.5l0.5-2.7l0.4-2.8l0.2-2.9v-3l-0.2-3.1l-0.3-3.1
		l-0.5-3.1l-0.7-3l-0.8-2.9l-1-2.8l-1.1-2.7l-1.2-2.5l-1.3-2.3l-1.5-2.1l-1.6-1.9l-1.7-1.6L616,501l-1.8-0.9l-1.8-0.6l-1.8-0.2
		l-1.2,0.1 M520.7,571.8L520.7,571.8l1-1.4l1.2-1.3l0.5-0.4 M521.4,526.7l-0.2-0.2l-0.6-0.8 M480.8,505l-0.4-0.1l-3.1-0.2l-3.2,0.1
		l-3.2,0.4l-3.2,0.7l-3.1,1l-3.1,1.3l-3,1.6l-2.9,1.9l-2.7,2.2l-2.6,2.4l-2.4,2.7l-2.2,2.9l-2,3l-1.7,3.2l-1.5,3.3l-1.2,3.4l-1,3.5
		l-0.7,3.5l-0.4,3.6l-0.1,3.5l0.2,3.5l0.5,3.4l0.8,3.3l1.1,3.2l1.3,3l1.6,2.9l1.8,2.6l2.1,2.4l2.3,2.2l0.2,0.1 M609.3,499.3
		l-103.9,15.5 M520.3,572.5l97.8-14.6 M509,516.4l-20.5-9.2 M455.8,580l20.5,9.2 M520.6,525.8l-1.1-1.4l-2.1-2.3l-1.2-1
		 M484.7,591.5l1.7,0.2h3.1l3.2-0.3l3.2-0.6l3.1-0.9l3.1-1.2l3-1.5l2.9-1.8l2.8-2.1l2.6-2.3l2.5-2.6l2.3-2.8l2-2.8l0.5-0.8"/>
	<line class="st4" x1="937.5" y1="480.7" x2="870.6" y2="490.7"/>
	<line class="st4" x1="687.5" y1="518.2" x2="629" y2="526.9"/>
</g>
</svg>


 : <svg
  className="rearUpperLink"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<path class="st2" d="M709.4,371.4c0-2.7,0.5-4.8,1.7-6.2c1.2-1.3,2.9-2.1,5.2-2.1c0.8,0,1.4,0,1.8,0.1v2.4c-0.5-0.1-1.1-0.2-1.6-0.2
	c-1,0-1.8,0.1-2.5,0.4s-1.1,0.8-1.4,1.3c-0.3,0.5-0.5,1.4-0.5,2.5h0.1c0.7-1.1,1.6-1.6,3-1.6c1.2,0,2.2,0.4,2.9,1.2
	c0.8,0.8,1.1,1.8,1.1,3.3c0,1.5-0.4,2.7-1.3,3.6c-0.9,0.9-2,1.3-3.5,1.3c-1,0-2-0.2-2.7-0.8c-0.8-0.4-1.4-1.2-1.7-2.1
	C709.7,373.8,709.4,372.7,709.4,371.4z M714.6,375.1c0.7,0,1.1-0.2,1.4-0.7c0.3-0.4,0.5-1,0.5-1.8c0-0.7-0.1-1.2-0.4-1.6
	c-0.3-0.4-0.8-0.5-1.4-0.5s-1.1,0.2-1.5,0.5c-0.4,0.4-0.7,0.9-0.7,1.3c0,0.8,0.2,1.4,0.5,2C713.4,374.8,713.9,375.1,714.6,375.1z"/>
<path class="st2" d="M724.3,375.2c-0.3,1.3-0.9,2.8-1.6,4.6h-2.1c0.4-1.7,0.8-3.3,1-4.8h2.7V375.2z"/>
<path class="st2" d="M735.6,366.5c0,0.9-0.2,1.6-0.8,2.2c-0.5,0.5-1.3,1-2.2,1.3v0.1c1.1,0.1,2,0.4,2.6,1c0.7,0.5,0.9,1.3,0.9,2.2
	c0,1.3-0.4,2.4-1.4,3.2c-1,0.8-2.4,1.1-4.1,1.1c-1.5,0-2.8-0.2-4-0.8v-2.5c0.5,0.3,1.1,0.5,1.7,0.7c0.7,0.1,1.3,0.2,2,0.2
	c1,0,1.7-0.2,2.2-0.5c0.4-0.3,0.7-0.9,0.7-1.6c0-0.7-0.2-1.1-0.8-1.4c-0.5-0.3-1.4-0.4-2.6-0.4h-1.1v-2.3h1.1c1.1,0,1.8-0.1,2.4-0.4
	c0.5-0.3,0.8-0.8,0.8-1.4c0-1.1-0.7-1.6-2-1.6c-0.4,0-1,0.1-1.4,0.2c-0.4,0.1-1,0.4-1.6,0.8l-1.4-2.1c1.3-0.9,2.8-1.4,4.6-1.4
	c1.4,0,2.6,0.3,3.5,0.9C735.2,364.5,735.6,365.4,735.6,366.5z"/>
<path class="st2" d="M753,377.2h-2.9V371c0-0.8-0.1-1.3-0.4-1.7c-0.2-0.4-0.7-0.5-1.2-0.5c-0.8,0-1.3,0.3-1.6,0.9s-0.5,1.4-0.5,2.7
	v5h-2.9v-10.7h2.2l0.4,1.4h0.1c0.3-0.5,0.7-0.9,1.2-1.2c0.5-0.3,1.2-0.4,1.8-0.4c1.6,0,2.7,0.5,3.3,1.5h0.2c0.3-0.5,0.8-0.9,1.3-1.2
	c0.5-0.3,1.2-0.4,1.8-0.4c1.2,0,2.2,0.3,2.7,1c0.7,0.7,1,1.6,1,2.9v7h-2.7V371c0-0.8-0.1-1.3-0.4-1.7c-0.2-0.4-0.7-0.5-1.2-0.5
	c-0.8,0-1.2,0.2-1.6,0.8c-0.3,0.5-0.5,1.3-0.5,2.4v5.3H753z"/>
<path class="st2" d="M772.2,377.2h-2.9V371c0-0.8-0.1-1.3-0.4-1.7c-0.2-0.4-0.7-0.5-1.2-0.5c-0.8,0-1.3,0.3-1.6,0.9
	s-0.5,1.4-0.5,2.7v5h-2.9v-10.7h2.2l0.4,1.4h0.1c0.3-0.5,0.7-0.9,1.2-1.2c0.5-0.3,1.2-0.4,1.8-0.4c1.6,0,2.7,0.5,3.3,1.5h0.2
	c0.3-0.5,0.8-0.9,1.3-1.2c0.5-0.3,1.2-0.4,1.8-0.4c1.2,0,2.2,0.3,2.7,1c0.7,0.7,1,1.6,1,2.9v7h-2.9V371c0-0.8-0.1-1.3-0.4-1.7
	c-0.2-0.4-0.7-0.5-1.2-0.5c-0.8,0-1.2,0.2-1.6,0.8c-0.3,0.5-0.5,1.3-0.5,2.4v5.3H772.2z"/>
<path class="st2" d="M793.4,363.3l-5.2,13.9h-2.6l5.2-13.9H793.4z"/>
<path class="st2" d="M809,370.3c0,2.4-0.4,4.2-1.2,5.4c-0.8,1.2-2.1,1.7-3.7,1.7s-2.8-0.7-3.7-1.8c-0.9-1.2-1.2-3-1.2-5.3
	c0-2.5,0.4-4.2,1.2-5.4c0.8-1.2,2.1-1.7,3.7-1.7s2.8,0.7,3.7,1.8C808.7,366.1,809,367.9,809,370.3z M802.2,370.3
	c0,1.7,0.1,2.9,0.4,3.7c0.3,0.8,0.8,1.1,1.5,1.1c0.7,0,1.2-0.3,1.5-1.1c0.3-0.8,0.4-2,0.4-3.7c0-1.7-0.1-2.9-0.4-3.7
	c-0.3-0.8-0.8-1.1-1.5-1.1c-0.7,0-1.2,0.3-1.5,1.1C802.3,367.3,802.2,368.5,802.2,370.3z"/>
<path class="st2" d="M814.1,375.2c-0.3,1.3-0.9,2.8-1.6,4.6h-2.1c0.4-1.7,0.8-3.3,1-4.8h2.7V375.2z"/>
<path class="st2" d="M826,377.2h-9.8v-2.1l3.5-3.6c1.1-1.1,1.7-1.8,2.1-2.2s0.5-0.8,0.7-1.2c0.1-0.4,0.2-0.8,0.2-1.1
	c0-0.5-0.1-1-0.4-1.3c-0.3-0.3-0.8-0.4-1.2-0.4c-0.5,0-1.1,0.1-1.6,0.3c-0.5,0.2-1.1,0.7-1.6,1.1l-1.6-1.8c0.7-0.5,1.3-1,1.7-1.2
	c0.4-0.2,1-0.4,1.5-0.5c0.5-0.1,1.1-0.2,1.8-0.2c0.9,0,1.6,0.1,2.3,0.4s1.2,0.8,1.5,1.3c0.3,0.5,0.5,1.2,0.5,2
	c0,0.7-0.1,1.2-0.3,1.8c-0.2,0.5-0.5,1.2-1.1,1.7c-0.4,0.5-1.3,1.4-2.5,2.5l-1.8,1.7v0.1h6.1v2.6H826z"/>
<path class="st2" d="M832.5,368.3c1.3,0,2.4,0.3,3.3,1.1c0.8,0.8,1.2,1.8,1.2,3.2c0,1.5-0.4,2.7-1.4,3.6s-2.4,1.3-4.1,1.3
	c-1.5,0-2.8-0.2-3.8-0.8v-2.5c0.5,0.2,1.1,0.4,1.7,0.7c0.7,0.2,1.3,0.2,1.8,0.2c1.8,0,2.7-0.8,2.7-2.2c0-1.4-1-2.1-2.8-2.1
	c-0.3,0-0.8,0-1.1,0.1c-0.3,0.1-0.8,0.1-1,0.2l-1.2-0.7l0.5-7.1h7.6v2.5h-5l-0.2,2.7l0.3-0.1C831.4,368.4,831.9,368.3,832.5,368.3z"
	/>
<path class="st2" d="M844.2,363.9c0-1,0.5-1.4,1.6-1.4c1.1,0,1.6,0.4,1.6,1.4c0,0.4-0.1,0.8-0.4,1.1c-0.2,0.2-0.7,0.3-1.2,0.3
	C844.8,365.3,844.2,364.7,844.2,363.9z M847.3,377.2h-2.9v-10.7h2.9V377.2z"/>
<path class="st2" d="M860.2,377.2h-2.9V371c0-0.8-0.1-1.3-0.4-1.7s-0.8-0.5-1.3-0.5c-0.9,0-1.4,0.3-1.7,0.9s-0.5,1.4-0.5,2.7v5h-2.9
	v-10.7h2.2l0.4,1.4h0.1c0.3-0.5,0.8-0.9,1.3-1.2c0.5-0.2,1.2-0.4,2-0.4c1.2,0,2.2,0.3,2.8,1s1,1.6,1,2.9v6.8H860.2z"/>
<path class="st3" d="M917.8,512.1l-0.2,1.1l-0.4,0.7l-0.9,0.2l-1.4-0.3l-1-0.4 M933.1,437.9l-0.5-0.1l-3.3-0.1l-3.3,0.2l-3.3,0.5
	l-3.3,0.9l-3.3,1.2l-3.2,1.5l-3,1.8l-2.9,2.2l-2.7,2.5l-2.5,2.7l-2.3,2.9l-1.5,2.3 M922.1,516.7l-2.2-2.1l-2.1-2.5 M917.8,466.5
	l2.1-3.2l2.3-2.9l2.5-2.7l2.7-2.5l2.9-2.2l3-1.8l3.2-1.5l3.3-1.2l3.3-0.9l3.3-0.5l3.3-0.2l3.3,0.1l0.5,0.1 M899.8,456.3l1.1-0.5
	l3.4-1.1l3.3-0.5l3,0.1l2.6,0.8l2,1.4l1.2,1.6l0.8,2.1l0.5,3l0.2,3.5 M914,471.6l0.4-4.3l0.1-3.9l-0.2-2.6l-0.7-2.3l-0.9-1.3l-1.3-1
	l-1.6-0.7l-2.1-0.2l-2.4,0.2 M913.9,513.4h0.2l0.3-0.4v-1.3l-0.4-2.3v-0.3 M801,471.1h0.9l1.6,0.2l1.6,0.5l1.6,0.9l1.6,1.3l1.6,1.6
	l1.5,1.8l1.4,2.2l1.3,2.4l1.2,2.7l1.1,2.8l1,3l0.8,3.2l0.7,3.3l0.4,3.3l0.3,3.3l0.1,3.3l-0.1,3.2l-0.3,3l-0.4,2.8l-0.7,2.6l-0.8,2.4
	l-1,2.2l-1.1,1.8l-1.2,1.5l-1.3,1.2l-1.4,0.9L810,529l-0.5,0.1 M795.7,518.6l0.1,0.2l1.4,2.2l1.4,1.8l1.5,1.5l1.5,1.2l1.5,0.9
	 M803.2,526.5l1.5,0.5l1.5,0.2l1.5-0.2l1.4-0.5l1.4-0.9l1.3-1.2l1.2-1.5l1-1.8l0.9-2.2l0.8-2.4l0.5-2.6l0.3-2.8l0.2-2.9v-3l-0.2-3.2
	l-0.3-3.2l-0.5-3l-0.8-3l-0.9-2.9l-1-2.8l-1.2-2.6l-1.3-2.4l-1.4-2.2l-1.4-1.8l-1.5-1.5l-1.5-1.2l-1.5-0.9l-1.5-0.5l-1.5-0.2
	l-1.5,0.2l-1.4,0.5l-1.4,0.9l-1.3,1.2l-1.2,1.5l-1,1.8l-0.9,2.2l-0.7,2.4l-0.2,0.7 M652.8,548.1l-1.3,0.5l-1.2,0.9 M648.5,508
	l0.4,0.4l1.7,1.3l1.7,0.8l0.5,0.1 M744,538.9l0.5-0.1l1.5-0.5l1.4-0.9l1.3-1.2l1.2-1.5l1.1-1.8l1-2.2l0.8-2.4l0.7-2.6l0.4-2.8l0.3-3
	l0.1-3.2l-0.1-3.3l-0.3-3.3l-0.4-3.3l-0.7-3.3l-0.8-3.2l-1-3l-1.1-2.8l-1.2-2.7l-1.3-2.4l-1.4-2.2l-1.5-1.8l-1.6-1.6l-1.6-1.3
	l-1.6-0.9l-1.6-0.5l-1.6-0.2h-0.9 M644.6,503.6l3.4,2.5l4.5,4.1l0.4,0.4 M652.8,548.1l-3.7,2.7 M608.4,486.5L608.4,486.5l-3.3-0.3
	l-3.3,0.1l-3.3,0.4l-3.3,0.8l-3.3,1.1l-3.2,1.4l-3,1.7l-2.9,2.1l-2.8,2.4l-2.6,2.6l-2.4,2.8l-2.2,3l-2,3.3l-1.6,3.4l-1.4,3.6
	l-1.1,3.6l-0.8,3.7l-0.4,3.7l-0.1,3.7l0.1,3.6l0.4,3.6l0.8,3.5l1.1,3.3l1.4,3.2l1.6,2.9l2,2.7l2.2,2.4l2,1.7 M647.7,552.7
	L647.7,552.7L647.7,552.7l-0.1-0.1l-0.2-0.1l-0.2-0.2l-0.3-0.2 M647.6,507.1l0.1,0.1v0.2v0.2l-0.1,0.3l-0.2,0.3l-0.2,0.3l-0.3,0.3
	 M597.3,565.7l-1.8-1.7l-2.2-2.4l-2-2.7l-1.6-2.9l-1.4-3.2l-1.1-3.3l-0.8-3.5l-0.4-3.6l-0.1-3.6l0.1-3.7l0.4-3.7l0.8-3.7l1.1-3.6
	l1.4-3.6l1.6-3.4l2-3.3l2.2-3l2.4-2.8l2.6-2.6l2.8-2.4l2.9-2.1l3-1.7l3.2-1.4l3.3-1.1l3.3-0.8l3.3-0.4l3.3-0.1l3.3,0.3h0.1
	 M937,493.9l1.6-1.7l2-2.4l1.6-2.6l1.4-2.8l1.1-2.9l0.8-3l0.5-3l0.1-2 M927.2,498.3L927.2,498.3l2.5-0.5l2.4-0.9l2.4-1.2l2.3-1.5
	l2.1-1.8l1.8-2.1l1.6-2.3l1.4-2.5l1.1-2.6l0.8-2.7l0.4-2.8l0.2-2.8 M946.2,474.3l-0.2-2.7l-0.4-2.6l-0.8-2.5l-1.1-2.3l-0.7-1.1
	 M917.8,512.1l-1-1.3l-1.2-1.1l-1.2-0.7L914,509 M923.9,488l0.1,1.7l0.4,2.6l0.8,2.5l1.1,2.3l1.4,2.1l1.6,1.8l1.8,1.5l2.1,1.2
	l2.3,0.9l2.4,0.5l2.4,0.2l2.5-0.2l2.5-0.5l2.4-0.9l2.4-1.2l2.3-1.5l2.1-1.8l1.8-2.1l1.6-2.3l1.4-2.5l1.1-2.6l0.8-2.7l0.4-2.8
	l0.2-2.8 M962.1,481.4l-0.2-2.7l-0.4-2.6l-0.8-2.5l-1.1-2.3l-1.4-2.1l-1.6-1.8l-1.8-1.5l-2.1-1.2l-2.3-0.9l-2.4-0.5l-2.4-0.2
	l-2.5,0.2l-2.5,0.5l-2.4,0.9l-2.4,1.2l-2.3,1.5l-0.3,0.2 M941.1,440.1l-2.3-0.9l-3-0.9l-2.6-0.4 M920.6,511.1l-0.3,0.3l-0.3,0.2
	l-0.3,0.2l-0.3,0.2l-0.3,0.1l-0.3,0.1h-0.3l-0.2-0.1 M936.5,523.3l-3-0.7l-3-1l-2.9-1.3l-2.7-1.6l-2.5-2l-0.2-0.1 M953.4,446.9
	l2.7,0.4l3,0.9l2.9,1.2l2.8,1.5l2.6,1.8l0.5,0.4 M920.6,468l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.1
	l-0.2-0.1 M914,471.6l1-0.8l1.2-1.4l1-1.6l0.7-1.4 M914.4,507.8v0.2v0.2v0.2v0.2v0.2v0.1l-0.1,0.1l-0.1,0.1 M893.4,510.9l-3.9,1.4
	l-2.9,0.9h-0.1 M893.2,509.9L893.2,509.9v0.2v0.1v0.1v0.1v0.1l0.1,0.1l0.1,0.1 M914.4,473.2V473v-0.2v-0.2v-0.2v-0.2v-0.2l-0.1-0.2
	l-0.1-0.2 M968.8,482.4l-0.2-3l-0.4-2.9l-0.8-2.7l-1.1-2.6l-1.4-2.4l-1.6-2.2l-2-1.8l-2.2-1.5l-2.3-1.2l-2.5-0.9l-2.6-0.5l-2.6-0.1
	l-2.7,0.2l-2.7,0.5l-2.6,1l-2.6,1.3l-2.5,1.6l-2.3,2l-2.2,2.2l-2,2.4l-1.6,2.6l-1.4,2.8l-1.1,2.9l-0.8,3l-0.5,3l-0.2,3l0.2,2.9
	l0.5,2.9l0.8,2.7l1.1,2.6l1.4,2.4l1.6,2.2l2,1.8l2.2,1.5l2.3,1.2l2.5,0.9l2.6,0.5l2.6,0.1l2.7-0.2l2.7-0.5l2.6-1l2.6-1.3l2.5-1.6
	l2.3-2l2.2-2.2l2-2.4l1.6-2.6l1.4-2.8l1.1-2.9l0.8-3l0.4-3L968.8,482.4 M914.4,473.2l-20.9,3.2 M893.4,476.3l2.5,1.2l2.2,1.6
	l1.8,2.1l1.5,2.5l1.1,2.7l0.7,2.9l0.2,3.2l-0.3,3.3l-0.8,3.3l-1.3,3.2l-1.6,2.8l-2.1,2.6l-1.8,1.7l-2,1.5 M893.4,510.9l20.9-3.2
	 M914.4,507.8l1.8,0.1l2.5,1.3l1.8,1.8 M920.6,511.1l2.1,2.5l2.3,2.2l2.5,1.8l2.7,1.5l2.8,1.2l3,0.9l3,0.5l3.2,0.2l3.3-0.2l3.3-0.5
	l3.2-0.9l3.2-1.3l3-1.6l2.9-2l2.8-2.2l2.6-2.5l2.4-2.7l2.2-2.9l2-3.2l1.6-3.3l1.4-3.5l1.1-3.6l0.8-3.6l0.4-3.6l0.2-3.6l-0.2-3.6
	l-0.4-3.5l-0.8-3.4l-1.1-3.3l-1.4-3l-1.6-2.8l-2-2.6l-2.2-2.3l-2.4-2l-2.6-1.7l-2.8-1.4l-2.9-1l-3-0.7l-3.2-0.3h-3.2l-3.3,0.4
	l-3.3,0.8l-3.2,1.1l-3,1.4l-3,1.7l-2.8,2.1l-2.7,2.4l-2.5,2.6l-2.3,2.8l-2.1,3 M920.6,468l-1.2,1.7l-1.4,1.5l-2,1.4l-1.6,0.5
	 M893.2,477.8v-0.2v-0.2v-0.2v-0.2v-0.2v-0.2l0.1-0.2l0.1-0.2 M886.5,475.7L886.5,475.7l3,0.2l3.9,0.3 M893.2,509.9l3.4-2.8l1.8-2.3
	l1.6-2.6l1.2-2.7l0.8-2.9l0.4-2.9l-0.1-3l-0.5-2.8l-1-2.7l-1.3-2.4l-1.7-2.1l-2.1-1.6l-2.4-1.2 M893.2,477.8l-3.2-1l-3.5-1h-0.1
	 M886.5,513.2L886.5,513.2l3.5-1.5l3.2-1.8 M800.7,483.7L800.7,483.7h1.2l1.3,0.4l1.3,0.8l1.2,1.1l1.2,1.4l1.1,1.7l1,2l0.9,2.2
	l0.8,2.3l0.5,2.5l0.3,2.5l0.2,2.5v2.4l-0.2,2.3l-0.3,2.2l-0.5,2l-0.8,1.7l-0.9,1.4l-1,1.1l-1.1,0.8l-1.2,0.3 M914,509.1l-27.5,4.1
	 M886.5,513.2l1.1-2.2l0.9-2.5l0.8-2.8l0.5-3l0.3-3.3l0.1-3.4l-0.1-3.5l-0.3-3.5l-0.5-3.5l-0.8-3.5l-0.9-3.4l-1.1-3.2 M886.5,475.7
	l27.5-4.1 M800.9,471.1h0.2 M680.4,544L680.4,544h-2.6l-3.7-0.3 M652.8,548.1l0.1-0.1l0.1-0.1v-0.1v-0.2v-0.2v-0.2v-0.2v-0.2
	 M674.8,510.4l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.1l-0.1-0.2l-0.1-0.1 M674.8,542.5l-0.1,0.1l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2l-0.1,0.2 M674.8,510.4l-3.4,2.8l-1.8,2.3l-1.6,2.6l-1.2,2.7l-0.8,2.9l-0.4,2.9l0.1,3l0.5,2.8
	l1,2.7l1.3,2.4l1.7,2.1l2.1,1.6l2.4,1.2 M674.8,542.5l2.8,0.9l2.7,0.7h0.1 M680.4,506.6L680.4,506.6l-2.7,1.8l-2.9,2 M621,521.9
	l-0.1,2l-0.5,3l-0.8,3l-1.1,2.9l-1.4,2.8l-1.6,2.6l-2,2.4l-1.6,1.7 M674,509.1l3.7-1.5l2.5-1h0.1 M652.8,510.6l0.1,0.2l0.1,0.2v0.2
	v0.2v0.2v0.2v0.2v0.2 M735.5,480.8h-0.2 M652.8,510.6l27.5-4.1 M680.4,506.6l1.1,3.2l0.9,3.4l0.8,3.5l0.5,3.5l0.3,3.5l0.1,3.5
	l-0.1,3.4l-0.3,3.3l-0.5,3l-0.8,2.8l-0.9,2.5l-1.1,2.2 M680.4,544l-27.5,4.1 M617.7,511.6l0.7,1.1l1.1,2.3l0.8,2.5l0.4,2.6l0.2,2.7
	l-0.2,2.8l-0.4,2.8l-0.8,2.7l-1.1,2.6l-1.4,2.5l-1.6,2.3l-1.8,2.1l-2.1,1.8l-2.3,1.5l-2.4,1.2l-2.4,0.9l-2.5,0.5l0,0 M607.9,516.1
	l0.3-0.2l2.3-1.5l2.4-1.2l2.4-0.9l2.5-0.5l2.5-0.2l2.4,0.2l2.4,0.5l2.3,0.9l2.1,1.2l1.8,1.5l1.6,1.8l1.4,2.1l1.1,2.3l0.8,2.5
	l0.4,2.6l0.2,2.7l-0.2,2.8l-0.4,2.8l-0.8,2.7l-1.1,2.6l-1.4,2.5l-1.6,2.3l-1.8,2.1l-2.1,1.8l-2.3,1.5l-2.4,1.2l-2.4,0.9l-2.5,0.5
	l-2.5,0.2l-2.4-0.2l-2.4-0.5l-2.3-0.9l-2.1-1.2l-1.8-1.5l-1.6-1.8l-1.4-2.1l-1.1-2.3l-0.8-2.5l-0.5-2.6l-0.1-1.7 M576.9,556.7
	l0.4,0.4l2.6,1.8l2.8,1.5l0.7,0.3 M615.7,488.7l-1.3-0.5l-3-1l-3-0.7 M653.1,546.8l20.9-3.2 M674,543.7l-2.5-1.2l-2.2-1.6l-1.8-2.1
	l-1.5-2.5l-1.1-2.7l-0.7-2.9l-0.2-3.2l0.3-3.3l0.8-3.3l1.3-3.2l1.6-2.8l2.1-2.6l1.8-1.7l2-1.5 M674,509.1l-20.9,3.2 M653.1,512.3
	l-1.8-0.1l-2.5-1.3l-1.8-1.8 M646.9,551.9l1.2-1.7l1.4-1.5l2-1.4l1.6-0.5 M598.7,537.7l0.2-3l0.4-3l0.8-3l1.1-2.9l1.4-2.8l1.6-2.6
	l2-2.4l2.2-2.2l2.3-2l2.5-1.6l2.6-1.3l2.6-1l2.7-0.5l2.7-0.2l2.6,0.1l2.6,0.5l2.5,0.9l2.3,1.2l2.2,1.5l2,1.8l1.6,2.2l1.4,2.4
	l1.1,2.6l0.8,2.7l0.5,2.9l0.2,3l-0.2,3l-0.5,3l-0.8,3L641,543l-1.4,2.8l-1.6,2.6l-2,2.4l-2.2,2.2l-2.3,2l-2.5,1.6l-2.6,1.3l-2.6,1
	l-2.7,0.5l-2.7,0.2l-2.6-0.1l-2.6-0.5l-2.5-0.9l-2.3-1.2l-2.2-1.5l-2-1.8l-1.6-2.2l-1.4-2.4l-1.1-2.6l-0.8-2.7l-0.4-2.9L598.7,537.7
	 M646.9,509l-2.1-2.5l-2.3-2.2l-2.5-1.8l-2.7-1.5l-2.8-1.2l-3-0.9l-3-0.5l-3.2-0.2l-3.3,0.2l-3.3,0.5l-3.2,0.9l-3.2,1.3l-3,1.6
	l-2.9,2l-2.8,2.2l-2.6,2.5l-2.4,2.7l-2.2,2.9l-2,3.2l-1.6,3.3l-1.4,3.5l-1.1,3.6l-0.8,3.6l-0.4,3.6l-0.2,3.6l0.2,3.6l0.4,3.5
	l0.8,3.4l1.1,3.3l1.4,3l1.6,2.8l2,2.6l2.2,2.3l2.4,2l2.6,1.7l2.8,1.4l2.9,1l3,0.7l3.2,0.3h3.2l3.3-0.4l3.3-0.8l3.2-1.1l3-1.4l3-1.7
	l2.8-2.1l2.7-2.4l2.5-2.6l2.3-2.8l2.1-3 M628.7,495.6l3,0.7l3,1l2.9,1.3l2.7,1.6l2.5,2l0.2,0.1 M611.8,572l-2.7-0.4l-3-0.9l-2.9-1.2
	l-2.8-1.5l-2.6-1.8l-0.5-0.4 M800.7,483.7l-51,7.6 M754.7,524.8l51.1-7.6 M932.8,437.8l-1.7-0.1H928l-3.2,0.3l-3.2,0.7l-3.2,0.9
	l-3,1.2l-2.9,1.5l-2.9,1.8l-2.7,2.1l-2.6,2.3l-2.4,2.6l-2.3,2.7l-2,2.8l-0.1,0.1 M913.9,513.4h0.2h0.1 M912.3,513.7l16.7,7.5
	 M961.4,449.2l-20.3-9.1 M936.6,523.4l0.4,0.1l3,0.2l3.2-0.1l3.2-0.4l3.2-0.8l3.2-1l3-1.3l2.9-1.6l2.8-1.8l2.7-2.2l2.5-2.4l2.4-2.6
	l2.2-2.8l2-3l1.7-3.2l1.5-3.3l1.2-3.4l1-3.5l0.7-3.5l0.4-3.5l0.1-3.5l-0.2-3.5l-0.4-3.4l-0.8-3.3l-1.1-3.2l-1.3-3l-1.5-2.8l-1.8-2.6
	l-2.1-2.4l-2.3-2.2l-0.1-0.1 M809.5,529.1l104.5-15.7 M905.3,455.4l-104.5,15.7 M804.2,528.2l1,0.4l1.7,0.4l1.7,0.1l0.7-0.1
	 M795.2,518.7l0.5,1.1l1.4,2.2l1.5,2l1.6,1.6l1.7,1.4l1.7,1.1l0.5,0.2 M801.1,471.1h-0.5l-1.6,0.4l-1.5,0.8l-1.4,1.1l-1.3,1.3
	l-1.2,1.6l-1.1,2l-1,2.2l-0.8,2.4l-0.5,2.3 M743.9,538.9l1-0.2l1.6-0.5l1.5-0.9l1.4-1.2l1.3-1.5l1.2-1.7l1-2.1l0.9-2.3l0.7-2.5
	l0.5-2.7l0.3-2.8l0.2-2.9v-3l-0.1-3l-0.3-3l-0.5-3l-0.7-3l-0.8-2.9l-1-2.8l-1.1-2.7l-1.2-2.5l-1.3-2.3l-1.4-2.1l-1.5-1.8l-1.6-1.5
	l-1.7-1.3l-1.7-1l-1.8-0.5l-1.7-0.2l-1.2,0.1 M647.7,552.7L647.7,552.7l1-1.4l1.2-1.3l0.5-0.4 M648.4,508l-0.2-0.2l-0.5-0.8
	 M608.2,486.5l-0.4-0.1l-3-0.2l-3.2,0.1l-3.2,0.4l-3.2,0.8l-3.2,1l-3,1.3l-2.9,1.6l-2.8,1.8l-2.7,2.2l-2.5,2.4l-2.4,2.6l-2.2,2.8
	l-2,3l-1.7,3.2l-1.5,3.3l-1.2,3.4l-1,3.5l-0.7,3.5l-0.3,3.5l-0.1,3.5l0.2,3.5l0.4,3.4l0.8,3.3l1.1,3.2l1.3,3l1.5,2.8l1.8,2.6
	l2.1,2.4l2.3,2.2l0.2,0.1 M735.3,480.8l-102.8,15.3 M647.3,553.3l96.8-14.5 M636.1,497.8l-20.3-9.1 M583.5,560.7l20.3,9.1
	 M647.6,507.1l-1.1-1.3l-2.2-2.3l-1.2-1 M612,572l1.7,0.2h3.2l3.2-0.3l3.2-0.7l3.2-0.9l3-1.2l2.9-1.5l2.9-1.8l2.7-2.1l2.6-2.3
	l2.4-2.6l2.3-2.7l2-2.8l0.5-0.9"/>
<line class="st1" x1="764" y1="492.4" x2="764" y2="384.7"/>
<line class="st1" x1="803.3" y1="485.5" x2="803.3" y2="386.4"/>
<line class="st1" x1="764.3" y1="408.7" x2="800.5" y2="408.7"/>
<polygon class="st2" points="801.5,408.7 793.7,400.1 793.7,417.1 "/>
<polygon class="st2" points="764,408.7 772,417.1 772,400.1 "/>
</svg>



}

export default RearUpperLink
