import "./frontUpperLink.scss"

import React from "react"

const FrontUpperLink = ({ explode }) => {
  return explode ? <svg
  className="frontUpperLink"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<g id="Nr">
	<g id="CT012026" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-front-camber-links", "_blank")}>
		<g>
			<g>
				<path class="st0" d="M839,603h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C851,597.6,845.6,603,839,603z"/>
			</g>
			<polyline class="st1" points="839.8,594.3 842.8,591 839.5,587.7 			"/>
		</g>
		<g>
			<path class="st2" d="M756.4,586.7c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3c0.7,0,1.6-0.2,2.5-0.5
				v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7c0.4-0.8,0.9-1.4,1.6-1.8
				c0.7-0.4,1.5-0.6,2.5-0.6c1,0,1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.7-0.3-1.1-0.5S756.7,586.7,756.4,586.7z"/>
			<path class="st2" d="M764.9,595h-2.1v-8.2h-2.7V585h7.5v1.8h-2.7V595z"/>
			<path class="st2" d="M775.4,590c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S775.4,588.4,775.4,590z M770.5,590c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8c0.5,0,0.8-0.3,1.1-0.8c0.2-0.5,0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8
				c-0.5,0-0.9,0.3-1.1,0.8C770.6,587.9,770.5,588.8,770.5,590z"/>
			<path class="st2" d="M781.7,595h-2.1v-5.8l0-1l0-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7V595z"/>
			<path class="st2" d="M791.4,595h-7v-1.5l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
				c0.3-0.2,0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.4
				c0,0.5-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4V595z"/>
			<path class="st2" d="M799.4,590c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S799.4,588.4,799.4,590z M794.5,590c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8c0.5,0,0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8
				c-0.5,0-0.9,0.3-1.1,0.8C794.6,587.9,794.5,588.8,794.5,590z"/>
			<path class="st2" d="M807.4,595h-7v-1.5l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3c-0.4,0-0.8,0.1-1.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
				c0.3-0.2,0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.4
				c0,0.5-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4V595z"/>
			<path class="st2" d="M808.3,590.8c0-2,0.4-3.5,1.3-4.4s2.1-1.4,3.8-1.4c0.6,0,1,0,1.3,0.1v1.7c-0.4-0.1-0.8-0.1-1.2-0.1
				c-0.7,0-1.3,0.1-1.8,0.3s-0.8,0.5-1,1c-0.2,0.4-0.4,1-0.4,1.8h0.1c0.5-0.8,1.2-1.2,2.2-1.2c0.9,0,1.6,0.3,2.1,0.8
				s0.8,1.3,0.8,2.3c0,1.1-0.3,1.9-0.9,2.5c-0.6,0.6-1.4,0.9-2.5,0.9c-0.7,0-1.4-0.2-1.9-0.5s-1-0.8-1.3-1.5
				S808.3,591.7,808.3,590.8z M811.9,593.5c0.5,0,0.8-0.2,1-0.5c0.2-0.3,0.4-0.7,0.4-1.3c0-0.5-0.1-0.9-0.3-1.2
				c-0.2-0.3-0.6-0.4-1-0.4c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.5,1c0,0.5,0.1,1,0.4,1.4S811.5,593.5,811.9,593.5z"/>
		</g>
	</g>
</g>
<g id="_x32_">
	<path class="st3" d="M998.3,498.7l-0.2,1l-0.4,0.6l-0.8,0.2l-1.3-0.3l-0.8-0.3 M1013,430.9l-2-0.2l-2.9-0.1l-2.9,0.2l-2.9,0.5
		l-2.9,0.9l-2.9,1.2l-2.8,1.4l-2.7,1.7l-2.6,2l-2.4,2.2l-2.3,2.4l-2.1,2.6l-1.4,2 M1002.7,503.4l-0.4-0.4l-2.1-2l-1.9-2.3
		 M998.3,457l1.9-2.8l2.1-2.6l2.3-2.4l2.4-2.2l2.6-2l2.7-1.7l2.8-1.4l2.9-1.2l2.9-0.9l2.9-0.5l2.9-0.2l2.9,0.1l2,0.2 M981.8,447.7
		l0.9-0.5l3.1-1l3-0.5l2.7,0.1l2.3,0.7l1.8,1.3l1.1,1.5l0.7,1.9l0.5,2.8l0.2,3.2 M994.8,461.7l0.1-1l0.2-2l0.1-1.9l0.1-1.8l0-0.9
		l-0.1-1.8l-0.2-1.1l-0.5-1.5l-0.4-0.6l-0.7-0.8l-0.9-0.7l-0.7-0.3l-1.7-0.4l-1-0.1l-2,0.1l-0.1,0 M994.8,500l0.2-0.1l0.2-0.3l0-0.2
		l0-0.6l-0.1-0.8l-0.2-1l-0.1-0.6l0-0.3 M891.2,461.2l1.1-0.1l1.4,0.2l1.4,0.5l1.5,0.8l1.4,1.1l1.4,1.4l1.4,1.6l1.3,1.9l1.2,2.1
		l1.1,2.3l1,2.5l0.9,2.6l0.7,2.7l0.6,2.8l0.5,2.9l0.3,2.9l0.2,2.9v2.8l-0.2,2.7l-0.3,2.6l-0.5,2.5l-0.6,2.3l-0.7,2.1l-0.9,1.8
		l-1,1.6l-1.1,1.3l-1.2,1l-1.3,0.7l-1.4,0.4l-0.3,0.1 M567.2,559.9l-1.1,0.5l-1.1,0.8 M563.2,523.2l0.4,0.4l1.6,1.2l1.5,0.7l0.5,0.1
		 M650.6,551.4l0.3-0.1l1.4-0.4l1.3-0.7l1.2-1l1.1-1.3l1-1.6l0.9-1.8l0.7-2.1l0.6-2.3l0.5-2.4l0.3-2.6l0.2-2.7v-2.8l-0.2-2.9
		l-0.3-2.9l-0.5-2.9l-0.6-2.8l-0.7-2.7l-0.9-2.6l-1-2.5l-1.1-2.3l-1.2-2.1l-1.3-1.9l-1.4-1.6l-1.4-1.4l-1.4-1.1l-1.4-0.8l-1.5-0.5
		l-1.4-0.2l-1.1,0.1 M559.4,519l0.2,0.1l2.1,1.5l2.1,1.7l2,1.9l1,1l0.4,0.4 M567.2,559.9l-0.9,0.7l-1.8,1.3l-0.7,0.4 M527.1,503.6
		l-0.2,0l-2.9-0.3l-2.9,0l-2.9,0.3l-2.9,0.6l-2.9,0.9l-2.9,1.2l-2.8,1.5l-2.7,1.8l-2.5,2.1l-2.4,2.3l-2.2,2.5l-2,2.7l-1.8,2.9
		l-1.6,3l-1.3,3.1l-1.1,3.2l-0.8,3.3l-0.6,3.3l-0.3,3.3v3.3l0.3,3.2l0.6,3.1l0.8,3l1.1,2.9l1.3,2.7l1.6,2.5l1.8,2.3l2,2.1l1,0.9
		 M562.5,564L562.5,564L562.5,564l-0.1-0.1l-0.2-0.1l-0.2-0.2l-0.3-0.2 M562.4,522.3l0.1,0.1l0,0.2l0,0.2l-0.1,0.3l-0.2,0.3
		l-0.2,0.3l-0.3,0.3 M516.3,575.9l-0.9-0.8l-2-2.1l-1.8-2.3l-1.6-2.5l-1.3-2.7l-1.1-2.9l-0.8-3l-0.6-3.1l-0.3-3.2v-3.3l0.3-3.3
		l0.6-3.3l0.8-3.3l1.1-3.2l1.3-3.1l1.6-3l1.8-2.9l2-2.7l2.2-2.5l2.4-2.3l2.5-2.1l2.7-1.8l2.8-1.5l2.9-1.2l2.9-0.9l2.9-0.6l2.9-0.3
		l2.9,0l2.9,0.3l0.1,0 M837.4,487.7l0.3,0.3l0.8,1.1l0.7,1.3l0.6,1.5l0.5,1.7l0.4,1.7l0.2,1.8l0.1,1.8l-0.1,1.7l-0.2,1.6l-0.4,1.4
		l-0.5,1.1 M833.8,512.6l0.9-0.3l1-0.6l0.9-0.9l0.8-1.2l0.7-1.4l0.5-1.6l0.4-1.8l0.2-2l0.1-2.1l-0.1-2.2l-0.2-2.2l-0.4-2.2l-0.5-2.1
		l-0.7-2l-0.8-1.9l-0.9-1.7l-1-1.5l-1-1.2l-1.1-0.9l-1.1-0.6l-1.1-0.3l-1.1,0l-0.1,0 M716,517.6l-0.1-1.8l-0.2-1.8l-0.4-1.7
		l-0.5-1.7l-0.6-1.5l-0.7-1.3l-0.8-1.1l-0.9-0.8l-0.9-0.6l-0.9-0.3l-0.9,0.1l-0.8,0.4l-0.7,0.7l-0.6,0.9l-0.5,1.2l-0.4,1.4l-0.2,1.6
		l-0.1,1.7l0.1,1.8l0.2,1.8l0.4,1.7l0.5,1.7l0.6,1.5l0.7,1.3l0.8,1.1l0.9,0.8l0.9,0.6l0.9,0.2l0.9-0.1l0.8-0.4l0.7-0.7l0.6-0.9
		l0.5-1.2l0.4-1.4l0.2-1.6L716,517.6 M709.4,502.4l0.1-0.3l0.8-1.2l0.9-0.9l1-0.6l1-0.3l1.1,0l1.1,0.3l1.1,0.6l1.1,0.9l1,1.2l1,1.5
		l0.9,1.7l0.8,1.9l0.7,2l0.5,2.1l0.4,2.2l0.2,2.2l0.1,2.2 M723.1,518.1l-0.1,2.1l-0.2,2l-0.4,1.8l-0.5,1.6l-0.7,1.4l-0.8,1.2
		l-0.9,0.9l-1,0.6l-1,0.3l-1.1,0l-1.1-0.3l-1.1-0.6l-1.1-0.9 M1015.8,482l0.2-0.2l1.8-2l1.6-2.2l1.4-2.3l1.2-2.5l0.9-2.6l0.7-2.6
		l0.4-2.7l0.1-1.7 M1006.8,486.2l1.1-0.2l2.2-0.6l2.1-0.9l2-1.2l1.9-1.5l1.8-1.7l1.6-1.9l1.4-2.1l1.2-2.2l0.9-2.3l0.7-2.4l0.4-2.5
		l0.1-2.5 M1024.2,464.1l-0.1-2.4l-0.4-2.3l-0.7-2.2l-0.9-2.1l-0.8-1.3 M998.3,498.7l-0.9-1.2l-1.1-1l-1.1-0.5l-0.4-0.1
		 M1003.9,476.7l0.1,1.5l0.4,2.3l0.7,2.2l0.9,2.1l1.2,1.9l1.4,1.7l1.6,1.4l1.8,1.2l1.9,0.9l2,0.6l2.1,0.3l2.2,0l2.2-0.3l2.2-0.6
		l2.1-0.9l2-1.2l1.9-1.5l1.8-1.7l1.6-1.9l1.4-2.1l1.2-2.2l0.9-2.3l0.7-2.4l0.4-2.5l0.1-2.5 M1038.7,470.6l-0.1-2.4l-0.4-2.3
		l-0.7-2.2l-0.9-2.1l-1.2-1.9l-1.4-1.7l-1.6-1.4l-1.8-1.2l-1.9-0.9l-2-0.6l-2.1-0.3l-2.2,0l-2.2,0.3l-2.2,0.6l-2.1,0.9l-2,1.2
		l-1.6,1.2 M1019.5,432.9l-0.2-0.1l-2.7-1l-2.8-0.7l-0.9-0.2 M1000.7,497.8l-0.3,0.3l-0.3,0.2l-0.3,0.2l-0.3,0.2l-0.3,0.1l-0.3,0.1
		l-0.3,0l-0.2-0.1 M1014.9,509l-2.7-0.6l-2.7-0.9l-2.6-1.2l-2.4-1.5l-1.8-1.4 M1031.6,439.3l0.9,0.2l2.8,0.7l2.7,1l2.5,1.3l2.4,1.6
		l1.3,1 M1000.7,458.4l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.1l-0.2-0.1 M994.8,461.7l0.9-0.7l1-1.3
		l0.9-1.5l0.6-1.3 M995.1,494.8l0,0.2l0,0.2l0,0.2l0,0.2l0,0.1l0,0.1l-0.1,0.1l-0.1,0.1 M975.9,497.7l-3.6,1.3l-2.6,0.8l-0.1,0
		 M975.7,496.7L975.7,496.7l0,0.2l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0.1,0.1 M995.1,463.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2
		l-0.1-0.2l-0.1-0.2 M1044.9,471.5l-0.1-2.6l-0.4-2.6l-0.7-2.4l-0.9-2.3l-1.2-2.1l-1.4-1.9l-1.6-1.7l-1.8-1.4l-2-1.2l-2.1-0.9
		l-2.2-0.6l-2.3-0.3l-2.4,0l-2.4,0.4l-2.4,0.7l-2.3,1l-2.2,1.2l-2.1,1.5l-2,1.8l-1.8,2l-1.6,2.2l-1.4,2.3l-1.2,2.5l-0.9,2.6
		l-0.7,2.7l-0.4,2.7l-0.1,2.7l0.1,2.6l0.4,2.6l0.7,2.4l0.9,2.3l1.2,2.1l1.4,1.9l1.6,1.7l1.8,1.4l2,1.2l2.1,0.9l2.2,0.6l2.3,0.3
		l2.4,0l2.4-0.4l2.4-0.7l2.3-1l2.2-1.2l2.1-1.5l2-1.8l1.8-2l1.6-2.2l1.4-2.3l1.2-2.5l0.9-2.6l0.7-2.6l0.4-2.7L1044.9,471.5
		 M995.1,463.2l-19.1,2.9 M975.9,466l2.3,1.1l2,1.5l1.7,1.9l1.4,2.2l1,2.5l0.6,2.7l0.2,2.9l-0.3,3l-0.7,3l-1.2,2.9l-1.5,2.6
		l-1.9,2.4l-1.7,1.6l-1.8,1.4 M975.9,497.7l19.1-2.9 M995.1,494.8l1.7,0.1l1.2,0.5l1.1,0.7l1.7,1.7 M1000.7,497.8l1.8,2.2l2,1.9
		l2.2,1.7l2.4,1.4l2.5,1.1l2.6,0.8l2.7,0.5l2.8,0.2l2.8-0.1l2.8-0.4l2.8-0.7l2.8-1l2.7-1.3l2.6-1.5l2.5-1.8l2.4-2l2.2-2.3l2.1-2.5
		l1.9-2.7l1.7-2.8l1.4-2.9l1.2-3l0.9-3.1l0.7-3.2l0.4-3.2l0.1-3.2l-0.1-3.1l-0.4-3.1l-0.7-3l-0.9-2.8l-1.2-2.7l-1.4-2.5l-1.7-2.3
		l-1.9-2.1l-2.1-1.9l-2.2-1.6l-2.4-1.3l-2.5-1l-2.6-0.7l-2.7-0.4l-2.8-0.1l-2.8,0.2l-2.8,0.5l-2.8,0.8l-2.8,1.1l-2.7,1.4l-2.6,1.6
		l-2.5,1.9l-2.4,2.1l-2.2,2.3l-2,2.5l-1.8,2.7 M1000.7,458.4l-1.1,1.5l-1.3,1.4l-1.8,1.3l-1.5,0.5 M975.7,467.3v-0.2l0-0.2l0-0.2
		l0-0.2l0-0.2l0-0.2l0-0.2l0.1-0.2 M969.6,465.5L969.6,465.5l2.7,0.2l3.6,0.3 M975.7,496.7l3.1-2.6l1.7-2.1l1.4-2.4l1.1-2.5l0.7-2.7
		l0.3-2.7l0-2.8l-0.5-2.6l-0.9-2.5l-1.2-2.2l-1.6-1.9l-1.9-1.5l-2.2-1.1 M975.7,467.3l-2.9-0.9l-3.1-0.9l-0.1,0 M969.6,499.8
		L969.6,499.8l3.2-1.4l2.9-1.7 M896.4,475.5l-0.9-0.6l-1-0.4l-1-0.1l-1,0.2l-0.9,0.5l-0.8,0.8l-0.7,1.1l-0.6,1.4l-0.5,1.6l-0.3,1.8
		l-0.2,1.9v2l0.2,2l0.3,2l0.5,2l0.6,1.9l0.7,1.8l0.8,1.6l0.9,1.4l1,1.1l1,0.8l1,0.5l1,0.2l1-0.1l0.9-0.4l0.9-0.7l0.6-0.7
		 M893.4,502.9l-1.1-0.6l-1.1-0.9l-1-1.2l-1-1.5l-0.9-1.7l-0.8-1.9l-0.7-2l-0.5-2.1l-0.4-2.2l-0.2-2.2l-0.1-2.2l0.1-2.1l0.2-2
		l0.4-1.8l0.5-1.6l0.7-1.4l0.8-1.2l0.9-0.9l1-0.6l1-0.3l1.1,0l1.1,0.3l1.1,0.6l1.1,0.9l1,1.2l1,1.5l0.9,1.7l0.8,1.9l0.7,2l0.5,2.1
		l0.4,2.2l0.2,2.2l0.1,2.2l-0.1,2.1l-0.2,2l-0.4,1.8l-0.5,1.6l-0.7,1.4l-0.8,1.2l-0.9,0.9l-1,0.6l-1,0.3l-1.1,0L893.4,502.9
		 M994.8,496l-25.2,3.8 M969.6,499.8l0.9-1.8l0.8-2.1l0.6-2.3l0.5-2.5l0.3-2.7l0.2-2.8v-2.9l-0.2-2.9l-0.3-3l-0.5-2.9l-0.6-2.9
		l-0.8-2.8l-0.9-2.7 M969.6,465.5l25.2-3.8 M899.2,514.3l-0.1,0 M893.4,511.9l1.4,0.5l1.4,0.2l1.4-0.2l1.3-0.5l1.2-0.8l1.1-1.1
		l1-1.4l0.9-1.6l0.8-1.9l0.7-2.1l0.5-2.3l0.4-2.5l0.2-2.6l0-2.7l-0.1-2.8l-0.3-2.8l-0.4-2.8l-0.6-2.7l-0.7-2.7l-0.9-2.6l-1-2.4
		l-1.1-2.2l-1.2-2l-1.3-1.8l-1.3-1.5l-1.4-1.2l-1.4-0.9l-1.4-0.6l-1.4-0.3l-1.4,0l-1.3,0.3l-1.3,0.6l-1.2,0.9l-1.1,1.2l-1,1.5
		l-0.9,1.8l-0.7,2l-0.6,2.2l-0.4,2.4l-0.3,2.6l-0.1,2.7l0,2.7l0.2,2.8l0.4,2.8l0.5,2.8l0.7,2.7l0.8,2.6l0.9,2.5l1,2.3l1.1,2.1
		l1.2,1.9l1.3,1.6l1.4,1.4l1.4,1.1L893.4,511.9 M592.4,556.1L592.4,556.1l-2.4,0l-3.4-0.3 M567.2,559.9l0.1-0.1l0.1-0.1l0-0.1l0-0.1
		l0-0.2l0-0.2l0-0.2l0-0.2 M587.2,525.4l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1 M587.2,554.7
		l-0.1,0.1L587,555l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.2l-0.1,0.2 M587.2,525.4l-3.1,2.6l-1.7,2.1l-1.4,2.4l-1.1,2.5
		l-0.7,2.7l-0.3,2.7l0.1,2.8l0.5,2.6l0.9,2.5l1.2,2.2l1.6,1.9l1.9,1.5l2.2,1.1 M587.2,554.7l2.6,0.8l2.5,0.5l0.1,0 M592.4,521.9
		L592.4,521.9l-2.5,1.7l-2.7,1.8 M538,535.9l-0.1,1.7l-0.4,2.7l-0.7,2.6l-0.9,2.6l-1.2,2.5l-1.4,2.3l-1.6,2.2l-1.8,2l-0.2,0.2
		 M586.6,524.2l3.4-1.4l2.3-0.9l0.1,0 M567.2,525.6l0.1,0.2l0.1,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M650.5,551.4L650.5,551.4
		 M567.2,525.6l25.2-3.8 M592.4,521.9l0.9,2.7l0.8,2.8l0.6,2.9l0.5,2.9l0.3,3l0.2,2.9v2.9l-0.2,2.8l-0.3,2.7l-0.5,2.5l-0.6,2.3
		l-0.8,2.1l-0.9,1.8 M592.4,556.1l-25.2,3.8 M535.1,526.4l0.8,1.3l0.9,2.1l0.7,2.2l0.4,2.3l0.1,2.4l-0.1,2.5l-0.4,2.5l-0.7,2.4
		l-0.9,2.3l-1.2,2.2l-1.4,2.1l-1.6,1.9l-1.8,1.7l-1.9,1.5l-2,1.2l-2.1,0.9l-2.2,0.6l-1.1,0.2 M526.1,530.6l1.6-1.2l2-1.2l2.1-0.9
		l2.2-0.6l2.2-0.3l2.2,0l2.1,0.3l2,0.6l1.9,0.9l1.8,1.2l1.6,1.4l1.4,1.7l1.2,1.9l0.9,2.1l0.7,2.2l0.4,2.3l0.1,2.4l-0.1,2.5l-0.4,2.5
		l-0.7,2.4l-0.9,2.3l-1.2,2.2l-1.4,2.1l-1.6,1.9l-1.8,1.7l-1.9,1.5l-2,1.2l-2.1,0.9l-2.2,0.6l-2.2,0.3l-2.2,0l-2.1-0.3l-2-0.6
		l-1.9-0.9l-1.8-1.2l-1.6-1.4l-1.4-1.7l-1.2-1.9l-0.9-2.1l-0.7-2.2l-0.4-2.3l-0.1-1.5 M497.8,567.6l1.2,1l2.4,1.6l2.4,1.2
		 M533.3,505.5l-1-0.4l-2.7-0.9l-2.6-0.6 M567.4,558.7l19.1-2.9 M586.6,555.8l-2.3-1.1l-2-1.5l-1.7-1.9l-1.4-2.2l-1-2.5l-0.6-2.7
		l-0.2-2.9l0.3-3l0.7-3l1.2-2.9l1.5-2.6l1.9-2.4l1.7-1.6l1.8-1.4 M586.6,524.2l-19.1,2.9 M567.4,527.1l-1.7-0.1l-1.2-0.5l-1.1-0.7
		l-1.7-1.7 M561.8,563.4l1.1-1.5l1.3-1.4l1.8-1.3l1.5-0.5 M517.6,550.3l0.1-2.7l0.4-2.7l0.7-2.6l0.9-2.6l1.2-2.5l1.4-2.3l1.6-2.2
		l1.8-2l2-1.8l2.1-1.5l2.2-1.2l2.3-1l2.4-0.7l2.4-0.4l2.4,0l2.3,0.3l2.2,0.6l2.1,0.9l2,1.2l1.8,1.4l1.6,1.7l1.4,1.9l1.2,2.1l0.9,2.3
		l0.7,2.4l0.4,2.6l0.1,2.6l-0.1,2.7l-0.4,2.7l-0.7,2.7l-0.9,2.6l-1.2,2.5l-1.4,2.3l-1.6,2.2l-1.8,2l-2,1.8l-2.1,1.5l-2.2,1.2l-2.3,1
		l-2.4,0.7l-2.4,0.4l-2.4,0l-2.3-0.3l-2.2-0.6l-2.1-0.9l-2-1.2l-1.8-1.4l-1.6-1.7l-1.4-1.9l-1.2-2.1l-0.9-2.3l-0.7-2.4l-0.4-2.6
		L517.6,550.3 M561.8,524l-1.8-2.2l-2-1.9l-2.2-1.7l-2.4-1.4l-2.5-1.1l-2.6-0.8l-2.7-0.5l-2.8-0.2l-2.8,0.1l-2.8,0.4l-2.8,0.7
		l-2.8,1l-2.7,1.3l-2.6,1.5l-2.5,1.8l-2.4,2l-2.2,2.3l-2.1,2.5l-1.9,2.7l-1.7,2.8L512,536l-1.2,3l-0.9,3.1l-0.7,3.2l-0.4,3.2
		l-0.1,3.2l0.1,3.1l0.4,3.1l0.7,3l0.9,2.8l1.2,2.7l1.4,2.5l1.7,2.3l1.9,2.1l2.1,1.9l2.2,1.6l2.4,1.3l2.5,1l2.6,0.7l2.7,0.4l2.8,0.1
		l2.8-0.2l2.8-0.5l2.8-0.8l2.8-1.1l2.7-1.4l2.6-1.6l2.5-1.9l2.4-2.1l2.2-2.3l2-2.5l1.8-2.7 M545.5,511.9l2.7,0.6l2.7,0.9l2.6,1.2
		l2.4,1.5l1.8,1.4 M528.8,581.7l-0.9-0.2l-2.8-0.7l-2.7-1l-2.5-1.3l-2.4-1.6l-1.3-1 M837.4,487.7l-3.7-3.8 M837.5,509.4l2.5-4.7
		 M829.2,481.9l-116.1,17.4 M717.6,529.9l116.2-17.4 M709.4,524.2l3.7,3.8 M709.4,502.4l-2.5,4.7 M1012.9,430.9l-1.4-0.2l-2.7-0.1
		l-2.8,0.1l-2.8,0.4l-2.8,0.7l-2.7,0.9l-2.7,1.2l-2.6,1.4l-2.5,1.7l-2.4,1.9l-2.3,2.1l-2.1,2.3l-1.9,2.5l-1.4,2.1 M994.8,500l0.1,0
		h0.2l0,0 M993.2,500.2l15.3,6.9 M1038.1,441.2l-18.6-8.3 M1015,509l0.1,0l2.7,0.3l2.8,0l2.8-0.3l2.8-0.5l2.8-0.8l2.7-1.1l2.7-1.3
		l2.6-1.6l2.5-1.8l2.3-2l2.2-2.2l2-2.4l1.9-2.6l1.7-2.7l1.5-2.8l1.2-2.9l1-3l0.8-3.1l0.5-3.1l0.3-3.1l0.1-3.1l-0.2-3.1l-0.4-3
		l-0.7-2.9l-0.9-2.8l-1.2-2.7l-1.4-2.5l-1.6-2.3l-1.8-2.1l-2-1.9l-0.5-0.4 M899.2,514.3l95.6-14.3 M986.8,446.9l-95.6,14.3
		 M894.4,513.5l0.9,0.3l1.6,0.4l1.5,0.1l0.7-0.1 M891.2,461.2L891.2,461.2l-1.5,0.4l-1.4,0.6l-1.3,0.9l-1.2,1.2l-1.1,1.4l-1,1.7
		l-0.9,1.9l-0.7,2.1l-0.6,2.2l-0.4,2.4l-0.3,2.5l-0.1,2.6l0,2.7l0.2,2.7l0.3,2.7l0.4,2.7l0.6,2.7l0.7,2.6l0.8,2.5l0.9,2.4l1.1,2.2
		l1.2,2.1l1.3,1.9l1.3,1.7l1.4,1.5l1.5,1.2l1.4,0.9l0.5,0.2 M650.5,551.4l0.8-0.1l1.4-0.5l1.3-0.7l1.2-1l1.2-1.2l1.1-1.5l0.9-1.7
		l0.8-2l0.7-2.1l0.5-2.3l0.4-2.4l0.2-2.5l0.1-2.6l-0.1-2.7l-0.2-2.7l-0.3-2.7l-0.5-2.7l-0.6-2.6l-0.8-2.6l-0.9-2.5l-1-2.3l-1.1-2.2
		l-1.2-2l-1.3-1.8l-1.4-1.6l-1.5-1.4l-1.5-1.1l-1.6-0.8l-1.6-0.5l-1.6-0.2l-1.4,0.1 M562.5,564l0.9-1.2l1.1-1.1l0.6-0.5
		 M563.2,523.2L563.2,523.2l-0.7-0.9 M526.9,503.6l-0.2,0l-2.7-0.3l-2.8,0l-2.8,0.3l-2.8,0.5l-2.8,0.8l-2.7,1.1l-2.7,1.3l-2.6,1.6
		l-2.5,1.8l-2.3,2l-2.2,2.2l-2,2.4l-1.9,2.6l-1.7,2.7l-1.5,2.8l-1.2,2.9l-1,3l-0.8,3.1l-0.5,3.1l-0.3,3.1l-0.1,3.1l0.2,3.1l0.4,3
		l0.7,2.9l0.9,2.8l1.2,2.7l1.4,2.5l1.6,2.3l1.8,2.1l2,1.9l0.5,0.4 M642.7,498.3l-94,14 M562.1,564.6l88.5-13.2 M551.9,513.8
		l-18.6-8.3 M503.8,571.4l18.6,8.3 M562.4,522.3l-0.9-1.2l-1.9-2l-1.7-1.5 M529,581.7l1.4,0.2l2.7,0.1l2.8-0.1l2.8-0.4l2.8-0.7
		l2.7-0.9l2.7-1.2l2.6-1.5l2.5-1.7l2.4-1.9l2.3-2.1l2.1-2.3l1.9-2.5l1.8-2.7"/>
</g>
</svg>

 : <svg
  className="frontUpperLink"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<g>
	<path class="st2" d="M728.1,389.5c0,0.8-0.2,1.5-0.7,2s-1.2,0.9-2,1.2v0.1c1,0.1,1.8,0.4,2.4,0.9s0.8,1.2,0.8,2
		c0,1.2-0.4,2.2-1.3,2.9s-2.2,1-3.8,1c-1.4,0-2.6-0.2-3.7-0.7v-2.3c0.5,0.3,1,0.5,1.6,0.6s1.2,0.2,1.8,0.2c0.9,0,1.6-0.2,2-0.5
		s0.6-0.8,0.6-1.5c0-0.6-0.2-1-0.7-1.3s-1.3-0.4-2.4-0.4h-1v-2.1h1c1,0,1.7-0.1,2.2-0.4s0.7-0.7,0.7-1.3c0-1-0.6-1.5-1.8-1.5
		c-0.4,0-0.9,0.1-1.3,0.2s-0.9,0.4-1.5,0.7l-1.3-1.9c1.2-0.8,2.6-1.3,4.2-1.3c1.3,0,2.4,0.3,3.2,0.8
		C727.7,387.7,728.1,388.5,728.1,389.5z"/>
	<path class="st2" d="M733.2,397.5c-0.3,1.2-0.8,2.6-1.5,4.2h-1.9c0.4-1.6,0.7-3,0.9-4.4h2.5L733.2,397.5z"/>
	<path class="st2" d="M743.6,389.5c0,0.8-0.2,1.5-0.7,2s-1.2,0.9-2,1.2v0.1c1,0.1,1.8,0.4,2.4,0.9s0.8,1.2,0.8,2
		c0,1.2-0.4,2.2-1.3,2.9s-2.2,1-3.8,1c-1.4,0-2.6-0.2-3.7-0.7v-2.3c0.5,0.3,1,0.5,1.6,0.6s1.2,0.2,1.8,0.2c0.9,0,1.6-0.2,2-0.5
		s0.6-0.8,0.6-1.5c0-0.6-0.2-1-0.7-1.3s-1.3-0.4-2.4-0.4h-1v-2.1h1c1,0,1.7-0.1,2.2-0.4s0.7-0.7,0.7-1.3c0-1-0.6-1.5-1.8-1.5
		c-0.4,0-0.9,0.1-1.3,0.2s-0.9,0.4-1.5,0.7l-1.3-1.9c1.2-0.8,2.6-1.3,4.2-1.3c1.3,0,2.4,0.3,3.2,0.8
		C743.2,387.7,743.6,388.5,743.6,389.5z"/>
	<path class="st2" d="M759.6,399.4h-2.7v-5.7c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.5-1.1-0.5c-0.7,0-1.2,0.3-1.5,0.8
		s-0.5,1.3-0.5,2.5v4.6h-2.7v-9.8h2l0.4,1.3h0.1c0.3-0.5,0.6-0.8,1.1-1.1s1.1-0.4,1.7-0.4c1.5,0,2.5,0.5,3,1.4h0.2
		c0.3-0.5,0.7-0.8,1.2-1.1c0.5-0.3,1.1-0.4,1.7-0.4c1.1,0,2,0.3,2.5,0.9c0.6,0.6,0.9,1.5,0.9,2.7v6.4H763v-5.7
		c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.5-1.1-0.5c-0.7,0-1.1,0.2-1.5,0.7c-0.3,0.5-0.5,1.2-0.5,2.2V399.4z"/>
	<path class="st2" d="M777.2,399.4h-2.7v-5.7c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.5-1.1-0.5c-0.7,0-1.2,0.3-1.5,0.8
		s-0.5,1.3-0.5,2.5v4.6h-2.7v-9.8h2l0.4,1.3h0.1c0.3-0.5,0.6-0.8,1.1-1.1s1.1-0.4,1.7-0.4c1.5,0,2.5,0.5,3,1.4h0.2
		c0.3-0.5,0.7-0.8,1.2-1.1c0.5-0.3,1.1-0.4,1.7-0.4c1.1,0,2,0.3,2.5,0.9c0.6,0.6,0.9,1.5,0.9,2.7v6.4h-2.7v-5.7
		c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.5-1.1-0.5c-0.7,0-1.1,0.2-1.5,0.7c-0.3,0.5-0.5,1.2-0.5,2.2V399.4z"/>
	<path class="st2" d="M796.7,386.6l-4.8,12.8h-2.4l4.8-12.8H796.7z"/>
	<path class="st2" d="M811.1,393c0,2.2-0.4,3.9-1.1,5s-1.9,1.6-3.4,1.6c-1.5,0-2.6-0.6-3.4-1.7s-1.1-2.8-1.1-4.9
		c0-2.3,0.4-3.9,1.1-5s1.9-1.6,3.4-1.6c1.5,0,2.6,0.6,3.4,1.7S811.1,390.8,811.1,393z M804.8,393c0,1.6,0.1,2.7,0.4,3.4
		c0.3,0.7,0.7,1,1.4,1c0.6,0,1.1-0.3,1.4-1s0.4-1.8,0.4-3.4c0-1.6-0.1-2.7-0.4-3.4c-0.3-0.7-0.7-1-1.4-1c-0.6,0-1.1,0.3-1.4,1
		S804.8,391.4,804.8,393z"/>
	<path class="st2" d="M815.8,397.5c-0.3,1.2-0.8,2.6-1.5,4.2h-1.9c0.4-1.6,0.7-3,0.9-4.4h2.5L815.8,397.5z"/>
	<path class="st2" d="M824.4,399.4h-2.7V392l0-1.2l0-1.3c-0.5,0.5-0.8,0.7-0.9,0.9l-1.5,1.2l-1.3-1.6l4.1-3.3h2.2V399.4z"/>
	<path class="st2" d="M836.5,389.5c0,0.8-0.2,1.5-0.7,2s-1.2,0.9-2,1.2v0.1c1,0.1,1.8,0.4,2.4,0.9s0.8,1.2,0.8,2
		c0,1.2-0.4,2.2-1.3,2.9s-2.2,1-3.8,1c-1.4,0-2.6-0.2-3.7-0.7v-2.3c0.5,0.3,1,0.5,1.6,0.6s1.2,0.2,1.8,0.2c0.9,0,1.6-0.2,2-0.5
		s0.6-0.8,0.6-1.5c0-0.6-0.2-1-0.7-1.3s-1.3-0.4-2.4-0.4h-1v-2.1h1c1,0,1.7-0.1,2.2-0.4s0.7-0.7,0.7-1.3c0-1-0.6-1.5-1.8-1.5
		c-0.4,0-0.9,0.1-1.3,0.2s-0.9,0.4-1.5,0.7l-1.3-1.9c1.2-0.8,2.6-1.3,4.2-1.3c1.3,0,2.4,0.3,3.2,0.8
		C836.1,387.7,836.5,388.5,836.5,389.5z"/>
	<path class="st2" d="M843.5,387.1c0-0.9,0.5-1.3,1.5-1.3s1.5,0.4,1.5,1.3c0,0.4-0.1,0.7-0.4,1c-0.2,0.2-0.6,0.3-1.1,0.3
		C844,388.4,843.5,387.9,843.5,387.1z M846.3,399.4h-2.7v-9.8h2.7V399.4z"/>
	<path class="st2" d="M858.2,399.4h-2.7v-5.7c0-0.7-0.1-1.2-0.4-1.6s-0.7-0.5-1.2-0.5c-0.8,0-1.3,0.3-1.6,0.8s-0.5,1.3-0.5,2.5v4.6
		h-2.7v-9.8h2l0.4,1.3h0.1c0.3-0.5,0.7-0.8,1.2-1.1c0.5-0.2,1.1-0.4,1.8-0.4c1.1,0,2,0.3,2.6,0.9s0.9,1.5,0.9,2.7V399.4z"/>
</g>
<path class="st3" d="M898.6,511.1l-0.2,0.9l-0.4,0.6l-0.7,0.2l-1.1-0.2l-0.8-0.3 M911.8,450.2L910,450l-2.6-0.1l-2.6,0.2l-2.6,0.5
	l-2.6,0.8l-2.6,1l-2.5,1.3l-2.4,1.5l-2.3,1.8l-2.2,2l-2,2.2l-1.9,2.4l-1.2,1.8 M902.5,515.2l-0.4-0.3l-1.9-1.8l-1.7-2 M898.6,473.6
	l1.7-2.5l1.9-2.4l2-2.2l2.2-2l2.3-1.8l2.4-1.5l2.5-1.3l2.6-1l2.6-0.8l2.6-0.5l2.6-0.2l2.6,0.1l1.8,0.2 M883.8,465.2l0.8-0.4l2.8-0.9
	l2.7-0.5l2.5,0l2.1,0.6l1.6,1.1l1,1.4l0.7,1.7l0.5,2.5l0.2,2.9 M895.4,477.8l0.3-3.6l0.1-3.3l-0.2-2.1l-0.6-1.9l-0.7-1.1l-1.1-0.8
	l-1.4-0.5l-1.7-0.2l-1.8,0.2 M895.4,512.2l0.1,0l0.3-0.3l0-1.1l-0.3-1.9l0-0.2 M802.4,477.4l1-0.1l1.3,0.2l1.3,0.4l1.3,0.7l1.3,1
	l1.3,1.2l1.2,1.5l1.2,1.7l1.1,1.9l1,2.1l0.9,2.2l0.8,2.4l0.7,2.5l0.5,2.5l0.4,2.6l0.3,2.6l0.1,2.6v2.5l-0.1,2.4l-0.3,2.3l-0.4,2.2
	l-0.5,2l-0.7,1.9l-0.8,1.7l-0.9,1.4l-1,1.2l-1.1,0.9l-1.2,0.7l-1.2,0.4l-0.3,0.1 M798.2,516.4l0.2,0.4l1.1,1.7l1.2,1.5l1.2,1.2
	l1.3,1l1.3,0.7 M804.4,522.9l1.3,0.4l1.3,0.1l1.2-0.1l1.2-0.4l1.1-0.7l1-1l0.9-1.2l0.8-1.5l0.7-1.7l0.6-1.9l0.5-2.1l0.3-2.2l0.2-2.3
	l0-2.4l-0.1-2.5l-0.3-2.5l-0.4-2.5l-0.5-2.5l-0.7-2.4l-0.8-2.3l-0.9-2.2l-1-2l-1.1-1.8l-1.1-1.6l-1.2-1.4l-1.2-1.1l-1.3-0.8
	l-1.3-0.6l-1.3-0.3h-1.2l-1.2,0.3l-1.1,0.6l-1.1,0.8l-1,1.1l-0.9,1.4l-0.8,1.6l-0.7,1.8l-0.5,1.9 M705,537.1l-1,0.5l-0.9,0.7
	 M701.4,504.1l0.3,0.3l1.4,1.1l1.4,0.7l0.4,0.1 M779.9,529.4l0.3-0.1l1.2-0.4l1.2-0.7l1.1-0.9l1-1.2l0.9-1.4l0.8-1.7l0.7-1.9l0.5-2
	l0.4-2.2l0.3-2.3l0.1-2.4v-2.5l-0.1-2.6l-0.3-2.6l-0.4-2.6l-0.5-2.5l-0.7-2.5l-0.8-2.4l-0.9-2.2l-1-2.1l-1.1-1.9l-1.2-1.7l-1.2-1.5
	l-1.3-1.2l-1.3-1l-1.3-0.7l-1.3-0.4l-1.3-0.2l-1,0.1 M698.2,500.5l2.8,2.1l3.7,3.4l0.3,0.4 M705,537.1l-3.1,2.2 M669,486.6l-0.2,0
	l-2.6-0.3l-2.6,0l-2.6,0.3l-2.6,0.6l-2.6,0.8l-2.6,1.1l-2.5,1.4l-2.4,1.6l-2.3,1.8l-2.1,2.1l-2,2.2l-1.8,2.4l-1.6,2.6l-1.4,2.7
	l-1.2,2.8l-1,2.9l-0.7,2.9l-0.5,3l-0.2,3v3l0.2,2.9l0.5,2.8l0.7,2.7l1,2.6l1.2,2.5l1.4,2.3l1.6,2.1l1.8,1.9l0.9,0.8 M700.8,540.8
	L700.8,540.8L700.8,540.8l-0.1-0.1l-0.1-0.1l-0.2-0.1l-0.2-0.2 M700.7,503.4L700.7,503.4l0.1,0.3l0,0.2l-0.1,0.2l-0.1,0.3l-0.2,0.3
	l-0.2,0.3 M659.3,551.5l-0.8-0.7l-1.8-1.9l-1.6-2.1l-1.4-2.3l-1.2-2.5l-1-2.6l-0.7-2.7l-0.5-2.8l-0.3-2.9v-3l0.3-3l0.5-3l0.7-2.9
	l1-2.9l1.2-2.8l1.4-2.7l1.6-2.6l1.8-2.4l2-2.2l2.1-2.1l2.3-1.8l2.4-1.6l2.5-1.4l2.6-1.1l2.6-0.8l2.6-0.6l2.6-0.3l2.6,0l2.6,0.3
	l0.1,0 M914.3,496.1l0.2-0.2l1.6-1.8l1.5-2l1.3-2.1l1.1-2.2l0.8-2.3l0.6-2.4l0.4-2.4l0.1-1.5 M906.2,499.8l1-0.2l1.9-0.6l1.9-0.8
	l1.8-1.1l1.7-1.3l1.6-1.5l1.4-1.7l1.3-1.9l1.1-2l0.8-2.1l0.6-2.2l0.4-2.2l0.1-2.2 M921.9,480l-0.1-2.2l-0.4-2.1l-0.6-2l-0.8-1.9
	l-0.7-1.2 M898.6,511.1l-0.8-1.1l-1-0.9l-1-0.5l-0.4,0 M903.6,491.3l0.1,1.4l0.4,2.1l0.6,2l0.8,1.9l1.1,1.7l1.3,1.5l1.4,1.3l1.6,1
	l1.7,0.8l1.8,0.5l1.9,0.3l1.9,0l2-0.3l1.9-0.6l1.9-0.8l1.8-1.1l1.7-1.3l1.6-1.5l1.4-1.7l1.3-1.9l1.1-2l0.8-2.1l0.6-2.2l0.4-2.2
	l0.1-2.2 M934.9,485.8l-0.1-2.2l-0.4-2.1l-0.6-2l-0.8-1.9l-1.1-1.7l-1.3-1.5l-1.4-1.3l-1.6-1.1l-1.7-0.8l-1.8-0.5l-1.9-0.3l-1.9,0
	l-2,0.3l-1.9,0.6l-1.9,0.8l-1.8,1.1l-1.4,1 M917.7,451.9l-0.2-0.1l-2.4-0.9l-2.5-0.6l-0.8-0.1 M900.8,510.2l-0.3,0.2l-0.3,0.2
	l-0.3,0.2l-0.3,0.1l-0.3,0.1l-0.3,0l-0.2,0l-0.2-0.1 M913.5,520.3l-2.4-0.5l-2.4-0.8l-2.3-1.1l-2.2-1.3l-1.7-1.3 M928.5,457.7
	l0.8,0.1l2.5,0.6l2.4,0.9l2.3,1.2l2.1,1.4l1.1,0.9 M900.8,474.9l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.2-0.1
	l-0.2-0.1 M895.4,477.8l0.8-0.6l0.9-1.1l0.8-1.3l0.6-1.2 M895.7,507.5l0,0.2l0,0.2l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l-0.1,0.1
	 M878.5,510.1l-3.2,1.2l-2.4,0.7l-0.1,0 M878.3,509.2L878.3,509.2l0,0.2l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0.1,0.1 M895.7,479.1l0-0.2
	l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l-0.1-0.2 M940.4,486.6l-0.1-2.4l-0.4-2.3l-0.6-2.2l-0.8-2.1l-1.1-1.9l-1.3-1.7l-1.5-1.5
	l-1.6-1.3l-1.8-1l-1.9-0.8l-2-0.5l-2.1-0.2l-2.1,0l-2.1,0.3l-2.1,0.6l-2.1,0.9l-2,1.1l-1.9,1.4l-1.8,1.6l-1.6,1.8l-1.5,2l-1.3,2.1
	l-1.1,2.2l-0.8,2.3l-0.6,2.4l-0.4,2.4l-0.1,2.4l0.1,2.4l0.4,2.3l0.6,2.2l0.8,2.1l1.1,1.9l1.3,1.7l1.5,1.5l1.6,1.3l1.8,1l1.9,0.8
	l2,0.5l2.1,0.2l2.1,0l2.1-0.3l2.1-0.6l2.1-0.9l2-1.1l1.9-1.4l1.8-1.6l1.6-1.8l1.5-2l1.3-2.1l1.1-2.2l0.8-2.3l0.6-2.4l0.4-2.4
	L940.4,486.6 M895.7,479.1l-17.2,2.6 M878.5,481.7l2,0.9l1.8,1.3l1.5,1.7l1.2,2l0.9,2.3l0.5,2.5l0.1,2.6l-0.3,2.7l-0.7,2.7l-1,2.6
	l-1.4,2.4l-1.7,2.1l-1.5,1.4l-1.6,1.2 M878.5,510.1l17.2-2.6 M895.7,507.5l1.5,0.1l2,1l1.5,1.6 M900.8,510.2l1.6,1.9l1.8,1.7l2,1.5
	l2.1,1.3l2.2,1l2.3,0.8l2.4,0.5l2.5,0.2l2.5-0.1l2.5-0.3l2.5-0.6l2.5-0.9l2.5-1.1l2.4-1.4l2.3-1.6l2.2-1.8l2-2l1.9-2.2l1.7-2.4
	l1.5-2.5l1.3-2.6l1.1-2.7l0.8-2.8l0.6-2.8l0.4-2.9l0.1-2.9l-0.1-2.8l-0.4-2.8l-0.6-2.7l-0.8-2.6l-1.1-2.4l-1.3-2.3l-1.5-2.1L941,466
	l-1.9-1.7l-2-1.4l-2.2-1.2l-2.3-0.9l-2.4-0.7l-2.5-0.4l-2.5-0.1l-2.5,0.2l-2.5,0.4l-2.5,0.7l-2.5,1l-2.4,1.2l-2.3,1.5l-2.2,1.7
	l-2.1,1.9l-2,2.1l-1.8,2.3l-1.6,2.4 M900.8,474.9l-1,1.4l-1.2,1.2l-1.6,1.1l-1.3,0.5 M878.3,482.9v-0.1l0-0.1l0-0.1l0-0.1l0-0.2
	l0-0.1l0-0.1l0.1-0.1 M872.8,481.2L872.8,481.2l2.5,0.2l3.2,0.3 M878.3,509.2l2.8-2.3l1.5-1.8l1.3-2.1l1-2.2l0.7-2.4l0.3-2.4l0-2.5
	l-0.4-2.4l-0.8-2.3l-1.1-1.9l-1.5-1.7l-1.7-1.3l-2-1 M878.3,482.9l-2.6-0.8l-2.8-0.8l-0.1,0 M872.8,512L872.8,512l2.9-1.3l2.6-1.5
	 M802.4,487.7L802.4,487.7l1.1,0l1,0.3l1,0.6l1,0.8l0.9,1.1l0.9,1.3l0.8,1.5l0.7,1.7l0.6,1.8l0.5,1.9l0.3,1.9l0.2,2l0.1,1.9
	l-0.1,1.9l-0.2,1.8l-0.3,1.6l-0.5,1.5l-0.6,1.3l-0.7,1l-0.8,0.8l-0.9,0.5l-0.8,0.2 M888.4,464.6l-0.2,0 M895.4,508.6l-22.6,3.4
	 M872.8,512l0.8-1.7l0.7-1.9l0.6-2.1l0.4-2.2l0.3-2.4l0.1-2.5v-2.6l-0.1-2.6l-0.3-2.7l-0.4-2.6l-0.6-2.6l-0.7-2.5l-0.8-2.4
	 M872.8,481.2l22.6-3.4 M809.6,525L809.6,525 M727.6,533.7L727.6,533.7l-2.2,0l-3.1-0.2 M705,537.1l0.1-0.1l0-0.1l0-0.1l0-0.1l0-0.1
	l0-0.1l0-0.2l0-0.2 M723,506.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1 M723,532.4L723,532.4
	l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M723,506.1l-2.8,2.3l-1.5,1.9l-1.3,2.1l-1,2.2l-0.7,2.4l-0.3,2.4
	l0,2.5l0.4,2.4l0.8,2.3l1.1,1.9l1.5,1.7l1.7,1.3l2,1 M723,532.4l2.3,0.7l2.2,0.5l0.1,0 M727.6,502.9L727.6,502.9l-2.2,1.5l-2.4,1.6
	 M678.8,515.5l-0.1,1.5l-0.4,2.4l-0.6,2.4l-0.8,2.3l-1.1,2.2l-1.3,2.1l-1.5,2l-1.6,1.8l-0.2,0.2 M722.4,505l3.1-1.2l2.1-0.8l0.1,0
	 M705,506.3l0.1,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M779.8,529.5L779.8,529.5 M705,506.3l22.6-3.4 M727.6,502.9l0.8,2.4
	l0.7,2.5l0.6,2.6l0.4,2.6l0.3,2.7l0.2,2.6v2.6l-0.2,2.5l-0.3,2.4l-0.4,2.2l-0.6,2.1l-0.7,1.9l-0.8,1.7 M727.6,533.7l-22.6,3.4
	 M676.2,507l0.7,1.2l0.8,1.9l0.6,2l0.4,2.1l0.1,2.2l-0.1,2.2l-0.4,2.2l-0.6,2.2l-0.8,2.1l-1.1,2l-1.3,1.9l-1.4,1.7l-1.6,1.5
	l-1.7,1.3l-1.8,1.1l-1.9,0.8l-1.9,0.6l-1,0.2 M668.1,510.7l1.4-1l1.8-1.1l1.9-0.8l2-0.6l2-0.3l2,0l1.9,0.3l1.8,0.5l1.7,0.8l1.6,1.1
	l1.4,1.3l1.3,1.5l1.1,1.7l0.8,1.9l0.6,2l0.4,2.1l0.1,2.2l-0.1,2.2l-0.4,2.2l-0.6,2.2l-0.8,2.1l-1.1,2l-1.3,1.9l-1.4,1.7l-1.6,1.5
	l-1.7,1.3l-1.8,1.1l-1.9,0.8l-2,0.6l-2,0.3l-2,0l-1.9-0.3l-1.8-0.5l-1.7-0.8l-1.6-1.1l-1.4-1.3l-1.3-1.5l-1.1-1.7l-0.8-1.9l-0.6-2
	l-0.4-2.1l-0.1-1.4 M642.7,544l1.1,0.9l2.1,1.4l2.1,1.1 M674.6,488.2l-0.9-0.4l-2.4-0.8l-2.3-0.5 M705.2,536l17.2-2.6 M722.4,533.4
	l-2-1l-1.8-1.3l-1.5-1.7l-1.2-2l-0.9-2.3l-0.5-2.5l-0.1-2.6l0.3-2.7l0.7-2.7l1-2.6l1.4-2.4l1.7-2.1l1.5-1.4l1.6-1.2 M722.4,505
	l-17.2,2.6 M705.2,507.6l-1.5-0.1l-2-1l-1.5-1.6 M700.1,540.2l1-1.4l1.2-1.2l1.6-1.1l1.3-0.5 M660.5,528.5l0.1-2.4l0.4-2.4l0.6-2.4
	l0.8-2.3l1.1-2.2l1.3-2.1l1.5-2l1.6-1.8l1.8-1.6l1.9-1.4l2-1.1l2.1-0.9l2.1-0.6l2.1-0.3l2.1,0l2.1,0.2l2,0.5l1.9,0.8l1.8,1l1.6,1.3
	l1.5,1.5l1.3,1.7l1.1,1.9l0.8,2.1l0.6,2.2l0.4,2.3l0.1,2.4l-0.1,2.4l-0.4,2.4l-0.6,2.4l-0.8,2.3l-1.1,2.2l-1.3,2.1l-1.5,2l-1.6,1.8
	l-1.8,1.6l-1.9,1.4l-2,1.1l-2.1,0.9l-2.1,0.6l-2.1,0.3l-2.1,0l-2.1-0.2l-2-0.5l-1.9-0.8l-1.8-1l-1.6-1.3l-1.5-1.5l-1.3-1.7l-1.1-1.9
	l-0.8-2.1l-0.6-2.2l-0.4-2.3L660.5,528.5 M700.1,504.9l-1.6-1.9l-1.8-1.7l-2-1.5l-2.1-1.3l-2.2-1l-2.3-0.8l-2.4-0.5l-2.5-0.2
	l-2.5,0.1l-2.5,0.3l-2.5,0.6l-2.5,0.9l-2.5,1.1l-2.4,1.4l-2.3,1.6l-2.2,1.8l-2,2l-1.9,2.2l-1.7,2.4l-1.5,2.5l-1.3,2.6l-1.1,2.7
	l-0.8,2.8l-0.6,2.8l-0.4,2.9l-0.1,2.9l0.1,2.8l0.4,2.8l0.6,2.7l0.8,2.6l1.1,2.4l1.3,2.3l1.5,2.1l1.7,1.9l1.9,1.7l2,1.4l2.2,1.2
	l2.3,0.9l2.4,0.7l2.5,0.4l2.5,0.1l2.5-0.2l2.5-0.4l2.5-0.7l2.5-1l2.4-1.2l2.3-1.5l2.2-1.7l2.1-1.9l2-2.1l1.8-2.3l1.6-2.4 M685.6,494
	l2.4,0.5l2.4,0.8l2.3,1.1l2.2,1.3l1.7,1.3 M670.6,556.6l-0.8-0.1l-2.5-0.6l-2.4-0.9l-2.3-1.2l-2.1-1.4l-1.2-0.9 M802.4,487.7
	l-17.8,2.7 M788.6,517.8l17.8-2.7 M911.7,450.2l-1.2-0.2l-2.5-0.1l-2.5,0.1l-2.5,0.4l-2.5,0.6l-2.5,0.8l-2.4,1.1l-2.3,1.3l-2.3,1.5
	l-2.2,1.7l-2,1.9l-1.9,2.1l-1.7,2.2l-1.2,1.9 M895.4,512.2l0.1,0h0.2l0,0 M894,512.4l13.8,6.2 M934.3,459.4l-16.7-7.5 M913.6,520.3
	l0.1,0l2.4,0.3l2.5,0l2.5-0.2l2.5-0.5l2.5-0.7l2.4-1l2.4-1.2l2.3-1.4l2.2-1.6l2.1-1.8l2-2l1.8-2.2l1.7-2.3l1.5-2.4l1.3-2.6l1.1-2.6
	l0.9-2.7l0.7-2.8l0.5-2.8l0.3-2.8l0-2.8l-0.2-2.8l-0.4-2.7l-0.6-2.6l-0.8-2.5l-1-2.4l-1.2-2.3l-1.4-2.1l-1.6-1.9l-1.8-1.7l-0.4-0.3
	 M809.6,525l85.8-12.8 M888.3,464.5l-85.8,12.8 M805.3,524.4l0.8,0.3l1.4,0.3l1.4,0.1l0.6-0.1 M797.9,516.5l0.8,1.4l1.1,1.7l1.2,1.5
	l1.3,1.3l1.3,1.1l1.3,0.8l0.4,0.2 M802.4,477.4L802.4,477.4l-1.4,0.3l-1.2,0.6l-1.1,0.8l-1.1,1l-1,1.3l-0.9,1.5l-0.8,1.7l-0.6,1.9
	l-0.5,2l-0.1,0.5 M779.8,529.4l0.7-0.1l1.3-0.4l1.2-0.7l1.1-0.9l1-1.1l0.9-1.3l0.8-1.6l0.7-1.8l0.6-1.9l0.5-2.1l0.3-2.2l0.2-2.3
	l0.1-2.4l-0.1-2.4l-0.2-2.4l-0.3-2.4l-0.4-2.4l-0.6-2.4l-0.7-2.3l-0.8-2.2l-0.9-2.1l-1-2l-1.1-1.8l-1.2-1.6l-1.2-1.4l-1.3-1.2
	l-1.4-1l-1.4-0.7l-1.4-0.5l-1.4-0.2l-1.3,0.1 M700.8,540.8l0.8-1.1l1-1l0.5-0.4 M701.4,504.1L701.4,504.1l-0.7-0.8 M668.8,486.5
	l-0.2,0l-2.4-0.3l-2.5,0l-2.5,0.2l-2.5,0.5l-2.5,0.7l-2.4,1l-2.4,1.2l-2.3,1.4l-2.2,1.6l-2.1,1.8l-2,2l-1.8,2.2l-1.7,2.3l-1.5,2.4
	l-1.3,2.6l-1.1,2.6l-0.9,2.7l-0.7,2.8l-0.5,2.8l-0.3,2.8l0,2.8l0.2,2.8l0.4,2.7l0.6,2.6l0.8,2.5l1,2.4l1.2,2.3l1.4,2.1l1.6,1.9
	l1.8,1.7l0.5,0.4 M772.8,481.8l-84.4,12.6 M700.4,541.3l79.5-11.9 M691.3,495.7l-16.7-7.5 M648.1,547.4l16.7,7.5 M700.7,503.4
	l-0.8-1.1l-1.7-1.8l-1.5-1.3 M670.7,556.6l1.2,0.2l2.5,0.1l2.5-0.1l2.5-0.4l2.5-0.6l2.5-0.8l2.4-1.1l2.3-1.3l2.3-1.5l2.2-1.7l2-1.9
	l1.9-2.1l1.7-2.2l1.6-2.4"/>
<line class="st4" x1="776.8" y1="483.7" x2="776.8" y2="405.2"/>
<line class="st4" x1="803.5" y1="487.9" x2="803.5" y2="406.6"/>
<line class="st4" x1="777.2" y1="424.8" x2="802.1" y2="424.8"/>
<polygon class="st2" points="802.9,424.8 796.5,417.8 796.5,431.8 "/>
<polygon class="st2" points="776.9,424.8 783.4,431.8 783.4,417.8 "/>
</svg>



}

export default FrontUpperLink
