import "./steeringLink.scss"

import React from "react"

const SteeringLink = ({ explode }) => {
  return explode ? (
    <svg
      className="steeringLink"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1545 1080"
    >
      <g id="Nr">
        <g
          id="CT012040"
          className="cursor--pointer"
          onClick={() =>
            window.open(
              "https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-steering-link",
              "_blank"
            )
          }
        >
          <g>
            <g>
              <g>
                <path
                  class="st0"
                  d="M847,621h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
						C859,615.6,853.6,621,847,621z"
                />
              </g>
              <polyline
                class="st1"
                points="847.8,612.3 850.8,609 847.5,605.7 				"
              />
            </g>
            <g>
              <path
                class="st2"
                d="M764.4,604.7c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3
					c0.7,0,1.6-0.2,2.5-0.5v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7
					c0.4-0.8,0.9-1.4,1.6-1.8c0.7-0.4,1.5-0.6,2.5-0.6s1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.7-0.3-1.1-0.5S764.7,604.7,764.4,604.7z
					"
              />
              <path
                class="st2"
                d="M772.9,613h-2.1v-8.2h-2.7V603h7.5v1.8h-2.7V613z"
              />
              <path
                class="st2"
                d="M783.4,608c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
					c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S783.4,606.4,783.4,608z M778.5,608c0,1.2,0.1,2.1,0.3,2.6
					s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
					C778.6,605.9,778.5,606.8,778.5,608z"
              />
              <path
                class="st2"
                d="M789.7,613h-2.1v-5.8v-1v-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7L789.7,613L789.7,613z"
              />
              <path
                class="st2"
                d="M799.4,613h-7v-1.5l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8
					c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3s-0.8,0.1-1.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
					s0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.4s-0.1,0.9-0.2,1.3
					c-0.2,0.4-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4v1.8H799.4z"
              />
              <path
                class="st2"
                d="M807.4,608c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
					c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S807.4,606.4,807.4,608z M802.5,608c0,1.2,0.1,2.1,0.3,2.6
					s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
					C802.6,605.9,802.5,606.8,802.5,608z"
              />
              <path
                class="st2"
                d="M815.6,611h-1.2v2.1h-2.1V611H808v-1.5l4.4-6.5h1.9v6.3h1.2L815.6,611L815.6,611z M812.4,609.3v-1.7
					c0-0.3,0-0.7,0-1.2s0-0.9,0.1-0.9h-0.1c-0.2,0.4-0.4,0.7-0.6,1.1l-1.8,2.8L812.4,609.3L812.4,609.3z"
              />
              <path
                class="st2"
                d="M823.3,608c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
					c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S823.3,606.4,823.3,608z M818.4,608c0,1.2,0.1,2.1,0.3,2.6
					s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
					C818.6,605.9,818.4,606.8,818.4,608z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="_x32_">
        <path
          class="st3"
          d="M519.4,591.2l-0.9,0.4l-0.9,0.6 M516.1,560.8l0.3,0.3l1.3,1l1.3,0.6l0.4,0.1 M588.5,584.1l1-0.2l1.3-0.7l1.2-1
		l1.1-1.4l1-1.7l0.8-2l0.6-2.2l0.5-2.4l0.3-2.6l0.1-2.8l-0.1-2.8l-0.3-2.9l-0.5-2.9l-0.6-2.8l-0.8-2.7l-1-2.5l-1.1-2.3l-1.2-2.1
		l-1.3-1.8l-1.4-1.5l-1.4-1.2l-1.4-0.8l-1.4-0.5l-1.4-0.1h-0.3 M512,556.6l0.3,0.2l3.5,2.6l3.4,3.1l0.3,0.3 M519.4,591.2l-2.9,2.1
		 M483.4,544.3h-0.8l-2.9,0.2l-2.9,0.6l-2.9,0.9l-2.8,1.3l-2.7,1.6l-2.6,1.9l-2.4,2.2l-2.2,2.5l-2,2.7l-1.7,2.9l-1.4,3.1l-1.1,3.2
		l-0.8,3.3l-0.5,3.3l-0.2,3.3l0.2,3.2l0.5,3.2l0.8,3l1.1,2.9l1.4,2.6l1.7,2.4l1.2,1.3 M515.5,594.6L515.5,594.6L515.5,594.6
		L515.5,594.6l-0.2-0.2l-0.2-0.1l-0.2-0.2 M515.5,560.1L515.5,560.1l0.1,0.3v0.2l-0.1,0.2l-0.1,0.2l-0.2,0.2l-0.2,0.2 M475.3,602.5
		l-0.9-1l-1.7-2.4l-1.4-2.6l-1.1-2.9l-0.8-3l-0.5-3.2l-0.2-3.3l0.2-3.3l0.5-3.3l0.8-3.3l1.1-3.2l1.4-3.1l1.7-2.9l2-2.7l2.2-2.5
		l2.4-2.2l2.6-1.9l2.7-1.6l2.8-1.3l2.9-0.9l2.9-0.6l2.9-0.2h0.4 M1036.8,474.1L1036.8,474.1L1036.8,474.1h0.2h0.1 M1056.7,513.3
		L1056.7,513.3l-0.2-0.1l-0.1-0.1l-0.1-0.2l-0.1-0.2v-0.3v-0.3 M1050.8,513.2L1050.8,513.2L1050.8,513.2h0.2l0.1-0.1l0.1-0.1
		 M1030.3,516.2L1030.3,516.2l1.9-0.1l0.7-0.1 M1038.5,473.6l0.1-0.3l0.2-0.2l0.3-0.2l0.3-0.1l0.3-0.1h0.3 M1098,475.2v0.3l-0.3,0.9
		l-0.7,0.9l-1.1,0.8l-1.5,0.8l-1.8,0.7l-2.1,0.7l-2.4,0.6l-2.7,0.5l-2.9,0.5l-3.1,0.4l-3.2,0.3l-3.3,0.2l-3.4,0.1h-3.4l-3.4-0.1
		l-3.3-0.2l-3.2-0.3 M1056.2,512l3.2,0.3l3.3,0.2l3.4,0.1h3.4l3.4-0.1l3.3-0.2l3.2-0.3l3.1-0.4l2.9-0.5l2.7-0.5l2.4-0.6l2.1-0.7
		l1.8-0.7l1.5-0.8l1.1-0.8l0.7-0.9l0.3-0.9v-0.3 M1033.9,482l1.6-0.8l0.6-0.6l0.3-0.6l-0.1-0.6l-0.5-0.6l-0.2-0.1 M1037,474.1
		l-8.5,1.3 M1034.6,478l1.1,0.4l1,0.6l0.6,0.6l0.2,0.7l-0.2,0.7l-0.7,0.6l-1.9,0.9 M1084.7,479.4h0.2l2.5-0.5l2.2-0.6l2-0.7l1.6-0.7
		l1.3-0.8l0.9-0.8l0.6-0.8l0.2-0.8l-0.2-0.8l-0.6-0.8l-0.9-0.8l-1.3-0.8l-1.6-0.7l-2-0.7l-2.2-0.6l-2.5-0.5l-2.7-0.5l-2.9-0.4
		l-3.1-0.3l-3.2-0.2l-3.3-0.1h-3.3l-3.3,0.1l-3.2,0.2l-3.1,0.3l-2.9,0.4l-2.7,0.5l-2.5,0.5l-2.3,0.6l-2,0.7l-1.6,0.7l-1.3,0.8
		l-0.9,0.8l-0.6,0.8 M1056.9,480.2l1.2,0.1 M1030.3,484l-1.2-2.2l-1.3-2l-1.3-1.7l-1.4-1.4l-1.4-1l-1.5-0.7l-1.4-0.3h-1.1
		 M1026.2,518.7l0.3-0.1l1.3-0.5l1.3-0.8l1.2-1.2 M966.4,482.8h0.4l1.4,0.1l1.4,0.5l1.4,0.8l1.4,1.2l1.4,1.5l1.3,1.8l1.2,2.1
		l1.1,2.3l1,2.5l0.8,2.7l0.6,2.8l0.5,2.9l0.3,2.9l0.1,2.8l-0.1,2.8l-0.3,2.6l-0.5,2.4l-0.6,2.2l-0.8,2l-1,1.7l-1.1,1.4l-1.2,1
		l-1.3,0.7l-0.9,0.2 M907.6,506.8L907.6,506.8l0.9,1.2l0.7,1.3l0.6,1.5l0.4,1.7l0.3,1.8l0.1,1.8l-0.1,1.7l-0.3,1.5l-0.4,1.3
		l-0.2,0.3 M904.6,527.4l0.3-0.1l1-0.5l0.9-0.8l0.8-1.2l0.6-1.5l0.5-1.7l0.3-2l0.1-2.1l-0.1-2.2l-0.3-2.2l-0.5-2.2l-0.6-2.1
		l-0.8-1.9l-0.9-1.6l-1-1.4l-1.1-1l-1.1-0.7l-1.1-0.3h-0.8 M644.3,555.9l-0.1-1.8l-0.3-1.8l-0.4-1.7l-0.6-1.5l-0.7-1.3l-0.8-1.1
		l-0.9-0.7l-0.9-0.4h-0.9l-0.8,0.3l-0.7,0.7l-0.6,1l-0.4,1.3l-0.3,1.5l-0.1,1.7l0.1,1.8l0.3,1.8l0.4,1.7l0.6,1.5l0.7,1.3l0.8,1.1
		l0.9,0.8l0.9,0.4h0.9l0.8-0.3l0.7-0.7l0.6-1l0.4-1.3l0.3-1.5L644.3,555.9 M638.8,543.4L638.8,543.4l0.8-1.3l0.9-0.8l1-0.5l1.1-0.1
		l1.1,0.3l1.1,0.7l1.1,1l1,1.4l0.9,1.6l0.8,1.9l0.6,2.1l0.5,2.2l0.3,2.2l0.1,2.2 M650.2,556.3l-0.1,2.1l-0.3,2l-0.5,1.7l-0.6,1.5
		l-0.8,1.2l-0.9,0.8l-1,0.5l-1.1,0.1l-1.1-0.3l-1.1-0.7l-0.7-0.6 M540.3,588.1L540.3,588.1h-2l-2.8-0.2 M519.4,591.2L519.4,591.2
		l0.1-0.2v-0.1v-0.1v-0.1v-0.1v-0.1v-0.1 M536,562.6L536,562.6l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
		 M536,586.9L536,586.9l-0.2,0.2l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M536,562.6l-2.5,2.1l-1.4,1.7l-1.2,2
		l-0.9,2.1l-0.6,2.2l-0.3,2.3v2.3l0.4,2.2l0.7,2.1l1,1.8l1.3,1.6l1.6,1.2l1.8,0.9 M536,586.9l2.2,0.7l2,0.5h0.1 M540.3,559.7
		L540.3,559.7l-2.1,1.4l-2.2,1.5 M495.3,571.3v0.5l-0.3,2.7l-0.7,2.6l-1,2.6l-1.3,2.4l-1.5,2.3l-1.8,2l-0.5,0.5 M535.5,561.6
		l2.8-1.1l1.9-0.8h0.1 M519.4,562.8L519.4,562.8v0.2v0.1v0.2v0.2v0.2v0.2v0.2 M582.1,540.2L582.1,540.2 M519.4,562.8l20.9-3.1
		 M540.3,559.7l0.9,2.6l0.7,2.8l0.6,2.9l0.4,2.9l0.2,2.9v2.8l-0.2,2.7l-0.4,2.5l-0.6,2.4l-0.7,2.1l-0.9,1.8 M540.3,588.1l-20.9,3.1
		 M492.8,563.5l1,1.8l0.8,2.1l0.5,2.3l0.2,2.4l-0.2,2.4l-0.5,2.4l-0.8,2.4l-1.1,2.2l-1.3,2.1l-1.6,1.9l-1.8,1.6l-1.9,1.3l-2.1,1
		l-2.1,0.7l-1.1,0.2 M485.4,566.9l0.2-0.2l1.9-1.3l2.1-1l2.1-0.7l2.2-0.3h2.1l2.1,0.4l1.9,0.8l1.8,1.1l1.6,1.4l1.3,1.7l1.1,1.9
		l0.8,2.1l0.5,2.3l0.2,2.4l-0.2,2.4l-0.5,2.4l-0.8,2.4l-1.1,2.2l-1.3,2.1l-1.6,1.9l-1.8,1.6l-1.9,1.3l-2.1,1l-2.1,0.7l-2.2,0.3h-2.1
		l-2.1-0.4l-1.9-0.8l-1.8-1.1l-1.6-1.4l-1.3-1.7l-1.1-1.9l-0.8-2.1l-0.5-2.3l-0.1-1.6 M460.2,596l0.8,0.8l2.2,1.8l2.4,1.5l1.3,0.6
		 M491.4,546.1L491,546l-2.7-0.9l-2.8-0.6l-2.1-0.2 M519.7,590.2l15.8-2.4 M535.5,587.8l-1.9-0.9l-1.7-1.2l-1.4-1.6l-1.1-1.9
		l-0.8-2.1l-0.5-2.3l-0.1-2.4l0.2-2.5l0.6-2.5l1-2.4l1.3-2.2l1.6-2l2.9-2.5 M535.5,561.6l-15.8,2.4 M519.7,564l-1.4-0.1l-1-0.4
		l-0.9-0.6l-1.4-1.4 M514.9,594.1l0.9-1.3l1.1-1.1l1.5-1.1l1.2-0.4 M478.4,583.2l0.2-2.7l0.5-2.7l0.8-2.6l1.1-2.5l1.4-2.3l1.6-2.1
		l1.9-1.9l2.1-1.6l2.2-1.3l2.3-1l2.4-0.6l2.4-0.3l2.3,0.1l2.3,0.5l2.1,0.8l2,1.2l1.8,1.5l1.5,1.8l1.3,2.1l1,2.3l0.7,2.4l0.3,2.6v2.6
		l-0.3,2.7l-0.7,2.6l-1,2.6l-1.3,2.4l-1.5,2.3l-1.8,2l-2,1.8l-2.1,1.5l-2.3,1.2l-2.3,0.8l-2.4,0.4l-2.4,0.1l-2.3-0.3l-2.2-0.7
		l-2.1-1l-1.9-1.3l-1.6-1.7L481,593l-1.1-2.2l-0.8-2.4l-0.5-2.5L478.4,583.2 M514.9,561.5l-1.8-2.1l-2.1-1.9l-2.3-1.5l-2.5-1.2
		l-2.6-0.9l-2.7-0.5l-2.8-0.1l-2.8,0.2l-2.8,0.6l-2.8,1l-2.7,1.3l-2.6,1.6l-2.5,2l-2.3,2.2l-2.1,2.5l-1.8,2.7l-1.6,2.9l-1.3,3
		l-1,3.1l-0.7,3.2l-0.3,3.2v3.2l0.3,3.1l0.7,3l1,2.8l1.3,2.6l1.6,2.4l1.8,2.2l2.1,1.9l2.3,1.6l2.5,1.2l2.6,0.9l2.7,0.5l2.8,0.1
		l2.8-0.2l2.8-0.6l2.8-1l2.7-1.3l2.6-1.6l2.5-1.9l2.3-2.2l2.1-2.5l1.8-2.7 M498.3,551.2l2.5,0.2l2.8,0.6l2.7,0.9l2.5,1.3l2.4,1.6
		l2.1,1.9l0.3,0.3 M490.7,609.5l-1.5-0.1l-2.8-0.4l-2.7-0.8L481,607l-2.4-1.5l-2.2-1.8l-1.1-1.1 M1052.8,476.7l0.8-0.4l1.4-0.5
		l1.7-0.5l1.9-0.4l2.2-0.3l2.3-0.2l2.4-0.1h2.5h2.5l2.4,0.1l2.3,0.2l2.2,0.3l1.9,0.4l1.7,0.5l1.4,0.5 M1082.5,476.3l0.8,0.4
		 M1033.9,515.8l-1.7,0.4h-1.8h-0.1 M1056.2,482.3l-1.6-0.6l-1.7-0.5l-1.5-0.3h-0.4 M1051.1,513l1-0.2l1.5-0.3l1.5-0.3l1.2-0.2
		 M1056.2,482.3V482v-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.2l0.1-0.2l0.1-0.1 M1056.2,482.3l2,2.8l1.8,3l1.4,3.1l0.9,3.3l0.3,3.4
		l-0.4,3.3l-0.8,2.7l-1.2,2.6l-1.9,3l-2.2,2.6 M1051.1,480.9L1051.1,480.9l-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
		h-0.1 M1034.7,482.6l-2.5,0.7l-1.9,0.7h-0.1 M1033.9,482L1033.9,482l0.2,0.2l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1h0.1h0.1 M1030.3,484
		L1030.3,484l1.9-1.1l1.7-0.8 M1034.7,482.6l15.8-2.4 M1050.6,480.2l1.6-0.1h1.2h1.3l2.3,0.2 M1058.1,480.3l1.9,0.2l3.2,0.2l3.3,0.1
		h3.3l3.3-0.1l3.2-0.2l3.1-0.3l2.9-0.4l2.5-0.4 M1040.1,472.7l-0.3,0.4l-0.6,0.4l-1.1,0.4l-1.1,0.2 M1085,471l1.2,0.6l0.8,0.7
		l0.5,0.7l0.1,0.7l-0.3,0.7l-0.7,0.7l-1,0.7l-1.4,0.6l-1.7,0.6l-2,0.5l-2.2,0.4l-2.4,0.3l-2.6,0.2l-2.7,0.1h-2.7h-2.7l-2.7-0.1
		l-2.6-0.2l-2.4-0.3l-2.2-0.4l-2-0.5l-1.7-0.6l-1.4-0.6l-1-0.7l-0.7-0.7l-0.3-0.7l0.1-0.7l0.5-0.7l0.8-0.7l1.2-0.6l1.5-0.6l1.8-0.5
		l2.1-0.5l2.3-0.4l2.5-0.3l2.6-0.2l2.7-0.1h2.7l2.7,0.1l2.6,0.2l2.5,0.3l2.3,0.4l2.1,0.5l1.8,0.5L1085,471 M970.6,494.6l-0.9-0.6
		l-1-0.3l-1,0.1l-0.9,0.5l-0.8,0.8l-0.7,1.2l-0.5,1.4l-0.4,1.7l-0.2,1.9v2l0.2,2l0.4,2l0.5,1.9l0.7,1.8l0.8,1.6l0.9,1.3l1,1l1,0.6
		 M969.7,515.5l1,0.3l1-0.1l0.9-0.5l0.8-0.8l0.1-0.2 M961.8,502.1l0.1-2.1l0.3-2l0.5-1.7l0.6-1.5l0.8-1.2l0.9-0.8l1-0.5l1.1-0.1
		l1.1,0.3l1.1,0.7l1.1,1l1,1.4l0.9,1.6l0.8,1.9l0.6,2.1l0.5,2.2l0.3,2.2l0.1,2.2l-0.1,2.1l-0.3,2l-0.5,1.7l-0.6,1.5l-0.8,1.2
		l-0.9,0.8l-1,0.5l-1.1,0.1l-1.1-0.3l-1.1-0.7l-1.1-1l-1-1.4l-0.9-1.6l-0.8-1.9l-0.6-2.1l-0.5-2.2l-0.3-2.2L961.8,502.1 M1051.1,513
		l2.9-2.3l2.7-2.8l1.8-2.4l1.4-2.8l0.7-2.2l0.3-2.3l-0.1-2.3l-0.5-2.3l-1.2-2.8l-1.6-2.7l-2.8-3.5l-3.2-3.3l-0.3-0.3 M1051.1,480.9
		l-20.9,3.1 M1030.3,516.2l20.9-3.1 M957.9,500.4l0.1,2.7l0.3,2.8l0.5,2.8l0.6,2.7l0.8,2.6l1,2.4l1.1,2.2l1.2,1.9l1.3,1.7l1.3,1.3
		l1.4,1l1.4,0.6l1.4,0.3l1.4-0.1l1.3-0.5l1.2-0.9l1.1-1.2l1-1.5l0.8-1.8l0.6-2.1l0.5-2.3l0.3-2.5l0.1-2.7l-0.1-2.7L978,504l-0.5-2.8
		l-0.6-2.7l-0.8-2.6l-1-2.4l-1.1-2.2l-1.2-1.9l-1.3-1.7l-1.4-1.3l-1.4-1l-1.4-0.6l-1.4-0.3l-1.3,0.1l-1.3,0.5l-1.2,0.9l-1.1,1.2
		l-1,1.5l-0.8,1.8l-0.6,2.1l-0.5,2.3l-0.3,2.5L957.9,500.4 M907.6,506.8l-3.1-3.1 M907.7,524.8l2-3.9 M900.8,502.1l-258.9,38.7
		 M645.7,566.1l258.9-38.7 M638.9,561.4l3.1,3.1 M638.8,543.4l-2,3.9 M588.4,584.1L588.4,584.1l1.5-0.4l1.3-0.7l1.2-1l1.1-1.3l1-1.6
		l0.9-1.8l0.7-2.1l0.5-2.3l0.4-2.4l0.2-2.5v-2.6l-0.2-2.7l-0.3-2.7l-0.5-2.7l-0.7-2.6l-0.8-2.5l-0.9-2.3l-1.1-2.2l-1.2-2l-1.3-1.7
		l-1.4-1.5l-1.5-1.2l-1.6-0.8l-1.6-0.5l-1.5-0.1l-0.5,0.1 M515.6,594.6L515.6,594.6l0.9-1.3l1-0.9l0.2-0.1 M516.1,560.8l-0.6-0.7
		 M483.2,544.3h-0.6l-2.7,0.1l-2.8,0.5l-2.7,0.8l-2.7,1.1l-2.6,1.4l-2.5,1.7l-2.3,1.9l-2.2,2.2l-2,2.4l-1.8,2.6l-1.5,2.8l-1.3,2.9
		l-1,3l-0.8,3.1l-0.5,3.1L453,577l0.1,3l0.4,3l0.7,2.9l1,2.7l1.3,2.6l1.5,2.4l1.7,2.1l0.3,0.3 M582,540.2l-77.9,11.6 M515.2,595.1
		l73.3-11 M506.8,553l-15.4-6.9 M466.9,600.7l15.4,6.9 M515.5,560.1l-0.9-1.2l-0.7-0.8 M490.9,609.5h1.1l2.8-0.2l2.8-0.6l2.7-0.9
		l2.7-1.2l2.6-1.5l2.4-1.8l2.3-2l2.1-2.3l1.9-2.5l1.3-2 M1038.6,473.4L1038.6,473.4l-0.4,0.2l-0.6,0.2l-0.7,0.2h-0.2 M1036.8,474.1
		l-8.4,1.3 M1050.8,513.2L1050.8,513.2l0.8-0.1h1h1.3l1.5,0.1l1.5,0.1 M1056.7,513.3l1.5,0.2l3,0.2l3.1,0.1l3.1,0.1h3.1l3.1-0.1
		l3-0.2l2.9-0.3l2.8-0.4l2.6-0.4l2.5-0.5l2.3-0.6l2-0.7l1.8-0.7l1.5-0.8l1.3-1l0.8-0.9l0.5-1l0.2-1V505 M1032.9,515.9l17.9-2.7
		 M1026.2,518.7l2.6-0.5l2.5-0.9l2.3-1.1l0.7-0.4 M1097.9,475.1l-0.1-0.8l-0.4-1l-0.6-0.9l-0.9-0.9l-1.3-0.9l-1.7-0.8l-1.9-0.7
		l-2.2-0.7l-2.4-0.6l-2.6-0.5l-2.7-0.4l-2.9-0.3l-3-0.2l-3.1-0.2l-3.1-0.1h-3.1l-3.1,0.1l-3,0.2l-2.9,0.3l-2.8,0.4l-2.7,0.4
		l-2.5,0.5l-2.3,0.6l-2.1,0.7l-1.8,0.7l-1.6,0.8l-1.3,0.9l-0.9,0.9l-0.5,0.9 M1098,504.9v-29.7 M1035.6,478.8l-0.9-0.7l-2.2-1.3
		l-2.3-1l-2.4-0.7l-2.5-0.4l-2.8-0.1l-2.7,0.2l0,0 M972.9,526.7l53.3-8 M1019.6,474.8l-53.3,8 M957.7,500.1v1.4l0.2,2.7l0.3,2.7
		l0.5,2.7l0.7,2.6l0.8,2.5l0.9,2.3l1.1,2.2l1.2,2l1.3,1.7l1.4,1.5l1.5,1.2l1.6,0.8l1.6,0.5l1.5,0.1l0.6-0.1 M966.4,482.8h-0.3
		l-1.4,0.4l-1.3,0.7l-1.2,1l-1.1,1.3l-1,1.6l-0.8,1.9l-0.7,2.1l-0.5,2.3l-0.3,2.5l-0.1,2.6v0.8"
        />
        <line class="st4" x1="974.3" y1="504.1" x2="910.5" y2="513.6" />
        <line class="st5" x1="640.1" y1="554.2" x2="596.7" y2="560.7" />
      </g>
    </svg>
  ) : (
    <svg
      className="steeringLink"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1545 1080"
    >
      <g>
        <path
          class="st2"
          d="M729.6,437.4h-9v-1.9l3.2-3.3c1-1,1.6-1.7,1.9-2s0.5-0.7,0.6-1.1s0.2-0.7,0.2-1c0-0.5-0.1-0.9-0.4-1.2
		c-0.3-0.3-0.7-0.4-1.1-0.4c-0.5,0-1,0.1-1.5,0.3s-1,0.6-1.5,1l-1.5-1.7c0.6-0.5,1.2-0.9,1.6-1.1s0.9-0.4,1.4-0.5s1-0.2,1.7-0.2
		c0.8,0,1.5,0.1,2.1,0.4s1.1,0.7,1.4,1.2s0.5,1.1,0.5,1.8c0,0.6-0.1,1.1-0.3,1.7c-0.2,0.5-0.5,1.1-1,1.6c-0.4,0.5-1.2,1.3-2.3,2.3
		L724,435v0.1h5.6V437.4z"
        />
        <path
          class="st2"
          d="M739.8,431c0,2.2-0.4,3.9-1.1,5s-1.9,1.6-3.4,1.6c-1.5,0-2.6-0.6-3.4-1.7s-1.1-2.8-1.1-4.9
		c0-2.3,0.4-3.9,1.1-5s1.9-1.6,3.4-1.6c1.5,0,2.6,0.6,3.4,1.7S739.8,428.8,739.8,431z M733.5,431c0,1.6,0.1,2.7,0.4,3.4
		c0.3,0.7,0.7,1,1.4,1c0.6,0,1.1-0.3,1.4-1s0.4-1.8,0.4-3.4c0-1.6-0.1-2.7-0.4-3.4c-0.3-0.7-0.7-1-1.4-1c-0.6,0-1.1,0.3-1.4,1
		S733.5,429.4,733.5,431z"
        />
        <path
          class="st2"
          d="M741.5,436.2c0-0.5,0.1-0.9,0.4-1.1s0.6-0.4,1.2-0.4c0.5,0,0.9,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1
		c0,0.5-0.1,0.8-0.4,1.1s-0.6,0.4-1.1,0.4c-0.5,0-0.9-0.1-1.1-0.4S741.5,436.7,741.5,436.2z"
        />
        <path
          class="st2"
          d="M750.7,424.4c1.2,0,2.2,0.3,3,0.8s1.1,1.3,1.1,2.3c0,0.7-0.2,1.2-0.5,1.8s-0.9,1-1.8,1.4
		c1,0.5,1.7,1.1,2.1,1.6s0.6,1.2,0.6,1.8c0,1.1-0.4,1.9-1.2,2.5s-1.9,1-3.3,1c-1.4,0-2.5-0.3-3.3-0.9s-1.2-1.4-1.2-2.5
		c0-0.7,0.2-1.4,0.6-2s1-1.1,1.9-1.5c-0.7-0.5-1.3-1-1.6-1.5s-0.5-1.1-0.5-1.7c0-0.9,0.4-1.7,1.1-2.2S749.5,424.4,750.7,424.4z
		 M748.7,434c0,0.5,0.2,0.9,0.5,1.2s0.8,0.4,1.4,0.4c0.7,0,1.2-0.1,1.5-0.4s0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1s-0.9-0.7-1.6-1.1
		C749.4,432.4,748.7,433.1,748.7,434z M750.7,426.4c-0.5,0-0.8,0.1-1.1,0.4s-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.3,0.9s0.6,0.6,1.2,0.9
		c0.6-0.3,1-0.5,1.2-0.8s0.3-0.6,0.3-1c0-0.4-0.1-0.7-0.4-1S751.2,426.4,750.7,426.4z"
        />
        <path
          class="st2"
          d="M770.7,437.4h-2.7v-5.7c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.5-1.1-0.5c-0.7,0-1.2,0.3-1.5,0.8
		s-0.5,1.3-0.5,2.5v4.6h-2.7v-9.8h2l0.4,1.3h0.1c0.3-0.5,0.6-0.8,1.1-1.1s1.1-0.4,1.7-0.4c1.5,0,2.5,0.5,3,1.4h0.2
		c0.3-0.5,0.7-0.8,1.2-1.1c0.5-0.3,1.1-0.4,1.7-0.4c1.1,0,2,0.3,2.5,0.9c0.6,0.6,0.9,1.5,0.9,2.7v6.4h-2.7v-5.7
		c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.5-1.1-0.5c-0.7,0-1.1,0.2-1.5,0.7c-0.3,0.5-0.5,1.2-0.5,2.2V437.4z"
        />
        <path
          class="st2"
          d="M788.4,437.4h-2.7v-5.7c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.5-1.1-0.5c-0.7,0-1.2,0.3-1.5,0.8
		s-0.5,1.3-0.5,2.5v4.6h-2.7v-9.8h2l0.4,1.3h0.1c0.3-0.5,0.6-0.8,1.1-1.1s1.1-0.4,1.7-0.4c1.5,0,2.5,0.5,3,1.4h0.2
		c0.3-0.5,0.7-0.8,1.2-1.1c0.5-0.3,1.1-0.4,1.7-0.4c1.1,0,2,0.3,2.5,0.9c0.6,0.6,0.9,1.5,0.9,2.7v6.4h-2.7v-5.7
		c0-0.7-0.1-1.2-0.4-1.6c-0.2-0.4-0.6-0.5-1.1-0.5c-0.7,0-1.1,0.2-1.5,0.7c-0.3,0.5-0.5,1.2-0.5,2.2V437.4z"
        />
        <path class="st2" d="M807.9,424.6l-4.8,12.8h-2.4l4.8-12.8H807.9z" />
        <path
          class="st2"
          d="M822.3,431c0,2.2-0.4,3.9-1.1,5s-1.9,1.6-3.4,1.6c-1.5,0-2.6-0.6-3.4-1.7s-1.1-2.8-1.1-4.9
		c0-2.3,0.4-3.9,1.1-5s1.9-1.6,3.4-1.6c1.5,0,2.6,0.6,3.4,1.7S822.3,428.8,822.3,431z M816,431c0,1.6,0.1,2.7,0.4,3.4
		c0.3,0.7,0.7,1,1.4,1c0.6,0,1.1-0.3,1.4-1s0.4-1.8,0.4-3.4c0-1.6-0.1-2.7-0.4-3.4c-0.3-0.7-0.7-1-1.4-1c-0.6,0-1.1,0.3-1.4,1
		S816,429.4,816,431z"
        />
        <path
          class="st2"
          d="M827,435.5c-0.3,1.2-0.8,2.6-1.5,4.2h-1.9c0.4-1.6,0.7-3,0.9-4.4h2.5L827,435.5z"
        />
        <path
          class="st2"
          d="M833.3,424.4c1.2,0,2.2,0.3,3,0.8s1.1,1.3,1.1,2.3c0,0.7-0.2,1.2-0.5,1.8s-0.9,1-1.8,1.4
		c1,0.5,1.7,1.1,2.1,1.6s0.6,1.2,0.6,1.8c0,1.1-0.4,1.9-1.2,2.5s-1.9,1-3.3,1c-1.4,0-2.5-0.3-3.3-0.9s-1.2-1.4-1.2-2.5
		c0-0.7,0.2-1.4,0.6-2s1-1.1,1.9-1.5c-0.7-0.5-1.3-1-1.6-1.5s-0.5-1.1-0.5-1.7c0-0.9,0.4-1.7,1.1-2.2S832.1,424.4,833.3,424.4z
		 M831.3,434c0,0.5,0.2,0.9,0.5,1.2s0.8,0.4,1.4,0.4c0.7,0,1.2-0.1,1.5-0.4s0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1s-0.9-0.7-1.6-1.1
		C831.9,432.4,831.3,433.1,831.3,434z M833.3,426.4c-0.5,0-0.8,0.1-1.1,0.4s-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.3,0.9s0.6,0.6,1.2,0.9
		c0.6-0.3,1-0.5,1.2-0.8s0.3-0.6,0.3-1c0-0.4-0.1-0.7-0.4-1S833.8,426.4,833.3,426.4z"
        />
        <path
          class="st2"
          d="M848.1,437.4h-9v-1.9l3.2-3.3c1-1,1.6-1.7,1.9-2s0.5-0.7,0.6-1.1s0.2-0.7,0.2-1c0-0.5-0.1-0.9-0.4-1.2
		c-0.3-0.3-0.7-0.4-1.1-0.4c-0.5,0-1,0.1-1.5,0.3s-1,0.6-1.5,1l-1.5-1.7c0.6-0.5,1.2-0.9,1.6-1.1s0.9-0.4,1.4-0.5s1-0.2,1.7-0.2
		c0.8,0,1.5,0.1,2.1,0.4s1.1,0.7,1.4,1.2s0.5,1.1,0.5,1.8c0,0.6-0.1,1.1-0.3,1.7c-0.2,0.5-0.5,1.1-1,1.6c-0.4,0.5-1.2,1.3-2.3,2.3
		l-1.7,1.6v0.1h5.6V437.4z"
        />
        <path
          class="st2"
          d="M854.7,425.1c0-0.9,0.5-1.3,1.5-1.3s1.5,0.4,1.5,1.3c0,0.4-0.1,0.7-0.4,1c-0.2,0.2-0.6,0.3-1.1,0.3
		C855.2,426.4,854.7,425.9,854.7,425.1z M857.5,437.4h-2.7v-9.8h2.7V437.4z"
        />
        <path
          class="st2"
          d="M869.4,437.4h-2.7v-5.7c0-0.7-0.1-1.2-0.4-1.6s-0.7-0.5-1.2-0.5c-0.8,0-1.3,0.3-1.6,0.8s-0.5,1.3-0.5,2.5v4.6
		h-2.7v-9.8h2l0.4,1.3h0.1c0.3-0.5,0.7-0.8,1.2-1.1c0.5-0.2,1.1-0.4,1.8-0.4c1.1,0,2,0.3,2.6,0.9s0.9,1.5,0.9,2.7V437.4z"
        />
      </g>
      <line class="st1" x1="718.5" y1="531.4" x2="718.5" y2="445.5" />
      <line class="st1" x1="872.7" y1="505.5" x2="872.7" y2="445.5" />
      <line class="st1" x1="719.9" y1="464.6" x2="870" y2="464.6" />
      <polygon class="st2" points="874,464.6 841.4,457.7 841.4,471.4 " />
      <polygon class="st2" points="718.6,464.6 751.3,471.4 751.3,457.7 " />
      <path
        class="st3"
        d="M640,574.3l-1,0.5l-0.8,0.6 M636.5,542.2l1.7,1.5l1.3,0.6l0.4,0.1 M712.8,566.9l1-0.3l1.4-0.7l1.3-1.1l1.2-1.4
	l1-1.8l0.8-2.1l0.7-2.4l0.5-2.6l0.3-2.8l0.1-2.9l-0.1-3l-0.3-3l-0.5-3l-0.7-3l-0.8-2.8l-1-2.7l-1.2-2.5l-1.3-2.2l-1.4-1.9l-1.4-1.6
	l-1.5-1.3l-1.5-0.9l-1.5-0.5l-1.5-0.1l-0.3,0 M632.1,537.9l0.3,0.2l3.7,2.7l3.6,3.3l0.3,0.4 M640,574.3l-3.1,2.2 M601.9,524.9
	l-0.8,0l-3.1,0.2l-3.1,0.6l-3.1,1l-3,1.3l-2.9,1.7l-2.7,2l-2.6,2.3l-2.3,2.6l-2.1,2.9l-1.8,3.1l-1.5,3.2l-1.2,3.4l-0.9,3.5l-0.5,3.5
	l-0.2,3.5l0.2,3.4l0.5,3.3l0.9,3.2l1.2,3l1.5,2.8l1.8,2.5l1.2,1.4 M635.9,577.9L635.9,577.9L635.9,577.9l-0.1-0.1l-0.1-0.1l-0.2-0.1
	l-0.2-0.2 M635.8,541.5L635.8,541.5l0.1,0.3l0,0.2l-0.1,0.2l-0.1,0.2l-0.2,0.3l-0.2,0.2 M593.4,586.3l-0.9-1.1l-1.8-2.5l-1.5-2.8
	l-1.2-3l-0.9-3.2l-0.5-3.3l-0.2-3.4l0.2-3.5l0.5-3.5l0.9-3.5l1.2-3.4l1.5-3.2l1.8-3.1l2.1-2.9l2.3-2.6l2.6-2.3l2.7-2l2.9-1.7l3-1.4
	l3.1-1l3.1-0.6l3.1-0.2l0.4,0 M946.5,486.5L946.5,486.5h0.1l0.1,0l0.1,0 M967.6,527.9L967.6,527.9l-0.2-0.1l-0.1-0.2l-0.1-0.2
	l-0.1-0.3l0-0.3l0-0.3 M961.4,527.9L961.4,527.9l0.1,0l0.1,0l0.1-0.1l0.1-0.1 M939.7,530.9L939.7,530.9l2-0.2l0.8-0.1 M948.4,486.1
	l0.2-0.3l0.2-0.3l0.3-0.2l0.3-0.2l0.3-0.1l0.3,0 M1011.1,487.7l0,0.4l-0.4,0.9l-0.8,0.9l-1.2,0.9l-1.5,0.8l-1.9,0.8l-2.2,0.7
	l-2.5,0.7l-2.8,0.6l-3,0.5l-3.2,0.4l-3.4,0.3l-3.5,0.2l-3.6,0.1l-3.6,0l-3.6-0.1l-3.5-0.2l-3.4-0.3 M967.1,526.6l3.4,0.3l3.5,0.2
	l3.6,0.1l3.6,0l3.6-0.1l3.5-0.2l3.4-0.3l3.2-0.4l3-0.5l2.8-0.6l2.5-0.7l2.2-0.7l1.9-0.8l1.5-0.8l1.2-0.9l0.8-0.9l0.4-0.9l0-0.4
	 M943.5,495l1.7-0.8l0.7-0.6l0.3-0.6l-0.1-0.6l-0.5-0.6l-0.2-0.2 M946.8,486.6l-8.9,1.3 M944.2,490.7l1.1,0.4l1.1,0.6l0.7,0.7
	l0.2,0.7l-0.3,0.7l-0.7,0.7l-2,1 M997.1,492.2l0.2,0l2.6-0.6l2.4-0.6l2.1-0.7l1.7-0.8l1.4-0.8l1-0.9l0.6-0.9l0.2-0.9l-0.2-0.9
	l-0.6-0.9l-1-0.9l-1.4-0.8l-1.7-0.8l-2.1-0.7l-2.4-0.7l-2.6-0.6l-2.9-0.5l-3.1-0.4l-3.2-0.3l-3.4-0.2l-3.4-0.1h-3.5l-3.4,0.1
	l-3.4,0.2l-3.2,0.3l-3.1,0.4l-2.9,0.5l-2.7,0.6l-2.4,0.6l-2.1,0.7l-1.7,0.8l-1.4,0.8l-1,0.9l-0.6,0.9 M967.8,493l1.2,0.1 M939.7,497
	l-1.2-2.4l-1.3-2.1l-1.4-1.8l-1.5-1.4l-1.5-1.1l-1.5-0.7l-1.5-0.3l-1.2,0 M935.4,533.7l0.3-0.1l1.4-0.5l1.3-0.9l1.2-1.3
	 M872.3,495.7l0.4,0l1.5,0.1l1.5,0.5l1.5,0.9l1.5,1.3l1.5,1.6l1.4,1.9l1.3,2.2l1.2,2.5l1,2.7l0.9,2.8l0.7,3l0.5,3l0.3,3l0.1,3
	l-0.1,2.9l-0.3,2.8l-0.5,2.6l-0.7,2.4l-0.9,2.1l-1,1.8l-1.2,1.4l-1.3,1.1l-1.4,0.7l-1,0.2 M868.1,533.7l1.1,1.7l1.4,1.7l1.4,1.4
	l1.5,1l1.5,0.7l1.5,0.3l1.4-0.1l1.4-0.5l1.3-0.9l1.1-1.3l1-1.6l0.9-1.9l0.7-2.2l0.5-2.5l0.3-2.7l0.1-2.8l-0.1-2.9l-0.3-2.9l-0.5-2.9
	l-0.7-2.8l-0.9-2.7l-1-2.5l-1.1-2.3l-1.3-2l-1.4-1.7l-1.4-1.4l-1.5-1l-1.5-0.7l-1.5-0.3l-1.4,0.1l-1.4,0.5l-1.3,0.9l-1.1,1.3l-1,1.6
	l-0.9,1.9l-0.7,2.2l-0.1,0.6 M662,571.1L662,571.1l-2.1,0l-3-0.2 M640,574.3L640,574.3l0.1-0.2l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.2
	 M657.5,544.2l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1 M657.5,569.8L657.5,569.8l-0.2,0.2
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M638.2,575.4l-0.2,0.2 M657.5,544.2l-2.7,2.2l-1.5,1.8l-1.3,2.1l-0.9,2.2
	l-0.6,2.3l-0.3,2.4l0,2.4l0.4,2.3l0.8,2.2l1.1,1.9l1.4,1.7l1.7,1.3l1.9,0.9 M657.5,569.8l2.3,0.7l2.2,0.5l0.1,0 M662,541.1
	L662,541.1l-2.2,1.5l-2.3,1.6 M614.5,553.4l0,0.6l-0.3,2.8l-0.7,2.8l-1,2.7l-1.3,2.6l-1.6,2.4l-1.9,2.1l-0.5,0.5 M656.9,543.2l3-1.2
	l2-0.8l0.1,0 M640,544.4l0.1,0.1l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M706,520.6l-0.1,0 M640,544.4l22-3.3 M662,541.1
	l0.9,2.8l0.8,2.9l0.6,3l0.4,3l0.2,3v3l-0.2,2.9l-0.4,2.7l-0.6,2.5l-0.8,2.2l-0.9,1.9 M662,571.1l-22,3.3 M611.9,545.1l1.1,1.9
	l0.8,2.2l0.5,2.4l0.2,2.5l-0.2,2.6l-0.5,2.5l-0.8,2.5l-1.1,2.4l-1.4,2.2l-1.7,2l-1.9,1.7l-2.1,1.4l-2.2,1.1l-2.3,0.7l-1.2,0.2
	 M604,548.7l0.2-0.2l2-1.4l2.2-1.1l2.2-0.7l2.3-0.3l2.2,0l2.2,0.4l2,0.8l1.9,1.1l1.7,1.5l1.4,1.8l1.1,2l0.8,2.2l0.5,2.4l0.2,2.5
	l-0.2,2.6l-0.5,2.5l-0.8,2.5l-1.1,2.4L623,572l-1.7,2l-1.9,1.7l-2,1.4l-2.2,1.1l-2.2,0.7l-2.3,0.3l-2.2,0l-2.2-0.4l-2-0.8l-1.9-1.1
	l-1.7-1.5l-1.4-1.8l-1.1-2l-0.8-2.2l-0.5-2.4l-0.2-1.7 M577.5,579.4l0.8,0.8l2.3,1.9l2.6,1.6l1.3,0.6 M610.4,526.8l-0.4-0.2l-2.8-1
	l-3-0.6l-2.2-0.2 M640.2,573.3l16.7-2.5 M656.9,570.8l-2-0.9l-1.8-1.3l-1.5-1.7l-1.2-2l-0.9-2.2l-0.5-2.4l-0.1-2.6l0.2-2.6l0.6-2.6
	l1-2.5l1.3-2.3l1.6-2.1l3.1-2.6 M656.9,543.2l-16.7,2.5 M640.2,545.7l-1.5-0.1l-2-1l-1.5-1.5 M635.2,577.4l2.1-2.6l1.6-1.1l1.3-0.4
	 M596.7,566l0.2-2.8l0.5-2.8l0.9-2.7l1.2-2.6l1.5-2.5l1.7-2.3l2-2l2.2-1.7l2.3-1.4l2.4-1l2.5-0.7l2.5-0.3l2.5,0.1l2.4,0.5l2.2,0.9
	l2.1,1.2l1.9,1.6l1.6,1.9l1.3,2.2l1,2.4l0.7,2.6l0.3,2.7v2.8l-0.3,2.8l-0.7,2.8l-1,2.7l-1.3,2.6l-1.6,2.4l-1.9,2.1l-2.1,1.9
	l-2.2,1.6l-2.4,1.2l-2.5,0.9l-2.5,0.5l-2.5,0.1l-2.4-0.3l-2.3-0.7l-2.2-1.1l-2-1.4l-1.7-1.7l-1.5-2l-1.2-2.3l-0.9-2.5l-0.5-2.7
	L596.7,566 M635.2,543l-1.9-2.3l-2.2-2l-2.4-1.6l-2.6-1.3l-2.8-0.9l-2.9-0.5l-3-0.1l-3,0.3l-3,0.6l-2.9,1l-2.9,1.4l-2.7,1.7
	l-2.6,2.1l-2.4,2.4l-2.2,2.6l-1.9,2.8l-1.6,3l-1.3,3.2l-1,3.3l-0.7,3.4l-0.3,3.4v3.3l0.3,3.3l0.7,3.1l1,3l1.3,2.8l1.6,2.5l1.9,2.3
	l2.2,2l2.4,1.6l2.6,1.3l2.7,0.9l2.9,0.5l2.9,0.1l3-0.3l3-0.6l3-1l2.9-1.4l2.8-1.7l2.6-2.1l2.4-2.3l2.2-2.6l1.9-2.8 M617.7,532.2
	l2.7,0.2l3,0.6l2.8,1l2.7,1.4l2.5,1.7l2.3,2l0.3,0.3 M609.7,593.6l-1.6-0.1l-3-0.5l-2.9-0.8l-2.7-1.2l-2.6-1.6l-2.3-1.9l-1.2-1.2
	 M963.5,489.3l0.8-0.4l1.5-0.6l1.8-0.5l2.1-0.4l2.3-0.3l2.4-0.2l2.6-0.2l2.6-0.1l2.6,0.1l2.6,0.2l2.4,0.2l2.3,0.3l2.1,0.4l1.8,0.5
	l1.5,0.6 M994.8,488.9l0.8,0.4 M943.5,530.5l-1.8,0.4l-1.9,0l-0.1,0 M967.1,495.2l-3.4-1.1l-1.6-0.3l-0.4-0.1 M961.7,527.7l1.1-0.3
	l3.1-0.6l1.2-0.2 M967.1,495.2l0-0.3l0-0.3l0.1-0.3l0.1-0.3l0.1-0.3l0.1-0.2l0.1-0.2l0.1-0.1 M967.1,495.2l2.1,2.9l1.9,3.1l1.5,3.3
	l1,3.4l0.3,3.6l-0.4,3.5l-0.9,2.9l-1.2,2.7l-2,3.1l-2.3,2.8 M961.7,493.7l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1
	l-0.1-0.1l-0.1,0 M944.4,495.5l-2.7,0.8l-2,0.7l-0.1,0 M943.5,495l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0l0.1,0
	 M939.7,497L939.7,497l2-1.2l1.8-0.8 M944.4,495.5l16.7-2.5 M961.1,493l1.7-0.1l2.6,0l2.4,0.2 M969,493.2l2,0.2l3.4,0.2l3.4,0.1h3.5
	l3.4-0.1l3.4-0.2l3.2-0.3l3.1-0.4l2.7-0.5 M950,485.1l-1,0.8l-1.1,0.4l-1.1,0.2 M997.5,483.3l1.3,0.7l0.9,0.7l0.5,0.7l0.1,0.7
	l-0.3,0.7l-0.7,0.7l-1.1,0.7l-1.4,0.6l-1.8,0.6l-2.1,0.5l-2.3,0.4l-2.6,0.3l-2.7,0.3l-2.8,0.2l-2.9,0.1l-2.9-0.1l-2.8-0.2l-2.7-0.3
	l-2.5-0.3l-2.3-0.4l-2.1-0.5l-1.8-0.6l-1.4-0.6l-1.1-0.7l-0.7-0.7l-0.3-0.7l0.1-0.7l0.5-0.7l0.9-0.7l1.3-0.7l1.6-0.6l1.9-0.6
	l2.2-0.5l2.4-0.4l2.6-0.3l2.8-0.2l2.9-0.1h2.9l2.9,0.1l2.8,0.2l2.6,0.3l2.4,0.4l2.2,0.5l1.9,0.6L997.5,483.3 M872.2,505.8l0.8,0
	l1.2,0.3l1.2,0.7l1.1,1.1l1.1,1.4l1,1.7l0.8,2l0.7,2.2l0.5,2.3l0.3,2.3l0.1,2.3l-0.1,2.2l-0.3,2.1l-0.5,1.8l-0.7,1.6l-0.8,1.2
	l-1,0.9l-1.1,0.5l-0.3,0.1 M961.7,527.7l3.1-2.4l2.9-3l1.9-2.6l1.5-2.9l0.7-2.3l0.3-2.5l-0.1-2.4l-0.6-2.5l-1.2-3l-1.7-2.9l-3-3.7
	L962,494l-0.4-0.3 M961.7,493.7l-22,3.3 M939.7,530.9l22-3.3 M872.2,505.8l-154.8,23.1 M721.3,555.6l154.8-23.1 M712.7,566.9
	L712.7,566.9l1.6-0.4l1.4-0.7l1.3-1l1.2-1.3l1-1.7l0.9-1.9l0.7-2.2l0.6-2.4l0.4-2.6l0.2-2.7l0-2.8l-0.2-2.8l-0.4-2.8l-0.5-2.8
	l-0.7-2.7l-0.8-2.6l-1-2.5l-1.1-2.3l-1.3-2.1l-1.4-1.8l-1.5-1.5l-1.6-1.2l-1.6-0.9l-1.7-0.5l-1.6-0.1l-0.6,0.1 M635.9,577.9
	L635.9,577.9l1-1.4l1-1l0.3-0.2 M636.4,542.3l-0.7-0.7h0 M601.7,524.9l-0.7,0l-2.9,0.1l-2.9,0.5l-2.9,0.8l-2.8,1.2l-2.7,1.5
	l-2.6,1.8l-2.5,2l-2.3,2.3l-2.1,2.5l-1.9,2.7l-1.6,2.9l-1.4,3l-1.1,3.2l-0.8,3.2l-0.5,3.3l-0.2,3.3l0.1,3.2l0.4,3.1l0.7,3l1,2.9
	l1.3,2.7l1.6,2.5l1.8,2.3l0.4,0.4 M705.9,520.6l-82.2,12.3 M635.5,578.5l77.3-11.6 M626.6,534.1l-16.2-7.3 M584.6,584.4l16.2,7.3
	 M635.8,541.5l-1-1.2l-0.8-0.8 M609.9,593.6l1.1,0l2.9-0.3l2.9-0.6l2.9-0.9l2.8-1.3l2.7-1.6l2.6-1.9l2.4-2.1l2.2-2.4l2-2.6l1.4-2.1
	 M948.5,485.9L948.5,485.9l-0.4,0.2l-0.7,0.2l-0.7,0.2l-0.2,0 M946.5,486.5l-8.8,1.3 M961.4,527.9L961.4,527.9l0.8-0.1l1-0.1l1.3,0
	l1.4,0.1 M965.9,527.8l0.1,0l1.5,0.1 M967.6,527.9l1.6,0.2l3.2,0.2l3.2,0.2l3.3,0.1l3.3,0l3.3-0.1l3.2-0.2l3.1-0.3l3-0.4l2.8-0.5
	l2.6-0.6l2.4-0.6l2.1-0.7l1.9-0.8l1.6-0.9l1.4-1l0.9-1l0.5-1l0.2-1.1l0-0.3 M942.5,530.7l18.9-2.8 M935.4,533.7l2.7-0.6l2.6-0.9
	l2.4-1.2l0.7-0.5 M1011,487.7l-0.1-0.8l-0.4-1.1l-0.7-1l-1-0.9l-1.4-0.9l-1.8-0.9l-2-0.8l-2.3-0.7L999,480l-2.7-0.5l-2.9-0.4l-3-0.3
	l-3.1-0.3l-3.2-0.2l-3.3-0.1l-3.3,0l-3.3,0.1l-3.2,0.2l-3.1,0.3l-3,0.4l-2.8,0.5l-2.6,0.5l-2.4,0.6l-2.2,0.7l-1.9,0.8l-1.7,0.9
	l-1.4,1l-1,1l-0.6,0.9 M1011.1,519v-31.3 M945.3,491.5l-1-0.7l-2.3-1.3l-2.4-1l-2.6-0.8l-2.6-0.5l-3-0.1l-2.9,0.2l0,0 M879.2,542.1
	l56.2-8.4 M928.5,487.3l-56.2,8.4 M867.8,533.8l0.1,0.3l1.3,2.1l1.4,1.8l1.5,1.5l1.6,1.2l1.7,0.9l1.7,0.5l1.6,0.1l0.7-0.1
	 M872.3,495.7l-0.4,0l-1.5,0.5l-1.4,0.8l-1.3,1.1l-1.2,1.4l-1,1.7l-0.9,2l-0.7,2.2l-0.3,1.5"
      />
    </svg>
  )
}

export default SteeringLink
