import "./motorMount.scss"

import React from "react"

const MotorMount = ({ explode }) => {
  return explode ? <svg
  className="motorMount"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<g id="_x31_">
	<line class="st0" x1="1118.9" y1="388" x2="1113.6" y2="388"/>
	<line class="st0" x1="1116.3" y1="390.7" x2="1116.3" y2="385.4"/>
	<line class="st0" x1="1114.4" y1="389.9" x2="1118.1" y2="386.1"/>
	<line class="st0" x1="1114.4" y1="386.1" x2="1118.1" y2="389.9"/>
	<path class="st1" d="M545.3,483.7l0.5,9.2l1.4,9.6l2.3,9.9l3.2,10l4,10l4.8,9.8l5.5,9.5l6.1,9l6.6,8.5l7.1,7.7l7.4,6.9l7.6,6l7.7,5
		l7.7,3.9l7.6,2.8l7.4,1.6l7.1,0.4l6.6-0.8l6.1-2l1.9-0.9 M721.8,645.4l-6.8,3.9 M552.8,483.7l1,13.3l3.2,14.8l2.5,7.9l3.1,7.9
		l7.8,15.7l9.6,14.6l5.3,6.6l5.5,6.1l11.2,10.2l11,7.5l11,5.2l11.2,2.8l5.5,0.3l5.3-0.5l9.6-3.5l7.8-6.6l3.1-4.3l2.4-4.7
		 M721.8,536.6l-6.8,3.9 M756.5,655.7l15.5-9 M781,627.8v-14.3 M706.8,489.8l4.9-2.8 M835.3,585.3l5.3-3.1 M822.5,550.9l-5.3,3.1
		 M345,828.4l0.9-0.5 M353,838.9l-0.9,0.5 M342.2,820.8l1-0.6 M358.8,844.1l-1.1,0.6 M341.2,813.6l1.2-0.7 M365.1,847.3l-1.5,0.9
		 M341.8,807.2l1.7-1 M370.9,848.6l-1.7,1 M344.2,802.4l1.7-1 M375.8,847.6l-1.7,1 M348,799.5l1.7-1 M379.8,844.3l-2,1.1
		 M355.2,797.5l-2.2,1.3 M382.9,838.8l-2.9,1.7 M1102.5,258.2L1102.5,258.2L1102.5,258.2v0.2v0.1l0.1,0.1 M1101.5,258.8
		L1101.5,258.8L1101.5,258.8l-0.2-0.1h-0.1l0,0l0,0 M1131.8,281.4L1131.8,281.4l-0.1-0.2l-0.1-0.1 M1132,281.5L1132,281.5l-0.1-0.1
		h-0.1h-0.1h-0.1 M1131.5,281.5l0.1-0.3 M1102.6,258.5l-0.1,0.8 M1130.5,281.5L1130.5,281.5l0.2-0.1h0.1h0.1l0,0 M1101.5,258.8
		L1101.5,258.8l0.2-0.1h0.1h0.1l0,0 M1113.2,257.7L1113.2,257.7L1113.2,257.7v0.2v0.1l0.1,0.1 M1111.9,258.2L1111.9,258.2
		L1111.9,258.2L1111.9,258.2h0.2h0.1h0.1 M1142.4,280.9L1142.4,280.9l-0.1-0.2l-0.1-0.1 M1142.4,280.9L1142.4,280.9L1142.4,280.9
		h0.2h0.1h0.1 M1113.3,258l-0.1,0.8 M1142.2,281l0.1-0.3 M1112.5,258.2L1112.5,258.2L1112.5,258.2h-0.2h-0.1h-0.1 M1141.5,280.9
		L1141.5,280.9L1141.5,280.9h-0.2h-0.1h-0.1 M1083.8,271.9L1083.8,271.9l-0.1-0.1h-0.1h-0.1h-0.1 M1090.2,262.5L1090.2,262.5v0.1
		v0.1v0.1l0.1,0.1 M1089.2,263.1L1089.2,263.1l-0.1-0.1h-0.1h-0.1l0,0l0,0 M1083.6,270.9L1083.6,270.9l-0.1-0.1v-0.1v-0.1v-0.1
		 M1112.7,294.5l0.1,0.1h0.1 M1113.1,294.9L1113.1,294.9l-0.1-0.1v-0.1v-0.1l0,0 M1119.5,285.7L1119.5,285.7l-0.1-0.2l-0.1-0.1
		 M1119.4,285.7L1119.4,285.7L1119.4,285.7h0.2h0.1h0.1 M1084.2,271.6l-0.5,0.3 M1112.7,294.5l0.2-0.1 M1119.2,285.8l0.1-0.3
		 M1090.3,262.8l-0.1,0.8 M1083.6,270.9L1083.6,270.9v0.2v0.1l0,0 M1112.6,293.5L1112.6,293.5v0.2v0.1l0,0 M1118.5,285.7
		L1118.5,285.7L1118.5,285.7h-0.2h-0.1h-0.1 M1089.2,263.1L1089.2,263.1l0.2-0.1h0.1h0.1l0,0 M1214.9,450.6l-0.9,1l-0.8,2.3l0.2,2.7
		l1.3,2.7l2.1,2.3l2.6,1.6l2.7,0.6l2.4-0.5l1-0.6 M1214,436.6L1214,436.6l1.5,1.9l2.4,1.1l2.9,0.2l3-0.8l2.6-1.7l1.9-2.2l0.8-2.5
		l-0.3-2.3 M1205,418.6l1.2,1.1l2.1,0.4l2.7-0.4l2.8-1.2l2.5-1.7l1.9-2.1l0.9-2.1l-0.2-1.8l-0.1-0.1 M1192.8,392.4L1192.8,392.4
		l1,0.4l1.9-0.2l2.6-0.7l2.9-1l2.8-1.3l2.2-1.3l1.3-1.1l0.2-0.7 M1143.1,321l-0.5-0.6l-0.3-0.9l0.1-1.9l0.6-1.8l1-1.9l1.7-2.3
		l1.1-1.2l1.3-1.1l1.4-0.8l1.3-0.5l1.1-0.1l0.9,0.3l0.1,0.1 M1190.4,431.6L1190.4,431.6l-1.7,0.1l-1.8,1.3l-1.7,2.2l-1.4,2.8
		l-0.8,2.9l-0.1,2.7l0.3,1.4 M1236,411.2l0.8-0.1l3-0.8l2.6-1.7l1.9-2.2l0.8-2.5l-0.4-2.4l-0.1-0.2 M1227.3,394.9l0.6-0.2l2.8-1.2
		l2.5-1.8l1.9-2.1l0.9-2.1l-0.2-1.8l-0.1-0.2 M1219.1,377.7L1219.1,377.7l3-1l2.8-1.3l2.2-1.3l1.3-1.1l0.2-0.7 M1168.7,306.8
		l0.2-0.3l1.1-1.2l1.3-1.1l1.4-0.8l1.3-0.5l1.1-0.1l0.9,0.3l0,0 M1231.6,453.6L1231.6,453.6l2.4-0.5l1.1-0.7 M1226.2,425.1l1.6,0.6
		l2.9,0.2l3-0.8l2.6-1.7l1.9-2.2l0.8-2.5l-0.4-2.4v-0.1 M1217.2,407.5l1.4,0.2l2.7-0.4l2.8-1.2l2.5-1.7l1.9-2.1l0.9-2.1l-0.2-1.8
		l-0.1-0.1 M1207,385.3l0.8-0.1l2.6-0.7l2.9-1l2.8-1.3l2.2-1.3l1.3-1.1l0.2-0.7 M1155.7,310.8l1.2-2l2-2.4l2.2-1.8l2.1-0.9l1.7,0.1
		l0.4,0.2 M931.9,534.5l1.1-0.7l2.7-2.8l1.9-3.8l1.1-4.6l0.2-5.2l-0.7-5.7l-1.5-6l-2.3-6l-3.1-5.8l-3.7-5.4l-4.1-4.9l-4.5-4.1
		l-4.6-3.2 M914.5,476.2l-4.6-2.2l-4.5-1.1l-4.1,0.1l-3.7,1.2l-0.3,0.2 M901.1,522.1l1.3,1.8l7.9,6.5 M910.7,482.7l-7.9-2.8
		l-6.7,1.3l-4.5,5.1l-1.6,8.2l1.5,10l1.2,2.8 M979.5,577.9l3.3-2.2l4.7-4.4l4-5.4l3.2-6.3l2.4-7.1l1.5-7.9l0.7-8.5l-0.2-9.1
		l-1.1-9.5l-2-9.8l-2.8-10l-3.6-10l-4.4-9.9l-5.1-9.7l-5.7-9.4l-6.3-8.9l-6.7-8.3l-7.1-7.6l-7.4-6.8l-7.6-5.9l-7.7-5 M931.5,416.3
		l-7.7-4l-7.6-2.9l-7.4-1.8l-7.1-0.6l-6.7,0.5l-6.3,1.6l-3.7,1.6 M887,530.2l4.7,7.3l11,13.3l12,11.2l12.5,8.7 M927.7,422.8
		l-12.5-5.8l-12-2.7l-11.1,0.4l-9.7,3.6l-8,6.6l-5.9,9.3l-3.7,11.7l-1.3,13.7l1.2,15.1l3.6,15.9l5.9,16.2l4.3,8.6 M478.9,448.4
		l-0.5,0.3l-0.5,0.4l-0.5,0.5l-0.5,0.5l-0.4,0.6l-0.4,0.6l-0.3,0.7l-0.3,0.7 M509.8,569.9l-0.8-0.4l-0.9-0.3l-1-0.2l-1-0.1l-1,0.1
		l-1,0.2l-0.9,0.3l-0.8,0.4 M502.3,467.1l0.8-1.2l1.2-0.9l1.3-0.4h1.2 M436.3,586.2l-1.3-0.9l-1-1.1l-0.6-1.2l-0.2-1.2 M436.3,586.2
		l11.3,6.5 M436.3,507.8v78.4 M462.7,592.8l-1.7,0.7l-1.8,0.6l-2,0.3l-2.1,0.1l-2.1-0.1l-2-0.3l-1.8-0.6l-1.6-0.8 M447.6,592.8
		v-78.4 M447.6,514.4l-11.3-6.5 M447.6,514.4l1.6,0.8l1.8,0.6l2,0.4l2.1,0.1l2.1-0.1l2-0.4l1.8-0.6l1.6-0.8 M459.4,506l-1.9,0.3
		l-1.9-0.1l-1.8-0.4l-1.5-0.7l-1.1-0.9l-0.7-1l-0.1-1.1l0.4-1.1 M441.9,495.8l-5.7,3.3 M436.3,499.1L435,500l-1,1.1l-0.6,1.2
		l-0.2,1.2l0.2,1.2l0.6,1.2l1,1.1l1.3,0.9 M450.8,501l-8.9-5.1 M450.8,501l1.1-1.1l1.7-0.9l2.1-0.6l2.2-0.2l2.1,0.3l1.8,0.7l1.2,1.1
		l0.5,1.4l-0.2,1.5l-0.9,1.3l-1.5,1l-1.6,0.5 M468.3,511.1l-8.9-5.1 M475.5,452.7l26.4,15.2 M475.5,452.7l-2.7,6.7l-3.2,6.5
		l-3.8,6.3l-4.2,5.9l-4.6,5.4l-4.9,4.8l-5.1,4.1l-5.2,3.4 M547.1,478.6v3.9 M509.8,461.4l3.8-2.2 M547.1,478.6l-3.8,2.2
		 M513.6,459.3l33.6,19.4 M462.7,592.8l39.6-22.9 M468.3,511.1l-5.7,3.3 M462.7,514.4v78.4 M502.3,569.9V467.1 M502.3,467.1v0.1v0.1
		v0.1v0.1v0.1v0.1v0.1v0.1 M501.9,467.9l-2.7,6.7l-3.2,6.5l-3.8,6.3l-4.2,5.9l-4.6,5.4l-4.9,4.8l-5.1,4.1l-5.2,3.4 M613.1,490.2
		l-92-53.1 M512.6,449.6l-2.2,0.8l-2.6,0.3l-2.6-0.3l-2.2-0.8l-1.4-1.2l-0.5-1.5l0.5-1.5l1.4-1.2l2.2-0.8l2.6-0.3l2.6,0.3l2.2,0.8
		l1.4,1.2l0.5,1.5l-0.5,1.5L512.6,449.6 M583.9,486.4l-1.4,1.2l-0.5,1.5l0.5,1.5l1.4,1.2l2.2,0.8l2.6,0.3l2.6-0.3l2.2-0.8l1.4-1.2
		l0.5-1.5l-0.5-1.5l-1.4-1.2l-2.2-0.8l-2.6-0.3l-2.6,0.3L583.9,486.4 M498.5,437.1l2.4-1.1l2.8-0.9l3-0.5l3.1-0.2l3.1,0.2l3,0.5
		l2.8,0.9l2.4,1.1 M478.9,448.4l19.6-11.3 M506.8,464.5l-27.9-16.1 M506.8,464.5h0.4l0.4,0.1l0.4,0.1l0.4,0.1l0.4,0.1l0.4,0.1
		l0.3,0.2l0.3,0.2 M545.3,483.7l1.9-1.1 M645.2,535.6l-2.8-0.8l-4.8-2.2l-4.8-3.3l-4.6-4.3l-4.2-5.1l-3.7-5.7l-3.1-6l-2.3-6.2
		l-1.4-6.1l-0.5-5.8 M613.1,490.2l-5.7,3.3 M644.7,539.1h-3.3l-4.6-1l-4.8-2.2l-4.8-3.3l-4.6-4.3l-4.2-5.1l-3.7-5.7l-3-6l-2.3-6.2
		l-1.4-6.1l-0.5-5.8 M656.5,521.8l-0.3,4.6 M603.7,491.3l3.8,2.2 M603.7,491.3l-14.9,8.6 M603.7,491.3l0.5,6.3l1.4,6.6l2.3,6.7
		l3.1,6.6l3.9,6.3l4.4,5.7l4.9,5l5.1,4.1l5.2,3l5.1,1.9l3.4,0.6 M588.8,499.9l0.5,6.3l1.4,6.6l2.3,6.7l3.1,6.6l3.9,6.3l4.4,5.7
		l4.9,5l5.1,4.1l5.2,3l5.1,1.9l4.9,0.6l4.4-0.6l3.9-1.8l3.1-3l2.3-4.1l1.4-5l0.5-5.7 M576.9,497.5h1.6l5.8,0.8l4.6,1.6 M664.5,528.8
		h-0.4l-2.6,0.3l-2.2,0.8 M659.3,529.9l-1.4,1.2l-0.5,1.5l0.5,1.5l1.4,1.2l2.2,0.8h0.1 M645.4,532.5l14.9-8.6 M645.4,532.5l2.3,2.1
		l1.6,3.3l0.1,1.5 M668.1,519.4l-2.2,1.3 M665.9,520.7l-3.8-2.2 M662.2,518.5l-5.7,3.3 M660.3,523.9l-3.8-2.2 M545.3,483.7l1.5-0.6
		l1.7-0.3l1.8,0.1l1.7,0.4l0.9,0.4 M543.4,484.7l1.9-1.1 M703.7,663.3l11.3-6.5 M709.1,486.7L709.1,486.7l0.5-0.2l0.5-0.1l0.5,0.1
		l0.6,0.2l0.6,0.3 M671.6,510.4l-13.2,34.3 M576.9,497.5l0.4,6.8l1.3,7.2l2.2,7.3l3,7.3l3.7,7.1l4.3,6.8l4.9,6.2l5.3,5.6l5.5,4.7
		l5.7,3.8l5.7,2.8l5.5,1.7l5.3,0.5l4.9-0.6l4.3-1.8l3.7-2.9l3-3.9l2.2-4.8l1.3-5.6l0.4-6.3 M576.9,497.5l-24.1-13.9 M658.4,544.6
		l-9-5.2 M715,649.4l35.5,20.5 M715,649.4l-2.2-1.8l-1.9-2.6l-1.3-2.9l-0.4-2.8l0.1-1 M702.8,634.5l0.1,3.1l1,4.4l1.9,4.5l2.6,4.2
		l3.2,3.5l3.5,2.6 M715,656.8l29.2,16.9 M737.6,605.6l-4.9-3.3l-4.7-4.2l-4.4-5l-3.9-5.6l-3.3-6.1l-2.6-6.3l-1.7-5.8 M780.2,630.2
		l-42.6-24.6 M737.6,605.6l-33.9,19.6 M696.4,486.4l0.1-0.1l0.9-0.3l1-0.1l1.1,0.1l1.1,0.3l1.1,0.6 M715,547.9l-11.3,6.5
		 M775.1,614.4l0.9,1.7l0.9,1.7l0.8,1.7l0.8,1.7l0.7,1.7l0.6,1.7l0.6,1.7l0.5,1.7 M715,540.5l-2.2-1.8l-1.9-2.6l-1.3-2.9l-0.4-2.8
		l0.1-1 M715,540.5l52.5,30.3 M702.8,525.6l0.1,3.1l1,4.4l1.9,4.5l2.6,4.2l3.2,3.5l3.5,2.6 M715,547.9l52.8,30.5 M711.7,487
		l110.8,63.9 M840.6,582.3v139.4 M840.6,721.7l-4.9,2.8 M767.8,578.4l0.9,0.5 M756.5,584.9l11.3-6.5 M738.3,758l0.2,1.2l0.6,1.2
		l1,1.1l1.3,0.9 M822.5,550.9l3.5,2.4l3.4,3.1l3.1,3.7l2.7,4.2l2.3,4.5l1.7,4.6l1,4.5l0.3,4.3 M741.4,762.4l16.2,9.4 M741.4,684
		v78.4 M775.1,614.4l3.8-2.2 M781,613.6l-5.8,0.8 M781,613.6l0.6-0.1h0.5l0.3,0.1 M811.2,609.2l-17.6,2.5 M757.6,693.3l-16.2-9.4
		 M764.6,682.1l-1.9,0.3l-1.9-0.1l-1.8-0.4l-1.5-0.7l-1.2-0.9l-0.7-1l-0.1-1.1l0.4-1.1 M741.4,675.3l5.7-3.3 M741.4,684l-1.3-0.9
		l-1-1.1l-0.6-1.2l-0.2-1.2l0.2-1.2l0.6-1.2l1-1.1l1.3-0.9 M757.6,693.3l1.8-0.4l1.8-0.4l1.7-0.5l1.7-0.5l1.6-0.6l1.6-0.7l1.5-0.7
		l1.4-0.8 M757.6,771.7v-78.4 M770.8,767.2l-1.4,0.8l-1.5,0.7l-1.6,0.7l-1.6,0.6l-1.7,0.5l-1.7,0.5l-1.8,0.4l-1.8,0.4 M788.5,551.1
		l-1.4,1.2l-0.5,1.5l0.5,1.5l1.4,1.2l2.2,0.8l2.6,0.3l2.6-0.3l2.2-0.8l1.4-1.2l0.5-1.5l-0.5-1.5l-1.4-1.2l-2.2-0.8l-2.6-0.3
		l-2.6,0.3L788.5,551.1 M701.8,486.9L813,551.1 M677.2,501.1l24.5-14.2 M773.1,594.2l3.1,1.3l4.1,3.6l1.8,3.4l0.5,3.7l-0.8,3.1
		l-1.8,2.3l-1.1,0.8 M814.9,607l-36,5.2 M738.3,683.3l-34.6-20 M703.7,663.3l-3.5-2.6l-3.2-3.5l-2.6-4.2l-1.9-4.5l-1-4.4v-4l1-3.3
		l1.9-2.3l2.6-1.1l3.2,0.2l3.5,1.4 M703.7,635l52.6,30.4 M756.5,584.9l-52.8-30.5 M703.7,554.4l-3.5-2.6l-3.2-3.5l-2.6-4.2l-1.9-4.5
		l-1-4.4v-4l1-3.3l1.9-2.3l2.6-1.1l3.2,0.2l3.5,1.4 M703.7,526.1l52.8,30.5 M756.5,556.6l3.5,2.6l3.2,3.5l2.6,4.2l1.9,4.5l1,4.4v4
		l-1,3.3l-1.9,2.3l-2.6,1.1l-3.2-0.2l-3.5-1.4 M788.5,565.3l-111.3-64.2 M671.6,500.7l0.6-0.3l0.6-0.2l0.7-0.1h0.7l0.7,0.1l0.8,0.2
		l0.8,0.3l0.8,0.4 M671.6,500.7v58 M671.6,558.8l-0.5,8.7l-1.4,8l-2.3,7.2l-3.2,6.3l-4,5.3l-4.8,4.3l-5.5,3.2l-6.1,2l-6.6,0.8
		l-7.1-0.4l-7.4-1.6l-7.6-2.8l-7.7-3.9l-7.7-5l-7.6-6l-7.4-6.9l-7.1-7.7l-6.6-8.5l-6.1-9l-5.5-9.5l-4.8-9.8l-4-10l-3.2-10l-2.3-9.9
		l-1.4-9.6l-0.5-9.2 M543.4,484.7v-3.9 M543.4,480.8l-33.6-19.4 M509.8,461.4v3.9 M509.8,465.4v104.5 M509.8,569.9l228.5,131.9
		 M763.1,658.5l-2.8-0.9l-3.9-1.9 M703.7,625.2l52.8,30.5 M703.7,625.2l-4.9-3.3l-4.7-4.2l-4.4-5l-3.9-5.6l-3.3-6.1l-2.6-6.3
		l-1.8-6.3l-0.9-6v-5.6l0.9-5l1.8-4.2l2.6-3.3l3.3-2.3l3.9-1.1h4.4l4.7,1.2l4.9,2.3 M756.5,594.7l-52.8-30.5 M756.5,594.7l0.3,0.2
		l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2 M758.9,596.2l49-7.1 M813,551.1l4.4,3.1l4.2,3.9l3.9,4.7l3.4,5.2
		l2.8,5.6l2.1,5.7l1.3,5.6l0.4,5.3 M813,551.1l-24.5,14.2 M807.9,589.1l-1.7-3.7l-1.9-3.6l-2.2-3.5l-2.4-3.2l-2.6-3l-2.8-2.7
		l-2.9-2.3l-2.9-1.9 M807.9,589.1l3.8-2.2 M811.7,587l2.7-0.9l2.8,0.3l2.4,1.5l1.7,2.4l0.8,3l-0.2,3.4l-0.6,2 M755.9,677.1L747,672
		 M755.9,677.1l1.1-1.1l0.3-0.2l1.8-0.9l2.1-0.5l2.2-0.1l2.1,0.4l1.7,0.8l1.1,1.2l0.4,1.4l-0.4,1.5l-1.1,1.3l-0.5,0.4l-1.8,0.8
		l-0.5,0.1 M773.4,687.2l-8.9-5.1 M793.6,611.8l17.6,10.2 M793.6,611.8l-3.6,0.5l-3.7,0.6l-3.8,0.7 M782.4,613.6l-0.4,10.3l-1,3.9
		 M747,672l5.3-3.5l5.2-4.2l5-4.9l4.7-5.6l4.3-6l3.8-6.4l3.2-6.7l2.6-6.8 M770.8,688.8l2.6-1.5 M770.8,767.2v-78.4 M770.8,767.2
		l64.9-37.5 M835.7,590.3v139.4 M821.3,598.6l14.4-8.3 M821.3,598.6l-0.9,2.1l-1.2,2l-1.3,1.7l-1.4,1.4l-1.5,1.1 M811.2,609.2
		l3.8-2.2 M811.2,621.9v-12.7 M773.4,687.2l5.9-3.9l5.8-4.9l5.5-5.8l5-6.5l4.5-7l3.8-7.4l3.1-7.6l2.3-7.6l1.4-7.4l0.5-7.1
		 M398.9,806.3l0.2,0.2l0.5,0.5 M355.9,847.1l4-2.3 M338,813.4l3.7-2.2 M362.7,851.6l3.4-2 M401.6,784.5l1,0.6l3.4,2.8l3.2,3.6
		l2.7,4.1l2,4.5l1.3,4.5l0.4,4.1 M338.4,805.8l3.3-1.9 M369.3,853.6l3.4-1.9 M408.7,801.6l2.1-0.8 M400.4,798.4l1.6-0.5h1.8l2,0.6
		l1.8,1.4l1.1,1.7 M408.7,801.6l0.4,1.9l-0.2,2l-0.8,2.1l-1.4,1.9l-1.4,1.1 M340.8,799.7l3.5-2 M405.4,810.6l-1.4,0.5l-1.3-0.1
		l-1.3-0.7l-0.2-0.2 M405.4,810.6l1.7,1.9 M399.3,807.1l-0.6-1.9l-0.3-1.9l0.1-2l0.8-1.8l1.2-1.1 M400.4,796.2v2.2 M399.1,806.7
		l0.2,0.2 M399.1,799.7l1.5,0.9l1.1,1.4l0.6,1.7v1.8l-0.6,1.9l-0.5,0.7 M396.7,840.6L375.2,853 M407.1,812.5l-1.7,0.6l-1.6-0.1
		l-1.6-0.9l-1.3-1.5 M398.6,806.1l-0.4-1.3l-0.4-2.9l0.3-2.7l1.2-2.1l1-0.8 M400.4,796.2l1.9-0.6h2.1l2.3,0.8l2.3,1.7l1.7,2.7
		 M410.8,800.8l0.7,2.9l-0.3,3l-1.2,2.8l-1.8,2.2l-1.1,0.8 M389.8,782.8l1.2-0.5l3.1-0.4l3.5,0.7l3.6,1.8l3.6,2.8l3.3,3.6l2.9,4.2
		l2.3,4.6l1.5,4.7l0.7,4.5l-0.2,4.1l-1,3.4l-1.8,2.6l-1.3,1 M367.2,784.2l-0.8-0.8 M344.9,795.9l21.5-12.4 M348.9,801l1.5-0.8
		 M400.6,838.3l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M400.6,838.3l-21.5,12.4 M401.4,837.5l-0.4-1.2
		l-0.4-1 M401.4,837.5l-21.5,12.4 M376.8,844.1l1.6-0.9 M372,782.1h-0.2h-0.2h-0.2h-0.2H371h-0.2h-0.2h-0.2 M370.8,782.1l-21.5,12.4
		 M373.8,784.8l-0.5-0.8l-1.3-1.9 M372,782.1l-21.5,12.4 M355.3,799.4l-1.7,1 M403.9,833.3l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2
		l0.1-0.2l0.1-0.2l0.1-0.2 M403.9,833.3l-21.5,12.4 M378.8,839.3l2-1.2 M376,825.2l-0.6,0.1l-2.2-0.3l-2.4-1.4l-2.1-2.4l-1.6-2.9
		l-0.8-3l0.1-2.7l1-1.9l0.2-0.2 M404.3,832.1l-0.7-1.1l-0.7-1.1l-1.2-1.4 M404.3,832.1l-21.5,12.4 M380.3,788.3l-0.1-0.5
		 M361.3,800.4l-2.4,1.4 M378.5,783.4l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1 M377.1,782.9
		l-21.5,12.4 M378.5,783.4L357,795.8 M380.2,787.9l-21.5,12.4 M380.2,787.9l-0.3-1.2l-0.4-1.2l-1-2.1 M400.8,820.8l1.2,0.7
		 M379.3,833.3l21.5-12.4 M385.9,792.9l0.1-1.3 M405.3,826.4l-21.5,12.4 M405.3,826.4v-0.2V826v-0.2v-0.2v-0.2v-0.2V825v-0.2
		 M385.9,792.9l-21.5,12.4 M387,793.7L387,793.7l-0.3-0.3l-0.2-0.2l-0.2-0.2l-0.2-0.2 M402,821.6L380.5,834 M405.3,824.8l-21.5,12.4
		 M405.3,824.8l-0.8-1l-0.9-0.9l-1.6-1.3 M399.6,814l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.2 M399.6,814
		l1.2,0.3 M378.1,826.4l21.5-12.4 M385.3,787.2l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1
		 M383.9,786.2l-21.5,12.4 M385.3,787.2l-21.5,12.4 M386,791.5l-21.5,12.4 M386,791.5v-1.1l-0.2-1.2l-0.5-2.1 M392.8,800.2l-0.2-0.3
		l-0.2-0.3l-0.2-0.3L392,799l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3 M396.8,806.9l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3
		l-0.2-0.3l-0.2-0.3l-0.2-0.3 M391.1,798l-21.5,12.4 M391.1,798l0.5-1 M396.8,806.9l1-0.2 M375.3,819.3l21.5-12.4 M395.5,804.3
		L374,816.8 M395.5,804.3l0.8-0.6 M356.3,803.2l-0.3,1.3l-0.3,4.2l0.6,4.7l1.4,4.9l2.2,4.9l2.9,4.6l3.4,4l3.7,3.3l3.8,2.3l3,1.1
		 M404.6,818.2l-21.5,12.4 M404.6,818.2V818v-0.2v-0.2v-0.2v-0.2V817l-0.1-0.2l-0.1-0.2 M400.7,814.2l-21.5,12.4 M404.2,816.4
		l-21.5,12.4 M404.2,816.4l-0.9-0.7l-0.9-0.6l-1.7-0.8 M390.6,791.8l-0.7,0.3l-0.3,0.2 M391.6,797l-21.5,12.4 M391.6,797l0.3-0.9
		l0.1-1l-0.1-1.9 M391.9,793.1l-21.5,12.4 M391.9,793.1l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2
		 M390.6,791.8l-21.5,12.4 M400.5,810.9L400.5,810.9l0.6-0.5l0.9-1.1 M402,809.4l-21.5,12.4 M402,809.4l-0.1-0.2l-0.1-0.2l-0.1-0.2
		l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M397.9,806.7l-21.5,12.4 M401.2,807.6L379.7,820 M401.2,807.6l-0.9-0.4l-0.9-0.3
		l-1.5-0.2 M396.6,799.1l-0.9-0.1h-0.8l-1,0.4 M396.3,803.7l-21.5,12.4 M396.3,803.7l0.5-0.6l0.4-0.8l0.4-1.6 M397.6,800.8
		l-21.5,12.4 M397.6,800.8l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M396.6,799.1l-21.5,12.4
		 M369.6,845.3l-3.5-0.1l-3.7-1.2l-3.8-2.3l-3.7-3.3l-3.4-4l-2.9-4.6l-2.2-4.9L345,820l-0.6-4.7l0.3-4.2l1.2-3.5l2-2.6l2.7-1.6
		l3.2-0.5l3.6,0.7l3.8,1.8l3.8,2.8l3.6,3.7l3.1,4.3l2.6,4.8l1.8,4.9l1,4.9l0.1,4.5l-0.8,3.9l-1.6,3.1l-2.4,2.1L369.6,845.3
		 M371.3,849.4l-0.7-1.6 M371.3,849.4l0.6,1.2l0.8,1.1l1.5,1.7 M375.2,853.1h-0.1H375h-0.1h-0.1h-0.1h-0.1h-0.1h-0.1 M374.1,848.6
		l0.5,1.2l0.3,1.2l0.4,2.1 M374.1,848.6l-0.8-1.6 M374.7,846.2l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1
		l-0.2,0.1 M375.7,847.6l-1-1.4 M379.1,850.7l-0.9-0.7l-0.9-0.8l-1.5-1.7 M379.9,849.9l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M377.8,845.5l0.7,1.1l0.6,1.2l0.8,2.1 M377.8,845.5l-1-1.4 M377.7,842.5l-0.1,0.2l-0.1,0.2
		l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M378.9,843.7l-1.2-1.1 M382.4,845.7l-0.9-0.4l-0.9-0.5l-1.6-1.2
		 M382.8,844.5l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M380,840.5l0.8,0.9l0.8,1.1l1.2,2
		 M380,840.5l-1.2-1.1 M379.2,837.2v0.3v0.3v0.3v0.3v0.3v0.3l-0.1,0.3l-0.1,0.3 M380.4,838l-1.2-0.8 M383.8,838.8H383l-0.9-0.1
		l-1.6-0.6 M383.8,837.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2v0.2 M380.5,834l0.9,0.7l0.9,0.8l1.5,1.7 M380.5,834l-1.2-0.7 M379,830.8v0.3
		v0.3v0.3v0.3v0.3v0.3v0.3v0.3 M380.2,831.1l-1.2-0.3 M383.1,830.6l-0.7,0.3l-0.7,0.2h-1.5 M382.7,828.8l0.1,0.2l0.1,0.2v0.2v0.2
		v0.2v0.2v0.2v0.2 M379.2,826.7l0.9,0.4l0.9,0.6l1.6,1.2 M379.2,826.7l-1.2-0.3 M377.2,823.8l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3
		l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3 M378.3,823.6l-1,0.2 M380.5,821.8l-0.5,0.7l-0.5,0.6l-0.6,0.3 M379.7,820l0.1,0.2l0.1,0.2
		l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2 M376.4,819.1h0.8l0.9,0.2l1.6,0.7 M376.4,819.1l-1,0.2 M374,816.8l0.2,0.3
		l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3 M374.8,816.1l-0.8,0.6 M376.1,813.2l-0.2,0.9l-0.3,0.8l-0.8,1.2
		 M375.1,811.5l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2 M372.4,811.8l0.4-0.2l0.8-0.1h1.5 M372.1,812
		l-0.8,0.6 M369.6,810.4l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3 M370.1,809.4l-0.5,1 M370.4,805.5
		l0.1,1.1v1.1l-0.4,1.7 M369.1,804.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2 M366.8,805.9l0.5-0.7
		l0.6-0.5l1.2-0.6 M366.8,805.9l-0.5,1 M364.4,805.3l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2 M364.6,803.9
		l-0.1,1.3 M363.8,799.6l0.3,1.2l0.3,1.2l0.1,2 M362.4,798.6l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1
		 M361,801.5l0.2-0.9l0.4-0.8l0.8-1.2 M361,801.5l-0.1,1.4 M358.9,801.8l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1
		l0.3,0.1 M358.7,800.3l0.2,1.5 M357,795.8l0.6,1.2l0.5,1.2l0.6,2.1 M355.6,795.3l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1
		l0.2,0.1l0.2,0.1 M355.2,799.1l-0.1-1.1l0.1-1l0.4-1.6 M355.2,799.1l0.3,1.5 M353.6,800.3h0.2h0.2h0.2h0.2h0.2h0.2h0.2l0.2,0.1
		 M353,798.7l0.6,1.6 M350.5,794.5l0.8,1.1l0.7,1.1l1,2.1 M349.3,794.6h0.2h0.2h0.2h0.2h0.2h0.2h0.2h0.2 M349.9,798.9l-0.4-1.2
		l-0.2-1.2l-0.1-2 M349.9,798.9l0.6,1.6 M348.9,801l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1h0.2h0.2 M348,799.5l0.9,1.5
		 M344.9,795.9l0.9,0.8l0.9,0.9l1.4,1.9 M343.9,796.4l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M345.6,801
		l-0.6-1.2l-0.5-1.2l-0.6-2.1 M345.6,801l0.9,1.5 M345.3,803.7l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.1l0.2-0.1l0.2-0.1l0.2-0.1
		 M344.2,802.4l1.1,1.3 M340.8,799.7l0.9,0.5l0.9,0.6l1.6,1.5 M340.1,800.8l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1
		l0.1-0.1l0.1-0.1 M342.6,805.1l-0.8-1l-0.7-1.1l-1-2.1 M342.6,805.1l1.1,1.3 M343.1,808.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2
		l0.1-0.2l0.1-0.2l0.1-0.2 M341.8,807.2l1.2,1 M338.4,805.8l0.9,0.2l0.9,0.3l1.7,0.9 M338.1,807.2V807v-0.2v-0.2v-0.2v-0.2V806v-0.2
		v-0.2 M341.2,810.9l-0.9-0.8l-0.8-1l-1.4-1.9 M341.2,810.9l1.2,0.9 M342.4,814.1v-0.3v-0.3v-0.3v-0.3v-0.3v-0.3V812v-0.3
		 M341.2,813.6l1.2,0.5 M338,813.4l0.8-0.2h0.8l1.6,0.3 M338.2,815.1v-0.2v-0.2v-0.2v-0.2v-0.2v-0.2v-0.2v-0.2 M341.6,817.8
		l-0.9-0.5l-0.9-0.7l-1.6-1.5 M341.6,817.8l1.2,0.5 M343.4,820.9l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3
		l-0.1-0.3 M342.2,820.8l1.1,0.1 M339.7,822l0.6-0.5l0.6-0.4l1.4-0.3 M340.2,823.8l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2
		l-0.1-0.2l-0.1-0.2l-0.1-0.2 M343.7,825.4l-0.9-0.2l-0.9-0.4l-1.7-1 M343.7,825.4h1.1 M345.9,828l-0.1-0.3l-0.1-0.3l-0.1-0.3
		l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3 M345,828.4l0.9-0.4 M343.2,830.8l0.3-0.8l0.4-0.7l1-0.9 M344.1,832.6l-0.1-0.2
		l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M347.3,832.8l-0.8,0.2h-0.8l-1.6-0.4 M347.3,832.8l0.9-0.4
		 M349.8,834.8l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3 M349.1,835.6l0.7-0.8 M348.3,839l0.1-1
		l0.2-1l0.6-1.5 M349.5,840.6l-0.2-0.2l-0.1-0.2L349,840l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M352.1,839.5l-0.6,0.5
		l-0.7,0.3l-1.4,0.3 M352.1,839.5l0.6-0.9 M354.6,840.6l-0.2-0.2l-0.2-0.2L354,840l-0.2-0.2l-0.2-0.2l-0.2-0.3l-0.2-0.3l-0.2-0.3
		 M354.3,841.8l0.3-1.2 M354.5,845.9l-0.2-1.2l-0.1-1.1l0.1-1.9 M355.9,847.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1
		l-0.2-0.1l-0.2-0.2l-0.2-0.2 M357.7,844.8l-0.3,0.8l-0.5,0.6l-1,0.9 M357.7,844.8l0.3-1.2 M360,844.9l-0.3-0.2l-0.3-0.2l-0.3-0.2
		l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.2-0.2l0,0 M360.1,846.4v-1.5 M361.3,850.9l-0.5-1.2l-0.4-1.2l-0.4-2.1 M362.7,851.6l-0.2-0.1
		l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1 M363.6,848.2l-0.1,1l-0.2,0.9l-0.6,1.4 M363.6,848.2l-0.1-1.5
		 M365.5,847.4l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1 M365.9,849l-0.4-1.6 M368,853.4l-0.7-1.1
		l-0.6-1.2l-0.8-2.1 M369.3,853.6h-0.2h-0.2h-0.2h-0.2h-0.2h-0.2h-0.2h-0.2 M369.2,849.5l0.2,1.2l0.1,1.1l-0.1,1.8 M369.2,849.5
		l-0.4-1.6 M370.5,847.8h-0.2h-0.2h-0.2h-0.2h-0.2h-0.2H369h-0.2 M441.2,800.3l9.3-1.3 M442.2,794.2l6-0.9 M443.3,792.7l-1.6,2.2
		l-0.7,1.6l-0.3,1.1 M448.2,793.4l-2.6-0.2l-1.5-0.2l-0.8-0.2 M450.5,799l-0.7-1l-1-1.9l-0.5-2.1l-0.1-0.7 M447.9,803.9l0.7-2.1
		l1-1.6l1-1.2 M442.9,803.3l1.7,0.3l1.7,0.2l1.5,0.1 M440.6,797.7l0.1,0.7l0.5,2.1l1,1.9l0.7,1 M452.8,805.1l1-3.2l0.1-3.5l-0.9-3.3
		l-1.6-2.6l-2.2-1.8l-1.7-0.6 M441.2,801l-0.6-2.5l0.2-2.6l1-2.2l1.6-1.5l2-0.6l2.1,0.4l1.8,1.4l1.3,2.1l0.6,2.5l-0.2,2.6l-1,2.2
		l-1.6,1.5l-2,0.6l-2.1-0.4l-1.8-1.4L441.2,801 M447.1,808.8l-2.2,0.1l-2.4-0.8l-1.8-1.2l-1.5-1.5l-1.6-2.6 M444.8,809.1l-0.7,0.2
		l-3.5-0.7l-1.6-0.9l-1.4-1.2l-2.3-3.4 M437.6,802.8l-0.9-2.5l-0.3-1.5l0.2-4l1.5-3.6l2.4-2.4l2.4-0.7 M442.6,809.4l-0.7,0.2
		l-3.5-0.7l-1.6-0.9l-1.4-1.2l-2.3-3.4 M435.4,803.1l-0.9-2.5l-0.3-1.5l0.2-4l1.5-3.6l2.4-2.4l2.4-0.7 M440.4,809.7l-0.7,0.2
		l-3.5-0.7l-1.6-0.9l-1.4-1.2l-2.3-3.4 M433.1,803.4l-0.9-2.5l-0.3-1.5l0.2-4l1.5-3.6l2.4-2.4l2.4-0.7 M438.1,810.1l-0.7,0.2
		l-3.5-0.7l-1.6-0.9l-1.4-1.2l-2.3-3.4 M430.9,803.7l-0.9-2.5l-0.3-1.5l0.2-4l1.5-3.6l2.4-2.4l2.4-0.7 M428.6,804l-1-3.1l-0.2-2.4
		l0.2-2.3l1-2.9l1.5-2.1l1.6-1.3l1.7-0.6h0.5 M435.9,810.5h0.3 M447.1,808.9l0.8-0.1l0.8-0.2l0.8-0.3l0.7-0.4l0.7-0.5l0.7-0.6
		l0.6-0.7l0.5-0.8 M447.5,790.1l-1.5-0.4l-2.3,0.3l-2.1,1.2l-1.6,2l-0.9,2.5 M439.2,795.6l0.3-1.2l1.2-2.4l1.8-1.6l1.7-0.5
		 M435.9,810.5l-2.9-0.4l-2.7-1.6l-2.2-2.6l-1.4-3.1l-0.5-3.4l0.5-3.3l1.3-2.7l1.9-1.9 M438.6,809.7L438.6,809.7l-2.7,0.8
		 M428.9,804l-1-3.3l-0.1-3.2l0.2-1.2l0.5-1.9l1-2l0.3-0.5l0.1-0.1l0.1-0.1l2-1.8l2.4-0.8l2,0.2 M438.4,810l-0.7,0.2l-3.5-0.7
		l-3-2.1l-0.9-1.1L429,804 M431.2,803.7l-1.1-4.1l-0.1-2l0.3-2l1.5-3.6l2.4-2.4l3-0.9l1.2,0.3 M440.7,809.7l-0.7,0.2l-3.5-0.7
		l-3-2.1l-0.9-1.1l-1.3-2.3 M433.4,803.4l-1.1-4.1l-0.1-2l0.3-2l1.5-3.6l2.4-2.4l3-0.9l1.2,0.3 M444.8,808.8l-2.6,0.7l-3.5-0.7
		l-3-2.1l-0.9-1.1l-1.3-2.3 M435.6,803l-1.1-4.1l-0.1-2l0.3-2l1.5-3.6l2.4-2.4l2.4-0.7 M445.1,809l-0.7,0.2l-3.5-0.7l-3-2.1
		l-0.9-1.1l-1.3-2.3 M437.9,802.7l-1.1-4.1l-0.1-2l0.3-2l1.5-3.6l2.4-2.4l3-0.9l3.2,0.7l2.7,2.2l0.8,1.1l0.1,0.2 M447.1,808.9
		l-2.8-0.2l-2.6-1.2l-2.2-2l-0.6-0.9l-1.1-1.9 M439.2,795.6l-0.4,2.5l0.2,2.9l1,2.9l1.7,2.4l2.2,1.7l2.8,0.8h0.4 M572.2,768.5
		l-1.7-1 M564.1,770.4L564.1,770.4l-2.5-0.7l-2.4-1.8l-2-2.5l-1.5-3l-0.6-2.9l0.3-2.4l0.1-0.2 M553.6,750.5l2.8-0.7l3.8,1.2l3.9,3.1
		l3.3,4.3l2.4,5.2l0.9,5.1 M572.2,768.5l-0.9,4.1l-2.3,2.7l0,0 M569.2,770.3l3-1.7 M567.7,770.4l3-1.7 M563.3,768.2l4.3,2.2
		 M569.2,770.3l-0.9,4.1l-2.3,2.7l-3.3,0.7l-4.1-1.2l-4.1-3.1l-3.4-4.3l-2.5-5.2l-1-4.9l0.5-4.1l2-2.7l3.1-0.8l3.8,1.2l3.9,3.1
		l3.3,4.3l2.4,5.2l0.9,5.1 M568.5,769.9l0.7,0.4 M564.6,768.8l-0.3,1.4l-1.4,1.6l-2,0.4l-2.4-0.7l-2.4-1.8l-2-2.5l-1.5-3l-0.6-2.9
		l0.3-2.4l1.1-1.6l1.8-0.4l2.2,0.7l2.2,1.8l1.9,2.5l1.3,3.1l0.5,3 M572.1,657.7l-1.8-0.7 M564,661.5l-1.5-0.1l-2.4-1.4l-2.2-2.2
		l-1.8-2.9l-1-2.9l-0.1-2.6l0.4-1 M563.2,658.1l4.2,1.3 M568.1,659.1l0.9,0.4 M564.7,658.6l-0.1,1.8l-1.1,2l-1.7,0.9l-2.3-0.2
		l-2.4-1.4l-2.2-2.2l-1.8-2.9l-1-2.9l-0.1-2.6l0.8-1.9l1.5-0.9l2.1,0.2l2.3,1.4l2,2.2l1.6,2.9l0.8,3 M553.6,641.7l1.3-0.8l3.6,0.4
		l3.9,2.3l3.6,3.8l2.8,5l1.5,5.2 M572.1,657.7l-0.2,4.6l-1.8,3.4l-1.3,0.6 M569,659.4l3-1.7 M569,659.4l-0.2,4.6l-1.8,3.4l-2.9,1.5
		l-3.9-0.4l-4.1-2.4l-3.7-3.8l-3-5l-1.6-5.1l-0.1-4.5l1.5-3.4l2.7-1.6l3.6,0.4l3.9,2.3l3.6,3.8l2.8,5l1.5,5.2 M567.4,659.5l3-1.7
		 M540.7,660.9l0.9,0.6l1.6,1.8l1.2,2.2l0.6,2.2l0.1,0.7 M495.8,694.7l5.2-3 M493.1,689.2l4.4-2.5 M543.4,673.5l0.6-0.5l0.9-1.8
		l0.1-2.5l-0.8-2.8l-1.5-2.7l-2-2.2l-2.2-1.4l-2.1-0.3l-1,0.3 M510.1,700.4l0.3-0.2l1.7-2l0.9-2.9v-3.6 M513.1,691.7l-0.9-3.9
		l-1.8-3.9l-2.4-3.6l-2.9-2.9l-3.1-2l-3-0.9l-2.7,0.3l-0.8,0.4 M495.8,694.7l0.4,0.3l0.5,0.2l0.5,0.2l0.5,0.2l0.6,0.2l0.6,0.2
		l0.6,0.2l0.6,0.2 M493.1,689.2l0.3,0.7l0.3,0.7l0.3,0.7l0.3,0.7l0.4,0.7l0.4,0.7l0.4,0.6l0.4,0.6 M494.6,685.4l-0.3,0.5l-0.3,0.5
		l-0.2,0.5l-0.2,0.5l-0.2,0.5l-0.2,0.5l-0.1,0.4l-0.1,0.4 M498.9,687.1l-0.6-0.2l-0.6-0.2l-0.6-0.2l-0.6-0.2l-0.5-0.2l-0.5-0.2
		l-0.5-0.2l-0.4-0.3 M501.7,692.5l-0.4-0.6l-0.4-0.6l-0.4-0.7l-0.4-0.7l-0.3-0.7l-0.3-0.7l-0.3-0.7l-0.3-0.7 M500.1,696.4l0.1-0.4
		l0.1-0.4l0.2-0.5l0.2-0.5l0.2-0.5l0.2-0.5l0.3-0.5l0.3-0.5 M504.9,701.3l2.4,0.2l2.4-0.9l1.7-2l0.9-2.9v-3.6 M512.3,692.2l-0.9-3.9
		l-1.8-3.9l-2.4-3.6l-2.9-2.9l-3.1-2l-3-0.9l-2.7,0.3l-2.1,1.4l-1.3,2.5v0.1 M540.7,769.7l1.6,1.3l1.5,2l1,2.2l0.3,2 M495.2,802.8
		l5.3-3.1 M493,797.2l3.6-2.1 M499.4,796.6l0.3,0.7 M543.4,782.4L543.4,782.4l1.2-1.4l0.4-2.3l-0.4-2.7l-1.2-2.8l-1.8-2.5l-2.2-1.7
		l-2.2-0.8l-1.8,0.4 M510.1,809.3l1.2-1l1.3-2.4l0.5-3.3 M513.2,802.6l-0.5-3.8l-1.3-4l-2.1-3.8l-2.7-3.3l-3-2.5l-3.1-1.5l-2.9-0.4
		l-2.1,0.7 M495.2,802.8l0.5,0.4l0.5,0.3l0.5,0.3l0.5,0.3l0.6,0.3l0.6,0.3l0.6,0.3l0.6,0.3 M493,797.2l0.2,0.7l0.2,0.7l0.2,0.7
		l0.3,0.7l0.3,0.7l0.3,0.7l0.3,0.7l0.4,0.6 M495.2,794.1l-0.4,0.4l-0.3,0.4l-0.3,0.4l-0.3,0.4l-0.3,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.3
		 M499.5,796.6l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.3l-0.5-0.4 M501.7,802.3l-0.4-0.6L501,801l-0.3-0.7
		l-0.3-0.7l-0.3-0.7l-0.2-0.7l-0.2-0.7l-0.2-0.7 M499.5,805.4l0.2-0.3l0.2-0.4l0.2-0.4l0.3-0.4l0.3-0.4l0.3-0.4l0.3-0.4l0.4-0.4
		 M504.9,810.2l0.9,0.2l2.7-0.2l2.1-1.4l1.3-2.4l0.5-3.3 M512.4,803l-0.5-3.8l-1.3-4l-2.1-3.8l-2.7-3.3l-3-2.5l-3.1-1.5l-2.9-0.4
		l-2.4,0.8l-1.7,1.9l-0.6,1.5 M1103.1,257.9L1103.1,257.9l-0.2-0.1h-0.1h-0.1l0,0 M1101.2,258.8L1101.2,258.8L1101.2,258.8l-0.1,0.1
		 M1096.6,264.2L1096.6,264.2l0.6-1.1l0.7-1l0.7-0.9l0.8-0.9l0.8-0.8l0.9-0.7 M1103.1,257.9L1103.1,257.9l-0.2,0.1l-0.1,0.1
		l-0.1,0.1l-0.1,0.1l-0.1,0.1v0.1v0.1 M1131.6,281.2l-29-22.6 M1132.1,280.5L1132.1,280.5l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1
		l-0.1,0.1v0.1v0.1 M1133.4,292.3l1-1.5l2.3-2.6l2.5-1.9 M1139.2,286.2l2.4-0.9l1.8,0.2l1,1.4v0.1 M1130.4,289.2l0.7-1l2.3-2.6
		l2.5-1.9 M1135.9,283.6l2.4-0.9l1.8,0.2l0.3,0.2 M1132,281.5l-0.2,0.1l-0.4,0.1h-0.4l-0.3-0.1l-0.3-0.2 M1130.5,281.5l-29-22.6
		 M1101.5,258.8l-0.9,0.7l-0.9,0.8l-0.9,0.9l-0.8,1l-0.7,1.1l-0.5,1 M1106.3,257.9l-0.2-0.2l-0.6-0.2h-0.7l-0.8,0.1l-0.8,0.3
		 M1103.1,257.9l29,22.6 M1132.1,280.5l0.1,0.1l0.1,0.2v0.2l-0.1,0.2l-0.2,0.2 M1113.5,257.3L1113.5,257.3L1113.5,257.3h0.2h0.1h0.1
		 M1111.8,258.4L1111.8,258.4v-0.1l0,0 M1109.5,260.4l0.6-0.6l0.8-0.8l0.9-0.7 M1142.3,280.6L1142.3,280.6l0.1-0.2l0.1-0.1l0.1-0.1
		l0.1-0.1l0.1-0.1l0.1-0.1h0.1 M1113.3,258L1113.3,258l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1 M1142.3,280.6l-29-22.6
		 M1146.4,288.6l0.9-1l2.5-1.9 M1149.9,285.7l2.4-0.9l1.8,0.2l1,1.4v0.1 M1143.5,285.6l0.6-0.6l2.5-1.9 M1146.6,283.1l2.4-0.9
		l1.8,0.2l0.3,0.2 M1117,257.3l-0.2-0.2l-0.6-0.2h-0.7l-0.8,0.1l-0.9,0.3 M1113.8,257.4l29,22.6 M1142.8,280l0.1,0.1l0.1,0.2v0.2
		l-0.1,0.2l-0.2,0.2 M1142.7,281l-0.2,0.1l-0.3,0.1h-0.4l-0.3-0.1l-0.3-0.2 M1141.2,280.9l-29-22.6 M1112.2,258.3l-0.9,0.7l-0.9,0.8
		l-0.7,0.7 M1083.3,272.5L1083.3,272.5l-0.1-0.1v-0.1v-0.1l0,0 M1090.5,262.1L1090.5,262.1L1090.5,262.1h0.2h0.1h0.1 M1088.9,263
		L1088.9,263L1088.9,263l-0.1,0.1 M1083.6,270.4l0.3-1l0.5-1l0.6-1l0.7-1l0.7-0.9l0.8-0.9l0.8-0.8l0.9-0.7 M1083.4,270.5
		L1083.4,270.5L1083.4,270.5l0.1-0.1h0.1 M1112.3,295.2L1112.3,295.2V295v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1
		 M1083.8,271.9l29,22.6 M1083.3,272.5L1083.3,272.5v-0.2v-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M1119.3,285.4L1119.3,285.4
		l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1h0.1 M1090.3,262.8L1090.3,262.8l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1
		l0.1-0.1h0.1 M1119.3,285.4l-29-22.6 M1121.7,304.8l-1.2-0.3l-1-1.4l-0.1-2.3l0.9-2.8l1.8-3l2.3-2.6l2.5-1.9 M1126.9,290.5l2.4-0.9
		l1.8,0.2l1,1.4v0.1 M1116.9,301.7l-0.8-1.2l-0.1-2.3l0.9-2.8l1.8-3l2.3-2.6l2.5-1.9 M1123.6,287.9l2.4-0.9l1.8,0.2l0.3,0.2
		 M1094,262.1l-0.2-0.2l-0.6-0.2h-0.7l-0.8,0.1l-0.9,0.3 M1090.8,262.2l29,22.6 M1119.8,284.8l0.1,0.1l0.1,0.2v0.2l-0.1,0.2
		l-0.2,0.2 M1119.7,285.8l-0.2,0.1l-0.3,0.1h-0.4l-0.3-0.1l-0.3-0.2 M1118.2,285.7l-29-22.6 M1089.2,263.1l-0.9,0.7l-0.9,0.8
		l-0.9,0.9l-0.8,1l-0.7,1.1l-0.6,1.1l-0.5,1.1l-0.4,1 M1083.6,270.9l29,22.6 M1112.6,293.5l0.2,0.2l0.2,0.3l0.1,0.3l0.1,0.3v0.3
		 M1113.1,294.9v0.2l-0.1,0.2l-0.2,0.1h-0.2l-0.2-0.1 M1112.3,295.2l-29-22.6 M1083.3,272.5v0.8l0.2,0.7l0.3,0.5l0.2,0.2
		 M1120.6,303.9l-0.7-1.1l-0.1-2.1l0.9-2.7l1.6-2.8l2.2-2.5l2.4-1.8 M1126.9,290.9l2.2-0.9l1.7,0.2l0.2,0.2 M1140.3,318.8l-0.9-0.3
		l-1-1.3l-0.1-2.1l0.9-2.7l1.6-2.8l2.2-2.5l2.4-1.8 M1145.4,305.4l2.2-0.9l1.7,0.2l1,1.3v0.2 M1139.4,318l-0.6-0.9l-0.1-1.9l0.7-2.5
		l1.5-2.6l2-2.4l2.2-1.8l2.1-0.9l1.7,0.1l0.4,0.3 M1146.6,288.7l0.8-0.8l2.4-1.8 M1149.9,286.1l2.2-0.9l1.7,0.2l0.2,0.2
		 M1164.9,303.6l1.2-1.2l2.4-1.8 M1168.4,300.6l2.2-0.9l1.7,0.2l1,1.3v0.1 M1165.1,303.8l0.8-0.9l2.2-1.8l2.1-0.9l1.7,0.1l0.3,0.2
		 M1133.6,292.4l1-1.5l2.2-2.5l2.4-1.8 M1139.2,286.6l2.2-0.9l1.7,0.2l0.2,0.2 M1151.9,307.5l1.3-2l2.2-2.5l2.4-1.8 M1157.7,301.1
		l2.2-0.9l1.7,0.2l1,1.3v0.1 M1152.1,307.7l1.1-1.8l2-2.4l2.2-1.8l2.1-0.9l1.7,0.1l0.4,0.2 M1076.2,302.1l1.9-0.5l6.6-0.8l7,0.4
		l7.3,1.5l7.6,2.6l7.7,3.7l7.7,4.7l7.6,5.7l7.5,6.6l7.2,7.4l6.8,8.1l6.4,8.7l5.8,9.2l5.2,9.6l4.5,9.8l3.8,9.9l3,9.9l2.2,9.8l1.3,9.5
		l0.4,9.1 M1183.8,427l-0.4,8.6l-1,6.7 M881.3,462.6l-1.2-0.3l-1.7,0.3l-1.1,1.3l-0.4,2l0.4,2.5l1.1,2.5l1.7,2.2l1.9,1.6l1.9,0.7
		l1.7-0.3l1.1-1.3l0.3-1.2 M928.5,544.2l-1.2-0.3l-1.7,0.3l-1.1,1.3l-0.4,2l0.4,2.5l1.1,2.5l1.7,2.2l1.9,1.6l1.9,0.7l1.7-0.3
		l1.1-1.3l0.3-1.2 M897.1,504.7l-0.5-1.6l-0.9-5v-4.5l0.9-3.9l1.8-3l1.3-1.2 M923.6,526.9l-0.9,0.3l-3.4,0.3l-3.8-0.9l-3.9-2l-3.9-3
		l-2.1-2.1 M895.2,505.8l-0.5-1.6l-0.9-5v-4.6l0.9-3.9l1.8-3l2.5-2l3.2-0.9l3.6,0.3l3.9,1.4l3.9,2.5l3.8,3.5l3.4,4.2l2.9,4.8
		l2.2,5.1l1.3,5.1l0.5,4.8 M927.7,516.5l-0.5,4.3l-1.3,3.5l-2.2,2.6l-2.9,1.5l-3.4,0.3l-3.8-0.9l-3.9-2l-3.9-3l-2.1-2.1
		 M928.5,435.3l-1.2-0.3l-1.7,0.3l-1.1,1.3l-0.4,2l0.4,2.5l1.1,2.5l1.7,2.2l1.9,1.6l1.9,0.7l1.7-0.3l1.1-1.3l0.3-1.2 M975.6,517
		l-1.2-0.3l-1.7,0.3l-1.1,1.3l-0.4,2l0.4,2.5l1.1,2.5l1.7,2.2l1.9,1.6l1.9,0.7l1.7-0.3l1.1-1.3l0.3-1.2 M911.1,483l-0.4-0.2
		 M910.7,530.6l7.9,2.8l6.7-1.3l4.5-5.1l1.6-8.2l-1.5-10l-4.4-10.3l-6.5-9l-7.9-6.5 M910.3,530.4l0.4,0.2 M911.4,500.2l5,12.1
		 M872.1,537.9l-5-12.1 M916.4,512.3l0.3-1.3v-1.6l-0.3-1.7l-0.6-1.7l-0.8-1.7l-1.1-1.6l-1.2-1.4l-1.3-1.1 M916.4,512.3l-44.3,25.6
		 M867.1,525.8l-2.4-1.2l-2.2,0.1L861,526l-0.6,2.4l0.4,3l1.3,3.1l2,2.8l2.4,1.9l2.4,0.8l2-0.5l1.3-1.7 M911.4,500.2l-44.3,25.6
		 M908.3,498.2l2-0.4l2.3,0.8l2.3,1.9l2,2.6l1.3,3l0.5,2.9 M918.6,509.1l-0.5,2.4l-1.3,1.5l0,0 M887.1,473.5l-0.4,2.5l-1.3,1.6
		l-1.9,0.6l-2.3-0.6l-2.4-1.7l-2.2-2.5l-1.6-3l-0.9-3.1v-2.8l0.9-2.1l1.6-1.1h2.2l2.4,1.1l2.3,2.1l1.9,2.8l1.3,3.1L887.1,473.5
		 M934.3,555.1l-0.4,2.5l-1.3,1.6l-1.9,0.6l-2.3-0.6l-2.4-1.7l-2.2-2.5l-1.6-3l-0.9-3.1v-2.8l0.9-2.1l1.6-1.1h2.2l2.4,1.1l2.3,2.1
		l1.9,2.8l1.3,3.1L934.3,555.1 M981.4,527.9l-0.4,2.5l-1.3,1.6l-1.9,0.6l-2.3-0.6l-2.4-1.7l-2.2-2.5l-1.6-3l-0.9-3.1v-2.8l0.9-2.1
		l1.6-1.1h2.2l2.4,1.1l2.3,2.1l1.9,2.8l1.3,3.1L981.4,527.9 M934.3,446.3l-0.4,2.5l-1.3,1.6l-1.9,0.6l-2.3-0.6l-2.4-1.7l-2.2-2.5
		l-1.6-3l-0.9-3.1v-2.8l0.9-2.1l1.6-1.1h2.2l2.4,1.1l2.3,2.1l1.9,2.8l1.3,3.1L934.3,446.3 M910.3,466.9l1.3-0.6l3.8-0.9l4.2,0.2
		l4.5,1.3l4.7,2.4l4.6,3.4l4.4,4.3l4,5l3.5,5.6l2.9,5.9l2.1,6l1.3,5.9l0.4,5.6 M952.2,511l-0.4,5.1l-1.3,4.4l-2.1,3.6l-2.9,2.6
		l-0.4,0.2 M1163.9,471.4l4.6-3.2l4.6-4.6l3.8-5.6l3-6.5l2.2-7.3l1.3-8.1l0.4-8.7 M1183.8,427.4l-0.4-9.2l-1.3-9.6l-2.2-9.8l-3-10
		l-3.8-10l-4.6-9.9l-5.2-9.6l-5.9-9.2l-6.4-8.7l-6.9-8.1l-7.2-7.4l-7.5-6.6l-7.7-5.7l-7.7-4.7l-7.7-3.7l-7.6-2.6l-7.4-1.5l-7.1-0.4
		l-6.6,0.8l-6.1,1.9l-3.5,1.8 M978,578.7l1.5-0.8 M884.8,410.8l-1.3,0.7 M927.7,570.9l12.5,5.8l12,2.7l11.1-0.4l9.7-3.6l8-6.6
		l5.9-9.3l3.7-11.7l1.3-13.7l-1.2-15.1l-3.6-15.9l-5.9-16.2l-7.9-15.8l-9.7-14.8l-11-13.3l-12-11.2l-12.5-8.7 M927.2,570.7l0.4,0.3
		 M927.2,570.7L927.2,570.7 M928.1,423.1l-0.4-0.3 M928.1,423.1L928.1,423.1 M580.6,632.6l-0.5,2.4l0.4,3.1l1.3,3.2l2,2.9l2.4,2.2
		l2.5,1.2l0.8,0.2 M589.8,644l-0.8-3.2l-1.6-3.1l-2.2-2.6l-2.5-1.8l-2.4-0.7l-2,0.5l-1.4,1.6l-0.5,2.5l0.4,3.1l1.3,3.2l2,2.9
		l2.4,2.2l2.5,1.2l2.3,0.1l1.7-1.1l1-2.1L589.8,644 M594.3,645.8l-1-4.1l-1.8-4.1l-2.5-3.8l-3-3.2l-3.2-2.3l-3.2-1.2l-2.9-0.1
		l-2.4,1.1l-1.7,2.2l-0.9,3.1l0.1,3.8l1,4.1l1.8,4.1l2.5,3.8l3,3.2l3.2,2.3l3.2,1.2l2.9,0.1l2.4-1.1l1.7-2.2l0.9-3.1L594.3,645.8
		 M594.9,653.1l0.7-0.5l1.7-2.2l0.9-3.1l-0.1-3.8 M598,643.6l-1-4.1l-1.8-4.1l-2.5-3.8l-3-3.2l-3.2-2.3l-3.2-1.2l-2.9-0.1l-1.7,0.6
		 M580.6,741.5l-0.4,1.3v2.9l0.9,3.2l1.7,3.1l2.2,2.6l2.5,1.7l1.9,0.6 M594.9,761.9l1.5-1.3l1.3-2.6l0.5-3.4 M598.2,754.6l-0.5-3.9
		l-1.3-4.2l-2.1-4l-2.7-3.6l-3.1-2.8l-3.2-1.9l-3.1-0.7l-2.7,0.4l-0.6,0.3 M589.9,754.2l-0.5-3.1l-1.3-3.2l-2-2.9l-2.4-2.2l-2.5-1.2
		H579l-1.7,1.2l-0.9,2.2v2.9l0.9,3.2l1.7,3.1l2.2,2.6l2.5,1.7l2.4,0.6l2-0.6l1.3-1.7L589.9,754.2 M594.4,756.8l-0.5-3.9l-1.3-4.2
		l-2.1-4l-2.7-3.6l-3.1-2.8l-3.2-1.9l-3.1-0.7l-2.7,0.4l-2.1,1.6l-1.3,2.6l-0.5,3.4l0.5,3.9l1.3,4.2l2.1,4l2.7,3.6l3.1,2.8l3.2,1.9
		l3.1,0.7l2.7-0.4l2.1-1.6l1.3-2.6L594.4,756.8 M433.1,503.5v78.4 M648.5,548.2l0.2-1.9 M648.7,546.3l0.7-7.9 M709.1,486.7l-3.8,2.2
		 M696.4,486.4l-24.5,14.1 M738.3,679.6V758 M345.9,827.9l-1.1,0.6 M351.5,840l1.7-1 M343.1,819.8l-2.5,1.4 M401.6,784.5l-1.1-0.7
		 M415.5,810v-1.3 M366.1,783.6L344.6,796 M373.9,846.7l0.9-0.5 M404.3,823.7l6.8-3.9 M389.8,782.8l-9.5,5.5 M389.6,792.2
		l-21.5,12.4 M379,823.4l21.5-12.4 M393.8,799.4l-21.5,12.4 M438.2,810.2h0.3 M440.4,809.9h0.3 M442.6,809.5h0.3 M444.9,809.2h0.3
		 M443.2,787.9h-0.3 M441,788.2h-0.3 M438.8,788.5h-0.3 M436.5,788.9h-0.3 M434.3,789.2H434 M444.8,789.7h-0.3h-0.3 M553.5,750.4
		l-3,1.7 M565.9,777.1l3.2-1.9 M553.5,641.5l-3,1.7 M565.9,668.2l3.2-1.9 M540.7,660.9l-1.1-0.7 M545,669.6v-1.3 M535.4,659.7
		L505,677.2 M513,691.1l30.4-17.6 M509.4,700.9l0.8-0.4 M495.5,675l-0.8,0.4 M492.1,679.1l-0.6,3.5l0.1,3.6l0.8,3.5l1.5,3.3l2.1,2.9
		l2.6,2.4l3.1,1.8l3.4,1.2 M540.7,769.7l-1.1-0.7 M545,778.5v-1.3 M535.4,768.6L505,786.1 M513,800l30.4-17.6 M509.4,809.7l0.8-0.4
		 M495.5,783.9l-0.8,0.4 M492.1,788l-0.6,3.5l0.1,3.6l0.8,3.5l1.5,3.3l2.1,2.9l2.6,2.4l3.1,1.8l3.4,1.2 M1102.5,258.2l-0.2,0.9
		 M1102.8,257.8L1102.8,257.8l-0.1,0.1l0,0l0,0v0.1v0.1v0.1v0.1 M1101.8,258.8L1101.8,258.8L1101.8,258.8l-0.2-0.1h-0.1h-0.1h-0.1
		h-0.1h-0.1 M1131.8,281.4L1131.8,281.4L1131.8,281.4L1131.8,281.4 M1131.7,281.4L1131.7,281.4l-0.2,0.1h-0.2h-0.2h-0.2l-0.1-0.1
		l0,0 M1130.8,281.4l-29-22.6 M1143.7,285.8l-3.3-2.6 M1138.1,282.8l-31.9-24.9 M1113.2,257.7l-0.2,0.9 M1113.5,257.3L1113.5,257.3
		l-0.1,0.1l0,0l0,0v0.1v0.1v0.1v0.1 M1112.5,258.2L1112.5,258.2l-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1h-0.1 M1142.5,280.9L1142.5,280.9
		L1142.5,280.9L1142.5,280.9 M1142.4,280.9L1142.4,280.9l-0.2,0.1h-0.2h-0.2h-0.2l-0.1-0.1l0,0 M1141.5,280.9l-29-22.6
		 M1154.4,285.2l-3.3-2.6 M1148.8,282.2l-31.9-24.9 M1166.6,468.5l5.9,2.2l7.4,1.9l4.9,0.8l2.6,0.3l2.8,0.2h5.9l8.4-1.1l5.5-1.5
		l5.9-2.4l5.4-3.1l2.1-1.5l2.1-1.7 M1214.9,450.6l-0.6,0.5l-0.6,0.5l-1,0.7l-1.9,1.2l-4,2l-8.9,2.3l-6.2,0.3l-7-0.7l-7.3-1.8
		 M1084,271.4l-0.6,0.4 M1083.4,271.8L1083.4,271.8l-0.1,0.1v0.1v0.1v0.1v0.1v0.1v0.1 M1090.2,262.5l-0.2,0.9 M1090.5,262.1
		L1090.5,262.1l-0.1,0.1l0,0v0.1v0.1v0.1v0.1v0.1 M1083.4,270.5L1083.4,270.5v0.2v0.1v0.1v0.1v0.1l0.1,0.1l0.1,0.1 M1089.5,263.1
		L1089.5,263.1l-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1h-0.1 M1113,294.6L1113,294.6L1113,294.6 M1112.6,293.7L1112.6,293.7l0.2,0.2
		l0.1,0.2l0.1,0.2v0.2v0.1l0,0 M1119.5,285.7L1119.5,285.7L1119.5,285.7L1119.5,285.7 M1119.4,285.7L1119.4,285.7l-0.2,0.1h-0.2
		h-0.2h-0.2l-0.1-0.1l0,0 M1083.6,271.1l29,22.6 M1118.5,285.7l-29-22.6 M1225.5,462.7l1.3-1.2l1.3-1.4l1.8-2.5l1.6-3.4l1-3.9
		l-0.3-9.2l-3.4-11 M1214,436.6l1.8,5l0.9,4.2l-0.4,3.4l-0.7,1l-0.4,0.3l-0.1,0.1l-0.1,0.1 M1116.9,301.7l3.3,2.6 M1131.4,290
		l-3.3-2.6 M1125.8,287l-31.9-24.9 M1084.1,274.8l31.9,24.9 M1120.6,303.9l18.6,14.5 M1149.6,304.9l-18.6-14.5 M1153,308.4l-3.7-3
		 M1139.4,318l3.7,3 M1228.7,430.2l-4.2-9l-5.5-10.3 M1205,418.6l5.2,9.8l3.8,8.1 M1219,410.8l-5.7-11.2l-5.6-13.1 M1192.8,392.4
		l6.2,14.3l6.1,11.9 M1207.7,386.6l-3.9-9.6l-3.7-8l-3.3-6.3l-2.7-4.8l-2.5-4.2l-2.5-4l-2.8-4.2l-4.9-6.9l-6.7-8.5l-6-6.7l-7.2-7.3
		l-8.6-7.6 M1143.1,321l11.1,10.2l8.5,9.5l6.1,7.8l4.5,6.3l2.6,3.9l2.3,3.6l2.3,3.9l1.9,3.4l2.3,4.3l2.6,5.2l2.7,6.1l2.8,7.1
		 M1190.4,431.6l-6.9-2.7 M1213.5,435.3l-2.4,0.3l-6.1-0.3l-6.9-1.3l-7.5-2.4 M1241.4,439.2l2.1-2.3l1.8-2.3l2.2-3.8l2.3-9.1
		l-1.1-9.9l-4-10.5 M1172.6,300.1l-18.6-14.5 M1176,303.3l-3.8-2.8 M1244.7,401.4l-4.2-7.8l-4.7-7.9 M1235.8,385.7l-4-6.9l-3.3-6.5
		 M1228.6,372.3l-4.5-9.8l-4.1-7.8l-3.4-5.9l-2.6-4.2l-3-4.5l-3.5-4.9l-4.7-6l-6.4-7.4l-5.6-5.8l-6.8-6.3l-8-6.4 M1230.7,455.7
		l4.4-3.4 M1216.7,445.5l-8.4,2.6l-5.9,0.5l-6.8-0.5l-7.5-1.6l-6.3-2.2 M1235.2,452.4l3-3.3l2.4-3.7l2.5-8.4l-0.7-9.8l-3.8-11
		 M1161.9,300.7l-18.6-14.5 M1165.3,304l-3.7-2.9 M1238.7,416.3l-4.3-8.6l-5.2-9.3 M1229.2,398.3l-4.9-9.2l-4.5-10 M1219.8,379.1
		l-5.3-12.2l-4.8-9.3l-3.7-6.5l-2.5-4.1l-2.5-3.8l-2.8-4.1l-4.9-6.6l-6.7-8.1l-5.9-6.4l-7.1-6.9l-8.4-7.1 M910.3,466.9l-13.2,7.6
		 M931.8,534.5l13.2-7.6 M900,522.7L900,522.7l2.8,3.1l2.9,2.8l3.2,2.6l3.5,2.2l3.6,1.7l3.7,1.1l3.8,0.4l3.7-0.4l3.6-1.2l1-0.7
		 M897.3,474.5l-2.6,1.7l-2.6,3l-2,3.6l-1.2,4.2l-0.5,4.5l0.2,4.6l0.7,4.4l1.1,4.3l1.1,3.1 M908.3,498.2l-46.2,26.7 M870.7,539.7
		l46.2-26.7 M1067.9,305.1L883.5,411.6 M979.5,577.9l184.4-106.5 M886,530.8l1.1,1.7l2.4,3.5l4.1,5.6l4.5,5.5l5,5.6l5.4,5.4l5.7,5.1
		l5.9,4.6l6.1,4.1l6.2,3.5l6.3,2.9l6.3,2.2l6.3,1.5l6.2,0.7l6.1-0.1l5.9-0.9l5.7-1.8l2.5-1.3 M885,410.9l-2.7,1.4l-5.1,3.9l-4.2,4.5
		l-3.5,5.2l-2.9,5.9l-2.2,6.5l-1.5,7.1l-0.8,7.5l-0.1,7.9l0.6,8.1l1.2,8.3l1.8,8.3l2.4,8.2l2.7,7.7l2.7,6.6l2.4,5.3l1.5,2.9
		 M578.9,625.3l-3.8,2.2 M591.1,655.2l3.8-2.2 M578.9,734.2l-3.8,2.2 M591.1,764.1l3.8-2.2"/>
	<polyline class="st2" points="447.1,808.9 447.9,808.8 448.7,808.5 449.5,808.2 450.3,807.7 451,807.2 451.6,806.6 452.2,805.9
		452.8,805.1 	"/>
	<line class="st2" x1="398.6" y1="805.1" x2="426.5" y2="801.1"/>
	<line class="st2" x1="440.9" y1="799" x2="445.9" y2="798.3"/>
	<line class="st2" x1="543.7" y1="773" x2="561.7" y2="762.6"/>
	<line class="st2" x1="567.8" y1="759.1" x2="587.9" y2="747.5"/>
	<line class="st2" x1="594.9" y1="743.5" x2="743.2" y2="657.8"/>
	<line class="st2" x1="840.6" y1="601.6" x2="932.3" y2="548.6"/>
	<line class="st2" x1="543.7" y1="664.1" x2="561.6" y2="653.8"/>
	<line class="st2" x1="567.7" y1="650.3" x2="587.9" y2="638.6"/>
	<line class="st2" x1="594.9" y1="634.6" x2="743.2" y2="548.9"/>
	<line class="st2" x1="781.1" y1="527.1" x2="932.4" y2="439.8"/>
</g>
<g id="Nr">
	<polyline class="st3" points="391.1,851.7 411.4,883.2 437.6,816.5 	"/>
	<g id="CT012053" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-48p-21t-pinion-gear", "_blank")}>
		<g>
			<g>
				<path class="st4" d="M454,906.5h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C466,901.1,460.6,906.5,454,906.5z"/>
			</g>
			<polyline class="st5" points="454.8,897.8 457.8,894.5 454.5,891.2 			"/>
		</g>
		<g>
			<path class="st6" d="M371.4,890.2c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3c0.7,0,1.6-0.2,2.5-0.5
				v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7s0.9-1.4,1.6-1.8
				c0.7-0.4,1.5-0.6,2.5-0.6s1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.7-0.3-1.1-0.5S371.7,890.2,371.4,890.2z"/>
			<path class="st6" d="M379.9,898.5h-2.1v-8.2h-2.7v-1.8h7.5v1.8h-2.7L379.9,898.5L379.9,898.5z"/>
			<path class="st6" d="M390.4,893.5c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3s2,0.4,2.6,1.3S390.4,891.9,390.4,893.5z M385.5,893.5c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C385.6,891.4,385.5,892.3,385.5,893.5z"/>
			<path class="st6" d="M396.7,898.5h-2.1v-5.8v-1v-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7V898.5z"/>
			<path class="st6" d="M406.4,898.5h-7V897l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8s0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3s-0.8,0.1-1.1,0.3s-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9s0.7-0.3,1.1-0.4
				c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.1,1s0.4,0.9,0.4,1.4s-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.8,1.2
				s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4v1.8H406.4z"/>
			<path class="st6" d="M414.4,893.5c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3s2,0.4,2.6,1.3S414.4,891.9,414.4,893.5z M409.5,893.5c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C409.6,891.4,409.5,892.3,409.5,893.5z"/>
			<path class="st6" d="M419,892.2c1,0,1.7,0.3,2.3,0.8s0.9,1.3,0.9,2.2c0,1.1-0.3,2-1,2.6s-1.7,0.9-3,0.9c-1.1,0-2-0.2-2.7-0.5
				v-1.8c0.4,0.2,0.8,0.3,1.3,0.5c0.5,0.1,0.9,0.2,1.4,0.2c1.3,0,1.9-0.5,1.9-1.6c0-1-0.7-1.5-2-1.5c-0.2,0-0.5,0-0.8,0.1
				c-0.3,0-0.5,0.1-0.7,0.2l-0.8-0.5l0.4-5.1h5.4v1.8H418l-0.2,2h0.2C418.3,892.2,418.6,892.2,419,892.2z"/>
			<path class="st6" d="M430,890.8c0,0.6-0.2,1.2-0.6,1.6s-0.9,0.7-1.6,0.9l0,0c0.8,0.1,1.4,0.3,1.8,0.7s0.6,0.9,0.6,1.6
				c0,1-0.3,1.7-1,2.2s-1.7,0.8-3,0.8c-1.1,0-2-0.2-2.9-0.5v-1.8c0.4,0.2,0.8,0.4,1.3,0.5s0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.5-0.4
				s0.5-0.6,0.5-1.1s-0.2-0.8-0.6-1s-1-0.3-1.8-0.3h-0.8v-1.6h0.8c0.8,0,1.3-0.1,1.7-0.3c0.4-0.2,0.5-0.5,0.5-1
				c0-0.8-0.5-1.1-1.4-1.1c-0.3,0-0.7,0.1-1,0.2s-0.7,0.3-1.1,0.6l-1-1.5c0.9-0.7,2-1,3.3-1c1,0,1.9,0.2,2.5,0.6S430,890,430,890.8z
				"/>
		</g>
	</g>
	<polyline class="st3" points="511.7,770.5 390.1,682.1 485.1,691.7 	"/>
	<g id="CT012054" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-replacement-brushless-motor-set3000kv", "_blank")}>
		<g>
			<g>
				<path class="st4" d="M386,694.5h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C398,689.1,392.6,694.5,386,694.5z"/>
			</g>
			<polyline class="st5" points="386.8,685.8 389.8,682.5 386.5,679.2 			"/>
		</g>
		<g>
			<path class="st6" d="M303.4,678.2c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3c0.7,0,1.6-0.2,2.5-0.5
				v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7s0.9-1.4,1.6-1.8
				c0.7-0.4,1.5-0.6,2.5-0.6s1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.7-0.3-1.1-0.5S303.7,678.2,303.4,678.2z"/>
			<path class="st6" d="M311.9,686.5h-2.1v-8.2h-2.7v-1.8h7.5v1.8h-2.7L311.9,686.5L311.9,686.5z"/>
			<path class="st6" d="M322.4,681.5c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3s2,0.4,2.6,1.3S322.4,679.9,322.4,681.5z M317.5,681.5c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C317.6,679.4,317.5,680.3,317.5,681.5z"/>
			<path class="st6" d="M328.7,686.5h-2.1v-5.8v-1v-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7V686.5z"/>
			<path class="st6" d="M338.4,686.5h-7V685l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8s0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3s-0.8,0.1-1.1,0.3s-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9s0.7-0.3,1.1-0.4
				c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.1,1s0.4,0.9,0.4,1.4s-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.8,1.2
				s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4v1.8H338.4z"/>
			<path class="st6" d="M346.4,681.5c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3s2,0.4,2.6,1.3S346.4,679.9,346.4,681.5z M341.5,681.5c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C341.6,679.4,341.5,680.3,341.5,681.5z"/>
			<path class="st6" d="M351,680.2c1,0,1.7,0.3,2.3,0.8s0.9,1.3,0.9,2.2c0,1.1-0.3,2-1,2.6s-1.7,0.9-3,0.9c-1.1,0-2-0.2-2.7-0.5
				v-1.8c0.4,0.2,0.8,0.3,1.3,0.5c0.5,0.1,0.9,0.2,1.4,0.2c1.3,0,1.9-0.5,1.9-1.6c0-1-0.7-1.5-2-1.5c-0.2,0-0.5,0-0.8,0.1
				c-0.3,0-0.5,0.1-0.7,0.2l-0.8-0.5l0.4-5.1h5.4v1.8H350l-0.2,2h0.2C350.3,680.2,350.6,680.2,351,680.2z"/>
			<path class="st6" d="M362.6,684.5h-1.2v2.1h-2.1v-2.1H355V683l4.4-6.5h1.9v6.3h1.2v1.7H362.6z M359.3,682.8v-1.7
				c0-0.3,0-0.7,0-1.2s0-0.9,0.1-0.9h-0.1c-0.2,0.4-0.4,0.7-0.6,1.1l-1.8,2.8L359.3,682.8L359.3,682.8z"/>
		</g>
	</g>
	<line class="st7" x1="1070.3" y1="599.8" x2="1056.5" y2="536.7"/>
	<line class="st7" x1="803.3" y1="823.8" x2="789.5" y2="760.7"/>
	<g id="CT012054_1_" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-replacement-brushless-motor-set3000kv", "_blank")}>
		<g>
			<g>
				<path class="st4" d="M1122,622.5h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C1134,617.1,1128.6,622.5,1122,622.5z"/>
			</g>
			<polyline class="st5" points="1122.8,613.8 1125.8,610.5 1122.5,607.2 			"/>
		</g>
		<g>
			<path class="st6" d="M1039.4,606.2c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3
				c0.7,0,1.6-0.2,2.5-0.5v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7
				s0.9-1.4,1.6-1.8c0.7-0.4,1.5-0.6,2.5-0.6s1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.8-0.3-1.1-0.5
				C1040.1,606.2,1039.7,606.2,1039.4,606.2z"/>
			<path class="st6" d="M1047.9,614.5h-2.1v-8.2h-2.7v-1.8h7.5v1.8h-2.7L1047.9,614.5L1047.9,614.5z"/>
			<path class="st6" d="M1058.4,609.5c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3s2,0.4,2.6,1.3S1058.4,607.9,1058.4,609.5z M1053.5,609.5c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C1053.6,607.4,1053.5,608.3,1053.5,609.5z"/>
			<path class="st6" d="M1064.7,614.5h-2.1v-5.8v-1v-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7V614.5z"/>
			<path class="st6" d="M1074.4,614.5h-7V613l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3s-0.8,0.1-1.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
				c0.3-0.2,0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.4
				s-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4v1.8H1074.4z"/>
			<path class="st6" d="M1082.4,609.5c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3s2,0.4,2.6,1.3S1082.4,607.9,1082.4,609.5z M1077.5,609.5c0,1.2,0.1,2.1,0.3,2.6
				c0.2,0.5,0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6c-0.2-0.5-0.6-0.8-1.1-0.8s-0.9,0.3-1.1,0.8
				C1077.6,607.4,1077.5,608.3,1077.5,609.5z"/>
			<path class="st6" d="M1087,608.2c1,0,1.7,0.3,2.3,0.8s0.9,1.3,0.9,2.2c0,1.1-0.3,2-1,2.6s-1.7,0.9-3,0.9c-1.1,0-2-0.2-2.7-0.5
				v-1.8c0.4,0.2,0.8,0.3,1.3,0.5c0.5,0.1,0.9,0.2,1.4,0.2c1.3,0,1.9-0.5,1.9-1.6c0-1-0.7-1.5-2-1.5c-0.2,0-0.5,0-0.8,0.1
				c-0.3,0-0.5,0.1-0.7,0.2l-0.8-0.5l0.4-5.1h5.4v1.8h-3.6l-0.2,2h0.2C1086.3,608.2,1086.6,608.2,1087,608.2z"/>
			<path class="st6" d="M1098.6,612.5h-1.2v2.1h-2.1v-2.1h-4.3V611l4.4-6.5h1.9v6.3h1.2L1098.6,612.5L1098.6,612.5z M1095.3,610.8
				v-1.7c0-0.3,0-0.7,0-1.2s0-0.9,0.1-0.9h-0.1c-0.2,0.4-0.4,0.7-0.6,1.1l-1.8,2.8L1095.3,610.8L1095.3,610.8z"/>
		</g>
	</g>
	<g id="CT012055" className="cursor--pointer"onClick={() => window.open("https://carisma-shop.com/collections/cybertruck-spares/products/cybertruck-motor-mount-set", "_blank")}>
		<g>
			<g>
				<path class="st4" d="M843,841.5h-86c-6.6,0-12-5.4-12-12l0,0c0-6.6,5.4-12,12-12h86c6.6,0,12,5.4,12,12l0,0
					C855,836.1,849.6,841.5,843,841.5z"/>
			</g>
			<polyline class="st5" points="843.8,832.8 846.8,829.5 843.5,826.2 			"/>
		</g>
		<g>
			<path class="st6" d="M760.4,825.2c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.7,1.4-0.7,2.5c0,2.2,0.8,3.3,2.5,3.3c0.7,0,1.6-0.2,2.5-0.5
				v1.8c-0.8,0.3-1.7,0.5-2.7,0.5c-1.5,0-2.6-0.4-3.3-1.3c-0.8-0.9-1.2-2.1-1.2-3.8c0-1,0.2-1.9,0.6-2.7c0.4-0.8,0.9-1.4,1.6-1.8
				c0.7-0.4,1.5-0.6,2.5-0.6s1.9,0.2,2.9,0.7l-0.7,1.7c-0.4-0.2-0.8-0.3-1.1-0.5C761.1,825.2,760.7,825.2,760.4,825.2z"/>
			<path class="st6" d="M768.9,833.5h-2.1v-8.2h-2.7v-1.8h7.5v1.8h-2.7V833.5z"/>
			<path class="st6" d="M779.4,828.5c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S779.4,826.9,779.4,828.5z M774.5,828.5
				c0,1.2,0.1,2.1,0.3,2.6s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8
				s-0.9,0.3-1.1,0.8C774.6,826.4,774.5,827.3,774.5,828.5z"/>
			<path class="st6" d="M785.7,833.5h-2.1v-5.8v-1v-1c-0.4,0.4-0.6,0.6-0.7,0.7l-1.1,0.9l-1-1.3l3.2-2.6h1.7L785.7,833.5
				L785.7,833.5z"/>
			<path class="st6" d="M795.4,833.5h-7V832l2.5-2.5c0.7-0.8,1.2-1.3,1.5-1.6c0.2-0.3,0.4-0.6,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8
				c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.9-0.3s-0.8,0.1-1.1,0.3c-0.4,0.2-0.7,0.4-1.1,0.8l-1.1-1.4c0.5-0.4,0.9-0.7,1.2-0.9
				s0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.3-0.1c0.6,0,1.2,0.1,1.7,0.3s0.8,0.5,1.1,1c0.3,0.4,0.4,0.9,0.4,1.4s-0.1,0.9-0.2,1.3
				c-0.2,0.4-0.4,0.8-0.8,1.2s-0.9,1-1.8,1.8l-1.3,1.2v0.1h4.4v1.8H795.4z"/>
			<path class="st6" d="M803.4,828.5c0,1.7-0.3,3-0.9,3.9s-1.5,1.3-2.6,1.3c-1.2,0-2-0.4-2.6-1.3s-0.9-2.1-0.9-3.8
				c0-1.8,0.3-3.1,0.9-3.9c0.6-0.8,1.4-1.3,2.6-1.3c1.2,0,2,0.4,2.6,1.3S803.4,826.9,803.4,828.5z M798.5,828.5
				c0,1.2,0.1,2.1,0.3,2.6s0.6,0.8,1.1,0.8s0.8-0.3,1.1-0.8s0.3-1.4,0.3-2.6c0-1.2-0.1-2.1-0.3-2.6s-0.6-0.8-1.1-0.8
				s-0.9,0.3-1.1,0.8C798.6,826.4,798.5,827.3,798.5,828.5z"/>
			<path class="st6" d="M808,827.2c1,0,1.7,0.3,2.3,0.8s0.9,1.3,0.9,2.2c0,1.1-0.3,2-1,2.6s-1.7,0.9-3,0.9c-1.1,0-2-0.2-2.7-0.5
				v-1.8c0.4,0.2,0.8,0.3,1.3,0.5c0.5,0.1,0.9,0.2,1.4,0.2c1.3,0,1.9-0.5,1.9-1.6c0-1-0.7-1.5-2-1.5c-0.2,0-0.5,0-0.8,0.1
				c-0.3,0-0.5,0.1-0.7,0.2l-0.8-0.5l0.4-5.1h5.4v1.8H807l-0.2,2h0.2C807.3,827.2,807.6,827.2,808,827.2z"/>
			<path class="st6" d="M816,827.2c1,0,1.7,0.3,2.3,0.8s0.9,1.3,0.9,2.2c0,1.1-0.3,2-1,2.6s-1.7,0.9-3,0.9c-1.1,0-2-0.2-2.7-0.5
				v-1.8c0.4,0.2,0.8,0.3,1.3,0.5c0.5,0.1,0.9,0.2,1.4,0.2c1.3,0,1.9-0.5,1.9-1.6c0-1-0.7-1.5-2-1.5c-0.2,0-0.5,0-0.8,0.1
				c-0.3,0-0.5,0.1-0.7,0.2l-0.8-0.5l0.4-5.1h5.4v1.8H815l-0.2,2h0.2C815.3,827.2,815.6,827.2,816,827.2z"/>
		</g>
	</g>
</g>
</svg>


 : <svg
  className="motorMount"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1545 1080"
>
<path class="st1" d="M545.3,483.6l0.5,9.2l1.4,9.6l2.3,9.9l3.2,10l4,10l4.8,9.8l5.5,9.5l6.1,9l6.6,8.5l7.1,7.7l7.4,6.9l7.6,6l7.7,5
	l7.7,3.9l7.6,2.8l7.4,1.6l7.1,0.4l6.6-0.8l6.1-2l1.9-0.9 M552.8,483.6l1,13.3l3.2,14.8l2.5,7.9l3.1,7.9l7.8,15.7l9.6,14.6l5.3,6.6
	l5.5,6.1l11.2,10.2l11,7.5l11,5.2l11.2,2.8l5.5,0.3l5.3-0.5l9.6-3.5l7.8-6.6l3.1-4.3l2.4-4.7 M721.8,536.5l-6.8,3.9 M756.5,655.7
	l15.5-9 M781,627.8v-14.3 M706.8,489.8l4.9-2.8 M835.3,585.3l5.3-3.1 M822.5,550.9l-5.3,3.1 M688.1,630.3l0.9-0.5 M696.2,640.8
	l-0.9,0.5 M685.4,622.7l1-0.6 M702,646l-1.1,0.6 M684.3,615.4l1.2-0.7 M708.3,649.2l-1.5,0.9 M685,609.1l1.7-1 M714,650.5l-1.7,1
	 M687.4,604.3l1.7-1 M718.9,649.5l-1.7,1 M691.2,601.3l1.8-1 M722.9,646.2l-2,1.1 M698.4,599.4l-2.2,1.3 M726.1,640.7l-2.9,1.7
	 M939.7,352.2L939.7,352.2l0,0.1l0,0.1l0,0.1l0.1,0.1 M938.8,352.8L938.8,352.8l-0.1-0.1l-0.1,0h-0.1l0,0l0,0 M969,375.4L969,375.4
	l-0.1-0.2l-0.1-0.1 M969.3,375.5L969.3,375.5l-0.1-0.1l-0.1,0l-0.1,0l-0.1,0 M968.8,375.5l0.1-0.3 M939.9,352.5l-0.1,0.8
	 M967.7,375.4L967.7,375.4l0.2-0.1l0.1,0l0.1,0l0,0 M938.8,352.8L938.8,352.8l0.2-0.1l0.1,0l0.1,0l0,0 M950.4,351.6L950.4,351.6
	l0,0.1l0,0.1l0,0.1l0.1,0.1 M949.1,352.2L949.1,352.2L949.1,352.2l0.1,0l0.1,0l0.1,0l0.1,0.1 M979.7,374.8L979.7,374.8l-0.1-0.2
	l-0.1-0.1 M979.6,374.9L979.6,374.9l0.1,0l0.1,0l0.1,0l0.1,0 M950.6,352l-0.1,0.8 M979.5,374.9l0.1-0.3 M949.8,352.2L949.8,352.2
	L949.8,352.2l-0.2,0l-0.1,0l-0.1,0.1 M978.7,374.8L978.7,374.8L978.7,374.8l-0.2,0l-0.1,0l-0.1,0 M921,365.8L921,365.8l-0.1-0.1
	l-0.1,0l-0.1,0l-0.1,0 M927.4,356.5L927.4,356.5l0,0.1l0,0.1l0,0.1l0.1,0.1 M926.5,357.1L926.5,357.1l-0.1-0.1l-0.1,0l-0.1,0l0,0
	l0,0 M920.8,364.8L920.8,364.8l-0.1-0.1l0-0.1l0-0.1l0-0.1 M950,388.4l0.1,0.1l0.1,0 M950.4,388.9L950.4,388.9l-0.1-0.1l0-0.1l0-0.1
	l0,0 M956.7,379.6L956.7,379.6l-0.1-0.2l-0.1-0.1 M956.7,379.7L956.7,379.7l0.1,0l0.1,0l0.1,0l0.1,0 M921.5,365.5l-0.5,0.3
	 M950,388.4l0.2-0.1 M956.5,379.7l0.1-0.3 M927.6,356.8l-0.1,0.8 M920.8,364.8L920.8,364.8l0,0.2l0,0.1l0,0 M949.8,387.4
	L949.8,387.4l0,0.2l0,0.1l0,0 M955.8,379.6L955.8,379.6L955.8,379.6l-0.2,0l-0.1,0l-0.1,0.1 M926.5,357.1L926.5,357.1l0.2-0.1l0.1,0
	l0.1,0l0,0 M1052.2,544.6l-0.9,1l-0.8,2.3l0.2,2.7l1.3,2.7l2.1,2.3l2.6,1.6l2.7,0.6l2.4-0.5l1-0.7 M1051.3,530.5L1051.3,530.5
	l1.5,1.9l2.4,1.1l2.9,0.2l3-0.8l2.6-1.7l1.9-2.2l0.8-2.5l-0.3-2.3 M1042.3,512.6l1.2,1.1l2.1,0.4l2.7-0.4l2.8-1.2l2.5-1.7l1.9-2.1
	l0.9-2.1l-0.2-1.8l-0.1-0.1 M1030.1,486.4L1030.1,486.4l1,0.4l1.9-0.2l2.6-0.7l2.9-1l2.8-1.3l2.2-1.3l1.3-1.1l0.2-0.7 M980.4,414.9
	l-0.5-0.6l-0.3-0.9l0.1-1.9l0.6-1.8l1-1.9l1.7-2.3l1.1-1.2l1.3-1.1l1.4-0.8l1.3-0.5l1.1-0.1l0.9,0.3l0.1,0.1 M1027.7,525.6l-0.1,0
	l-1.6,0.1l-1.8,1.3l-1.7,2.2l-1.4,2.8l-0.8,2.9l-0.1,2.7l0.3,1.4 M1073.3,505.1l0.8-0.1l3-0.8l2.6-1.7l1.9-2.2l0.8-2.5l-0.4-2.4
	l-0.1-0.2 M1064.5,488.8l0.6-0.2l2.8-1.2l2.5-1.7l1.9-2.1l0.9-2.1l-0.2-1.8l-0.1-0.2 M1056.4,471.6l0.1,0l2.9-1l2.8-1.3l2.2-1.3
	l1.3-1.1l0.2-0.7 M1005.9,400.8l0.2-0.3l1.1-1.2l1.3-1.1l1.4-0.8l1.3-0.5l1.1-0.1l0.9,0.3l0,0 M1068.9,547.6L1068.9,547.6l2.4-0.5
	l1.1-0.7 M1063.5,519.1l1.6,0.6l2.9,0.2l3-0.8l2.6-1.7l1.9-2.2l0.8-2.5l-0.4-2.4l0-0.1 M1054.4,501.4l1.4,0.2l2.7-0.4l2.8-1.2
	l2.5-1.7l1.9-2.1l0.9-2.1l-0.2-1.8l-0.1-0.1 M1044.3,479.2l0.8-0.1l2.6-0.7l2.9-1l2.8-1.3l2.2-1.3l1.3-1.1l0.2-0.7 M992.9,404.7
	l1.2-2l2-2.4l2.2-1.8l2.1-0.9l1.7,0.1l0.4,0.2 M776.2,613.8l0-2.5l-0.7-5.7l-1.5-6l-2-5.3 M769.1,628.4l1.1-0.7l2.7-2.8l1.9-3.8
	l1.1-4.6l0.1-0.5 M729.8,579.3l-0.9,1l-1.5,7.6 M748.7,624.9l7.2,2.5l6.7-1.3l4.5-5.1l1.6-8.2l-1.5-10l-3.1-7.4 M801.2,538.6l-2.6-3
	l-7.1-7.6l-7.4-6.8l-7.6-5.9l-7.7-5 M768.7,510.3l-7.7-4l-7.6-2.9l-7.4-1.8l-7.1-0.6l-2.6,0.1 M774.7,523.6l-9.3-6.5 M764.9,516.8
	l-9.8-4.5 M478.9,448.4l-0.5,0.3l-0.5,0.4l-0.5,0.5l-0.5,0.5l-0.4,0.6l-0.4,0.6l-0.3,0.7l-0.3,0.7 M509.8,569.9l-0.8-0.4l-0.9-0.3
	l-1-0.2l-1-0.1l-1,0.1l-1,0.2l-0.9,0.3l-0.8,0.4 M502.3,467.1l0.8-1.2l1.2-0.9l1.3-0.4l1.2,0 M436.3,586.2l-1.3-0.9l-1-1.1l-0.6-1.2
	l-0.2-1.2 M436.3,586.2l11.3,6.5 M436.3,507.8v78.4 M447.6,514.3l-11.3-6.5 M459.5,506l-1.9,0.3l-1.9-0.1l-1.8-0.4l-1.5-0.7
	l-1.2-0.9l-0.7-1l-0.1-1.1l0.4-1.1 M441.9,495.8l-5.7,3.3 M436.3,499.1L435,500l-1,1.1l-0.6,1.2l-0.2,1.2l0.2,1.2l0.6,1.2l1,1.1
	l1.3,0.9 M462.7,592.7l-1.6,0.8l-1.8,0.6l-2,0.4l-2.1,0.1l-2.1-0.1l-2-0.4l-1.8-0.6l-1.6-0.8 M447.6,514.3l1.6,0.8l1.8,0.6l2,0.3
	l2.1,0.1l2.1-0.1l2-0.3l1.8-0.6l1.6-0.8 M447.6,592.7v-78.4 M450.8,501l-8.9-5.1 M450.8,501l1.1-1.1l1.7-0.9l2.1-0.6l2.2-0.2
	l2.1,0.3l1.8,0.7l1.2,1.1l0.5,1.4l-0.2,1.5l-0.9,1.3l-1.6,1l-1.6,0.5 M468.3,511.1l-8.9-5.1 M475.5,452.7l26.4,15.2 M475.5,452.7
	l-2.7,6.7l-3.2,6.5l-3.8,6.3l-4.2,5.9l-4.6,5.4l-4.9,4.8l-5.1,4.1l-5.2,3.4 M547.2,478.6v3.9 M509.8,461.4l3.8-2.2 M547.2,478.6
	l-3.8,2.2 M513.6,459.3l33.6,19.4 M462.7,592.7l39.6-22.9 M468.3,511.1l-5.7,3.3 M462.7,514.3v78.4 M502.3,569.9V467.1 M502.3,467.1
	l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1 M501.9,467.9l-2.7,6.7l-3.2,6.5l-3.8,6.3l-4.2,5.9l-4.6,5.4l-4.9,4.8l-5.1,4.1
	l-5.2,3.4 M613.2,490.2l-92-53.1 M512.7,449.6l-2.2,0.8l-2.6,0.3l-2.5-0.3l-2.2-0.8l-1.4-1.2l-0.5-1.5l0.5-1.5l1.4-1.2l2.2-0.8
	l2.5-0.3l2.6,0.3l2.2,0.8l1.4,1.2l0.5,1.5l-0.5,1.5L512.7,449.6 M583.9,486.4l-1.4,1.2l-0.5,1.5l0.5,1.5l1.4,1.2l2.2,0.8l2.6,0.3
	l2.6-0.3l2.2-0.8l1.4-1.2l0.5-1.5l-0.5-1.5l-1.4-1.2l-2.2-0.8l-2.6-0.3l-2.6,0.3L583.9,486.4 M498.5,437l2.4-1.1l2.8-0.9l3-0.5
	l3.1-0.2l3.1,0.2l3,0.5l2.8,0.9l2.4,1.1 M478.9,448.4l19.6-11.3 M506.8,464.5l-27.9-16.1 M506.8,464.5l0.4,0l0.4,0.1l0.4,0.1
	l0.4,0.1l0.4,0.1l0.4,0.1l0.3,0.2l0.3,0.2 M545.3,483.6l1.9-1.1 M645.3,535.6l-2.8-0.8l-4.8-2.2l-4.8-3.3l-4.6-4.3l-4.2-5.1
	l-3.7-5.7l-3-6l-2.3-6.2l-1.4-6.1l-0.5-5.8 M613.2,490.2l-5.7,3.3 M644.7,539.1l-3.3,0l-4.6-1l-4.8-2.2l-4.8-3.3l-4.6-4.3l-4.2-5.1
	l-3.7-5.7l-3.1-6l-2.3-6.2l-1.4-6.1l-0.5-5.8 M656.5,521.7l-0.4,4.6 M603.7,491.3l3.8,2.2 M603.7,491.3l-14.9,8.6 M603.7,491.3
	l0.5,6.3l1.4,6.6l2.3,6.7l3.1,6.6l3.9,6.3l4.4,5.7l4.9,5l5.1,4.1l5.2,3l5.1,1.9l3.4,0.6 M588.8,499.9l0.5,6.3l1.4,6.6l2.3,6.7
	l3.1,6.6l3.9,6.3l4.4,5.7l4.9,5l5.1,4.1l5.2,3l5.1,1.9l4.9,0.6l4.4-0.6l3.9-1.8l3.1-3l2.3-4.1l1.4-5l0.5-5.7 M576.9,497.5l1.6,0
	l5.8,0.8l4.5,1.6 M664.5,528.8l-0.4,0l-2.6,0.3l-2.2,0.8 M659.4,529.9l-1.4,1.2l-0.5,1.5l0.5,1.5l1.4,1.2l2.2,0.8l0.1,0
	 M645.4,532.5l14.9-8.6 M645.4,532.5l2.3,2.1l1.6,3.3l0.1,1.5 M668.1,519.4l-2.2,1.3 M666,520.7l-3.8-2.2 M662.2,518.5l-5.7,3.3
	 M660.3,523.9l-3.8-2.2 M545.3,483.6l1.5-0.6l1.7-0.3l1.8,0.1l1.7,0.4l0.9,0.4 M543.4,484.7l1.9-1.1 M703.7,663.3l11.3-6.5
	 M709.1,486.7L709.1,486.7l0.5-0.2l0.5-0.1l0.5,0.1l0.6,0.2l0.6,0.3 M671.6,510.3l-13.2,34.3 M576.9,497.5l0.4,6.8l1.3,7.2l2.2,7.3
	l3,7.3l3.7,7.1l4.3,6.8l4.9,6.2l5.3,5.6l5.5,4.7l5.7,3.8l5.7,2.8l5.5,1.7l5.3,0.5l4.9-0.6l4.3-1.8l3.7-2.9l3-3.9l2.2-4.8l1.3-5.6
	l0.4-6.3 M576.9,497.5l-24.1-13.9 M658.4,544.6l-9-5.2 M721.6,653.1l2.9,1.7 M712.9,655.3l2.1,1.4 M715,656.7l9.4,5.5 M718.8,585.6
	l-2.3-4.3L714,575l-1.7-5.8 M780.2,630.1l-7-4 M696.5,486.4l0.1-0.1l0.9-0.3l1-0.1l1.1,0.1l1.1,0.3l1.1,0.6 M715,547.9l-11.3,6.5
	 M775.2,614.4l0.9,1.7l0.9,1.7l0.8,1.7l0.8,1.7l0.7,1.7l0.6,1.7l0.6,1.7l0.5,1.7 M715,540.5l-2.2-1.8l-1.9-2.6l-1.3-2.9l-0.4-2.8
	l0.1-1 M752,561.8l15.5,8.9 M715,540.5l9.5,5.5 M702.8,525.6l0.1,3.1l1,4.4l1.9,4.5l2.6,4.2l3.2,3.5l3.5,2.6 M746,565.8l21.8,12.6
	 M715,547.9l9.4,5.5 M711.7,487l110.8,63.9 M840.6,582.3v139.4 M840.6,721.6l-4.9,2.8 M767.8,578.3l0.9,0.5 M756.5,584.9l11.3-6.5
	 M738.3,758l0.2,1.2l0.6,1.2l1,1.1l1.3,0.9 M822.5,550.9l3.5,2.4l3.4,3.1l3.1,3.7l2.7,4.2l2.3,4.5l1.7,4.6l1,4.5l0.3,4.3
	 M741.4,762.4l16.2,9.4 M741.4,684v78.4 M775.2,614.4l3.8-2.2 M781,613.5l-5.8,0.8 M781,613.5l0.6-0.1l0.5,0l0.3,0.1 M811.2,609.2
	l-17.6,2.5 M757.7,693.3l-16.2-9.4 M764.6,682.1l-1.9,0.3l-1.9-0.1l-1.8-0.4l-1.5-0.7l-1.1-0.9l-0.7-1l-0.1-1.1l0.4-1.1
	 M741.4,675.2l5.7-3.3 M741.4,684l-1.3-0.9l-1-1.1l-0.6-1.2l-0.2-1.2l0.2-1.2l0.6-1.2l1-1.1l1.3-0.9 M757.7,693.3l1.8-0.4l1.8-0.4
	l1.7-0.5l1.7-0.5l1.6-0.6l1.6-0.7l1.5-0.7l1.4-0.8 M757.7,771.7v-78.4 M770.8,767.1l-1.4,0.8l-1.5,0.7l-1.6,0.7l-1.6,0.6l-1.7,0.5
	l-1.7,0.5l-1.8,0.4l-1.8,0.4 M788.6,551.1l-1.4,1.2l-0.5,1.5l0.5,1.5l1.4,1.2l2.2,0.8l2.6,0.3l2.6-0.3l2.2-0.8l1.4-1.2l0.5-1.5
	l-0.5-1.5l-1.4-1.2l-2.2-0.8l-2.6-0.3l-2.6,0.3L788.6,551.1 M701.8,486.9l111.3,64.2 M677.3,501l24.5-14.2 M773.2,594.1l3,1.3
	l4.1,3.6l1.8,3.4l0.5,3.7l-0.8,3.1l-1.8,2.3l-1.1,0.8 M815,607l-36,5.2 M738.3,683.3l-34.6-20 M703.7,663.3l-3.5-2.6l-3.2-3.5
	l-2.6-4.2l-1.9-4.5l-1-4.4l-0.1-3.2 M728.4,649.2l27.9,16.1 M756.5,584.9l-52.8-30.5 M703.7,554.4l-3.5-2.6l-3.2-3.5l-2.6-4.2
	l-1.9-4.5l-1-4.4v-4l1-3.3l1.9-2.3l2.6-1.1l3.2,0.2l3.5,1.4 M703.7,526.1l52.8,30.5 M756.5,556.6l3.5,2.6l3.2,3.5l2.6,4.2l1.9,4.5
	l1,4.4v4l-1,3.3l-1.9,2.3l-2.6,1.1l-3.2-0.2l-3.5-1.4 M788.6,565.3L677.3,501 M671.6,500.7l0.6-0.3l0.6-0.2l0.7-0.1l0.7,0l0.7,0.1
	l0.8,0.2l0.8,0.3l0.8,0.4 M671.6,500.7v58 M671.6,558.8l-0.5,8.7l-1.4,8l-2.3,7.2l-3.2,6.3l-4,5.3l-4.8,4.3l-5.5,3.2l-6.1,2
	l-6.6,0.8l-7.1-0.4l-7.4-1.6l-7.6-2.8l-7.7-3.9l-7.7-5l-7.6-6l-7.4-6.9l-7.1-7.7l-6.6-8.5l-6.1-9l-5.5-9.5l-4.8-9.8l-4-10l-3.2-10
	l-2.3-9.9l-1.4-9.6l-0.5-9.2 M543.4,484.7v-3.9 M543.4,480.8l-33.6-19.4 M509.8,461.4v3.9 M509.8,465.3v104.5 M509.8,569.9
	l228.5,131.9 M763.2,658.5l-2.8-0.9l-3.9-1.9 M736.8,644.3l19.6,11.3 M683.4,602.5l-0.8-1.6l-2.6-6.3l-1.8-6.3l-0.9-6v-5.6l0.9-5
	l1.8-4.2l2.6-3.3l3.3-2.3l3.9-1.1l4.4,0l4.7,1.2l4.9,2.3 M756.5,594.7l-52.8-30.5 M756.5,594.7l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2
	l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2 M758.9,596.2l49-7.1 M813.1,551.1l4.4,3.1l4.2,3.9l3.9,4.7l3.4,5.2l2.8,5.6l2.1,5.7l1.3,5.6
	l0.4,5.3 M813.1,551.1l-24.5,14.2 M807.9,589.1l-1.7-3.7l-1.9-3.6l-2.2-3.5l-2.4-3.2l-2.6-3l-2.8-2.7l-2.9-2.3l-2.9-1.9
	 M807.9,589.1l3.8-2.2 M811.7,586.9l2.7-0.9l2.8,0.3l2.4,1.5l1.7,2.4l0.8,3l-0.2,3.4l-0.6,2 M755.9,677.1l-8.9-5.1 M755.9,677.1
	l1.1-1.1l0.3-0.2l1.8-0.9l2.1-0.5l2.2-0.1l2.1,0.4l1.7,0.8l1.1,1.2l0.4,1.4l-0.4,1.5l-1.1,1.3l-0.5,0.4l-1.8,0.8l-0.5,0.1
	 M773.5,687.2l-8.9-5.1 M793.6,611.7l17.6,10.2 M793.6,611.7l-3.6,0.5l-3.7,0.6l-3.8,0.7 M782.4,613.6l-0.4,10.3l-1,3.9 M747.1,672
	l5.3-3.5l5.2-4.2l5-4.9l4.7-5.6l4.3-6l3.8-6.4l3.2-6.7l2.6-6.8 M770.8,688.8l2.6-1.5 M770.8,767.1v-78.4 M770.8,767.1l64.9-37.5
	 M835.7,590.3v139.4 M821.3,598.6l14.4-8.3 M821.3,598.6l-0.9,2.1l-1.2,2l-1.3,1.7l-1.4,1.4L815,607 M811.2,609.2l3.8-2.2
	 M811.2,621.9v-12.7 M773.5,687.2l5.9-3.9l5.8-4.9l5.5-5.8l5-6.5l4.5-7l3.8-7.4l3.1-7.6l2.3-7.6l1.4-7.4l0.5-7.1 M699,649l4-2.3
	 M681.2,615.3l3.7-2.2 M705.9,653.5l3.4-2 M750.7,591.3l1.6,2l2.7,4.1l0.1,0.2 M681.6,607.6l3.3-1.9 M712.5,655.5l3.4-1.9
	 M683.9,601.6l3.5-2 M739.9,642.5L718.4,655 M758.7,611.9l-0.2,2.8l-1,3.4l-1.8,2.6l-1.3,1 M749.8,590.8l1.4,1.7l2.9,4.2l0.2,0.4
	 M733,584.7l1.2-0.5l3.1-0.4l0.2,0 M710.4,586.1l-0.8-0.8 M688.1,597.7l21.5-12.4 M692.1,602.9l1.5-0.8 M743.7,640.2l0.1-0.1
	l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M743.7,640.2l-21.5,12.4 M744.5,639.4l-0.4-1.2l-0.4-1 M744.5,639.4
	L723,651.8 M720,646l1.6-0.9 M715.2,584l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0 M713.9,584l-21.5,12.4 M717,586.7
	l-0.5-0.8l-1.3-1.9 M715.2,584l-21.5,12.4 M698.5,601.3l-1.7,1 M747,635.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2
	l0.1-0.2 M747,635.2l-21.5,12.4 M722,641.2l2-1.2 M747.5,634l-0.7-1.1l-0.7-1.1l-1.2-1.4 M747.5,634L726,646.4 M723.4,590.2
	l-0.1-0.5 M704.5,602.3l-2.4,1.4 M721.7,585.3l-0.2-0.1l-0.2-0.1l-0.2-0.1L721,585l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1
	 M720.3,584.8l-21.5,12.4 M721.7,585.3l-21.5,12.4 M723.4,589.8l-21.5,12.4 M723.4,589.8l-0.3-1.2l-0.4-1.2l-1-2.1 M743.9,622.7
	l1.2,0.7 M722.4,635.1l21.5-12.4 M729.1,594.8l0.1-1.3 M748.4,628.3l-21.5,12.4 M748.4,628.3l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2
	l0-0.2v-0.2 M729.1,594.8l-21.5,12.4 M730.1,595.6L730.1,595.6l-0.3-0.3l-0.2-0.2l-0.2-0.2l-0.2-0.2 M745.2,623.5l-21.5,12.4
	 M748.5,626.7L727,639.1 M748.5,626.7l-0.8-1l-0.9-0.9l-1.6-1.3 M742.7,615.8l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3
	l-0.1-0.3l-0.1-0.2 M742.7,615.8l1.2,0.3 M721.2,628.3l21.5-12.4 M728.5,589l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1
	l-0.2-0.1l-0.2-0.1 M727.1,588.1l-21.5,12.4 M728.5,589L707,601.5 M729.2,593.4l-21.5,12.4 M729.2,593.4l0-1.1l-0.2-1.2l-0.5-2.1
	 M736,602.1l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3 M740,608.8l-0.2-0.3l-0.2-0.3l-0.2-0.3
	l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3 M734.3,599.9l-21.5,12.4 M734.3,599.9l0.5-1 M740,608.8l1-0.2 M718.5,621.2
	l21.5-12.4 M738.7,606.2l-21.5,12.4 M738.7,606.2l0.8-0.6 M708.7,633.1l0.6,0.6l3.7,3.3l3.8,2.3l3,1.1 M699.5,605.1l-0.3,1.3
	l-0.3,4.2l0.6,4.7l0.8,3 M747.8,620.1l-21.5,12.4 M747.8,620.1l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l-0.1-0.2 M743.9,616.1
	l-21.5,12.4 M747.4,618.3l-21.5,12.4 M747.4,618.3l-0.9-0.7l-0.9-0.6l-1.7-0.8 M733.8,593.7l-0.7,0.3l-0.3,0.2 M734.8,598.9
	l-21.5,12.4 M734.8,598.9l0.3-0.9l0.1-1l-0.1-1.9 M735.1,595l-21.5,12.4 M735.1,595l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2
	l-0.2-0.2l-0.2-0.2l-0.2-0.2 M733.8,593.7l-21.5,12.4 M743.6,612.8L743.6,612.8l0.6-0.5l0.9-1.1 M745.2,611.3l-21.5,12.4
	 M745.2,611.3l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M741,608.6L719.5,621 M744.4,609.5
	l-21.5,12.4 M744.4,609.5l-0.9-0.4l-0.9-0.3l-1.5-0.2 M739.7,601l-0.9-0.1l-0.8,0l-1,0.4 M739.5,605.6L718,618 M739.5,605.6l0.5-0.6
	l0.4-0.8l0.4-1.6 M740.8,602.7l-21.5,12.4 M740.8,602.7l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2
	 M739.7,601l-21.5,12.4 M712.8,647.2l-3.5-0.1l-3.7-1.2l-3.8-2.3l-3.7-3.3l-3.4-4l-2.9-4.6l-2.2-4.9l-1.4-4.9l-0.6-4.7l0.3-4.2
	l1.2-3.5l2-2.6l2.7-1.6l3.2-0.5l3.6,0.7l3.8,1.8l3.8,2.8l3.6,3.7l3.2,4.3l2.6,4.8l1.8,4.9l1,4.9l0.1,4.5l-0.8,3.9l-1.6,3.1l-2.4,2.1
	L712.8,647.2 M714.5,651.3l-0.7-1.6 M714.5,651.3l0.6,1.2l0.8,1.1l1.5,1.7 M718.4,655l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0
	l-0.1,0l-0.1,0 M717.3,650.5l0.5,1.2l0.3,1.2l0.4,2.1 M717.3,650.5l-0.8-1.6 M717.9,648.1l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1
	l-0.2,0.1l-0.2,0.1l-0.2,0.1l-0.2,0.1 M718.9,649.5l-1-1.4 M722.2,652.6l-0.9-0.7l-0.9-0.8l-1.5-1.7 M723,651.8l-0.1,0.1l-0.1,0.1
	l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1 M721,647.4l0.7,1.1l0.6,1.2l0.8,2.1 M721,647.4l-1-1.4 M720.9,644.4
	l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2L720,646 M722,645.6l-1.2-1.1 M725.5,647.6l-0.9-0.4l-0.9-0.5
	l-1.6-1.2 M726,646.4l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2l-0.1,0.2 M723.2,642.3l0.8,0.9l0.8,1.1l1.2,2
	 M723.2,642.3l-1.2-1.1 M722.3,639.1l0,0.3l0,0.3l0,0.3l0,0.3l0,0.3l0,0.3L722,641l-0.1,0.3 M723.6,639.9l-1.2-0.8 M726.9,640.7
	l-0.8,0l-0.9-0.1l-1.6-0.6 M727,639.1v0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M723.7,635.9l0.9,0.7l0.9,0.8l1.5,1.7
	 M723.7,635.9l-1.2-0.7 M722.2,632.7l0,0.3l0,0.3l0,0.3l0,0.3l0,0.3l0,0.3l0,0.3l0,0.3 M723.4,633l-1.2-0.3 M726.3,632.5l-0.7,0.3
	l-0.7,0.2l-1.5,0 M725.9,630.7l0.1,0.2l0.1,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2l0,0.2 M722.4,628.5l0.9,0.4l0.9,0.6l1.6,1.2
	 M722.4,628.5l-1.2-0.3 M720.4,625.7l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3 M721.4,625.5l-1,0.2
	 M723.7,623.7l-0.5,0.7l-0.5,0.6l-0.6,0.3 M722.9,621.9l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2
	 M719.5,621l0.8,0l0.9,0.2l1.6,0.7 M719.5,621l-1,0.2 M717.2,618.7l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3
	l0.2,0.3 M718,618l-0.8,0.6 M719.3,615.1l-0.2,0.9l-0.3,0.8L718,618 M718.2,613.4l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2l0.1,0.2
	l0.1,0.2l0.1,0.2 M715.5,613.7l0.4-0.2l0.8-0.1l1.5,0 M715.3,613.9l-0.8,0.6 M712.8,612.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3l0.2,0.3
	l0.2,0.3l0.2,0.3l0.2,0.3 M713.3,611.3l-0.5,1 M713.6,607.4l0.1,1.1l0,1.1l-0.4,1.7 M712.3,606.1l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2
	l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2 M710,607.8l0.5-0.7l0.6-0.5l1.2-0.6 M710,607.8l-0.5,1 M707.6,607.2l0.2,0.2l0.2,0.2l0.2,0.2
	l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2l0.2,0.2 M707.7,605.8l-0.1,1.3 M707,601.5l0.3,1.2l0.3,1.2l0.1,2 M705.6,600.5l0.2,0.1l0.2,0.1
	l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1 M704.2,603.4l0.2-0.9l0.4-0.8l0.8-1.2 M704.2,603.4l-0.1,1.4 M702.1,603.7
	l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1l0.3,0.1 M701.9,602.2l0.2,1.5 M700.2,597.7l0.6,1.2l0.5,1.2l0.6,2.1
	 M698.8,597.2l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1l0.2,0.1 M698.4,601l-0.1-1.1l0.1-1l0.4-1.6 M698.4,601
	l0.3,1.5 M696.8,602.2l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0.1 M696.2,600.6l0.6,1.6 M693.7,596.4l0.8,1.1l0.7,1.1l1,2.1
	 M692.4,596.4l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0l0.2,0 M693.1,600.8l-0.4-1.2l-0.2-1.2l-0.1-2 M693.1,600.8l0.6,1.6
	 M692.1,602.9l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2-0.1l0.2,0l0.2,0 M691.2,601.3l0.9,1.5 M688.1,597.7l0.9,0.8l0.9,0.9
	l1.4,1.9 M687.1,598.3l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M688.8,602.8l-0.6-1.2l-0.4-1.2l-0.6-2.1
	 M688.8,602.8l0.9,1.5 M688.5,605.6l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.2-0.1l0.2-0.1l0.2-0.1 M687.4,604.3l1.1,1.3
	 M683.9,601.6l0.9,0.5l0.9,0.6l1.6,1.5 M683.3,602.7l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M685.8,607
	l-0.8-1l-0.7-1.1l-1-2.1 M685.8,607l1.1,1.3 M686.2,610.1l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2
	 M685,609.1l1.2,1 M681.6,607.6l0.9,0.2l0.9,0.3l1.7,0.9 M681.3,609.1l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2
	 M684.4,612.8l-0.9-0.8l-0.8-1l-1.4-1.9 M684.4,612.8l1.2,0.9 M685.6,616l0-0.3l0-0.3l0-0.3l0-0.3l0-0.3l0-0.3l0-0.3l0-0.3
	 M684.3,615.4l1.2,0.5 M681.2,615.3l0.8-0.2l0.8,0l1.6,0.3 M681.3,617l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2l0-0.2
	 M684.8,619.7l-0.9-0.5l-0.9-0.7l-1.6-1.5 M684.8,619.7l1.2,0.5 M686.6,622.8l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3
	l-0.1-0.3l-0.1-0.3l-0.1-0.3 M685.4,622.7l1.1,0.1 M682.8,623.9l0.6-0.5l0.6-0.4l1.4-0.3 M683.4,625.7l-0.1-0.2l-0.1-0.2l-0.1-0.2
	l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M686.9,627.3l-0.9-0.2l-0.9-0.4l-1.7-1 M686.9,627.3l1.1,0 M689.1,629.9l-0.1-0.3
	l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3l-0.1-0.3 M688.1,630.3l0.9-0.4 M686.4,632.7l0.3-0.8l0.4-0.7l1-0.9
	 M687.3,634.5l-0.1-0.2L687,634l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2 M690.5,634.7l-0.8,0.2l-0.8,0l-1.6-0.4
	 M690.5,634.7l0.9-0.4 M692.9,636.7l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3 M692.3,637.5l0.7-0.8
	 M691.5,640.9l0.1-1l0.2-1l0.6-1.5 M692.7,642.5l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2l-0.1-0.2
	 M695.3,641.4l-0.6,0.5l-0.7,0.3l-1.4,0.3 M695.3,641.4l0.6-0.9 M697.8,642.5l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2l-0.2-0.2
	l-0.2-0.3l-0.2-0.3l-0.2-0.3 M697.5,643.7l0.3-1.2 M697.7,647.8l-0.2-1.2l-0.1-1.1l0.1-1.8 M699,649l-0.2-0.1l-0.2-0.1l-0.2-0.1
	l-0.2-0.1l-0.2-0.1l-0.2-0.2l-0.2-0.2l-0.2-0.2 M700.9,646.6l-0.3,0.8l-0.5,0.6l-1,0.9 M700.9,646.6l0.3-1.2 M703.2,646.8l-0.3-0.2
	l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.3-0.2l-0.2-0.2 M703.2,648.3l-0.1-1.5 M704.5,652.8l-0.5-1.2l-0.4-1.2l-0.4-2.1
	 M705.9,653.5l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1L705,653l-0.2-0.1l-0.2-0.1l-0.2-0.1 M706.8,650.1l-0.1,1l-0.2,0.9l-0.6,1.4
	 M706.8,650.1l-0.1-1.5 M708.7,649.3l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.2-0.1l-0.3-0.1 M709.1,650.9
	l-0.4-1.6 M711.2,655.3l-0.7-1.1l-0.6-1.2l-0.8-2.1 M712.5,655.5l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0
	 M712.4,651.4l0.2,1.2l0.1,1.1l-0.1,1.8 M712.4,651.4l-0.4-1.6 M713.7,649.7l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0l-0.2,0h-0.2l-0.2,0
	 M746.6,607.6l9.3-1.3 M747.6,601.6l6-0.9 M748.7,600.1l-1.6,2.2l-0.7,1.6l-0.3,1.1 M753.6,600.7l-2.6-0.2l-1.5-0.2l-0.8-0.2
	 M755.9,606.3l-0.7-1l-1-1.9l-0.5-2.1l-0.1-0.7 M753.3,611.3l0.7-2.1l1-1.6l1-1.2 M748.4,610.7l1.7,0.3l1.7,0.2l1.5,0.1
	 M746.1,605.1l0.1,0.7l0.5,2.1l1,1.9l0.7,1 M758.2,612.4l1-3.2l0.1-3.5l-0.9-3.3l-1.6-2.6l-2.2-1.8l-1.7-0.6 M746.6,608.4l-0.6-2.5
	l0.2-2.6l1-2.2l1.6-1.5l2-0.6l2.1,0.4l1.8,1.4l1.3,2.1l0.6,2.5l-0.2,2.6l-1,2.2l-1.6,1.5l-2,0.6l-2.1-0.4l-1.8-1.4L746.6,608.4
	 M752.6,616.2l-2.2,0.1l-2.4-0.8l-1.8-1.2l-1.5-1.5l-0.4-0.6 M750.3,616.4l-0.7,0.2l-3.5-0.7l-1.6-0.9l-1.4-1.2l-0.3-0.5
	 M742.8,609.2l-0.6-1.7l-0.3-1.5l0.2-4l1.5-3.6l2.4-2.4l2.4-0.7 M748.1,616.7l-0.7,0.2l-2.6-0.5 M740.3,601.5l1.1-2.7l2.4-2.4
	l2.4-0.7 M740.3,608.9l-0.4-1.1l-0.3-1.5l0.1-1.6 M752.5,616.2l0.8-0.1l0.8-0.2l0.8-0.3l0.7-0.4l0.7-0.5l0.7-0.6l0.6-0.7l0.5-0.8
	 M752.9,597.4l-1.5-0.4l-2.3,0.3l-2.1,1.2l-1.6,2l-0.9,2.5 M744.6,602.9l0.3-1.2l1.2-2.4l1.8-1.6l1.7-0.5 M748.3,616.7l-0.7,0.2
	l-3.5-0.7l-0.5-0.4 M740.3,602l1.3-3.2l2.4-2.4l2.4-0.7 M740.6,608.6l-0.6-2.3l0-1.2 M750.6,616.4l-0.7,0.2l-3.5-0.7l-3-2.1
	l-0.4-0.5 M743,608.9l-0.8-2.9l-0.1-2l0.3-2l1.5-3.6l2.4-2.4l3-0.9l3.2,0.7l2.7,2.2l0.8,1.1l0.1,0.2 M752.5,616.2l-2.8-0.2l-2.6-1.2
	l-2.2-2l-0.5-0.7 M744.6,602.9l-0.4,2.5l0.2,2.9l1,2.9l1.7,2.4l2.2,1.7l2.8,0.8l0.4,0 M747.7,551.5l1,3.4 M747.2,556.5l1.5-0.9
	 M745.6,556.6l3-1.7 M742.4,548.5l1.7,2.9l1.5,5.2 M747.2,556.5l-0.2,4.6l-1.8,3.4l-2.2,1.1 M746.3,556.2l0.9,0.4 M747.9,660.5
	l0.1,0.2l0.9,5.1 M747.4,667.4l1.5-0.9 M745.9,667.5l3-1.7 M742.7,657.5l2.2,4.9l0.9,5.1 M747.4,667.4l-0.9,4.1l-1.4,1.6 M746.7,667
	l0.7,0.4 M728.5,560.3l5.2-3 M725.8,554.8l4.4-2.5 M742.8,566.1l0.3-0.2l1.7-2l0.9-2.9l0-3.6 M728.6,540.5l-0.4,0.2 M745.8,557.4
	l-0.9-3.9l-1.8-4l-0.6-1.1 M728.5,560.3l0.4,0.3l0.5,0.2l0.5,0.2l0.5,0.2l0.6,0.2l0.6,0.2l0.6,0.2l0.6,0.2 M725.8,554.8l0.3,0.7
	l0.3,0.7l0.3,0.7l0.3,0.7l0.4,0.7l0.4,0.7l0.4,0.6l0.4,0.6 M727.3,551l-0.3,0.5l-0.3,0.5l-0.2,0.5l-0.2,0.5l-0.2,0.5L726,554
	l-0.1,0.4l-0.1,0.4 M731.6,552.7l-0.6-0.2l-0.6-0.2l-0.6-0.2l-0.6-0.2l-0.5-0.2l-0.5-0.2l-0.5-0.2l-0.4-0.3 M734.4,558.2l-0.4-0.6
	l-0.4-0.7l-0.4-0.7l-0.4-0.7l-0.3-0.7l-0.3-0.7l-0.3-0.7l-0.3-0.7 M732.8,562l0.1-0.4l0.1-0.4l0.2-0.5l0.2-0.5l0.2-0.5l0.2-0.5
	l0.3-0.5l0.3-0.5 M737.6,567l2.4,0.2l2.4-0.9l1.7-2l0.9-2.9l0-3.6 M728.8,540.6l-0.6,0.2l-2.1,1.4l-1.3,2.5l0,0.1 M745,557.8
	l-0.9-3.9l-1.8-3.9l-1.6-2.5 M727.9,668.5l5.3-3.1 M725.7,662.9l3.6-2.1 M732.1,662.3l0.3,0.7 M744.5,673.5l1-2l0.5-3.3
	 M728.6,649.4l-0.4,0.2 M745.9,668.3l-0.5-3.8l-1.3-4l-1.7-3.1 M727.9,668.5l0.5,0.4l0.5,0.3l0.5,0.3l0.5,0.3l0.6,0.3l0.6,0.3
	l0.6,0.3l0.6,0.3 M725.7,662.9l0.2,0.7l0.2,0.7l0.2,0.7l0.3,0.7l0.3,0.7l0.3,0.7l0.3,0.7l0.4,0.6 M727.9,659.8l-0.4,0.4l-0.3,0.4
	l-0.3,0.4l-0.3,0.4l-0.3,0.4l-0.2,0.4l-0.2,0.4l-0.2,0.3 M732.2,662.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.6-0.3l-0.5-0.3l-0.5-0.3
	l-0.5-0.3l-0.5-0.4 M734.4,667.9l-0.4-0.6l-0.3-0.7l-0.3-0.7l-0.3-0.7l-0.3-0.7l-0.2-0.7l-0.2-0.7l-0.2-0.7 M732.2,671l0.2-0.3
	l0.2-0.3l0.2-0.4l0.3-0.4l0.3-0.4l0.3-0.4l0.3-0.4l0.4-0.4 M743.7,673.9l1-2l0.5-3.3 M737.6,675.9l0.9,0.2l1.7,0 M728.8,649.5
	l-1.6,0.7l-1.7,1.9l-0.6,1.5 M745.1,668.7l-0.5-3.8l-1.3-4l-2.1-3.8l-0.5-0.7 M940.4,351.9L940.4,351.9l-0.2-0.1l-0.1,0l-0.1,0l0,0
	 M938.4,352.7L938.4,352.7L938.4,352.7l-0.1,0.1 M933.9,358.2L933.9,358.2l0.6-1.1l0.7-1l0.7-0.9l0.8-0.9l0.8-0.8l0.9-0.7
	 M940.4,351.9L940.4,351.9l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l0,0.1l0,0.1 M968.9,375.1l-29-22.6 M969.3,374.5
	L969.3,374.5l-0.2,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l0,0.1l0,0.1 M970.7,386.2l1-1.5l2.3-2.6l2.5-1.9 M976.5,380.2l2.4-0.9
	l1.8,0.2l1,1.4l0,0.1 M967.6,383.2l0.7-1l2.3-2.6l2.5-1.9 M973.1,377.6l2.4-0.9l1.8,0.2l0.3,0.2 M969.3,375.5l-0.2,0.1l-0.4,0.1
	l-0.4,0l-0.3-0.1l-0.3-0.2 M967.7,375.4l-29-22.6 M938.8,352.8l-0.9,0.7l-0.9,0.8l-0.9,0.9l-0.8,1l-0.7,1.1l-0.5,1 M943.5,351.8
	l-0.2-0.2l-0.6-0.2l-0.7,0l-0.8,0.1l-0.8,0.3 M940.4,351.9l29,22.6 M969.3,374.5l0.1,0.1l0.1,0.2l0,0.2l-0.1,0.2l-0.2,0.2
	 M950.7,351.2L950.7,351.2l0.1,0l0.1,0l0.1,0l0.1,0 M949.1,352.3L949.1,352.3l0-0.1l0,0 M946.8,354.4l0.6-0.6l0.8-0.8l0.9-0.7
	 M979.5,374.6L979.5,374.6l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0 M950.6,352L950.6,352l0.1-0.2l0.1-0.1l0.1-0.1
	l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0 M979.5,374.6l-29-22.6 M983.7,382.5l0.9-1l2.5-1.9 M987.2,379.7l2.4-0.9l1.8,0.2l1,1.4l0,0.1
	 M980.7,379.5l0.6-0.6l2.5-1.9 M983.8,377l2.4-0.9l1.8,0.2l0.3,0.2 M954.2,351.3l-0.2-0.2l-0.6-0.2l-0.7,0l-0.8,0.1l-0.9,0.3
	 M951.1,351.3l29,22.6 M980,373.9l0.1,0.1l0.1,0.2l0,0.2l-0.1,0.2l-0.2,0.2 M980,374.9l-0.2,0.1l-0.4,0.1l-0.4,0l-0.3-0.1l-0.3-0.2
	 M978.4,374.9l-29-22.6 M949.4,352.2l-0.9,0.7l-0.9,0.8l-0.7,0.7 M920.6,366.5L920.6,366.5l-0.1-0.1l0-0.1l0-0.1v0 M927.7,356.1
	L927.7,356.1l0.1,0l0.1,0l0.1,0l0.1,0 M926.1,357L926.1,357L926.1,357l-0.1,0.1 M920.8,364.4l0.3-1l0.5-1l0.6-1l0.7-1l0.7-0.9
	l0.8-0.9l0.8-0.8l0.9-0.7 M920.7,364.5L920.7,364.5L920.7,364.5l0.1-0.1l0.1,0 M949.5,389.1L949.5,389.1l0-0.2l0-0.1l0.1-0.1
	l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1 M921,365.8l29,22.6 M920.6,366.5L920.6,366.5l0-0.2l0-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1
	l0.1-0.1 M956.6,379.4L956.6,379.4l0.1-0.2l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0 M927.6,356.8L927.6,356.8l0.1-0.2
	l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0 M956.6,379.4l-29-22.6 M958.9,398.7l-1.2-0.3l-1-1.4l-0.1-2.3l0.9-2.8l1.8-3
	l2.3-2.6l2.5-1.9 M964.2,384.5l2.4-0.9l1.8,0.2l1,1.4l0,0.1 M954.1,395.7l-0.8-1.2l-0.1-2.3l0.9-2.8l1.8-3l2.3-2.6l2.5-1.9
	 M960.8,381.9l2.4-0.9l1.8,0.2l0.3,0.2 M931.2,356.1L931,356l-0.6-0.2l-0.7,0l-0.8,0.1l-0.9,0.3 M928.1,356.1l29,22.6 M957,378.8
	l0.1,0.1l0.1,0.2l0,0.2l-0.1,0.2l-0.2,0.2 M957,379.7l-0.2,0.1l-0.3,0.1l-0.4,0l-0.3-0.1l-0.3-0.2 M955.4,379.7l-29-22.6
	 M926.5,357.1l-0.9,0.7l-0.9,0.8l-0.9,0.9l-0.8,1l-0.7,1.1l-0.6,1.1l-0.5,1.1l-0.4,1 M920.8,364.8l29,22.6 M949.8,387.4l0.2,0.2
	l0.2,0.3l0.1,0.3l0.1,0.3l0,0.3 M950.4,388.9l0,0.2l-0.1,0.2l-0.2,0.1l-0.2,0l-0.2-0.1 M949.5,389.1l-29-22.6 M920.6,366.5l0,0.8
	l0.2,0.7l0.3,0.5l0.2,0.2 M957.8,397.9l-0.7-1.1l-0.1-2.1l0.9-2.7l1.6-2.8l2.2-2.5l2.4-1.8 M964.1,384.9l2.2-0.9l1.7,0.2l0.2,0.2
	 M977.6,412.8l-0.9-0.3l-1-1.3l-0.1-2.1l0.9-2.7l1.6-2.8l2.2-2.5l2.4-1.8 M982.7,399.4l2.2-0.9l1.7,0.2l1,1.3l0,0.2 M976.7,411.9
	l-0.6-0.9l-0.1-1.9l0.7-2.5l1.5-2.6l2-2.4l2.2-1.8l2.1-0.9l1.7,0.1l0.4,0.3 M983.9,382.7l0.8-0.8l2.4-1.8 M987.1,380l2.2-0.9
	l1.7,0.2l0.2,0.2 M1002.1,397.6l1.2-1.2l2.4-1.8 M1005.7,394.5l2.2-0.9l1.7,0.2l1,1.3l0,0.1 M1002.3,397.7l0.8-0.9l2.2-1.8l2.1-0.9
	l1.7,0.1l0.3,0.2 M970.9,386.4l1-1.5l2.2-2.5l2.4-1.8 M976.4,380.6l2.2-0.9l1.7,0.2l0.2,0.2 M989.1,401.4l1.3-2l2.2-2.5l2.4-1.8
	 M995,395.1l2.2-0.9l1.7,0.2l1,1.3l0,0.1 M989.3,401.6l1.1-1.8l2-2.4l2.2-1.8l2.1-0.9l1.7,0.1l0.4,0.2 M913.5,396.1l1.9-0.5l6.6-0.8
	l7,0.3l7.3,1.5l7.6,2.6l7.7,3.7l7.7,4.7l7.6,5.7l7.5,6.6l7.2,7.4l6.8,8.1l6.4,8.7l5.8,9.2l5.2,9.6l4.5,9.8l3.8,9.9l3,9.9l2.2,9.8
	l1.3,9.5l0.4,9.1 M1021,521l-0.4,8.6l-1,6.7 M733.7,584.3l0.2-0.7l0.7-1.6 M760.9,620.8l-0.9,0.3l-3.4,0.3l-1.6-0.2 M761.1,595.9
	l2,4.7l1.3,5.1l0.5,4.8 M731.8,585.3l0.2-0.6l1.8-3l0.1-0.1 M764.9,610.4l-0.5,4.3l-1.3,3.5l-2.2,2.6l-2.9,1.5l-3.4,0.3l-1.6-0.2
	 M709.3,631.8l-5-12.1 M718.9,626.3l-9.5,5.5 M704.3,619.7l-2.4-1.2l-2.2,0.1l-1.5,1.3l-0.6,2.4l0.4,3l1.3,3.1l2,2.8l2.4,1.9
	l2.4,0.8l2-0.5l1.3-1.7 M712.7,614.9l-8.4,4.8 M1001.1,565.3l4.6-3.2l4.6-4.6l3.8-5.6l3-6.5l2.2-7.3l1.3-8.1l0.4-8.7 M1021,521.4
	l-0.4-9.2l-1.3-9.6l-2.2-9.8l-3-10l-3.8-10l-4.6-9.9l-5.2-9.6l-5.9-9.2l-6.4-8.7l-6.9-8.1l-7.2-7.4l-7.5-6.6l-7.7-5.7l-7.7-4.7
	l-7.7-3.7l-7.6-2.6l-7.4-1.5l-7.1-0.4l-6.6,0.8l-6.1,1.9l-3.5,1.8 M765.4,517.1l-0.4-0.3 M765.4,517.1L765.4,517.1 M750.3,563.3
	l0.7-0.5l1.7-2.2l0.9-3.1l0.1-2.7 M742.7,566.2l2.1-0.1l2.4-1.1l1.7-2.2l0.9-3.1l-0.1-3.7 M728.2,540.3l-0.1,0.2l-0.1,0.3
	 M749.7,556l-0.9-3.9 M749.4,670.6l0-0.1l0.5-3.4 M728.2,649.3l-0.2,0.3 M749.8,667.1l-0.5-3.9l-0.6-2.1 M753.3,667.8l0.3-2.9l0-1.1
	 M433.2,503.5v78.4 M648.6,548.2l0.2-1.9 M648.7,546.3l0.7-7.9 M709.1,486.7l-3.8,2.2 M696.5,486.4l-24.5,14.2 M738.3,679.6V758
	 M689,629.8l-1.1,0.6 M694.7,641.9l1.7-1 M686.3,621.7l-2.5,1.4 M709.3,585.5l-21.5,12.4 M717.1,648.6l0.9-0.5 M747.5,625.6l6.8-3.9
	 M733,584.7l-9.5,5.5 M732.8,594.1l-21.5,12.4 M722.1,625.3l21.5-12.4 M737,601.3l-21.5,12.4 M748.1,616.9l0.3,0 M750.3,616.5l0.3,0
	 M748.7,595.2l-0.3,0 M746.4,595.6l-0.3,0 M750.2,597.1l-0.3,0l-0.3,0 M744.1,565.4l1.8-1 M742.1,566.5l0.8-0.4 M728.2,540.7
	l-0.8,0.4 M724.8,544.8l-0.6,3.5l0.1,3.6l0.8,3.5l1.5,3.3l2.1,2.9l2.6,2.4l3.1,1.8l3.4,1.2 M728.2,649.6l-0.8,0.4 M724.8,653.6
	l-0.6,3.5l0.1,3.6l0.8,3.5l1.5,3.3l2.1,2.9l2.6,2.4l3.1,1.8l3.4,1.2 M939.7,352.2l-0.2,0.9 M940,351.8L940,351.8l-0.1,0.1l0,0l0,0
	l0,0.1l0,0.1l0,0.1l0,0.1 M939.1,352.7L939.1,352.7l-0.1-0.1l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0 M969,375.4L969,375.4
	L969,375.4L969,375.4 M969,375.4L969,375.4l-0.2,0.1l-0.2,0l-0.2,0l-0.2,0l-0.1-0.1l0,0 M968,375.4l-29-22.6 M980.9,379.7l-3.3-2.6
	 M975.4,376.7l-31.9-24.9 M950.4,351.6l-0.2,0.9 M950.7,351.2L950.7,351.2l-0.1,0.1l0,0l0,0l0,0.1l0,0.1l0,0.1l0,0.1 M949.8,352.2
	L949.8,352.2l-0.1-0.1l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0 M979.7,374.8L979.7,374.8L979.7,374.8L979.7,374.8 M979.6,374.9
	L979.6,374.9l-0.2,0.1l-0.2,0l-0.2,0l-0.2,0l-0.1-0.1l0,0 M978.7,374.8l-29-22.6 M991.6,379.2l-3.3-2.6 M986.1,376.2l-31.9-24.9
	 M1003.9,562.5l5.9,2.2l7.4,1.9l4.9,0.8l2.6,0.3l2.8,0.2l5.9,0l8.4-1.1l5.5-1.5l5.9-2.4l5.4-3.1l2.1-1.5l2.1-1.7 M1052.2,544.6
	l-0.6,0.5l-0.6,0.5l-1,0.7l-1.9,1.2l-4,2l-8.9,2.3l-6.2,0.3l-7-0.7l-7.3-1.8 M921.3,365.4l-0.6,0.4 M920.7,365.8L920.7,365.8
	l-0.1,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1 M927.4,356.5l-0.2,0.9 M927.7,356.1L927.7,356.1l-0.1,0.1l0,0l0,0l0,0.1l0,0.1l0,0.1
	l0,0.1 M920.7,364.5L920.7,364.5l0,0.2l0,0.1l0,0.1l0,0.1l0,0.1l0.1,0.1l0.1,0.1 M926.8,357L926.8,357l-0.1-0.1l-0.1,0l-0.1,0
	l-0.1,0l-0.1,0l-0.1,0l-0.1,0 M950.2,388.6L950.2,388.6L950.2,388.6 M949.8,387.7L949.8,387.7l0.2,0.2l0.1,0.2l0.1,0.2l0,0.2v0.1
	l0,0 M956.7,379.6L956.7,379.6L956.7,379.6L956.7,379.6 M956.7,379.7L956.7,379.7l-0.2,0.1l-0.2,0l-0.2,0l-0.2,0l-0.1-0.1l0,0
	 M920.9,365.1l29,22.6 M955.8,379.6l-29-22.6 M1062.7,556.6l1.3-1.2l1.3-1.4l1.8-2.5l1.6-3.4l1-3.9l-0.3-9.2l-3.4-11 M1051.3,530.5
	l1.8,5l0.9,4.2l-0.4,3.4l-0.7,1l-0.4,0.3l-0.1,0.1l-0.1,0.1 M954.1,395.7l3.3,2.6 M968.6,384l-3.3-2.6 M963.1,381l-31.9-24.9
	 M921.4,368.7l31.9,24.9 M957.8,397.9l18.6,14.5 M986.9,398.9l-18.6-14.5 M990.3,402.4l-3.7-3 M976.7,411.9l3.7,3 M1066,524.2
	l-4.2-9l-5.5-10.3 M1042.3,512.6l5.2,9.8l3.8,8.1 M1056.2,504.8l-5.7-11.2l-5.6-13.1 M1030.1,486.4l6.2,14.3l6.1,11.9 M1044.9,480.5
	l-3.9-9.6l-3.7-8l-3.3-6.3l-2.7-4.8l-2.5-4.2l-2.5-4l-2.8-4.2l-4.9-6.9l-6.7-8.5l-6-6.7l-7.2-7.3l-8.6-7.6 M980.4,414.9l11.1,10.2
	l8.5,9.5l6.1,7.8l4.5,6.3l2.6,3.9l2.3,3.6l2.3,3.9l1.9,3.4l2.3,4.3l2.6,5.2l2.7,6.2l2.8,7.1 M1027.7,525.6l-6.9-2.7 M1050.7,529.3
	l-2.4,0.3l-6.1-0.3l-6.9-1.3l-7.5-2.4 M1078.6,533.2l2.1-2.3l1.8-2.3l2.2-3.8l2.3-9.1l-1.1-9.9l-4-10.5 M1009.9,394.1l-18.6-14.5
	 M1013.3,397.2l-3.8-2.8 M1082,495.3l-4.2-7.8l-4.7-7.9 M1073.1,479.7l-4-6.9l-3.3-6.5 M1065.9,466.2l-4.5-9.8l-4.1-7.8l-3.4-5.9
	l-2.6-4.2l-3-4.5l-3.5-4.9l-4.7-6l-6.4-7.4l-5.6-5.8l-6.8-6.3l-8-6.4 M1068,549.7l4.4-3.4 M1053.9,539.5l-8.4,2.6l-5.9,0.4l-6.8-0.5
	l-7.5-1.6l-6.3-2.2 M1072.4,546.3l3-3.3l2.4-3.7l2.5-8.4l-0.7-9.8l-3.8-11 M999.2,394.6l-18.6-14.5 M1002.6,398l-3.7-2.9
	 M1075.9,510.2l-4.3-8.6l-5.2-9.3 M1066.4,492.2l-4.9-9.2l-4.5-10 M1057.1,473.1l-5.3-12.2l-4.8-9.3l-3.7-6.5l-2.5-4.1l-2.5-3.8
	l-2.8-4.1l-4.9-6.6l-6.7-8.1l-5.9-6.4l-7.1-6.9l-8.4-7.1 M769.1,628.5l10.1-5.8 M748.3,626.5l1.3,0.8l3.6,1.7l3.7,1.1l3.8,0.4
	l3.7-0.4l3.6-1.2l1-0.7 M727.1,577.7l-0.9,3.2l-0.5,4.5l0.1,3.4 M710.6,612.3l-11.2,6.5 M707.9,633.6l11.2-6.5 M905.1,399
	l-172.8,99.8 M840.6,658l160.5-92.7 M746.5,565.5l3.8-2.2"/>
</svg>


}

export default MotorMount
